import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import SignInUserAuthContainer from '../../../components/componentsUser/SignInUserAuth/SignInUserAuthContainer';

class SignInAuthView extends PureComponent {

    render() {


        return(
            <AnimatedView>

                <SignInUserAuthContainer />

            </AnimatedView>

        );
    }
}

export default SignInAuthView;
