
export const DashboardSummaryExportDataElements = [
    {
        dBKey: 'WarehousePort',
        displayName: 'Port',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 80
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'ConsigneeName',
        displayName: 'Consignee',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 350
    },
    {
        dBKey: 'DeliveryAddress',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'InWarehousePCS',
        displayName: 'In Warehouse PCS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'InWarehousePallets',
        displayName: 'In Warehouse Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },


]

export default DashboardSummaryExportDataElements

