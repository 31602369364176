import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import { limitUpperCaseLengthMask, textStringInputMask, limitCharacterLength } from '../../../resources/logic/inputMasks';




function AddSeaportModal({
    addNewModalShown,
    handleSave,
    handleClose,
    onChangeFunc,
    onExited,
    state, ...props

}) {
    return (

        <div className="modal-container">

            <Modal size={"lg"} backdrop={'static'} keyboard={false} show={addNewModalShown} onHide={handleClose} onExited={onExited} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>Add New Seaport</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Panel bordered style={{ marginBottom: '15px' }}>

                        <FormGroup >
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                <InputGroup.Addon>Seaport Code (3 Characters):</InputGroup.Addon>
                                <Input value={state.SeaportCode} onChange={(e) => { onChangeFunc(e, 'SeaportCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 3)} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup >
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                <InputGroup.Addon>Seaport Port Name:</InputGroup.Addon>
                                <Input value={state.Port} onChange={(e) => { onChangeFunc(e, 'Port') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 150)} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup >
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                <InputGroup.Addon>Country:</InputGroup.Addon>
                                <Input value={state.Country} onChange={(e) => { onChangeFunc(e, 'Country') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 150)} />
                            </InputGroup>
                        </FormGroup>

                    </Panel>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default AddSeaportModal;

