export const XdockLoadPalletDataElements = [


    // {
    //     dBKey: 'HouseRef',
    //     displayName: 'House Ref',
    //     primaryCol: true,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 250
    // },
 


    {
        dBKey: 'PalletSeq',
        displayName: 'Pallet Sequence',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 130
    },
 

    {
        dBKey: 'CartonQty',
        displayName: 'Carton Qty',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 130
    },
 


    
    
]


export default XdockLoadPalletDataElements

