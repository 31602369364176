import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, Loader, Button, ControlLabel, InputGroup, Input } from 'rsuite';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatStringForAPI, formatDateForUrlSearch, formatNumberForAPI, isNumeric, formatDateTimeForUrlSearch, formatTimeForUrlSearch, findLowestMissingNumber, queryStringParseWithZero, formatAllTextNumbersSymbolsForAPI } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import ShipmentManagementDrawer from './ShipmentManagementDrawer';
import RSearchInput from '../../componentsGeneral/Rsuite/SearchInput/RSearchInput';
import CtrlTwrShipmentDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentDataElements';
import ShipmentManagementAddNewLegModal from './ShipmentManagementAddNewLegModal';
import ShipmentManagementMultiModal from './ShipmentManagementMultiModal';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "ProRef"


const ctrlTwrShipmentStatus = [
    {
      "label": "Open",
      "value": 0
    },
    {
      "label": "Complete",
      "value": 1
    },
    {
      "label": "All",
      "value": null
    }
  ]
  

const mountRef = React.createRef();

class ShipmentManagement extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},


            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchPro: '',
            searchPO: '',
            // searchCustomer: '',
            searchCountry: '',
            searchStatus: '',
            isFilterShown: true,

            //Ship Management Drawer
            showShipManagmentDrawer: false,
            ShipManagementExistingDict: {},
            unsavedShipManagmentChanges: false,
            ShipManagementProRef: null,
            AddNewLegDict: {},
            ShipManagementHbolData: {},
            ShipManagementPoData: {},
            ShipManagementCustRefData: {},


            //Add new Leg Modal
            addNewLegModalShown: false,

            newLegModalLegNumber: null,
            newLegModalMode: null,



            //Multi Modal
            multiModalShown: false,
            multiModalLegNumber: null,
            multiModalStateName: null,
            multiModalHbolID: null,
            multiModalNewValue: '',

            multiModalValuesSaving: false,


            //Milestone Event Entry

            //Receive Container Drawer
            ReceiveDict: {},
            LocationDict: {},
            unsavedShipManagmentChanges: false,

            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,


        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        this.onClickShipDetails = this.onClickShipDetails.bind(this);

        //Search Values
        this.onChangeSearchPro = this.onChangeSearchPro.bind(this);
        this.onChangeSearchCustomer = this.onChangeSearchCustomer.bind(this);
        this.onChangeSearchCountry = this.onChangeSearchCountry.bind(this);
        this.onChangeSearchPO = this.onChangeSearchPO.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);

        //Ship Management Drawer
        this.onClickOpenShipManagmenetDrawer = this.onClickOpenShipManagmenetDrawer.bind(this);
        this.onChangeShipmentManagement = this.onChangeShipmentManagement.bind(this);
        this.onClickShipmentManagementDrawerSave = this.onClickShipmentManagementDrawerSave.bind(this);
        this.onClickDeleteLeg = this.onClickDeleteLeg.bind(this);
        this.onClickShowAddLeg = this.onClickShowAddLeg.bind(this);
        this.closeShipmentManagementDrawer = this.closeShipmentManagementDrawer.bind(this);
        this.setShipmentManagementDrawerToClose = this.setShipmentManagementDrawerToClose.bind(this);
        this.onClickMulti = this.onClickMulti.bind(this);
        this.onClickDeleteMulti = this.onClickDeleteMulti.bind(this);

        //Add new Leg Modal
        this.handleSaveAddNewModal = this.handleSaveAddNewModal.bind(this);
        this.handleCloseAddNewModal = this.handleCloseAddNewModal.bind(this);
        this.onChangeAddNewLegModal = this.onChangeAddNewLegModal.bind(this);

        //Multi Modal
        this.onChangeMultiModal = this.onChangeMultiModal.bind(this);
        this.handleCloseMultiModal = this.handleCloseMultiModal.bind(this);
        this.handleSaveMultiModal = this.handleSaveMultiModal.bind(this);
        this.setMultiModalToSaving = this.setMultiModalToSaving.bind(this);

    }



    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection

        this.loadSortFromStorage()
        this.updateParamsFromURL()

    }

    getsessionStorageWarehouse() {

        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }

    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = queryStringParse(values.pro)
        }
        catch (e) {
            var paramPro = null
        }
        if (paramPro != null) {
            this.onChangeSearchPro(paramPro)
        }

        try {
            var paramPO = queryStringParse(values.po)
        }
        catch (e) {
            var paramPO = null
        }
        if (paramPO != null) {
            this.onChangeSearchPO(paramPO)
        }

        try {
            var paramCustomer = this.props.customerSelection
        }
        catch (e) {
            var paramCustomer = null
        }
        if (paramCustomer != null) {
            this.onChangeSearchCustomer(paramCustomer)
        }

        try {
            var paramCountry = queryStringParse(values.country)
        }
        catch (e) {
            var paramCountry = null
        }
        if (paramCountry != null) {
            this.onChangeSearchCountry(paramCountry)
        }


        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

    }




    componentDidUpdate(prevProps, prevState) {

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


        if (prevProps.CtrlTwrShipmentLegsData !== this.props.CtrlTwrShipmentLegsData && this.props.CtrlTwrShipmentLegsData.length > 0) {
            this.preloadShipManagement()
        }
        if (prevProps.CtrlTwrShipmentLegsData !== this.props.CtrlTwrShipmentLegsData && this.props.CtrlTwrShipmentLegsData.length === 0) {
            // this.preloadShipManagement()
            this.onClickShowAddLeg()
        }

        if (prevProps.CtrlTwrShipmentLegsCustomerRefData !== this.props.CtrlTwrShipmentLegsCustomerRefData) {
            this.preloadHbolPoCustRef()
        }
        if (prevProps.CtrlTwrShipmentLegsPoData !== this.props.CtrlTwrShipmentLegsPoData) {
            this.preloadHbolPoCustRef()
        }
        if (prevProps.CtrlTwrShipmentLegsHbolData !== this.props.CtrlTwrShipmentLegsHbolData) {
            this.preloadHbolPoCustRef()
        }

        if (prevProps.CtrlTwrShipmentLegsDataPostSuccessful !== this.props.CtrlTwrShipmentLegsDataPostSuccessful && this.props.CtrlTwrShipmentLegsDataPostSuccessful) {
            setTimeout(() => {
                try {
                    this.setShipmentManagementDrawerToClose();
                }
                catch (e) { }
            }, 100);
        }

        if (prevProps.CtrlTwrShipmentLegsPostSuccessful !== this.props.CtrlTwrShipmentLegsPostSuccessful && this.props.CtrlTwrShipmentLegsPostSuccessful) {
            setTimeout(() => {
                try {
                    this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef)
                }
                catch (e) { }
            }, 100);
        }


        if (!prevProps.CtrlTwrShipmentLegsCustomerRefPostSuccessful && this.props.CtrlTwrShipmentLegsCustomerRefPostSuccessful && !this.state.multiModalValuesSaving) {
            setTimeout(() => {
                try {
                    this.props.ctrlTwrShipmentLegsCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef, null, null)
                }
                catch (e) { }
            }, 100);
        }

        if (!prevProps.CtrlTwrShipmentLegsPoPostSuccessful && this.props.CtrlTwrShipmentLegsPoPostSuccessful && !this.state.multiModalValuesSaving) {
            setTimeout(() => {
                try {
                    this.props.ctrlTwrShipmentLegsPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef, null, null)
                }
                catch (e) { }
            }, 100);
        }

        if (!prevProps.CtrlTwrShipmentLegsHbolPostSuccessful && this.props.CtrlTwrShipmentLegsHbolPostSuccessful && !this.state.multiModalValuesSaving) {
            setTimeout(() => {
                try {
                    this.props.ctrlTwrShipmentLegsHbolGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef, null)
                }
                catch (e) { }
            }, 100);
        }



        // if (prevProps.isCtrlTwrShipmentLoading === true && this.props.isCtrlTwrShipmentLoading === false && this.state.searchPro !== '' && this.props.CtrlTwrShipmentData.length == 0) {
        //     window.alert('Not found.')
        // }
        // if (prevProps.ContainerPostSuccessful !== this.props.ContainerPostSuccessful && this.props.ContainerPostSuccessful && this.props.isCtrlTwrShipmentLoading === false) {
        //     // try {
        //     this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.NewContainerId, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        //     this.setNewContainerID(this.props.NewContainerId)
        //     // }
        //     // catch (e) { }

        //     this.setStateCloseAddNewDrawer()

        //     // if (window.confirm('Build new container?')) {
        //     this.onClickAddToContainer()
        //     // }

        //     this.setInitialTreeSelection(this.props.NewContainerId, this.state.Container)
        // }

        // new container id needs to be selected row not row zero
        // if (prevProps.MbolPostSuccessful !== this.props.MbolPostSuccessful && this.props.MbolPostSuccessful) {
        //     try {
        //         this.setNewMbolTreeSelection(this.props.NewMbolId, this.state.addNewText)
        //     }
        //     catch (e) { }

        //     setTimeout(() => {
        //         try {
        //             this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        //         }
        //         catch (e) { }
        //     }, 100);


        // }


        // if (prevProps.PoPostSuccessful !== this.props.PoPostSuccessful && this.props.PoPostSuccessful) {
        //     try {
        //         this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        //         this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        //         this.props.transProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

        //     }
        //     catch (e) { }
        // }


    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }

    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchPro: '',
            // searchCustomer: null,
            searchCountry: null,
            searchPO: '',
            searchStatus: null,

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchPro: '',
            searchPO: '',
        });


        // try {
        //     var searchCustomer = this.props.customerSelection
        // }
        // catch (e) {
        //     var searchCustomer = null
        // }

        try {
            var searchCountry = this.state.searchCountry
        }
        catch (e) {
            var searchCountry = null
        }

        try {
            var searchStatus = this.state.searchStatus
        }
        catch (e) {
            var searchStatus = null
        }


        try {
            var searchStatus = this.state.searchStatus
            this.props.history.push({
                search:
                    // 'customer=' + searchCustomer + '&' +
                    'country=' + searchCountry + '&' +
                    'status=' + searchStatus
            });
        }
        catch (e) { }

    }

    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            // searchCustomer: null,
            searchCountry: null,

        });
    }

    onChangeSearchPro(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchPO: '',
            searchPro: e,
            // searchCustomer: null,
            searchCountry: null,
            searchStatus: null,
        });
    }

    onChangeSearchPO(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchPO: e,
            searchPro: '',
            // searchCustomer: null,
            searchCountry: null,
            searchStatus: null,

        });
    }

    onChangeSearchCustomer(e) {
        this.setState({
            // searchCustomer: e,
        });
    }
    onChangeSearchCountry(e) {
        this.setState({
            searchCountry: e,
        });
    }
    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }


    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'containerdetails',
                search: 'containerid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }


    //Table on click details
    onClickShipDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'shipmentdetailsmanagement',
                search: 'pro=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }




    //Ship Management Drawer
    onClickOpenShipManagmenetDrawer(e, rowData, history) {

        this.props.ctrlTwrShipmentLegsHbolGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ProRef, null)
        this.props.ctrlTwrShipmentLegsPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ProRef, null, null)
        this.props.ctrlTwrShipmentLegsCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ProRef, null, null)

        this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ProRef)

        this.setState({
            //Ship Management Drawer
            showShipManagmentDrawer: true,
            ShipManagementExistingDict: {},
            unsavedShipManagmentChanges: false,
            ShipManagementProRef: rowData.ProRef,
            addNewLegModalShown: false,
            AddNewLegDict: {},
            multiModalShown: false,
            multiModalValuesSaving: false,
            ShipManagementHbolData: {},
            ShipManagementPoData: {},
            ShipManagementCustRefData: {},
        });
    }

    preloadHbolPoCustRef() {
        var hbolData = this.props.CtrlTwrShipmentLegsHbolData
        var poData = this.props.CtrlTwrShipmentLegsPoData
        var custRefData = this.props.CtrlTwrShipmentLegsCustomerRefData

        this.setState({
            ShipManagementHbolData: hbolData,
            ShipManagementPoData: poData,
            ShipManagementCustRefData: custRefData
        });
        this.forceUpdate()
    }

    preloadShipManagement() {
        var legsData = this.props.CtrlTwrShipmentLegsData
        var hbolData = this.props.CtrlTwrShipmentLegsHbolData
        var poData = this.props.CtrlTwrShipmentLegsPoData
        var custRefData = this.props.CtrlTwrShipmentLegsCustomerRefData

        this.setState({
            addNewLegModalShown: false,
            ShipManagementExistingDict: legsData,
            unsavedShipManagmentChanges: false,
            ShipManagementHbolData: hbolData,
            ShipManagementPoData: poData,
            ShipManagementCustRefData: custRefData
        });
        this.forceUpdate()
    }

    onClickShipmentManagementDrawerSave(e) {
        // check if depart or deliver dates are blank
        if (this.state.ShipManagementExistingDict.findIndex(item => item.ModeID !== 6 && (item.PlannedDepartureDate === null || item.PlannedDepartureDate === '')) !== -1) {
            window.alert('Please enter all Departure Dates before saving.')
        }
        else if (this.state.ShipManagementExistingDict.findIndex(item => item.ModeID !== 6 && (item.EstimatedDeliveryDate === null || item.EstimatedDeliveryDate === '')) !== -1) {
            window.alert('Please enter all Delivery Dates before saving.')
        }
        else if (this.state.ShipManagementExistingDict.findIndex(item => [3, 4].includes(item.ModeID) && (item.Trailer === null || item.Trailer === '')) !== -1) {
            window.alert('Please enter Trailer before saving.')
        }
        else {

            // if (window.confirm('Save Data?')) {
            for (let l in this.state.ShipManagementExistingDict) {
                try {
                    this.props.ctrlTwrShipmentLegsDataPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,

                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].ProRef),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].LegNumber),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].ModeID),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].LegID),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].WarehouseID),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].BOL),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].MBOL),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].HBOL),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].PO),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Container),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Carrier),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Seal),

                        formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].PlannedDepartureDate),
                        formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].EstimatedDeliveryDate),

                        // formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].EstimatedPickupDate),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].PickupAddress),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].DeliveryAddress),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Trailer),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Vessel),
                        // formatDateForUrlSearch(this.state.ShipManagementExistingDict[l].SailingDate),
                        // formatDateForUrlSearch(this.state.ShipManagementExistingDict[l].SailingEstimatedArrivalDate),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].SeaportOriginID),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].SeaportDestID),
                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Flight),
                        // formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].FlightETD),
                        // formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].FlightETA),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].AirportOriginID),
                        formatNumberForAPI(this.state.ShipManagementExistingDict[l].AirportDestID),
                        formatStringForAPI(this.state.ShipManagementExistingDict[l].CustomerRef),

                        formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Shipper_Name), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Origin_AddressLine), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Origin_City), formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_State), formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_PostalCode), formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_CountryCode), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Consignee_Name), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Destination_AddressLine), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Destination_City), formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_State), formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_PostalCode), formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_CountryCode),

                        formatTimeForUrlSearch(this.state.ShipManagementExistingDict[l].PickupAppointmentTime),
                        formatTimeForUrlSearch(this.state.ShipManagementExistingDict[l].DeliveryAppointmentTime)

                    )
                }
                catch (e) { }
            }

            this.setState({
                unsavedShipManagmentChanges: false,
                showShipManagmentDrawer: false,
                addNewLegModalShown: false,
                multiModalShown: false,
                multiModalValuesSaving: false,
            });
        }
    }

    onClickDeleteLeg(e, legID) {
        if (this.props.CtrlTwrShipmentLegsData.length === 1) {
            window.alert('Error: Cannot delete all legs.')
        }
        else {
            if (window.confirm('Delete Leg?')) {
                this.props.ctrlTwrShipmentLegsPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    null,
                    null,
                    null,
                    legID,
                    1


                )
            }
        }
    }

    onClickShowAddLeg(e) {
        if (this.state.unsavedShipManagmentChanges) {
            window.alert('Please save changes before adding new leg.')
        }
        else {
            try {
                const legNumbersArray = this.state.ShipManagementExistingDict.map(item => item.LegNumber);
                var newLegNumber = findLowestMissingNumber(legNumbersArray)
            }
            catch (e) {
                var newLegNumber = 1
            }
            this.setState({
                addNewLegModalShown: true,
                newLegModalLegNumber: newLegNumber,
                newLegModalMode: null,
            });
        }
    }

    onChangeShipmentManagement(e, legID, stateName) {

        var shipManagementDictUpdated = this.state.ShipManagementExistingDict
        var keyToUpdate = shipManagementDictUpdated.findIndex(item => item.LegID === legID)

        shipManagementDictUpdated[keyToUpdate][stateName] = e

        this.setState({
            ShipManagementExistingDict: shipManagementDictUpdated,
            unsavedShipManagmentChanges: true
        });

        this.forceUpdate()
    }

    closeShipmentManagementDrawer() {
        if (this.state.unsavedShipManagmentChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setShipmentManagementDrawerToClose()
            }
        }
        else {
            this.setShipmentManagementDrawerToClose()
        }
    }

    setShipmentManagementDrawerToClose() {
        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            AddNewLegDict: {},
            unsavedShipManagmentChanges: false,

            showShipManagmentDrawer: false,
            addNewLegModalShown: false,

            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
        });
    }


    onClickMulti(e, legID, stateName, hbolID) {
        if (this.state.unsavedShipManagmentChanges) {
            window.alert('Please save changes first.')
        }
        else {
            this.setState({
                multiModalShown: true,
                multiModalValuesSaving: false,
                multiModalLegNumber: legID,
                multiModalStateName: stateName,
                multiModalHbolID: hbolID,
                multiModalNewValue: ''
            });
        }

    }

    onClickDeleteMulti(e, stateName, hbolID, poID, custRefID) {
        if (stateName === 'HBOL') {
            if (window.confirm('Delete HBOL?')) {
                this.props.ctrlTwrShipmentLegsHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, hbolID, null, null, 1)
            }
        }
        else if (stateName === 'PO') {
            if (window.confirm('Delete PO?')) {
                this.props.ctrlTwrShipmentLegsPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, poID, null, 1)
            }
        }
        else if (stateName === 'CustomerRef') {
            if (window.confirm('Delete Customer Ref?')) {
                this.props.ctrlTwrShipmentLegsCustomerRefPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, custRefID, null, null, null, 1)
            }
        }
    }


    //Multi Modal

    onChangeMultiModal(e) {
        this.setState({
            multiModalNewValue: e
        });

    }

    handleCloseMultiModal() {
        this.setState({
            multiModalShown: false,
            multiModalValuesSaving: false,
            multiModalLegNumber: null,
            multiModalStateName: null,
            multiModalHbolID: null,
            multiModalNewValue: ''
        });
    }

    setMultiModalToSaving(trueOrFalse) {
        this.setState({
            multiModalValuesSaving: trueOrFalse,
        });
    }


    handleSaveMultiModal() {
        this.setMultiModalToSaving(true)

        // try

        var multiArray = this.state.multiModalNewValue.split(',').map(item => item.trim())

        const uniqueMultiArray = [...new Set(multiArray)];

        if (this.state.multiModalStateName === 'HBOL') {
            for (let x in uniqueMultiArray) {
                this.props.ctrlTwrShipmentLegsHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.multiModalHbolID, this.state.multiModalLegNumber, formatStringForAPI(uniqueMultiArray[x]), null)
            }
        }
        else if (this.state.multiModalStateName === 'PO') {
            for (let x in uniqueMultiArray) {
                this.props.ctrlTwrShipmentLegsPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.multiModalHbolID, this.state.multiModalLegNumber, null, formatStringForAPI(uniqueMultiArray[x]), null)
            }
        }
        else if (this.state.multiModalStateName === 'CustomerRef') {
            for (let x in uniqueMultiArray) {
                this.props.ctrlTwrShipmentLegsCustomerRefPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.state.multiModalLegNumber, this.state.multiModalHbolID, formatStringForAPI(uniqueMultiArray[x]), null)
            }
        }

        this.setMultiModalToSaving(false)

        this.handleCloseMultiModal()

    }


    //Add new Leg Modal
    onChangeAddNewLegModal(e, label) {
        try {
            if (label === 'Mode') {
                this.setState({
                    newLegModalMode: e
                });
            }
            else if (label === 'Leg') {
                if (this.state.ShipManagementExistingDict.filter(item => item.LegNumber === Number(e)).length > 0) {
                    window.alert('Duplicate leg entry, please enter new leg number.')
                }
                else if (e === 0) {
                    window.alert('Please select number greater than 0.')
                }
                else {
                    this.setState({
                        newLegModalLegNumber: String(e)
                    });
                }
            }
        }
        catch (e) {

        }

        this.forceUpdate()
    }


    handleCloseAddNewModal() {
        this.setState({
            addNewLegModalShown: false,
            newLegModalLegNumber: null,
            newLegModalMode: null,
        });
    }

    handleSaveAddNewModal() {

        if (this.state.ShipManagementProRef === null || this.state.ShipManagementProRef === '') {
            window.alert('Error, please refresh and try again.')
        }
        else if (this.state.newLegModalLegNumber === null || this.state.newLegModalLegNumber === '') {
            window.alert('Please enter leg number.')
        }
        else if (this.state.newLegModalMode === null || this.state.newLegModalMode === '') {
            window.alert('Please select mode.')
        }
        else {
            this.props.ctrlTwrShipmentLegsPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                formatNumberForAPI(this.state.ShipManagementProRef),
                formatNumberForAPI(this.state.newLegModalLegNumber),
                formatNumberForAPI(this.state.newLegModalMode),
                null,
                null,
                null

            )

            this.handleCloseAddNewModal()
            this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef)
            this.setState({
                //Ship Management Drawer
                showShipManagmentDrawer: true,
                ShipManagementExistingDict: {},
                unsavedShipManagmentChanges: false,
                addNewLegModalShown: false,
                AddNewLegDict: {},
                ShipManagementHbolData: {},
                ShipManagementPoData: {},
                ShipManagementCustRefData: {},
            });
        }
    }



    render() {

        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PRO #',
                                this.onChangeSearchPro,
                                this.props.history,
                                'pro',
                                this.state.searchPro
                            )}
                        </Row>

                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PO',
                                this.onChangeSearchPO,
                                this.props.history,
                                'po',
                                this.state.searchPO
                            )}
                        </Row>
                    </Panel>

                    <Panel bordered style={{ marginBottom: '10px' }}>

                        {/* <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.state.customerArray,
                                this.state.searchCustomer,
                                'Customer',
                                this.onChangeSearchCustomer,
                                this.props.history,
                                mountRef
                            )}
                        </Row> */}
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.CtrlTwrCountryData,
                                this.state.searchCountry,
                                'Origin Country',
                                this.onChangeSearchCountry,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                ctrlTwrShipmentStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isCtrlTwrShipmentLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.CtrlTwrShipmentData) !== 'undefined' && this.props.CtrlTwrShipmentData != null) {
            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.CtrlTwrShipmentData,     //   tableData,
                CtrlTwrShipmentDataElements,       //   DataElements,
                this.props.isCtrlTwrShipmentLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   onClickAddMilestone,
                this.onClickOpenShipManagmenetDrawer,       //   onClickSelect,
                'Edit Legs',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickShipDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick


            )


            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'ShipmentManagement_' + currentDateTime}>
                                <ExcelSheet data={this.props.CtrlTwrShipmentData} name="ShipmentManagement">
                                    {
                                        CtrlTwrShipmentDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }


        let selectedPro;
        try {
            if (typeof (this.state.ShipManagementProRef) !== 'undefined' && this.state.ShipManagementProRef != null) {

                selectedPro = this.state.ShipManagementProRef
            }
            else {
                selectedPro = ''
            }
        }
        catch (e) {
            selectedPro = ''
        }


        let shipmentManagementDrawer;
        if (this.props.isCtrlTwrShipmentLegsDataLoading) {
            shipmentManagementDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        // else if (this.props.isCtrlTwrShipmentLegsHbolLoading) {
        //     shipmentManagementDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        // else if (this.props.isCtrlTwrShipmentLegsPoLoading) {
        //     shipmentManagementDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        // else if (this.props.isCtrlTwrShipmentLegsCustomerRefLoading) {
        //     shipmentManagementDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        else if (!this.state.showShipManagmentDrawer) {
            shipmentManagementDrawer = <div>  </div>
        }
        else if (Object.keys(this.state.ShipManagementExistingDict).length > 0 && typeof (this.state.ShipManagementExistingDict) !== 'undefined' && this.state.ShipManagementExistingDict != null) {
            shipmentManagementDrawer = <ShipmentManagementDrawer {...this.props} state={this.state} onChange={this.onChangeShipmentManagement} confirm={this.onClickShipmentManagementDrawerSave} close={this.closeShipmentManagementDrawer} onClickDeleteLeg={this.onClickDeleteLeg} onClickShowAddLeg={this.onClickShowAddLeg} onChangeNewLeg={this.onChangeNewLeg} onClickMulti={this.onClickMulti} onClickDeleteMulti={this.onClickDeleteMulti} masterLabel={selectedPro} />
        }


        return (
            <div>
                <h3>
                    Control Tower - Shipment Management
                </h3>
                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}

                {shipmentManagementDrawer}


                <ShipmentManagementAddNewLegModal {...this.props} state={this.state} addNewLegModalShown={this.state.addNewLegModalShown} onChangeAddNewLegModal={this.onChangeAddNewLegModal} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseAddNewModal} handleSave={this.handleSaveAddNewModal} />

                <ShipmentManagementMultiModal {...this.props} state={this.state} multiModalShown={this.state.multiModalShown} onChangeMultiModal={this.onChangeMultiModal} handleClose={this.handleCloseMultiModal} handleSave={this.handleSaveMultiModal} />


            </div>
        );

    }
}

export default withRouter(ShipmentManagement);