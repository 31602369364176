import React      from 'react';
import PropTypes  from 'prop-types';
import cx         from 'classnames';

const VasHomeMainContent = ({
  children,
  isAnimated,
  isExpanded,
  isMobile,
  warehouseSelection,
  customerSelection,
  organizationSelection
  // languageSelection
}) => (

  <aside className={
    cx({
      'right-side':             true,
      'right-side--top-margin': true,
      'aside-right-animated':   isAnimated,
      'strech':                 isExpanded,
      'ismobile':               isMobile,
      'warehouseSelection':     warehouseSelection,
      'customerSelection':     customerSelection,
      'organizationSelection': organizationSelection
      // 'languageSelection':      languageSelection
    })}>

    { children }
  </aside>
);

VasHomeMainContent.propTypes = {
  // children:   PropTypes.node.isRequired,
  // isAnimated: PropTypes.bool.isRequired,
  // isExpanded: PropTypes.bool.isRequired,
  // isMobile: PropTypes,
  // warehouseSelection: PropTypes,
  // customerSelection: PropTypes
  // languageSelection: PropTypes
};

VasHomeMainContent.defaultProps = {
  isAnimated: true,
  isExpanded: false,
  isMobile: false
  // languageSelection: 'English'
};

export default VasHomeMainContent;

