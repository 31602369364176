export const CtrlTwrShipmentLegsMilestonesMultiDataElements = [
    {
        dBKey: 'ProRef',
        displayName: 'ProRef',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 105
    },
    {
        dBKey: 'LegNumber',
        displayName: 'Leg Number',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 105
    },

    {
        dBKey: 'ModeName',
        displayName: 'Mode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },
    {
        dBKey: 'Vessel',
        displayName: 'Vessel',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },


    {
        dBKey: 'PlannedDepartureDate',
        displayName: 'Planned Departure Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'DisplayPickupAppointmentTime',
        displayName: 'Pickup Appointment Time',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },


    {
        dBKey: 'EstimatedDeliveryDate',
        displayName: 'Estimated Delivery Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'DisplayDeliveryAppointmentTime',
        displayName: 'Delivery Appointment Time',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'MilestoneDT',
        displayName: 'Datetime',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Milestone',
        displayName: 'Milestone',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 385
    },

    
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Port',
        displayName: 'Port',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'City',
        displayName: 'City',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'State',
        displayName: 'State',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Zip',
        displayName: 'Zip',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CountryName',
        displayName: 'Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },





]


export default CtrlTwrShipmentLegsMilestonesMultiDataElements

