export const VisibilityPackageUpsEventsDataElements = [
    {
        dBKey: 'ScanEventDT',
        displayName: 'Scan Event DT',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'ScanEventCode',
        displayName: 'Event Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },

    {
        dBKey: 'ScanEventDesc',
        displayName: 'Event Desc',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 340
    },   
    {
        dBKey: 'ScanEventCity',
        displayName: 'Event City',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },     
    {
        dBKey: 'ScanEventState',
        displayName: 'Event State',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'ScanEventZIP',
        displayName: 'Scan Event ZIP',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ScanEventCountry',
        displayName: 'Event Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'EvolveClassification',
        displayName: 'Event Classification',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    
   


]


export default VisibilityPackageUpsEventsDataElements

