export const CtrlTwrPublicShipmentLegsDetailDataElements = [
    {
        dBKey: 'LegNumber',
        displayName: 'Leg Number',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 120
    },

    {
        dBKey: 'ModeName',
        displayName: 'Mode',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 280
    },

    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },


    {
        dBKey: 'PlannedDepartureDate',
        displayName: 'Planned Departure Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'DisplayPickupAppointmentTime',
        displayName: 'Pickup Appointment Time',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'EstimatedDeliveryDate',
        displayName: 'Estimated Delivery Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'DisplayDeliveryAppointmentTime',
        displayName: 'Delivery Appointment Time',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },





    {
        dBKey: 'MBOL',
        displayName: 'MBOL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'BOL',
        displayName: 'BOL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },



    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },


    



    {
        dBKey: 'Seal',
        displayName: 'Seal',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },


    {
        dBKey: 'Vessel',
        displayName: 'Vessel',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },




    {
        dBKey: 'Flight',
        displayName: 'Flight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'Trailer',
        displayName: 'Trailer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },



    {
        dBKey: 'OriginPort',
        displayName: 'OriginPort',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },


    {
        dBKey: 'DestinationPort',
        displayName: 'DestinationPort',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },


    {
        dBKey: 'PickupAddress',
        displayName: 'Pickup Address',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },



    {
        dBKey: 'DeliveryAddress',
        displayName: 'Delivery Address',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    

]


export default CtrlTwrPublicShipmentLegsDetailDataElements

