import { connect } from 'react-redux';
import React, { Component } from 'react';
import ShipmentDetails from './ShipmentDetails';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../redux/actions/actionsUser';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isCtrlTwrCountryLoading: store.ctrlTwrReducer.isCtrlTwrCountryLoading,
    CtrlTwrCountryData: store.ctrlTwrReducer.CtrlTwrCountryData,

    isCtrlTwrModeRefLoading: store.ctrlTwrReducer.isCtrlTwrModeRefLoading,
    CtrlTwrModeRefData: store.ctrlTwrReducer.CtrlTwrModeRefData,

    isCtrlTwrAirportLoading: store.ctrlTwrReducer.isCtrlTwrAirportLoading,
    CtrlTwrAirportData: store.ctrlTwrReducer.CtrlTwrAirportData,

    isCtrlTwrSeaportLoading: store.ctrlTwrReducer.isCtrlTwrSeaportLoading,
    CtrlTwrSeaportData: store.ctrlTwrReducer.CtrlTwrSeaportData,


    isCtrlTwrShipmentLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLoading,
    CtrlTwrShipmentData: store.ctrlTwrReducer.CtrlTwrShipmentData,

    isCtrlTwrShipmentLegsDataLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsDataLoading,
    CtrlTwrShipmentLegsData: store.ctrlTwrReducer.CtrlTwrShipmentLegsData,


    isCtrlTwrShipmentLegsMilestonesLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsMilestonesLoading,
    CtrlTwrShipmentLegsMilestonesData: store.ctrlTwrReducer.CtrlTwrShipmentLegsMilestonesData,


    isCtrlTwrShipmentLegsCustomerRefLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsCustomerRefLoading,
    CtrlTwrShipmentLegsCustomerRefData: store.ctrlTwrReducer.CtrlTwrShipmentLegsCustomerRefData,


    isCtrlTwrShipmentLegsPoLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsPoLoading,
    CtrlTwrShipmentLegsPoData: store.ctrlTwrReducer.CtrlTwrShipmentLegsPoData,


    isCtrlTwrShipmentLegsHbolLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsHbolLoading,
    CtrlTwrShipmentLegsHbolData: store.ctrlTwrReducer.CtrlTwrShipmentLegsHbolData,

    isCtrlTwrMilestoneRefLoading: store.ctrlTwrReducer.isCtrlTwrMilestoneRefLoading,
    CtrlTwrMilestoneRefData: store.ctrlTwrReducer.CtrlTwrMilestoneRefData,

    isCtrlTwrShipmentFileUploadLoading: store.ctrlTwrReducer.isCtrlTwrShipmentFileUploadLoading,
    CtrlTwrShipmentFileUploadData: store.ctrlTwrReducer.CtrlTwrShipmentFileUploadData,

    CtrlTwrShipmentFileUploadPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentFileUploadPostSuccessful,

    isCtrlTwrShipmentNotesPrivateLoading: store.ctrlTwrReducer.isCtrlTwrShipmentNotesPrivateLoading,
    CtrlTwrShipmentNotesPrivateData: store.ctrlTwrReducer.CtrlTwrShipmentNotesPrivateData,

    // CtrlTwrShipmentNotesPrivatePostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPrivatePostSuccessful,

    isCtrlTwrShipmentNotesPublicLoading: store.ctrlTwrReducer.isCtrlTwrShipmentNotesPublicLoading,
    CtrlTwrShipmentNotesPublicData: store.ctrlTwrReducer.CtrlTwrShipmentNotesPublicData,

    CtrlTwrShipmentUserSubscriptionPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentUserSubscriptionPostSuccessful,

    // CtrlTwrShipmentNotesPublicPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPublicPostSuccessful,
});

const mapDispatchToProps = dispatch =>
({
    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },

    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrCountryGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrCountryGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrAirportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrAirportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrSeaportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrSeaportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    ctrlTwrMilestoneRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrMilestoneRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    ctrlTwrShipmentGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert))
    },

    ctrlTwrShipmentLegsDataGet: (_email, _authToken, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsDataGet(_email, _authToken, _ProRef))
    },
    ctrlTwrShipmentLegsMilestonesGet: (_email, _authToken, _ProRef, _LegID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsMilestonesGet(_email, _authToken, _ProRef, _LegID))
    },
    ctrlTwrShipmentLegsCustomerRefGet: (_email, _authToken, _ProRef, _LegID, _HbolID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsCustomerRefGet(_email, _authToken, _ProRef, _LegID, _HbolID))
    },
    ctrlTwrShipmentLegsPoGet: (_email, _authToken, _ProRef, _LegID, _HbolID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsPoGet(_email, _authToken, _ProRef, _LegID, _HbolID))
    },
    ctrlTwrShipmentLegsHbolGet: (_email, _authToken, _ProRef, _LegID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsHbolGet(_email, _authToken, _ProRef, _LegID))
    },


    ctrlTwrShipmentFileUploadGet: (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentFileUploadGet(_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete))
    },


    ctrlTwrShipmentPrivateNotesGet: (_email, _authToken, _ShipmentNoteID, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPrivateNotesGet(_email, _authToken, _ShipmentNoteID, _ProRef))
    },
    // ctrlTwrShipmentPrivateNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete) => {
    //     dispatch(ctrlTwrActions.ctrlTwrShipmentPrivateNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete))
    // },
    ctrlTwrShipmentPublicNotesGet: (_email, _authToken, _ShipmentNoteID, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPublicNotesGet(_email, _authToken, _ShipmentNoteID, _ProRef))
    },
    // ctrlTwrShipmentPublicNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete) => {
    //     dispatch(ctrlTwrActions.ctrlTwrShipmentPublicNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete))
    // },

    ctrlTwrShipmentUserSubscriptionPost: (_email, _authToken, _ProRef, _Active) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentUserSubscriptionPost(_email, _authToken, _ProRef, _Active))
    },


});

class ShipmentDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.CtrlTwrShipmentFileUploadPostSuccessful !== this.props.CtrlTwrShipmentFileUploadPostSuccessful && this.props.CtrlTwrShipmentFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
        }
        
        if (prevProps.CtrlTwrShipmentUserSubscriptionPostSuccessful !== this.props.CtrlTwrShipmentUserSubscriptionPostSuccessful && this.props.CtrlTwrShipmentUserSubscriptionPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, null, null, null, null, null, null, null, null)
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = formatStringForAPI(values.pro)
        }
        catch (e) {
            var paramPro = null
        }

        // try {
        //     var paramPo = formatStringForAPI(values.po)
        // }
        // catch (e) {
        //     var paramPo = null
        // }

        // try {
        //     var paramCustomer = queryStringParse(values.customer)
        // }
        // catch (e) {
        //     var paramCustomer = null
        // }

        // try {
        //     var paramCountry = queryStringParse(values.country)
        // }
        // catch (e) {
        //     var paramCountry = null
        // }

        // try {
        //     var paramStatus = queryStringParse(values.status)
        // }
        // catch (e) {
        //     var paramStatus = null
        // }
        this.props.ctrlTwrModeRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrAirportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrSeaportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrMilestoneRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.ctrlTwrCountryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)


        this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, null, null, null, null, null, null, null, null)

        this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
        this.props.ctrlTwrShipmentLegsMilestonesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null)
        this.props.ctrlTwrShipmentLegsCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        this.props.ctrlTwrShipmentLegsPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        this.props.ctrlTwrShipmentLegsHbolGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null)

        this.props.ctrlTwrShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)

        this.props.ctrlTwrShipmentPrivateNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)    
        this.props.ctrlTwrShipmentPublicNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)
    
    }



    render() {

        return (

            <ShipmentDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                organizationSelection={this.props.organizationSelection}

                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}

                isCtrlTwrCountryLoading={this.props.isCtrlTwrCountryLoading}
                CtrlTwrCountryData={this.props.CtrlTwrCountryData}

                isCtrlTwrModeRefLoading={this.props.isCtrlTwrModeRefLoading}
                CtrlTwrModeRefData={this.props.CtrlTwrModeRefData}

                isCtrlTwrAirportLoading={this.props.isCtrlTwrAirportLoading}
                CtrlTwrAirportData={this.props.CtrlTwrAirportData}

                isCtrlTwrSeaportLoading={this.props.isCtrlTwrSeaportLoading}
                CtrlTwrSeaportData={this.props.CtrlTwrSeaportData}

                isCtrlTwrShipmentLoading={this.props.isCtrlTwrShipmentLoading}
                CtrlTwrShipmentData={this.props.CtrlTwrShipmentData}

                isCtrlTwrMilestoneRefLoading={this.props.isCtrlTwrMilestoneRefLoading}
                CtrlTwrMilestoneRefData={this.props.CtrlTwrMilestoneRefData}

                ctrlTwrShipmentLegsDataGet={this.props.ctrlTwrShipmentLegsDataGet}
                isCtrlTwrShipmentLegsDataLoading={this.props.isCtrlTwrShipmentLegsDataLoading}
                CtrlTwrShipmentLegsData={this.props.CtrlTwrShipmentLegsData}

                ctrlTwrShipmentLegsMilestonesGet={this.props.ctrlTwrShipmentLegsMilestonesGet}

                isCtrlTwrShipmentLegsMilestonesLoading={this.props.isCtrlTwrShipmentLegsMilestonesLoading}
                CtrlTwrShipmentLegsMilestonesData={this.props.CtrlTwrShipmentLegsMilestonesData}

                isCtrlTwrShipmentLegsCustomerRefLoading={this.props.isCtrlTwrShipmentLegsCustomerRefLoading}
                CtrlTwrShipmentLegsCustomerRefData={this.props.CtrlTwrShipmentLegsCustomerRefData}

                isCtrlTwrShipmentLegsPoLoading={this.props.isCtrlTwrShipmentLegsPoLoading}
                CtrlTwrShipmentLegsPoData={this.props.CtrlTwrShipmentLegsPoData}

                isCtrlTwrShipmentLegsHbolLoading={this.props.isCtrlTwrShipmentLegsHbolLoading}
                CtrlTwrShipmentLegsHbolData={this.props.CtrlTwrShipmentLegsHbolData}

                ctrlTwrShipmentLegsCustomerRefGet={this.props.ctrlTwrShipmentLegsCustomerRefGet}
                ctrlTwrShipmentLegsPoGet={this.props.ctrlTwrShipmentLegsPoGet}
                ctrlTwrShipmentLegsHbolGet={this.props.ctrlTwrShipmentLegsHbolGet}

                ctrlTwrShipmentFileUploadGet={this.props.ctrlTwrShipmentFileUploadGet}

                isCtrlTwrShipmentFileUploadLoading={this.props.isCtrlTwrShipmentFileUploadLoading}
                CtrlTwrShipmentFileUploadData={this.props.CtrlTwrShipmentFileUploadData}
                CtrlTwrShipmentFileUploadPostSuccessful={this.props.CtrlTwrShipmentFileUploadPostSuccessful}

                CtrlTwrShipmentUserSubscriptionPostSuccessful={this.props.CtrlTwrShipmentUserSubscriptionPostSuccessful}
                ctrlTwrShipmentUserSubscriptionPost={this.props.ctrlTwrShipmentUserSubscriptionPost}


                isCtrlTwrShipmentNotesPrivateLoading={this.props.isCtrlTwrShipmentNotesPrivateLoading}
                CtrlTwrShipmentNotesPrivateData={this.props.CtrlTwrShipmentNotesPrivateData}
            
                // CtrlTwrShipmentNotesPrivatePostSuccessful={this.props.CtrlTwrShipmentNotesPrivatePostSuccessful}
            
                isCtrlTwrShipmentNotesPublicLoading={this.props.isCtrlTwrShipmentNotesPublicLoading}
                CtrlTwrShipmentNotesPublicData={this.props.CtrlTwrShipmentNotesPublicData}
            
                // CtrlTwrShipmentNotesPublicPostSuccessful={this.props.CtrlTwrShipmentNotesPublicPostSuccessful}

                ctrlTwrShipmentPrivateNotesGet={this.props.ctrlTwrShipmentPrivateNotesGet}
                ctrlTwrShipmentPrivateNotesPost={this.props.ctrlTwrShipmentPrivateNotesPost}
                ctrlTwrShipmentPublicNotesGet={this.props.ctrlTwrShipmentPublicNotesGet}
                ctrlTwrShipmentPublicNotesPost={this.props.ctrlTwrShipmentPublicNotesPost}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentDetailsContainer));



