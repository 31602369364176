import { connect } from 'react-redux';
import React, { Component } from 'react';
import PackageDetails from './PackageDetails';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../redux/actions/actionsUser';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import * as visibilityActions from '../../../redux/actions/visibilityActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({
    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,
    

    isVisibilityPackageLoading: store.visibilityReducer.isVisibilityPackageLoading,
    VisibilityPackageData: store.visibilityReducer.VisibilityPackageData,

    isVisibilityPackageUpsEventsLoading: store.visibilityReducer.isVisibilityPackageUpsEventsLoading,
    VisibilityPackageUpsEventsData: store.visibilityReducer.VisibilityPackageUpsEventsData,

});

const mapDispatchToProps = dispatch =>
({
    visibilityPackageGet: (_email, _authToken, _PackageID) => {
        dispatch(visibilityActions.visibilityPackageGet(_email, _authToken, _PackageID))
    },
    visibilityPackageUpsEventsGet: (_email, _authToken, _PackageID) => {
        dispatch(visibilityActions.visibilityPackageUpsEventsGet(_email, _authToken, _PackageID))
    },

});

class PackageDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPackageID = formatStringForAPI(values.packageid)
        }
        catch (e) {
            var paramPackageID = null
        }

        this.props.visibilityPackageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPackageID)
        this.props.visibilityPackageUpsEventsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPackageID)
    }



    render() {

        return (

            <PackageDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                organizationSelection={this.props.organizationSelection}

                isVisibilityPackageLoading={this.props.isVisibilityPackageLoading}
                VisibilityPackageData={this.props.VisibilityPackageData}
            
                isVisibilityPackageUpsEventsLoading={this.props.isVisibilityPackageUpsEventsLoading}
                VisibilityPackageUpsEventsData={this.props.VisibilityPackageUpsEventsData}

            />



        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackageDetailsContainer));



