export const InventoryLotOrderOutboundSkuDataElements = [
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },


    {
        dBKey: 'UOM',
        displayName: 'UOM',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },

    {
        dBKey: 'Qty',
        displayName: 'Qty',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 80
    },

    {
        dBKey: 'LoadedQty',
        displayName: 'Loaded Qty',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },



    {
        dBKey: 'LotCode',
        displayName: 'Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },




    {
        dBKey: 'SerialNumber',
        displayName: 'Serial Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },





]


export default InventoryLotOrderOutboundSkuDataElements

