/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';



/* ################################### VAS ACTIONS TYPES ################################### */
export const ON_ERROR = 'ON_ERROR';


export const TRANS_CONSIGNEE_GET = 'TRANS_CONSIGNEE_GET';
export const TRANS_CONSIGNEE_COMPLETE = 'TRANS_CONSIGNEE_COMPLETE';
export const TRANS_CONSIGNEE_ERROR = 'TRANS_CONSIGNEE_ERROR';

export const TRANS_CUSTOMERREF_GET = 'TRANS_CUSTOMERREF_GET';
export const TRANS_CUSTOMERREF_COMPLETE = 'TRANS_CUSTOMERREF_COMPLETE';
export const TRANS_CUSTOMERREF_ERROR = 'TRANS_CUSTOMERREF_ERROR';

export const TRANS_PROJECT_GET = 'TRANS_PROJECT_GET';
export const TRANS_PROJECT_COMPLETE = 'TRANS_PROJECT_COMPLETE';
export const TRANS_PROJECT_ERROR = 'TRANS_PROJECT_ERROR';

export const TRANS_DESTINATION_GET = 'TRANS_DESTINATION_GET';
export const TRANS_DESTINATION_COMPLETE = 'TRANS_DESTINATION_COMPLETE';
export const TRANS_DESTINATION_ERROR = 'TRANS_DESTINATION_ERROR';

export const CONTAINER_GET = 'CONTAINER_GET';
export const CONTAINER_COMPLETE = 'CONTAINER_COMPLETE';
export const CONTAINER_ERROR = 'CONTAINER_ERROR';

export const POST_CONTAINER_GET = 'POST_CONTAINER_GET';
export const POST_CONTAINER_COMPLETE = 'POST_CONTAINER_COMPLETE';
export const POST_CONTAINER_ERROR = 'POST_CONTAINER_ERROR';

export const CONTAINER_JSON_TREE_GET = 'CONTAINER_JSON_TREE_GET';
export const CONTAINER_JSON_TREE_COMPLETE = 'CONTAINER_JSON_TREE_COMPLETE';
export const CONTAINER_JSON_TREE_ERROR = 'CONTAINER_JSON_TREE_ERROR';

export const TRANS_CONTAINER_HISTORY_GET = 'TRANS_CONTAINER_HISTORY_GET';
export const TRANS_CONTAINER_HISTORY_COMPLETE = 'TRANS_CONTAINER_HISTORY_COMPLETE';
export const TRANS_CONTAINER_HISTORY_ERROR = 'TRANS_CONTAINER_HISTORY_ERROR';

export const SHIPMENT_RECEIVE_GET = 'SHIPMENT_RECEIVE_GET';
export const SHIPMENT_RECEIVE_COMPLETE = 'SHIPMENT_RECEIVE_COMPLETE';
export const SHIPMENT_RECEIVE_ERROR = 'SHIPMENT_RECEIVE_ERROR';

export const POST_SHIPMENT_RECEIVE_GET = 'POST_SHIPMENT_RECEIVE_GET';
export const POST_SHIPMENT_RECEIVE_COMPLETE = 'POST_SHIPMENT_RECEIVE_COMPLETE';
export const POST_SHIPMENT_RECEIVE_ERROR = 'POST_SHIPMENT_RECEIVE_ERROR';

export const POST_MBOL_GET = 'POST_MBOL_GET';
export const POST_MBOL_COMPLETE = 'POST_MBOL_COMPLETE';
export const POST_MBOL_ERROR = 'POST_MBOL_ERROR';

export const POST_HBOL_GET = 'POST_HBOL_GET';
export const POST_HBOL_COMPLETE = 'POST_HBOL_COMPLETE';
export const POST_HBOL_ERROR = 'POST_HBOL_ERROR';

export const TRANS_LOAD_GET = 'TRANS_LOAD_GET';
export const TRANS_LOAD_COMPLETE = 'TRANS_LOAD_COMPLETE';
export const TRANS_LOAD_ERROR = 'TRANS_LOAD_ERROR';

export const TRANS_POST_LOAD_GET = 'TRANS_POST_LOAD_GET';
export const TRANS_POST_LOAD_COMPLETE = 'TRANS_POST_LOAD_COMPLETE';
export const TRANS_POST_LOAD_ERROR = 'TRANS_POST_LOAD_ERROR';

export const TRANS_POST_LOAD_REMOVE_GET = 'TRANS_POST_LOAD_REMOVE_GET';
export const TRANS_POST_LOAD_REMOVE_COMPLETE = 'TRANS_POST_LOAD_REMOVE_COMPLETE';
export const TRANS_POST_LOAD_REMOVE_ERROR = 'TRANS_POST_LOAD_REMOVE_ERROR';

export const TRANS_LOAD_PO_GET = 'TRANS_LOAD_PO_GET';
export const TRANS_LOAD_PO_COMPLETE = 'TRANS_LOAD_PO_COMPLETE';
export const TRANS_LOAD_PO_ERROR = 'TRANS_LOAD_PO_ERROR';

export const TRANS_LOAD_PO_HISTORY_GET = 'TRANS_LOAD_PO_HISTORY_GET';
export const TRANS_LOAD_PO_HISTORY_COMPLETE = 'TRANS_LOAD_PO_HISTORY_COMPLETE';
export const TRANS_LOAD_PO_HISTORY_ERROR = 'TRANS_LOAD_PO_HISTORY_ERROR';

export const TRANS_OUTBOUND_GET = 'TRANS_OUTBOUND_GET';
export const TRANS_OUTBOUND_COMPLETE = 'TRANS_OUTBOUND_COMPLETE';
export const TRANS_OUTBOUND_ERROR = 'TRANS_OUTBOUND_ERROR';

export const PO_GET = 'PO_GET';
export const PO_COMPLETE = 'PO_COMPLETE';
export const PO_ERROR = 'PO_ERROR';

export const POST_PO_GET = 'POST_PO_GET';
export const POST_PO_COMPLETE = 'POST_PO_COMPLETE';
export const POST_PO_ERROR = 'POST_PO_ERROR';

export const TRANS_PO_LOCATION_GET = 'TRANS_PO_LOCATION_GET';
export const TRANS_PO_LOCATION_COMPLETE = 'TRANS_PO_LOCATION_COMPLETE';
export const TRANS_PO_LOCATION_ERROR = 'TRANS_PO_LOCATION_ERROR';

export const TRANS_POST_PO_LOCATION_GET = 'TRANS_POST_PO_LOCATION_GET';
export const TRANS_POST_PO_LOCATION_COMPLETE = 'TRANS_POST_PO_LOCATION_COMPLETE';
export const TRANS_POST_PO_LOCATION_ERROR = 'TRANS_POST_PO_LOCATION_ERROR';

export const TRANS_PO_LOAD_LOCATION_HISTORY_GET = 'TRANS_PO_LOAD_LOCATION_HISTORY_GET';
export const TRANS_PO_LOAD_LOCATION_HISTORY_COMPLETE = 'TRANS_PO_LOAD_LOCATION_HISTORY_COMPLETE';
export const TRANS_PO_LOAD_LOCATION_HISTORY_ERROR = 'TRANS_PO_LOAD_LOCATION_HISTORY_ERROR';

export const PO_RECOVERY_GET = 'PO_RECOVERY_GET';
export const PO_RECOVERY_COMPLETE = 'PO_RECOVERY_COMPLETE';
export const PO_RECOVERY_ERROR = 'PO_RECOVERY_ERROR';

export const TRANS_PO_HISTORY_GET = 'TRANS_PO_HISTORY_GET';
export const TRANS_PO_HISTORY_COMPLETE = 'TRANS_PO_HISTORY_COMPLETE';
export const TRANS_PO_HISTORY_ERROR = 'TRANS_PO_HISTORY_ERROR';


export const TRANS_CONTAINER_FILEUPLOAD_GET = 'TRANS_CONTAINER_FILEUPLOAD_GET';
export const TRANS_CONTAINER_FILEUPLOAD_COMPLETE = 'TRANS_CONTAINER_FILEUPLOAD_COMPLETE';
export const TRANS_CONTAINER_FILEUPLOAD_ERROR = 'TRANS_CONTAINER_FILEUPLOAD_ERROR';

export const TRANS_POST_CONTAINER_FILEUPLOAD_GET = 'TRANS_POST_CONTAINER_FILEUPLOAD_GET';
export const TRANS_POST_CONTAINER_FILEUPLOAD_COMPLETE = 'TRANS_POST_CONTAINER_FILEUPLOAD_COMPLETE';
export const TRANS_POST_CONTAINER_FILEUPLOAD_ERROR = 'TRANS_POST_CONTAINER_FILEUPLOAD_ERROR';


