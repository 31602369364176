import { connect } from 'react-redux';
import React, { Component } from 'react';
import Load from './Load'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import { queryStringParse, formatStringForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isLoadLoading: store.transReducer.isLoadLoading,
    LoadData: store.transReducer.LoadData,

    isLoadPoLoading: store.transReducer.isLoadPoLoading,
    LoadPoData: store.transReducer.LoadPoData,

    LoadPostSuccessful: store.transReducer.LoadPostSuccessful,

});

const mapDispatchToProps = dispatch =>
({

    transLoadGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _MbolID, _HbolID, _PoID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal) => {
        dispatch(actions.transLoadGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _MbolID, _HbolID, _PoID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal))
    },
    
    transPoGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID) => {
        dispatch(actions.transPoGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID))
    },

    transLoadPost: (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _Destination, _LoadStatusID, _LoadPoData, _LocationPoData, _PoID, _PCS, _Pallets, _WarehouseID, _CustomerID) => {
        dispatch(actions.transLoadPost(_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _Destination, _LoadStatusID, _LoadPoData, _LocationPoData, _PoID, _PCS, _Pallets, _WarehouseID, _CustomerID))
    },

    transLoadPoGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID) => {
        dispatch(actions.transLoadPoGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID))
    },
});

class LoadContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
        // this.props.userWarehousesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.LoadPostSuccessful !== this.props.LoadPostSuccessful && this.props.LoadPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramLoadStatus = queryStringParse(values.loadstatus)
        }
        catch (e) {
            var paramLoadStatus = null
        }

        this.props.transLoadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramLoadStatus, null, null, null, null, null, null, null, null, null, null, null, null, null)

    }



    render() {

        return (

            <Load
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                
                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}

                transPoGet={this.props.transPoGet}

                isLoadPoLoading={this.props.isLoadPoLoading}
                LoadPoData={this.props.LoadPoData}
                transLoadPost={this.props.transLoadPost}

                LoadPostSuccessful={this.props.LoadPostSuccessful}

                transLoadPoGet={this.props.transLoadPoGet}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadContainer));



