import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    List,
    Button,
    ButtonToolbar,
    ButtonGroup,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Divider,
    IconButton
} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask } from '../../../../resources/logic/inputMasks';
import RButtonSmall from '../../../componentsGeneral/Rsuite/Button/RButtonSmall';
import * as moment from 'moment'
import { convertNanToZero } from '../../../../resources/logic/functions';
import CloseIcon from '@rsuite/icons/Close';
import debounceClick from '../../../../resources/logic/useability';
import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import InventoryLoadDataElements from '../../../dataComponentsInventory/InventoryLoadDataElements';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const tableIdCol = "LoadID"

const styles = {
    width: '100%',
    overflowX: 'hidden',
};

function limitNumberToExistingUnitsValue(e, locationitem, item, originalProps) {
    var itemIndex = originalProps[item.SkuID].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === locationitem['SkuWarehouseLocationID']);

    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)

    try {
        var limitValue = originalProps[item.SkuID][itemIndex]['Units']
    }
    catch (e) {
        var limitValue = 0
    }

    if (nonNegativeValue <= limitValue) {
        return nonNegativeValue
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}

function limitNumberToExistingValue(e, locationitem, item, originalProps) {
    var itemIndex = originalProps[item.SkuID].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === locationitem['SkuWarehouseLocationID']);

    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)

    try {
        var limitValue = originalProps[item.SkuID][itemIndex]['Cases']
    }
    catch (e) {
        var limitValue = 0
    }

    if (nonNegativeValue <= limitValue) {
        return nonNegativeValue
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}

// function limitNumberToExistingValue(e, item) {
//     var nonNegativeValue
//     nonNegativeValue = numbersInputMask(e)

//     try {
//         var limitValue = item['CasesReceived'] - item['CasesLoaded']
//     }
//     catch (e) {
//         var limitValue = 0
//     }

//     if (nonNegativeValue <= limitValue) {
//         return nonNegativeValue
//     }
//     else {
//         window.alert('Must not be greater than ' + limitValue.toString())
//         return limitValue
//     }
//     // var newString = null

//     // if (inputString == null || typeof (inputString) == 'undefined') {

//     // }
//     // else {
//     //     var stripString = inputString.trim().replace(/[|&;$%"'`\[\]#!^*~<>()+=]/g, '') //Add unwanted characters inside []
//     //     var newString = "'" + stripString + "'"
//     // }
//     // return e.target.value
// }

// style={{ overflowX: 'hidden!important' }} 

const mountRef = React.createRef();

const AddToExistingLoadDrawer = ({ onClickSelectExistingLoad, onChange, show, placement, confirm, close, remove, state, handleSortColumn, sortColumn, sortType, ...props }) => (


    // if (props.InventoryRecoveryPostLoading) {
    //     return (
    //         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    //             <Loader
    //                 type="TailSpin"
    //                 color="#3396E5"
    //                 height="50"
    //                 width="50"
    //             />
    //             <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, receiving...</p>
    //         </div>
    //     );
    // }



    <Drawer show={state.showAddToExistingLoadDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '850px' }}    >
        <Drawer.Header>
            <Drawer.Title>Add To Existing Load</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ overflowX: 'hidden!important' }}> <div ref={mountRef}>
            <div>

                <Panel header={<h4>Existing Loads</h4>} bordered style={{ marginBottom: '15px' }}>
                    <Col md={24} sm={24}>
                        {RTable(
                            props.InventoryLoadLotData,        // tableData,
                            InventoryLoadDataElements,  // DataElements,
                            sortColumn,                 // sortColumn,
                            sortType,                   // sortType,
                            props.isInventoryLoadLotLoading,         // loading,
                            handleSortColumn,      // handleSortColumn,
                            null,        // onClickDetails,
                            false, // checkColEntry,
                            // true,                      // checkColEntry,
                            tableIdCol,                 // keyColumn,
                            null,                       // checkedKeys,
                            null,                       // handleCheckAll,
                            null,                       // handleCheck,
                            true,                      // boolSelectButton,
                            onClickSelectExistingLoad,     // onClickSelect,
                            'Select',                       // selectButtonText,
                            props.isMobile,        // isMobile,
                            props.history,         // history,
                            state.ExistingLoadID,                        // highlightRowKey
                            false,                            // editLink,
                            null,                            // editLinkText,
                            null                            // editLinkOnClick
                        )}
                    </Col>
                </Panel>

                {state.ExistingLoadID == null ?
                    <div></div> :
                    <Panel bordered style={{ marginBottom: '10px' }} header={
                        <div >
                            <h4>Adding To Existing Load:</h4>
                        </div>
                    }>
                        <List>
                            Trailer: <strong>{state.existingLoadData.Trailer}</strong>
                            <br />
                            Carrier: <strong>{state.existingLoadData.Carrier}</strong>
                            <br />
                            BOL: <strong>{state.existingLoadData.BOL}</strong>
                            <br />
                            Seal: <strong>{state.existingLoadData.Seal}</strong>
                            <br />
                            Pickup Date: <strong>{state.existingLoadData.PickupDate}</strong>
                            <br />
                            Delivery Date: <strong>{state.existingLoadData.DeliveryDate}</strong>
                            <br />
                            Destination: <strong>{state.existingLoadData.Destination}</strong>
                            <br />
                            Customer: <strong>{state.existingLoadData.CustomerName}</strong>
                            <br />
                            Warehouse: <strong>{state.existingLoadData.WarehouseName}</strong>
                            <br />
                        </List>
                    </Panel>
                }

                <Panel bordered style={{ marginBottom: '15px' }}>

                    <Row >
                        <Col md={12} sm={24}>
                            <ButtonToolbar >
                                <DebouncedButton onClick={confirm} buttonText="Save" block={true} />
                            </ButtonToolbar>
                        </Col>
                        <Col md={12} sm={24}>
                            <ButtonToolbar >
                                <Button block onClick={close} appearance="default">Cancel</Button>
                            </ButtonToolbar>
                        </Col>
                    </Row>

                </Panel>



                {state.AddToExistingLoadDict.map(item => (

                    state.reportNav === 'pallet' ?

                        <Panel bordered style={{ marginBottom: '10px' }}>

                            <Row>
                                <Col md={14} sm={24}>
                                    Pallet Barcode: <strong>{item.PalletBarcode}</strong>
                                    <br />
                                    Warehouse Location: <strong>{item.WarehouseLocationName}</strong>
                                </Col>

                                <Col md={6} sm={24}>

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Total Cases:</InputGroup.Addon>
                                        <InputNumber readOnly value={item.CasesInWhse} className={'custom-input-number'} scrollable={false} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Total Units:</InputGroup.Addon>
                                        <InputNumber readOnly value={item.UnitsInWhse} className={'custom-input-number'} scrollable={false} />
                                    </InputGroup>

                                </Col>
                                <Col md={4} sm={24}>
                                    <Button style={{ float: 'right' }} onClick={(e) => { remove(e, item) }} appearance="primary">Remove</Button>
                                    <br />
                                </Col>
                            </Row>

                        </Panel>


                        :
                        state.reportNav === 'case' ?

                            <Panel bordered style={{ marginBottom: '10px' }}>

                                <Row>
                                    <Col md={14} sm={24}>
                                        Case Barcode: <strong>{item.CaseBarcode}</strong>
                                        <br />
                                        Pallet Barcode: <strong>{item.PalletBarcode}</strong>
                                        <br />
                                        Warehouse Location: <strong>{item.WarehouseLocationName}</strong>
                                    </Col>

                                    <Col md={6} sm={24}>

                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Total Units:</InputGroup.Addon>
                                            <InputNumber readOnly value={item.UnitsInWhse} className={'custom-input-number'} scrollable={false} />
                                        </InputGroup>

                                    </Col>
                                    <Col md={4} sm={24}>
                                        <Button style={{ float: 'right' }} onClick={(e) => { remove(e, item) }} appearance="primary">Remove</Button>
                                        <br />
                                    </Col>
                                </Row>

                            </Panel>


                            :


                            <Panel bordered style={{ marginBottom: '10px' }}>

                                <Row>
                                    <Col md={14} sm={24}>
                                        SKU: <strong>{item.SKU}</strong>
                                        <br />
                                        Case Barcode: <strong>{item.CaseBarcode}</strong>
                                        <br />
                                        Pallet Barcode: <strong>{item.PalletBarcode}</strong>
                                        <br />
                                        Warehouse Location: <strong>{item.WarehouseLocationName}</strong>
                                    </Col>

                                    <Col md={6} sm={24}>

                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Units:</InputGroup.Addon>
                                            <InputNumber onChange={(e) => onChange(e, item, 'UnitsInWhse')} value={item.UnitsInWhse} className={'custom-input-number'} scrollable={false} />
                                        </InputGroup>

                                    </Col>
                                    <Col md={4} sm={24}>
                                        <Button style={{ float: 'right' }} onClick={(e) => { remove(e, item) }} appearance="primary">Remove</Button>
                                        <br />
                                    </Col>
                                </Row>

                            </Panel>


                ))}



            </div>

        </div>

        </Drawer.Body>
        <Drawer.Footer>

            <br />

        </Drawer.Footer>
    </Drawer>
);


AddToExistingLoadDrawer.defaultProps = {
    show: false
};

export default AddToExistingLoadDrawer;



