import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../../components/componentsGeneral/AnimatedView/AnimatedView';
import InboundContainer from '../../../../components/componentsInventoryManagement/_Lot_Tracking_Components/Inbound_Lot/InboundContainer';
import { Message } from 'rsuite';
import { lotTrackingWarehouseIDs } from '../../../../app/appConfig';

class invManInboundLotView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    componentDidMount() {

    }

    render() {

        if (!lotTrackingWarehouseIDs.includes(this.props.warehouseSelection)) {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please only select Lot Tracking warehouses" /></AnimatedView>;
        }

        return (
            <AnimatedView>
                <InboundContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default invManInboundLotView;
