import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Tree } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventorySkuWarehouseLocationDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseLocationDataElements';
import queryString from 'query-string';
import { queryStringParse, replaceNegativeWithZero, isNumeric, formatStringForAPI, uniqueArray, convertNanToZero, formatTrueFalseToBit, formatNumberForAPI, formatAllTextNumbersSymbolsForAPI } from '../../../../resources/logic/functions';
import InventoryLoadSkuDetailsDataElements from '../../../dataComponentsInventory/InventoryLoadSkuDetailsDataElements';
import EditSingleWarehouseLocationsDrawer from './EditSingleWarehouseLocationsDrawer';
import EditSkuDetailsDrawer from './EditSkuDetailsDrawer';

const tableIdCol = "SkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class SkuDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Edit Warehouse Location
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,


            //Edit Po Values
            showEditSkuDetailsDrawer: false,
            unsavedEditSkuValues: false,
            // Po: null,
            CustomerRef: null,
            SKU: null,
            Project: null,
            // ExpectedCases: null,
            // ExpectedPallets: null,
            Notes: null,
        };


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        // Warehouse Location Details
        this.onClickWarehouseLocationDetails = this.onClickWarehouseLocationDetails.bind(this);

        this.onClickLoadDetails = this.onClickLoadDetails.bind(this);




        //Edit Warehouse Location
        this.onclickEditWarehouseLocation = this.onclickEditWarehouseLocation.bind(this);
        this.closeEditSingleWarehouseLocationsDrawer = this.closeEditSingleWarehouseLocationsDrawer.bind(this);
        this.setStateCloseEditSingleWarehouseLocationsDrawer = this.setStateCloseEditSingleWarehouseLocationsDrawer.bind(this);
        // this.onClickAddLocation = this.onClickAddLocation.bind(this);
        // this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickSaveEditWarehouseLocation = this.onClickSaveEditWarehouseLocation.bind(this);


        //Edit Po Drawer
        this.closeEditSkuDrawer = this.closeEditSkuDrawer.bind(this);
        this.onClickConfirmEditSku = this.onClickConfirmEditSku.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)

        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this)
        this.onChangeSKU = this.onChangeSKU.bind(this)
        this.onChangeProject = this.onChangeProject.bind(this)
        this.onChangeNotes = this.onChangeNotes.bind(this)
    }




    componentDidMount() {
        this.loadSortFromStorage()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


        if (prevProps.InventorySkuData !== this.props.InventorySkuData) {
            try{
                var skuWarehouseID = this.props.InventorySkuData[0].WarehouseID
                var skuCustomerID = this.props.InventorySkuData[0].CustomerID

                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
            }
            catch(e){}
        }


        if (prevProps.InventorySkuPostSuccessful !== this.props.InventorySkuPostSuccessful && this.props.InventorySkuPostSuccessful) {
            this.setStateCloseAddNewDrawer()
        }
    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    //Table buttons
    onClickWarehouseLocationDetails(e, rowData, history) {
        try {
            var paramID = rowData["WarehouseLocationID"]
            var paramWarehouseID = rowData["WarehouseID"]
            history.push({
                pathname: 'warehouselocationdetails',
                search: 'warehouselocationid=' + paramID + '&' +
                    'warehouseid=' + paramWarehouseID
            });
        }
        catch (e) {
        }
    }



    //On Click Details
    onClickLoadDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'loaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }
    }






    //Warehouse Location Drawer Buttons
    onclickEditWarehouseLocation(e, rowData, history) {
        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.WarehouseID, rowData.CustomerID);

        var origRowOversize = rowData['Oversize']

        this.setState({
            showEditSingleWarehouseLocationsDrawer: true,
            unsavedEditWarehouseLocationValues: false,

            LocationToEditDict: rowData,
            LocationOriginalDict: rowData,
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: origRowOversize
        });

    }





    // check if same location but oversize
    onChangeEditLocationDrawer = (event, label) => {
        if (label === 'newWarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === event) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newWarehouseLocationID: event,
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newWarehouseLocationID: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else if (label === 'newOversize') {
            if (this.state.LocationOriginalDict['WarehouseLocationID'] === this.state.newWarehouseLocationID) {
                if (this.state.LocationOriginalDict['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                this.setState({
                    newOversize: newOversize,
                    unsavedEditWarehouseLocationValues: true
                });
            }
            else {
                this.setState({
                    newOversize: event,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else {
            this.setState({
                [label]: event,
                unsavedEditWarehouseLocationValues: true
            });
        }
        this.forceUpdate()
    };


    // handleAddNewLocation = (newLocationDict) => {
    //     this.setState({
    //         unsavedEditWarehouseLocationValues: true,
    //         LocationDict: newLocationDict
    //     }, () => {
    //         // Do an API call with this.state.searchTerm
    //     });
    // }

    // onClickAddLocation = (event) => {
    //     //Check if any Warehouse names are null
    //     if (this.state.LocationDict[this.state.LocationToEditDict.SkuID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
    //         window.alert('Please enter all location values first.')
    //     }
    //     else {
    //         //save warehouse name
    //         var currentLocationDict = this.state.LocationDict

    //         currentLocationDict[this.state.LocationToEditDict.SkuID].push({ 'WarehouseLocationID': null, 'Units': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': null })

    //         this.handleAddNewLocation(currentLocationDict)

    //         this.forceUpdate()
    //     }
    // }



    onClickSaveEditWarehouseLocation(e) {

        try {
            var warehouseID = this.state.LocationOriginalDict.WarehouseID
            var customerID = this.state.LocationOriginalDict.CustomerID

            var skuID = this.state.LocationOriginalDict.SkuID

            var originalLocationWarehouseID = formatNumberForAPI(this.state.LocationOriginalDict.WarehouseLocationID)
            var originalLocationSkuWarehouseLocID = formatNumberForAPI(this.state.LocationOriginalDict.SkuWarehouseLocationID)
            var originalLocationUnits = convertNanToZero(this.state.LocationOriginalDict.Units) - convertNanToZero(this.state.newUnits)
            var originalLocationCases = convertNanToZero(this.state.LocationOriginalDict.Cases) - convertNanToZero(this.state.newCases)
            var originalLocationPallets = convertNanToZero(this.state.LocationOriginalDict.Pallets) - convertNanToZero(this.state.newPallets)
            var originalLocationOversize = formatTrueFalseToBit(this.state.LocationOriginalDict.Oversize)

            var newLocationID = formatNumberForAPI(this.state.newWarehouseLocationID)
            var newLocationOversize = formatTrueFalseToBit(this.state.newOversize)
            var newLocationUnits = convertNanToZero(this.state.newUnits)
            var newLocationCases = convertNanToZero(this.state.newCases)
            var newLocationPallets = convertNanToZero(this.state.newPallets)

            if ((newLocationUnits === null || newLocationUnits == 0) && (newLocationCases === null || newLocationCases == 0) && (newLocationPallets === null || newLocationPallets == 0)) {
                window.alert('Please select Units, Cases, Pallets to move.')
            }
            else if (newLocationID === null) {
                window.alert('Please select new location.')
            }
            else {
                try {
                    // call Inventory_SKU_Warehouse_Location_Post twice
                    // first time call with old location and send new totals for old location
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        originalLocationSkuWarehouseLocID,
                        originalLocationWarehouseID,
                        convertNanToZero(originalLocationUnits),
                        convertNanToZero(originalLocationCases),
                        convertNanToZero(originalLocationPallets),
                        originalLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )
                    // second time call with new location and new counts
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        null,
                        newLocationID,
                        convertNanToZero(newLocationUnits),
                        convertNanToZero(newLocationCases),
                        convertNanToZero(newLocationPallets),
                        newLocationOversize,
                        originalLocationWarehouseID,
                        warehouseID,
                        customerID,
                        null,
                        null,
                        null,
                        null
                    )

                    this.setStateCloseEditSingleWarehouseLocationsDrawer()
                }
                catch (e) { }
            }
        }
        catch (e) {

        }


    }
    closeEditSingleWarehouseLocationsDrawer() {
        if (this.state.unsavedEditWarehouseLocationValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditSingleWarehouseLocationsDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseEditSingleWarehouseLocationsDrawer()
        }
    }
    setStateCloseEditSingleWarehouseLocationsDrawer() {
        this.setState({
            showEditSingleWarehouseLocationsDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationOriginalDict: {},
            LocationToEditDict: {},
            newWarehouseLocationID: null,
            newUnits: 0,
            newCases: 0,
            newPallets: 0,
            newOversize: 0,

        });
    }





    //Edit Po Drawer
    onClickEditSku() {
        this.openEditSku()
        this.setState({
            // Po: this.props.InventorySkuData[0].PO,

            CustomerRef: this.props.InventorySkuData[0].CustomerRefID,
            SKU: this.props.InventorySkuData[0].SKU,
            Project: this.props.InventorySkuData[0].ProjectID,
            // ExpectedCases: this.props.InventorySkuData[0].CaseCount,
            // ExpectedPallets: this.props.InventorySkuData[0].PalletCount,
            Notes: this.props.InventorySkuData[0].Notes
        });
    }
    openEditSku(placement) {
        this.setState({
            placement,
            showEditSkuDetailsDrawer: true,
            // unsavedEditSkuValues: true,
        });
    }

    onChangeNotes = (e) => {
        this.setState({
            unsavedEditSkuValues: true,
            Notes: e
        });
    }

    // onChangeCustomerRef = (e, value, item) => {
    //     this.setState({
    //         CustomerRef: e
    //     });
    // }
    // onChangeProject = (e, value, item) => {
    //     this.setState({
    //         Project: e
    //     });
    // }
    onChangeSKU = (e) => {
        this.setState({
            unsavedEditSkuValues: true,
            SKU: e
        });
    }


    // onChangeEditPo = (event, field) => {
    //     var dict = {};
    //     dict[field] = event;
    //     this.setState(
    //         dict
    //     );
    //     this.setState({
    //         unsavedEditSkuValues: true,
    //     });
    // };

    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            unsavedEditSkuValues: true,
            CustomerRef: e
        });
    }

    onChangeProject = (e, value, item) => {
        this.setState({
            unsavedEditSkuValues: true,
            Project: e
        });
    }

    onClickConfirmEditSku(e) {

        this.props.inventorySkuPost(
            this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventorySkuData[0].SkuID, formatStringForAPI(this.state.SKU), null, null, null, null, null, formatStringForAPI(this.state.CustomerRef), null, formatStringForAPI(this.state.Project), formatAllTextNumbersSymbolsForAPI(this.state.Notes)
        )


    }
    closeEditSkuDrawer() {
        if (this.state.unsavedEditSkuValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showEditSkuDetailsDrawer: false,
            unsavedEditSkuValues: false,

            Po: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,
        });
    }


    render() {
        const { sortColumn, sortType } = this.state;

        let skuDetailsDiv;
        let pageHeader;
        if (this.props.isInventorySkuLoading) {
            pageHeader = <div>Inventory Management - SKU</div>

            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }
        else if (typeof (this.props.InventorySkuData[0]) !== 'undefined' && this.props.InventorySkuData != null) {
            try {
                var skuName = this.props.InventorySkuData[0].SKU.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - SKU: ' + skuName} </div>
            }
            catch (e) {
                pageHeader = <div>Inventory Management - SKU</div>
            }

            skuDetailsDiv =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div style={{ float: 'right' }}>
                                    <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditSku(e) }} >Edit</Button>
                                </div>
                            }>
                                Warehouse: <strong>{this.props.InventorySkuData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{this.props.InventorySkuData[0].CustomerName}</strong>
                                <br />
                                Customer Ref: <strong>{this.props.InventorySkuData[0].CustomerRef}</strong>
                                <br />
                                Project: <strong>{this.props.InventorySkuData[0].Project}</strong>
                                <br />
                                Status: <strong>{this.props.InventorySkuData[0].SkuStatusName}</strong>
                                <br />
                                Notes: <strong>{this.props.InventorySkuData[0].Notes}</strong>
                                <br />
                            </Panel>
                        </Col>
                    </Row>
                    <Row>



                        {/* <Col md={6} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Expected: <strong>{this.props.InventorySkuData[0].UnitsExpected}</strong>
                                <br />
                                Cases Expected: <strong>{this.props.InventorySkuData[0].CasesExpected}</strong>
                                <br />
                                Pallets Expected: <strong>{this.props.InventorySkuData[0].PalletsExpected}</strong>
                                <br />
                            </Panel>
                        </Col> */}
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Received: <strong>{this.props.InventorySkuData[0].UnitsReceived}</strong>
                                <br />
                                Cases Received: <strong>{this.props.InventorySkuData[0].CasesReceived}</strong>
                                <br />
                                Pallets Received: <strong>{this.props.InventorySkuData[0].PalletsReceived}</strong>
                                <br />
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units In Whse: <strong>{this.props.InventorySkuData[0].Units}</strong>
                                <br />
                                Cases In Whse: <strong>{this.props.InventorySkuData[0].Cases}</strong>
                                <br />
                                Pallets In Whse: <strong>{this.props.InventorySkuData[0].Pallets}</strong>
                                <br />
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Shipped: <strong>{this.props.InventorySkuData[0].UnitsLoaded}</strong>
                                <br />
                                Cases Shipped: <strong>{this.props.InventorySkuData[0].CasesLoaded}</strong>
                                <br />
                                Pallets Shipped: <strong>{this.props.InventorySkuData[0].PalletsLoaded}</strong>
                                <br />
                            </Panel>
                        </Col>

                    </Row>
                </div>



        }
        else {
            pageHeader = <div>Inventory Management - SKU</div>
            skuDetailsDiv = <div></div>
        }


        let shipmentPoTable;
        let tableDataDiv;
        if (this.props.isInventorySkuWarehouseLocationLoading) {
            shipmentPoTable = <div></div>
            tableDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationData) !== 'undefined' && this.props.InventorySkuWarehouseLocationData != null) {
            shipmentPoTable = RTableGeneral(
                "SkuWarehouseLocationID",     //   keyColumn,
                this.props.InventorySkuWarehouseLocationData,     //   tableData,
                InventorySkuWarehouseLocationDataElements,       //   DataElements,
                this.props.isInventorySkuWarehouseLocationLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickWarehouseLocationDetails,     //   link1onClick,

                true,       //   link2bool,
                'Edit',     //   link2text,
                this.onclickEditWarehouseLocation,     //   link2onClick,
            )

            tableDataDiv = <Row>
                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>
                    <ExcelSheet data={this.props.InventorySkuWarehouseLocationData} name="Warehouse">
                        {
                            InventorySkuWarehouseLocationDataElements.map(
                                ({ dBKey, displayName }, idx) => {
                                    return (
                                        <ExcelColumn label={displayName} value={dBKey} />
                                    );
                                }
                            )
                        }
                    </ExcelSheet>
                </ExcelFile>
            </Row>
        }
        else {
            shipmentPoTable = <div></div>
            tableDataDiv = <div></div>
        }







        let skuRecoveryTimeline;
        if (this.props.isInventorySkuRecoveryHistoryLoading) {
            skuRecoveryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuRecoveryHistoryData[0]) !== 'undefined' && this.props.InventorySkuRecoveryHistoryData != null) {
            skuRecoveryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="SKU Recovery History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventorySkuRecoveryHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.SkuRecoveryDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l></l>
                                            {/* {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '} */}
                                            {item.Container != null ? <l><br /> Received From Container:  {item.Container} </l> : ' '}
                                            {item.UnitsReceived != null ? <l><br /> Units Received:  {item.UnitsReceived} </l> : ' '}
                                            {item.CasesReceived != null ? <l><br /> Cases Received:  {item.CasesReceived} </l> : ' '}
                                            {item.PalletsReceived != null ? <l><br /> Pallets Received:  {item.PalletsReceived} </l> : ' '}
                                            {/* {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '} */}
                                            {item.SkuDescription != null ? <l><br /> Description:  {item.SkuDescription} </l> : ' '}

                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            skuRecoveryTimeline = <div></div>
        }



        let skuWarehouseLocationHistoryTimeline;
        if (this.props.isInventorySkuWarehouseLocationHistoryLoading) {
            skuWarehouseLocationHistoryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationHistoryData[0]) !== 'undefined' && this.props.InventorySkuWarehouseLocationHistoryData != null) {
            skuWarehouseLocationHistoryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="Warehouse Location History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventorySkuWarehouseLocationHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.CreatedDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l><br />{item.Notes} &nbsp;
                                            </l>
                                            {item.WarehouseLocationName != null ? <l><br /> Warehouse Location:  {item.WarehouseLocationName} </l> : ' '}
                                            {item.PreviousWarehouseLocationName != null ? <l><br /> Previous Warehouse Location:  {item.PreviousWarehouseLocationName} </l> : ' '}
                                            {item.Units != null ? <l><br /> Units:  {item.Units} </l> : ' '}
                                            {item.Cases != null ? <l><br /> Cases:  {item.Cases} </l> : ' '}
                                            {item.Pallets != null ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                            {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '}

                                            
                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            skuWarehouseLocationHistoryTimeline = <div></div>
        }




        let loadDiv;
        let rTable;
        if (this.props.isInventoryLoadSkuLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadSkuData) !== 'undefined' && this.props.InventoryLoadSkuData != null) {

            if (this.props.InventoryLoadSkuData.length === 0) {
                rTable = <div></div>
                loadDiv = <div></div>
            }
            else {

                // let rTable;
                rTable = RTableGeneral(
                    'LoadSkuID',     //   keyColumn,
                    this.props.InventoryLoadSkuData,     //   tableData,
                    InventoryLoadSkuDetailsDataElements,       //   DataElements,
                    this.props.isInventoryLoadSkuLoading,        //   loading,

                    this.props.isMobile,        //   isMobile,
                    this.props.history,     //   history,

                    sortColumn,     //   sortColumn,
                    sortType,       //   sortType,
                    this.handleSortColumn,      //   handleSortColumn,

                    false, // checkColEntry,
                    null,       //   checkedKeys,
                    null,       //   handleCheckAll,
                    null,       //   handleCheck,

                    false,      //   boolSelectButton,
                    null,       //   onClickSelect,
                    null,       //   selectButtonText,
                    null,       //   selectHighlightRowKey,

                    true,       //   link1bool,
                    'Details',     //   link1text,
                    this.onClickLoadDetails,     //   link1onClick,

                    false,       //   link2bool,
                    null,       //   link2text,
                    null  //   link2onClick
                )

                loadDiv = <div>


                    {(this.props.isMobile == true) ? <div></div> :
                        <div>
                            <Row>
                                <ExcelFile element={<Button >Export Excel</Button>} filename={'SKU_' + currentDateTime}>
                                    <ExcelSheet data={this.props.InventoryLoadSkuData} name="SKU">

                                        {
                                            InventoryLoadSkuDetailsDataElements.map(
                                                ({ dBKey, displayName }, idx) => {
                                                    return (
                                                        <ExcelColumn label={displayName} value={dBKey} />
                                                    );
                                                }
                                            )
                                        }

                                    </ExcelSheet>
                                </ExcelFile>
                            </Row>
                        </div>
                    }

                </div>
            }
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }



        let editWarehouseLocationDrawerDiv;
        let unsavedLocationChanges
        if (!this.state.showEditSingleWarehouseLocationsDrawer) {
            editWarehouseLocationDrawerDiv = <div></div>
            unsavedLocationChanges = false
        }
        else {
            unsavedLocationChanges = this.state.unsavedEditWarehouseLocationValues
            editWarehouseLocationDrawerDiv = <EditSingleWarehouseLocationsDrawer {...this.props} state={this.state} onChangeLocation={this.onChangeEditLocationDrawer} onClickAddLocation={this.onClickAddLocation} confirm={this.onClickSaveEditWarehouseLocation} close={this.closeEditSingleWarehouseLocationsDrawer} unsavedChanges={unsavedLocationChanges} />
        }




        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>
                    {skuDetailsDiv}
                </PanelGroup>







                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Warehouse Locations" style={{ marginBottom: 10 }}>
                            {tableDataDiv}
                            {shipmentPoTable}
                        </Panel>
                    </Col>
                </Row>


                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Loads" style={{ marginBottom: 10 }}>
                            {loadDiv}
                            {rTable}
                        </Panel>
                    </Col>
                </Row>


                {skuRecoveryTimeline}
                {skuWarehouseLocationHistoryTimeline}


                {editWarehouseLocationDrawerDiv}

                <EditSkuDetailsDrawer {...this.props} state={this.state} onChangeProject={this.onChangeProject} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeSKU={this.onChangeSKU} confirm={this.onClickConfirmEditSku} close={this.closeEditSkuDrawer} unsavedChanges={this.state.unsavedEditSkuValues} />

            </div>
        );

    }
}

export default withRouter(SkuDetail);
