import React, {
    PureComponent
} from 'react';
import { Modal, Button, ControlLabel, Col, FormGroup, InputGroup, InputPicker, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import BarcodeMultipleCard from '../Barcode/BarcodeMultipleCard';
import PalletContentsNoMapping from '../Barcode/PalletContentsNoMapping';
import CaseContentsNoMapping from '../Barcode/CaseContentsNoMapping';

const mountRef = React.createRef();

function NewPalletBarcodeModal({
    newBarcodeModalShown,
    closePrintBarcodes,
    // onPrintBarcodes,
    state, ...props
}) {



    const palletBarcodes = props.InventoryNewPalletBarcode
        ? 
            <BarcodeMultipleCard barcode={props.InventoryNewPalletBarcode.trim()} key={props.InventoryNewPalletBarcode} />
        : [];

    // const groupedPalletContents = props.InventoryRecoveryPalletContent
    //     ? props.InventoryRecoveryPalletContent.reduce((groups, content) => {
    //         const key = content.PalletBarcode;
    //         if (!groups[key]) {
    //             groups[key] = [];
    //         }
    //         groups[key].push(content);
    //         return groups;
    //     }, {})
    //     : {};



    // function onPrintBarcode() {
    //     // this.setState({ showPrintableBarcode: true }, () => {

    //     var container = document.getElementById("div-svg");
    //     var mySVG = document.getElementById("barcode-canvas");
    //     var width = "100%";
    //     var height = "100%";
    //     var printWindow = window.open('', 'PrintMap',
    //         'width=' + width + ',height=' + height);
    //     printWindow.document.writeln(container.innerHTML);
    //     printWindow.document.close();
    //     printWindow.print();
    //     printWindow.close();


    //     // this.setState({ showPrintableBarcode: false });

    // }


    function onPrintBarcode() {
        var container = document.getElementById("div-svg");
        var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
        
    }



    function onPrintContent() {
        // this.setState({ showPrintableContent: true }, () => {
        // window.print();
        // var container = document.getElementById("div-content");
        // var mySVG = document.getElementById("barcode-canvas");
        // var width = "100%";
        // var height = "100%";
        // var printWindow = window.open('', 'PrintMap',
        //     'width=' + width + ',height=' + height);
        // printWindow.document.writeln(container.innerHTML);
        // printWindow.document.close();
        // printWindow.print();
        // printWindow.close();

        var container = document.getElementById("div-content");
        var mySVG = document.getElementById("barcode-canvas");
        var printWindow = window.open('', 'PrintMap',
            'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }


    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={newBarcodeModalShown} onHide={closePrintBarcodes} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>New Pallet Barcode</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <FormGroup>


                        <div id="div-svg" style={{ display: 'flex', flexDirection: 'column' }}>
                            {palletBarcodes}
                        </div>

                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(e) => { onPrintBarcode(e) }} appearance="primary">
                        Print Barcodes
                    </Button>
                    <Button onClick={closePrintBarcodes} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default NewPalletBarcodeModal;

