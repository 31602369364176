import React from 'react';
import { Row, Col, Panel, Timeline } from 'rsuite'; // Assuming you're using rsuite
import Loader from 'react-loader-spinner'; // Assuming you're using react-loader-spinner

function OrderOutboundHistoryDiv({ isInventoryLotOrderOutboundHistoryGetLoading, InventoryLotOrderOutboundHistoryData }) {
    if (isInventoryLotOrderOutboundHistoryGetLoading) {
        return (
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        );
    }

    else if (typeof (InventoryLotOrderOutboundHistoryData[0]) !== 'undefined' && InventoryLotOrderOutboundHistoryData != null) {
        return (
            <div>
                <Row>
                    <Col md={24} sm={24}>
                        <Panel bordered header="Order History" style={{ marginBottom: 10 }}>
                            <Timeline>
                                {InventoryLotOrderOutboundHistoryData.map(item => (
                                    <Timeline.Item key={item.OrderOutboundHistoryID}>
                                        {item.HistoryType != null ? <strong>{item.HistoryType}: </strong>: ''}
                                        {item.CreatedDT}  &nbsp;
                                        {item.EmailAddr} &nbsp;
                                        
                                        <l></l>
                                        

                                        {item.OrderStatus != null ? <l><br /> Status:  <strong>{item.OrderStatus}</strong> </l> : ' '}
                                        {item.OrderRef != null ? <l><br /> Order Ref:  <strong>{item.OrderRef}</strong> </l> : ' '}
                                        {item.OrderType != null ? <l><br /> Order Type:  <strong>{item.OrderType}</strong> </l> : ' '}
                                        {item.OrderPriority != null ? <l><br /> Order Priority:  <strong>{item.OrderPriority}</strong> </l> : ' '}
                                        {item.OrderDate != null ? <l><br /> Order Date:  <strong>{item.OrderDate}</strong> </l> : ' '}
                                        {item.ShipByDate != null ? <l><br /> Ship By Date:  <strong>{item.ShipByDate}</strong> </l> : ' '}
                                        {item.DeliverByDate != null ? <l><br /> Deliver By Date:  <strong>{item.DeliverByDate}</strong> </l> : ' '}
                                        {item.ShipToName != null ? <l><br /> Ship To Name:  <strong>{item.ShipToName}</strong> </l> : ' '}
                                        {item.ShipToAddressLine1 != null ? <l><br /> Ship To Address Line 1:  <strong>{item.ShipToAddressLine1}</strong> </l> : ' '}
                                        {item.ShipToAddressLine2 != null ? <l><br /> Ship To Address Line 2:  <strong>{item.ShipToAddressLine2}</strong> </l> : ' '}
                                        {item.ShipToCity != null ? <l><br /> Ship To City:  <strong>{item.ShipToCity}</strong> </l> : ' '}
                                        {item.ShipToState != null ? <l><br /> Ship To State:  <strong>{item.ShipToState}</strong> </l> : ' '}
                                        {item.ShipToZip != null ? <l><br /> Ship To Zip:  <strong>{item.ShipToZip}</strong> </l> : ' '}
                                        {item.ShipToCountry != null ? <l><br /> Ship To Country:  <strong>{item.ShipToCountry}</strong> </l> : ' '}
                                        {item.Notes != null ? <l><br /> Notes:  <strong>{item.Notes}</strong> </l> : ' '}

                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }

    return <div></div>;
}

export default OrderOutboundHistoryDiv;
