import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Tooltip, Whisper, Row } from 'rsuite';

const tooltip = (
  <Tooltip>
    This is a help <i>tooltip</i> .
  </Tooltip>
);

const IconButton = ({
  iconLabel,
  icon,
  iconColor,
  allowClick,
  onClickIconButton,
  isMobile
}) => (

  <div>
    {(() => {
      switch (isMobile) {
        case true: return (
          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip> {iconLabel} </Tooltip>}>
            <Panel bordered style={{display: 'flex', flexDirection: 'row', justifyContent: 'center',  marginBottom: 10, cursor: (allowClick ? 'pointer' : '') }} onClick={allowClick ? (e) => { onClickIconButton(e) } : undefined}  >
  
              <span className={`sm-st-icon-mobile st-${iconColor}`}  >
                {icon}
              </span>

            </Panel>
          </Whisper>
        );

        case false: return (
          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip> {iconLabel} </Tooltip>}>
            <Panel bordered style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10, cursor: (allowClick ? 'pointer' : '') }} onClick={allowClick ? (e) => { onClickIconButton(e) } : undefined} >
              <span className={`sm-st-icon st-${iconColor}`}  >
                {icon}
              </span>
            </Panel>
          </Whisper>
        );
        default: return (
          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip> {iconLabel} </Tooltip>}>
            <Panel bordered style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10, cursor: (allowClick ? 'pointer' : '') }} onClick={allowClick ? (e) => { onClickIconButton(e) } : undefined} >
              <span className={`sm-st-icon st-${iconColor}`}  >
                {icon}
              </span>
            </Panel>
          </Whisper>
        )
      }
    })()}
  </div >

);

IconButton.propTypes = {
  statValue: PropTypes.string,
  iconLabel: PropTypes.string,
  icon: PropTypes.node,
  isMobile: PropTypes.bool
};

IconButton.defaultProps = {
  allowClick: false,
  statValue: '0',
  iconLabel: '',
  icon: (<i className="fa fa-check-square-o"></i>),
  iconColor: 'blue',
  isMobile: false
};

export default IconButton;


























// import React from 'react';
// import PropTypes from 'prop-types';
// import classnames from 'classnames';
// // import checkProps from '@jam3/react-check-extra-props';
// import styles from './IconButton.module.scss';

// function IconButton({ icon, title, text }) {
//   return (
//     <div className={classnames(styles.card)}>
//       <div className={styles.iconBox}>{icon}</div>
//       <div className={styles.textBox}>
//         <div>
//           <strong>{title}</strong>
//         </div>
//         <div>{text}</div>
//       </div>
//     </div>
//   );
// }

// // IconButton.propTypes = checkProps({ icon: PropTypes.element, title: PropTypes.string, text: PropTypes.string });

// IconButton.defaultProps = {};

// export default IconButton;
