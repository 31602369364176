export const AddToLoadHawbDataElements = [
    {
        dBKey: 'HouseRef',
        displayName: 'House Ref',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Master',
        displayName: 'Master',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'HawbStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ConsigneeName',
        displayName: 'Consignee',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Weight',
        displayName: 'Weight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Cartons',
        displayName: 'Cartons',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'RecoveredPCS',
        displayName: 'Received CTNS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'LoadedPCS',
        displayName: 'Loaded PCS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'LoadedPallets',
        displayName: 'Loaded Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    {
        dBKey: 'AgentNote',
        displayName: 'Agent Note',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    {
        dBKey: 'CustomsCleared',
        displayName: 'Customs Cleared',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'Orders',
        displayName: 'Orders',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'DeliveryAddress',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'DCCode',
        displayName: 'DC Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


]


export default AddToLoadHawbDataElements

