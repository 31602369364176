import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import { Loader, Button, Row, Col, Panel, ControlLabel, InputGroup, Input } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import queryString from 'query-string';
import { queryStringParse, formatDateForUrlSearch } from '../../../../resources/logic/functions';
import { textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RDatePickerRange from '../../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import InventoryOutboundDataElements from '../../../dataComponentsInventory/InventoryOutboundDataElements';


const tableIdCol = 'id'


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class Outbound extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchSku: '',
            searchSealNo: '',
            searchDepartDateStart: null,
            searchDepartDateEnd: null,

            isFilterShown: true,


        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeSearchSku = this.onChangeSearchSku.bind(this);
        this.onChangeSearchSealNo = this.onChangeSearchSealNo.bind(this);
        this.onChangeSearchDepartDateStart = this.onChangeSearchDepartDateStart.bind(this);
        this.onChangeSearchDepartDateEnd = this.onChangeSearchDepartDateEnd.bind(this);

    }


    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
    }


    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search);

        const parseAndApply = (value, callback) => {
            try {
                var param = queryStringParse(value);
                if (param != null) {
                    callback(param);
                }
            } catch (e) {
                // Handle exception if needed
            }
        };

        parseAndApply(values.sku, this.onChangeSearchSku);
        parseAndApply(values.sealno, this.onChangeSearchSealNo);
        parseAndApply(values.departdatestart, this.onChangeSearchDepartDateStart);
        parseAndApply(values.departdateend, this.onChangeSearchDepartDateEnd);
    };



    // updateParamsFromURL = () => {
    //     const values = queryString.parse(this.props.location.search)


    //     try {
    //         var paramSku = queryStringParse(values.sku)
    //     }
    //     catch (e) {
    //         var paramSku = null
    //     }
    //     if (paramSku != null) {
    //         this.onChangeSearchSku(paramSku)
    //     }
    //     try {
    //         var paramSealNo = queryStringParse(values.sealno)
    //     }
    //     catch (e) {
    //         var paramSealNo = null
    //     }
    //     if (paramSealNo != null) {
    //         this.onChangeSearchSealNo(paramSealNo)
    //     }
    //     try {
    //         var paramDepartDateStart = queryStringParse(values.departdatestart)
    //     }
    //     catch (e) {
    //         var paramDepartDateStart = null
    //     }
    //     this.onChangeSearchDepartDateStart(paramDepartDateStart)
    //     try {
    //         var paramDepartDateEnd = queryStringParse(values.departdateend)
    //     }
    //     catch (e) {
    //         var paramDepartDateEnd = null
    //     }
    //     this.onChangeSearchDepartDateEnd(paramDepartDateEnd)
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }

    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    //Search Functions
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });


        this.setState({
            searchSku: '',
            searchSealNo: '',
            searchDepartDateStart: null,
            searchDepartDateEnd: null,

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {


        try {
            var searchDepartDateStart = this.state.searchDepartDateStart
            var searchDepartDateEnd = this.state.searchDepartDateEnd
            var searchSku = this.state.searchSku
            var searchSealNo = this.state.searchSealNo

            this.props.history.push({
                search:
                    'sku=' + searchSku + '&' +
                    'sealno=' + searchSealNo + '&' +
                    'departdatestart=' + formatDateForUrlSearch(searchDepartDateStart) + '&' +
                    'departdateend=' + formatDateForUrlSearch(searchDepartDateEnd)
            });
        }
        catch (e) { }
    }



    onChangeSearchSku(e) {
        this.setState({
            searchSku: e,
        });
    }
    onChangeSearchSealNo(e) {
        this.setState({
            searchSealNo: e,
        });
    }
    onChangeSearchDepartDateStart(e) {
        this.setState({
            searchDepartDateStart: e
        });
    }
    onChangeSearchDepartDateEnd(e) {
        this.setState({
            searchDepartDateEnd: e
        });
    }




    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData['LoadID']
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                'warehouseid=' + paramWarehouseID + '&' +
                'customerid=' + paramCustomerID 
            });
        }
        catch (e) {
        }
    }



    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;



        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Row >

                            <ControlLabel>SKU</ControlLabel>
                            <InputGroup style={{ marginBottom: 10 }}>
                                <Input value={this.state.searchSku} onChange={(e) => { this.onChangeSearchSku(e) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                {this.state.searchSku && <InputGroup.Button onClick={() => this.onChangeSearchSku('')}>x</InputGroup.Button>}
                            </InputGroup>

                        </Row>

                        <Row >

                            <ControlLabel>Seal #</ControlLabel>
                            <InputGroup style={{ marginBottom: 10 }}>
                                <Input value={this.state.searchSealNo} onChange={(e) => { this.onChangeSearchSealNo(e) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} cleanable />
                                {this.state.searchSealNo && <InputGroup.Button onClick={() => this.onChangeSearchSealNo('')}>x</InputGroup.Button>}
                            </InputGroup>

                        </Row>

                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Depart Date',
                                this.onChangeSearchDepartDateStart,
                                this.onChangeSearchDepartDateEnd,
                                this.state.searchDepartDateStart,
                                this.state.searchDepartDateEnd
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }





        let loadDiv;
        let rTable;
        if (this.props.isInventoryLotOutboundLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotOutboundData) !== 'undefined' && this.props.InventoryLotOutboundData != null) {

            rTable = RTable(
                this.props.InventoryLotOutboundData,        // tableData,
                InventoryOutboundDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isInventoryLotOutboundLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                false, // checkColEntry,
                // true,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                checkedKeys,                       // checkedKeys,
                this.handleCheckAll,                       // handleCheckAll,
                this.handleCheck,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey
                false,                            // editLink,
                null,                            // editLinkText,
                this.onClickExportLoad                            // editLinkOnClick
            )

            loadDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Outbound_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.InventoryLotOutboundData} name="Outbound">

                                    {
                                        InventoryOutboundDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }



        return (
            <div>
                <h3>
                    Inventory Management - Outbound
                </h3>


                {hideShowSearch}
                {searchDiv}


                {loadDiv}
                {rTable}

            </div>
        );

    }
}

export default withRouter(Outbound);

