import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, FormGroup, DatePicker, Input } from 'rsuite';

const styles = {
    marginBottom: '10px',
    width: '100%'
};



const acceptOrDeclineData = [
    {
      "label": "Accept",
      "value": 1
    },
    {
      "label": "Decline",
      "value": 0
    }
  ]

const mountRef = React.createRef();

function LoadTenderResponseModal({
    loadTenderResponseModalShown,
    handleSave,
    handleClose,
    onChangeLoadTenderResponseModal,
    resetRows,
    state, ...props

}) {
    return (

        <div className="modal-container" >
            <Modal backdrop={'static'} keyboard={false} show={loadTenderResponseModalShown} onHide={handleClose} onExited={resetRows}>
                <Modal.Header>
                    <Modal.Title>Load Tender: {state.loadTenderNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Panel bordered style={{ marginBottom: '15px' }} >
                        <div ref={mountRef}>



                            <Col md={24} sm={24} style={styles}>

                                <FormGroup >
                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Response:</InputGroup.Addon>
                                        <InputPicker preventOverflow
                                            container={() => mountRef.current}
                                            placeholder={'Accept/Decline...'}
                                            value={state.loadTenderModalAcceptDecline}
                                            onChange={(e) => { onChangeLoadTenderResponseModal(e, 'loadTenderModalAcceptDecline') }}
                                            data={acceptOrDeclineData}
                                            block
                                            // size="sm"
                                            style={{ width: '100%' }}
                                            cleanable={true}
                                        />
                                    </InputGroup>
                                </FormGroup>



                            </Col>



                        </div>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default LoadTenderResponseModal;