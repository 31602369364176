import { connect } from 'react-redux';
import React, { Component } from 'react';
import Inventory from './Inventory'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventorySkuLoading: store.inventoryReducer.isInventorySkuLoading,
    InventorySkuData: store.inventoryReducer.InventorySkuData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,
    SkuLocationDataGrouped: store.inventoryReducer.SkuLocationDataGrouped,

    InventoryLoadPostSuccessful: store.inventoryReducer.InventoryLoadPostSuccessful,


    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,


});

const mapDispatchToProps = dispatch =>
({
    inventorySkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventoryLoadPost: (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _LoadSkuData, _LocationSkuData, _WarehouseLocationID, _SkuWarehouseLocationID, _LoadSkuID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryLoadPost(_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _LoadSkuData, _LocationSkuData, _WarehouseLocationID, _SkuWarehouseLocationID, _LoadSkuID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseID, _CustomerID))
    },
    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


});

class InventoryContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLoadPostSuccessful !== this.props.InventoryLoadPostSuccessful && this.props.InventoryLoadPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatus = queryStringParse(values.skustatusid)
        }
        catch (e) {
            var paramStatus = null
        }

        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }


        try {
            var paramRecoveryDateStart = formatDateForAPI(values.recoverydatestart)
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        try {
            var paramRecoveryDateEnd = formatDateForAPI(values.recoverydateend)
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }



        try {
            var paramCustomerRefID = queryStringParse(values.customerrefid)
        }
        catch (e) {
            var paramCustomerRefID = null
        }

        try {
            var paramCustomerRef = formatDateForAPI(values.customerref)
        }
        catch (e) {
            var paramCustomerRef = null
        }
        try {
            var paramWarehouseLocationId = formatDateForAPI(values.warehouselocationid)
        }
        catch (e) {
            var paramWarehouseLocationId = null
        }



        this.props.inventorySkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramSku, null, paramContainer, paramStatus, paramWarehouseLocationId)
        this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramSku, null, paramContainer, paramStatus, paramWarehouseLocationId)

        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

    }



    render() {

        return (

            <Inventory
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventorySkuLoading={this.props.isInventorySkuLoading}
                InventorySkuData={this.props.InventorySkuData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}
                SkuLocationDataGrouped={this.props.SkuLocationDataGrouped}

                InventoryLoadPostSuccessful={this.props.InventoryLoadPostSuccessful}
                inventoryLoadPost={this.props.inventoryLoadPost}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryContainer));



