export const LoadHawbDataElements = [

    {
        dBKey: 'HouseRef',
        displayName: 'House Ref',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 160
    },
    {
        dBKey: 'Master',
        displayName: 'Master',
        primaryCol: true,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },


    {
        dBKey: 'DCCode',
        displayName: 'DC Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },


    {
        dBKey: 'PCS',
        displayName: 'PCS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },



    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },

    {
        dBKey: 'DeliveryAddress',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },


    // {
    //     dBKey: 'CustomsCleared',
    //     displayName: 'Customs Cleared',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 130
    // },
    

]


export default LoadHawbDataElements

