import { connect } from 'react-redux'
import { reducer } from 'aws-cognito-redux-saga'
import SignInComponent from './SignIn'
import * as actions from '../../../redux/actions/actionsUser';

const mapStatetoProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(reducer.init())
    },
    signIn: (username, password, code) => {
      dispatch(reducer.signIn(username, password, code))
    },
    appSignIn:( currentUserEmail ) =>
    {
        dispatch( actions.appSignIn( currentUserEmail ) )
    },
    getPageConfig:( currentUserEmail, filter ) =>
    {
        dispatch( actions.getPageConfig( currentUserEmail, filter ) )
    },
    userWarehousesGet:( currentUserEmail, otherUsersID ) =>
    {
        dispatch( actions.userWarehousesGet( currentUserEmail, otherUsersID ) )
    },
    userOrganizationGet:( currentUserEmail, otherUsersID ) =>
    {
        dispatch( actions.userOrganizationGet( currentUserEmail, otherUsersID ) )
    },
    userCustomersGet:( currentUserEmail, otherUsersID ) =>
    {
        dispatch( actions.userCustomersGet( currentUserEmail, otherUsersID ) )
    },
    userModulesGet:( currentUserEmail, otherUsersID ) =>
    {
        dispatch( actions.userModulesGet( currentUserEmail, otherUsersID ) )
    },
    userLabelGenCarrierGet:( currentUserEmail, otherUsersID ) =>
    {
        dispatch( actions.userLabelGenCarrierGet( currentUserEmail, otherUsersID ) )
    },
    userLabelGenCarrierServiceGet:( currentUserEmail, otherUsersID ) =>
    {
        dispatch( actions.userLabelGenCarrierServiceGet( currentUserEmail, otherUsersID ) )
    },


  }
}

export default connect(mapStatetoProps, mapDispatchToProps)(SignInComponent)

