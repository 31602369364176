import React from 'react';
import './App.css';
import PropTypes from 'prop-types'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './components/componentsGeneral/PrivateRoute'
import vasView from './views/ViewsVas/vasView/vasView'
import CognitoAuth from './components/componentsUser/CognitoAuth'
import SignIn from './components/componentsUser/SignIn'
import { state } from 'aws-cognito-redux-saga'
import SignInAuthView from './views/viewsUser/SignInAuthView/SignInAuthView'
import Header from './components/componentsGeneral/Header'
import Footer from './components/componentsGeneral/Footer/Footer'
import userSignUpView from './views/viewsUser/userSignUp/userSignUpView';
import ResetPassword from './components/componentsUser/ResetPassword'
import ChangePassword from './components/componentsUser/ChangePassword'
import ForceChangePassword from './components/componentsUser/ForceChangePassword'

class App extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { auth } = this.props

    return (

        <div >
          <CognitoAuth />
          <Header />
          <Switch>
  
              <PrivateRoute path="/wms" component={vasView} />
              <PrivateRoute path="/auth" component={SignInAuthView} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={userSignUpView} />
              <Route path="/resetpassword" component={ResetPassword} />
              <Route path="/changepassword" component={ChangePassword} />
              <Route path="/forcechangepassword" component={ForceChangePassword} />

              <Route
                path="/"
                render={() =>                 
                  auth.isSignedIn === state.AUTH_SUCCESS ? ( 
                    <Redirect to="/auth" />
                  ) : (
                    <Redirect to="/signin" />
                  )
                }
              />

          </Switch>
          <Footer />
        </div>
    )
  }
}

export default App