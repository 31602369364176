import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoadDetails from './LoadDetails';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';

const mapStateToProps = store =>
({
    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,

    isInventorySkuLoading: store.inventoryReducer.isInventorySkuLoading,
    InventorySkuData: store.inventoryReducer.InventorySkuData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    SkuLocationDataGrouped: store.inventoryReducer.SkuLocationDataGrouped,

    InventoryLoadPostSuccessful: store.inventoryReducer.InventoryLoadPostSuccessful,

    InventoryLoadRemoveSkuPostSuccessful: store.inventoryReducer.InventoryLoadRemoveSkuPostSuccessful,

    isInventoryLoadLoading: store.inventoryReducer.isInventoryLoadLoading,
    InventoryLoadData: store.inventoryReducer.InventoryLoadData,

    isInventoryLoadSkuLoading: store.inventoryReducer.isInventoryLoadSkuLoading,
    InventoryLoadSkuData: store.inventoryReducer.InventoryLoadSkuData,

    isInventoryLoadSkuHistoryLoading: store.inventoryReducer.isInventoryLoadSkuHistoryLoading,
    InventoryLoadSkuHistoryData: store.inventoryReducer.InventoryLoadSkuHistoryData,

    isInventorySkuWarehouseLocationHistoryLoading: store.inventoryReducer.isInventorySkuWarehouseLocationHistoryLoading,
    InventorySkuWarehouseLocationHistoryData: store.inventoryReducer.InventorySkuWarehouseLocationHistoryData,

    isInventoryLoadSkuLocationHistoryLoading: store.inventoryReducer.isInventoryLoadSkuLocationHistoryLoading,
    InventoryLoadSkuLocationHistoryData: store.inventoryReducer.InventoryLoadSkuLocationHistoryData,



});

const mapDispatchToProps = dispatch =>
({
    inventoryLoadRemoveSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _LoadSkuID, _LoadID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseLocationID, _SkuWarehouseLocationID, _WarehouseLocations) => {
        dispatch(inventoryActions.inventoryLoadRemoveSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _LoadSkuID, _LoadID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseLocationID, _SkuWarehouseLocationID, _WarehouseLocations))
    },
    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    inventoryLoadPost: (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _LoadSkuData, _LocationSkuData, _WarehouseLocationID, _SkuWarehouseLocationID, _LoadSkuID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryLoadPost(_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _LoadSkuData, _LocationSkuData, _WarehouseLocationID, _SkuWarehouseLocationID, _LoadSkuID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseID, _CustomerID))
    },
    inventoryLoadGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _SkuID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID,) => {
        dispatch(inventoryActions.inventoryLoadGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _SkuID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID,))
    },
    inventoryLoadSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) => {
        dispatch(inventoryActions.inventoryLoadSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID))
    },
    inventoryLoadSkuHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) => {
        dispatch(inventoryActions.inventoryLoadSkuHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID))
    },
    inventorySkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventorySkuWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventoryLoadSkuLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID, _Oversize) => {
        dispatch(inventoryActions.inventoryLoadSkuLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID, _Oversize))
    },




});

class LoadDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLoadRemoveSkuPostSuccessful !== this.props.InventoryLoadRemoveSkuPostSuccessful && this.props.InventoryLoadRemoveSkuPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }
        if (prevProps.InventoryLoadPostSuccessful !== this.props.InventoryLoadPostSuccessful && this.props.InventoryLoadPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }

        if (prevProps.InventoryLoadRemovePalletCaseSkuPostSuccessful !== this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful && this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful) {
            this.handleUrlParam()
        }
        else { }
        
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramLoadID = queryStringParse(values.loadid)
        }
        catch (e) {
            var paramLoadID = null
        }

        try {
            var paramWarehouseID = queryStringParse(values.warehouseid)
        }
        catch (e) {
            var paramWarehouseID = null
        }

        try {
            var paramCustomerID = queryStringParse(values.customerid)
        }
        catch (e) {
            var paramCustomerID = null
        }

        this.props.inventoryLoadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, null, null)

        this.props.inventoryLoadSkuHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null)
        this.props.inventoryLoadSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null)
        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID);

        this.props.inventorySkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, null, null, null, null, 1, null )
        this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, null, null, null, null, 1, null )




    }



    render() {

        return (

            <LoadDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                inventorySkuGet={this.props.inventorySkuGet}
                isInventorySkuLoading={this.props.isInventorySkuLoading}
                InventorySkuData={this.props.InventorySkuData}

                isInventoryDestinationLoading={this.props.isInventoryDestinationLoading}
                InventoryDestinationData={this.props.InventoryDestinationData}

                inventorySkuWarehouseLocGet={this.props.inventorySkuWarehouseLocGet}
                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}
                SkuLocationDataGrouped={this.props.SkuLocationDataGrouped}

                InventoryLoadPostSuccessful={this.props.InventoryLoadPostSuccessful}
                inventoryLoadPost={this.props.inventoryLoadPost}

                inventoryLoadRemoveSkuPost={this.props.inventoryLoadRemoveSkuPost}
                InventoryLoadRemoveSkuPostSuccessful={this.props.InventoryLoadRemoveSkuPostSuccessful}

                isInventoryLoadLoading={this.props.isInventoryLoadLoading}
                InventoryLoadData={this.props.InventoryLoadData}

                inventoryLoadSkuGet={this.props.inventoryLoadSkuGet}
                isInventoryLoadSkuLoading={this.props.isInventoryLoadSkuLoading}
                InventoryLoadSkuData={this.props.InventoryLoadSkuData}


                isInventoryLoadSkuHistoryLoading={this.props.isInventoryLoadSkuHistoryLoading}
                InventoryLoadSkuHistoryData={this.props.InventoryLoadSkuHistoryData}


                inventorySkuWarehouseLocationHistoryGet={this.props.inventorySkuWarehouseLocationHistoryGet}
                isInventorySkuWarehouseLocationHistoryLoading={this.props.isInventorySkuWarehouseLocationHistoryLoading}
                InventorySkuWarehouseLocationHistoryData={this.props.InventorySkuWarehouseLocationHistoryData}


                inventoryLoadSkuLocationHistoryGet={this.props.inventoryLoadSkuLocationHistoryGet}
                isInventoryLoadSkuLocationHistoryLoading={this.props.isInventoryLoadSkuLocationHistoryLoading}
                InventoryLoadSkuLocationHistoryData={this.props.InventoryLoadSkuLocationHistoryData}



            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadDetailsContainer));




