import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    ButtonGroup,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Divider,
    IconButton

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';
import RButtonSmall from '../../componentsGeneral/Rsuite/Button/RButtonSmall';
import * as moment from 'moment'
import { convertNanToZero } from '../../../resources/logic/functions';
import CloseIcon from '@rsuite/icons/Close';


const styles = {
    width: '100%'
};


function limitNumberToExistingValue(e, locationitem, item, originalProps) {
    var itemIndex = originalProps[item.PoID].findIndex(({ WarehouseLocationID }) => WarehouseLocationID === locationitem['WarehouseLocationID']);

    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)

    try {
        var limitValue = originalProps[item.PoID][itemIndex]['Cases']
    }
    catch (e) {
        var limitValue = 0
    }

    if (nonNegativeValue <= limitValue) {
        return nonNegativeValue
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}

// function limitNumberToExistingValue(e, item) {
//     var nonNegativeValue
//     nonNegativeValue = numbersInputMask(e)

//     try {
//         var limitValue = item['CasesReceived'] - item['CasesLoaded']
//     }
//     catch (e) {
//         var limitValue = 0
//     }

//     if (nonNegativeValue <= limitValue) {
//         return nonNegativeValue
//     }
//     else {
//         window.alert('Must not be greater than ' + limitValue.toString())
//         return limitValue
//     }
//     // var newString = null

//     // if (inputString == null || typeof (inputString) == 'undefined') {

//     // }
//     // else {
//     //     var stripString = inputString.trim().replace(/[|&;$%"'`\[\]#!^*~<>()+=]/g, '') //Add unwanted characters inside []
//     //     var newString = "'" + stripString + "'"
//     // }
//     // return e.target.value
// }

// style={{ overflowX: 'hidden!important' }} 

const mountRef = React.createRef();

const BuildLoadDrawer = ({ onChange, onChangeExisting, onChangeLocation, onClickRemoveFromAdd, totalRecoveredNum, totalPalleteNum, show, placement, confirm, close, remove, state, ...props }) => (

    <Drawer show={state.showBuildLoadDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '655px' }}    >
        <Drawer.Header>
            <Drawer.Title>Build Load</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body > <div ref={mountRef}>

            <Row style={{ marginBottom: '15px' }}>
                <Col md={24} sm={24}>
                    <FormGroup>
                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                            <Input readOnly value={'Total PCS: ' + totalRecoveredNum} />
                        </InputGroup>
                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                            <Input readOnly value={'Total Pallets: ' + totalPalleteNum} />
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>

            <Panel bordered style={{ marginBottom: '15px' }}>
                <Row>
                    <Col md={24} sm={24}>
                        <FormGroup >
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Pickup Date:</InputGroup.Addon>
                                <DatePicker
                                    container={() => mountRef.current} oneTap style={styles} placeholder={(state.NewLoadData.PickupDate === null ? 'Pickup Date' : moment(state.NewLoadData.PickupDate).format('YYYY-MM-DD'))} onChange={(e) => onChange(e, 'PickupDate')} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Delivery Date:</InputGroup.Addon>
                                <DatePicker
                                    container={() => mountRef.current} oneTap style={styles} placeholder={(state.NewLoadData.DeliveryDate === null ? 'Delivery Date' : moment(state.NewLoadData.DeliveryDate).format('YYYY-MM-DD'))} onChange={(e) => onChange(e, 'DeliveryDate')} />
                            </InputGroup>

                            <div style={{ marginBottom: '15px', width: '100%' }}>
                                <InputPicker preventOverflow
                                    container={() => mountRef.current}
                                    block
                                    creatable
                                    placeholder={'Destination...'}
                                    value={state.NewLoadData.Destination}
                                    // onChange={(e, value, label) => { onChangeDestination(e, value, label) }}
                                    onChange={(e) => { onChange(e, 'Destination') }}
                                    data={props.TransDestinationData}
                                    cleanable={true}
                                />
                            </div>

                        </FormGroup>
                    </Col>
                    <Col md={12} sm={24}>
                        <FormGroup >

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                <Input defaultValue={state.NewLoadData.Carrier} onChange={(e) => { onChange(e, 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '15px', width: '100%' }}>
                                <InputGroup.Addon>Trailer #:</InputGroup.Addon>
                                <Input defaultValue={state.NewLoadData.Trailer} onChange={(e) => { onChange(e, 'Trailer') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                        </FormGroup>
                    </Col>
                    <Col md={12} sm={24}>
                        <FormGroup >


                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>BOL #:</InputGroup.Addon>
                                <Input defaultValue={state.NewLoadData.BOL} onChange={(e) => { onChange(e, 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '15px', width: '100%' }}>
                                <InputGroup.Addon>Seal #:</InputGroup.Addon>
                                <Input defaultValue={state.NewLoadData.Seal} onChange={(e) => { onChange(e, 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>


                <Row >

                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <Button block onClick={(e) => { confirm(e, totalRecoveredNum, totalPalleteNum) }} appearance="primary">Save</Button>
                        </ButtonToolbar>
                    </Col>
                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <Button block onClick={close} appearance="default">Cancel</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>

            </Panel>







            {state.BuildLoadDict.map(item => (
                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: '10px' }}>

                            <Row>
                                <Col md={8} sm={24}>
                                    PO: <strong>{item.PO}</strong>
                                    <br />
                                    Container: <strong>{item.Container}</strong>
                                    <br />
                                </Col>

                                <Col md={12} sm={24}>

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Total Cases:</InputGroup.Addon>
                                        <InputNumber readOnly value={item.NewCasesReceived} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, item, 'NewCasesReceived')} scrollable={false} onInput={(e) => e.target.value = limitNumberToExistingValue(e, item)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Total Pallets:</InputGroup.Addon>
                                        <InputNumber readOnly value={item.NewPallets} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, item, 'NewPallets')} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                    </InputGroup>

                                </Col>
                                <Col md={4} sm={24}>
                                    <Button style={{ float: 'right' }} onClick={(e) => { remove(e, item) }} appearance="primary">Remove</Button>
                                    <br />
                                </Col>
                            </Row>

                            <Divider />


                            {
                                item.PoID in state.LocationDict ?

                                    state.LocationDict[item.PoID].map(locationitem => (
                                        <div>
                                            <Row>
                                                <Col md={7} sm={7}>
                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Location:</InputGroup.Addon>
                                                        <Input value={locationitem['WarehouseLocationName']} readOnly />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={7} sm={7}>
                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Cases:</InputGroup.Addon>
                                                        <InputNumber value={locationitem['Cases']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, item, locationitem, 'Cases', locationitem['WarehouseLocationID'])} scrollable={false} onInput={(e) => e.target.value = limitNumberToExistingValue(e, locationitem, item, props.PoLocationDataGrouped)} />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={7} sm={7}>
                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                                        <InputNumber value={locationitem['Pallets']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, item, locationitem, 'Pallets', locationitem['WarehouseLocationID'])} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                                    </InputGroup>
                                                </Col>

                                                <Col md={2} sm={2}>

                                                    <IconButton size="md" icon={<CloseIcon />} onClick={(e) => onClickRemoveFromAdd(e, item, locationitem, locationitem['WarehouseLocationID'])} />

                                                </Col>

                                            </Row>
                                        </div>
                                    ))


                                    :

                                    <div></div>

                            }





                        </Panel>
                    </Col>
                </Row>
            ))}


            {/* </Form> */}

        </div> </Drawer.Body>
        <Drawer.Footer>

            <br />

        </Drawer.Footer>
    </Drawer>
);


BuildLoadDrawer.defaultProps = {
    show: false
};

export default BuildLoadDrawer;


