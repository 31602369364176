import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Tree } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import LoadDataElements from '../../dataComponentsTrans/LoadDataElements';
import EditPoDrawer from './EditPoDrawer';
import { formatStringForAPI, formatDateTimeForUrlSearch, formatDateForUrlSearch, convertNanToZero, replaceNegativeWithZero, isNumeric, formatAllTextNumbersSymbolsForAPI, formatTrueFalseToBit } from '../../../resources/logic/functions';
import TransContainerWarehouseLocationDataElements from '../../dataComponentsTrans/TransContainerWarehouseLocationDataElements';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import EditWarehouseLocationDrawer from './EditWarehouseLocationsDrawer';
import RemoveFromLoadModal from './RemoveFromLoadModal';
import RUploader from '../../componentsGeneral/Rsuite/Uploader/RUploader';
import { appAPI } from '../../../app/appConfig';


const tableIdCol = "PoID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")



class PoDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Add To PO Values
            MBOL: null,
            HBOL: null,
            PO: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            addNewText: null,

            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,

            selectedPoCases: null,
            selectedPoPallets: null,
            selectedPoNotes: null,
            selectedPoCustomerRef: null,
            selectedPoProject: null,

            //Edit Po Values
            showEditPoDrawer: false,
            unsavedEditPoValues: false,
            Po: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,

            //Edit Warehouse Location
            showEditWarehouseLocationDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationDict: {},
            LocationToEditDict: {},
            Po: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,

            //Remove From Load Modal
            removeFromModalShown: false,
            removeFromLoadRowData: {}
        };

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        this.onClickRemoveFromLoad = this.onClickRemoveFromLoad.bind(this)

        this.onClickContainerDetails = this.onClickContainerDetails.bind(this);

        //Edit Warehouse Location
        this.onChangeExistingOversize = this.onChangeExistingOversize.bind(this);
        this.onclickEditWarehouseLocation = this.onclickEditWarehouseLocation.bind(this);
        this.closeEditWarehouseLocationDrawer = this.closeEditWarehouseLocationDrawer.bind(this);
        this.setStateCloseEditWarehouseLocationDrawer = this.setStateCloseEditWarehouseLocationDrawer.bind(this);
        this.onClickAddLocation = this.onClickAddLocation.bind(this);
        this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickSaveEditWarehouseLocation = this.onClickSaveEditWarehouseLocation.bind(this);

        //Json Tree
        this.onClickTree = this.onClickTree.bind(this);
        this.onClickPoDetails = this.onClickPoDetails.bind(this);

        //Edit Po Drawer
        this.closeEditPoDrawer = this.closeEditPoDrawer.bind(this);
        this.onClickConfirmEditPo = this.onClickConfirmEditPo.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)
        this.onChangeEditPo = this.onChangeEditPo.bind(this)
        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this)
        this.onChangeProject = this.onChangeProject.bind(this)

        //Remove From Load Modal
        this.handleOpenRemoveFromLoadModal = this.handleOpenRemoveFromLoadModal.bind(this);
        this.handleCloseRemoveFromLoadModal = this.handleCloseRemoveFromLoadModal.bind(this);
        this.handleSaveRemoveFromLoadModal = this.handleSaveRemoveFromLoadModal.bind(this);

        //File Upload
        this.onUpload = this.onUpload.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.PoPostSuccessful !== this.props.PoPostSuccessful && this.props.PoPostSuccessful) {
            this.setStateCloseAddNewDrawer()
        }

    }


    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 500);
    }


    //Warehouse Location Drawer Buttons
    onclickEditWarehouseLocation(e, rowData, history) {

        try{
            var existingOversize = rowData['Oversize']
        }
        catch(e){
            var existingOversize = false
        }

        var locationDict = {};
        locationDict[rowData.PoID] = [{ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': 0, 'Pallets': 0, 'Oversize': existingOversize }]

        var newLocationToEdit = rowData
        newLocationToEdit['OriginalCases'] = rowData['Cases']
        newLocationToEdit['OriginalPallets'] = rowData['Pallets']
        newLocationToEdit['OriginalOversize'] = rowData['Oversize']

        this.setState({
            showEditWarehouseLocationDrawer: true,
            LocationDict: locationDict,
            LocationToEditDict: newLocationToEdit,
            unsavedEditWarehouseLocationValues: false,
        });

    }

    onChangeExistingOversize(e, existingData){
        if(window.confirm('Do you wish to update Oversize indicator?')){
            try {
                this.props.transPoLocationPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    existingData.PoID,
                    existingData.WarehouseLocationID,
                    replaceNegativeWithZero(existingData.OriginalCases),
                    replaceNegativeWithZero(existingData.OriginalPallets),
                    existingData.WarehouseLocationID,
                    this.props.warehouseSelection,
                    this.props.customerSelection,
                    formatTrueFalseToBit(e)
                )
            }
            catch (e) { }

            this.setStateCloseEditWarehouseLocationDrawer()
        }
    }

    onChangeEditLocationDrawer = (event, itemLocation, label, existinglocationname) => {

        if (label === 'WarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationDict[this.state.LocationToEditDict.PoID].filter(item => item.WarehouseLocationID === event).length > 0) {
                window.alert('Duplicate location entry, please use existing.')
            }
            else if (this.state.LocationToEditDict.WarehouseLocationID === event) {
                window.alert('Location already exists, please choose new location.')
            }
            else {
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

                warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID][keyToUpdate][label] = event

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedEditWarehouseLocationValues: true
                });
            }
        }
        else {
            var warehouseLocationDictUpdated = this.state.LocationDict
            var keyToUpdate = warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

            warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID][keyToUpdate][label] = isNumeric(event)

            this.setState({
                LocationDict: warehouseLocationDictUpdated,
                unsavedEditWarehouseLocationValues: true
            });


        }


        var currentBuildLoadDict = this.state.LocationToEditDict

        try {
            currentBuildLoadDict['Cases'] = replaceNegativeWithZero(this.state.LocationToEditDict.OriginalCases) - replaceNegativeWithZero((warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict['Pallets'] = replaceNegativeWithZero(this.state.LocationToEditDict.OriginalPallets) - replaceNegativeWithZero((warehouseLocationDictUpdated[this.state.LocationToEditDict.PoID].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            // currentBuildLoadDict['Cases'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['CasesReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['CasesLoaded'])
            // currentBuildLoadDict['Pallets'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsLoaded'])
        }

        if (currentBuildLoadDict['Cases'] < 0) {

            window.alert('Warning: moving cases than exist at location.')

        }

        this.setState({
            LocationToEditDict: currentBuildLoadDict
        });

        this.forceUpdate()
    };

    handleAddNewLocation = (newLocationDict) => {
        this.setState({
            unsavedEditWarehouseLocationValues: true,
            LocationDict: newLocationDict
        }, () => {
            // Do an API call with this.state.searchTerm
        });
    }

    onClickAddLocation = (event) => {
        //Check if any Warehouse names are null
        if (this.state.LocationDict[this.state.LocationToEditDict.PoID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all location values first.')
        }
        else {

            
            try{
                var existingOversize = this.state.LocationToEditDict['Oversize']
            }
            catch(e){
                var existingOversize = false
            }

            //save warehouse name
            var currentLocationDict = this.state.LocationDict

            currentLocationDict[this.state.LocationToEditDict.PoID].push({ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': existingOversize })

            this.handleAddNewLocation(currentLocationDict)

            this.forceUpdate()
        }
    }
    onClickSaveEditWarehouseLocation(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            try {
                var poID = this.state.LocationToEditDict.PoID
            }
            catch (e) {
                var poID = -1
            }
            try { var PreviousWarehouseLocationID = convertNanToZero(this.state.LocationToEditDict.WarehouseLocationID) }
            catch (e) { var PreviousWarehouseLocationID = 0 }


            var findNullLocations = this.state.LocationDict[poID].filter(locations => locations.WarehouseLocationID === null)
            if (findNullLocations.length > 0) {
                window.alert('Please select new locations.')
            }
            else {
                // if (window.confirm('Save Location Changes?')) {

                    //Save update to existing location:
                    try { var casesExistingLocNum = convertNanToZero(this.state.LocationToEditDict['Cases']) }
                    catch (e) { var casesExistingLocNum = 0 }

                    try { var palletsExistingLocNum = convertNanToZero(this.state.LocationToEditDict['Pallets']) }
                    catch (e) { var palletsExistingLocNum = 0 }

                    try { var oversizeExistingLoc = formatTrueFalseToBit(this.state.LocationToEditDict['Oversize']) }
                    catch (e) { var oversizeExistingLoc = false }

                    try {
                        this.props.transPoLocationPost(
                            this.props.currentUserEmail,
                            this.props.userSignInData.AuthToken,
                            poID,
                            PreviousWarehouseLocationID,
                            replaceNegativeWithZero(casesExistingLocNum),
                            replaceNegativeWithZero(palletsExistingLocNum),
                            PreviousWarehouseLocationID,
                            this.props.warehouseSelection,
                            this.props.customerSelection,
                            oversizeExistingLoc
                        )
                    }
                    catch (e) { }

                    for (let l in this.state.LocationDict[poID]) {
                        try { var casesLocNum = convertNanToZero(this.state.LocationDict[poID][l]['Cases']) }
                        catch (e) { var casesLocNum = 0 }

                        try { var palletsLocNum = convertNanToZero(this.state.LocationDict[poID][l]['Pallets']) }
                        catch (e) { var palletsLocNum = 0 }

                        try { var WarehouseLocationIDNum = convertNanToZero(this.state.LocationDict[poID][l]['WarehouseLocationID']) }
                        catch (e) { var WarehouseLocationIDNum = 0 }

                        try { var oversizeLoc = formatTrueFalseToBit(this.state.LocationDict[poID][l]['Oversize']) }
                        catch (e) { var oversizeLoc = false }

                        if ((casesLocNum === null || casesLocNum == 0) && (palletsLocNum === null || palletsLocNum == 0)) {
                        }
                        else {
                            try {
                                this.props.transPoLocationPost(
                                    this.props.currentUserEmail,
                                    this.props.userSignInData.AuthToken,
                                    poID,
                                    WarehouseLocationIDNum,
                                    replaceNegativeWithZero(casesLocNum),
                                    replaceNegativeWithZero(palletsLocNum),
                                    PreviousWarehouseLocationID,
                                    this.props.warehouseSelection,
                                    this.props.customerSelection,
                                    oversizeLoc
                                )
                            }
                            catch (e) { }
                        }
                    }
                    this.setStateCloseEditWarehouseLocationDrawer()
                // }
            }

        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }
    closeEditWarehouseLocationDrawer() {
        if (this.state.unsavedEditWarehouseLocationValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditWarehouseLocationDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseEditWarehouseLocationDrawer()
        }
    }
    setStateCloseEditWarehouseLocationDrawer() {
        this.setState({
            showEditWarehouseLocationDrawer: false,
            unsavedEditWarehouseLocationValues: false,
            LocationDict: {},
            LocationToEditDict: {},

        });
    }


    //Load table buttons
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            history.push({
                pathname: 'loaddetails',
                search: 'loadid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }
    onClickRemoveFromLoad(rowData, keyColumn) {

        this.props.transPoLoadLocationHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, rowData["LoadID"], this.props.PoData[0]["PoID"], null)

        this.handleOpenRemoveFromLoadModal(rowData)

    }
    onClickContainerDetails(e) {
        try {
            var paramID = this.props.PoData[0].ContainerID
            this.props.history.push({
                pathname: 'containerdetails',
                search: 'containerid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }





    //Remove from load modal
    handleOpenRemoveFromLoadModal(rowData) {
        this.setState({
            removeFromModalShown: true,
            removeFromLoadRowData: rowData
        });
    }
    handleCloseRemoveFromLoadModal() {
        this.setState({
            removeFromModalShown: false,
            removeFromLoadRowData: {}
        });
    }
    handleSaveRemoveFromLoadModal() {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            const currentListLocation = this.props.PoLoadLocationHistoryData

            var newLocationToPost = []
            for (let x in currentListLocation) {
                try {
                    newLocationToPost.push({ 'PoID': isNumeric(currentListLocation[x]["PoID"]), 'WarehouseLocationID': currentListLocation[x]['WarehouseLocationID'], 'Cases': currentListLocation[x]['NetCases'], 'Pallets': currentListLocation[x]['NetPallets'] })
                }
                catch (e) { }
            }
            var locationString = JSON.stringify(newLocationToPost)
            try {
                var paramPostLocationPoData = "'" + locationString + "'"
            }
            catch (e) {
                var paramPostLocationPoData = null
            }

            if (this.props.PoLoadLocationHistoryData.length === 0) {
                paramPostLocationPoData = null
            }

            this.props.transLoadRemovePost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.removeFromLoadRowData["LoadID"], this.props.PoData[0]["PoID"], this.state.removeFromLoadRowData["TotalPCS"], this.state.removeFromLoadRowData["TotalPallets"], paramPostLocationPoData, this.props.warehouseSelection, this.props.customerSelection)

            this.handleCloseRemoveFromLoadModal()
        }

        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }




    //Container Tree

    onClickTree(e) {

        this.setState({
            addNewText: null,
            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,
        });

        var selectedArray = []
        const findById = (dataset, id) => {
            for (let c of dataset) {
                if (c.value === id) {
                    selectedArray = c
                    return selectedArray
                }
                else {
                    for (let m of c.children) {
                        if (m.value === id) {
                            selectedArray = m
                            return selectedArray
                        }
                        else {
                            for (let h of m.children) {
                                if (h.value === id) {
                                    selectedArray = m
                                    return selectedArray
                                }
                                else {
                                    for (let p of h.children) {
                                        if (p.value === id) {
                                            selectedArray = p
                                            return selectedArray
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        selectedArray = findById(this.props.ContainerJsonTreeData, e['value']);

        if (e['value'].includes('C')) {
            this.setState({
                selectedType: 'container',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('M')) {
            this.setState({
                selectedType: 'mbol',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('H')) {
            this.setState({
                selectedType: 'hbol',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('P')) {
            this.setState({
                selectedType: 'po',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1),

                selectedPoCases: selectedArray['CaseCount'],
                selectedPoPallets: selectedArray['PalletCount'],
                selectedPoNotes: selectedArray['Notes'],
                selectedPoCustomerRef: selectedArray['custref'][0]['CustomerRef'],
                selectedPoProject: selectedArray['custref'][0]['Project'],
            });
        }
    }

    onClickPoDetails(e) {
        try {
            var paramID = this.state.selectedID
            this.props.history.push({
                pathname: 'podetails',
                search: 'poid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }

    }



    //Edit Po Drawer
    onClickEditPo() {
        this.openEditPo()
        this.setState({
            Po: this.props.PoData[0].PO,

            CustomerRef: this.props.PoData[0].CustomerRefID,
            Project: this.props.PoData[0].ProjectID,
            ExpectedCases: this.props.PoData[0].CaseCount,
            ExpectedPallets: this.props.PoData[0].PalletCount,
            Notes: this.props.PoData[0].PoNotes
        });
    }
    openEditPo(placement) {
        this.setState({
            placement,
            showEditPoDrawer: true,
            // unsavedEditPoValues: true,
        });
    }
    onChangeEditPo = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
        this.setState({
            unsavedEditPoValues: true,
        });
    };

    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            unsavedEditPoValues: true,
            CustomerRef: e
        });
    }

    onChangeProject = (e, value, item) => {
        this.setState({
            unsavedEditPoValues: true,
            Project: e
        });
    }

    onClickConfirmEditPo(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (this.state.Po == null || this.state.Po == '') {
                window.alert('Please enter new Po.')
            }
            else  {

                this.props.transPoPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    this.props.PoData[0].PoID,
                    formatStringForAPI(this.state.Po),
                    null,
                    formatStringForAPI(this.state.CustomerRef),
                    this.state.ExpectedCases,
                    this.state.ExpectedPallets,
                    formatAllTextNumbersSymbolsForAPI(this.state.Notes),
                    formatStringForAPI(this.state.Project),
                    0,
                    this.props.warehouseSelection,
                    this.props.customerSelection)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeEditPoDrawer() {
        if (this.state.unsavedEditPoValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
                window.location.reload();
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showEditPoDrawer: false,
            unsavedEditPoValues: false,

            Po: null,
            CustomerRef: null,
            Project: null,
            ExpectedCases: null,
            ExpectedPallets: null,
            Notes: null,
        });
    }


    //File Upload
    onUpload(e) {
        console.log('upload file', e)
    }

    render() {
        const { sortColumn, sortType } = this.state;

        let pageHeader;
        let detailsDiv;
        let mawbCurrentStatus;
        let editButton;
        if (this.props.isPoLoading) {
            detailsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
            editButton = <div></div>
        }
        //   else if (this.props.isMawbRecoverySummaryLoading) {
        //     detailsDiv =
        //       <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //           type="TailSpin"
        //           color="#3396E5"
        //           height="50"
        //           width="50"
        //         />
        //       </div>
        //   }
        else if (typeof (this.props.PoData[0]) !== 'undefined' && this.props.PoData != null) {

            pageHeader = 'Transload - PO: ' + this.props.PoData[0].PO

            mawbCurrentStatus = this.props.PoData[0].CurrentPoStatusID

            detailsDiv =
                <div>

                    <Row >
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10, height: 270 }}>
                                {/* {mawbCurrentStatus == 2 ? <Button style={{ float: 'right' }} onClick={(e) => { this.onClickCloseStatus(e) }} >Close PO Status</Button> : ''} */}
                                Container: <Button size="sm" appearance="primary" onClick={(e) => { this.onClickContainerDetails(e) }} >{this.props.PoData[0].Container}</Button>
                                <br />
                                MBOL: <strong>{this.props.PoData[0].MBOL}</strong>
                                <br />
                                HBOL: <strong>{this.props.PoData[0].HBOL}</strong>
                                <br />
                                Status: <strong>{this.props.PoData[0].PoStatusName}</strong>
                                <br />
                                LCL/FCL: <strong>{this.props.PoData[0].LCL_FCL}</strong>
                                <br />
                                Customer Ref: <strong>{this.props.PoData[0].CustomerRef}</strong>
                                <br />
                                Project: <strong>{this.props.PoData[0].Project}</strong>
                                <br />
                                ETD: <strong>{this.props.PoData[0].ETD}</strong>
                                <br />
                                ETA: <strong>{this.props.PoData[0].ETA}</strong>
                                <br />
                                Delivery Appointment: <strong>{this.props.PoData[0].DeliveryAppointment}</strong>
                                <br />
                                Notes: <strong>{this.props.PoData[0].PoNotes}</strong>
                                <br />

                            </Panel>
                        </Col>
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10, height: 270 }}>
                                Received Date: <strong>{this.props.PoData[0].RcdDate}</strong>
                                <br />
                                <br />
                                Expected Cartons: <strong>{this.props.PoData[0].CaseCount}</strong>
                                <br />
                                Received Cartons: <strong>{this.props.PoData[0].CasesReceived}</strong>
                                <br />
                                In Whse Cartons: <strong>{this.props.PoData[0].InWarehouseCases}</strong>
                                <br />
                                Shipped Cartons: <strong>{this.props.PoData[0].CasesLoaded}</strong>
                                <br />
                                <br />
                                Expected Pallets: <strong>{this.props.PoData[0].PalletCount}</strong>
                                <br />
                                Received Pallets: <strong>{this.props.PoData[0].PalletsReceived}</strong>
                                <br />
                                In Whse Pallets: <strong>{this.props.PoData[0].InWarehousePallets}</strong>
                                <br />
                                Shipped Pallets: <strong>{this.props.PoData[0].PalletsLoaded}</strong>
                                <br />
                                <br />

                            </Panel>
                        </Col>
                    </Row>
                </div>


            editButton = <div style={{ float: 'right' }}>
                <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditPo(e) }} >Edit</Button>
            </div>


        }
        else {
            detailsDiv = <div></div>;
            editButton = <div></div>
        }

        let unsavedEditPoChanges;
        unsavedEditPoChanges = this.state.unsavedEditPoValues



        let loadDiv;
        let rTable;
        if (this.props.isLoadLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadData) !== 'undefined' && this.props.LoadData != null) {

            rTable = RTable(
                this.props.LoadData,        // tableData,
                LoadDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isLoadLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                null,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                null,                       // checkedKeys,
                null,                       // handleCheckAll,
                null,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey

                true,// editLink,
                'Remove', // editLinkText,
                this.onClickRemoveFromLoad// editLinkOnClick
            )

            loadDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>
                                <ExcelSheet data={this.props.LoadData} name="Load">
                                    {
                                        LoadDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }



            </div>
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }

        let loadTimeline;
        if (this.props.isLoadPoHistoryLoading) {
            loadTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadPoHistoryData[0]) !== 'undefined' && this.props.LoadPoHistoryData != null) {
            loadTimeline =
                <div>
                    <Panel header="Load Events" bordered style={{ marginBottom: 10 }}>
                        <Timeline>
                            {this.props.LoadPoHistoryData.map(item => (
                                <Timeline.Item>
                                    {item.LoadPoDT}  &nbsp;
                                    {item.EmailAddr}
                                    {item.Carrier != null ? <l><br /> Carrier:  {item.Carrier} </l> : ' '}
                                    {item.Trailer != null ? <l><br /> Trailer:  {item.Trailer} </l> : ' '}
                                    {item.PCS != 0 ? <l><br /> PCS:  {item.PCS} </l> : ' '}
                                    {item.Pallets != 0 ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                    {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Panel>
                </div>
        }




        let ContainerJsonTreeDiv;
        if (this.props.isContainerJsonTreeLoading) {
            ContainerJsonTreeDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.ContainerJsonTreeData[0]) !== 'undefined' && this.props.ContainerJsonTreeData != null) {
            ContainerJsonTreeDiv = <div>
                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                            <Col md={12} sm={24}>
                                <Tree data={this.props.ContainerJsonTreeData} onSelect={(e) => { this.onClickTree(e) }} />
                            </Col>
                            <Col md={12} sm={24}>
                                {
                                    this.state.selectedType === 'container' ? <div>
                                    </div> : <div></div>
                                }
                                {
                                    this.state.selectedType === 'mbol' ? <div>
                                    </div> : <div></div>
                                }
                                {
                                    this.state.selectedType === 'hbol' ? <div>
                                    </div> : <div></div>
                                }
                                {
                                    this.state.selectedType === 'po' ? <div>
                                        <h5 style={{ marginBottom: 5 }}>
                                            PO: {this.state.selectedLabel}
                                        </h5>

                                        <div>
                                            <Row >
                                                <Col md={24} sm={24}>
                                                    <Panel bordered style={{ marginBottom: 10 }}>
                                                        {/* <Button size="sm" appearance="primary" style = {{float: 'right'}} onClick={(e) => { this.onClickPoDetails(e) }} >Details</Button> */}
                                                        Expected Cases: <strong>{this.state.selectedPoCases}</strong>
                                                        <br />
                                                        Expected Pallets: <strong>{this.state.selectedPoPallets}</strong>
                                                        <br />
                                                        Notes: <strong>{this.state.selectedPoNotes}</strong>
                                                        <br />
                                                        Customer Ref: <strong>{this.state.selectedPoCustomerRef}</strong>
                                                        <br />
                                                        Project: <strong>{this.state.selectedPoProject}</strong>
                                                        <br />
                                                    </Panel>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div> : <div></div>
                                }
                            </Col>
                        </Panel>
                    </Col>
                </Row>
            </div>
        }
        else {
            ContainerJsonTreeDiv = <div></div>;
        }



        let poHistoryTimeline;
        if (this.props.isPoHistoryLoading) {
            poHistoryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.PoHistoryData[0]) !== 'undefined' && this.props.PoHistoryData != null) {
            poHistoryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.PoHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.PoHistoryDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l><br />{item.PoHistoryNotes} &nbsp;
                                            </l>
                                            {item.PO != null ? <l><br /> PO:  {item.PO} </l> : ' '}
                                            {item.CustomerRef != null ? <l><br /> Customer Ref:  {item.CustomerRef} </l> : ' '}
                                            {item.Project != null ? <l><br /> Project:  {item.Project} </l> : ' '}
                                            {item.WarehouseLocationName != null ? <l><br /> Warehouse Location:  {item.WarehouseLocationName} </l> : ' '}
                                            {item.PreviousWarehouseLocationName != null ? <l><br /> Previous Warehouse Location:  {item.PreviousWarehouseLocationName} </l> : ' '}
                                            {item.CaseCount != null ? <l><br /> Case Count:  {item.CaseCount} </l> : ' '}
                                            {item.PalletCount != null ? <l><br /> Pallet Count:  {item.PalletCount} </l> : ' '}
                                            {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize} </l> : ' '}
                                            {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}
                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            poHistoryTimeline = <div></div>
        }




        let warehouseLocationTable;
        if (this.props.isPoLocationLoading) {
            warehouseLocationTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.PoLocationData[0]) !== 'undefined' && this.props.PoLocationData != null) {
            warehouseLocationTable = <div>

                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Warehouse Locations" style={{ marginBottom: 10 }}>

                            <Row>
                                {RTableGeneral(
                                    tableIdCol,     //   keyColumn,
                                    this.props.PoLocationData,     //   tableData,
                                    TransContainerWarehouseLocationDataElements,       //   DataElements,
                                    this.props.isPoLocationLoading,        //   loading,

                                    this.props.isMobile,        //   isMobile,
                                    this.props.history,     //   history,

                                    sortColumn,     //   sortColumn,
                                    sortType,       //   sortType,
                                    this.handleSortColumn,      //   handleSortColumn,

                                    null, // checkColEntry,
                                    null,       //   checkedKeys,
                                    null,       //   handleCheckAll,
                                    null,       //   handleCheck,

                                    false,      //   boolSelectButton,
                                    null,       //   onClickSelect,
                                    null,       //   selectButtonText,
                                    null,       //   selectHighlightRowKey,

                                    true,       //   link1bool,
                                    'Edit',     //   link1text,
                                    this.onclickEditWarehouseLocation,     //   link1onClick,

                                    false,       //   link2bool,
                                    null,       //   link2text,
                                    null  //   link2onClick

                                )}
                            </Row>

                        </Panel>
                    </Col>
                </Row>

            </div>

        }
        else {
            warehouseLocationTable = <div></div>
        }

        let editWarehouseLocationDrawerDiv;
        let unsavedLocationChanges
        if (!this.state.showEditWarehouseLocationDrawer) {
            editWarehouseLocationDrawerDiv = <div></div>
            unsavedLocationChanges = false
        }
        else {
            unsavedLocationChanges = this.state.unsavedEditWarehouseLocationValues
            editWarehouseLocationDrawerDiv = <EditWarehouseLocationDrawer {...this.props} state={this.state} onChangeExistingOversize={this.onChangeExistingOversize} onChangeLocation={this.onChangeEditLocationDrawer} onClickAddLocation={this.onClickAddLocation} confirm={this.onClickSaveEditWarehouseLocation} close={this.closeEditWarehouseLocationDrawer} unsavedChanges={unsavedLocationChanges} />
        }


        let removeFromLoadModalDiv;
        if (!this.state.removeFromModalShown) {
            removeFromLoadModalDiv = <div></div>
        }
        else if (this.props.isPoLoadLocationHistoryLoading) {
            removeFromLoadModalDiv = <div></div>
        }
        else {
            removeFromLoadModalDiv = <RemoveFromLoadModal {...this.props} state={this.state} titlePO={this.props.PoData[0].PO} removeFromModalShown={this.state.removeFromModalShown} onChangeExisting={this.onChangeExistingBuildLoadDrawer} onChangeLocation={this.onChangeLocation} handleOpen={this.handleOpenRemoveFromLoadModal} handleClose={this.handleCloseRemoveFromLoadModal} handleSave={this.handleSaveRemoveFromLoadModal} />
        }




        return (
            <div>
                <h3>
                    {pageHeader}  {editButton}
                </h3>

                <PanelGroup>

                    {detailsDiv}
                    {/* {recoveryTimeline} */}
                    {ContainerJsonTreeDiv}

                </PanelGroup>


                <Panel header="Load List" bordered style={{ marginBottom: 10 }}>
                    {loadDiv}
                    {rTable}
                </Panel>

                {warehouseLocationTable}
                {poHistoryTimeline}
                {loadTimeline}



                <EditPoDrawer {...this.props} state={this.state} onChangeEditPo={this.onChangeEditPo} onChangeCustomerRef={this.onChangeCustomerRef} onChangeProject={this.onChangeProject} confirm={this.onClickConfirmEditPo} close={this.closeEditPoDrawer} unsavedChanges={unsavedEditPoChanges} />
                {editWarehouseLocationDrawerDiv}

                {removeFromLoadModalDiv}

                {/* <RUploader onUpload={this.onUpload} uploadApi={appAPI.API.data.FileUploadAPI} /> */}

            </div>
        );

    }
}

export default withRouter(PoDetails);

