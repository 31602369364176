/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';





export const DOWNLOAD_FILE_FROM_S3_GET = 'DOWNLOAD_FILE_FROM_S3_GET';
export const DOWNLOAD_FILE_FROM_S3_COMPLETE = 'DOWNLOAD_FILE_FROM_S3_COMPLETE';
export const DOWNLOAD_FILE_FROM_S3_ERROR = 'DOWNLOAD_FILE_FROM_S3_ERROR';






/* ################################### VAS ACTIONS TYPES ################################### */
export const ON_ERROR = 'ON_ERROR';


export const CTRLTWR_GENERATE_PRO_GET = 'CTRLTWR_GENERATE_PRO_GET';
export const CTRLTWR_GENERATE_PRO_COMPLETE = 'CTRLTWR_GENERATE_PRO_COMPLETE';
export const CTRLTWR_GENERATE_PRO_ERROR = 'CTRLTWR_GENERATE_PRO_ERROR';

export const CTRLTWR_MODE_REF_GET = 'CTRLTWR_MODE_REF_GET';
export const CTRLTWR_MODE_REF_COMPLETE = 'CTRLTWR_MODE_REF_COMPLETE';
export const CTRLTWR_MODE_REF_ERROR = 'CTRLTWR_MODE_REF_ERROR';

export const CTRLTWR_AIRPORT_GET = 'CTRLTWR_AIRPORT_GET';
export const CTRLTWR_AIRPORT_COMPLETE = 'CTRLTWR_AIRPORT_COMPLETE';
export const CTRLTWR_AIRPORT_ERROR = 'CTRLTWR_AIRPORT_ERROR';

export const CTRLTWR_SEAPORT_GET = 'CTRLTWR_SEAPORT_GET';
export const CTRLTWR_SEAPORT_COMPLETE = 'CTRLTWR_SEAPORT_COMPLETE';
export const CTRLTWR_SEAPORT_ERROR = 'CTRLTWR_SEAPORT_ERROR';

export const CTRLTWR_MILESTONE_REF_GET = 'CTRLTWR_MILESTONE_REF_GET';
export const CTRLTWR_MILESTONE_REF_COMPLETE = 'CTRLTWR_MILESTONE_REF_COMPLETE';
export const CTRLTWR_MILESTONE_REF_ERROR = 'CTRLTWR_MILESTONE_REF_ERROR';

export const CTRLTWR_COUNTRY_GET = 'CTRLTWR_COUNTRY_GET';
export const CTRLTWR_COUNTRY_COMPLETE = 'CTRLTWR_COUNTRY_COMPLETE';
export const CTRLTWR_COUNTRY_ERROR = 'CTRLTWR_COUNTRY_ERROR';

export const CTRLTWR_STATE_GET = 'CTRLTWR_STATE_GET';
export const CTRLTWR_STATE_COMPLETE = 'CTRLTWR_STATE_COMPLETE';
export const CTRLTWR_STATE_ERROR = 'CTRLTWR_STATE_ERROR';

export const CTRLTWR_SHIPMENT_GET = 'CTRLTWR_SHIPMENT_GET';
export const CTRLTWR_SHIPMENT_COMPLETE = 'CTRLTWR_SHIPMENT_COMPLETE';
export const CTRLTWR_SHIPMENT_ERROR = 'CTRLTWR_SHIPMENT_ERROR';

export const CTRLTWR_POST_SHIPMENT_GET = 'CTRLTWR_POST_SHIPMENT_GET';
export const CTRLTWR_POST_SHIPMENT_COMPLETE = 'CTRLTWR_POST_SHIPMENT_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_ERROR = 'CTRLTWR_POST_SHIPMENT_ERROR';

export const CTRLTWR_QUOTING_SHIPMENT_GET = 'CTRLTWR_QUOTING_SHIPMENT_GET';
export const CTRLTWR_QUOTING_SHIPMENT_COMPLETE = 'CTRLTWR_QUOTING_SHIPMENT_COMPLETE';
export const CTRLTWR_QUOTING_SHIPMENT_ERROR = 'CTRLTWR_QUOTING_SHIPMENT_ERROR';

export const CTRLTWR_POST_QUOTING_SHIPMENT_GET = 'CTRLTWR_POST_QUOTING_SHIPMENT_GET';
export const CTRLTWR_POST_QUOTING_SHIPMENT_COMPLETE = 'CTRLTWR_POST_QUOTING_SHIPMENT_COMPLETE';
export const CTRLTWR_POST_QUOTING_SHIPMENT_ERROR = 'CTRLTWR_POST_QUOTING_SHIPMENT_ERROR';

export const CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_GET = 'CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_GET';
export const CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_COMPLETE = 'CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_COMPLETE';
export const CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_ERROR = 'CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_ERROR';

export const CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_GET = 'CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_GET';
export const CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_COMPLETE = 'CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_COMPLETE';
export const CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_ERROR = 'CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_ERROR';

export const CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_GET = 'CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_GET';
export const CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_COMPLETE = 'CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_COMPLETE';
export const CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_ERROR = 'CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_ERROR';

export const CTRLTWR_QUOTING_LINE_ITEM_GET = 'CTRLTWR_QUOTING_LINE_ITEM_GET';
export const CTRLTWR_QUOTING_LINE_ITEM_COMPLETE = 'CTRLTWR_QUOTING_LINE_ITEM_COMPLETE';
export const CTRLTWR_QUOTING_LINE_ITEM_ERROR = 'CTRLTWR_QUOTING_LINE_ITEM_ERROR';

export const CTRLTWR_QUOTING_LINE_ITEM_UOM_GET = 'CTRLTWR_QUOTING_LINE_ITEM_UOM_GET';
export const CTRLTWR_QUOTING_LINE_ITEM_UOM_COMPLETE = 'CTRLTWR_QUOTING_LINE_ITEM_UOM_COMPLETE';
export const CTRLTWR_QUOTING_LINE_ITEM_UOM_ERROR = 'CTRLTWR_QUOTING_LINE_ITEM_UOM_ERROR';

export const CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_GET = 'CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_GET';
export const CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_COMPLETE = 'CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_COMPLETE';
export const CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_ERROR = 'CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_ERROR';

export const CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_GET = 'CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_GET';
export const CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_COMPLETE = 'CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_COMPLETE';
export const CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_ERROR = 'CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_ERROR';

export const CTRLTWR_QUOTING_REPORT_GET = 'CTRLTWR_QUOTING_REPORT_GET';
export const CTRLTWR_QUOTING_REPORT_COMPLETE = 'CTRLTWR_QUOTING_REPORT_COMPLETE';
export const CTRLTWR_QUOTING_REPORT_ERROR = 'CTRLTWR_QUOTING_REPORT_ERROR';

export const CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_GET = 'CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_GET';
export const CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_COMPLETE = 'CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_COMPLETE';
export const CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_ERROR = 'CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_ERROR';

export const CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_GET = 'CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_GET';
export const CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_COMPLETE = 'CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_COMPLETE';
export const CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_ERROR = 'CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_ERROR';

export const CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_GET = 'CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_GET';
export const CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_COMPLETE = 'CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_COMPLETE';
export const CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_ERROR = 'CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_ERROR';

export const CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_GET = 'CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_GET';
export const CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_COMPLETE = 'CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_COMPLETE';
export const CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_ERROR = 'CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_ERROR';

export const CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_GET = 'CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_GET';
export const CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_COMPLETE = 'CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_COMPLETE';
export const CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_ERROR = 'CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_ERROR';

export const CTRLTWR_SHIPMENT_NOTES_PRIVATE_GET = 'CTRLTWR_SHIPMENT_NOTES_PRIVATE_GET';
export const CTRLTWR_SHIPMENT_NOTES_PRIVATE_COMPLETE = 'CTRLTWR_SHIPMENT_NOTES_PRIVATE_COMPLETE';
export const CTRLTWR_SHIPMENT_NOTES_PRIVATE_ERROR = 'CTRLTWR_SHIPMENT_NOTES_PRIVATE_ERROR';

export const CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_GET = 'CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_GET';
export const CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_COMPLETE = 'CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_ERROR = 'CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_ERROR';

export const CTRLTWR_SHIPMENT_NOTES_PUBLIC_GET = 'CTRLTWR_SHIPMENT_NOTES_PUBLIC_GET';
export const CTRLTWR_SHIPMENT_NOTES_PUBLIC_COMPLETE = 'CTRLTWR_SHIPMENT_NOTES_PUBLIC_COMPLETE';
export const CTRLTWR_SHIPMENT_NOTES_PUBLIC_ERROR = 'CTRLTWR_SHIPMENT_NOTES_PUBLIC_ERROR';

export const CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_GET = 'CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_GET';
export const CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_COMPLETE = 'CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_ERROR = 'CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_ERROR';

export const CTRLTWR_SHIPMENT_LEGS_DATA_GET = 'CTRLTWR_SHIPMENT_LEGS_DATA_GET';
export const CTRLTWR_SHIPMENT_LEGS_DATA_COMPLETE = 'CTRLTWR_SHIPMENT_LEGS_DATA_COMPLETE';
export const CTRLTWR_SHIPMENT_LEGS_DATA_ERROR = 'CTRLTWR_SHIPMENT_LEGS_DATA_ERROR';

export const CTRLTWR_POST_SHIPMENT_LEGS_GET = 'CTRLTWR_POST_SHIPMENT_LEGS_GET';
export const CTRLTWR_POST_SHIPMENT_LEGS_COMPLETE = 'CTRLTWR_POST_SHIPMENT_LEGS_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_LEGS_ERROR = 'CTRLTWR_POST_SHIPMENT_LEGS_ERROR';

export const CTRLTWR_POST_SHIPMENT_LEGS_DATA_GET = 'CTRLTWR_POST_SHIPMENT_LEGS_DATA_GET';
export const CTRLTWR_POST_SHIPMENT_LEGS_DATA_COMPLETE = 'CTRLTWR_POST_SHIPMENT_LEGS_DATA_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_LEGS_DATA_ERROR = 'CTRLTWR_POST_SHIPMENT_LEGS_DATA_ERROR';

export const CTRLTWR_SHIPMENT_LEGS_MILESTONES_GET = 'CTRLTWR_SHIPMENT_LEGS_MILESTONES_GET';
export const CTRLTWR_SHIPMENT_LEGS_MILESTONES_COMPLETE = 'CTRLTWR_SHIPMENT_LEGS_MILESTONES_COMPLETE';
export const CTRLTWR_SHIPMENT_LEGS_MILESTONES_ERROR = 'CTRLTWR_SHIPMENT_LEGS_MILESTONES_ERROR';

export const CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_GET = 'CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_GET';
export const CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_COMPLETE = 'CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_ERROR = 'CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_ERROR';

export const CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_GET = 'CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_GET';
export const CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_COMPLETE = 'CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_ERROR = 'CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_ERROR';

export const CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_GET = 'CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_GET';
export const CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_COMPLETE = 'CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_COMPLETE';
export const CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_ERROR = 'CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_ERROR';

export const CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_GET = 'CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_GET';
export const CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_COMPLETE = 'CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_COMPLETE';
export const CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_ERROR = 'CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_ERROR';

export const CTRLTWR_SHIPMENT_LEGS_PO_GET = 'CTRLTWR_SHIPMENT_LEGS_PO_GET';
export const CTRLTWR_SHIPMENT_LEGS_PO_COMPLETE = 'CTRLTWR_SHIPMENT_LEGS_PO_COMPLETE';
export const CTRLTWR_SHIPMENT_LEGS_PO_ERROR = 'CTRLTWR_SHIPMENT_LEGS_PO_ERROR';

export const CTRLTWR_SHIPMENT_LEGS_HBOL_GET = 'CTRLTWR_SHIPMENT_LEGS_HBOL_GET';
export const CTRLTWR_SHIPMENT_LEGS_HBOL_COMPLETE = 'CTRLTWR_SHIPMENT_LEGS_HBOL_COMPLETE';
export const CTRLTWR_SHIPMENT_LEGS_HBOL_ERROR = 'CTRLTWR_SHIPMENT_LEGS_HBOL_ERROR';

export const CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_GET = 'CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_GET';
export const CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_COMPLETE = 'CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_ERROR = 'CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_ERROR';

export const CTRLTWR_POST_SHIPMENT_LEGS_HBOL_GET = 'CTRLTWR_POST_SHIPMENT_LEGS_HBOL_GET';
export const CTRLTWR_POST_SHIPMENT_LEGS_HBOL_COMPLETE = 'CTRLTWR_POST_SHIPMENT_LEGS_HBOL_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_LEGS_HBOL_ERROR = 'CTRLTWR_POST_SHIPMENT_LEGS_HBOL_ERROR';

export const CTRLTWR_POST_SHIPMENT_LEGS_PO_GET = 'CTRLTWR_POST_SHIPMENT_LEGS_PO_GET';
export const CTRLTWR_POST_SHIPMENT_LEGS_PO_COMPLETE = 'CTRLTWR_POST_SHIPMENT_LEGS_PO_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_LEGS_PO_ERROR = 'CTRLTWR_POST_SHIPMENT_LEGS_PO_ERROR';

export const CTRLTWR_TIMEZONE_GET = 'CTRLTWR_TIMEZONE_GET';
export const CTRLTWR_TIMEZONE_COMPLETE = 'CTRLTWR_TIMEZONE_COMPLETE';
export const CTRLTWR_TIMEZONE_ERROR = 'CTRLTWR_TIMEZONE_ERROR';

export const CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_GET = 'CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_GET';
export const CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_COMPLETE = 'CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_COMPLETE';
export const CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_ERROR = 'CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_ERROR';

export const CTRLTWR_ALERTS_SHIPMENT_DELIVERY_GET = 'CTRLTWR_ALERTS_SHIPMENT_DELIVERY_GET';
export const CTRLTWR_ALERTS_SHIPMENT_DELIVERY_COMPLETE = 'CTRLTWR_ALERTS_SHIPMENT_DELIVERY_COMPLETE';
export const CTRLTWR_ALERTS_SHIPMENT_DELIVERY_ERROR = 'CTRLTWR_ALERTS_SHIPMENT_DELIVERY_ERROR';

export const CTRLTWR_ALERTS_SHIPMENT_SUMMARY_GET = 'CTRLTWR_ALERTS_SHIPMENT_SUMMARY_GET';
export const CTRLTWR_ALERTS_SHIPMENT_SUMMARY_COMPLETE = 'CTRLTWR_ALERTS_SHIPMENT_SUMMARY_COMPLETE';
export const CTRLTWR_ALERTS_SHIPMENT_SUMMARY_ERROR = 'CTRLTWR_ALERTS_SHIPMENT_SUMMARY_ERROR';

export const CTRLTWR_ALERTS_STATS_GET = 'CTRLTWR_ALERTS_STATS_GET';
export const CTRLTWR_ALERTS_STATS_COMPLETE = 'CTRLTWR_ALERTS_STATS_COMPLETE';
export const CTRLTWR_ALERTS_STATS_ERROR = 'CTRLTWR_ALERTS_STATS_ERROR';

export const CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_GET = 'CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_GET';
export const CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_COMPLETE = 'CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_ERROR = 'CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_ERROR';

export const CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_GET = 'CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_GET';
export const CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_COMPLETE = 'CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_ERROR = 'CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_ERROR';

export const CTRLTWR_CHART_SHIPMENT_DELIVERY_GET = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_GET';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_COMPLETE = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_COMPLETE';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_ERROR = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_ERROR';

export const CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_GET = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_GET';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_COMPLETE = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_COMPLETE';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_ERROR = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_ERROR';

export const CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_GET = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_GET';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_COMPLETE = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_COMPLETE';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_ERROR = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_ERROR';

export const CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_GET = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_GET';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_COMPLETE = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_COMPLETE';
export const CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_ERROR = 'CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_ERROR';

export const CTRLTWR_SHIPMENT_FILEUPLOAD_GET = 'CTRLTWR_SHIPMENT_FILEUPLOAD_GET';
export const CTRLTWR_SHIPMENT_FILEUPLOAD_COMPLETE = 'CTRLTWR_SHIPMENT_FILEUPLOAD_COMPLETE';
export const CTRLTWR_SHIPMENT_FILEUPLOAD_ERROR = 'CTRLTWR_SHIPMENT_FILEUPLOAD_ERROR';

export const CTRLTWR_POST_SHIPMENT_FILEUPLOAD_GET = 'CTRLTWR_POST_SHIPMENT_FILEUPLOAD_GET';
export const CTRLTWR_POST_SHIPMENT_FILEUPLOAD_COMPLETE = 'CTRLTWR_POST_SHIPMENT_FILEUPLOAD_COMPLETE';
export const CTRLTWR_POST_SHIPMENT_FILEUPLOAD_ERROR = 'CTRLTWR_POST_SHIPMENT_FILEUPLOAD_ERROR';

export const EDI_990_QUEUE_GET = 'EDI_990_QUEUE_GET';
export const EDI_990_QUEUE_COMPLETE = 'EDI_990_QUEUE_COMPLETE';
export const EDI_990_QUEUE_ERROR = 'EDI_990_QUEUE_ERROR';

export const EDI_204_GET = 'EDI_204_GET';
export const EDI_204_COMPLETE = 'EDI_204_COMPLETE';
export const EDI_204_ERROR = 'EDI_204_ERROR';

export const EDI_POST_990_QUEUE_GET = 'EDI_POST_990_QUEUE_GET';
export const EDI_POST_990_QUEUE_COMPLETE = 'EDI_POST_990_QUEUE_COMPLETE';
export const EDI_POST_990_QUEUE_ERROR = 'EDI_POST_990_QUEUE_ERROR';

export const EDI_204_OID_GET = 'EDI_204_OID_GET';
export const EDI_204_OID_COMPLETE = 'EDI_204_OID_COMPLETE';
export const EDI_204_OID_ERROR = 'EDI_204_OID_ERROR';

export const EDI_204_L5_GET = 'EDI_204_L5_GET';
export const EDI_204_L5_COMPLETE = 'EDI_204_L5_COMPLETE';
export const EDI_204_L5_ERROR = 'EDI_204_L5_ERROR';