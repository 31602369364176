import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel, Loader } from 'rsuite';
// import ReactExport from "react-export-excel";
import * as moment from 'moment'

import { formatForExcelExport, formatBitToTrueFalse } from '../../../resources/logic/functions';


import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")



function formatDataSet(
    headerLoadData,
    bodyPoData
) {
    let formattedData = []

    try {
        formattedData = [
            {
                columns: [
                    { title: "Depart Manifest", style: { font: { sz: "24", bold: true } } },
                ],
                data: [
                    [
                        { value: "Pickup Date", style: { font: { bold: true } } },
                        { value: formatForExcelExport(headerLoadData.PickupDate) },
                    ],
                    [
                        { value: "Delivery Date", style: { font: { bold: true } } },
                        { value: formatForExcelExport(headerLoadData.DeliveryDate) },
                    ],
                    [
                        { value: "BOL #", style: { font: { bold: true } } },
                        { value: formatForExcelExport(headerLoadData.BOL) },
                    ],
                    [
                        { value: "Carrier", style: { font: { bold: true } } },
                        { value: formatForExcelExport(headerLoadData.Carrier) },
                    ],
                    [
                        { value: "Trailer #", style: { font: { bold: true } } },
                        { value: formatForExcelExport(headerLoadData.Trailer) },
                    ],
                    [
                        { value: "Seal #", style: { font: { bold: true } } },
                        { value: formatForExcelExport(headerLoadData.Seal) },
                    ]
                ]
            },

            {
                ySteps: 3,
                columns: [
                    { title: "Customer Ref", width: { wpx: 100 } },
                    { title: "SKU", width: { wpx: 90 } },
                    // { title: "Orders", width: { wpx: 150 } },
                    { title: "Loaded Units", width: { wpx: 100 } },
                    { title: "Loaded Cases", width: { wpx: 100 } },
                    // { title: "Expected CTNS", width: { wpx: 100 } },
                    // { title: "Weight (CHW)", width: { wpx: 100 } },
                    { title: "Pallets", width: { wpx: 60 } },
                    { title: "Oversize", width: { wpx: 150 } },
                    // { title: "DC Code", width: { wpx: 90 } },
                ],
                data:
                    bodyPoData.map((record, index) => {
                        return [
                            { value: formatForExcelExport(record.CustomerRef) },
                            { value: formatForExcelExport(record.SKU) },
                            // { value: formatForExcelExport(record.Orders) },
                            { value: formatForExcelExport(record.Units) },
                            { value: formatForExcelExport(record.Cases) },
                            // { value: formatForExcelExport(record.Cartons) },
                            // { value: formatForExcelExport(record.Weight) },
                            { value: formatForExcelExport(record.Pallets) },
                            { value: formatBitToTrueFalse(record.Oversize) },
                            // { value: formatForExcelExport(record.DCCode) }
                        ];
                    })
            }
        ];
    }
    catch (e) { }

    return (
        formattedData
    )
}



function LoadExportModal({
    isModalShown,
    handleClose,
    state, ...props
}) {
    let exportDiv;
    let modalBodyDiv;
    if (props.isInventoryLoadSkuLoading) {
        modalBodyDiv = <div></div>
        exportDiv =
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
    }
    else if (typeof (props.InventoryLoadSkuData) !== 'undefined' && props.InventoryLoadSkuData != null && typeof (state.loadDataExportModal) !== 'undefined' && state.loadDataExportModal != null) {
        modalBodyDiv = <div>  Carrier: {state.loadDataExportModal.Carrier} <br /> Trailer: {state.loadDataExportModal.Trailer} </div>
        exportDiv = <div>
            {(props.isMobile == true) ? <div></div> :
                <div>
                    <Row>
                        <ExcelFile element={<Button style={{ marginRight: '10px' }}>Download Excel</Button>} filename={'Load_' + currentDateTime}>
                            <ExcelSheet dataSet={formatDataSet(state.loadDataExportModal, props.InventoryLoadSkuData)} name="Delivery Manifest">
                            </ExcelSheet>
                        </ExcelFile>

                        <Button onClick={handleClose} >
                            Cancel
                        </Button>
                    </Row>
                </div>
            }
        </div>
    }
    else {
        modalBodyDiv = <div></div>
        exportDiv = <div></div>
    }




    return (

        <div className="modal-container">

            <Modal  keyboard={false} show={isModalShown} onHide={handleClose} size={'xs'}>
                <Modal.Header>
                    <Modal.Title>Export Depart Manifest</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBodyDiv}


                </Modal.Body>
                <Modal.Footer>
                    {exportDiv}
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default LoadExportModal;

