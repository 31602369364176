import React, {
    PureComponent
} from 'react';
import { Modal, Button, ControlLabel, Col, FormGroup, InputGroup, InputPicker, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import BarcodeMultipleCard from '../Barcode/BarcodeMultipleCard';
import PalletContentsNoMapping from '../Barcode/PalletContentsNoMapping';
import CaseContentsNoMapping from '../Barcode/CaseContentsNoMapping';

const mountRef = React.createRef();

function NewBarcodesModal({
    newBarcodeModalShown,
    closePrintBarcodes,
    // onPrintBarcodes,
    state, ...props
}) {


    const groupedPalletContents = props.InventoryRecoveryPalletContent
        ? props.InventoryRecoveryPalletContent.reduce((groups, content) => {
            const key = content.PalletBarcode;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(content);
            return groups;
        }, {})
        : {};


    const palletContents = Object.keys(groupedPalletContents).map((barcode, index) => {
        return (
            <div key={index} style={{ border: '1px solid black', margin: '10px', padding: '10px' }}>
                <strong>Pallet: {barcode}</strong><br />
                Warehouse Location: {groupedPalletContents[barcode][0].WarehouseLocationName}
                {groupedPalletContents[barcode].map((content, index) => (
                    <div key={index} style={{ margin: '10px 0' }}>
                        <PalletContentsNoMapping content={content} />
                        {/* <hr /> */}
                    </div>
                ))}
            </div>
        );
    });

    //only displays first 10
    const palletBarcodes = Object.keys(groupedPalletContents).map((barcode, index) => {
        const contents = groupedPalletContents[barcode];
        const remainingItems = Math.max(contents.length - 9, 0);
        return (
            <BarcodeMultipleCard barcode={barcode.trim()} key={barcode} barcodeContent={
                [...contents.slice(0, 9).map((content, index) => (
                    <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                        Case SKU: {content.Case_SKU} (Qty: {content.CaseQty})
                        {content.LotCode ? ` Lot: ${content.LotCode}` : null}
                    </p>
                )), remainingItems > 0 ? <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>{remainingItems} more rows...</p> : null]
            } />
        );
    });




    const groupedCaseContents = props.InventoryRecoveryCaseSku
        ? props.InventoryRecoveryCaseSku.reduce((groups, content) => {
            const key = `${content.CaseBarcode}-_${content.CaseLotCode}-_${content.CaseSerialNumber}-_${content.WarehouseLocationName}-_${content.PalletBarcode}`;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(content);
            return groups;
        }, {})
        : {};

    const caseContents = Object.keys(groupedCaseContents).map((key, index) => {
        const [barcode, lotCode, serialNumber, warehouseLocation, palletBarcode] = key.split('-_');
        return (
            <div key={index} style={{ border: '1px solid black', margin: '10px', padding: '10px' }}>
                <strong>Case: {barcode}</strong><br />
                <strong>Pallet Barcode: {palletBarcode}</strong><br />
                <strong>Warehouse Location: {warehouseLocation}</strong><br />
                <strong>Lot Code: {lotCode}</strong><br />
                <strong>Serial Number: {serialNumber}</strong><br />
                {groupedCaseContents[key].map((content, index) => (
                    <div key={index} style={{ margin: '10px 0' }}>
                        SKU: {content.SKU}<br />
                        Units In Warehouse: {content.UnitsInWhse}<br />
                        Lot Code: {content.LotCode}<br />
                        Serial Number: {content.SerialNumber}<br />
                        <hr />
                    </div>
                ))}
            </div>
        );
    });




    const caseBarcodes = props.InventoryRecoveryCaseInfo
        ? Object.keys(props.InventoryRecoveryCaseInfo).map(barcode => {
            const contents = props.InventoryRecoveryCaseInfo[barcode];
            return (
                <BarcodeMultipleCard barcode={contents['CaseBarcode'].trim()} key={contents['CaseBarcode'].trim()} barcodeContent={
                    <div>
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Case SKU: {contents.Case_SKU}
                        </p>
                        {contents.Case_LotCode ?
                            <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                                Lot: {contents.Case_LotCode}
                            </p>
                            : null}
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Pallet Barcode: {contents.PalletBarcode}
                        </p>
                    </div>
                } />
            );
        })
        : null;





    function onPrintPalletBarcode() {

        var container = document.getElementById("div-svg-pallet");
        var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();

    }

    function onPrintCaseBarcode() {
        var container = document.getElementById("div-svg-case");
        var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();

    }

    function onPrintContent() {
        var container = document.getElementById("div-content");
        var mySVG = document.getElementById("barcode-canvas");
        var printWindow = window.open('', 'PrintMap',
            'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }


    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={newBarcodeModalShown} onHide={closePrintBarcodes} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>New Barcodes and Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <FormGroup>


                        <div id="div-content" style={{ display: 'flex', flexDirection: 'column' }}>
                            {palletContents}
                            <br />
                            <Divider />
                            <br />
                            {caseContents}
                        </div>

                    </FormGroup>


                    <FormGroup>

                        <div id="div-svg-pallet" style={{ display: 'flex', flexDirection: 'column' }}>
                            {palletBarcodes}
                        </div>

                        <div id="div-svg-case" style={{ display: 'flex', flexDirection: 'column' }}>
                            {caseBarcodes}
                        </div>

                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    {props.InventoryRecoveryPalletBarcodes && (
                        <Button onClick={(e) => { onPrintPalletBarcode(e) }} appearance="primary">
                            Print Pallet Barcodes
                        </Button>
                    )}
                    {props.InventoryRecoveryCaseBarcodes && (
                        <Button onClick={(e) => { onPrintCaseBarcode(e) }} appearance="primary">
                            Print Case Barcodes
                        </Button>
                    )}

                    {(props.InventoryRecoveryPalletContent || props.InventoryRecoveryCaseSku) && (
                        <Button onClick={(e) => { onPrintContent(e) }} appearance="primary">
                            Print Content
                        </Button>
                    )}

                    <Button onClick={closePrintBarcodes} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default NewBarcodesModal;

