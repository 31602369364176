import { connect } from 'react-redux';
import React, { Component } from 'react';
import Shipment from './Shipment'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import * as userActions from '../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isContainerLoading: store.transReducer.isContainerLoading,
    ContainerData: store.transReducer.ContainerData,

    ContainerPostSuccessful: store.transReducer.ContainerPostSuccessful,
    NewContainerId: store.transReducer.NewContainerId,

    ShipmentReceivePostSuccessful: store.transReducer.ShipmentReceivePostSuccessful,

    MbolPostSuccessful: store.transReducer.MbolPostSuccessful,
    NewMbolId: store.transReducer.NewMbolId,

    HbolPostSuccessful: store.transReducer.HbolPostSuccessful,
    NewHbolId: store.transReducer.NewHbolId,

    PoPostSuccessful: store.transReducer.PoPostSuccessful,
    NewPoId: store.transReducer.NewPoId,


    isContainerJsonTreeLoading: store.transReducer.isContainerJsonTreeLoading,
    ContainerJsonTreeData: store.transReducer.ContainerJsonTreeData,


    isShipmentReceiveLoading: store.transReducer.isShipmentReceiveLoading,
    ShipmentReceiveData: store.transReducer.ShipmentReceiveData,

    isTransCustomerRefLoading: store.transReducer.isTransCustomerRefLoading,
    TransCustomerRefData: store.transReducer.TransCustomerRefData,

    isTransProjectLoading: store.transReducer.isTransProjectLoading,
    TransProjectData: store.transReducer.TransProjectData,

    isPoLocationLoading: store.transReducer.isPoLocationLoading,
    PoLocationData: store.transReducer.PoLocationData,

    PoLocationPostSuccessful: store.transReducer.PoLocationPostSuccessful,




    MawbEvolveDataSaveSuccessful: store.transReducer.MawbEvolveDataSaveSuccessful,

    MawbAddSuccessful: store.transReducer.MawbAddSuccessful,

    isMawbCarrierLoading: store.transReducer.isMawbCarrierLoading,
    MawbCarrierData: store.transReducer.MawbCarrierData,


    isHawbLoading: store.transReducer.isHawbLoading,
    HawbData: store.transReducer.HawbData,

    HawbRecoverySaveSuccessful: store.transReducer.HawbRecoverySaveSuccessful,

    isHawbConsigneeLoading: store.transReducer.isHawbConsigneeLoading,
    HawbConsigneeData: store.transReducer.HawbConsigneeData,

    PostHawbSuccessful: store.transReducer.PostHawbSuccessful,

    MawbRecoverySaveSuccessful: store.transReducer.MawbRecoverySaveSuccessful,


    PostUserColorSuccessful: store.transReducer.PostUserColorSuccessful,

    // isMawbRecoverySummaryLoading: store.transReducer.isMawbRecoverySummaryLoading,
    // MawbRecoverySummaryData: store.transReducer.MawbRecoverySummaryData,

});

const mapDispatchToProps = dispatch =>
({

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    transContainerGet: (_email, _authToken, _ContainerID, _Container, _ContainerStatusID, _LCLFCL, _DeliveryAppointmentStart, _DeliveryAppointmentEnd, _ReceivedDTStart, _ReceivedDTEnd, _EtdStart, _EtdEnd, _EtaStart, _EtaEnd, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) => {
        dispatch(actions.transContainerGet(_email, _authToken, _ContainerID, _Container, _ContainerStatusID, _LCLFCL, _DeliveryAppointmentStart, _DeliveryAppointmentEnd, _ReceivedDTStart, _ReceivedDTEnd, _EtdStart, _EtdEnd, _EtaStart, _EtaEnd, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID))
    },

    transContainerPost: (_email, _authToken, _ContainerID, _Container, _LCLFCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID) => {
        dispatch(actions.transContainerPost(_email, _authToken, _ContainerID, _Container, _LCLFCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID))
    },


    transContainerJsonTreeGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) => {
        dispatch(actions.transContainerJsonTreeGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID))
    },

    transCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    transProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    transPoLocationGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoLocationGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID))
    },

    transPoLocationPost: (_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize) => {
        dispatch(actions.transPoLocationPost(_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize))
    },

    transMbolPost: (_email, _authToken, _MbolID, _MBOL, _ContainerID, _Delete, _WarehouseID, _CustomerID) => {
        dispatch(actions.transMbolPost(_email, _authToken, _MbolID, _MBOL, _ContainerID, _Delete, _WarehouseID, _CustomerID))
    },
    transHbolPost: (_email, _authToken, _HbolID, _HBOL, _MbolID, _Delete, _WarehouseID, _CustomerID) => {
        dispatch(actions.transHbolPost(_email, _authToken, _HbolID, _HBOL, _MbolID, _Delete, _WarehouseID, _CustomerID))
    },
    transPoPost: (_email, _authToken, _PoID, _PO, _HbolID, _CustomerRef, _CaseCount, _PalletCount, _Notes, _Project, _Delete, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoPost(_email, _authToken, _PoID, _PO, _HbolID, _CustomerRef, _CaseCount, _PalletCount, _Notes, _Project, _Delete, _WarehouseID, _CustomerID))
    },

    transShipmentReceiveGet: (_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _WarehouseID, _CustomerID) => {
        dispatch(actions.transShipmentReceiveGet(_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _WarehouseID, _CustomerID))
    },

    transShipmentReceivePost: (_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _CaseCountExpected, _CasesReceived, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID) => {
        dispatch(actions.transShipmentReceivePost(_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _CaseCountExpected, _CasesReceived, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID))
    },
});

class ShipmentContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.handleUrlParam();
        // this.props.userWarehousesGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }


        if (prevProps.ContainerPostSuccessful !== this.props.ContainerPostSuccessful && this.props.ContainerPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        // MbolPostSuccessful: store.transReducer.MbolPostSuccessful,
        // HbolPostSuccessful: store.transReducer.HbolPostSuccessful,
        // PoPostSuccessful: store.transReducer.PoPostSuccessful,

        if (prevProps.ShipmentReceivePostSuccessful !== this.props.ShipmentReceivePostSuccessful && this.props.ShipmentReceivePostSuccessful) {
            this.handleUrlParam();
        }




        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.MawbEvolveDataSaveSuccessful !== this.props.MawbEvolveDataSaveSuccessful && this.props.MawbEvolveDataSaveSuccessful) {
            this.handleUrlParam();
        }


        if (prevProps.HawbRecoverySaveSuccessful !== this.props.HawbRecoverySaveSuccessful && this.props.HawbRecoverySaveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.MawbAddSuccessful !== this.props.MawbAddSuccessful && this.props.MawbAddSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.PostHawbSuccessful !== this.props.PostHawbSuccessful && this.props.PostHawbSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.MawbRecoverySaveSuccessful !== this.props.MawbRecoverySaveSuccessful && this.props.MawbRecoverySaveSuccessful) {
            setTimeout(() => {
                const values = queryString.parse(this.props.location.search)
                try {
                    var paramContainerID = queryStringParse(values.containerid)
                }
                catch (e) {
                    var paramContainerID = null
                }
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        try {
            var paramMbol = formatStringForAPI(values.mbol)
        }
        catch (e) {
            var paramMbol = null
        }
        try {
            var paramHbol = formatStringForAPI(values.hbol)
        }
        catch (e) {
            var paramHbol = null
        }
        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }

        // try {
        //     var paramMinETADischargeStart = formatDateForAPI(values.etadischargestart)
        // }
        // catch (e) {
        //     var paramMinETADischargeStart = null
        // }

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

        this.props.transContainerGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramContainerID, paramContainer, paramStatus, null, null, null, null, null, null, null, null, null, null, paramMbol, null, paramHbol, null, paramPo, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        
    }



    render() {

        return (

            <Shipment
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}


                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isContainerLoading={this.props.isContainerLoading}
                ContainerData={this.props.ContainerData}

                transContainerPost={this.props.transContainerPost}

                ContainerPostSuccessful={this.props.ContainerPostSuccessful}
                NewContainerId={this.props.NewContainerId}

                MbolPostSuccessful={this.props.MbolPostSuccessful}
                HbolPostSuccessful={this.props.HbolPostSuccessful}
                PoPostSuccessful={this.props.PoPostSuccessful}

                NewMbolId={this.props.NewMbolId}
                NewHbolId={this.props.NewHbolId}
                NewPoId={this.props.NewPoId}

                transContainerJsonTreeGet={this.props.transContainerJsonTreeGet}
                isContainerJsonTreeLoading={this.props.isContainerJsonTreeLoading}
                ContainerJsonTreeData={this.props.ContainerJsonTreeData}

                transMbolPost={this.props.transMbolPost}
                transHbolPost={this.props.transHbolPost}
                transPoPost={this.props.transPoPost}

                transShipmentReceiveGet={this.props.transShipmentReceiveGet}
                isShipmentReceiveLoading={this.props.isShipmentReceiveLoading}
                ShipmentReceiveData={this.props.ShipmentReceiveData}

                transPoLocationGet={this.props.transPoLocationGet}
                isPoLocationLoading={this.props.isPoLocationLoading}
                PoLocationData={this.props.PoLocationData}

                transPoLocationPost={this.props.transPoLocationPost}


                transShipmentReceivePost={this.props.transShipmentReceivePost}
                ShipmentReceivePostSuccessful={this.props.ShipmentReceivePostSuccessful}

                isTransCustomerRefLoading={this.props.isTransCustomerRefLoading}
                TransCustomerRefData={this.props.TransCustomerRefData}

                isTransProjectLoading={this.props.isTransProjectLoading}
                TransProjectData={this.props.TransProjectData}


            // mawbAddPost={this.props.mawbAddPost}
            // MawbAddSuccessful={this.props.MawbAddSuccessful}

            // MawbEvolveDataSaveSuccessful={this.props.MawbEvolveDataSaveSuccessful}
            // mawbEvolveDataEntryPost={this.props.mawbEvolveDataEntryPost}

            // // isMawbRecoverySummaryLoading={this.props.isMawbRecoverySummaryLoading}
            // // MawbRecoverySummaryData={this.props.MawbRecoverySummaryData}

            // isHawbLoading={this.props.isHawbLoading}
            // HawbData={this.props.HawbData}
            // hawbGet={this.props.hawbGet}

            // hawbRecoveryPost={this.props.hawbRecoveryPost}
            // HawbRecoverySaveSuccessful={this.props.HawbRecoverySaveSuccessful}


            // isMawbCarrierLoading={this.props.isMawbCarrierLoading}
            // MawbCarrierData={this.props.MawbCarrierData}


            // isHawbConsigneeLoading={this.props.isHawbConsigneeLoading}
            // HawbConsigneeData={this.props.HawbConsigneeData}

            // hawbPost={this.props.hawbPost}
            // PostHawbSuccessful={this.props.PostHawbSuccessful}

            // customsCityAPIMessages={this.props.customsCityAPIMessages}


            // mawbRecoveryPost={this.props.mawbRecoveryPost}

            // userColorPost={this.props.userColorPost}
            // PostUserColorSuccessful={this.props.PostUserColorSuccessful}


            // userColorArray={this.newColorArray}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentContainer));



