import React, {
    PureComponent
} from 'react';

import { withRouter } from 'react-router-dom';
import InventoryLoadDataElements from '../../../dataComponentsInventory/InventoryLoadDataElements';
import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import { Loader, Button, Row, Col, Panel } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import LoadExportModal from '../../LoadExport/LoadExportModal';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import { loadStatus } from '../../../../app/appConfig';
import queryString from 'query-string';
import { queryStringParse, isNumeric, replaceNegativeWithZero, formatStringForAPI } from '../../../../resources/logic/functions';
import BuildLoadDrawer from './BuildLoadDrawer';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "LoadID"

const mountRef = React.createRef();

class Load extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchStatus: null,

            isFilterShown: true,

            //Export Modal
            isModalShown: false,
            loadDataExportModal: {},


            //Build Load Drawer
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false,
            existingDataChangeLoading: false,

        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        // this.onChangeSearchMaster = this.onChangeSearchMaster.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Export Modal
        this.onClickExportLoad = this.onClickExportLoad.bind(this);
        this.handleCloseExportModal = this.handleCloseExportModal.bind(this);


        //Build Load Drawer
        this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onChangeLoad = this.onChangeLoad.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onClickBuildLoad = this.onClickBuildLoad.bind(this);
        this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickBuildLoadDrawerSave = this.onClickBuildLoadDrawerSave.bind(this);
        this.onClickRemoveFromBuild = this.onClickRemoveFromBuild.bind(this);

        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);

    }


    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
    }


    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramLoadStatus = queryStringParse(values.loadstatus)
        }
        catch (e) {
            var paramLoadStatus = null
        }
        if (paramLoadStatus != null) {
            this.onChangeSearchStatus(paramLoadStatus)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    //Search Functions
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });

        // var defaultWarehouse = null
        // try {
        //     if (this.props.UserWarehouseData.length == 1) {
        //         defaultWarehouse = this.props.UserWarehouseData[0]['value']
        //     }
        //     else {
        //         defaultWarehouse = null
        //     }
        // }
        // catch (e) {
        // }


        this.setState({
            // searchMaster: '',
            searchStatus: 1,
            // searchCarrier: null,
            // searchMinETADischargeStart: null,
            // searchMinETADischargeEnd: null,
            // warehouseSelection: defaultWarehouse

        });
        this.props.history.push({
            search:
                'loadstatus=1'
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        if (this.props.warehouseSelection === null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection === null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                var searchCarrier = this.state.searchCarrier
                this.props.history.push({
                    search:
                        'loadstatus=' + searchStatus

                    // 'warehouseid=' + this.state.warehouseSelection
                    // 'carrier=' + searchCarrier + '&' +

                });
            }
            catch (e) { }
        }
    }


    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }
    }

    onClickDepartLoad(e) {
        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
        if (this.state.checkedKeys.length > 0) {
            if (window.confirm('Depart Load?')) {
                var buildLoadArray = {};
                buildLoadArray = this.props.InventoryLoadLotData.filter(x => this.state.checkedKeys.includes(x.LoadID))
                var missingDataBool = false;

                for (let k in buildLoadArray) {

                    if (buildLoadArray[k].Trailer === null || buildLoadArray[k].Trailer == 'null') {
                        // window.alert('Please enter Trailer.')
                        missingDataBool = true
                    }
                    else if (buildLoadArray[k].Carrier === null || buildLoadArray[k].Carrier == 'null') {
                        // window.alert('Please enter Carrier.')
                        missingDataBool = true
                    }
                    else if (buildLoadArray[k].BOL === null || buildLoadArray[k].BOL == 'null') {
                        // window.alert('Please enter BOL.')
                        missingDataBool = true
                    }
                    else if (buildLoadArray[k].Seal === null || buildLoadArray[k].Seal == 'Seal') {
                        // window.alert('Please enter Seal.')
                        missingDataBool = true
                    }
                    else if (buildLoadArray[k].CurrentLoadStatusID == 1) {
                        this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, buildLoadArray[k].LoadID, null, null, null, null, null, null, null, null, null, null, null, 2, null, null, null, null, null, null, null, null)
                    }
                    else {
                    }

                }

                if (missingDataBool) {
                    window.alert('Remaining selected loads missing data.')
                }

            }
        }
        else {
            window.alert('Please select rows.')
        }
        // }
        // else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }


    onClickCreateLoad(e) {

    }


    //Export Modal
    onClickExportLoad(rowData, keyColumn) {
        this.props.inventoryLoadSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, rowData["LoadID"], null)
        this.setState({
            loadDataExportModal: rowData,
            isModalShown: true
        });

    }
    handleCloseExportModal() {
        this.setState({
            isModalShown: false
        });
    }







    //Build Load Drawer
    onClickBuildLoad(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {



            // if (this.state.checkedKeys.length > 0) {
                var buildLoadArray = {};
            //     var rowID;
            //     if (this.state.reportNav === 'pallet') {
            //         rowID = 'PalletID'
            //         buildLoadArray = this.props.InventoryPalletWarehouseData.filter(x => this.state.checkedKeys.includes(x.PalletID))
            //     }
            //     else if (this.state.reportNav === 'case') {
            //         rowID = 'CaseID'
            //         buildLoadArray = this.props.InventoryCaseWarehouseData.filter(x => this.state.checkedKeys.includes(x.CaseID))
            //     }
            //     else if (this.state.reportNav === 'sku') {
            //         rowID = 'CaseSkuID'
            //         buildLoadArray = this.props.InventorySkuWarehouseData.filter(x => this.state.checkedKeys.includes(x.CaseSkuID))
            //     }

            var newLoadDict = {}
            newLoadDict['PickupDate'] = null
            newLoadDict['DeliveryDate'] = null
            newLoadDict['Carrier'] = null
            newLoadDict['BOL'] = null
            newLoadDict['Trailer'] = null
            newLoadDict['Seal'] = null

            this.setState({
                BuildLoadDict: buildLoadArray,
                NewLoadData: newLoadDict,
                showBuildLoadDrawer: true,
            });
            // }
            // else {
            //     window.alert('Please select rows.')
            // }
        }
        else {
            window.alert('Please select one warehouse and one customer to build a load.')
        }
    }


    onChangeBuildLoadDrawer = (e, item, label) => {

        var rowID
        var currentCheckedRow
        var currentItem

        if (this.state.reportNav === 'pallet') {
            rowID = 'PalletID'
        }
        else if (this.state.reportNav === 'case') {
            rowID = 'CaseID'
        }
        else if (this.state.reportNav === 'sku') {
            rowID = 'CaseSkuID'
        }


        currentItem = item[rowID]

        var currentBuildDict = this.state.BuildLoadDict
        var buildKeyToUpdate = currentBuildDict.findIndex(item => item[rowID] === currentItem)


        // currentCheckedRow = this.state.BuildLoadDict.filter(x => currentItem === x[rowID])

        currentBuildDict[buildKeyToUpdate][label] = e
        // this.handleSetStatePallet(currentPalletDict)

        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: currentBuildDict
        });

        this.forceUpdate()

        // var currentLoadData = this.state.NewLoadData
        // currentLoadData[label] = event
        // this.setState({
        //     unsavedBuildLoadChanges: true,
        //     NewLoadData: currentLoadData
        // });

    };


    onChangeSku(e, pallet, cases, skus, label) {


        var palletSeq = pallet['PalletSeq']
        var caseSeq = cases['CaseSeq']
        var skuSeq = skus['SkuSeq']
        var currentPalletDict = this.state.ReceivePalletDict
        var palletKeyToUpdate = currentPalletDict.findIndex(item => item.PalletSeq === palletSeq)
        var caseKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'].findIndex(item => item.CaseSeq === caseSeq)

        var skuKeyToUpdate = currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'].findIndex(item => item.SkuSeq === skuSeq)

        currentPalletDict[palletKeyToUpdate]['Cases'][caseKeyToUpdate]['Skus'][skuKeyToUpdate][label] = e
        this.handleSetStatePallet(currentPalletDict)


    }



    onChangeLoad = (e, label) => {
        var loadDict = this.state.NewLoadData
        loadDict[label] = e
        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: loadDict
        });

        this.forceUpdate()



        // setTimeout(() => {
        //     this.setState({
        //         existingDataChangeLoading: true
        //     });
        // }, 50);


        // var skuId = item.SkuID;
        // var itemIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        // var currentBuildLoadDict = this.state.BuildLoadDict

        // currentBuildLoadDict[itemIndex][label] = isNumeric(event)

        // this.setState({
        //     unsavedBuildLoadChanges: true,
        //     BuildLoadDict: currentBuildLoadDict,

        //     existingDataChangeLoading: false
        // });
    };
    onChangeLocation = (e, item, locationitem, label, skuWarehouseLocationID) => {
        setTimeout(() => {
            this.setState({
                existingDataChangeLoading: true
            });
        }, 50);

        var skuId = item.SkuID;
        var itemIndex = this.state.LocationDict[skuId].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === skuWarehouseLocationID);
        var newLocationBuildDict = this.state.LocationDict

        newLocationBuildDict[skuId][itemIndex][label] = isNumeric(e)

        var currentBuildLoadDictIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        var currentBuildLoadDict = this.state.BuildLoadDict

        try {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Units, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['CasesReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['CasesLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsLoaded'])
        }

        this.setState({
            unsavedBuildLoadChanges: true,
            BuildLoadDict: currentBuildLoadDict,
            LocationDict: newLocationBuildDict,
            existingDataChangeLoading: false
        });
    }

    onClickRemoveFromAdd = (e, item, locationitem, skuWarehouseLocationID) => {

        this.onChangeLocation(0, item, locationitem, 'Units', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Cases', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Pallets', skuWarehouseLocationID)

        this.forceUpdate()
    }

    onClickRemoveFromBuild(e, item) {

        var rowID;
        if (this.state.reportNav === 'pallet') {
            rowID = 'PalletID'
        }
        else if (this.state.reportNav === 'case') {
            rowID = 'CaseID'
        }
        else if (this.state.reportNav === 'sku') {
            rowID = 'CaseSkuID'
        }

        var prevBuild = {}
        prevBuild = this.state.BuildLoadDict

        var idToRemove = item[rowID];

        var itemIndex = this.state.BuildLoadDict.findIndex((row) => row[rowID] === idToRemove);
        // var itemIndex = this.state.BuildLoadDict.findIndex(({ rowID }) => rowID === idToRemove);
        prevBuild.splice(itemIndex, 1);

        setTimeout(() => {
            this.setState({
                BuildLoadDict: prevBuild
            });
        }, 50);




        this.forceUpdate()

    }
    onClickBuildLoadDrawerSave(e) {

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            // var loadPalletDict = this.state.BuildLoadDict
            // var LoadPalletDictString = JSON.stringify(loadPalletDict)

            // try {
            //     var paramPostLoadPalletDict = "'" + LoadPalletDictString + "'"
            // }
            // catch (e) {
            //     var paramPostLoadPalletDict = null
            // }


            try {
                if (this.state.NewLoadData.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.NewLoadData.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.NewLoadData.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.NewLoadData.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                // var paramPostCarrier = "'" + this.state.NewLoadData.Carrier + "'"
                var paramPostCarrier = formatStringForAPI(this.state.NewLoadData.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                // var paramPostBOL = "'" + this.state.NewLoadData.BOL + "'"
                var paramPostBOL = formatStringForAPI(this.state.NewLoadData.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                // var paramPostTrailer = "'" + this.state.NewLoadData.Trailer + "'"
                var paramPostTrailer = formatStringForAPI(this.state.NewLoadData.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                // var paramPostSeal = "'" + this.state.NewLoadData.Seal + "'"
                var paramPostSeal = formatStringForAPI(this.state.NewLoadData.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }


            try {
                var paramPostDesintation = formatStringForAPI(this.state.NewLoadData.Destination)
            }
            catch (e) {
                var paramPostDesintation = null
            }
            try {
                var paramPostLoadStatusID = 1
            }
            catch (e) {
                var paramPostLoadStatusID = 1
            }
            // try {
            //     var paramPostLoadInventorySkuData = "'" + poString + "'"
            // }
            // catch (e) {
            //     var paramPostLoadInventorySkuData = null
            // }
            // try {
            //     var paramPostLocationInventorySkuData = "'" + locationString + "'"
            // }
            // catch (e) {
            //     var paramPostLocationInventorySkuData = null
            // }
            try {
                var paramReportNav = formatStringForAPI(this.state.reportNav)
            }
            catch (e) {
                var paramReportNav = null
            }




            try {
                var paramPostMC = formatStringForAPI(this.state.NewLoadData.MC)
            }
            catch (e) {
                var paramPostMC = null
            }
            try {
                var paramPostDOT = formatStringForAPI(this.state.NewLoadData.DOT)
            }
            catch (e) {
                var paramPostDOT = null
            }
            try {
                var paramPostDriver = formatStringForAPI(this.state.NewLoadData.Driver)
            }
            catch (e) {
                var paramPostDriver = null
            }
            try {
                var paramPostTruck = formatStringForAPI(this.state.NewLoadData.Truck)
            }
            catch (e) {
                var paramPostTruck = null
            }
            try {
                var paramPostTruckPlate = formatStringForAPI(this.state.NewLoadData.TruckPlate)
            }
            catch (e) {
                var paramPostTruckPlate = null
            }
            try {
                var paramPostTrailerPlate = formatStringForAPI(this.state.NewLoadData.TrailerPlate)
            }
            catch (e) {
                var paramPostTrailerPlate = null
            }
            try {
                var paramPostNotes = formatStringForAPI(this.state.NewLoadData.Notes)
            }
            catch (e) {
                var paramPostNotes = null
            }






            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, paramPostLoadStatusID, null, paramPostMC, paramPostDOT, paramPostDriver, paramPostTruck, paramPostTruckPlate, paramPostTrailerPlate, paramPostNotes) 




            // if (this.state.NewLoadData.Carrier == null || this.state.NewLoadData.Carrier == '') {
            //     window.alert('Please enter Carrier')
            // }
            // else if (this.state.NewLoadData.Trailer == null || this.state.NewLoadData.Trailer == '') {
            //     window.alert('Please enter Trailer')
            // }

            // if (window.confirm('Save Data?')) {

            // const currentListPo = this.state.BuildLoadDict
            // const newPoToPost = currentListPo.map(({ SkuID, NewUnitsLoaded, NewCasesLoaded, NewPallets }) => ({ SkuID, NewUnitsLoaded, NewCasesLoaded, NewPallets }));
            // var poString = JSON.stringify(newPoToPost)

            // const currentListLocation = this.state.LocationDict
            // var newLocationToPost = []
            // for (let x in currentListLocation) {
            //     for (let i in currentListLocation[x]) {
            //         try {

            //             if ((currentListLocation[x][i]['Units'] === null || currentListLocation[x][i]['Units'] == 0) && (currentListLocation[x][i]['Cases'] === null || currentListLocation[x][i]['Cases'] == 0) && (currentListLocation[x][i]['Pallets'] === null || currentListLocation[x][i]['Pallets'] == 0)) {

            //             }
            //             else {
            //                 newLocationToPost.push({ 'SkuID': x, 'WarehouseLocationID': currentListLocation[x][i]['WarehouseLocationID'], 'SkuWarehouseLocationID': currentListLocation[x][i]['SkuWarehouseLocationID'], 'Units': currentListLocation[x][i]['Units'], 'Cases': currentListLocation[x][i]['Cases'], 'Pallets': currentListLocation[x][i]['Pallets'], 'Oversize': formatTrueFalseToBit(currentListLocation[x][i]['Oversize']) })
            //             }
            //         }
            //         catch (e) { }
            //     }
            // }
            // var locationString = JSON.stringify(newLocationToPost)



            // this.props.inventoryLoadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, paramPostTotalUnits, paramPostTotalCases, paramPostTotalPallets, paramPostDesintation, null, paramPostLoadStatusID, paramPostLoadInventorySkuData, paramPostLocationInventorySkuData, null, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)

            this.setState({
                unsavedBuildLoadChanges: false,
                showBuildLoadDrawer: false
            });
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeBuildLoadDrawer() {
        if (this.state.unsavedBuildLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setBuildLoadDrawerToClose()
            }
        }
        else {
            this.setBuildLoadDrawerToClose()
        }
    }

    setBuildLoadDrawerToClose() {
        this.setState({
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false
        });
    }




    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;


        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>



                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                loadStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>


                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }



        let loadDiv;
        let rTable;
        if (this.props.isInventoryLoadLotLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadLotData) !== 'undefined' && this.props.InventoryLoadLotData != null) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramStatusID = queryStringParse(values.loadstatus)
            }
            catch (e) {
                var paramStatusID = null
            }
            rTable = RTable(
                this.props.InventoryLoadLotData,        // tableData,
                InventoryLoadDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isInventoryLoadLotLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                (paramStatusID == 1 || paramStatusID == null ? true : false), // checkColEntry,
                // true,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                checkedKeys,                       // checkedKeys,
                this.handleCheckAll,                       // handleCheckAll,
                this.handleCheck,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey
                true,                            // editLink,
                'Manifest',                            // editLinkText,
                this.onClickExportLoad                            // editLinkOnClick
            )

            loadDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>

                            <Button style={{ float: 'left', marginRight: '10px' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Create New Load</Button>
                            {paramStatusID == 1 || paramStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> : ''}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                    {
                                        InventoryLoadDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }


        let buildLoadDrawerDiv;
        if (this.state.showBuildLoadDrawer) {
            buildLoadDrawerDiv = <BuildLoadDrawer {...this.props} state={this.state} onChange={this.onChangeBuildLoadDrawer} onChangeLoad={this.onChangeLoad} onChangeLocation={this.onChangeLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickBuildLoadDrawerSave} close={this.closeBuildLoadDrawer} remove={this.onClickRemoveFromBuild} />

        }
        else {
            buildLoadDrawerDiv = <div></div>
        }

        return (
            <div>
                <h3>
                    Inventory Management - Load
                </h3>


                {hideShowSearch}
                {searchDiv}


                {loadDiv}
                {rTable}







                <LoadExportModal {...this.props} state={this.state}
                    isModalShown={this.state.isModalShown}
                    handleClose={this.handleCloseExportModal}
                />





                {buildLoadDrawerDiv}


            </div>
        );

    }
}

export default withRouter(Load);

