import React, {
    PureComponent
} from 'react';
import { Uploader, Loader } from 'rsuite';
import { appSettings } from '../../../../app/appSettings';
import { appAPI } from '../../../../app/appConfig';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { awsCognitoConfig } from '../../../../app/awsCognitoConfig';
import axios from 'axios';
import { formatNumberForAPI, formatStringForAPI, formatStringWithoutQuotesForAPI } from '../../../../resources/logic/functions';

const poolData = { UserPoolId: awsCognitoConfig.cognitoLogin.Auth.UserPoolId, ClientId: awsCognitoConfig.cognitoLogin.Auth.ClientId };
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
const cognitoUser = userPool.getCurrentUser();


function onUpload(file, dbID, s3FilePrefix, currentUserEmail, userSignInData, customerSelection, fileList, onUploadAction, s3Bucket) {
    var contentType = file.blobFile.type

    var allowedFileTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'message/rfc822',
        'image/jpeg',
        'application/pdf',
        'text/plain',
        'image/png',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/bmp',
        'application/msword',
        'image/gif',
        'application/zip',
        'image/heic',
        'application/json'
    ]


    // Check filesize
    if (file.blobFile.size > 10000000) {
        window.alert('File size exceeds 10MB limit.')
    }
    //Check filetype
    else if( !allowedFileTypes.includes(contentType) ){
        window.alert('File type is not allowed for upload.')
    }
    //Check if file exists
    else if (fileList && fileList.findIndex(item => item.FileName === file.name) !== -1) {
        window.alert('File already exists.')
    }
    else {
        var jwtToken = ''

        if (cognitoUser === null) { window.location.reload(); }
        cognitoUser.getSession(function (err, data) {
            if (err) {
                window.location.reload();
            } else {
                const cognitoUserSession = data;
                jwtToken = cognitoUserSession.getIdToken().jwtToken;
            }
        });

        var s3FileName = s3FilePrefix.toString() + '-' + formatStringWithoutQuotesForAPI(file.name)

        var apiUrl = appAPI.API.data.FileUploadAPI + s3Bucket + '/' + s3FileName

    

        axios({
            method: 'POST',
            url: `${apiUrl}`,
            headers: {
                'Authorization': jwtToken,
                'Content-Type': 'multipart/form-data'
            },
            data: file.blobFile
        })
            .then((res) => {

                if (res.data === ""){
                onUploadAction(
                    currentUserEmail,
                    userSignInData.AuthToken,
                    customerSelection,
                    null,
                    formatNumberForAPI(dbID),
                    "'" + file.name + "'",
                    "'" + s3FileName + "'",
                    formatStringForAPI(contentType),
                    0,
                    null)
                }
                else{
                    window.alert("Error Uploading File.")
                }

            })
            .catch((error) => {
                window.alert("Error uploading file, please try again.")
            });


    }
}


function RUploaderGeneral(
    isMobile,
    history,
    currentUserEmail,
    userSignInData,
    customerSelection,
    fileList,
    onUploadAction,
    loading,
    dbID,
    s3FilePrefix,
    s3Bucket
) {

    let rUploader;

    if (loading) {
        rUploader = <div align="center">
            <section className="panel">
            </section>
            <Loader
                type="TailSpin"
                color="#3396E5"
                height="50"
                width="50"
            />
        </div>
    }

    else {
        rUploader = <Uploader draggable onUpload={(e) => { onUpload(e, dbID, s3FilePrefix, currentUserEmail, userSignInData, customerSelection, fileList, onUploadAction, s3Bucket) }} fileListVisible={false}>
            <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>Click or Drag files to this area to upload</span>
            </div>
        </Uploader>
    }



    return (

        rUploader

    )
}


export default RUploaderGeneral;




// 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Requested-With,x-amz-content-sha256'