import { connect } from 'react-redux';
import React, { Component } from 'react';
import Dashboard from './Dashboard'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI, formatDateForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isDashboardLoading:                                  store.vasReducer.isDashboardLoading,
    DashboardData:                                       store.vasReducer.DashboardData,

    isHawbDestinationLoading: store.vasReducer.isHawbDestinationLoading,
    HawbDestinationData: store.vasReducer.HawbDestinationData,

    isHawbConsigneeLoading: store.vasReducer.isHawbConsigneeLoading,
    HawbConsigneeData: store.vasReducer.HawbConsigneeData,


    isDashboardSummaryLoading: store.vasReducer.isDashboardSummaryLoading,
    DashboardSummaryData: store.vasReducer.DashboardSummaryData,

});

const mapDispatchToProps = dispatch =>
({

    outboundGet:( _email, _authToken, _LoadID, _HouseID, _HouseRef, _MasterID, _Master, _OrderNo, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _DCCode, _Carrier, _Trailer, _Seal, _BOL, _WarehouseID, _CustomerID  ) =>
    {
        dispatch( actions.outboundGet( _email, _authToken, _LoadID, _HouseID, _HouseRef, _MasterID, _Master, _OrderNo, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _DCCode, _Carrier, _Trailer, _Seal, _BOL, _WarehouseID, _CustomerID  ) )
    },

    hawbDestinationGet: (_email, _authToken, _HawbStatusID) => {
        dispatch(actions.hawbDestinationGet(_email, _authToken, _HawbStatusID))
    },

    hawbConsigneeGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbConsigneeGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    dashboardSummaryGet: (_email, _authToken, _WarehouseID, _WarehousePort, _ConsigneeID, _DestinationID, _CustomerID) => {
        dispatch(actions.dashboardSummaryGet(_email, _authToken, _WarehouseID, _WarehousePort, _ConsigneeID, _DestinationID, _CustomerID))
    },


});

class DashboardContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
        // this.props.userWarehousesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.PostLoadSuccessful !== this.props.PostLoadSuccessful && this.props.PostLoadSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }


    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)


        try {
            var paramConsignee = queryStringParse(values.consignee)
        }
        catch (e) {
            var paramConsignee = null
        }


        try {
            var paramPort = queryStringParse(values.port)
        }
        catch (e) {
            var paramPort = null
        }


        try {
            var paramDestination = queryStringParse(values.destinationid)
        }
        catch (e) {
            var paramDestination = null
        }

        try {
            var paramWarehouse = this.props.warehouseSelection
        }
        catch (e) {
            var paramWarehouse = null
        }

        try {
            var paramCustomer = this.props.customerSelection
        }
        catch (e) {
            var paramCustomer = null
        }

        this.props.hawbDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)

        this.props.hawbConsigneeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouse, paramCustomer)


        this.props.dashboardSummaryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken,  paramWarehouse, paramPort, paramConsignee, paramDestination, paramCustomer )
    }



    render() {

        return (

            <Dashboard
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isHawbConsigneeLoading={this.props.isHawbConsigneeLoading}
                HawbConsigneeData={this.props.HawbConsigneeData}


                isDashboardLoading={this.props.isDashboardLoading}
                DashboardData={this.props.DashboardData}                
                
                isHawbDestinationLoading={this.props.isHawbDestinationLoading}
                HawbDestinationData={this.props.HawbDestinationData}

                isDashboardSummaryLoading={this.props.isDashboardSummaryLoading}
                DashboardSummaryData={this.props.DashboardSummaryData}

            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardContainer));



