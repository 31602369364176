import { connect } from 'react-redux';
import React, { Component } from 'react';
import MultiMilestoneEventEntry from './MultiMilestoneEventEntry'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import * as userActions from '../../../redux/actions/actionsUser';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI , queryStringParseWithZero} from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isCtrlTwrCountryLoading:                                store.ctrlTwrReducer.isCtrlTwrCountryLoading,
    CtrlTwrCountryData:                                     store.ctrlTwrReducer.CtrlTwrCountryData,


    isCtrlTwrStateLoading:                                store.ctrlTwrReducer.isCtrlTwrStateLoading,
    CtrlTwrStateData:                                store.ctrlTwrReducer.CtrlTwrStateData,

    isCtrlTwrModeRefLoading: store.ctrlTwrReducer.isCtrlTwrModeRefLoading,
    CtrlTwrModeRefData: store.ctrlTwrReducer.CtrlTwrModeRefData,

    isCtrlTwrAirportLoading: store.ctrlTwrReducer.isCtrlTwrAirportLoading,
    CtrlTwrAirportData: store.ctrlTwrReducer.CtrlTwrAirportData,

    isCtrlTwrSeaportLoading: store.ctrlTwrReducer.isCtrlTwrSeaportLoading,
    CtrlTwrSeaportData: store.ctrlTwrReducer.CtrlTwrSeaportData,

    CtrlTwrShipmentLegsDataPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsDataPostSuccessful,

    isCtrlTwrShipmentLegsMilestonesMultiLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsMilestonesMultiLoading,
    CtrlTwrShipmentLegsMilestonesMultiData: store.ctrlTwrReducer.CtrlTwrShipmentLegsMilestonesMultiData,

    isCtrlTwrShipmentLegsDataLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsDataLoading,
    CtrlTwrShipmentLegsData: store.ctrlTwrReducer.CtrlTwrShipmentLegsData,

    CtrlTwrShipmentLegsPostSuccessful:                      store.ctrlTwrReducer.CtrlTwrShipmentLegsPostSuccessful,


    isCtrlTwrMilestoneRefLoading:                      store.ctrlTwrReducer.isCtrlTwrMilestoneRefLoading,
    CtrlTwrMilestoneRefData:                      store.ctrlTwrReducer.CtrlTwrMilestoneRefData,

    isCtrlTwrShipmentLegsMilestonesLoading:                      store.ctrlTwrReducer.isCtrlTwrShipmentLegsMilestonesLoading,
    CtrlTwrShipmentLegsMilestonesData:                      store.ctrlTwrReducer.CtrlTwrShipmentLegsMilestonesData,

    CtrlTwrShipmentLegsMilestonesMultiPostSuccessful:                      store.ctrlTwrReducer.CtrlTwrShipmentLegsMilestonesMultiPostSuccessful,

    isCtrlTwrTimezoneLoading:                      store.ctrlTwrReducer.isCtrlTwrTimezoneLoading,
    CtrlTwrTimezoneData:                      store.ctrlTwrReducer.CtrlTwrTimezoneData,


});

const mapDispatchToProps = dispatch =>
({
    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrCountryGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrCountryGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrStateGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrStateGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrAirportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrAirportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrSeaportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrSeaportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrShipmentLegsDataPost: (_email, _authToken, _ProRef, _LegNumber, _ModeID, _LegID, _WarehouseID, _BOL, _MBOL, _HBOL, _PO, _Container, _Carrier, _Seal, _PlannedDepartureDate, _EstimatedDeliveryDate, _PickupAddress, _DeliveryAddress, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _CustomerRef, _Shipper_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode, _PickupAppointmentTime, _DeliveryAppointmentTime) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsDataPost(_email, _authToken, _ProRef, _LegNumber, _ModeID, _LegID, _WarehouseID, _BOL, _MBOL, _HBOL, _PO, _Container, _Carrier, _Seal, _PlannedDepartureDate, _EstimatedDeliveryDate, _PickupAddress, _DeliveryAddress, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _CustomerRef, _Shipper_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode, _PickupAppointmentTime, _DeliveryAppointmentTime))
    },

    ctrlTwrShipmentLegsMilestonesMultiGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _ShipmentClosed, _ModeID, _LegID, _Container, _Carrier, _Seal, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _OriginCountryID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsMilestonesMultiGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _ShipmentClosed, _ModeID, _LegID, _Container, _Carrier, _Seal, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _OriginCountryID))
    },


    ctrlTwrShipmentLegsDataGet: (_email, _authToken, _ProRef, ) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsDataGet(_email, _authToken, _ProRef, ))
    },
    ctrlTwrShipmentLegsPost: (_email, _authToken, _ProRef, _LegNumber, _ModeID, _DataJson, _LegID, _DeleteLeg, ) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsPost(_email, _authToken, _ProRef, _LegNumber, _ModeID, _DataJson, _LegID, _DeleteLeg, ))
    },
    
    ctrlTwrMilestoneRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrMilestoneRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    ctrlTwrShipmentLegsMilestonesGet: (_email, _authToken, _ProRef, _LegID ) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsMilestonesGet(_email, _authToken, _ProRef, _LegID))
    },

    ctrlTwrShipmentLegsMilestonesMultiPost: (_email, _authToken, _ShipmentLegMilestoneID, _ProRef, _LegIDs, _ModeID, _MilestoneID, _MilestoneDT, _MilestoneTimezone, _CountryCodeID, _City, _State, _Zip, _SeaportCodeID, _AirportCodeID, _WarehouseID, _DeleteMilestone, _StateCodeID ) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsMilestonesMultiPost(_email, _authToken, _ShipmentLegMilestoneID, _ProRef, _LegIDs, _ModeID, _MilestoneID, _MilestoneDT, _MilestoneTimezone, _CountryCodeID, _City, _State, _Zip, _SeaportCodeID, _AirportCodeID, _WarehouseID, _DeleteMilestone, _StateCodeID))
    },

    ctrlTwrTimezoneGet: (_email, _authToken,  ) => {
        dispatch(ctrlTwrActions.ctrlTwrTimezoneGet(_email, _authToken, ))
    },
});

class MultiMilestoneEventEntryContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.handleUrlParam();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
       
        if (prevProps.CtrlTwrShipmentLegsMilestonesMultiPostSuccessful !== this.props.CtrlTwrShipmentLegsMilestonesMultiPostSuccessful && this.props.CtrlTwrShipmentLegsMilestonesMultiPostSuccessful) {
            this.handleUrlParam();
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = formatStringForAPI(values.pro)
        }
        catch (e) {
            var paramPro = null
        }

        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }


        try {
            var paramCarrier = formatStringForAPI(values.carrier)
        }
        catch (e) {
            var paramCarrier = null
        }

        try {
            var paramVessel = formatStringForAPI(values.vessel)
        }
        catch (e) {
            var paramVessel = null
        }

        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }

        try {
            var paramTrailer = formatStringForAPI(values.trailer)
        }
        catch (e) {
            var paramTrailer = null
        }

        try {
            var paramSeal = formatStringForAPI(values.seal)
        }
        catch (e) {
            var paramSeal = null
        }

        try {
            var paramFlight = formatStringForAPI(values.flight)
        }
        catch (e) {
            var paramFlight = null
        }



        try {
            var paramModeID = queryStringParse(values.modeid)
        }
        catch (e) {
            var paramModeID = null
        }





        // try {
        //     var paramCustomer = queryStringParse(values.customer)
        // }
        // catch (e) {
        //     var paramCustomer = null
        // }

        try {
            var paramCountry = queryStringParse(values.country)
        }
        catch (e) {
            var paramCountry = null
        }

        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }

        this.props.ctrlTwrModeRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrAirportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrSeaportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrMilestoneRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.ctrlTwrCountryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrStateGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)


        
        this.props.ctrlTwrShipmentLegsMilestonesMultiGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, paramPo, paramStatus, paramModeID, null, paramContainer, paramCarrier, paramSeal, paramTrailer, paramVessel, null, null, paramFlight, null, null, paramCountry)

        this.props.ctrlTwrTimezoneGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken)
    }



    render() {

        return (

            <MultiMilestoneEventEntry
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isCtrlTwrModeRefLoading={this.props.isCtrlTwrModeRefLoading}
                CtrlTwrModeRefData={this.props.CtrlTwrModeRefData}

                isCtrlTwrAirportLoading={this.props.isCtrlTwrAirportLoading}
                CtrlTwrAirportData={this.props.CtrlTwrAirportData}
            
                isCtrlTwrSeaportLoading={this.props.isCtrlTwrSeaportLoading}
                CtrlTwrSeaportData={this.props.CtrlTwrSeaportData}

                CtrlTwrShipmentLegsDataPostSuccessful={this.props.CtrlTwrShipmentLegsDataPostSuccessful}
                ctrlTwrShipmentLegsDataPost={this.props.ctrlTwrShipmentLegsDataPost}


                isCtrlTwrCountryLoading={this.props.isCtrlTwrCountryLoading}
                CtrlTwrCountryData={this.props.CtrlTwrCountryData}
                
                isCtrlTwrStateLoading={this.props.isCtrlTwrStateLoading}
                CtrlTwrStateData={this.props.CtrlTwrStateData}


                isCtrlTwrShipmentLegsMilestonesMultiLoading={this.props.isCtrlTwrShipmentLegsMilestonesMultiLoading}
                CtrlTwrShipmentLegsMilestonesMultiData={this.props.CtrlTwrShipmentLegsMilestonesMultiData}


                ctrlTwrShipmentLegsDataGet={this.props.ctrlTwrShipmentLegsDataGet}
                isCtrlTwrShipmentLegsDataLoading={this.props.isCtrlTwrShipmentLegsDataLoading}
                CtrlTwrShipmentLegsData={this.props.CtrlTwrShipmentLegsData}

                CtrlTwrShipmentLegsPostSuccessful={this.props.CtrlTwrShipmentLegsPostSuccessful}
                ctrlTwrShipmentLegsPost={this.props.ctrlTwrShipmentLegsPost}

                isCtrlTwrMilestoneRefLoading={this.props.isCtrlTwrMilestoneRefLoading}
                CtrlTwrMilestoneRefData={this.props.CtrlTwrMilestoneRefData}
            
                ctrlTwrShipmentLegsMilestonesGet={this.props.ctrlTwrShipmentLegsMilestonesGet}

                isCtrlTwrShipmentLegsMilestonesLoading={this.props.isCtrlTwrShipmentLegsMilestonesLoading}
                CtrlTwrShipmentLegsMilestonesData={this.props.CtrlTwrShipmentLegsMilestonesData}


                ctrlTwrShipmentLegsMilestonesMultiPost={this.props.ctrlTwrShipmentLegsMilestonesMultiPost}
                CtrlTwrShipmentLegsMilestonesMultiPostSuccessful={this.props.CtrlTwrShipmentLegsMilestonesMultiPostSuccessful}

                isCtrlTwrTimezoneLoading={this.props.isCtrlTwrTimezoneLoading}
                CtrlTwrTimezoneData={this.props.CtrlTwrTimezoneData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MultiMilestoneEventEntryContainer));



