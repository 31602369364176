export const XdockLoadPalletCartonDataElements = [


    {
        dBKey: 'CartonID',
        displayName: 'Carton ID',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },
 


    {
        dBKey: 'CartonQty',
        displayName: 'Carton Qty',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 130
    },
 




    
    
]


export default XdockLoadPalletCartonDataElements

