import React from 'react';
import { Row, Col, Panel, Timeline } from 'rsuite'; // Assuming you're using rsuite
import Loader from 'react-loader-spinner'; // Assuming you're using react-loader-spinner

function LoadSKUsHistoryDiv({ isInventoryLoadSKUsHistoryLoading, InventoryLoadSKUsHistoryData }) {
    if (isInventoryLoadSKUsHistoryLoading) {
        return (
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        );
    }

    if (typeof (InventoryLoadSKUsHistoryData[0]) !== 'undefined' && InventoryLoadSKUsHistoryData != null) {
        return (
            <div>
                <Row>
                    <Col md={24} sm={24}>
                        <Panel bordered header="Load Unit History" style={{ marginBottom: 10 }}>
                            <Timeline>
                                {InventoryLoadSKUsHistoryData.map(item => (
                                    <Timeline.Item key={item.LoadSkuHistoryID}>
                                        {item.CreatedDT}  &nbsp;
                                        {item.EmailAddr}
                                        <l></l>
                                        {item.HistoryNotes != null ? <l><br /> {item.HistoryNotes} </l> : ' '}            
                                        {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '}  
                                        {item.SkuUnits != null ? <l><br /> Units:  {item.SkuUnits} </l> : ' '}
                                        {item.CaseBarcode != null ? <l><br /> Case Barcode:  {item.CaseBarcode} </l> : ' '}     
                                        {item.PalletBarcode != null ? <l><br /> Pallet Barcode:  {item.PalletBarcode} </l> : ' '}                                         
                                        
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }

    return <div></div>;
}

export default LoadSKUsHistoryDiv;

