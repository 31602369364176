import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { lettersNumbersDashesSpacesInputMask, lettersNumbersDashesInputMask, lettersNumbersDashesSpacesCommasInputMask, textStringInputMask, limitCharacterLength, textNumbersSymbolsInputmask, limitUpperCaseLengthMask, zipCodeInputMask } from '../../../resources/logic/inputMasks';
import { Row, Col, Panel, PanelGroup, Button, Timeline, ButtonToolbar, FormGroup, InputGroup, InputPicker, Input, Whisper, DatePicker, Tooltip, Divider, IconButton, Checkbox } from 'rsuite';
import { formatNumberForAPI, formatStringForAPI, formatDateForUrlSearch, formatDateTimeForUrlSearch, findLowestMissingNumber, formatTrueFalseToBit, formatTextNumbersSymbolsForAPI, formatTimeForUrlSearch, formatAllTextNumbersSymbolsForAPI } from '../../../resources/logic/functions';
import MinusIcon from '@rsuite/icons/Minus';
import CloseIcon from '@rsuite/icons/Close';
import queryString from 'query-string';
import ReactExport from "react-data-export";
import ShipmentManagementAddNewLegModal from '../ShipmentManagement/ShipmentManagementAddNewLegModal';
import ShipmentManagementMultiModal from '../ShipmentManagement/ShipmentManagementMultiModal';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import CtrlTwrShipmentLegsMilestonesDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentLegsMilestonesDataElements';
import * as moment from 'moment'
import MilestoneEventModal from '../MilestoneEventEntry/MilestoneEventModal';
import ShipmentDetailsManagementEditDrawer from './ShipmentDetailsManagementEditDrawer';
import RUploader from '../../componentsGeneral/Rsuite/Uploader/RUploader';
import * as customIconButton from '../../common/IconButton/IconButton';
import { appAPI } from '../../../app/appConfig';
import CtrlTwrShipmentFileUploadsDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentFileUploadsDataElements';
import { dbValues } from '../../../app/appConfig';

import MilestoneEventEntryDrawer from '../MilestoneEventEntry/MilestoneEventEntryDrawer';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "ShipmentLegMilestoneID"

const tooltipDelete = (
  <Tooltip>
    Delete
  </Tooltip>
);

const styles = {
  marginTop: '5px',
  width: '100%'
};

const inputStyle = {
  width: '100%'
};


const mountRef = React.createRef();





class ShipmentDetailsManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

      //Warehouse & Customer Arrays
      warehouseArray: {},
      customerArray: {},

      //Ship Management Drawer
      ShipManagementExistingDict: {},
      unsavedShipManagmentChanges: false,
      ShipManagementProRef: null,
      AddNewLegDict: {},
      ShipManagementHbolData: {},
      ShipManagementPoData: {},
      ShipManagementCustRefData: {},


      //Shipment Details Edit Drawer
      showShipmentDetailsManagementEditDrawer: false,
      ShipEntryPO: '',
      ShipEntryCustomer: null,
      ShipEntryOriginCountry: '',
      ShipEntryDestinationCountry: '',
      ShipEntryETD: null,
      ShipEntryPallets: null,
      ShipEntryCases: null,
      ShipEntryUnitCount: null,

      ShipEntryWeightUnits: null,
      ShipHotShipment: null,

      SpecialRequirements: null,
      GrossWeight: null,
      ChargeableWeight: null,
      Length: null,
      Width: null,
      Height: null,
      DimUnits: null,
      Commodity: null,

      UnsavedShipEntryChanges: false,


      //Add new Leg Modal
      addNewLegModalShown: false,
      newLegModalLegNumber: null,
      newLegModalMode: null,

      //Multi Modal
      multiModalShown: false,
      multiModalLegNumber: null,
      multiModalStateName: null,
      multiModalHbolID: null,
      multiModalNewValue: '',

      multiModalValuesSaving: false,



      //Edit Milestone Modal
      addNewMilestoneModalShown: false,
      newMilestoneModalShipmentLegMilestoneID: null,

      newMilestoneModalLegID: null,
      newMilestoneModalMilestoneID: null,

      newMilestoneModalModeID: null,
      newMilestoneModalMode: null,
      newMilestoneModalMilestoneDT: null,
      newMilestoneModalMilestoneTimezone: null,
      newMilestoneModalCountryCodeID: null,
      newMilestoneModalCity: null,
      newMilestoneModalState: null,
      newMilestoneModalStateCodeID: null,
      newMilestoneModalZip: null,
      newMilestoneModalSeaportCodeID: null,
      newMilestoneModalAirportCodeID: null,
      newMilestoneModalWarehouseID: null,



      //Ship Management Drawer
      showMilestoneEntryDrawer: false,
      ShipMilestonesExistingDict: {},
      unsavedShipManagmentChanges: false,
      ShipManagementProRef: null,
      AddNewLegDict: {},

      //Notes
      ShipmentNote: '',
      NoteIsPrivate: true

    };


    this.onClickEditShipmentDetails = this.onClickEditShipmentDetails.bind(this);
    this.preloadShipEditDrawer = this.preloadShipEditDrawer.bind(this);

    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);


    this.onClickHotShipment = this.onClickHotShipment.bind(this);
    this.onClickShipmentSubscribe = this.onClickShipmentSubscribe.bind(this);


    //Ship Management Drawer
    this.onClickOpenShipManagmenetDrawer = this.onClickOpenShipManagmenetDrawer.bind(this);
    this.onChangeMilestoneEventEntry = this.onChangeMilestoneEventEntry.bind(this);
    this.onClickMilestoneEventEntryDrawerSave = this.onClickMilestoneEventEntryDrawerSave.bind(this);
    this.onClickAddMilestone = this.onClickAddMilestone.bind(this);
    this.onClickAddMissingMilestone = this.onClickAddMissingMilestone.bind(this);

    this.closeMilestoneEventEntryDrawer = this.closeMilestoneEventEntryDrawer.bind(this);
    this.setMilestoneEventEntryDrawerToClose = this.setMilestoneEventEntryDrawerToClose.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);


    //Ship Management Drawer
    this.loadShipLegData = this.loadShipLegData.bind(this);
    this.onChangeShipmentManagement = this.onChangeShipmentManagement.bind(this);
    this.onClickShipmentManagementDrawerSave = this.onClickShipmentManagementDrawerSave.bind(this);
    this.onClickDeleteLeg = this.onClickDeleteLeg.bind(this);
    this.onClickShowAddLeg = this.onClickShowAddLeg.bind(this);
    this.closeShipmentManagementDrawer = this.closeShipmentManagementDrawer.bind(this);
    this.setShipmentManagementDrawerToClose = this.setShipmentManagementDrawerToClose.bind(this);
    this.onClickMulti = this.onClickMulti.bind(this);
    this.onClickDeleteMulti = this.onClickDeleteMulti.bind(this);

    //Shipment Details Edit Drawer
    this.onClickSaveShipDetailsEdit = this.onClickSaveShipDetailsEdit.bind(this);
    this.setShipDetailsEditDrawerToClose = this.setShipDetailsEditDrawerToClose.bind(this);
    this.closeShipDetailsEditDrawer = this.closeShipDetailsEditDrawer.bind(this);
    this.onChangeWarehouse = this.onChangeWarehouse.bind(this);
    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.onChangePO = this.onChangePO.bind(this);
    this.onChangeOriginCountry = this.onChangeOriginCountry.bind(this);
    this.onChangeDestinationCountry = this.onChangeDestinationCountry.bind(this);
    this.onChangeETD = this.onChangeETD.bind(this);

    this.onChangePallets = this.onChangePallets.bind(this);
    this.onChangeCases = this.onChangeCases.bind(this);
    this.onChangeUnitCount = this.onChangeUnitCount.bind(this);
    this.onChangeGrossWeight = this.onChangeGrossWeight.bind(this);
    this.onChangeChargeableWeight = this.onChangeChargeableWeight.bind(this);
    this.onChangeWeightUnits = this.onChangeWeightUnits.bind(this);

    this.onChangeFunc = this.onChangeFunc.bind(this);

    //Add new Leg Modal
    this.handleSaveAddNewLegModal = this.handleSaveAddNewLegModal.bind(this);
    this.handleCloseAddNewLegModal = this.handleCloseAddNewLegModal.bind(this);
    this.onChangeAddNewLegModal = this.onChangeAddNewLegModal.bind(this);

    //Multi Modal
    this.onChangeMultiModal = this.onChangeMultiModal.bind(this);
    this.handleCloseMultiModal = this.handleCloseMultiModal.bind(this);
    this.handleSaveMultiModal = this.handleSaveMultiModal.bind(this);
    this.setMultiModalToSaving = this.setMultiModalToSaving.bind(this);


    //Edit Milestone Modal
    this.handleSaveAddNewModal = this.handleSaveAddNewModal.bind(this);
    this.handleCloseAddNewModal = this.handleCloseAddNewModal.bind(this);
    this.onChangeAddNewMilestone = this.onChangeAddNewMilestone.bind(this);
    this.onClickEditMilestone = this.onClickEditMilestone.bind(this);
    this.onClickDeleteMilestone = this.onClickDeleteMilestone.bind(this);

    //File Upload
    this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);
    this.onClickDeleteFileUpload = this.onClickDeleteFileUpload.bind(this);
    this.onClickMoveDocToShipment = this.onClickMoveDocToShipment.bind(this);
    this.onClickDeleteQuotingFileUpload = this.onClickDeleteQuotingFileUpload.bind(this);

    //Notes
    this.handleSaveAddNewNote = this.handleSaveAddNewNote.bind(this);
    this.onChangeCheckBoxOrgNote = this.onChangeCheckBoxOrgNote.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onClickDeletePublicNote = this.onClickDeletePublicNote.bind(this);
    this.onClickDeletePrivateNote = this.onClickDeletePrivateNote.bind(this);

  }


  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection

    this.loadShipLegData()

  }


  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }

    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }

  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.CtrlTwrShipmentLegsData !== this.props.CtrlTwrShipmentLegsData && this.props.CtrlTwrShipmentLegsData.length > 0) {
      this.preloadShipManagement()
    }


    if (prevProps.CtrlTwrShipmentLegsData !== this.props.CtrlTwrShipmentLegsData && this.props.CtrlTwrShipmentLegsData.length > 0) {
      this.preloadShipManagement()
    }

    if (prevProps.CtrlTwrShipmentLegsCustomerRefData !== this.props.CtrlTwrShipmentLegsCustomerRefData) {
      this.preloadHbolPoCustRef()
    }
    if (prevProps.CtrlTwrShipmentLegsPoData !== this.props.CtrlTwrShipmentLegsPoData) {
      this.preloadHbolPoCustRef()
    }
    if (prevProps.CtrlTwrShipmentLegsHbolData !== this.props.CtrlTwrShipmentLegsHbolData) {
      this.preloadHbolPoCustRef()
    }

    if (prevProps.CtrlTwrShipmentLegsDataPostSuccessful !== this.props.CtrlTwrShipmentLegsDataPostSuccessful && this.props.CtrlTwrShipmentLegsDataPostSuccessful) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
        }
        catch (e) { }
      }, 100);
    }

    if (prevProps.CtrlTwrShipmentLegsPostSuccessful !== this.props.CtrlTwrShipmentLegsPostSuccessful && this.props.CtrlTwrShipmentLegsPostSuccessful) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
        }
        catch (e) { }
      }, 100);
    }


    if (!prevProps.CtrlTwrShipmentLegsCustomerRefPostSuccessful && this.props.CtrlTwrShipmentLegsCustomerRefPostSuccessful && !this.state.multiModalValuesSaving) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrShipmentLegsCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        }
        catch (e) { }
      }, 100);
    }

    if (!prevProps.CtrlTwrShipmentLegsPoPostSuccessful && this.props.CtrlTwrShipmentLegsPoPostSuccessful && !this.state.multiModalValuesSaving) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrShipmentLegsPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        }
        catch (e) { }
      }, 100);
    }

    if (!prevProps.CtrlTwrShipmentLegsHbolPostSuccessful && this.props.CtrlTwrShipmentLegsHbolPostSuccessful && !this.state.multiModalValuesSaving) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrShipmentLegsHbolGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null)
        }
        catch (e) { }
      }, 100);
    }



    if (prevProps.CtrlTwrShipmentLegsMilestonesPostSuccessful !== this.props.CtrlTwrShipmentLegsMilestonesPostSuccessful && this.props.CtrlTwrShipmentLegsMilestonesPostSuccessful) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrShipmentLegsMilestonesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null)
        }
        catch (e) { }
      }, 100);

    }


    if (prevProps.CtrlTwrShipmentNotesPrivatePostSuccessful !== this.props.CtrlTwrShipmentNotesPrivatePostSuccessful && this.props.CtrlTwrShipmentNotesPrivatePostSuccessful) {
      const values = queryString.parse(this.props.location.search)
      try {
        var paramPro = formatStringForAPI(values.pro)
      }
      catch (e) {
        var paramPro = null
      }
      this.setState({
        ShipmentNote: '',
        NoteIsPrivate: true
      });
      this.props.ctrlTwrShipmentPrivateNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)
    }

    if (prevProps.CtrlTwrShipmentNotesPublicPostSuccessful !== this.props.CtrlTwrShipmentNotesPublicPostSuccessful && this.props.CtrlTwrShipmentNotesPublicPostSuccessful) {
      const values = queryString.parse(this.props.location.search)
      try {
        var paramPro = formatStringForAPI(values.pro)
      }
      catch (e) {
        var paramPro = null
      }
      this.setState({
        ShipmentNote: '',
        NoteIsPrivate: true
      });
      this.props.ctrlTwrShipmentPublicNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)
    }



  }


  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }


  onClickHotShipment(e, currentStatus, proRef) {
    if (currentStatus) {
      if (window.confirm('Unmark as Hot Shipment?')) {
        try {
          this.props.ctrlTwrHotShipmentPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, proRef, 0)
        }
        catch (e) { }
      }
    }
    else {
      if (window.confirm('Mark as Hot Shipment?')) {
        try {
          this.props.ctrlTwrHotShipmentPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, proRef, 1)
        }
        catch (e) { }
      }
    }
  }

  onClickShipmentSubscribe(e, currentStatus, proRef) {
    if (currentStatus) {
      if (window.confirm('Unsubscribe from Shipment?')) {
        try {
          this.props.ctrlTwrShipmentUserSubscriptionPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, proRef, 0)
        }
        catch (e) { }
      }
    }
    else {
      if (window.confirm('Subscribe to Shipment?')) {
        try {
          this.props.ctrlTwrShipmentUserSubscriptionPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, proRef, 1)
        }
        catch (e) { }
      }
    }
  }





  //Shipment Details Edit Drawer
  onClickEditShipmentDetails(e) {
    this.preloadShipEditDrawer()

    this.setState({
      showShipmentDetailsManagementEditDrawer: true,
    });

  }

  preloadShipEditDrawer() {
    var poEdit = this.props.CtrlTwrShipmentData[0].PO
    var customerEdit = this.props.CtrlTwrShipmentData[0].CustomerID
    var countryEdit = this.props.CtrlTwrShipmentData[0].OriginCountryID
    var destinationEdit = this.props.CtrlTwrShipmentData[0].DestinationCountryID
    var etdEdit = this.props.CtrlTwrShipmentData[0].ETD
    var hotShipmentEdit = this.props.CtrlTwrShipmentData[0].HotShipment

    var palletsEdit = this.props.CtrlTwrShipmentData[0].Pallets
    var casesEdit = this.props.CtrlTwrShipmentData[0].Cases
    var unitCountEdit = this.props.CtrlTwrShipmentData[0].UnitCount

    var weightUnitsEdit = this.props.CtrlTwrShipmentData[0].WeightUnits

    var SpecialRequirementsEdit = this.props.CtrlTwrShipmentData[0].SpecialRequirements
    var GrossWeightEdit = this.props.CtrlTwrShipmentData[0].GrossWeight
    var ChargeableWeightEdit = this.props.CtrlTwrShipmentData[0].ChargeableWeight
    var LengthEdit = this.props.CtrlTwrShipmentData[0].Length
    var WidthEdit = this.props.CtrlTwrShipmentData[0].Width
    var HeightEdit = this.props.CtrlTwrShipmentData[0].Height
    var DimUnitsEdit = this.props.CtrlTwrShipmentData[0].DimUnits
    var CommodityEdit = this.props.CtrlTwrShipmentData[0].Commodity


    this.setState({
      ShipEntryPO: poEdit,
      ShipEntryCustomer: customerEdit,
      ShipEntryOriginCountry: countryEdit,
      ShipEntryDestinationCountry: destinationEdit,
      ShipEntryETD: etdEdit,

      ShipEntryPallets: palletsEdit,
      ShipEntryCases: casesEdit,
      ShipEntryUnitCount: unitCountEdit,

      ShipEntryWeightUnits: weightUnitsEdit,

      SpecialRequirements: SpecialRequirementsEdit,
      GrossWeight: GrossWeightEdit,
      ChargeableWeight: ChargeableWeightEdit,
      Length: LengthEdit,
      Width: WidthEdit,
      Height: HeightEdit,
      DimUnits: DimUnitsEdit,
      Commodity: CommodityEdit,


      ShipHotShipment: hotShipmentEdit,
      UnsavedShipEntryChanges: false,
    });
    // this.forceUpdate()




  }

  onChangeWarehouse(e) {
    this.setState({
      ShipEntryWarehouse: e,
    });
  }

  onChangeCustomer(e) {
    this.setState({
      ShipEntryCustomer: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangePO(e) {
    this.setState({
      ShipEntryPO: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeOriginCountry(e) {
    this.setState({
      ShipEntryOriginCountry: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeDestinationCountry(e) {
    this.setState({
      ShipEntryDestinationCountry: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeETD(e) {
    this.setState({
      ShipEntryETD: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangePallets(e) {
    this.setState({
      ShipEntryPallets: e,
      UnsavedShipEntryChanges: true
    });
  }

  onChangeCases(e) {
    this.setState({
      ShipEntryCases: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeUnitCount(e) {
    this.setState({
      ShipEntryUnitCount: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeGrossWeight(e) {
    this.setState({
      GrossWeight: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeChargeableWeight(e) {
    this.setState({
      ChargeableWeight: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeWeightUnits(e) {
    this.setState({
      ShipEntryWeightUnits: e,
      UnsavedShipEntryChanges: true
    });
  }

  onChangeFunc(e, statename) {
    this.setState({
      [statename]: e,
      UnsavedShipEntryChanges: true
    });
  }


  onClickSaveShipDetailsEdit(e) {
    if (this.state.ShipEntryCustomer === null || this.state.ShipEntryCustomer === '') {
      window.alert('Please select a Customer.')
    }
    else if (this.state.ShipEntryOriginCountry === null || this.state.ShipEntryOriginCountry === '') {
      window.alert('Please select an Origin Country.')
    }

    else if ((this.state.GrossWeight > 0 || this.state.ChargeableWeight > 0) && (this.state.ShipEntryWeightUnits === null || this.state.ShipEntryWeightUnits === '')) {
      window.alert('Please enter weight units.')
    }

    else {
      try {
        this.props.ctrlTwrShipmentPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,
          formatNumberForAPI(this.props.CtrlTwrShipmentData[0].ProRef),

          formatNumberForAPI(this.state.ShipEntryCustomer),
          formatNumberForAPI(this.state.ShipEntryOriginCountry),

          formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryPO),

          formatDateForUrlSearch(this.state.ShipEntryETD),

          formatTrueFalseToBit(this.state.ShipHotShipment),

          formatNumberForAPI(this.state.ShipEntryPallets),
          formatNumberForAPI(this.state.ShipEntryCases),
          formatNumberForAPI(this.state.ShipEntryUnitCount),

          formatStringForAPI(this.state.ShipEntryWeightUnits),
          formatNumberForAPI(this.state.ShipEntryDestinationCountry),

          formatAllTextNumbersSymbolsForAPI(this.state.SpecialRequirements),
          formatNumberForAPI(this.state.GrossWeight),
          formatNumberForAPI(this.state.ChargeableWeight),
          formatNumberForAPI(this.state.Length),
          formatNumberForAPI(this.state.Width),
          formatNumberForAPI(this.state.Height),
          formatStringForAPI(this.state.DimUnits),
          formatAllTextNumbersSymbolsForAPI(this.state.Commodity)

        )
      }
      catch (e) { }
      this.setShipDetailsEditDrawerToClose()
    }
  }


  closeShipDetailsEditDrawer() {
    if (this.state.UnsavedShipEntryChanges) {
      if (window.confirm('Leave without saving changes?')) {
        this.setShipDetailsEditDrawerToClose()
      }
    }
    else {
      this.setShipDetailsEditDrawerToClose()
    }
  }

  setShipDetailsEditDrawerToClose() {
    this.setState({
      showShipmentDetailsManagementEditDrawer: false,
      ShipEntryPO: '',
      ShipEntryCustomer: null,
      ShipEntryOriginCountry: '',
      ShipEntryDestinationCountry: '',
      ShipEntryETD: null,
      ShipEntryPallets: null,
      ShipEntryCases: null,
      ShipEntryUnitCount: null,
      ShipEntryWeightUnits: null,
      ShipHotShipment: null,
      UnsavedShipEntryChanges: false,

      SpecialRequirements: null,
      GrossWeight: null,
      ChargeableWeight: null,
      Length: null,
      Width: null,
      Height: null,
      DimUnits: null,
      Commodity: null,
    });
  }


  //Ship Management Drawer
  onClickOpenShipManagmenetDrawer(e) {


    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)

    this.setState({
      //Ship Management Drawer
      showMilestoneEntryDrawer: true,
      ShipMilestonesExistingDict: {},
      unsavedShipManagmentChanges: false,
      ShipManagementProRef: paramPro,
      addNewMilestoneModalShown: false,
      AddNewLegDict: {},
    });
  }


  onChangeMilestoneEventEntry(e, legID, stateName) {

    var shipManagementDictUpdated = this.state.ShipMilestonesExistingDict
    var keyToUpdate = shipManagementDictUpdated.findIndex(item => item.LegID === legID)

    shipManagementDictUpdated[keyToUpdate][stateName] = e

    this.setState({
      ShipMilestonesExistingDict: shipManagementDictUpdated,
      unsavedShipManagmentChanges: true
    });

    this.forceUpdate()
  }


  loadShipLegData() {
    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    this.setState({
      //Ship Management Drawer
      ShipManagementExistingDict: {},
      unsavedShipManagmentChanges: false,
      ShipManagementProRef: paramPro,
      addNewLegModalShown: false,
      AddNewLegDict: {},
      multiModalShown: false,
      multiModalValuesSaving: false,
      ShipManagementHbolData: {},
      ShipManagementPoData: {},
      ShipManagementCustRefData: {},
    });
  }

  preloadHbolPoCustRef() {
    var hbolData = this.props.CtrlTwrShipmentLegsHbolData
    var poData = this.props.CtrlTwrShipmentLegsPoData
    var custRefData = this.props.CtrlTwrShipmentLegsCustomerRefData

    this.setState({
      ShipManagementHbolData: hbolData,
      ShipManagementPoData: poData,
      ShipManagementCustRefData: custRefData
    });
    this.forceUpdate()
  }

  preloadShipManagement() {
    var legsData = this.props.CtrlTwrShipmentLegsData
    var hbolData = this.props.CtrlTwrShipmentLegsHbolData
    var poData = this.props.CtrlTwrShipmentLegsPoData
    var custRefData = this.props.CtrlTwrShipmentLegsCustomerRefData

    this.setState({
      ShipMilestonesExistingDict: legsData,
      ShipManagementExistingDict: legsData,
      unsavedShipManagmentChanges: false,
      ShipManagementHbolData: hbolData,
      ShipManagementPoData: poData,
      ShipManagementCustRefData: custRefData
    });
    this.forceUpdate()

  }



  onClickMilestoneEventEntryDrawerSave(e) {
    // if (window.confirm('Save Data?')) {
    for (let l in this.state.ShipMilestonesExistingDict) {
      try {
        this.props.ctrlTwrShipmentLegsDataPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,

          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].ProRef),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].LegNumber),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].ModeID),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].LegID),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].WarehouseID),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].BOL),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].MBOL),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].HBOL),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].PO),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Container),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Carrier),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Seal),

          formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].PlannedDepartureDate),
          formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].EstimatedDeliveryDate),

          // formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].EstimatedPickupDate),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].PickupAddress),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].DeliveryAddress),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Trailer),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Vessel),
          // formatDateForUrlSearch(this.state.ShipMilestonesExistingDict[l].SailingDate),
          // formatDateForUrlSearch(this.state.ShipMilestonesExistingDict[l].SailingEstimatedArrivalDate),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].SeaportOriginID),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].SeaportDestID),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Flight),
          // formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].FlightETD),
          // formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].FlightETA),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].AirportOriginID),
          formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].AirportDestID),
          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].CustomerRef),

          formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Shipper_Name), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_AddressLine), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_City), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_State), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_PostalCode), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_CountryCode), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Consignee_Name), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_AddressLine), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_City), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_State), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_PostalCode), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_CountryCode),

          formatTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].PickupAppointmentTime),
          formatTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].DeliveryAppointmentTime)
        )
      }
      catch (e) { }
    }

    this.setState({
      unsavedShipManagmentChanges: false,
      showMilestoneEntryDrawer: false,
      addNewMilestoneModalShown: false
    });
    // }
  }


  onClickShipmentManagementDrawerSave(e) {
    // check if depart or deliver dates are blank
    if (this.state.ShipManagementExistingDict.findIndex(item => item.ModeID !== 6 && (item.PlannedDepartureDate === null || item.PlannedDepartureDate === '')) !== -1) {
      window.alert('Please enter all Departure Dates before saving.')
    }
    else if (this.state.ShipManagementExistingDict.findIndex(item => item.ModeID !== 6 && (item.EstimatedDeliveryDate === null || item.EstimatedDeliveryDate === '')) !== -1) {
      window.alert('Please enter all Delivery Dates before saving.')
    }
    else {

      for (let l in this.state.ShipManagementExistingDict) {
        try {
          this.props.ctrlTwrShipmentLegsDataPost(
            this.props.currentUserEmail,
            this.props.userSignInData.AuthToken,

            formatNumberForAPI(this.state.ShipManagementExistingDict[l].ProRef),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].LegNumber),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].ModeID),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].LegID),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].WarehouseID),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].BOL),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].MBOL),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].HBOL),
            formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].PO),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].Container),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].Carrier),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].Seal),

            formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].PlannedDepartureDate),
            formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].EstimatedDeliveryDate),


            // formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].PlannedDepartureDate),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].PickupAddress),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].DeliveryAddress),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].Trailer),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].Vessel),
            // formatDateForUrlSearch(this.state.ShipManagementExistingDict[l].PlannedDepartureDate),
            // formatDateForUrlSearch(this.state.ShipManagementExistingDict[l].EstimatedDeliveryDate),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].SeaportOriginID),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].SeaportDestID),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].Flight),
            // formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].PlannedDepartureDate),
            // formatDateTimeForUrlSearch(this.state.ShipManagementExistingDict[l].EstimatedDeliveryDate),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].AirportOriginID),
            formatNumberForAPI(this.state.ShipManagementExistingDict[l].AirportDestID),
            formatStringForAPI(this.state.ShipManagementExistingDict[l].CustomerRef),

            formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Shipper_Name), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Origin_AddressLine), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Origin_City), formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_State), formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_PostalCode), formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_CountryCode), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Consignee_Name), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Destination_AddressLine), formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Destination_City), formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_State), formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_PostalCode), formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_CountryCode),


            formatTimeForUrlSearch(this.state.ShipManagementExistingDict[l].PickupAppointmentTime),
            formatTimeForUrlSearch(this.state.ShipManagementExistingDict[l].DeliveryAppointmentTime)

          )
        }
        catch (e) { }
      }

      this.setState({
        unsavedShipManagmentChanges: false,
        addNewLegModalShown: false,
        multiModalShown: false,
        multiModalValuesSaving: false,
      });
    }
  }

  onClickDeleteLeg(e, legID) {
    if (this.props.CtrlTwrShipmentLegsData.length === 1) {
      window.alert('Error: Cannot delete all legs.')
    }
    else {
      if (window.confirm('Delete Leg?')) {
        this.props.ctrlTwrShipmentLegsPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,
          null,
          null,
          null,
          null,
          legID,
          1



        )
      }
    }
  }

  onClickShowAddLeg(e) {
    if (this.state.unsavedShipManagmentChanges) {
      window.alert('Please save changes before adding new leg.')
    }
    else {
      const legNumbersArray = this.state.ShipManagementExistingDict.map(item => item.LegNumber);
      try {
        var newLegNumber = findLowestMissingNumber(legNumbersArray)
      }
      catch (e) {
        var newLegNumber = 1
      }
      this.setState({
        addNewLegModalShown: true,
        newLegModalLegNumber: newLegNumber,
        newLegModalMode: null,
      });
    }
  }

  onChangeShipmentManagement(e, legID, stateName) {

    var shipManagementDictUpdated = this.state.ShipManagementExistingDict
    var keyToUpdate = shipManagementDictUpdated.findIndex(item => item.LegID === legID)

    shipManagementDictUpdated[keyToUpdate][stateName] = e

    this.setState({
      ShipManagementExistingDict: shipManagementDictUpdated,
      unsavedShipManagmentChanges: true
    });

    this.forceUpdate()
  }

  closeShipmentManagementDrawer() {
    if (this.state.unsavedShipManagmentChanges) {
      if (window.confirm('Leave without saving changes?')) {
        this.setShipmentManagementDrawerToClose()
      }
    }
    else {
      this.setShipmentManagementDrawerToClose()
    }
  }

  setShipmentManagementDrawerToClose() {
    this.setState({
      ReceiveDict: {},
      LocationDict: {},
      AddNewLegDict: {},
      unsavedShipManagmentChanges: false,

      addNewLegModalShown: false,

      ReceiveDrawerContainerID: null,
      ReceiveDrawerContainer: null,
      ReceiveDrawerNo: null,
      ReceiveDrawerPMC: null,
      ReceiveDrawerPickupBy: null,
      ReceiveDrawerTrips: null,
      ReceiveDrawerArrivalTime: null,
      ReceiveDrawerLFD: null,
      ReceiveDrawerArrivalAtWarehouse: null,
      ReceiveDrawerDeliveryTime: null,
      ReceiveDrawerNotes: null,
    });
  }


  onClickMulti(e, legID, stateName, hbolID) {
    if (this.state.unsavedShipManagmentChanges) {
      window.alert('Please save changes first.')
    }
    else {
      this.setState({
        multiModalShown: true,
        multiModalValuesSaving: false,
        multiModalLegNumber: legID,
        multiModalStateName: stateName,
        multiModalHbolID: hbolID,
        multiModalNewValue: ''
      });
    }

  }

  onClickDeleteMulti(e, stateName, hbolID, poID, custRefID) {

    if (stateName === 'HBOL') {
      if (window.confirm('Delete HBOL?')) {
        this.props.ctrlTwrShipmentLegsHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, hbolID, null, null, 1)
      }
    }
    else if (stateName === 'PO') {
      if (window.confirm('Delete PO?')) {
        this.props.ctrlTwrShipmentLegsPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, poID, null, 1)
      }
    }
    else if (stateName === 'CustomerRef') {
      if (window.confirm('Delete Customer Ref?')) {
        this.props.ctrlTwrShipmentLegsCustomerRefPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, custRefID, null, null, null, 1)
      }
    }
  }


  //Add new Milestone Modal

  onClickAddMilestone(e, legID, modeID, mode) {
    this.setState({
      addNewMilestoneModalShown: true,
      newMilestoneModalLegID: legID,
      newMilestoneModalModeID: modeID,
      newMilestoneModalMode: mode,

      newMilestoneModalMilestoneID: null,
      newMilestoneModalException: false,
      newMilestoneModalMilestoneDT: null,
      newMilestoneModalMilestoneTimezone: null,
      newMilestoneModalCountryCodeID: dbValues.USCountryCodeID,
      newMilestoneModalCity: null,
      newMilestoneModalState: null,
      newMilestoneModalStateCodeID: null,
      newMilestoneModalZip: null,
      newMilestoneModalSeaportCodeID: null,
      newMilestoneModalAirportCodeID: null,
      newMilestoneModalWarehouseID: null,
    });
  }



  onClickAddMissingMilestone(e, rowData, history, additionalClickdata) {

    this.setState({
      addNewMilestoneModalShown: true,
      newMilestoneModalLegID: additionalClickdata.LegID,
      newMilestoneModalModeID: additionalClickdata.ModeID,
      newMilestoneModalMode: additionalClickdata.ModeName,

      newMilestoneModalMilestoneID: rowData.value,
      newMilestoneModalException: rowData.ExceptionMilestone,
      newMilestoneModalMilestoneDT: null,
      newMilestoneModalMilestoneTimezone: null,
      newMilestoneModalCountryCodeID: dbValues.USCountryCodeID,
      newMilestoneModalCity: null,
      newMilestoneModalState: null,
      newMilestoneModalStateCodeID: null,
      newMilestoneModalZip: null,
      newMilestoneModalSeaportCodeID: null,
      newMilestoneModalAirportCodeID: null,
      newMilestoneModalWarehouseID: null,
    });
  }


  onClickDelete(e, rowData, history) {
    if (window.confirm('Delete Milestone?')) {
      try {
        this.props.ctrlTwrShipmentLegsMilestonesPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,
          formatNumberForAPI(rowData.ShipmentLegMilestoneID),
          formatNumberForAPI(rowData.ProRef),
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          1,
          null
        )
      }
      catch (e) { }
    }
  }


  closeMilestoneEventEntryDrawer() {
    if (this.state.unsavedShipManagmentChanges) {
      if (window.confirm('Leave without saving changes?')) {
        this.setMilestoneEventEntryDrawerToClose()
      }
    }
    else {
      this.setMilestoneEventEntryDrawerToClose()
    }
  }

  setMilestoneEventEntryDrawerToClose() {
    this.setState({
      ReceiveDict: {},
      LocationDict: {},
      AddNewLegDict: {},
      unsavedShipManagmentChanges: false,

      showMilestoneEntryDrawer: false,
      addNewMilestoneModalShown: false,

      ReceiveDrawerContainerID: null,
      ReceiveDrawerContainer: null,
      ReceiveDrawerNo: null,
      ReceiveDrawerPMC: null,
      ReceiveDrawerPickupBy: null,
      ReceiveDrawerTrips: null,
      ReceiveDrawerArrivalTime: null,
      ReceiveDrawerLFD: null,
      ReceiveDrawerArrivalAtWarehouse: null,
      ReceiveDrawerDeliveryTime: null,
      ReceiveDrawerNotes: null,
    });
  }



  //Multi Modal

  onChangeMultiModal(e) {
    this.setState({
      multiModalNewValue: e
    });

  }

  handleCloseMultiModal() {
    this.setState({
      multiModalShown: false,
      multiModalValuesSaving: false,
      multiModalLegNumber: null,
      multiModalStateName: null,
      multiModalHbolID: null,
      multiModalNewValue: ''
    });
  }

  setMultiModalToSaving(trueOrFalse) {
    this.setState({
      multiModalValuesSaving: trueOrFalse,
    });
  }


  handleSaveMultiModal() {
    this.setMultiModalToSaving(true)

    // try

    var multiArray = this.state.multiModalNewValue.split(',').map(item => item.trim())

    const uniqueMultiArray = [...new Set(multiArray)];

    if (this.state.multiModalStateName === 'HBOL') {
      for (let x in uniqueMultiArray) {
        this.props.ctrlTwrShipmentLegsHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.multiModalHbolID, this.state.multiModalLegNumber, formatStringForAPI(uniqueMultiArray[x]), null)
      }
    }
    else if (this.state.multiModalStateName === 'PO') {
      for (let x in uniqueMultiArray) {
        this.props.ctrlTwrShipmentLegsPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.multiModalHbolID, this.state.multiModalLegNumber, null, formatStringForAPI(uniqueMultiArray[x]), null)
      }
    }
    else if (this.state.multiModalStateName === 'CustomerRef') {
      for (let x in uniqueMultiArray) {
        this.props.ctrlTwrShipmentLegsCustomerRefPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.state.multiModalLegNumber, this.state.multiModalHbolID, formatStringForAPI(uniqueMultiArray[x]), null)
      }
    }

    this.setMultiModalToSaving(false)

    this.handleCloseMultiModal()

  }


  //Add new Leg Modal
  onChangeAddNewLegModal(e, label) {
    try {
      if (label === 'Mode') {
        this.setState({
          newLegModalMode: e
        });
      }
      else if (label === 'Leg') {
        if (this.state.ShipManagementExistingDict.filter(item => item.LegNumber === Number(e)).length > 0) {
          window.alert('Duplicate leg entry, please enter new leg number.')
        }
        else if (e === 0) {
          window.alert('Please select number greater than 0.')
        }
        else {
          this.setState({
            newLegModalLegNumber: String(e)
          });
        }
      }
    }
    catch (e) {

    }

    this.forceUpdate()
  }


  handleCloseAddNewLegModal() {
    this.setState({
      addNewLegModalShown: false,
      newLegModalLegNumber: null,
      newLegModalMode: null,
    });
  }

  handleSaveAddNewLegModal() {

    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }


    // if (this.state.ShipManagementProRef === null || this.state.ShipManagementProRef === '') {
    //   window.alert('Error, please refresh and try again.')
    // }
    if (this.state.newLegModalLegNumber === null || this.state.newLegModalLegNumber === '') {
      window.alert('Please enter leg number.')
    }
    else if (this.state.newLegModalMode === null || this.state.newLegModalMode === '') {
      window.alert('Please select mode.')
    }
    else {
      this.props.ctrlTwrShipmentLegsPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        paramPro,
        formatNumberForAPI(this.state.newLegModalLegNumber),
        formatNumberForAPI(this.state.newLegModalMode),
        null,
        null,
        null




      )

      this.handleCloseAddNewLegModal()
      this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
      this.setState({
        //Ship Management Drawer
        ShipManagementExistingDict: {},
        unsavedShipManagmentChanges: false,
        addNewLegModalShown: false,
        AddNewLegDict: {},
        ShipManagementHbolData: {},
        ShipManagementPoData: {},
        ShipManagementCustRefData: {},
      });
    }
  }




  //Table on click details
  onClickShipDetails(e, rowData, history) {
    try {
      var paramID = rowData[tableIdCol]
      history.push({
        pathname: 'shipmentdetailsmanagement',
        search: 'pro=\'' + paramID + '\''
      });
    }
    catch (e) {
    }
  }

  //Edit Milestone Modal

  onClickEditMilestone(e, rowData, history) {
    this.setState({
      addNewMilestoneModalShown: true,
      newMilestoneModalLegID: rowData.LegID,
      newMilestoneModalModeID: rowData.ModeID,
      newMilestoneModalMode: rowData.ModeName,

      newMilestoneModalShipmentLegMilestoneID: rowData.ShipmentLegMilestoneID,

      newMilestoneModalMilestoneID: rowData.MilestoneID,
      newMilestoneModalMilestoneDT: rowData.MilestoneDT,
      newMilestoneModalMilestoneTimezone: rowData.TimezoneID,
      newMilestoneModalCountryCodeID: rowData.CountryCodeID,
      newMilestoneModalCity: rowData.City,
      newMilestoneModalState: rowData.State,
      newMilestoneModalStateCodeID: rowData.StateCodeID,
      newMilestoneModalZip: rowData.Zip,
      newMilestoneModalSeaportCodeID: rowData.SeaportCodeID,
      newMilestoneModalAirportCodeID: rowData.AirportCodeID,
      newMilestoneModalWarehouseID: rowData.WarehouseID,
    });
  }



  onChangeAddNewMilestone(e, label) {
    if (label === 'newMilestoneModalMilestoneID') {
      this.setState({
        newMilestoneModalMilestoneID: e
      });
    }
    else if (label === 'newMilestoneModalMilestoneDT') {
      this.setState({
        newMilestoneModalMilestoneDT: e
      });
    }
    else if (label === 'newMilestoneModalMilestoneTimezone') {
      this.setState({
        newMilestoneModalMilestoneTimezone: e
      });
    }
    else if (label === 'newMilestoneModalCountryCodeID') {
      this.setState({
        newMilestoneModalCountryCodeID: e
      });
      if (e !== dbValues.USCountryCodeID) {
        this.setState({
          newMilestoneModalStateCodeID: null
        });
      }
      else if (e === dbValues.USCountryCodeID) {
        this.setState({
          newMilestoneModalState: null
        });
      }
    }
    else if (label === 'newMilestoneModalCity') {
      this.setState({
        newMilestoneModalCity: e
      });
    }
    else if (label === 'newMilestoneModalState') {
      this.setState({
        newMilestoneModalState: e,
        newMilestoneModalStateCodeID: null
      });
    }
    else if (label === 'newMilestoneModalStateCodeID') {
      this.setState({
        newMilestoneModalStateCodeID: e,
        newMilestoneModalState: null
      });
    }
    else if (label === 'newMilestoneModalZip') {
      this.setState({
        newMilestoneModalZip: e
      });
    }
    else if (label === 'newMilestoneModalSeaportCodeID') {
      this.setState({
        newMilestoneModalSeaportCodeID: e
      });
    }
    else if (label === 'newMilestoneModalAirportCodeID') {
      this.setState({
        newMilestoneModalAirportCodeID: e
      });
    }
    else if (label === 'newMilestoneModalWarehouseID') {
      this.setState({
        newMilestoneModalWarehouseID: e
      });
    }
  }


  handleCloseAddNewModal() {
    this.setState({
      addNewMilestoneModalShown: false,

      newMilestoneModalLegID: null,
      newMilestoneModalMilestoneID: null,
      newMilestoneModalShipmentLegMilestoneID: null,

      newMilestoneModalModeID: null,
      newMilestoneModalMode: null,
      newMilestoneModalMilestoneDT: null,
      newMilestoneModalMilestoneTimezone: null,
      newMilestoneModalCountryCodeID: null,
      newMilestoneModalCity: null,
      newMilestoneModalState: null,
      newMilestoneModalStateCodeID: null,
      newMilestoneModalZip: null,
      newMilestoneModalSeaportCodeID: null,
      newMilestoneModalAirportCodeID: null,
      newMilestoneModalWarehouseID: null,
    });
  }


  onClickDeleteMilestone(e, rowData, history) {
    if (window.confirm('Delete Milestone?')) {
      this.props.ctrlTwrShipmentLegsMilestonesPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        formatNumberForAPI(rowData.ShipmentLegMilestoneID),
        formatNumberForAPI(rowData.ProRef),
        null, null, null, null, null, null, null, null, null, null, null, null,
        1,
        null
      )
    }
  }

  handleSaveAddNewModal() {

    if (this.state.newMilestoneModalMilestoneID === null || this.state.newMilestoneModalMilestoneID === '') {
      window.alert('Please enter a milestone.')
    }
    else if (this.state.newMilestoneModalMilestoneDT === null || this.state.newMilestoneModalMilestoneDT === '') {
      window.alert('Please enter date.')
    }
    else if (this.state.newMilestoneModalMilestoneTimezone === null || this.state.newMilestoneModalMilestoneTimezone === '') {
      window.alert('Please enter timezone.')
    }
    else {
      this.props.ctrlTwrShipmentLegsMilestonesPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        formatNumberForAPI(this.state.newMilestoneModalShipmentLegMilestoneID),
        formatNumberForAPI(this.props.CtrlTwrShipmentData[0].ProRef),
        formatNumberForAPI(this.state.newMilestoneModalLegID),
        formatNumberForAPI(this.state.newMilestoneModalModeID),
        formatNumberForAPI(this.state.newMilestoneModalMilestoneID),
        formatDateTimeForUrlSearch(this.state.newMilestoneModalMilestoneDT),
        formatNumberForAPI(this.state.newMilestoneModalMilestoneTimezone),
        formatNumberForAPI(this.state.newMilestoneModalCountryCodeID),
        formatStringForAPI(this.state.newMilestoneModalCity),
        formatStringForAPI(this.state.newMilestoneModalState),
        formatStringForAPI(this.state.newMilestoneModalZip),
        formatNumberForAPI(this.state.newMilestoneModalSeaportCodeID),
        formatNumberForAPI(this.state.newMilestoneModalAirportCodeID),
        formatNumberForAPI(this.state.newMilestoneModalWarehouseID),
        0,
        formatNumberForAPI(this.state.newMilestoneModalStateCodeID)

      )

      this.handleCloseAddNewModal()
      // this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef)
      // this.setState({

      //     addNewMilestoneModalShown: false,

      //     newMilestoneModalLegID: null,
      //     newMilestoneModalMilestoneID: null,

      //     newMilestoneModalModeID: null,
      //     newMilestoneModalMode: null,
      //     newMilestoneModalMilestoneDT: null,
      //     newMilestoneModalCountryCodeID: null,
      //     newMilestoneModalCity: null,
      //     newMilestoneModalState: null,
      //     newMilestoneModalZip: null,
      //     newMilestoneModalSeaportCodeID: null,
      //     newMilestoneModalAirportCodeID: null,
      // });
    }
  }



  //File Upload
  onClickDownloadFileUpload(e, rowData, history) {
    try {
      this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.API.data.FileDownloadCtrlTwrS3)
    }
    catch (e) {
      window.alert("Error Downloading File.")
    }
  }
  onClickDeleteFileUpload(e, rowData, history) {
    if (window.confirm('Do you wish to delete this file?'))
      this.props.ctrlTwrShipmentFileUploadPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        this.props.customerSelection,
        formatNumberForAPI(rowData.FileUploadID),
        null,
        null,
        null,
        null,
        1
      )
  }



  //Quoting File Upload
  onClickDeleteQuotingFileUpload(e, rowData, history) {
    if (window.confirm('Do you wish to delete this file?')) {
      this.props.ctrlTwrQuotingShipmentFileUploadPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        this.props.customerSelection,
        formatNumberForAPI(rowData.FileUploadID),
        null,
        null,
        null,
        null,
        1,
        null
      )
    }
  }


  onClickMoveDocToShipment(e, rowData, history) {

    if (window.confirm('Do you wish to add this into shipment files?')) {
      this.props.ctrlTwrQuotingShipmentFileUploadPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        this.props.customerSelection,
        formatNumberForAPI(rowData.FileUploadID),
        formatNumberForAPI(rowData.ProRef),
        null,
        null,
        null,
        null,
        1
      )
    }

  }



  onChangeCheckBoxOrgNote(e, value) {
    this.setState({
      NoteIsPrivate: value
    });
  }
  onChangeNote(e) {
    this.setState({
      ShipmentNote: e
    });
  }

  handleSaveAddNewNote(e) {
    try {
      if (typeof (this.props.organizationSelection) === 'number' && this.state.NoteIsPrivate) {
        if (this.state.ShipmentNote !== '') {
          this.props.ctrlTwrShipmentPrivateNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrShipmentData[0].ProRef), formatAllTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
        }
      }
      else {
        //not assigned to organization can only write public notes:
        if (this.state.ShipmentNote !== '') {
          this.props.ctrlTwrShipmentPublicNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrShipmentData[0].ProRef), formatAllTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
        }
      }
    }
    catch (e) {
    }

  }
  onClickDeletePrivateNote(e, noteid) {
    if (window.confirm('Confirm Delete Note?')) {
      this.props.ctrlTwrShipmentPrivateNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(noteid), null, null, 1)
    }
  }

  onClickDeletePublicNote(e, noteid) {
    if (window.confirm('Confirm Delete Note?')) {
      this.props.ctrlTwrShipmentPublicNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(noteid), null, null, 1)
    }
  }


  render() {
    const { sortColumn, sortType } = this.state;

    let pageHeader;
    let fileUploadDiv;
    let lateShipmentDiv;
    let shipSubscriptionDiv;
    let alertDiv;
    let detailsDiv;
    let editDrawer;
    if (this.props.isCtrlTwrShipmentLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
      editDrawer = <div></div>
    }
    else if (this.props.isCtrlTwrShipmentFileUploadLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentData[0]) !== 'undefined' && this.props.CtrlTwrShipmentData != null) {

      editDrawer = <ShipmentDetailsManagementEditDrawer {...this.props} state={this.state} onChangeMilestoneEventEntry={this.onChangeMilestoneEventEntry} onChangeWarehouse={this.onChangeWarehouse} onChangeCustomer={this.onChangeCustomer} onChangePO={this.onChangePO} onChangeOriginCountry={this.onChangeOriginCountry} onChangeDestinationCountry={this.onChangeDestinationCountry} onChangeETD={this.onChangeETD} onChangePallets={this.onChangePallets} onChangeCases={this.onChangeCases} onChangeUnitCount={this.onChangeUnitCount} onChangeGrossWeight={this.onChangeGrossWeight} onChangeChargeableWeight={this.onChangeChargeableWeight} onChangeWeightUnits={this.onChangeWeightUnits} onChangeFunc={this.onChangeFunc} onClickSaveShipDetailsEdit={this.onClickSaveShipDetailsEdit} close={this.closeShipDetailsEditDrawer} />

      // if (this.props.CtrlTwrShipmentData[0].HotShipment) {
      //   hotShipmentDiv = <customIconButton
      //     iconLabel={'Hot Shipment'}
      //     icon={<i className="fa fa-exclamation-triangle" />}
      //     iconColor={'red'}
      //     allowClick={true}
      //     onClickIconButton={(e) => { this.onClickHotShipment(e, this.props.CtrlTwrShipmentData[0].HotShipment, this.props.CtrlTwrShipmentData[0].ProRef) }}
      //     isMobile={this.props.isMobile}
      //   />
      // }
      // else {
      //   hotShipmentDiv = <customIconButton
      //     iconLabel={'Click to mark Hot Shipment'}
      //     icon={<i className="fa fa-exclamation-triangle" />}
      //     iconColor={'white'}
      //     allowClick={true}
      //     onClickIconButton={(e) => { this.onClickHotShipment(e, this.props.CtrlTwrShipmentData[0].HotShipment, this.props.CtrlTwrShipmentData[0].ProRef) }}
      //     isMobile={this.props.isMobile}
      //   />
      // }

      if (this.props.CtrlTwrShipmentData[0].LateShipment) {
        lateShipmentDiv = <customIconButton
          iconLabel={'Late Shipment'}
          icon={<i className="fa fa-hourglass" />}
          iconColor={'red'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }
      else {
        lateShipmentDiv = <customIconButton
          iconLabel={'On Time'}
          icon={<i className="fa fa-hourglass" />}
          iconColor={'green'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }

      if (this.props.CtrlTwrShipmentData[0].ShipmentSubscription) {
        shipSubscriptionDiv = <customIconButton
          iconLabel={'Shipment Subscription'}
          icon={<i className="fa fa-envelope-open" />}
          iconColor={'green'}
          allowClick={true}
          onClickIconButton={(e) => { this.onClickShipmentSubscribe(e, this.props.CtrlTwrShipmentData[0].ShipmentSubscription, this.props.CtrlTwrShipmentData[0].ProRef) }}
          isMobile={this.props.isMobile}
        />
      }
      else {
        shipSubscriptionDiv = <customIconButton
          iconLabel={'Click to Subscribe'}
          icon={<i className="fa fa-envelope-open" />}
          iconColor={'white'}
          allowClick={true}
          onClickIconButton={(e) => { this.onClickShipmentSubscribe(e, this.props.CtrlTwrShipmentData[0].ShipmentSubscription, this.props.CtrlTwrShipmentData[0].ProRef) }}
          isMobile={this.props.isMobile}
        />
      }

      // if (this.props.CtrlTwrShipmentData[0].CarrierYellowAlerts > 0 || this.props.CtrlTwrShipmentData[0].CarrierRedAlerts > 0 || this.props.CtrlTwrShipmentData[0].CustomerYellowAlerts > 0 || this.props.CtrlTwrShipmentData[0].CustomerRedAlerts > 0) {
      if (this.props.CtrlTwrShipmentData[0].CustomerYellowAlerts > 0 || this.props.CtrlTwrShipmentData[0].CustomerRedAlerts > 0) {
        alertDiv = <customIconButton
          iconLabel={'Shipment Alerts'}
          icon={<i className="fa fa-bell" />}
          iconColor={'yellow'}
          allowClick={false}
          // onClickIconButton={(e) => { this.onClickShipmentSubscribe(e, this.props.CtrlTwrShipmentData[0].ShipmentSubscription, this.props.CtrlTwrShipmentData[0].ProRef) }}
          isMobile={this.props.isMobile}
        />
      }
      else {
        alertDiv = <customIconButton
          iconLabel={'No Shipment Alerts'}
          icon={<i className="fa fa-bell" />}
          iconColor={'white'}
          allowClick={false}
          // onClickIconButton={(e) => { this.onClickShipmentSubscribe(e, this.props.CtrlTwrShipmentData[0].ShipmentSubscription, this.props.CtrlTwrShipmentData[0].ProRef) }}
          isMobile={this.props.isMobile}
        />
      }

      pageHeader = <div style={{ float: 'left' }}>  {'Control Tower - Shipment: ' + this.props.CtrlTwrShipmentData[0].ProRef} </div>

      fileUploadDiv = RUploader(
        this.props.isMobile,                          // isMobile,
        this.props.history,                           // history,
        this.props.currentUserEmail,                  //currentUserEmail,
        this.props.userSignInData,                    //userSignInData
        this.props.customerSelection,                 //customerSelection
        this.props.CtrlTwrShipmentFileUploadData,      //fileList,
        this.props.ctrlTwrShipmentFileUploadPost,     //onUploadAction,
        null,                                         // loading,
        this.props.CtrlTwrShipmentData[0].ProRef,     // proRef,
        appAPI.API.data.FileDownloadCtrlTwrS3         //s3Bucket
      )

      detailsDiv =
        <div>
          <Row >
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}
                header={
                  <ButtonToolbar >
                    <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditShipmentDetails(e) }} >Edit</Button>
                  </ButtonToolbar>
                }
              >
                PO: <strong>{this.props.CtrlTwrShipmentData[0].PO}</strong>
                <br />
                Planned Pickup: <strong>{this.props.CtrlTwrShipmentData[0].ETD}</strong>
                <br />
                Customer: <strong>{this.props.CtrlTwrShipmentData[0].CustomerName}</strong>
                <br />
                Origin Country: <strong>{this.props.CtrlTwrShipmentData[0].CountryName}</strong>
                <br />
                Destination Country: <strong>{this.props.CtrlTwrShipmentData[0].DestCountryName}</strong>
                <br />

                Current Mode: <strong>{this.props.CtrlTwrShipmentData[0].ModeName}</strong>
                <br />

                Current Milestone: <strong>{this.props.CtrlTwrShipmentData[0].Milestone}</strong>
                <br />
                Current Milestone DT: <strong>{this.props.CtrlTwrShipmentData[0].MilestoneDT}</strong>
                <br />




              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}
                header={<div></div>}
              >

                Pallets: <strong>{this.props.CtrlTwrShipmentData[0].Pallets}</strong>
                <br />
                Cases: <strong>{this.props.CtrlTwrShipmentData[0].Cases}</strong>
                <br />
                Units: <strong>{this.props.CtrlTwrShipmentData[0].UnitCount}</strong>
                <br />
                Gross Weight: <strong>{this.props.CtrlTwrShipmentData[0].GrossWeight} {this.props.CtrlTwrShipmentData[0].WeightUnits}</strong>
                <br />
                Chargeable Weight: <strong>{this.props.CtrlTwrShipmentData[0].ChargeableWeight} {this.props.CtrlTwrShipmentData[0].WeightUnits}</strong>
                <br />
                Special Requirements: <strong>{this.props.CtrlTwrShipmentData[0].SpecialRequirements}</strong>
                <br />
                Commodity: <strong>{this.props.CtrlTwrShipmentData[0].Commodity}</strong>
                <br />
                Dimensions (L x W x H): <strong>{this.props.CtrlTwrShipmentData[0].Length} {this.props.CtrlTwrShipmentData[0].Width !== null ? 'x' : ''} {this.props.CtrlTwrShipmentData[0].Width} {this.props.CtrlTwrShipmentData[0].Height !== null ? 'x' : ''} {this.props.CtrlTwrShipmentData[0].Height} {this.props.CtrlTwrShipmentData[0].DimUnits}</strong>
                <br />
              </Panel>
            </Col>
          </Row>
        </div>
    }
    else {
      detailsDiv = <div></div>;
      // hotShipmentDiv = <div></div>;
      lateShipmentDiv = <div></div>
      alertDiv = <div></div>
      shipSubscriptionDiv = <div></div>
      editDrawer = <div></div>
    }



    let quotingFileUploadsTable;
    if (this.props.isCtrlTwrQuotingShipmentFileUploadLoading) {
      quotingFileUploadsTable =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrQuotingShipmentFileUploadData) !== 'undefined' && this.props.CtrlTwrQuotingShipmentFileUploadData != null && this.props.CtrlTwrQuotingShipmentFileUploadData.length > 0) {
      var quoteFilesTableComponent = RTableGeneral(
        tableIdCol,     //   keyColumn,
        this.props.CtrlTwrQuotingShipmentFileUploadData,     //   tableData,
        CtrlTwrShipmentFileUploadsDataElements,       //   DataElements,
        this.props.isCtrlTwrQuotingShipmentFileUploadLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        true,      //   onClickEditMilestone,
        this.onClickDownloadFileUpload,       //   onClickSelect,
        'Download',       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        true,       //   link1bool,
        'Add to Files',     //   link1text,
        this.onClickMoveDocToShipment,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      quotingFileUploadsTable = <Panel
        bordered style={{ marginBottom: 10 }}
        header='Quote Uploads'
      >
        {quoteFilesTableComponent}
      </Panel>


    }
    else {
      quotingFileUploadsTable = <div></div>
    }




    let fileUploadsTable;
    if (this.props.isCtrlTwrShipmentFileUploadLoading) {

      fileUploadsTable =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentFileUploadData) !== 'undefined' && this.props.CtrlTwrShipmentFileUploadData != null && this.props.CtrlTwrShipmentFileUploadData.length > 0) {
      var milestoneTableComponent = RTableGeneral(
        tableIdCol,     //   keyColumn,
        this.props.CtrlTwrShipmentFileUploadData,     //   tableData,
        CtrlTwrShipmentFileUploadsDataElements,       //   DataElements,
        this.props.isCtrlTwrShipmentFileUploadLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        true,      //   onClickEditMilestone,
        this.onClickDownloadFileUpload,       //   onClickSelect,
        'Download',       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        true,       //   link1bool,
        'Delete',     //   link1text,
        this.onClickDeleteFileUpload,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      fileUploadsTable = <Panel
        bordered style={{ marginBottom: 10 }}
        header='File Uploads'
      >
        {milestoneTableComponent}
      </Panel>


    }
    else {
      fileUploadsTable = <div></div>
    }


    let iconsDiv = <div>

      <Row>
        <Col md={8} sm={24}>
          {lateShipmentDiv}
        </Col>

        <Col md={8} sm={24}>
          {alertDiv}
        </Col>



        <Col md={8} sm={24}>
          {shipSubscriptionDiv}
        </Col>

      </Row>
    </div>


    let legsDataDiv;
    if (this.props.isCtrlTwrShipmentLegsDataLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isCtrlTwrShipmentLegsCustomerRefLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isCtrlTwrShipmentLegsPoLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isCtrlTwrShipmentLegsHbolLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentLegsData[0]) !== 'undefined' && this.props.CtrlTwrShipmentLegsData != null && typeof (this.state.ShipManagementExistingDict[0]) !== 'undefined' && this.state.ShipManagementExistingDict != null) {
      legsDataDiv =
        <div>
          <Panel bordered style={{ marginBottom: 10 }}>
            {
              this.state.ShipManagementExistingDict.map(legItem => (

                legItem.ModeID === 1 ? //Ocean
                  <Panel
                    bordered
                    style={{ marginBottom: '10px' }}
                    header={
                      <Row>
                        <Col md={16} sm={24}>
                          <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                        </Col>
                        <Col md={8} sm={24}>
                          <ButtonToolbar >
                            <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                          </ButtonToolbar>
                        </Col>
                      </Row>
                    }
                  >
                    <FormGroup >
                      <InputGroup style={styles}>
                        <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                        <InputPicker preventOverflow
                          container={() => mountRef.current}
                          placeholder={'Managing Warehouse...'}
                          value={legItem['WarehouseID']}
                          onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'WarehouseID') }}
                          data={this.state.warehouseArray}
                          style={inputStyle}
                          cleanable={true}
                          placement={'auto'}
                        />
                      </InputGroup>


                      <FormGroup >
                        <InputGroup style={styles}>
                          <InputGroup.Addon>Container:</InputGroup.Addon>
                          <Input value={legItem['Container']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                        </InputGroup>
                      </FormGroup>
                    </FormGroup>

                    <Row>
                      <Col md={12} sm={24}>


                        <FormGroup >
                          <InputGroup style={styles}>
                            <InputGroup.Addon>MBOL:</InputGroup.Addon>
                            <Input value={legItem['MBOL']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'MBOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup >
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Seal:</InputGroup.Addon>
                            <Input value={legItem['Seal']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                          </InputGroup>
                        </FormGroup>


                      </Col>
                      <Col md={12} sm={24}>


                        <FormGroup >
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Carrier:</InputGroup.Addon>
                            <Input value={legItem['Carrier']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup >
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Vessel:</InputGroup.Addon>
                            <Input value={legItem['Vessel']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Vessel') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} sm={24}>
                        <FormGroup>
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Departure:</InputGroup.Addon>
                            <DatePicker
                              container={() => mountRef.current}
                              placeholder="Sailing Date"
                              onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PlannedDepartureDate') }}
                              value={legItem["PlannedDepartureDate"]}
                              format="YYYY-MM-DD"
                              showMeridian
                              size="sm"
                              ranges={[
                                {
                                  label: 'Now',
                                  value: new Date()
                                }
                              ]}
                              style={styles}
                              oneTap
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12} sm={24}>
                        <FormGroup>
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Arrival:</InputGroup.Addon>
                            <DatePicker
                              container={() => mountRef.current}
                              placeholder="Estimated Arrival Date"
                              onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                              value={legItem["EstimatedDeliveryDate"]}
                              format="YYYY-MM-DD"
                              showMeridian
                              size="sm"
                              ranges={[
                                {
                                  label: 'Now',
                                  value: new Date()
                                }
                              ]}
                              style={styles}
                              oneTap
                              placement={'leftStart'}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} sm={24}>
                        <FormGroup style={{ marginTop: '5px' }}>
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Origin:</InputGroup.Addon>
                            <InputPicker preventOverflow
                              container={() => mountRef.current}
                              placeholder={'Origin Port...'}
                              value={legItem['SeaportOriginID']}
                              onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'SeaportOriginID') }}
                              data={this.props.CtrlTwrSeaportData}
                              block
                              cleanable={true}
                              style={inputStyle}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col md={12} sm={24}>
                        <FormGroup style={{ marginTop: '5px' }}>
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Destination:</InputGroup.Addon>
                            <InputPicker preventOverflow
                              container={() => mountRef.current}
                              placeholder={'Destination Port...'}
                              value={legItem['SeaportDestID']}
                              onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'SeaportDestID') }}
                              data={this.props.CtrlTwrSeaportData}
                              block
                              cleanable={true}
                              style={inputStyle}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>


                    <Row style={{ marginTop: 10 }}>
                      <Col md={12} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                          <FormGroup>
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin City:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin State:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                            </InputGroup>

                          </FormGroup>
                        </Panel>
                      </Col>
                      <Col md={12} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                          <FormGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination City:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination State:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                            </InputGroup>

                          </FormGroup>
                        </Panel>
                      </Col>
                    </Row>

                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                    <Panel
                      bordered
                      style={{ marginBottom: '10px' }}
                    >
                      <Row >
                        <Col md={8} sm={24}>
                          <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'HBOL', null) }} appearance="default">Add HBOL</Button>
                        </Col>
                        <Col md={8} sm={24}>
                          <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                        </Col>
                        <Col md={8} sm={24}>
                          <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                        </Col>
                      </Row>
                      {
                        this.state.ShipManagementCustRefData.map(freeCustItem => (
                          freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                              <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                              <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                  <CloseIcon />
                                </InputGroup.Button>
                              </Whisper>

                            </InputGroup>
                            :
                            ''
                        ))
                      }
                      {
                        this.state.ShipManagementPoData.map(freePotem => (
                          freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                            <InputGroup style={styles}>
                              <InputGroup.Addon>PO</InputGroup.Addon>
                              <Input readOnly style={styles} value={freePotem["PO"]} />

                              <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                  <CloseIcon />
                                </InputGroup.Button>
                              </Whisper>

                            </InputGroup>
                            :
                            ''
                        ))
                      }
                      {
                        this.state.ShipManagementHbolData.map(hbolItem => (
                          hbolItem["LegID"] === legItem["LegID"] ?
                            <FormGroup style={{ marginTop: '5px' }}>
                              <InputGroup style={styles}>
                                <InputGroup.Addon>HBOL</InputGroup.Addon>
                                <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                <InputGroup.Button onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add PO</InputGroup.Button>
                                <InputGroup.Button onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add Customer Ref</InputGroup.Button>

                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                  <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'HBOL', hbolItem["HbolID"], null, null) }}  >
                                    <CloseIcon />
                                  </InputGroup.Button>
                                </Whisper>

                              </InputGroup>
                              {
                                this.state.ShipManagementCustRefData.map(custItem => (
                                  custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>
                                        <MinusIcon />
                                      </InputGroup.Addon>
                                      <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                      <Input readOnly style={styles} value={custItem["CustomerRef"]} />

                                      <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                        <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, custItem["CustomerRefID"]) }}  >
                                          <CloseIcon />
                                        </InputGroup.Button>
                                      </Whisper>


                                    </InputGroup>
                                    :
                                    ''
                                ))
                              }
                              {
                                this.state.ShipManagementPoData.map(poItem => (
                                  poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>
                                        <MinusIcon />
                                      </InputGroup.Addon>
                                      <InputGroup.Addon>PO</InputGroup.Addon>
                                      <Input readOnly style={styles} value={poItem["PO"]} />

                                      <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                        <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, poItem["PoID"], null) }}  >
                                          <CloseIcon />
                                        </InputGroup.Button>
                                      </Whisper>


                                    </InputGroup>
                                    :
                                    ''
                                ))
                              }
                            </FormGroup>
                            :
                            <div></div>
                        ))
                      }
                    </Panel>




                  </Panel>

                  :

                  legItem.ModeID === 2 ? //Air
                    <Panel
                      bordered
                      style={{ marginBottom: '10px' }}
                      header={
                        <Row>
                          <Col md={16} sm={24}>
                            <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                          </Col>
                          <Col md={8} sm={24}>
                            <ButtonToolbar >
                              <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                            </ButtonToolbar>
                          </Col>
                        </Row>
                      }
                    >
                      <FormGroup >
                        <InputGroup style={styles}>
                          <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                          <InputPicker preventOverflow
                            container={() => mountRef.current}
                            placeholder={'Managing Warehouse...'}
                            value={legItem['WarehouseID']}
                            onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'WarehouseID') }}
                            data={this.state.warehouseArray}
                            style={inputStyle}
                            cleanable={true}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup >
                        <InputGroup style={styles}>
                          <InputGroup.Addon>MBOL:</InputGroup.Addon>
                          <Input value={legItem['MBOL']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'MBOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                        </InputGroup>
                      </FormGroup>

                      <Row>

                        <Col md={12} sm={24}>

                          <FormGroup >
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Carrier:</InputGroup.Addon>
                              <Input value={legItem['Carrier']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                          </FormGroup>



                        </Col>

                        <Col md={12} sm={24}>
                          <FormGroup >
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Flight:</InputGroup.Addon>
                              <Input value={legItem['Flight']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Flight') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                          </FormGroup>

                        </Col>

                      </Row>
                      <Row>
                        <Col md={12} sm={24}>
                          <FormGroup>
                            <InputGroup style={styles}>
                              <InputGroup.Addon>ETD:</InputGroup.Addon>
                              <DatePicker
                                container={() => mountRef.current}
                                placeholder="ETD"
                                onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                value={legItem['PlannedDepartureDate']}
                                format="YYYY-MM-DD hh:mm aa"
                                defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                showMeridian
                                size="sm"
                                ranges={[
                                  {
                                    label: 'Now',
                                    value: new Date()
                                  }
                                ]}
                                style={styles}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Col md={12} sm={24}>

                          <FormGroup>
                            <InputGroup style={styles}>
                              <InputGroup.Addon>ETA:</InputGroup.Addon>
                              <DatePicker
                                container={() => mountRef.current}
                                placeholder="ETA"
                                onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                value={legItem['EstimatedDeliveryDate']}
                                format="YYYY-MM-DD hh:mm aa"
                                defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                showMeridian
                                size="sm"
                                ranges={[
                                  {
                                    label: 'Now',
                                    value: new Date()
                                  }
                                ]}
                                style={styles}
                                placement={'leftStart'}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12} sm={24}>
                          <FormGroup style={{ marginTop: '5px' }}>
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin:</InputGroup.Addon>
                              <InputPicker preventOverflow
                                container={() => mountRef.current}
                                placeholder={'Origin Port...'}
                                value={legItem['AirportOriginID']}
                                onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'AirportOriginID') }}
                                data={this.props.CtrlTwrAirportData}
                                block
                                cleanable={true}
                                style={inputStyle}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Col md={12} sm={24}>
                          <FormGroup style={{ marginTop: '5px' }}>
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination:</InputGroup.Addon>
                              <InputPicker preventOverflow
                                container={() => mountRef.current}
                                placeholder={'Destination Port...'}
                                value={legItem['AirportDestID']}
                                onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'AirportDestID') }}
                                data={this.props.CtrlTwrAirportData}
                                block
                                cleanable={true}
                                style={inputStyle}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>


                      <Row style={{ marginTop: 10 }}>
                        <Col md={12} sm={24}>
                          <Panel bordered style={{ marginBottom: 10 }} >
                            <FormGroup>
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                              </InputGroup>

                            </FormGroup>
                          </Panel>
                        </Col>
                        <Col md={12} sm={24}>
                          <Panel bordered style={{ marginBottom: 10 }} >
                            <FormGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                              </InputGroup>

                              <InputGroup style={styles}>
                                <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                              </InputGroup>

                            </FormGroup>
                          </Panel>
                        </Col>
                      </Row>


                      <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                      <Panel
                        bordered
                        style={{ marginBottom: '10px' }}
                      >
                        <Row >
                          <Col md={8} sm={24}>
                            <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'HBOL', null) }} appearance="default">Add HBOL</Button>
                          </Col>
                          <Col md={8} sm={24}>
                            <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                          </Col>
                          <Col md={8} sm={24}>
                            <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                          </Col>
                        </Row>
                        {
                          this.state.ShipManagementCustRefData.map(freeCustItem => (
                            freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                  <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                    <CloseIcon />
                                  </InputGroup.Button>
                                </Whisper>

                              </InputGroup>
                              :
                              ''
                          ))
                        }
                        {
                          this.state.ShipManagementPoData.map(freePotem => (
                            freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                              <InputGroup style={styles}>
                                <InputGroup.Addon>PO</InputGroup.Addon>
                                <Input readOnly style={styles} value={freePotem["PO"]} />

                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                  <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                    <CloseIcon />
                                  </InputGroup.Button>
                                </Whisper>

                              </InputGroup>
                              :
                              ''
                          ))
                        }
                        {
                          this.state.ShipManagementHbolData.map(hbolItem => (
                            hbolItem["LegID"] === legItem["LegID"] ?
                              <FormGroup style={{ marginTop: '5px' }}>
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>HBOL</InputGroup.Addon>
                                  <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                  <InputGroup.Button onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add PO</InputGroup.Button>
                                  <InputGroup.Button onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add Customer Ref</InputGroup.Button>

                                  <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                    <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'HBOL', hbolItem["HbolID"], null, null) }}  >
                                      <CloseIcon />
                                    </InputGroup.Button>
                                  </Whisper>

                                </InputGroup>
                                {
                                  this.state.ShipManagementCustRefData.map(custItem => (
                                    custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>
                                          <MinusIcon />
                                        </InputGroup.Addon>
                                        <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                        <Input readOnly style={styles} value={custItem["CustomerRef"]} />

                                        <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                          <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, custItem["CustomerRefID"]) }}  >
                                            <CloseIcon />
                                          </InputGroup.Button>
                                        </Whisper>


                                      </InputGroup>
                                      :
                                      ''
                                  ))
                                }
                                {
                                  this.state.ShipManagementPoData.map(poItem => (
                                    poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>
                                          <MinusIcon />
                                        </InputGroup.Addon>
                                        <InputGroup.Addon>PO</InputGroup.Addon>
                                        <Input readOnly style={styles} value={poItem["PO"]} />

                                        <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                          <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, poItem["PoID"], null) }}  >
                                            <CloseIcon />
                                          </InputGroup.Button>
                                        </Whisper>


                                      </InputGroup>
                                      :
                                      ''
                                  ))
                                }
                              </FormGroup>
                              :
                              <div></div>
                          ))
                        }
                      </Panel>




                    </Panel>

                    :

                    legItem.ModeID === 3 ? //Truck Load
                      <Panel
                        bordered
                        style={{ marginBottom: '10px' }}
                        header={
                          <Row>
                            <Col md={16} sm={24}>
                              <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                            </Col>
                            <Col md={8} sm={24}>
                              <ButtonToolbar >
                                <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                              </ButtonToolbar>
                            </Col>
                          </Row>
                        }
                      >
                        <FormGroup >
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                            <InputPicker preventOverflow
                              container={() => mountRef.current}
                              placeholder={'Managing Warehouse...'}
                              value={legItem['WarehouseID']}
                              onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'WarehouseID') }}
                              data={this.state.warehouseArray}
                              style={inputStyle}
                              cleanable={true}
                            />
                          </InputGroup>
                        </FormGroup>

                        <Row>
                          <Col md={12} sm={24}>
                            <FormGroup >
                              <InputGroup style={styles}>
                                <InputGroup.Addon>BOL:</InputGroup.Addon>
                                <Input value={legItem['BOL']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                              </InputGroup>
                            </FormGroup>


                            <FormGroup >
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Seal:</InputGroup.Addon>
                                <Input value={legItem['Seal']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                              </InputGroup>
                            </FormGroup>

                          </Col>

                          <Col md={12} sm={24}>
                            <FormGroup >
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                <Input value={legItem['Carrier']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                              </InputGroup>
                            </FormGroup>

                            <FormGroup >
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Trailer:</InputGroup.Addon>
                                <Input value={legItem['Trailer']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Trailer') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                              </InputGroup>
                            </FormGroup>

                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} sm={24}>
                            <FormGroup>
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Pickup:</InputGroup.Addon>
                                <DatePicker
                                  container={() => mountRef.current}
                                  placeholder="Estimated Pickup Date"
                                  onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                  value={legItem['PlannedDepartureDate']}
                                  format="YYYY-MM-DD"
                                  // format="YYYY-MM-DD hh:mm aa"
                                  // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                  showMeridian
                                  size="sm"
                                  ranges={[
                                    {
                                      label: 'Now',
                                      value: new Date()
                                    }
                                  ]}
                                  oneTap
                                  style={styles}
                                />
                              </InputGroup>
                            </FormGroup>

                            <FormGroup>
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Appointment Time:</InputGroup.Addon>
                                <DatePicker
                                  container={() => mountRef.current}
                                  // placeholder="Pickup Appointment Time"
                                  onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PickupAppointmentTime') }}
                                  value={legItem['PickupAppointmentTime']}
                                  // format="YYYY-MM-DD hh:mm aa"
                                  // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                  format="HH:mm aa"
                                  showMeridian
                                  size="sm"
                                  // ranges={[
                                  //   {
                                  //     label: 'Now',
                                  //     value: new Date()
                                  //   }
                                  // ]}
                                  style={styles}
                                // oneTap
                                />
                              </InputGroup>
                            </FormGroup>


                          </Col>
                          <Col md={12} sm={24}>
                            <FormGroup>
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Delivery:</InputGroup.Addon>
                                <DatePicker
                                  container={() => mountRef.current}
                                  placeholder="Estimated Delivery Date"
                                  onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                  value={legItem["EstimatedDeliveryDate"]}
                                  format="YYYY-MM-DD"
                                  // format="YYYY-MM-DD hh:mm aa"
                                  // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                  showMeridian
                                  size="sm"
                                  ranges={[
                                    {
                                      label: 'Now',
                                      value: new Date()
                                    }
                                  ]}
                                  style={styles}
                                  oneTap
                                  placement={'leftStart'}
                                />
                              </InputGroup>
                            </FormGroup>


                            <FormGroup>
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Appointment Time:</InputGroup.Addon>
                                <DatePicker
                                  container={() => mountRef.current}
                                  // placeholder="Delivery Appointment Time"
                                  onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'DeliveryAppointmentTime') }}
                                  value={legItem["DeliveryAppointmentTime"]}
                                  format="hh:mm aa"
                                  // format="YYYY-MM-DD hh:mm aa"
                                  // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                  showMeridian
                                  size="sm"
                                  ranges={[
                                    {
                                      label: 'Now',
                                      value: new Date()
                                    }
                                  ]}
                                  style={styles}
                                  // oneTap
                                  placement={'leftStart'}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* <FormGroup >
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Pickup Address:</InputGroup.Addon>
                            <Input value={legItem['PickupAddress']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PickupAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup >
                          <InputGroup style={styles}>
                            <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                            <Input value={legItem['DeliveryAddress']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                          </InputGroup>
                        </FormGroup> */}



                        <Row style={{ marginTop: 10 }}>
                          <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} >
                              <FormGroup>
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                </InputGroup>

                              </FormGroup>
                            </Panel>
                          </Col>
                          <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} >
                              <FormGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                </InputGroup>

                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                  <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                </InputGroup>

                              </FormGroup>
                            </Panel>
                          </Col>
                        </Row>


                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                        <Panel
                          bordered
                          style={{ marginBottom: '10px' }}
                        >
                          <Row >
                            <Col md={12} sm={24}>
                              <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                            </Col>
                            <Col md={12} sm={24}>
                              <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                            </Col>
                          </Row>
                          {
                            this.state.ShipManagementCustRefData.map(freeCustItem => (
                              freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                  <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                  <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                    <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                      <CloseIcon />
                                    </InputGroup.Button>
                                  </Whisper>

                                </InputGroup>
                                :
                                ''
                            ))
                          }
                          {
                            this.state.ShipManagementPoData.map(freePotem => (
                              freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>PO</InputGroup.Addon>
                                  <Input readOnly style={styles} value={freePotem["PO"]} />

                                  <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                    <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                      <CloseIcon />
                                    </InputGroup.Button>
                                  </Whisper>

                                </InputGroup>
                                :
                                ''
                            ))
                          }

                        </Panel>





                      </Panel>

                      :

                      legItem.ModeID === 4 ? //Less Than Truck Load

                        <Panel
                          bordered
                          style={{ marginBottom: '10px' }}
                          header={
                            <Row>
                              <Col md={16} sm={24}>
                                <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                              </Col>
                              <Col md={8} sm={24}>
                                <ButtonToolbar >
                                  <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                </ButtonToolbar>
                              </Col>
                            </Row>
                          }
                        >
                          <FormGroup >
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                              <InputPicker preventOverflow
                                container={() => mountRef.current}
                                placeholder={'Managing Warehouse...'}
                                value={legItem['WarehouseID']}
                                onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'WarehouseID') }}
                                data={this.state.warehouseArray}
                                style={inputStyle}
                                cleanable={true}
                              />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup >
                            <InputGroup style={styles}>
                              <InputGroup.Addon>BOL:</InputGroup.Addon>
                              <Input value={legItem['BOL']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                            </InputGroup>
                          </FormGroup>

                          <Row>
                            <Col md={12} sm={24}>
                              <FormGroup >
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Seal:</InputGroup.Addon>
                                  <Input value={legItem['Seal']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col md={12} sm={24}>
                              <FormGroup >
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                  <Input value={legItem['Carrier']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12} sm={24}>
                              <FormGroup>
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Pickup:</InputGroup.Addon>
                                  <DatePicker
                                    container={() => mountRef.current}
                                    placeholder="Estimated Pickup Date"
                                    onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                    value={legItem['PlannedDepartureDate']}
                                    format="YYYY-MM-DD"
                                    // format="YYYY-MM-DD hh:mm aa"
                                    // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                    showMeridian
                                    size="sm"
                                    ranges={[
                                      {
                                        label: 'Now',
                                        value: new Date()
                                      }
                                    ]}
                                    style={styles}
                                    oneTap
                                  // placement={'leftStart'}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            <Col md={12} sm={24}>
                              <FormGroup>
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Delivery:</InputGroup.Addon>
                                  <DatePicker
                                    container={() => mountRef.current}
                                    placeholder="Estimated Delivery Date"
                                    onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                    value={legItem["EstimatedDeliveryDate"]}
                                    format="YYYY-MM-DD"
                                    // format="YYYY-MM-DD hh:mm aa"
                                    // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                    showMeridian
                                    size="sm"
                                    ranges={[
                                      {
                                        label: 'Now',
                                        value: new Date()
                                      }
                                    ]}
                                    style={styles}
                                    oneTap
                                    placement={'leftStart'}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* <FormGroup >
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Pickup Address:</InputGroup.Addon>
                              <Input value={legItem['PickupAddress']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PickupAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup >
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                              <Input value={legItem['DeliveryAddress']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                            </InputGroup>
                          </FormGroup> */}


                          <Row style={{ marginTop: 10 }}>
                            <Col md={12} sm={24}>
                              <Panel bordered style={{ marginBottom: 10 }} >
                                <FormGroup>
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                  </InputGroup>

                                </FormGroup>
                              </Panel>
                            </Col>
                            <Col md={12} sm={24}>
                              <Panel bordered style={{ marginBottom: 10 }} >
                                <FormGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                  </InputGroup>

                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                    <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                  </InputGroup>

                                </FormGroup>
                              </Panel>
                            </Col>
                          </Row>

                          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                          <Panel
                            bordered
                            style={{ marginBottom: '10px' }}
                          >
                            <Row >
                              <Col md={12} sm={24}>
                                <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                              </Col>
                              <Col md={12} sm={24}>
                                <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                              </Col>
                            </Row>
                            {
                              this.state.ShipManagementCustRefData.map(freeCustItem => (
                                freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                    <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                    <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                      <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                        <CloseIcon />
                                      </InputGroup.Button>
                                    </Whisper>

                                  </InputGroup>
                                  :
                                  ''
                              ))
                            }
                            {
                              this.state.ShipManagementPoData.map(freePotem => (
                                freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>PO</InputGroup.Addon>
                                    <Input readOnly style={styles} value={freePotem["PO"]} />

                                    <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                      <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                        <CloseIcon />
                                      </InputGroup.Button>
                                    </Whisper>

                                  </InputGroup>
                                  :
                                  ''
                              ))
                            }

                          </Panel>



                        </Panel>

                        :

                        legItem.ModeID === 5 ? //Rail

                          <Panel
                            bordered
                            style={{ marginBottom: '10px' }}
                            header={
                              <Row>
                                <Col md={16} sm={24}>
                                  <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                </Col>
                                <Col md={8} sm={24}>
                                  <ButtonToolbar >
                                    <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                  </ButtonToolbar>
                                </Col>
                              </Row>
                            }
                          >
                            <FormGroup >
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                <InputPicker preventOverflow
                                  container={() => mountRef.current}
                                  placeholder={'Managing Warehouse...'}
                                  value={legItem['WarehouseID']}
                                  onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'WarehouseID') }}
                                  data={this.state.warehouseArray}
                                  style={inputStyle}
                                  cleanable={true}
                                />
                              </InputGroup>
                            </FormGroup>

                            <Row>
                              <Col md={12} sm={24}>
                                <FormGroup >
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>BOL:</InputGroup.Addon>
                                    <Input value={legItem['BOL']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                  </InputGroup>
                                </FormGroup>

                                <FormGroup >
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Container:</InputGroup.Addon>
                                    <Input value={legItem['Container']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col md={12} sm={24}>
                                <FormGroup >
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Seal:</InputGroup.Addon>
                                    <Input value={legItem['Seal']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                  </InputGroup>
                                </FormGroup>

                                <FormGroup >
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                    <Input value={legItem['Carrier']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12} sm={24}>
                                <FormGroup>
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Pickup:</InputGroup.Addon>
                                    <DatePicker
                                      container={() => mountRef.current}
                                      placeholder="Estimated Pickup Date"
                                      onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                      value={legItem['PlannedDepartureDate']}
                                      format="YYYY-MM-DD"
                                      // format="YYYY-MM-DD hh:mm aa"
                                      // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                      showMeridian
                                      size="sm"
                                      ranges={[
                                        {
                                          label: 'Now',
                                          value: new Date()
                                        }
                                      ]}
                                      style={styles}
                                      oneTap
                                    // placement={'leftStart'}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col md={12} sm={24}>
                                <FormGroup>
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Delivery:</InputGroup.Addon>
                                    <DatePicker
                                      container={() => mountRef.current}
                                      placeholder="Estimated Delivery Date"
                                      onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                      value={legItem["EstimatedDeliveryDate"]}
                                      format="YYYY-MM-DD"
                                      // format="YYYY-MM-DD hh:mm aa"
                                      // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                      showMeridian
                                      size="sm"
                                      ranges={[
                                        {
                                          label: 'Now',
                                          value: new Date()
                                        }
                                      ]}
                                      style={styles}
                                      oneTap
                                      placement={'leftStart'}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                            {/* <FormGroup >
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Pickup Address:</InputGroup.Addon>
                                <Input value={legItem['PickupAddress']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PickupAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                              </InputGroup>
                            </FormGroup>

                            <FormGroup >
                              <InputGroup style={styles}>
                                <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                                <Input value={legItem['DeliveryAddress']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                              </InputGroup>
                            </FormGroup> */}


                            <Row style={{ marginTop: 10 }}>
                              <Col md={12} sm={24}>
                                <Panel bordered style={{ marginBottom: 10 }} >
                                  <FormGroup>
                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                    </InputGroup>

                                  </FormGroup>
                                </Panel>
                              </Col>
                              <Col md={12} sm={24}>
                                <Panel bordered style={{ marginBottom: 10 }} >
                                  <FormGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                      <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                    </InputGroup>

                                  </FormGroup>
                                </Panel>
                              </Col>
                            </Row>

                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                            <Panel
                              bordered
                              style={{ marginBottom: '10px' }}
                            >
                              <Row >
                                <Col md={24} sm={24}>
                                  <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                </Col>
                              </Row>
                              {
                                this.state.ShipManagementPoData.map(freePotem => (
                                  freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                    <InputGroup style={styles}>
                                      <InputGroup.Addon>PO</InputGroup.Addon>
                                      <Input readOnly style={styles} value={freePotem["PO"]} />

                                      <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                        <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                          <CloseIcon />
                                        </InputGroup.Button>
                                      </Whisper>

                                    </InputGroup>
                                    :
                                    ''
                                ))
                              }

                            </Panel>





                          </Panel>

                          :

                          legItem.ModeID === 6 ? //Brokerage
                            <Panel
                              bordered
                              style={{ marginBottom: '10px' }}
                              header={
                                <Row>
                                  <Col md={16} sm={24}>
                                    <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                  </Col>
                                  <Col md={8} sm={24}>
                                    <ButtonToolbar >
                                      <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                    </ButtonToolbar>
                                  </Col>
                                </Row>
                              }
                            >
                              <FormGroup >
                                <InputGroup style={styles}>
                                  <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                  <InputPicker preventOverflow
                                    container={() => mountRef.current}
                                    placeholder={'Managing Warehouse...'}
                                    value={legItem['WarehouseID']}
                                    onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'WarehouseID') }}
                                    data={this.state.warehouseArray}
                                    style={inputStyle}
                                    cleanable={true}
                                  />
                                </InputGroup>
                              </FormGroup>

                            </Panel>

                            :

                            legItem.ModeID === 7 ? //Drayage
                              <Panel
                                bordered
                                style={{ marginBottom: '10px' }}
                                header={
                                  <Row>
                                    <Col md={16} sm={24}>
                                      <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                    </Col>
                                    <Col md={8} sm={24}>
                                      <ButtonToolbar >
                                        <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                      </ButtonToolbar>
                                    </Col>
                                  </Row>
                                }
                              >
                                <FormGroup >
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                    <InputPicker preventOverflow
                                      container={() => mountRef.current}
                                      placeholder={'Managing Warehouse...'}
                                      value={legItem['WarehouseID']}
                                      onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'WarehouseID') }}
                                      data={this.state.warehouseArray}
                                      style={inputStyle}
                                      cleanable={true}
                                      placement={'auto'}
                                    />
                                  </InputGroup>
                                </FormGroup>

                                <FormGroup >
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Container:</InputGroup.Addon>
                                    <Input value={legItem['Container']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                  </InputGroup>
                                </FormGroup>

                                <Row>
                                  <Col md={12} sm={24}>


                                    <FormGroup >
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>MBOL:</InputGroup.Addon>
                                        <Input value={legItem['MBOL']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'MBOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                      </InputGroup>
                                    </FormGroup>

                                    <FormGroup >
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>Seal:</InputGroup.Addon>
                                        <Input value={legItem['Seal']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                      </InputGroup>
                                    </FormGroup>

                                  </Col>
                                  <Col md={12} sm={24}>




                                    <FormGroup >
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                        <Input value={legItem['Carrier']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                      </InputGroup>
                                    </FormGroup>

                                    <FormGroup >
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>Vessel:</InputGroup.Addon>
                                        <Input value={legItem['Vessel']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Vessel') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12} sm={24}>
                                    <FormGroup>
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>Departure:</InputGroup.Addon>
                                        <DatePicker
                                          container={() => mountRef.current}
                                          placeholder="Estimated Departure Date"
                                          onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                          value={legItem["PlannedDepartureDate"]}
                                          format="YYYY-MM-DD"
                                          // format="YYYY-MM-DD hh:mm aa"
                                          // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                          showMeridian
                                          size="sm"
                                          ranges={[
                                            {
                                              label: 'Now',
                                              value: new Date()
                                            }
                                          ]}
                                          style={styles}
                                          oneTap
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>

                                  <Col md={12} sm={24}>
                                    <FormGroup>
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>Arrival:</InputGroup.Addon>
                                        <DatePicker
                                          container={() => mountRef.current}
                                          placeholder="Estimated Arrival Date"
                                          onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                          value={legItem["EstimatedDeliveryDate"]}
                                          format="YYYY-MM-DD"
                                          // format="YYYY-MM-DD hh:mm aa"
                                          // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                          showMeridian
                                          size="sm"
                                          ranges={[
                                            {
                                              label: 'Now',
                                              value: new Date()
                                            }
                                          ]}
                                          style={styles}
                                          oneTap
                                          placement={'leftStart'}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12} sm={24}>
                                    <FormGroup style={{ marginTop: '5px' }}>
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>Origin:</InputGroup.Addon>
                                        <InputPicker preventOverflow
                                          container={() => mountRef.current}
                                          placeholder={'Origin Port...'}
                                          value={legItem['SeaportOriginID']}
                                          onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'SeaportOriginID') }}
                                          data={this.props.CtrlTwrSeaportData}
                                          block
                                          cleanable={true}
                                          style={inputStyle}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>

                                  <Col md={12} sm={24}>
                                    <FormGroup style={{ marginTop: '5px' }}>
                                      <InputGroup style={styles}>
                                        <InputGroup.Addon>Destination:</InputGroup.Addon>
                                        <InputPicker preventOverflow
                                          container={() => mountRef.current}
                                          placeholder={'Destination Port...'}
                                          value={legItem['SeaportDestID']}
                                          onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'SeaportDestID') }}
                                          data={this.props.CtrlTwrSeaportData}
                                          block
                                          cleanable={true}
                                          style={inputStyle}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                {/* <FormGroup >
                                  <InputGroup style={styles}>
                                    <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                                    <Input value={legItem['DeliveryAddress']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                  </InputGroup>
                                </FormGroup> */}



                                <Row style={{ marginTop: 10 }}>
                                  <Col md={12} sm={24}>
                                    <Panel bordered style={{ marginBottom: 10 }} >
                                      <FormGroup>
                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                        </InputGroup>

                                      </FormGroup>
                                    </Panel>
                                  </Col>
                                  <Col md={12} sm={24}>
                                    <Panel bordered style={{ marginBottom: 10 }} >
                                      <FormGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                        </InputGroup>

                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                          <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                        </InputGroup>

                                      </FormGroup>
                                    </Panel>
                                  </Col>
                                </Row>


                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                <Panel
                                  bordered
                                  style={{ marginBottom: '10px' }}
                                >
                                  <Row >
                                    <Col md={8} sm={24}>
                                      <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'HBOL', null) }} appearance="default">Add HBOL</Button>
                                    </Col>
                                    <Col md={8} sm={24}>
                                      <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                    </Col>
                                    <Col md={8} sm={24}>
                                      <Button block onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                                    </Col>
                                  </Row>
                                  {
                                    this.state.ShipManagementCustRefData.map(freeCustItem => (
                                      freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                          <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                          <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                            <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                              <CloseIcon />
                                            </InputGroup.Button>
                                          </Whisper>

                                        </InputGroup>
                                        :
                                        ''
                                    ))
                                  }
                                  {
                                    this.state.ShipManagementPoData.map(freePotem => (
                                      freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                        <InputGroup style={styles}>
                                          <InputGroup.Addon>PO</InputGroup.Addon>
                                          <Input readOnly style={styles} value={freePotem["PO"]} />

                                          <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                            <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                              <CloseIcon />
                                            </InputGroup.Button>
                                          </Whisper>

                                        </InputGroup>
                                        :
                                        ''
                                    ))
                                  }
                                  {
                                    this.state.ShipManagementHbolData.map(hbolItem => (
                                      hbolItem["LegID"] === legItem["LegID"] ?
                                        <FormGroup style={{ marginTop: '5px' }}>
                                          <InputGroup style={styles}>
                                            <InputGroup.Addon>HBOL</InputGroup.Addon>
                                            <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                            <InputGroup.Button onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'PO', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add PO</InputGroup.Button>
                                            <InputGroup.Button onClick={(e) => { this.onClickMulti(e, legItem["LegID"], 'CustomerRef', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add Customer Ref</InputGroup.Button>

                                            <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                              <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'HBOL', hbolItem["HbolID"], null, null) }}  >
                                                <CloseIcon />
                                              </InputGroup.Button>
                                            </Whisper>

                                          </InputGroup>
                                          {
                                            this.state.ShipManagementCustRefData.map(custItem => (
                                              custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                                <InputGroup style={styles}>
                                                  <InputGroup.Addon>
                                                    <MinusIcon />
                                                  </InputGroup.Addon>
                                                  <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                  <Input readOnly style={styles} value={custItem["CustomerRef"]} />

                                                  <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                    <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'CustomerRef', null, null, custItem["CustomerRefID"]) }}  >
                                                      <CloseIcon />
                                                    </InputGroup.Button>
                                                  </Whisper>


                                                </InputGroup>
                                                :
                                                ''
                                            ))
                                          }
                                          {
                                            this.state.ShipManagementPoData.map(poItem => (
                                              poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                                <InputGroup style={styles}>
                                                  <InputGroup.Addon>
                                                    <MinusIcon />
                                                  </InputGroup.Addon>
                                                  <InputGroup.Addon>PO</InputGroup.Addon>
                                                  <Input readOnly style={styles} value={poItem["PO"]} />

                                                  <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                    <InputGroup.Button onClick={(e) => { this.onClickDeleteMulti(e, 'PO', null, poItem["PoID"], null) }}  >
                                                      <CloseIcon />
                                                    </InputGroup.Button>
                                                  </Whisper>


                                                </InputGroup>
                                                :
                                                ''
                                            ))
                                          }
                                        </FormGroup>
                                        :
                                        <div></div>
                                    ))
                                  }
                                </Panel>


                              </Panel>

                              :

                              <Panel
                                bordered
                                style={{ marginBottom: '10px' }}
                                header={
                                  <Row>
                                    <Col md={16} sm={24}>
                                      <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                    </Col>
                                    <Col md={8} sm={24}>
                                      <ButtonToolbar >
                                        <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                      </ButtonToolbar>
                                    </Col>
                                  </Row>
                                }
                              >
                              </Panel>



              ))
            }


            <FormGroup >
              <ButtonToolbar>
                {
                  this.state.unsavedShipManagmentChanges ?
                    <Button onClick={this.onClickShipmentManagementDrawerSave} appearance="primary">Save Changes</Button>
                    :
                    <div></div>
                }
                <Button style={{ float: 'right', marginBottom: '10px' }} onClick={(e) => { this.onClickShowAddLeg(e) }} >Add New Leg</Button>
              </ButtonToolbar>
            </FormGroup>




          </Panel>
        </div>
    }
    else {
      legsDataDiv = <div></div>;
    }











    let downloadMilestoneDiv;
    let milestoneTable;
    if (this.props.isCtrlTwrShipmentLegsMilestonesLoading) {

      downloadMilestoneDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentLegsMilestonesData) !== 'undefined' && this.props.CtrlTwrShipmentLegsMilestonesData != null) {
      var milestoneTableComponent = RTableGeneral(
        tableIdCol,     //   keyColumn,
        this.props.CtrlTwrShipmentLegsMilestonesData,     //   tableData,
        CtrlTwrShipmentLegsMilestonesDataElements,       //   DataElements,
        this.props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        false,      //   onClickEditMilestone,
        null,       //   onClickSelect,
        null,       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        true,       //   link1bool,
        'Edit',     //   link1text,
        this.onClickEditMilestone,     //   link1onClick,

        true,       //   link2bool,
        'Delete',       //   link2text,
        this.onClickDeleteMilestone  //   link2onClick
      )

      downloadMilestoneDiv = <div>
        {(this.props.isMobile == true) ? <div></div> :
          <div>
            <Row>
              <ExcelFile element={<Button style={{ marginTop: '10px', float: 'right' }}>Export Excel</Button>} filename={'Milestones_' + currentDateTime}>
                <ExcelSheet data={this.props.CtrlTwrShipmentLegsMilestonesData} name="Milestones">
                  {
                    CtrlTwrShipmentLegsMilestonesDataElements.map(
                      ({ dBKey, displayName }, idx) => {
                        return (
                          <ExcelColumn label={displayName} value={dBKey} />
                        );
                      }
                    )
                  }
                </ExcelSheet>
              </ExcelFile>
            </Row>
          </div>
        }
      </div>


      milestoneTable = <Panel
        bordered style={{ marginBottom: 10 }}
        // header='Milestones'
        header={
          <Row>
            <Col md={16} sm={24}>
              <span>Milestones</span>
            </Col>
            <Col md={8} sm={24}>
              <ButtonToolbar >
                <Button style={{ float: 'right' }} onClick={(e) => { this.onClickOpenShipManagmenetDrawer(e) }} >Add Milestone</Button>
              </ButtonToolbar>
            </Col>
          </Row>
        }
      >
        {milestoneTableComponent}
        {downloadMilestoneDiv}
      </Panel>


    }
    else {
      milestoneTable = <div></div>
      downloadMilestoneDiv = <div></div>
    }





    let shipmentManagementDrawer;
    if (!this.state.showMilestoneEntryDrawer) {
      shipmentManagementDrawer = <div>  </div>
    }
    else if (Object.keys(this.state.ShipMilestonesExistingDict).length > 0 && typeof (this.state.ShipMilestonesExistingDict) !== 'undefined' && this.state.ShipMilestonesExistingDict != null) {
      shipmentManagementDrawer = <MilestoneEventEntryDrawer {...this.props} state={this.state} onChange={this.onChangeMilestoneEventEntry} confirm={this.onClickMilestoneEventEntryDrawerSave} close={this.closeMilestoneEventEntryDrawer} onClickAddMilestone={this.onClickAddMilestone} onClickAddMissingMilestone={this.onClickAddMissingMilestone} onClickShowAddLeg={this.onClickShowAddLeg} onChangeNewLeg={this.onChangeNewLeg} onClickDelete={this.onClickDelete} masterLabel={this.state.ShipManagementProRef} />
    }



    let noteEntry;
    if (this.props.isCtrlTwrShipmentNotesPrivateLoading || this.props.isCtrlTwrShipmentNotesPublicLoading) {
      noteEntry =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else {
      noteEntry = <div>
        <Row >
          <Col md={24} sm={24}>
            <Panel bordered style={{ marginBottom: 10 }} >
              <FormGroup >

                {typeof (this.props.organizationSelection) === 'number' ?

                  <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <InputGroup.Addon>Add New Note:</InputGroup.Addon>
                    <Checkbox checked={this.state.NoteIsPrivate} onChange={(e, value) => this.onChangeCheckBoxOrgNote(e, value)}>{this.state.NoteIsPrivate ? 'Private:  ' : 'Public:  '} </Checkbox>
                    <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                    <Button onClick={(e) => { this.handleSaveAddNewNote(e) }}>Save</Button>
                  </InputGroup>

                  :

                  <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <InputGroup.Addon>Add New Note:</InputGroup.Addon>
                    <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                    <Button onClick={(e) => { this.handleSaveAddNewNote(e) }}>Save</Button>
                  </InputGroup>

                }

              </FormGroup>
            </Panel>
          </Col>
        </Row>
      </div>
    }


    let publicNotes;
    if (this.props.isCtrlTwrShipmentNotesPublicLoading) {
      publicNotes =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentNotesPublicData[0]) !== 'undefined' && this.props.CtrlTwrShipmentNotesPublicData != null) {
      publicNotes =
        <div>
          <Row >
            <Col md={24} sm={24}>
              <Panel bordered header="Public Notes" style={{ marginBottom: 10 }}>
                <Timeline>
                  {this.props.CtrlTwrShipmentNotesPublicData.map(item => (
                    <Timeline.Item>
                      <strong>{item.NoteDT}  &nbsp;
                        {item.EmailAddr} : &nbsp; </strong>
                      <l>{item.PublicNote} &nbsp;
                      </l>

                      <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                        <IconButton icon={<CloseIcon />} onClick={(e) => { this.onClickDeletePublicNote(e, item.ShipmentNoteID) }} size="xs" style={{ float: 'right' }} />
                      </Whisper>

                    </Timeline.Item>
                  ))}
                </Timeline>

              </Panel>
            </Col>
          </Row>

        </div>
    }
    else {
      publicNotes = <div></div>
    }




    let privateNotes;
    if (this.props.isCtrlTwrShipmentNotesPrivateLoading) {
      privateNotes =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrShipmentNotesPrivateData[0]) !== 'undefined' && this.props.CtrlTwrShipmentNotesPrivateData != null) {
      privateNotes =
        <div>
          <Row >
            <Col md={24} sm={24}>
              <Panel bordered header="Private Notes" style={{ marginBottom: 10 }}>
                <Timeline>
                  {this.props.CtrlTwrShipmentNotesPrivateData.map(item => (
                    <Timeline.Item>
                      <strong>{item.NoteDT}  &nbsp;
                        {item.EmailAddr} : &nbsp; </strong>
                      <l>{item.PrivateNote} &nbsp;
                      </l>

                      <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                        <IconButton icon={<CloseIcon />} onClick={(e) => { this.onClickDeletePrivateNote(e, item.ShipmentNoteID) }} size="xs" style={{ float: 'right' }} />
                      </Whisper>

                    </Timeline.Item>
                  ))}
                </Timeline>

              </Panel>
            </Col>
          </Row>

        </div>
    }
    else {
      privateNotes = <div></div>
    }


    return (
      <div>
        <h3>
          <Row>{pageHeader} </Row>
        </h3>

        {iconsDiv}

        <PanelGroup>
          {detailsDiv}
        </PanelGroup>



        {milestoneTable}

        {noteEntry}
        {publicNotes}
        {privateNotes}


        {legsDataDiv}



        <ShipmentManagementAddNewLegModal {...this.props} state={this.state} addNewLegModalShown={this.state.addNewLegModalShown} onChangeAddNewLegModal={this.onChangeAddNewLegModal} handleOpen={this.handleOpenAddNewLegModal} handleClose={this.handleCloseAddNewLegModal} handleSave={this.handleSaveAddNewLegModal} />

        <ShipmentManagementMultiModal {...this.props} state={this.state} multiModalShown={this.state.multiModalShown} onChangeMultiModal={this.onChangeMultiModal} handleClose={this.handleCloseMultiModal} handleSave={this.handleSaveMultiModal} />

        <MilestoneEventModal {...this.props} state={this.state} addNewMilestoneModalShown={this.state.addNewMilestoneModalShown} onChangeAddNewMilestone={this.onChangeAddNewMilestone} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseAddNewModal} handleSave={this.handleSaveAddNewModal} />


        {editDrawer}

        {quotingFileUploadsTable}


        {fileUploadsTable}
        {fileUploadDiv}





        {shipmentManagementDrawer}

      </div>
    );

  }
}

export default withRouter(ShipmentDetailsManagement);



