import React, {
  PureComponent
} from 'react';
import { DatePicker, Input, InputGroup, Icon, ControlLabel, Tooltip, Whisper, FlexboxGrid } from 'rsuite';
import { queryStringParse } from '../../../../resources/logic/functions';


const styles = {
  // width: 300,
  marginBottom: 10,
  // width: 200 
  width: '100%'
};

const divStyle = {
  // marginLeft: 5,
  // marginRight: 5
};

// const tooltipSearch = (
//     <Tooltip>
//       Search
//     </Tooltip>
//   );

// const tooltipClear = (
//     <Tooltip>
//       Clear
//     </Tooltip>
//   );  


// function onClickSearch(e, history, querySearch, searchValue) {  
//   if (searchValue != ''){
//     var param = null
//     param = queryStringParse(searchValue)
//     try{
//         history.push({
//         search: querySearch + '=\'' + param +'\'' 
//         }); 
//     }
//     catch(e){}
//   }
//   else{
//     window.alert('Enter Search Value')
//   }
// }

// function onClickReset(e, history, onChange) {   
//   onChange('')   
//   try{
//       history.push({
//       }); 
//   }
//   catch(e){}
// }

function RDatePicker(
  isMobile,
  label,
  onChange,
  // history,
  searchValue,
  // onClick
  // inputMask
) {

  let rInput;

  try {
    rInput =
      <div style={divStyle}>
        <ControlLabel>{label}</ControlLabel>

        <FlexboxGrid justify="start">
          <FlexboxGrid.Item colspan={24}><DatePicker oneTap style={styles} placeholder={label} value={searchValue} onChange={(e) => { onChange(e) }} /></FlexboxGrid.Item>
        </FlexboxGrid>

      </div>


  }
  catch (e) { }

  return (

    rInput

  )
}


export default RDatePicker;



