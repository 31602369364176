import React, {
    PureComponent
} from 'react';
import { Row, Col, Button, Panel, FormGroup, Toggle, Loader } from 'rsuite';
import FuncLabelGenPrintOptions from './FuncLabelGenPrintOptions';

const styles = {
    marginBottom: '10px',
    width: '100%'
};
const stylesRequiredInput = {
    backgroundColor: '#FFE4E1'
};
const stylesInputPicker = {
    width: '100%'
};
const stylesRequiredAddOn = {
    backgroundColor: "rgb(255,228,225)"
};

const mountRef = React.createRef();

function StepLabel({
    handleChangeFunc,
    handleEnvToggleChange,
    onClickGenerateLabel,
    state, ...props
}) {

    let stepLabelDiv;
    if (props.isLabelGenApiRequestLoading) {
        stepLabelDiv =
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
                <br />
            </div>
    }
    else {
        stepLabelDiv = <Col md={24} sm={24}>

            <Panel bordered style={{ marginBottom: 10 }} >

                <Row style={{ marginBottom: 10 }}>
                    <Col md={12} sm={24} >
                        <FuncLabelGenPrintOptions {...props} state={state} handleChange={handleChangeFunc} />
                    </Col>

                    <Col md={12} sm={24} >
                        <Panel header={'Environment'} bordered  >
                            <FormGroup >
                                <Toggle size="lg"
                                    checkedChildren="Production"
                                    unCheckedChildren="Test"
                                    onChange={(e) => { handleEnvToggleChange(e) }}
                                    checked={state.ProdEnvironment}
                                />
                            </FormGroup>
                            <br />
                        </Panel>
                    </Col>


                    {/* <Col md={12} sm={24} >
                        <Panel header={'Printer Settings'} bordered style={{ height: '140px' }} >
                        <FormGroup >
                            <Col md={12} sm={24}>
                            <InputGroup style={styles}>
                                <InputGroup.Addon>Printer IP:</InputGroup.Addon>
                                <Input value={state.PrinterIP}
                                onChange={(e) => { handlePrinterIpChange(e) }}
                                onInput={(e) => e.target.value = textStringInputMask(e)}
                                />
                            </InputGroup>
                            </Col>

                            <Col md={12} sm={24}>
                            <InputGroup style={styles}>
                                <InputGroup.Addon>Printer Port:</InputGroup.Addon>
                                <Input value={state.PrinterPort}
                                onChange={(e) => { handlePrinterPortChange(e) }}
                                onInput={(e) => e.target.value = textStringInputMask(e)}
                                />
                            </InputGroup>
                            </Col>
                        </FormGroup>
                        </Panel>
                    </Col> */}

                </Row>

                <div>


                    <div style={{ marginTop: '15px' }}>
                        <Button variant="success" size="sm" block onClick={(e) => { onClickGenerateLabel(e) }} appearance="primary">
                            Create {state.envToggleValue} Label
                        </Button>
                    </div>
                </div>


            </Panel>
        </Col>
    }

    return (
        <div ref={mountRef}>
            {stepLabelDiv}
        </div>

    );
}


export default StepLabel;