import React from 'react';
import { withRouter } from 'react-router-dom';
import { Panel, List, InputGroup, Input, Icon, Loader, Row, Col, ButtonToolbar, Button, DatePicker, FormGroup, InputPicker } from 'rsuite';
import { formatStringForAPI } from '../../../../resources/logic/functions';
import { Divider } from 'material-ui';
import _ from 'lodash';
import { lettersNumbersDashesSpacesInputMask, limitCharacterLength, textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import * as moment from 'moment'
import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import InventoryLoadDataElements from '../../../dataComponentsInventory/InventoryLoadDataElements';

const styles = {
    marginBottom: '10px',
    width: '100%'
};

const mountRef = React.createRef();
const paramStatusID = '1,2';

const tableIdCol = "LoadID"


class LoadBuildByScanning extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],


            barcodeInput: '',
            barcodeScannedList: [],
            palletContent: [],
            palletIDList: [],
            caseContent: [],
            caseIDList: [],
            caseSkus: [],
            skuContent: [],

            processedCaseID: [],
            processedCaseSkuIDs: [],


            //Build Load Drawer
            BuildLoadDict: [],
            LocationDict: {},
            PickupDate: null,
            DeliveryDate: null,
            Destination: '',
            Carrier: '',
            Trailer: '',
            BOL: '',
            Seal: '',

            MC: '',
            DOT: '',
            Driver: '',
            Truck: '',
            TruckPlate: '',
            TrailerPlate: '',
            Notes: '',


            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false,
            existingDataChangeLoading: false,

            addToExistingLoadID: null,
            existingLoadData: []
        };

        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Detect barcode scanning
        this.scannedInput = '';

        //Build Load Drawer
        // this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onChangeLoad = this.onChangeLoad.bind(this);
        // this.onChangeLocation = this.onChangeLocation.bind(this);
        // this.onClickBuildLoad = this.onClickBuildLoad.bind(this);
        // this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickBuildLoadDrawerSave = this.onClickBuildLoadDrawerSave.bind(this);
        // this.onClickRemoveFromBuild = this.onClickRemoveFromBuild.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
        this.handleInputSubmit = this.handleInputSubmit.bind(this);

        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);

        this.initialSearchToClearData = this.initialSearchToClearData.bind(this)

        this.onSelectLoad = this.onSelectLoad.bind(this)
        this.onClickDeselectLoad = this.onClickDeselectLoad.bind(this)
    }


    resetState = () => {
        this.initialSearchToClearData()

        this.setState({

            barcodeInput: '',
            barcodeScannedList: [],
            palletContent: [],
            palletIDList: [],
            caseContent: [],
            caseIDList: [],
            caseSkus: [],
            skuContent: [],

            processedCaseID: [],
            processedCaseSkuIDs: [],


            //Build Load Drawer
            BuildLoadDict: [],
            LocationDict: {},
            PickupDate: null,
            DeliveryDate: null,
            Destination: '',
            Carrier: '',
            Trailer: '',
            BOL: '',
            Seal: '',

            MC: '',
            DOT: '',
            Driver: '',
            Truck: '',
            TruckPlate: '',
            TrailerPlate: '',
            Notes: '',

            
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false,
            existingDataChangeLoading: false,
            addToExistingLoadID: null,
            existingLoadData: []
        });
        this.forceUpdate()
    }

    componentDidMount() {
        this.initialSearchToClearData()
        window.addEventListener('keypress', this.handleGlobalKeyPress);
        this.loadSortFromStorage()
    }

    initialSearchToClearData() {
        // Need to clear the data from the previous view and search making sure to return 0 results
        // this.props.inventoryLotBarcodeScanValidateGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, 1)
        // this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        // this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        // this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
    }

    componentWillUnmount() {
        window.removeEventListener('keypress', this.handleGlobalKeyPress);
    }

    //Detect barcode scanning
    handleGlobalKeyPress = (e) => {
        // Ignore paste events and events when the input is focused
        if (e.ctrlKey || e.metaKey || document.activeElement.id === "barcodeinput") {
            return;
        }

        if (e.key === 'Enter') {
            this.setState({ barcodeInput: this.scannedInput }, () => {
                this.handleInputSubmit();
            });
            this.scannedInput = '';
        } else {
            this.scannedInput += e.key;
        }
    };




    componentDidUpdate(prevProps) {

        if (this.props.InventoryLotBarcodeScanValidateData != null && this.props.InventoryLotBarcodeScanValidateData.length > 0 && !_.isEqual(prevProps.InventoryLotBarcodeScanValidateData, this.props.InventoryLotBarcodeScanValidateData)) {
            this.addScannedBarcode();
        }


        // if (this.props.InventoryPalletWarehouseData.length > 0 && !_.isEqual(prevProps.InventoryPalletWarehouseData, this.props.InventoryPalletWarehouseData)) {
        //     this.addPallets();
        // }
        // if (this.props.InventoryCaseWarehouseData.length > 0 && !_.isEqual(prevProps.InventoryCaseWarehouseData, this.props.InventoryCaseWarehouseData)) {
        //     this.addCases();
        // }
        // if (this.props.InventorySkuWarehouseData.length > 0 && !_.isEqual(prevProps.InventorySkuWarehouseData, this.props.InventorySkuWarehouseData)) {
        //     this.addSkus();
        // }


        if (prevProps.InventoryLoadBarcodeScanPostSuccessful !== this.props.InventoryLoadBarcodeScanPostSuccessful && this.props.InventoryLoadBarcodeScanPostSuccessful) {
            this.resetState()
            window.alert('Save successful.')
        }


    }



    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {

            this.handleSortColumn(sortColumn, sortType)
        }
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    addScannedBarcode() {
        const { InventoryLotBarcodeScanValidateData } = this.props;
        const { palletContent, caseContent, palletIDList, caseIDList } = this.state;

        var barcodeType = InventoryLotBarcodeScanValidateData[0].BarcodeType;
        var barcodeStatusID = InventoryLotBarcodeScanValidateData[0].StatusID;
        var palletID = InventoryLotBarcodeScanValidateData[0].PalletID;
        var caseID = InventoryLotBarcodeScanValidateData[0].CaseID;
        var barcode = InventoryLotBarcodeScanValidateData[0].Barcode;
        var valid = InventoryLotBarcodeScanValidateData[0].Valid;

        if (barcodeType === 'P' && valid == true && (barcodeStatusID === 1 || barcodeStatusID === 2)) {
            if (!palletContent.includes(barcode)) {
                this.setState({
                    palletContent: [...palletContent, barcode],
                    palletIDList: [...palletIDList, palletID]
                });
            }
        }
        else if (barcodeType === 'C' && valid == true && (barcodeStatusID === 1 || barcodeStatusID === 2)) {
            if (!caseContent.includes(barcode)) {
                this.setState({
                    caseContent: [...caseContent, barcode],
                    caseIDList: [...caseIDList, caseID]
                });
            }
        }
        else if (valid == false) {
            window.alert('Barcode not recognized.')
        }
        else if (barcodeStatusID === 3) {
            window.alert('Barcode is already picked.')
        }
        else if (barcodeStatusID === 4) {
            window.alert('Barcode is already shipped.')
        }
    }


    addPallets() {

        const { InventoryPalletWarehouseData } = this.props;
        const { palletContent } = this.state;

        const newPalletContent = [...palletContent];



        [InventoryPalletWarehouseData].forEach(data => {
            data.forEach(item => {
                if (!newPalletContent.includes(item.PalletBarcode)) {
                    newPalletContent.push(item.PalletBarcode);
                }
            });
        });
        this.setState({ palletContent: newPalletContent });




        this.setState(prevState => {
            const newElements = this.props.InventoryPalletWarehouseData.filter(
                item => !prevState.BuildLoadDict.some(existingItem => existingItem.PalletID === item.PalletID)
            );

            return { BuildLoadDict: [...prevState.BuildLoadDict, ...newElements] };
        });


    }
    addCases() {
        const { InventoryCaseWarehouseData } = this.props;
        const { caseContent } = this.state;
        const { caseSkus } = this.state;


        const newCaseContent = [...caseContent];

        [InventoryCaseWarehouseData].forEach(data => {
            data.forEach(item => {
                if (!newCaseContent.includes(item.CaseBarcode)) {
                    newCaseContent.push(item.CaseBarcode);
                }
            });
        });


        // const newCaseSkus = InventoryCaseWarehouseData.reduce((acc, item) => {
        //     const existingSku = acc.find(sku => sku.CaseSKU === item.CaseSKU);

        //     if (existingSku) {
        //         existingSku.quantity += 1;
        //     } else {
        //         acc.push({ CaseSKU: item.CaseSKU, quantity: 1 });
        //     }

        //     return acc;
        // }, [...caseSkus]);






        const newCaseSkus = [...caseSkus];

        // const { processedCaseID } = this.state;
        // const newprocessedCaseID = [...processedCaseID];

        const { processedCaseID } = this.state;
        const newprocessedCaseID = new Set(processedCaseID);


        InventoryCaseWarehouseData.forEach(item => {
            const existingCaseSku = newCaseSkus.find(sku => sku.CaseSKU === item.CaseSKU);

            if (!existingCaseSku) {
                newCaseSkus.push({ CaseSKU: item.CaseSKU, quantity: 1 });
                newprocessedCaseID.add(item.CaseID);
            } else if (!newprocessedCaseID.has(item.CaseID)) {
                existingCaseSku.quantity += 1;
                newprocessedCaseID.add(item.CaseID);
            }
        });

        // this.setState({ caseSkus: newCaseSkus });





        this.setState({
            caseContent: newCaseContent,
            caseSkus: newCaseSkus,
            processedCaseID: newprocessedCaseID
        });





        this.setState(prevState => {
            const newElements = this.props.InventoryCaseWarehouseData.filter(
                item => !prevState.BuildLoadDict.some(existingItem => existingItem.CaseID === item.CaseID)
            );

            return { BuildLoadDict: [...prevState.BuildLoadDict, ...newElements] };
        });


    }


    addSkus = () => {
        const { InventorySkuWarehouseData } = this.props;

        const { skuContent } = this.state;
        const newSkuContent = [...skuContent];

        // const { processedCaseSkuIDs } = this.state;
        // const newprocessedCaseSkuIDs = [...processedCaseSkuIDs];

        const { processedCaseSkuIDs } = this.state;
        const newprocessedCaseSkuIDs = new Set(processedCaseSkuIDs);


        InventorySkuWarehouseData.forEach(item => {
            const existingItem = newSkuContent.find(sku => sku.SKU === item.SKU);

            if (!existingItem) {
                newSkuContent.push({ SKU: item.SKU, UnitsInWhse: item.UnitsInWhse });
                newprocessedCaseSkuIDs.add(item.CaseSkuID);
            } else if (!newprocessedCaseSkuIDs.has(item.CaseSkuID)) {
                existingItem.UnitsInWhse += item.UnitsInWhse;
                newprocessedCaseSkuIDs.add(item.CaseSkuID);
            }
        });

        this.setState({ skuContent: newSkuContent, processedCaseSkuIDs: newprocessedCaseSkuIDs });
    };


    onChangeLoad = (e, label) => {
        // var loadDict = this.state
        // loadDict[label] = e
        this.setState({
            unsavedBuildLoadChanges: true,
            [label]: e
        });

        this.forceUpdate()
    };


    // onChangeLoad(e, label) {
    //     // Get the current time
    //     const now = Date.now();

    //     // Check if the last input was less than 30 milliseconds ago
    //     if (this.lastInputTime && now - this.lastInputTime < 30) {
    //       // If it was, ignore this input
    //       return;
    //     }

    //     // Update the last input time
    //     this.lastInputTime = now;

    //     // Continue with your existing onChangeLoad logic...
    //     this.setState({
    //         unsavedBuildLoadChanges: true,
    //         [label]: e
    //     });

    //     this.forceUpdate()
    //   }


    //   onChangeLoad(e, label) {
    //     // Get the current time
    //     const now = Date.now();

    //     // If we're currently blocking inputs, ignore this input
    //     if (this.blockInputsUntil && now < this.blockInputsUntil) {
    //         return;
    //     }

    //     // If the last input was less than 30 milliseconds ago, start blocking inputs for the next 500 milliseconds
    //     if (this.lastInputTime && now - this.lastInputTime < 30) {
    //         this.blockInputsUntil = now + 500;
    //         return;
    //     }

    //     // Update the last input time
    //     this.lastInputTime = now;

    //     // Continue with your existing onChangeLoad logic...
    //     this.setState({
    //         unsavedBuildLoadChanges: true,
    //         [label]: e
    //     });

    //     this.forceUpdate();
    // }





    onClickRemoveFromAdd = (e) => {

    }

    onClickBuildLoadDrawerSave(e) {


        if ((!this.state.palletContent || this.state.palletContent.length === 0) && (!this.state.caseContent || this.state.caseContent.length === 0)) {
            window.alert('Nothing to load.');
        }

        else if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            // let LoadPalletDictString = this.state.palletIDList.join(",");
            // // let LoadPalletDictString = this.state.palletContent.join(",");
            // // let LoadPalletDictString = this.state.palletContent.map(item => `'${item}'`).join(",");
            // try {
            //     var paramPostLoadPalletDict = "'" + LoadPalletDictString + "'"
            // }
            // catch (e) {
            //     var paramPostLoadPalletDict = null
            // }

            let LoadPalletDictString = this.state.palletIDList.join(",");
            var paramPostLoadPalletDict = null;

            if (LoadPalletDictString !== "") {
                paramPostLoadPalletDict = "'" + LoadPalletDictString + "'";
            }

            // let LoadCaseDictString = this.state.caseIDList.join(",");
            // // let LoadCaseDictString = this.state.caseContent.join(",");
            // // let LoadCaseDictString = this.state.caseContent.map(item => `'${item}'`).join(",");
            // try {
            //     var paramPostLoadCaseDict = "'" + LoadCaseDictString + "'"
            // }
            // catch (e) {
            //     var paramPostLoadCaseDict = null
            // }


            let LoadCaseDictString = this.state.caseIDList.join(",");
            var paramPostLoadCaseDict = null;

            if (LoadCaseDictString !== "") {
                paramPostLoadCaseDict = "'" + LoadCaseDictString + "'";
            }



            try {

                var paramLoadID = formatStringForAPI(this.state.addToExistingLoadID)
            }
            catch (e) {
                var paramLoadID = null
            }


            try {
                if (this.state.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                // var paramPostCarrier = "'" + this.state.Carrier + "'"
                var paramPostCarrier = formatStringForAPI(this.state.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                // var paramPostBOL = "'" + this.state.BOL + "'"
                var paramPostBOL = formatStringForAPI(this.state.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                // var paramPostTrailer = "'" + this.state.Trailer + "'"
                var paramPostTrailer = formatStringForAPI(this.state.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                // var paramPostSeal = "'" + this.state.Seal + "'"
                var paramPostSeal = formatStringForAPI(this.state.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }


            try {
                var paramPostMC = formatStringForAPI(this.state.MC)
            }
            catch (e) {
                var paramPostMC = null
            }
            try {
                var paramPostDOT = formatStringForAPI(this.state.DOT)
            }
            catch (e) {
                var paramPostDOT = null
            }
            try {
                var paramPostDriver = formatStringForAPI(this.state.Driver)
            }
            catch (e) {
                var paramPostDriver = null
            }
            try {
                var paramPostTruck = formatStringForAPI(this.state.Truck)
            }
            catch (e) {
                var paramPostTruck = null
            }
            try {
                var paramPostTruckPlate = formatStringForAPI(this.state.TruckPlate)
            }
            catch (e) {
                var paramPostTruckPlate = null
            }
            try {
                var paramPostTrailerPlate = formatStringForAPI(this.state.TrailerPlate)
            }
            catch (e) {
                var paramPostTrailerPlate = null
            }
            try {
                var paramPostNotes = formatStringForAPI(this.state.Notes)
            }
            catch (e) {
                var paramPostNotes = null
            }


            try {
                var paramPostDesintation = formatStringForAPI(this.state.Destination)
            }
            catch (e) {
                var paramPostDesintation = null
            }
            try {
                var paramPostLoadStatusID = 1
            }
            catch (e) {
                var paramPostLoadStatusID = 1
            }

            // try {
            //     var paramReportNav = formatStringForAPI(this.state.reportNav)
            // }
            // catch (e) {
            //     var paramReportNav = null
            // }

            if (this.saveButton) {
                this.saveButton.blur();
            }


            this.props.inventoryLoadBarcodeScanPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPostLoadPalletDict, paramPostLoadCaseDict, null, paramLoadID, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, paramPostLoadStatusID, paramPostMC, paramPostDOT, paramPostDriver, paramPostTruck, paramPostTruckPlate, paramPostTrailerPlate, paramPostNotes)


            this.setState({
                unsavedBuildLoadChanges: false,
                showBuildLoadDrawer: false
            });
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }



    onClickReset = (e) => {
        if (window.confirm("Are you sure you want to reset form?")) {
            // Remove focus from the Reset button
            if (this.resetButton) {
                this.resetButton.blur();
            }

            this.resetState();
        }
    }



    handleInputChange = (value) => {
        this.setState({ barcodeInput: value });
    };

    isPallet = (barcode) => {
        const parts = barcode.split(' ');
        const thirdPosition = parts.length > 2 ? parts[2].toUpperCase() : null;
        return thirdPosition === 'P';
    };

    isCase = (barcode) => {
        const parts = barcode.split(' ');
        const thirdPosition = parts.length > 2 ? parts[2].toUpperCase() : null;
        return thirdPosition === 'C';
    };


    handleInputSubmit(e, inputDisabled) {


        if (inputDisabled === true) {
            window.alert('Please wait before scanning next barcode.')
        }
        else if (this.props.isInventoryLotBarcodeScanValidateLoading) {
            window.alert('Please wait before scanning next barcode.')
        }
        // else if ((this.props.isInventoryPalletWarehouseLoading || this.props.isInventoryCaseWarehouseLoading || this.props.isInventorySkuWarehouseLoading)) {
        //     window.alert('Please wait before scanning next barcode.')
        // }
        else {
            if (this.state.barcodeInput) {

                if (this.isPallet(this.state.barcodeInput) || this.isCase(this.state.barcodeInput)) {
                    this.props.inventoryLotBarcodeScanValidateGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, formatStringForAPI(this.state.barcodeInput))
                }


                // if (this.isPallet(this.state.barcodeInput)) {
                //     // this.getPallets(barcode);
                //     this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, formatStringForAPI(paramStatusID), null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null, null)
                //     this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, formatStringForAPI(paramStatusID), null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null)
                //     this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, formatStringForAPI(paramStatusID), null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
                // } else if (this.isCase(this.state.barcodeInput)) {
                //     // getCases function...
                //     this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, formatStringForAPI(paramStatusID), null, null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null)
                //     this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, formatStringForAPI(paramStatusID), null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null)
                // }
                else {
                    window.alert('Barcode not recognized as pallet or case.');
                }

                this.setState(prevState => ({
                    barcodeScannedList: [...prevState.barcodeScannedList, this.state.barcodeInput],
                    barcodeInput: ''
                }));
            }
        }

    };



    onSelectLoad(e, rowData, history) {
        this.setState({
            addToExistingLoadID: rowData['LoadID'],
            existingLoadData: rowData
        });
    }

    onClickDeselectLoad() {
        this.setState({
            addToExistingLoadID: null,
            existingLoadData: []
        });
    }

    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;



        if (this.props.InventoryLoadPalletCaseSkuPostLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, building load...</p>
                </div>
            );
        }


        let inputDisabled = false;
        if (this.props.isInventoryLotBarcodeScanValidateLoading) {
            inputDisabled = true;
        }
        else {
            inputDisabled = false;
        }


        // let inputDisabled = false;
        // if (this.props.isInventoryPalletWarehouseLoading || this.props.isInventoryCaseWarehouseLoading || this.props.isInventorySkuWarehouseLoading) {
        //     inputDisabled = true;
        // }
        // else {
        //     inputDisabled = false;
        // }



        let rTable;
        if (this.props.isInventoryLoadLotLoading) {
            rTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadLotData) !== 'undefined' && this.props.InventoryLoadLotData != null) {
            rTable = RTable(
                this.props.InventoryLoadLotData,        // tableData,
                InventoryLoadDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isInventoryLoadLotLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                false, // checkColEntry,
                // true,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                null,                       // checkedKeys,
                null,                       // handleCheckAll,
                null,                       // handleCheck,
                true,                      // boolSelectButton,
                this.onSelectLoad,     // onClickSelect,
                'Select',                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                this.state.addToExistingLoadID,                        // highlightRowKey
                true,                            // editLink,
                'Manifest',                            // editLinkText,
                this.onClickExportLoad                            // editLinkOnClick
            )


        }
        else {
            rTable = <div></div>

        }


        let destinationDiv;
        if (this.props.isInventoryDestinationLoading) {
            destinationDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryDestinationData) !== 'undefined' && this.props.InventoryDestinationData != null) {
            destinationDiv = <div style={{ marginBottom: '5px', width: '100%' }}>
                <InputPicker preventOverflow
                    container={() => mountRef.current}
                    block
                    creatable
                    placeholder={'Destination...'}
                    value={this.state.Destination}
                    // onChange={(e, value, label) => { onChangeDestination(e, value, label) }}
                    onChange={(e) => { this.onChangeLoad(e, 'Destination') }}
                    data={this.props.InventoryDestinationData}
                    cleanable={true}
                />
            </div>
        }
        else {
            destinationDiv = <div></div>
        }

        return (


            <div style={{ margin: '0 auto' }}>
                <Panel header={<h3>Inventory Management - Load Scan Barcodes</h3>} bordered style={styles}>

                    {/* <InputGroup inside style={{ marginBottom: '10px' }}> */}
                    <InputGroup style={{ marginBottom: '10px' }}>
                        <InputGroup.Addon>Barcode:</InputGroup.Addon>
                        <Input
                            id="barcodeinput"
                            // innerRef={this.inputRef}
                            value={this.state.barcodeInput}
                            onChange={this.handleInputChange}
                            // onPressEnter={this.handleInputSubmit(inputDisabled)}
                            onPressEnter={(e) => { this.handleInputSubmit(e, inputDisabled) }}
                            // disabled={inputDisabled}
                            autoFocus
                        />
                        <InputGroup.Button onClick={(e) => { this.handleInputSubmit(e, inputDisabled) }} onPressEnter={(e) => { this.handleInputSubmit(e, inputDisabled) }} disabled={inputDisabled}>
                            {inputDisabled ? <Loader size="md" /> : <Icon icon="barcode" />}
                        </InputGroup.Button>
                    </InputGroup>





                    {this.state.addToExistingLoadID == null ?

                        <Panel bordered style={{ marginBottom: '10px' }} header={
                            <div >
                                <h4>Create New Load:</h4>
                            </div>
                        }>
                            <Row>
                                <Col md={24} sm={24}>
                                    <FormGroup >
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Pickup Date:</InputGroup.Addon>
                                            <DatePicker
                                                container={() => mountRef.current} oneTap style={styles} value={(this.state.PickupDate === null ? null : moment(this.state.PickupDate).format('YYYY-MM-DD'))} onChange={(e) => this.onChangeLoad(e, 'PickupDate')} />
                                        </InputGroup>
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Delivery Date:</InputGroup.Addon>
                                            <DatePicker
                                                container={() => mountRef.current} oneTap style={styles} value={(this.state.DeliveryDate === null ? null : moment(this.state.DeliveryDate).format('YYYY-MM-DD'))} onChange={(e) => this.onChangeLoad(e, 'DeliveryDate')} />
                                        </InputGroup>

                                        {destinationDiv}

                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={24}>
                                    <FormGroup >

                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                            <Input value={this.state.Carrier} onChange={(e) => { this.onChangeLoad(e, 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>

                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Trailer #:</InputGroup.Addon>
                                            <Input value={this.state.Trailer} onChange={(e) => { this.onChangeLoad(e, 'Trailer') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>

                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>MC #:</InputGroup.Addon>
                                            <Input defaultValue={this.state.MC} onChange={(e) => { this.onChangeLoad(e, 'MC') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>DOT #:</InputGroup.Addon>
                                            <Input defaultValue={this.state.DOT} onChange={(e) => { this.onChangeLoad(e, 'DOT') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Driver:</InputGroup.Addon>
                                            <Input defaultValue={this.state.Driver} onChange={(e) => { this.onChangeLoad(e, 'Driver') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>



                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={24}>
                                    <FormGroup >


                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>BOL #:</InputGroup.Addon>
                                            <Input value={this.state.BOL} onChange={(e) => { this.onChangeLoad(e, 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>

                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Seal #:</InputGroup.Addon>
                                            <Input value={this.state.Seal} onChange={(e) => { this.onChangeLoad(e, 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>


                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Truck #:</InputGroup.Addon>
                                            <Input defaultValue={this.state.Truck} onChange={(e) => { this.onChangeLoad(e, 'Truck') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Truck Plate:</InputGroup.Addon>
                                            <Input defaultValue={this.state.TruckPlate} onChange={(e) => { this.onChangeLoad(e, 'TruckPlate') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>
                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                            <InputGroup.Addon>Trailer Plate:</InputGroup.Addon>
                                            <Input defaultValue={this.state.TrailerPlate} onChange={(e) => { this.onChangeLoad(e, 'TrailerPlate') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>



                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>

                                <Col md={24} sm={24}>

                                    <FormGroup >

                                        <InputGroup style={{ marginBottom: '15px', width: '100%' }}>
                                            <InputGroup.Addon>Notes:</InputGroup.Addon>
                                            <Input defaultValue={this.state.Notes} onChange={(e) => { this.onChangeLoad(e, 'Notes') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 300)} />
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                            </Row>



                        </Panel>

                        :


                        <Panel bordered style={{ marginBottom: '10px' }} header={
                            <div >
                                <h4>Adding To Existing Load:
                                    <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeselectLoad(e) }} >Deselect Existing Load</Button>
                                </h4>
                            </div>
                        }>


                            <Row>

                                <Col md={12} sm={12}>
                                    <List>
                                        Trailer: <strong>{this.state.existingLoadData.Trailer}</strong>
                                        <br />
                                        Carrier: <strong>{this.state.existingLoadData.Carrier}</strong>
                                        <br />
                                        BOL: <strong>{this.state.existingLoadData.BOL}</strong>
                                        <br />
                                        Seal: <strong>{this.state.existingLoadData.Seal}</strong>
                                        <br />
                                        Pickup Date: <strong>{this.state.existingLoadData.PickupDate}</strong>
                                        <br />
                                        Delivery Date: <strong>{this.state.existingLoadData.DeliveryDate}</strong>
                                        <br />

                                        Customer: <strong>{this.state.existingLoadData.CustomerName}</strong>
                                        <br />
                                        Warehouse: <strong>{this.state.existingLoadData.WarehouseName}</strong>
                                        <br />
                                    </List>

                                </Col>


                                <Col md={12} sm={12}>
                                    <List>
                                        Destination: <strong>{this.state.existingLoadData.Destination}</strong>
                                        <br />
                                        MC #: <strong>{this.state.existingLoadData.MC}</strong>
                                        <br />
                                        DOT #: <strong>{this.state.existingLoadData.DOT}</strong>
                                        <br />
                                        Driver: <strong>{this.state.existingLoadData.Driver}</strong>
                                        <br />
                                        Truck #: <strong>{this.state.existingLoadData.Truck}</strong>
                                        <br />
                                        Truck Plate: <strong>{this.state.existingLoadData.TruckPlate}</strong>
                                        <br />
                                        Trailer Plate: <strong>{this.state.existingLoadData.TrailerPlate}</strong>
                                        <br />
                                        Notes: <strong>{this.state.existingLoadData.Notes}</strong>
                                        <br />


                                    </List>

                                </Col>
                            </Row>




                        </Panel>
                    }


                    <Row >
                        <Col md={12} sm={24}>
                            <ButtonToolbar >
                                {/* <Button block onClick={(e) => { this.onClickBuildLoadDrawerSave(e) }} appearance="primary">Save</Button> */}
                                <button className="primarybutton" ref={saveButton => this.saveButton = saveButton} onClick={(e) => { this.onClickBuildLoadDrawerSave(e) }}>Save Load</button>
                            </ButtonToolbar>
                        </Col>
                        <Col md={12} sm={24}>
                            <ButtonToolbar >
                                {/* <Button block ref={resetButton => this.resetButton = resetButton?.button} onClick={this.onClickReset} appearance="default">Reset</Button> */}
                                <button className="defaultbutton" ref={resetButton => this.resetButton = resetButton} onClick={this.onClickReset}>Reset</button>
                            </ButtonToolbar>
                        </Col>
                    </Row>



                    {this.state.barcodeScannedList.length > 0 && (
                        <div>
                            <br />
                            <h4>Barcodes Scanned</h4>

                            <List>
                                {
                                    (() => {
                                        let encounteredBarcodes = new Set();
                                        return this.state.barcodeScannedList.map((barcode) => {
                                            const isBarcodeInContent = this.state.palletContent.indexOf(barcode) !== -1 || this.state.caseContent.indexOf(barcode) !== -1; // Supports Internet Explorer
                                            const isRepeat = encounteredBarcodes.has(barcode);
                                            encounteredBarcodes.add(barcode);
                                            return (
                                                <div key={barcode}>
                                                    {isBarcodeInContent ? (isRepeat ? '⚠️' : '✅') : '❌'} {barcode}
                                                </div>
                                            )
                                        })
                                    })()
                                }
                            </List>

                        </div>
                    )}
                </Panel>

                {this.state.palletContent.length > 0 && (
                    <Panel header={<h4>Pallets Loaded</h4>} bordered style={styles}>
                        <List>
                            {this.state.palletContent.map((item, index) => (
                                <List.Item key={index} index={index}>
                                    {item}
                                </List.Item>
                            ))}
                        </List>
                    </Panel>
                )}

                {(this.state.caseContent.length > 0 || this.state.caseSkus.length > 0) && (
                    <Panel bordered style={styles}>
                        <Col md={12} sm={24}>
                            {this.state.caseContent.length > 0 && (
                                // <Panel header={<h4>Cases Loaded</h4>} style={styles}>
                                <div>
                                    <h4>Cases Loaded</h4>
                                    <List>
                                        {this.state.caseContent.map((item, index) => (
                                            <List.Item key={index} index={index}>
                                                {item}
                                            </List.Item>
                                        ))}
                                    </List>
                                </div>
                                // </Panel>
                            )}
                        </Col>
                        <Col md={12} sm={24}>
                            {this.state.caseSkus.length > 0 && (
                                // <Panel header={<h4>Case SKUs</h4>} style={styles}>
                                <div>
                                    <h4>Case SKUs</h4>
                                    <List>
                                        {this.state.caseSkus.map((item, index) => (
                                            <List.Item key={index} index={index}>
                                                {item.CaseSKU} (Qty: {item.quantity})
                                            </List.Item>
                                        ))}
                                    </List>
                                </div>
                                // </Panel>
                            )}
                        </Col>

                    </Panel>

                )}

                {this.state.skuContent.length > 0 && (
                    <Panel header={<h4>Units Loaded</h4>} bordered style={styles}>
                        <List>
                            {this.state.skuContent.map((item, index) => (
                                <List.Item key={index} index={index}>
                                    {item.SKU} (Qty: {item.UnitsInWhse})
                                </List.Item>
                            ))}
                        </List>
                    </Panel>
                )}

                <Panel header={<h4>Existing Loads</h4>} bordered style={styles}>
                    {rTable}
                </Panel>

            </div>

        );
    }
}

export default withRouter(LoadBuildByScanning);