import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel } from 'rsuite';


function limitNumberToExistingValue(e, item) {
    try {
        var limitValue = item['RecoveredPCS'] - item['LoadedPCS']
    }
    catch (e) {
        var limitValue = 0
    }
    if (e.target.value <= limitValue) {
        return e.target.value
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }

}


function AddNewModal({
    addNewModalShown, 
    handleSave,
    handleClose,
    onChangeExisting,
    resetRows,
    state, ...props


}) {
    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={addNewModalShown} onHide={handleClose} onExited={resetRows} style={{ zIndex:'9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>Add House Ref: {state.item.HouseRef}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Panel bordered style={{ marginBottom: '15px' }}>
                        <Row>
                            <Col md={24} sm={24}>
                                <FormGroup >
                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>PCS:</InputGroup.Addon>
                                        <InputNumber defaultValue={state.item.NewRecoveredPCS} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, state.item, 'NewRecoveredPCS')} scrollable={false} onInput={(e) => e.target.value = limitNumberToExistingValue(e, state.item)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                        <InputNumber defaultValue={state.item.NewPallets} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, state.item, 'NewPallets')} scrollable={false} />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Panel>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default AddNewModal;

