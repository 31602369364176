import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textStringInputMask, lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';


const styles = {
    // width: 300,
    // marginBottom: 10,
    width: '100%'
};


//   const styles = {
//     // width: 300,
//     marginBottom: 10,
//     width: 200 
//   };


const mountRef = React.createRef();

const MasterEvolveDataDrawer = ({ onChange, show, placement, confirm, close, masterLabel, state, ...props }) => (

    <Drawer show={state.showEvolveDataDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Master: {masterLabel}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>


                <FormGroup>
                    <ControlLabel>No</ControlLabel>
                    <FormControl name="No" value={state.EvolveDataNo} onChange={(e) => { onChange(e, 'EvolveDataNo') }} onInput={(e) => e.target.value = numbersInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>PMC</ControlLabel>
                    <FormControl name="PMC" value={state.EvolveDataPMC} onChange={(e) => { onChange(e, 'EvolveDataPMC') }} onInput={(e) => e.target.value = numbersInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Pickup By</ControlLabel>
                    <FormControl name="PickupBy" value={state.EvolveDataPickupBy} onChange={(e) => { onChange(e, 'EvolveDataPickupBy') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Trips</ControlLabel>
                    <FormControl name="Trips" value={state.EvolveDataTrips} onChange={(e) => { onChange(e, 'EvolveDataTrips') }} onInput={(e) => e.target.value = numbersInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Arrival Date</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="Arrival Date" value={state.EvolveDataArrivalTime} onChange={(e) => { onChange(e, 'EvolveDataArrivalTime') }} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>LFD</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="LFD" value={state.EvolveDataLFD} onChange={(e) => { onChange(e, 'EvolveDataLFD') }} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Airline Recovered</ControlLabel>
                    <FormControl name="AirlineRecovered" value={state.EvolveDataRecovered} onChange={(e) => { onChange(e, 'EvolveDataRecovered') }} onInput={(e) => e.target.value = numbersInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Arrival At Warehouse</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="Arrival At Warehouse" value={state.EvolveDataArrivalAtWarehouse} onChange={(e) => { onChange(e, 'EvolveDataArrivalAtWarehouse') }} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Delivery Date</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="Delivery Date" value={state.EvolveDataDeliveryTime} onChange={(e) => { onChange(e, 'EvolveDataDeliveryTime') }} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Notes</ControlLabel>
                    <FormControl name="Notes" value={state.EvolveDataNotes} onChange={(e) => { onChange(e, 'EvolveDataNotes') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                </FormGroup>



                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={confirm} appearance="primary">Submit</Button>
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>


        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer>

);


MasterEvolveDataDrawer.defaultProps = {
    show: false
};

export default MasterEvolveDataDrawer;


