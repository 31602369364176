import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, FormGroup, DatePicker, Input } from 'rsuite';
import { lettersNumbersDashesSpacesInputMask, lettersNumbersInputMask, lettersNumbersDashesInputMask, zipCodeInputMask, numbersDashInputMask, textStringInputMask } from '../../../resources/logic/inputMasks';
import { LabelGenLengthUnits, LabelGenWeightUnits, UpsPackagingCode } from '../../../app/appConfig';
import AddPackageInputDiv from './AddPackageInputDiv';

const styles = {
    marginBottom: '10px',
    width: '100%'
};

const mountRef = React.createRef();

function AddPackageModal({
    addNewPackageModalShown,
    handleSave,
    handleClose,
    onChange,
    resetRows,
    state, ...props
}) {
    return (

        <div className="modal-container" >
            <Modal backdrop={'static'} keyboard={false} show={addNewPackageModalShown} onHide={handleClose} onExited={resetRows} >
                <Modal.Header>
                    <Modal.Title>Package:</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <AddPackageInputDiv {...props} state={state} handleSave={handleSave} onChange={onChange} resetRows={resetRows} handleClose={handleClose}/>


                </Modal.Body>

            </Modal>
        </div>
    );
}


export default AddPackageModal;