import { connect } from 'react-redux';
import React, { Component } from 'react';
import InventoryPalletCaseSku from './InventoryPalletCaseSku'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI, formatTrueFalseToBit } from '../../../../resources/logic/functions';
import * as userActions from '../../../../redux/actions/actionsUser'

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryLotSkuLoading: store.inventoryReducer.isInventoryLotSkuLoading,
    InventoryLotSkuData: store.inventoryReducer.InventoryLotSkuData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,
    SkuLocationDataGrouped: store.inventoryReducer.SkuLocationDataGrouped,



    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,



    isInventorySkuWarehouseLoading: store.inventoryReducer.isInventorySkuWarehouseLoading,
    InventorySkuWarehouseData: store.inventoryReducer.InventorySkuWarehouseData,

    isInventoryCaseWarehouseLoading: store.inventoryReducer.isInventoryCaseWarehouseLoading,
    InventoryCaseWarehouseData: store.inventoryReducer.InventoryCaseWarehouseData,

    isInventoryPalletWarehouseLoading: store.inventoryReducer.isInventoryPalletWarehouseLoading,
    InventoryPalletWarehouseData: store.inventoryReducer.InventoryPalletWarehouseData,

    InventoryLoadPalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadPalletCaseSkuPostSuccessful,


    InventoryPalletCreateNewPostSuccessful: store.inventoryReducer.InventoryPalletCreateNewPostSuccessful,
    InventoryNewPalletBarcode: store.inventoryReducer.InventoryNewPalletBarcode,

    isInventoryLoadLotLoading: store.inventoryReducer.isInventoryLoadLotLoading,
    InventoryLoadLotData: store.inventoryReducer.InventoryLoadLotData,



    isInventoryLotHistoryTypeLoading: store.inventoryReducer.isInventoryLotHistoryTypeLoading,
    InventoryLotHistoryTypeData: store.inventoryReducer.InventoryLotHistoryTypeData,

    InventoryDeleteContainerPalletCaseUnitPostSuccessful: store.inventoryReducer.InventoryDeleteContainerPalletCaseUnitPostSuccessful,
    isInventoryDeleteContainerPalletCaseUnitLoading: store.inventoryReducer.isInventoryDeleteContainerPalletCaseUnitLoading,


    InventoryPalletWarehouseLocationPostSuccessful: store.inventoryReducer.InventoryPalletWarehouseLocationPostSuccessful,


    isInventoryPalletWarehouseLoading2: store.inventoryReducer.isInventoryPalletWarehouseLoading2,
    InventoryPalletWarehouseData2: store.inventoryReducer.InventoryPalletWarehouseData2,


    InventoryCaseWarehouseLocationPostSuccessful: store.inventoryReducer.InventoryCaseWarehouseLocationPostSuccessful,

});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryLotSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryLotSkuWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryLotSkuWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventoryCaseWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },

    inventoryPalletWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },


    inventoryLoadPalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes))
    },


    inventoryPalletCreateNewPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _WarehouseLocationID, _Oversize, _Notes) => {
        dispatch(inventoryActions.inventoryPalletCreateNewPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _WarehouseLocationID, _Oversize, _Notes))
    },

    inventoryLoadLotGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadLotGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID))
    },

    inventoryLotHistoryTypeGet: (_email, _authToken, _InventoryDelete) => {
        dispatch(inventoryActions.inventoryLotHistoryTypeGet(_email, _authToken, _InventoryDelete))
    },

    inventoryLotDeleteContainerPalletCaseUnitPost: (_email, _authToken, _WarehouseID, _CustomerID, _HistoryTypeID, _ContainerIdList, _PalletIdList, _PalletBarcode, _CaseIdList, _CaseBarcode, _UnitIdList, _UnitsToRemove) => {
        dispatch(inventoryActions.inventoryLotDeleteContainerPalletCaseUnitPost(_email, _authToken, _WarehouseID, _CustomerID, _HistoryTypeID, _ContainerIdList, _PalletIdList, _PalletBarcode, _CaseIdList, _CaseBarcode, _UnitIdList, _UnitsToRemove))
    },


    inventoryPalletWarehouseLocationPost: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _NewWarehouseLocationID, _Departed, _PalletStatusID, _Oversize, _NewWarehouseLocationBarcode, _NewWarehouseLocationBarcodeNoSpaces, _PalletIdList) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationPost(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _NewWarehouseLocationID, _Departed, _PalletStatusID, _Oversize, _NewWarehouseLocationBarcode, _NewWarehouseLocationBarcodeNoSpaces, _PalletIdList))
    },

    inventoryPalletWarehouseLocationGet2: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet2(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },

    inventoryCaseWarehouseLocationPost: (_email, _authToken, _WarehouseID, _CustomerID, _CaseID, _CaseBarcode, _NewPalletID, _NewPalletBarcode, _CaseIdList) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationPost(_email, _authToken, _WarehouseID, _CustomerID, _CaseID, _CaseBarcode, _NewPalletID, _NewPalletBarcode, _CaseIdList))
    },


});

class InventoryPalletCaseSkuContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLoadPalletCaseSkuPostSuccessful !== this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.props.InventoryLoadPalletCaseSkuPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.InventoryPalletCreateNewPostSuccessful !== this.props.InventoryPalletCreateNewPostSuccessful && this.props.InventoryPalletCreateNewPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventoryDeleteContainerPalletCaseUnitPostSuccessful !== this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful && this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful) {
            this.handleUrlParam()
        }
        

        if (prevProps.InventoryPalletWarehouseLocationPostSuccessful !== this.props.InventoryPalletWarehouseLocationPostSuccessful && this.props.InventoryPalletWarehouseLocationPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventoryCaseWarehouseLocationPostSuccessful !== this.props.InventoryCaseWarehouseLocationPostSuccessful && this.props.InventoryCaseWarehouseLocationPostSuccessful) {
            this.handleUrlParam()
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramReportNav = queryStringParse(values.reportnav)
        }
        catch (e) {
            var paramReportNav = null
        }


        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatusID = formatStringForAPI(values.statusid)
        }
        catch (e) {
            var paramStatusID = null
        }


        try {
            var paramRecoveryDateStart = formatDateForAPI(values.recoverydtstart)
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        try {
            var paramRecoveryDateEnd = formatDateForAPI(values.recoverydtend)
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }


        try {
            var paramWarehouseLocationId = formatDateForAPI(values.warehouselocationid)
        }
        catch (e) {
            var paramWarehouseLocationId = null
        }







        try {
            var paramPalletbarcode = formatStringForAPI(values.palletbarcode)
        }
        catch (e) {
            var paramPalletbarcode = null
        }

        try {
            var paramCasesku = formatStringForAPI(values.casesku)
        }
        catch (e) {
            var paramCasesku = null
        }
        try {
            var paramcasebarcode = formatStringForAPI(values.casebarcode)
        }
        catch (e) {
            var paramcasebarcode = null
        }
        try {
            var paramcaseserial = formatStringForAPI(values.caseserial)
        }
        catch (e) {
            var paramcaseserial = null
        }
        try {
            var paramcaselotcode = formatStringForAPI(values.caselotcode)
        }
        catch (e) {
            var paramcaselotcode = null
        }
        try {
            var paramunitsku = formatStringForAPI(values.unitsku)
        }
        catch (e) {
            var paramunitsku = null
        }
        try {
            var paramunitserial = formatStringForAPI(values.unitserial)
        }
        catch (e) {
            var paramunitserial = null
        }
        try {
            var paramunitlotcode = formatStringForAPI(values.unitlotcode)
        }
        catch (e) {
            var paramunitlotcode = null
        }

        try {
            var paramcaseskuid = formatStringForAPI(values.caseskuid)
        }
        catch (e) {
            var paramcaseskuid = null
        }
        try {

            if (typeof values.caseskuid === 'undefined' || values.caseskuid == null) {
                var paramPalletOversize = null
            }
            else {
                var paramPalletOversize = formatTrueFalseToBit(values.palletoverize)
            }
        }
        catch (e) {
            var paramPalletOversize = null
        }

        try {
            var paramSkuID = formatStringForAPI(values.skuid)
        }
        catch (e) {
            var paramSkuID = null
        }

        try {
            var paramCaseID = formatStringForAPI(values.caseid)
        }
        catch (e) {
            var paramCaseID = null
        }

        try {
            var paramPalletID = formatStringForAPI(values.palletid)
        }
        catch (e) {
            var paramPalletID = null
        }

        // this.props.inventoryLotSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramSku, null, paramContainer, paramStatus, paramWarehouseLocationId)
        // this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramSku, null, paramContainer, paramStatus, paramWarehouseLocationId)

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);



        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);


        this.props.inventoryLotHistoryTypeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, 1)
        


        // if (paramRecoveryDtEnd !== null && paramRecoveryDtStart === null) {
        //     window.alert('Please select start date.')
        // }
        // else {

        // }

        // this.props.currentUserEmail, this.props.userSignInData.AuthToken, _WarehouseLocationID, this.props.warehouseSelection, this.props.customerSelection, _PalletStatusID, _CaseStatusID, _SkuStatusID, null, null, null, null, null, null, null, null, null, null, null, null, null, paramRecoveryDtStart, paramRecoveryDtEnd

        if (paramReportNav === 'pallet') {
            this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
            this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        
            this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseLocationId, this.props.warehouseSelection, this.props.customerSelection, paramStatusID, null, null, paramPalletID, paramPalletbarcode, paramCaseID, paramcasebarcode, paramCasesku, paramcaselotcode, paramcaseserial, paramSkuID, paramunitsku, paramunitlotcode, paramunitserial, null, paramContainer, paramRecoveryDateStart, paramRecoveryDateEnd, paramPalletOversize)


        }
        else if (paramReportNav === 'case') {
            this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
            this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        
            this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseLocationId, this.props.warehouseSelection, this.props.customerSelection, null, paramStatusID, null, paramPalletID, paramPalletbarcode, paramCaseID, paramcasebarcode, paramCasesku, paramcaselotcode, paramcaseserial, paramSkuID, paramunitsku, paramunitlotcode, paramunitserial, null, paramContainer, paramRecoveryDateStart, paramRecoveryDateEnd)
        

        }
        else if (paramReportNav === 'sku') {
            this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
            this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, -1, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
       

            this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseLocationId, this.props.warehouseSelection, this.props.customerSelection, null, null, paramStatusID, paramPalletID, paramPalletbarcode, paramCaseID, paramcasebarcode, null, paramCasesku, paramcaselotcode, paramcaseserial, paramSkuID, paramunitsku, paramunitlotcode, paramunitserial, null, paramContainer, paramRecoveryDateStart, paramRecoveryDateEnd, null)
        

        }





    }



    render() {

        return (

            <InventoryPalletCaseSku
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}


                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                
                isInventoryLotSkuLoading={this.props.isInventoryLotSkuLoading}
                InventoryLotSkuData={this.props.InventoryLotSkuData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}
                SkuLocationDataGrouped={this.props.SkuLocationDataGrouped}



                isInventorySkuWarehouseLoading={this.props.isInventorySkuWarehouseLoading}
                InventorySkuWarehouseData={this.props.InventorySkuWarehouseData}

                isInventoryCaseWarehouseLoading={this.props.isInventoryCaseWarehouseLoading}
                InventoryCaseWarehouseData={this.props.InventoryCaseWarehouseData}

                isInventoryPalletWarehouseLoading={this.props.isInventoryPalletWarehouseLoading}
                InventoryPalletWarehouseData={this.props.InventoryPalletWarehouseData}

                InventoryLoadPalletCaseSkuPostSuccessful={this.props.InventoryLoadPalletCaseSkuPostSuccessful}
                inventoryLoadPalletCaseSkuPost={this.props.inventoryLoadPalletCaseSkuPost}

                InventoryNewPalletBarcode={this.props.InventoryNewPalletBarcode}
                InventoryPalletCreateNewPostSuccessful={this.props.InventoryPalletCreateNewPostSuccessful}
                inventoryPalletCreateNewPost={this.props.inventoryPalletCreateNewPost}

                inventoryLoadLotGet={this.props.inventoryLoadLotGet}
                isInventoryLoadLotLoading={this.props.isInventoryLoadLotLoading}
                InventoryLoadLotData={this.props.InventoryLoadLotData}


                isInventoryDestinationLoading={this.props.isInventoryDestinationLoading}
                InventoryDestinationData={this.props.InventoryDestinationData}





                isInventoryLotHistoryTypeLoading={this.props.isInventoryLotHistoryTypeLoading}
                InventoryLotHistoryTypeData={this.props.InventoryLotHistoryTypeData}

                InventoryDeleteContainerPalletCaseUnitPostSuccessful={this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful}  
                isInventoryDeleteContainerPalletCaseUnitLoading={this.props.isInventoryDeleteContainerPalletCaseUnitLoading}
                inventoryLotDeleteContainerPalletCaseUnitPost={this.props.inventoryLotDeleteContainerPalletCaseUnitPost}



                InventoryPalletWarehouseLocationPostSuccessful={this.props.InventoryPalletWarehouseLocationPostSuccessful}
                inventoryPalletWarehouseLocationPost={this.props.inventoryPalletWarehouseLocationPost}

                isInventoryPalletWarehouseLoading2={this.props.isInventoryPalletWarehouseLoading2}
                InventoryPalletWarehouseData2={this.props.InventoryPalletWarehouseData2}

                inventoryPalletWarehouseLocationGet2={this.props.inventoryPalletWarehouseLocationGet2}


                inventoryCaseWarehouseLocationPost={this.props.inventoryCaseWarehouseLocationPost}
                InventoryCaseWarehouseLocationPostSuccessful={this.props.InventoryCaseWarehouseLocationPostSuccessful}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryPalletCaseSkuContainer));



