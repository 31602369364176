export const CtrlTwrShipmentListDataElements = [
    {
        dBKey: 'ProRef',
        displayName: 'PRO',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerRef',
        displayName: 'Customer Ref',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'Alpha2Code',
        displayName: 'Origin Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'DestAlpha2Code',
        displayName: 'Destination Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Vessel',
        displayName: 'Vessel',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 220
    },    
    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 220
    },    
    {
        dBKey: 'ETD',
        displayName: 'Planned Pickup',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'PlannedDepartureDate',
        displayName: 'Planned Departure Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 220
    },    
    {
        dBKey: 'DisplayPickupAppointmentTime',
        displayName: 'Pickup Appointment Time',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'EstimatedDeliveryDate',
        displayName: 'Estimated Delivery Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
    {
        dBKey: 'DisplayDeliveryAppointmentTime',
        displayName: 'Delivery Appointment Time',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'Milestone',
        displayName: 'Current Milestone',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },  
    {
        dBKey: 'MilestoneDT',
        displayName: 'Current Milestone DT',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
    {
        dBKey: 'ModeName',
        displayName: 'Current Mode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },    
    {
        dBKey: 'HrsSinceLastMilestone',
        displayName: 'Hours Since Last Milestone',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
    {
        dBKey: 'MissingMilestone',
        displayName: 'Milestone Alert',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },    
    {
        dBKey: 'LateShipment',
        displayName: 'Late Shipment',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },   


]


export default CtrlTwrShipmentListDataElements

