export const XdockTrinityDataElements = [
    {
        dBKey: 'ShipmentCreateDate',
        displayName: 'Shipment Create Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 170
    },
    {
        dBKey: 'ShipmentETD',
        displayName: 'Shipment ETD',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'Origin',
        displayName: 'Origin',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'HAWBDestination',
        displayName: 'HAWB Destination',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'HouseRef',
        displayName: 'House Ref',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 160
    },
    {
        dBKey: 'Master',
        displayName: 'Master',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 160
    },
    {
        dBKey: 'MawbStatusName',
        displayName: 'Airline Recovery Status',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'ConsigneeName',
        displayName: 'Consignee Name',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Cartons',
        displayName: 'Cartons',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Inner',
        displayName: 'Inner',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Weight',
        displayName: 'Weight',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Chargeable',
        displayName: 'Chargeable',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'Volume',
        displayName: 'Volume',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ActualPickup',
        displayName: 'Actual Pickup',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'ETALastDischarge',
        displayName: 'ETA Last Discharge',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'DCRequiredDate',
        displayName: 'DC Required Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'CarrierName',
        displayName: 'Carrier Name',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'FlightVoyage',
        displayName: 'Flight Voyage',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'Orders',
        displayName: 'Orders',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 450
    },

    // {
    //     dBKey: 'LastDischargePort',
    //     displayName: 'Last Discharge Port',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },
    // {
    //     dBKey: 'ETADischarge',
    //     displayName: 'ETA Discharge',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },
    // {
    //     dBKey: 'EstCartageDelivery',
    //     displayName: 'Est Cartage Delivery',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },
    // {
    //     dBKey: 'ActualCartageDelivery',
    //     displayName: 'Actual Cartage Delivery',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },
    // {
    //     dBKey: 'DeliverToAddress',
    //     displayName: 'Deliver To Address',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },

    // {
    //     dBKey: 'AgentNote',
    //     displayName: 'Agent Note',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },



    // {
    //     dBKey: 'ConsignorName',
    //     displayName: 'Consignor Name',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },

    // {
    //     dBKey: 'ConsignorState',
    //     displayName: 'Consignor State',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },

    // {
    //     dBKey: 'ConsignorCity',
    //     displayName: 'Consignor City',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },

    // {
    //     dBKey: 'ConsignorPostcode',
    //     displayName: 'Consignor Postcode',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },

    // {
    //     dBKey: 'ShipperAddress',
    //     displayName: 'Shipper Address',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },



]


export default XdockTrinityDataElements

