export const InventoryLoadPalletDataElements = [
    {
        dBKey: 'PalletBarcode',
        displayName: 'Pallet Barcode',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 220
    },
    {
        dBKey: 'PalletCaseQty',
        displayName: 'Case Qty',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'PalletSku',
        displayName: 'Case SKUs',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'PalletLotCode',
        displayName: 'Case Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },

    // // {
    // //     dBKey: 'UnitsReceived',
    // //     displayName: 'Units Received',
    // //     primaryCol: false,
    // //     mobileDisplay: false,
    // //     sortable: true,
    // //     colWidth: 140
    // // },
    // {
    //     dBKey: 'Units',
    //     displayName: 'Units In Whse',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 140
    // },
    // // {
    // //     dBKey: 'CasesReceived',
    // //     displayName: 'Cases Received',
    // //     primaryCol: false,
    // //     mobileDisplay: false,
    // //     sortable: true,
    // //     colWidth: 140
    // // },
    // {
    //     dBKey: 'Cases',
    //     displayName: 'Cases In Whse',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 140
    // },
    // // {
    // //     dBKey: 'PalletsReceived',
    // //     displayName: 'Pallets Received',
    // //     primaryCol: false,
    // //     mobileDisplay: false,
    // //     sortable: true,
    // //     colWidth: 140
    // // },
    // {
    //     dBKey: 'Pallets',
    //     displayName: 'Pallets In Whse',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 140
    // },
    // {
    //     dBKey: 'CustomerRef',
    //     displayName: 'Customer Ref',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 180
    // },
    // {
    //     dBKey: 'Project',
    //     displayName: 'Project',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 180
    // },



]


export default InventoryLoadPalletDataElements

