import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/componentsGeneral/AnimatedView/AnimatedView';

import AppConfigurationContainer from '../../../components/componentsUser/AppConfiguration/AppConfigurationContainer';

class appConfigurationView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {



          };
      }



    render() {

        return(
            <AnimatedView>

                <AppConfigurationContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection} organizationSelection={this.props.organizationSelection}/>
                
            </AnimatedView>

        );
    }
}

export default appConfigurationView;
