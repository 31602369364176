import React, {
    PureComponent
} from 'react';
import { SelectPicker, InputPicker, ControlLabel, Tooltip } from 'rsuite';
import { queryStringParse, isNumericReplaceNullWithEmpty, isNumeric, replaceNullWithEmpty, replaceEmptyWithNull } from '../../../../resources/logic/functions';


const styles = {
    // width: 300,
    marginBottom: 10
};

const tooltipSearch = (
    <Tooltip>
        Search
    </Tooltip>
);

const tooltipClear = (
    <Tooltip>
        Clear
    </Tooltip>
);



function CustomInputPicker({
    // isMobile,
    menuData,
    selectionValue,
    stateName,
    onChange,
    color,
    ...props
}) {

    const styles = {
        background: color,
        width: '100%'
    };


    return (

        <select style={styles} value={replaceNullWithEmpty(selectionValue)} onChange={(e) => { onChange(isNumeric(e.target.value), stateName) }}>
            <option value=''></option>
            {menuData.map((option) => (
                <option value={option.value}>{option.label}</option>
            ))}
        </select>


        // <select style={styles} value={isNumericReplaceNullWithEmpty(selectionValue)} onChange={(e) => { onChange(isNumeric(e.target.value), stateName) }}>
        //     <option value=''></option>
        //     {menuData.map((option) => (
        //         <option value={option.value}>{option.label}</option>
        //     ))}
        // </select>

    );
}


export default CustomInputPicker;
