import React, {
    PureComponent
} from 'react';

import { withRouter } from 'react-router-dom';
import { Loader, Button, Row, Col, Panel } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatDateForUrlSearch } from '../../../resources/logic/functions';
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import { DashboardSummaryDataElements } from '../../dataComponentsXdock/DashboardSummaryDataElements';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import DashboardSummaryExportDataElements from '../../dataComponentsXdock/DashboardSummaryExportDataElements';
import uniqueBy from '../../../resources/logic/functions';
import { uniqueArray } from '../../../resources/logic/functions';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "HouseID"

const mountRef = React.createRef();

class Dashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            // Table Check Box
            checkedKeys: [],


            portList: [],
            warehouseList: [],

            // //Search Values
            // searchHouseRef: '',
            // searchMaster: '',
            // searchOrderNo: '',
            // searchSealNo: '',
            // searchDepartDateStart: null,
            // searchDepartDateEnd: null,
            searchDestinationID: null,
            searchConsignee: null,
            searchPort: null,


            // @HouseRef varchar(50)  = null,
            // @Master varchar(50)  = null,
            // @OrderNo varchar(50) = null,
            // @DepartDateStart datetime = null,
            // @DepartDateEnd datetime = null,
            // @DestinationID int = null,


            isFilterShown: true,


        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        // //Search Values
        // this.onChangeSearchHouseRef = this.onChangeSearchHouseRef.bind(this);
        // this.onChangeSearchMaster = this.onChangeSearchMaster.bind(this);
        // this.onChangeSearchOrderNo = this.onChangeSearchOrderNo.bind(this);
        // this.onChangeSearchSealNo = this.onChangeSearchSealNo.bind(this);
        // this.onChangeSearchDepartDateStart = this.onChangeSearchDepartDateStart.bind(this);
        // this.onChangeSearchDepartDateEnd = this.onChangeSearchDepartDateEnd.bind(this);
        this.onChangeSearchDestinationID = this.onChangeSearchDestinationID.bind(this);
        this.onChangeSearchConsignee = this.onChangeSearchConsignee.bind(this);
        this.onChangeSearchPort = this.onChangeSearchPort.bind(this);

        this.onClickDetails = this.onClickDetails.bind(this);


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

    }



    componentDidMount() {
        this.getsessionStorageWarehouse()
        this.loadSortFromStorage()

        const values = queryString.parse(this.props.location.search)

        try {
            var paramDestination = queryStringParse(values.destinationid)
        }
        catch (e) {
            var paramDestination = null
        }
        this.onChangeSearchDestinationID(paramDestination)

        try {
            var paramConsignee = queryStringParse(values.consignee)
        }
        catch (e) {
            var paramConsignee = null
        }
        this.onChangeSearchConsignee(paramConsignee)

        try {
            var paramPort = queryStringParse(values.port)
        }
        catch (e) {
            var paramPort = null
        }
        this.onChangeSearchPort(paramPort)



    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }
    
    getsessionStorageWarehouse() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {

                let uniquePort = uniqueArray(warehouseMenuList, 'WarehousePort')

                var portArray = []

                for (let key in uniquePort) {
                    portArray.push({ label: uniquePort[key], value: uniquePort[key] })
                }

                this.setState({
                    portList: portArray,
                    warehouseList: warehouseMenuList
                })

            }
        }
        catch (e) { }
    }

    saveWarehouseSelectionTosessionStorage(warehouseToSave) {
        try {
            sessionStorage.setItem('currentWarehouse', warehouseToSave)
        }
        catch (e) {

        }

        // navigate to shipment view
        // this.props.history.push('/vas/shipment')
        window.location.reload();
    }

    saveCustomerSelectionTosessionStorage(customerToSave) {
        try {
            sessionStorage.setItem('currentCustomer', customerToSave)
        }
        catch (e) {

        }

        // navigate to shipment view
        // this.props.history.push('/vas/shipment')
        window.location.reload();
    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType){
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) {}       
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) {}
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) {}
    }
    loadSortFromStorage(){
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) {}
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) {}
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) {}
        if ( pathName === this.props.location.pathname){
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    //Search Functions
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        // var defaultWarehouse = null
        // try {
        //     if (this.props.UserWarehouseData.length == 1) {
        //         defaultWarehouse = this.props.UserWarehouseData[0]['value']
        //     }
        //     else {
        //         defaultWarehouse = null
        //     }
        // }
        // catch (e) {
        // }


        this.setState({
            // searchHouseRef: '',
            // searchMaster: '',
            // searchOrderNo: '',
            // searchSealNo: '',
            // searchDepartDateStart: null,
            // searchDepartDateEnd: null,
            searchDestinationID: null,
            searchConsignee: null,
            searchPort: null
            // warehouseSelection: defaultWarehouse

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        try {
            // var searchHouseRef = this.state.searchHouseRef
            // var searchMaster = this.state.searchMaster
            // var searchOrderNo = this.state.searchOrderNo
            // var searchDepartDateStart = this.state.searchDepartDateStart
            // var searchDepartDateEnd = this.state.searchDepartDateEnd
            var searchDestinationID = this.state.searchDestinationID

            this.props.history.push({
                search:
                    // 'houseref=' + searchHouseRef + '&' +
                    // 'master=' + searchMaster + '&' +
                    // 'orderno=' + searchOrderNo + '&' +
                    // 'departdatestart=' + formatDateForUrlSearch(searchDepartDateStart) + '&' +
                    // 'departdateend=' + formatDateForUrlSearch(searchDepartDateEnd) + '&' +
                    'consignee=' + this.state.searchConsignee + '&' +
                    'destinationid=' + searchDestinationID + '&' +
                    'port=' + this.state.searchPort
                // 'warehouseid=' + this.state.warehouseSelection
            });
        }
        catch (e) { }
    }

    // onChangeSearchMaster(e) {
    //     this.setState({
    //         searchMaster: e,
    //         searchHouseRef: '',
    //         searchOrderNo: '',
    //         searchSealNo: '',
    //     });
    // }
    // onChangeSearchHouseRef(e) {
    //     this.setState({
    //         searchHouseRef: e,
    //         searchMaster: '',
    //         searchOrderNo: '',
    //         searchSealNo: '',
    //     });
    // }
    // onChangeSearchOrderNo(e) {
    //     this.setState({
    //         searchOrderNo: e,
    //         searchHouseRef: '',
    //         searchMaster: '',
    //         searchSealNo: '',
    //     });
    // }
    // onChangeSearchSealNo(e) {
    //     this.setState({
    //         searchOrderNo: '',
    //         searchHouseRef: '',
    //         searchMaster: '',
    //         searchSealNo: e,
    //     });
    // }
    // onChangeSearchDepartDateStart(e) {
    //     this.setState({
    //         searchDepartDateStart: e
    //     });
    // }
    // onChangeSearchDepartDateEnd(e) {
    //     this.setState({
    //         searchDepartDateEnd: e
    //     });
    // }
    onChangeSearchConsignee(e) {
        this.setState({
            searchConsignee: e
        });
    }
    onChangeSearchDestinationID(e) {
        this.setState({
            searchDestinationID: e
        });
    }
    onChangeSearchPort(e) {
        this.setState({
            searchPort: e
        });
    }



    findArrayElementByTitle(array, key, value) {
        return array.find((element) => {
          return element[key] === value;
        })
      }


    //On Click Details
    onClickDetails(e, rowData, history) {


        

        try {
            history.push({
                pathname: 'warehouse',
                search:
                    'hawbstatusid=' + 1 + '&' +
                    'destination=' + rowData.DestinationID + '&' +
                    'consignee=' + rowData.ConsigneeID + '&' +
                    'warehouseport=' + rowData.WarehousePort
            });
        }
        catch (e) {
        }

        var matchWarehouse = this.findArrayElementByTitle(this.state.warehouseList, 'WarehouseName', rowData.WarehouseName )


        this.saveWarehouseSelectionTosessionStorage(matchWarehouse.value)
        // this.saveCustomerSelectionTosessionStorage()


    }










    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;




        let hideShowSearch;
        hideShowSearch = <Row  style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>







        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >

                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.HawbConsigneeData,
                                this.state.searchConsignee,
                                'Consignee',
                                this.onChangeSearchConsignee,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.HawbDestinationData,
                                this.state.searchDestinationID,
                                'Destination',
                                this.onChangeSearchDestinationID,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.state.portList,
                                this.state.searchPort,
                                'Port',
                                this.onChangeSearchPort,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>

                            <Button style={{ float: 'right' }} onClick={(e) => { this.saveWarehouseSelectionTosessionStorage('null') }}>All Warehouses</Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }


        let exportDiv;
        let summaryTableDiv;
        if (this.props.isDashboardSummaryLoading) {
            exportDiv = <div></div>

            summaryTableDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.DashboardSummaryData) !== 'undefined' && this.props.DashboardSummaryData != null) {
            exportDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Dashboard_' + currentDateTime}>
                                <ExcelSheet data={this.props.DashboardSummaryData} name="Dashboard">
                                    {
                                        DashboardSummaryExportDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            summaryTableDiv = <div style={{ marginBottom: '10px' }}>
                {/* <Panel bordered style={{ marginBottom: '10px' }} header={<Row>Summary</Row>}> */}

                {RTableGeneral(
                    tableIdCol,     //   keyColumn,
                    this.props.DashboardSummaryData,     //   tableData,
                    DashboardSummaryDataElements,       //   DataElements,
                    this.props.isDashboardSummaryLoading,        //   loading,

                    this.props.isMobile,        //   isMobile,
                    this.props.history,     //   history,

                    sortColumn,     //   sortColumn,
                    sortType,       //   sortType,
                    this.handleSortColumn,      //   handleSortColumn,

                    false,      //   checkColEntry,
                    null,       //   checkedKeys,
                    null,       //   handleCheckAll,
                    null,       //   handleCheck,

                    false,      //   boolSelectButton,
                    null,       //   onClickSelect,
                    null,       //   selectButtonText,
                    null,       //   selectHighlightRowKey,

                    true,       //   link1bool,
                    'Shipments',     //   link1text,
                    this.onClickDetails,     //   link1onClick,

                    false,       //   link2bool,
                    null,       //   link2text,
                    null  //   link2onClick

                )}

                {/* </Panel> */}
            </div>
        }
        else {
            exportDiv = <div></div>
            summaryTableDiv = <div></div>
        }


        return (
            <div>
                <h3>
                    Airfreight - Dashboard
                </h3>


                {hideShowSearch}
                {searchDiv}


                {exportDiv}
                {summaryTableDiv}





            </div>
        );

    }
}

export default withRouter(Dashboard);

