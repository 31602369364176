import React, {
    PureComponent
} from 'react';

import * as moment from 'moment';
import queryString from 'query-string';
import ReactExport from "react-export-excel";
import { withRouter } from 'react-router-dom';
import { Button, Col, Loader, Panel, Row } from 'rsuite';
import { appAPI } from '../../../app/appConfig';
import { formatDateForUrlSearch, queryStringParse } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import XdockTrinityDataElements from '../../dataComponentsXdock/XdockTrinityDataElements';



//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "TrinityDataID"

const mountRef = React.createRef();

class Reporting extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            // Table Check Box
            checkedKeys: [],

            //Search Values

            searchDepartDateStart: null,
            searchDepartDateEnd: null,


            isFilterShown: true,


        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
  
        this.onChangeSearchDepartDateStart = this.onChangeSearchDepartDateStart.bind(this);
        this.onChangeSearchDepartDateEnd = this.onChangeSearchDepartDateEnd.bind(this);

        // //Download
        // this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

    }




    componentDidMount() {
        this.loadSortFromStorage()
        const values = queryString.parse(this.props.location.search)



        try {
            var paramDepartDateStart = queryStringParse(values.startdate)
        }
        catch (e) {
            var paramDepartDateStart = null
        }
        this.onChangeSearchDepartDateStart(paramDepartDateStart)
        try {
            var paramDepartDateEnd = queryStringParse(values.enddate)
        }
        catch (e) {
            var paramDepartDateEnd = null
        }
        this.onChangeSearchDepartDateEnd(paramDepartDateEnd)


    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }
  




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType){
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) {}       
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) {}
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) {}
    }
    loadSortFromStorage(){
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) {}
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) {}
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) {}
        if ( pathName === this.props.location.pathname){
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    //Search Functions
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });


        this.setState({

            searchDepartDateStart: null,
            searchDepartDateEnd: null,


        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchHouseRef: '',
            searchMaster: '',
            searchOrderNo: '',
            searchSealNo: '',
        });

        try {
            // var searchHouseRef = this.state.searchHouseRef
            // var searchMaster = this.state.searchMaster
            // var searchOrderNo = this.state.searchOrderNo
            var searchDepartDateStart = this.state.searchDepartDateStart
            var searchDepartDateEnd = this.state.searchDepartDateEnd
            // var searchDestinationID = this.state.searchDestinationID

            this.props.history.push({
                search:
                    // 'houseref=' + searchHouseRef + '&' +
                    // 'master=' + searchMaster + '&' +
                    // 'orderno=' + searchOrderNo + '&' +
                    'startdate=' + formatDateForUrlSearch(searchDepartDateStart) + '&' +
                    'enddate=' + formatDateForUrlSearch(searchDepartDateEnd) 
                    // 'destinationid=' + searchDestinationID
                    // 'warehouseid=' + this.state.warehouseSelection
            });
        }
        catch (e) { }
    }


    onSearchClearOtherFilters(){
        this.setState({
            searchDepartDateStart: null,
            searchDepartDateEnd: null
            // searchDestinationID: null
        });
    }

    onChangeSearchDepartDateStart(e) {
        this.setState({
            searchDepartDateStart: e
        });
    }
    onChangeSearchDepartDateEnd(e) {
        this.setState({
            searchDepartDateEnd: e
        });
    }





    // //Download
    // onClickDownloadFileUpload(e, rowData, history) {
    //     try {
    //         this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.API.data.FileDownloadXDockS3)
    //     }
    //     catch (e) {
    //         window.alert("Error Downloading File.")
    //     }
    // }



    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;




        let hideShowSearch;
        hideShowSearch = <Row  style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>


        // let warehouseSelectionDiv;
        // if (this.props.isUserWarehouseLoading) {
        //     warehouseSelectionDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else {

        //     warehouseSelectionDiv =
        //         <Row>
        //             {RSelectPicker(
        //                 this.props.isMobile,
        //                 this.props.UserWarehouseData,
        //                 this.state.warehouseSelection,
        //                 'Warehouse',
        //                 this.onChangeWarehouseSelection,
        //                 this.props.history
        //             )}
        //         </Row>


        // }




        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                  
                    <Panel bordered style={{ marginBottom: '10px' }}>


                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'ETA Last Discharge Date',
                                this.onChangeSearchDepartDateStart,
                                this.onChangeSearchDepartDateEnd,
                                this.state.searchDepartDateStart,
                                this.state.searchDepartDateEnd
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }




        let loadDiv;
        let rTable;
        if (this.props.isXdockTrinityDataLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.XdockTrinityData) !== 'undefined' && this.props.XdockTrinityData != null) {

            rTable = RTableGeneral(
                "TrinityDataFileID",     //   keyColumn,
                this.props.XdockTrinityData,     //   tableData,
                XdockTrinityDataElements,       //   DataElements,
                this.props.isXdockTrinityDataLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   onClickEditMilestone,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                false,       //   link1bool,
                null,     //   link1text,
                null,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )

            loadDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Reporting_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.XdockTrinityData} name="Reporting">

                                    {
                                        XdockTrinityDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }


        return (
            <div>
                <h3>
                    Airfreight - Reporting
                </h3>


                {hideShowSearch}
                {searchDiv}


                {loadDiv}
                {rTable}


            </div>
        );

    }
}

export default withRouter(Reporting);

