import React from 'react'
import ReactDOM from 'react-dom'
import App from './AppContainer'
import './resources/style/style.css'
import './resources/style/dash-style.css'
// import "./resources/style/highlight/darkula.css";

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import "./resources/style/index.style.scss"


import 'font-awesome/css/font-awesome.min.css'

// import 'ionicons/dist/css/ionicons.css'

import dataService from './redux/services/services';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './redux/reducers'
import createSagaMiddleware from 'redux-saga'
import sagas from './redux/sagas'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
// import injectTapEventPlugininjectTapEventPlugin from 'react-tap-event-plugin'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { blue800, amber50 } from 'material-ui/styles/colors'

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'rsuite/dist/styles/rsuite-default.css'

// import './resources/style/highlight/darkula.css'
// import 'ionicons/dist/css/ionicons.css'





const muiTheme = getMuiTheme({
  palette: {
    accent1Color: amber50
  },
  tabs: {
    backgroundColor: blue800
  }
})


const sagaMiddleware = createSagaMiddleware()

let composeEnhancers = compose

if (process.env.NODE_ENV === 'development') {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension
  }
}

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware, dataService))
)

sagaMiddleware.run(sagas)


ReactDOM.render(
  <MuiThemeProvider muiTheme={muiTheme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
)