import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Button,
    ButtonToolbar,
    Form,
    FormGroup,
    ControlLabel,
    InputPicker,
    DatePicker,
    Loader,
    Row,
    InputGroup,
    Panel,
    Input,
    Col,
    InputNumber,
    ButtonGroup
} from 'rsuite';
import { lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import CtrlTwrShipmentLegsMilestonesEntryDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentLegsMilestonesEntryDataElements';
import CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements';
import RTableGeneralAdditionalData from '../../componentsGeneral/Rsuite/Table/RTableGeneralAdditionalData';


const styles = {
    marginTop: '5px',
    width: '100%'
};


const tableIdCol = "ShipmentLegMilestoneID"

// for milestones: 
// event code/description, 
// mode, 
// datetime, 
// select a location, 
// country/city/state/zip (not required though), 
// user, 
// stored at highest level (master, container, BOL)
// airpot iata codes

const mountRef = React.createRef();


const MilestoneEventEntryDrawer = ({ onChange, placement, confirm, close, masterLabel, onClickAddMilestone, onClickAddMissingMilestone, onClickShowAddLeg, onChangeNewLeg, onClickDelete, state, ...props }) => (

    state.showMilestoneEntryDrawer === false ?
        <div align="center">
        </div>

        :

        props.isCtrlTwrShipmentLegsMilestonesLoading ?

            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

            :


            <Drawer show={state.showMilestoneEntryDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
                <Drawer.Header>
                    <Drawer.Title style={{ marginBottom: '15px' }}>PRO: {masterLabel}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body> <div ref={mountRef}>

                    {
                        state.ShipMilestonesExistingDict.map(legItem => (

                            legItem.ModeID === 1 ? //Ocean
                                <Panel
                                    bordered
                                    style={{ marginBottom: '10px' }}
                                    header={
                                        <Row>
                                            <Col md={16} sm={24}>
                                                <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                            </Col>
                                            <Col md={8} sm={24}>
                                                <ButtonToolbar >
                                                    <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                </ButtonToolbar>
                                            </Col>
                                        </Row>
                                    }
                                >

                                    {RTableGeneral(
                                        tableIdCol,     //   keyColumn,
                                        props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                        CtrlTwrShipmentLegsMilestonesEntryDataElements,       //   DataElements,
                                        props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                        props.isMobile,        //   isMobile,
                                        props.history,     //   history,

                                        null,     //   sortColumn,
                                        null,       //   sortType,
                                        null,      //   handleSortColumn,

                                        null, // checkColEntry,
                                        null,       //   checkedKeys,
                                        null,       //   handleCheckAll,
                                        null,       //   handleCheck,

                                        null,      //   onClickAddMilestone,
                                        null,       //   onClickSelect,
                                        null,       //   selectButtonText,
                                        null,       //   selectHighlightRowKey,

                                        true,       //   link1bool,
                                        'Delete',     //   link1text,
                                        onClickDelete,     //   link1onClick,

                                        false,       //   link2bool,
                                        null,       //   link2text,
                                        null  //   link2onClick
                                    )
                                    }

                                    <Panel bordered style={{ marginBottom: '10px', marginTop: '10px' }} header={'Missing Required Milestones'}>
                                        {RTableGeneralAdditionalData(
                                            tableIdCol,     //   keyColumn,
                                            props.CtrlTwrMilestoneRefData.filter(x => [legItem.ModeID].includes(x.ModeID) && x.Required).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                            // props.CtrlTwrMilestoneRefData.filter(x => [legItem.LegID].includes(x.ModeID) && x.Required ).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                            // props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                            CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements,       //   DataElements,
                                            props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                            props.isMobile,        //   isMobile,
                                            props.history,     //   history,

                                            null,     //   sortColumn,
                                            null,       //   sortType,
                                            null,      //   handleSortColumn,

                                            null, // checkColEntry,
                                            null,       //   checkedKeys,
                                            null,       //   handleCheckAll,
                                            null,       //   handleCheck,

                                            true,      //   onClickAddMilestone,
                                            onClickAddMissingMilestone,       //   onClickSelect,
                                            'Add',       //   selectButtonText,
                                            null,       //   selectHighlightRowKey,
                                            legItem,    //additionalClickdata,

                                            false,       //   link1bool,
                                            null,     //   link1text,
                                            null,     //   link1onClick,

                                            false,       //   link2bool,
                                            null,       //   link2text,
                                            null  //   link2onClick
                                        )
                                        }
                                    </Panel>


                                </Panel>

                                :

                                legItem.ModeID === 2 ? //Air
                                    <Panel
                                        bordered
                                        style={{ marginBottom: '10px' }}
                                        header={
                                            <Row>
                                                <Col md={16} sm={24}>
                                                    <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                </Col>
                                                <Col md={8} sm={24}>
                                                    <ButtonToolbar >
                                                        <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                    </ButtonToolbar>
                                                </Col>
                                            </Row>
                                        }
                                    >
                                        {RTableGeneral(
                                            tableIdCol,     //   keyColumn,
                                            props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                            CtrlTwrShipmentLegsMilestonesEntryDataElements,       //   DataElements,
                                            props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                            props.isMobile,        //   isMobile,
                                            props.history,     //   history,

                                            null,     //   sortColumn,
                                            null,       //   sortType,
                                            null,      //   handleSortColumn,

                                            null, // checkColEntry,
                                            null,       //   checkedKeys,
                                            null,       //   handleCheckAll,
                                            null,       //   handleCheck,

                                            null,      //   onClickAddMilestone,
                                            null,       //   onClickSelect,
                                            null,       //   selectButtonText,
                                            null,       //   selectHighlightRowKey,

                                            true,       //   link1bool,
                                            'Delete',     //   link1text,
                                            onClickDelete,     //   link1onClick,

                                            false,       //   link2bool,
                                            null,       //   link2text,
                                            null  //   link2onClick
                                        )
                                        }


                                        <Panel bordered style={{ marginBottom: '10px', marginTop: '10px' }} header={'Missing Required Milestones'}>
                                            {RTableGeneralAdditionalData(
                                                tableIdCol,     //   keyColumn,
                                                props.CtrlTwrMilestoneRefData.filter(x => [legItem.ModeID].includes(x.ModeID) && x.Required).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                                CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements,       //   DataElements,
                                                props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                props.isMobile,        //   isMobile,
                                                props.history,     //   history,

                                                null,     //   sortColumn,
                                                null,       //   sortType,
                                                null,      //   handleSortColumn,

                                                null, // checkColEntry,
                                                null,       //   checkedKeys,
                                                null,       //   handleCheckAll,
                                                null,       //   handleCheck,

                                                true,      //   onClickAddMilestone,
                                                onClickAddMissingMilestone,       //   onClickSelect,
                                                'Add',       //   selectButtonText,
                                                null,       //   selectHighlightRowKey,
                                                legItem,    //additionalClickdata,

                                                false,       //   link1bool,
                                                null,     //   link1text,
                                                null,     //   link1onClick,

                                                false,       //   link2bool,
                                                null,       //   link2text,
                                                null  //   link2onClick
                                            )
                                            }
                                        </Panel>

                                    </Panel>

                                    :

                                    legItem.ModeID === 3 ? //Truck Load
                                        <Panel
                                            bordered
                                            style={{ marginBottom: '10px' }}
                                            header={
                                                <Row>
                                                    <Col md={16} sm={24}>
                                                        <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                    </Col>
                                                    <Col md={8} sm={24}>
                                                        <ButtonToolbar >
                                                            <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                        </ButtonToolbar>
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            {RTableGeneral(
                                                tableIdCol,     //   keyColumn,
                                                props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                                CtrlTwrShipmentLegsMilestonesEntryDataElements,       //   DataElements,
                                                props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                props.isMobile,        //   isMobile,
                                                props.history,     //   history,

                                                null,     //   sortColumn,
                                                null,       //   sortType,
                                                null,      //   handleSortColumn,

                                                null, // checkColEntry,
                                                null,       //   checkedKeys,
                                                null,       //   handleCheckAll,
                                                null,       //   handleCheck,

                                                null,      //   onClickAddMilestone,
                                                null,       //   onClickSelect,
                                                null,       //   selectButtonText,
                                                null,       //   selectHighlightRowKey,

                                                true,       //   link1bool,
                                                'Delete',     //   link1text,
                                                onClickDelete,     //   link1onClick,

                                                false,       //   link2bool,
                                                null,       //   link2text,
                                                null  //   link2onClick
                                            )
                                            }

                                            <Panel bordered style={{ marginBottom: '10px', marginTop: '10px' }} header={'Missing Required Milestones'}>
                                                {RTableGeneralAdditionalData(
                                                    tableIdCol,     //   keyColumn,
                                                    props.CtrlTwrMilestoneRefData.filter(x => [legItem.ModeID].includes(x.ModeID) && x.Required).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                                    CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements,       //   DataElements,
                                                    props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                    props.isMobile,        //   isMobile,
                                                    props.history,     //   history,

                                                    null,     //   sortColumn,
                                                    null,       //   sortType,
                                                    null,      //   handleSortColumn,

                                                    null, // checkColEntry,
                                                    null,       //   checkedKeys,
                                                    null,       //   handleCheckAll,
                                                    null,       //   handleCheck,

                                                    true,      //   onClickAddMilestone,
                                                    onClickAddMissingMilestone,       //   onClickSelect,
                                                    'Add',       //   selectButtonText,
                                                    null,       //   selectHighlightRowKey,
                                                    legItem,    //additionalClickdata,

                                                    false,       //   link1bool,
                                                    null,     //   link1text,
                                                    null,     //   link1onClick,

                                                    false,       //   link2bool,
                                                    null,       //   link2text,
                                                    null  //   link2onClick
                                                )
                                                }
                                            </Panel>


                                        </Panel>

                                        :

                                        legItem.ModeID === 4 ? //Less Than Truck Load

                                            <Panel
                                                bordered
                                                style={{ marginBottom: '10px' }}
                                                header={
                                                    <Row>
                                                        <Col md={16} sm={24}>
                                                            <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                        </Col>
                                                        <Col md={8} sm={24}>
                                                            <ButtonToolbar >
                                                                <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Row>
                                                }
                                            >
                                                {RTableGeneral(
                                                    tableIdCol,     //   keyColumn,
                                                    props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                                    CtrlTwrShipmentLegsMilestonesEntryDataElements,       //   DataElements,
                                                    props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                    props.isMobile,        //   isMobile,
                                                    props.history,     //   history,

                                                    null,     //   sortColumn,
                                                    null,       //   sortType,
                                                    null,      //   handleSortColumn,

                                                    null, // checkColEntry,
                                                    null,       //   checkedKeys,
                                                    null,       //   handleCheckAll,
                                                    null,       //   handleCheck,

                                                    null,      //   onClickAddMilestone,
                                                    null,       //   onClickSelect,
                                                    null,       //   selectButtonText,
                                                    null,       //   selectHighlightRowKey,

                                                    true,       //   link1bool,
                                                    'Delete',     //   link1text,
                                                    onClickDelete,     //   link1onClick,

                                                    false,       //   link2bool,
                                                    null,       //   link2text,
                                                    null  //   link2onClick
                                                )
                                                }


                                                <Panel bordered style={{ marginBottom: '10px', marginTop: '10px' }} header={'Missing Required Milestones'}>
                                                    {RTableGeneralAdditionalData(
                                                        tableIdCol,     //   keyColumn,
                                                        props.CtrlTwrMilestoneRefData.filter(x => [legItem.ModeID].includes(x.ModeID) && x.Required).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                                        CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements,       //   DataElements,
                                                        props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                        props.isMobile,        //   isMobile,
                                                        props.history,     //   history,

                                                        null,     //   sortColumn,
                                                        null,       //   sortType,
                                                        null,      //   handleSortColumn,

                                                        null, // checkColEntry,
                                                        null,       //   checkedKeys,
                                                        null,       //   handleCheckAll,
                                                        null,       //   handleCheck,

                                                        true,      //   onClickAddMilestone,
                                                        onClickAddMissingMilestone,       //   onClickSelect,
                                                        'Add',       //   selectButtonText,
                                                        null,       //   selectHighlightRowKey,
                                                        legItem,    //additionalClickdata,

                                                        false,       //   link1bool,
                                                        null,     //   link1text,
                                                        null,     //   link1onClick,

                                                        false,       //   link2bool,
                                                        null,       //   link2text,
                                                        null  //   link2onClick
                                                    )
                                                    }
                                                </Panel>

                                            </Panel>

                                            :

                                            legItem.ModeID === 5 ? //Rail

                                                <Panel
                                                    bordered
                                                    style={{ marginBottom: '10px' }}
                                                    header={
                                                        <Row>
                                                            <Col md={16} sm={24}>
                                                                <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                            </Col>
                                                            <Col md={8} sm={24}>
                                                                <ButtonToolbar >
                                                                    <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                                </ButtonToolbar>
                                                            </Col>
                                                        </Row>
                                                    }
                                                >
                                                    {RTableGeneral(
                                                        tableIdCol,     //   keyColumn,
                                                        props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                                        CtrlTwrShipmentLegsMilestonesEntryDataElements,       //   DataElements,
                                                        props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                        props.isMobile,        //   isMobile,
                                                        props.history,     //   history,

                                                        null,     //   sortColumn,
                                                        null,       //   sortType,
                                                        null,      //   handleSortColumn,

                                                        null, // checkColEntry,
                                                        null,       //   checkedKeys,
                                                        null,       //   handleCheckAll,
                                                        null,       //   handleCheck,

                                                        null,      //   onClickAddMilestone,
                                                        null,       //   onClickSelect,
                                                        null,       //   selectButtonText,
                                                        null,       //   selectHighlightRowKey,

                                                        true,       //   link1bool,
                                                        'Delete',     //   link1text,
                                                        onClickDelete,     //   link1onClick,

                                                        false,       //   link2bool,
                                                        null,       //   link2text,
                                                        null  //   link2onClick
                                                    )
                                                    }


                                                    <Panel bordered style={{ marginBottom: '10px', marginTop: '10px' }} header={'Missing Required Milestones'}>
                                                        {RTableGeneralAdditionalData(
                                                            tableIdCol,     //   keyColumn,
                                                            props.CtrlTwrMilestoneRefData.filter(x => [legItem.ModeID].includes(x.ModeID) && x.Required).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                                            CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements,       //   DataElements,
                                                            props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                            props.isMobile,        //   isMobile,
                                                            props.history,     //   history,

                                                            null,     //   sortColumn,
                                                            null,       //   sortType,
                                                            null,      //   handleSortColumn,

                                                            null, // checkColEntry,
                                                            null,       //   checkedKeys,
                                                            null,       //   handleCheckAll,
                                                            null,       //   handleCheck,

                                                            true,      //   onClickAddMilestone,
                                                            onClickAddMissingMilestone,       //   onClickSelect,
                                                            'Add',       //   selectButtonText,
                                                            null,       //   selectHighlightRowKey,
                                                            legItem,    //additionalClickdata,

                                                            false,       //   link1bool,
                                                            null,     //   link1text,
                                                            null,     //   link1onClick,

                                                            false,       //   link2bool,
                                                            null,       //   link2text,
                                                            null  //   link2onClick
                                                        )
                                                        }
                                                    </Panel>

                                                </Panel>

                                                :

                                                legItem.ModeID === 6 ? //Brokerage
                                                    <Panel
                                                        bordered
                                                        style={{ marginBottom: '10px' }}
                                                        header={
                                                            <Row>
                                                                <Col md={16} sm={24}>
                                                                    <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                                </Col>
                                                                <Col md={8} sm={24}>
                                                                    <ButtonToolbar >
                                                                        <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                                    </ButtonToolbar>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    >
                                                        {RTableGeneral(
                                                            tableIdCol,     //   keyColumn,
                                                            props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                                            CtrlTwrShipmentLegsMilestonesEntryDataElements,       //   DataElements,
                                                            props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                            props.isMobile,        //   isMobile,
                                                            props.history,     //   history,

                                                            null,     //   sortColumn,
                                                            null,       //   sortType,
                                                            null,      //   handleSortColumn,

                                                            null, // checkColEntry,
                                                            null,       //   checkedKeys,
                                                            null,       //   handleCheckAll,
                                                            null,       //   handleCheck,

                                                            null,      //   onClickAddMilestone,
                                                            null,       //   onClickSelect,
                                                            null,       //   selectButtonText,
                                                            null,       //   selectHighlightRowKey,

                                                            true,       //   link1bool,
                                                            'Delete',     //   link1text,
                                                            onClickDelete,     //   link1onClick,

                                                            false,       //   link2bool,
                                                            null,       //   link2text,
                                                            null  //   link2onClick
                                                        )
                                                        }


                                                        <Panel bordered style={{ marginBottom: '10px', marginTop: '10px' }} header={'Missing Required Milestones'}>
                                                            {RTableGeneralAdditionalData(
                                                                tableIdCol,     //   keyColumn,
                                                                props.CtrlTwrMilestoneRefData.filter(x => [legItem.ModeID].includes(x.ModeID) && x.Required).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                                                CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements,       //   DataElements,
                                                                props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                                props.isMobile,        //   isMobile,
                                                                props.history,     //   history,

                                                                null,     //   sortColumn,
                                                                null,       //   sortType,
                                                                null,      //   handleSortColumn,

                                                                null, // checkColEntry,
                                                                null,       //   checkedKeys,
                                                                null,       //   handleCheckAll,
                                                                null,       //   handleCheck,

                                                                true,      //   onClickAddMilestone,
                                                                onClickAddMissingMilestone,       //   onClickSelect,
                                                                'Add',       //   selectButtonText,
                                                                null,       //   selectHighlightRowKey,
                                                                legItem,    //additionalClickdata,

                                                                false,       //   link1bool,
                                                                null,     //   link1text,
                                                                null,     //   link1onClick,

                                                                false,       //   link2bool,
                                                                null,       //   link2text,
                                                                null  //   link2onClick
                                                            )
                                                            }
                                                        </Panel>

                                                    </Panel>

                                                    :


                                                    legItem.ModeID === 7 ? //Drayage
                                                        <Panel
                                                            bordered
                                                            style={{ marginBottom: '10px' }}
                                                            header={
                                                                <Row>
                                                                    <Col md={16} sm={24}>
                                                                        <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                                    </Col>
                                                                    <Col md={8} sm={24}>
                                                                        <ButtonToolbar >
                                                                            <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                                        </ButtonToolbar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        >

                                                            {RTableGeneral(
                                                                tableIdCol,     //   keyColumn,
                                                                props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)),     //   tableData,
                                                                CtrlTwrShipmentLegsMilestonesEntryDataElements,       //   DataElements,
                                                                props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                                props.isMobile,        //   isMobile,
                                                                props.history,     //   history,

                                                                null,     //   sortColumn,
                                                                null,       //   sortType,
                                                                null,      //   handleSortColumn,

                                                                null, // checkColEntry,
                                                                null,       //   checkedKeys,
                                                                null,       //   handleCheckAll,
                                                                null,       //   handleCheck,

                                                                null,      //   onClickAddMilestone,
                                                                null,       //   onClickSelect,
                                                                null,       //   selectButtonText,
                                                                null,       //   selectHighlightRowKey,

                                                                true,       //   link1bool,
                                                                'Delete',     //   link1text,
                                                                onClickDelete,     //   link1onClick,

                                                                false,       //   link2bool,
                                                                null,       //   link2text,
                                                                null  //   link2onClick
                                                            )
                                                            }


                                                            <Panel bordered style={{ marginBottom: '10px', marginTop: '10px' }} header={'Missing Required Milestones'}>
                                                                {RTableGeneralAdditionalData(
                                                                    tableIdCol,     //   keyColumn,
                                                                    props.CtrlTwrMilestoneRefData.filter(x => [legItem.ModeID].includes(x.ModeID) && x.Required).filter(x => !props.CtrlTwrShipmentLegsMilestonesData.filter(x => [legItem.LegID].includes(x.LegID)).map(item => item.MilestoneID).includes(x.value)),
                                                                    CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements,       //   DataElements,
                                                                    props.isCtrlTwrShipmentLegsMilestonesLoading,        //   loading,

                                                                    props.isMobile,        //   isMobile,
                                                                    props.history,     //   history,

                                                                    null,     //   sortColumn,
                                                                    null,       //   sortType,
                                                                    null,      //   handleSortColumn,

                                                                    null, // checkColEntry,
                                                                    null,       //   checkedKeys,
                                                                    null,       //   handleCheckAll,
                                                                    null,       //   handleCheck,

                                                                    true,      //   onClickAddMilestone,
                                                                    onClickAddMissingMilestone,       //   onClickSelect,
                                                                    'Add',       //   selectButtonText,
                                                                    null,       //   selectHighlightRowKey,
                                                                    legItem,    //additionalClickdata,

                                                                    false,       //   link1bool,
                                                                    null,     //   link1text,
                                                                    null,     //   link1onClick,

                                                                    false,       //   link2bool,
                                                                    null,       //   link2text,
                                                                    null  //   link2onClick
                                                                )
                                                                }
                                                            </Panel>


                                                        </Panel>
                                                        :

                                                        <Panel
                                                            bordered
                                                            style={{ marginBottom: '10px' }}
                                                            header={
                                                                <Row>
                                                                    <Col md={16} sm={24}>
                                                                        <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                                    </Col>
                                                                    <Col md={8} sm={24}>
                                                                        <ButtonToolbar >
                                                                            <Button style={{ float: 'right' }} appearance="primary" onClick={(e) => { onClickAddMilestone(e, legItem["LegID"], legItem["ModeID"], legItem["ModeName"]) }} >Add Milestone</Button>
                                                                        </ButtonToolbar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        >
                                                        </Panel>



                        ))
                    }


                    <FormGroup>

                        <ButtonToolbar >
                            {/* <Button onClick={confirm} appearance="primary">Save</Button> */}
                            <Button onClick={close} appearance="default">Close</Button>


                        </ButtonToolbar>

                    </FormGroup>


                </div> </Drawer.Body>
                <Drawer.Footer>

                    <br />

                </Drawer.Footer>


            </Drawer >

);



export default MilestoneEventEntryDrawer;


