import React from 'react';
import { Row, Col, Panel, Button } from 'rsuite'; // Assuming you're using rsuite
import Loader from 'react-loader-spinner'; // Assuming you're using react-loader-spinner




function CaseDetailsDiv({ isInventoryCaseWarehouseLoading, InventoryCaseWarehouseData }) {


    if (isInventoryCaseWarehouseLoading) {
        return (
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        );
    }

    if (typeof (InventoryCaseWarehouseData[0]) !== 'undefined' && InventoryCaseWarehouseData != null) {
        let pageHeader;
        try {
            var skuName = InventoryCaseWarehouseData[0].CaseBarcode.toString()
            pageHeader = <div style={{ marginBottom: '15px' }}>  {'Case: ' + skuName} </div>
        }
        catch (e) {

        }




        return (

            <div>
                <h3>
                    {pageHeader}
                </h3>
                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} header={
                            <div style={{ float: 'right' }}>
                                {/* <Button style={{ float: 'right' }} onClick={(e) => { this.movePalletModalShown(e) }} >Move Pallet Location</Button> */}
                                {/* {closePalletButton} */}
                            </div>
                        }>
                                Warehouse Location: <strong>{InventoryCaseWarehouseData[0].WarehouseLocationName}</strong>
                                <br />
                                Warehouse: <strong>{InventoryCaseWarehouseData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{InventoryCaseWarehouseData[0].CustomerName}</strong>
                                <br />
                                Case Barcode: <strong>{InventoryCaseWarehouseData[0].CaseBarcode}</strong>
                                <br />
                                Case SKU: <strong>{InventoryCaseWarehouseData[0].CaseSKU}</strong>
                                <br />
                                Case Status: <strong>{InventoryCaseWarehouseData[0].CaseStatusName}</strong>
                                <br />
                                Lot Code: <strong>{InventoryCaseWarehouseData[0].LotCode}</strong>
                                <br />
                                Serial Number: <strong>{InventoryCaseWarehouseData[0].SerialNumber}</strong>
                                <br />
                                Pallet Barcode: <strong>{InventoryCaseWarehouseData[0].PalletBarcode}</strong>
                                <br />
                        </Panel>
                    </Col>
                </Row>




            </div>
        );
    }

    return <div></div>;
}

export default CaseDetailsDiv;
