import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import { ContainerDataElements } from '../../dataComponentsTrans/ContainerDataElements';
import RTableContainer from '../../componentsGeneral/Rsuite/Table/RTableContainer';
import { Row, Col, Panel, Loader, Button, ControlLabel, InputGroup, Input } from 'rsuite';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatStringForAPI, formatDateForUrlSearch, formatNumberForAPI, convertNanToZero, isNumeric, formatDateTimeForUrlSearch, formatTrueFalseToBit, formatAllTextNumbersSymbolsForAPI } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { mawbStatus, colorColumnsValuesDropDownFilter } from '../../../app/appConfig';
// import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
// import RSearchInput from '../../componentsGeneral/Rsuite/SearchInput/RSearchInput';
import AddNewContainerDrawer from './AddNewContainerDrawer';
// import ContainerEvolveDataDrawer from './ContainerEvolveDataDrawer';
import ReceiveContainerDrawer from './ReceiveContainerDrawer';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
// import AddNewHawbModal from './AddNewHawbModal';
// import AirlineRecoveryDrawer from '../ContainerDetails/AirlineRecoveryDrawer';
// import { lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';
import AddToContainerDrawer from './AddToContainerDrawer';



//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "ContainerID"

const mountRef = React.createRef();

class Shipment extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchContainer: '',
            searchMBOL: '',
            searchHBOL: '',
            searchPO: '',
            searchStatus: null,

            // searchCarrier: null,
            // searchMinETADischargeStart: null,
            // searchMinETADischargeEnd: null,

            // searchPlannedPickupStart: null,
            // searchPlannedPickupEnd: null,
            // serachAssignedDriver: '',
            // searchMawbColorCode: null,

            isFilterShown: true,

            //Add New Container Values
            unsavedNewContainerChanges: false,
            Container: null,
            Notes: null,
            LclFcl: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null,

            //Add To Container Values
            EditContainerID: null,
            MBOL: null,
            HBOL: null,
            PO: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            addNewText: null,

            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,

            selectedPoCases: null,
            selectedPoPallets: null,
            selectedPoNotes: null,
            selectedPoCustomerRef: null,
            selectedPoProject: null,

            // //Add Evolve Data Values
            // showEvolveDataDrawer: false,
            // EvolveDataContainerID: null,
            // EvolveDataContainer: null,
            // EvolveDataNo: null,
            // EvolveDataPMC: null,
            // EvolveDataPickupBy: null,
            // EvolveDataTrips: null,
            // EvolveDataRecovered: null,
            // EvolveDataArrivalTime: null,
            // EvolveDataLFD: null,
            // EvolveDataArrivalAtWarehouse: null,
            // EvolveDataDeliveryTime: null,
            // EvolveDataNotes: null,

            //Receive Container Drawer
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            showReceiveDrawer: false,
            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,


            // //Airline Recovery Drawer
            // AirlineRecoveryDict: {

            //     AirlineRecoveryDriverAssigned: null,
            //     AirlineRecoveryNote: null,


            //     AirlineRecoveryCartonsRecovered: null,
            //     AirlineRecoveryPickedUpBy: null,
            //     AirlineRecoveryCarrier: null,


            // },
            // AirlineRecoveryPlannedPickupDT: null,
            // AirlineRecoveryPickupDate: null,
            // AirlineRecoveryLFD: null,
            // AirlineRecoveryPMC: null,

            // unsavedAirlineRecoveryChanges: false,
            // showAirlineRecoveryDrawer: false,
            // AirlineRecoveryFreightRecovered: null,
            // AirlineRecoveryTripMade: null,

            // //Add new HAWB modal
            // addNewModalShown: false,

            // newModalHouseRef: null,
            // newModalConsigneeID: null,
            // // newModalNotes: null,
            // // newModalOrigin: null,
            // // newModalHAWBDestination: null,
            // // newModalLastDischargePort: null,
            // // newModalDeliverToAddress: null,
            // // newModalOrders: null,
            // // newModalShipmentETD: null,
            // // newModalEstCartageDelivery: null,
            // // newModalETADischarge: null,
            // // newModalActualCartageDelivery: null,
            // newModalCartons: null,
            // // newModalNewPallets: null,
            // // newModalWeight: null,
            // // newModalChargeable: null,
            // // newModalVolume: null



            // userColorArray: []

        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Add new drawer
        this.closeAddNewDrawer = this.closeAddNewDrawer.bind(this);
        this.onClickConfirmAddNewContainer = this.onClickConfirmAddNewContainer.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)


        //Add To Container Drawer
        this.closeAddToContainerDrawer = this.closeAddToContainerDrawer.bind(this);
        this.onClickConfirmAddToContainer = this.onClickConfirmAddToContainer.bind(this);
        this.onClickTree = this.onClickTree.bind(this);
        this.onClickDeleteMbol = this.onClickDeleteMbol.bind(this);
        this.onClickDeleteHbol = this.onClickDeleteHbol.bind(this);
        this.onClickDeletePo = this.onClickDeletePo.bind(this);

        this.onChangeCases = this.onChangeCases.bind(this);
        this.onChangePallets = this.onChangePallets.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this);
        this.onChangeProject = this.onChangeProject.bind(this);

        this.onClickTableLinkEditContainer = this.onClickTableLinkEditContainer.bind(this);

        this.setNewContainerID = this.setNewContainerID.bind(this)

        this.setNewMbolTreeSelection = this.setNewMbolTreeSelection.bind(this)
        this.setNewHbolTreeSelection = this.setNewHbolTreeSelection.bind(this)

        //Receive drawer
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);

        this.onClickReceive = this.onClickReceive.bind(this);
        this.onClickAddMissing = this.onClickAddMissing.bind(this);
        this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
        this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);

        this.onClickAddLocation = this.onClickAddLocation.bind(this);

        this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);

        //Search Values
        this.onChangeSearchContainer = this.onChangeSearchContainer.bind(this);
        this.onChangeSearchMBOL = this.onChangeSearchMBOL.bind(this);
        this.onChangeSearchHBOL = this.onChangeSearchHBOL.bind(this);
        this.onChangeSearchPO = this.onChangeSearchPO.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);


    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()

    }
    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainer = queryStringParse(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        if (paramContainer != null) {
            this.onChangeSearchContainer(paramContainer)
        }

        try {
            var paramMbol = queryStringParse(values.mbol)
        }
        catch (e) {
            var paramMbol = null
        }
        if (paramMbol != null) {
            this.onChangeSearchMBOL(paramMbol)
        }

        try {
            var paramHbol = queryStringParse(values.hbol)
        }
        catch (e) {
            var paramHbol = null
        }
        if (paramHbol != null) {
            this.onChangeSearchHBOL(paramHbol)
        }

        try {
            var paramPO = queryStringParse(values.po)
        }
        catch (e) {
            var paramPO = null
        }
        if (paramPO != null) {
            this.onChangeSearchPO(paramPO)
        }

        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

    }


    setNewContainerID(newID) {
        this.setState({
            EditContainerID: newID
        });
    }


    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.MawbEvolveDataSaveSuccessful !== this.props.MawbEvolveDataSaveSuccessful && this.props.MawbEvolveDataSaveSuccessful) {
        //     this.closeEvolveDataDrawer()
        // }        

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


        if (prevProps.ShipmentReceiveData !== this.props.ShipmentReceiveData && this.props.ShipmentReceiveData.length > 0) {
            this.preloadReceiveState()
        }


        if (prevProps.isContainerLoading === true && this.props.isContainerLoading === false && this.state.searchContainer !== '' && this.props.ContainerData.length == 0) {
            this.addMawb(this.state.searchContainer)
        }
        if (prevProps.ContainerPostSuccessful !== this.props.ContainerPostSuccessful && this.props.ContainerPostSuccessful && this.props.isContainerLoading === false) {
            // try {
            this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.NewContainerId, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
            this.setNewContainerID(this.props.NewContainerId)
            // }
            // catch (e) { }

            this.setStateCloseAddNewDrawer()

            // if (window.confirm('Build new container?')) {
            this.onClickAddToContainer()
            // }

            this.setInitialTreeSelection(this.props.NewContainerId, this.state.Container)
        }

        // new container id needs to be selected row not row zero
        if (prevProps.MbolPostSuccessful !== this.props.MbolPostSuccessful && this.props.MbolPostSuccessful) {
            try {
                this.setNewMbolTreeSelection(this.props.NewMbolId, this.state.addNewText)
            }
            catch (e) { }

            setTimeout(() => {
                try {
                    this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
                }
                catch (e) { }
            }, 100);


        }
        if (prevProps.HbolPostSuccessful !== this.props.HbolPostSuccessful && this.props.HbolPostSuccessful) {
            try {
                this.setNewHbolTreeSelection(this.props.NewHbolId, this.state.addNewText)
            }
            catch (e) { }

            setTimeout(() => {
                try {
                    this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
                }
                catch (e) { }
            }, 100);


        }
        if (prevProps.PoPostSuccessful !== this.props.PoPostSuccessful && this.props.PoPostSuccessful) {
            try {
                this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
                this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
                this.props.transProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

            }
            catch (e) { }
        }




    }


    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchContainer: '',
            searchMBOL: '',
            searchHBOL: '',
            searchPO: '',
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchContainer: '',
            searchMBOL: '',
            searchHBOL: '',
            searchPO: '',
        });

        var sDT = null
        var eDT = null


        if (this.state.searchPlannedPickupStart === null) {
            sDT = null
        }
        else {
            sDT = "'" + moment(this.state.searchPlannedPickupStart).format('YYYY-MM-DD') + "'"
        }
        if (this.state.searchPlannedPickupEnd === null) {
            eDT = null
        }
        else {
            eDT = "'" + moment(this.state.searchPlannedPickupEnd).format('YYYY-MM-DD') + "'"
        }

        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                this.props.history.push({
                    search:
                        'containerstatus=' + searchStatus

                });
            }
            catch (e) { }
        }
    }


    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchPlannedPickupStart: null,
            searchPlannedPickupEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null
        });

    }

    onChangeSearchContainer(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchContainer: e,
            searchPO: '',
            searchMBOL: '',
            searchHBOL: ''
        });
    }

    onChangeSearchMBOL(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchMBOL: e,
            searchPO: '',
            searchContainer: '',
            searchHBOL: ''
        });
    }
    onChangeSearchHBOL(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchHBOL: e,
            searchPO: '',
            searchContainer: '',
            searchMBOL: ''
        });
    }
    onChangeSearchPO(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchPO: e,
            searchContainer: '',
            searchMBOL: '',
            searchHBOL: ''
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }

    onChangeSearchMinETADischargeStart(e) {
        this.setState({
            searchMinETADischargeStart: e
        });
    }
    onChangeSearchMinETADischargeEnd(e) {
        this.setState({
            searchMinETADischargeEnd: e
        });
    }

    onChangeSearchPlannedPickupStart(e) {
        this.setState({
            searchPlannedPickupStart: e
        });
    }
    onChangeSearchPlannedPickupEnd(e) {
        this.setState({
            searchPlannedPickupEnd: e
        });
    }
    onChangeSearchAssigedDriver(e) {
        this.setState({
            serachAssignedDriver: e
        });
    }
    onChangeSearchMawbColorCode(e) {
        this.setState({
            searchMawbColorCode: e
        });
    }

    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'containerdetails',
                search: 'containerid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    onClick(e) {
        window.alert('Click')
    }



    //Evolve Data drawer
    onClickOpenEvolveDataDrawer(rowData) {
        this.setState({
            evolveDataRowData: rowData,
            showEvolveDataDrawer: true,
            // EvolveDataContainerID: rowData.ContainerID,

            // //Add Evolve Data Values
            EvolveDataContainerID: rowData.ContainerID,
            EvolveDataContainer: rowData.Container,
            EvolveDataNo: rowData.No,
            EvolveDataPMC: rowData.PMC,
            EvolveDataPickupBy: rowData.PickupBy,
            EvolveDataTrips: rowData.Trips,
            EvolveDataRecovered: rowData.AirlineRecovered,
            EvolveDataArrivalTime: rowData.ArrivalTime,
            EvolveDataLFD: rowData.LFD,
            EvolveDataArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
            EvolveDataDeliveryTime: rowData.DeliveryTime,
            EvolveDataNotes: rowData.Notes
        });
    }
    onChangeEvolveData = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };
    onClickConfirmEvolveData(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            // if (window.confirm('Save New Data?')) {
                this.props.mawbEvolveDataEntryPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(this.state.EvolveDataContainerID),
                    formatStringForAPI(this.state.EvolveDataContainer),

                    formatNumberForAPI(this.state.EvolveDataNo),
                    formatNumberForAPI(this.state.EvolveDataPMC),
                    formatStringForAPI(this.state.EvolveDataPickupBy),
                    formatNumberForAPI(this.state.EvolveDataTrips),
                    formatDateForUrlSearch(this.state.EvolveDataArrivalTime),
                    formatDateForUrlSearch(this.state.EvolveDataLFD),
                    formatDateForUrlSearch(this.state.EvolveDataArrivalAtWarehouse),
                    formatDateForUrlSearch(this.state.EvolveDataDeliveryTime),
                    formatAllTextNumbersSymbolsForAPI(this.state.EvolveDataNotes),
                    formatNumberForAPI(this.state.EvolveDataRecovered),
                    this.props.warehouseSelection,
                    this.props.customerSelection

                )
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeEvolveDataDrawer() {
        this.setState({
            showEvolveDataDrawer: false,

            EvolveDataContainerID: null,
            EvolveDataContainer: null,
            EvolveDataNo: null,
            EvolveDataPMC: null,
            EvolveDataPickupBy: null,
            EvolveDataTrips: null,
            EvolveDataRecovered: null,
            EvolveDataArrivalTime: null,
            EvolveDataLFD: null,
            EvolveDataArrivalAtWarehouse: null,
            EvolveDataDeliveryTime: null,
            EvolveDataNotes: null,
        });
    }





    onClickAdd(e, rowData, history) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            this.props.mawbAddPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(rowData.ContainerID), null, this.props.warehouseSelection, this.props.customerSelection)

        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }






    //Receive Container drawer
    onClickReceive(e, rowData, history) {
        this.props.transShipmentReceiveGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ContainerID, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        // this.props.transPoLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ContainerID, null, null, null, this.props.warehouseSelection, this.props.customerSelection)

        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: rowData,
            showReceiveDrawer: true,
            ReceiveDrawerContainerID: rowData.ContainerID,

            //Add Evolve Data Values
            ReceiveDrawerContainer: rowData.Container,
            ReceiveDrawerNotes: rowData.Notes
        });
    }
    preloadReceiveState() {
        var receivedDict = {};
        for (let k in this.props.ShipmentReceiveData) {
            // receivedDict[this.props.ShipmentReceiveData[k].HouseID] = { 'Cases': this.props.ShipmentReceiveData[k].Cartons - this.props.ShipmentReceiveData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
            if (this.props.ShipmentReceiveData[k].PoID === null) {
            }
            else {
                receivedDict[this.props.ShipmentReceiveData[k].PoID] = { 'Cases': null, 'Pallets': null, 'Notes': '', 'Customs': false }
            }
        }

        var locationDict = {};
        for (let k in this.props.ShipmentReceiveData) {
            if (this.props.ShipmentReceiveData[k].PoID === null) {
            }
            else {
                // locationDict[this.props.ShipmentReceiveData[k].PoID] = [{'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null,  }]
                locationDict[this.props.ShipmentReceiveData[k].PoID] = [{ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': false }]
            }
        }

        try {
            var receivedDictLength = Object.keys(receivedDict).length
        }
        catch (e) {
            var receivedDictLength = 0
        }
        if (typeof (receivedDict) === 'undefined' || receivedDict === null || receivedDictLength === 0) {
            window.alert('No POs exist for Receiving.')
        }
        else {
            this.setState({
                unsavedReceiveChanges: false,
                ReceiveDict: receivedDict,
                LocationDict: locationDict
            });
        }
    }
    onChangeReceiveDrawer = (event, item, label) => {
        var currentReceiveDict = this.state.ReceiveDict
        var poID = item.PoID;

        currentReceiveDict[poID][label] = event

        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: currentReceiveDict
        });

    };

    onChangeReceiveDrawerLocations = (event, item, locationitem, label, existinglocationname) => {

        if (label === 'WarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationDict[item.PoID].filter(item => item.WarehouseLocationID === event).length > 0) {
                window.alert('Duplicate location entry, please use existing.')
            }
            else {
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

                warehouseLocationDictUpdated[item.PoID][keyToUpdate][label] = event

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
        }
        else {
            var warehouseLocationDictUpdated = this.state.LocationDict
            var keyToUpdate = warehouseLocationDictUpdated[item.PoID].findIndex(item => item.WarehouseLocationID === existinglocationname)

            warehouseLocationDictUpdated[item.PoID][keyToUpdate][label] = event

            this.setState({
                LocationDict: warehouseLocationDictUpdated,
                unsavedReceiveChanges: true
            });

            //Use warehouseLocationDictUpdated to update totals of Pallets and cases
            var currentReceiveDict = this.state.ReceiveDict
            var poID = item.PoID;

            var sumNewValue = 0;

            for (var x in warehouseLocationDictUpdated[poID]) {
                sumNewValue += convertNanToZero(parseInt(warehouseLocationDictUpdated[poID][x][label]));
            }

            currentReceiveDict[poID][label] = sumNewValue

            this.setState({
                ReceiveDict: currentReceiveDict
            });

        }

        this.forceUpdate()
    };



    handleAddNewLocation = (newLocationDict) => {
        this.setState({
            unsavedReceiveChanges: true,
            LocationDict: newLocationDict
        }, () => {
            // Do an API call with this.state.searchTerm

        });
    }

    onClickAddLocation = (event, item) => {
        //Check if any Warehouse names are null
        if (this.state.LocationDict[item.PoID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all location values first.')
        }
        else {
            //save warehouse name
            var currentLocationDict = this.state.LocationDict

            currentLocationDict[item.PoID].push({ 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null, 'Oversize': false })

            this.handleAddNewLocation(currentLocationDict)

            this.forceUpdate()
        }
    }

    onClickRemoveFromAdd = (e, item, locationitem, warehouseLocationID) => {
        var currentList = JSON.parse(JSON.stringify(this.state.LocationDict));
        var poID = item.PoID

        if (currentList[poID].length === 1) {
            currentList[poID][0] = { 'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': 0, 'Pallets': 0, 'Oversize': false }
        }
        else {
            var remainingItems = currentList[poID].filter((item) => { return item.WarehouseLocationID !== warehouseLocationID });
            currentList[poID] = JSON.parse(JSON.stringify(remainingItems));
        }

        var currentBuildLoadDict = this.state.ReceiveDict
        try {
            currentBuildLoadDict[poID]['Cases'] = (currentList[poID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Cases), 0))
            currentBuildLoadDict[poID]['Pallets'] = (currentList[poID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Pallets), 0))
        }
        catch (e) {
            // currentBuildLoadDict[poID]['Cases'] = replaceNegativeWithZero(currentBuildLoadDict['CasesReceived'] - currentBuildLoadDict['CasesLoaded'])
            // currentBuildLoadDict[poID]['Pallets'] = replaceNegativeWithZero(currentBuildLoadDict['PalletsReceived'] - currentBuildLoadDict['PalletsLoaded'])
        }
        this.setState({
            // unsavedBuildLoadChanges: true,
            ReceiveDict: currentBuildLoadDict,
            LocationDict: currentList,
        });

        this.forceUpdate()
    }

    onClickReceiveDrawerSave(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (this.props.warehouseSelection === null) {
                window.alert('Please select a warehouse and customer.')
            }
            else if (this.props.customerSelection === null) {
                window.alert('Please select a customer.')
            }
            else  {
                for (let k in this.state.ReceiveDict) {
                    try { var recoveredNum = this.state.ReceiveDict[k]['Cases'] }
                    catch (e) { var recoveredNum = 0 }
                    try { var palletNum = this.state.ReceiveDict[k]['Pallets'] }
                    catch (e) { var palletNum = 0 }

                    if ((recoveredNum === null || recoveredNum == 0) && (palletNum === null || palletNum == 0)) {
                    }
                    else {
                        try {
                            this.props.transShipmentReceivePost(
                                this.props.currentUserEmail,
                                this.props.userSignInData.AuthToken,
                                formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
                                null,
                                null,
                                formatNumberForAPI(k),
                                null,
                                formatNumberForAPI(this.state.ReceiveDict[k]['Cases']),
                                formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
                                formatAllTextNumbersSymbolsForAPI(this.state.ReceiveDict[k]['Notes']),
                                null,
                                this.props.warehouseSelection,
                                this.props.customerSelection
                            )
                        }
                        catch (e) { }
                    }
                }

                for (let l in this.state.LocationDict) {

                    try { var poID = l }
                    catch (e) { var poID = null }

                    for (let i in this.state.LocationDict[l]) {

                        try { var casesLocNum = convertNanToZero(this.state.LocationDict[l][i]['Cases']) }
                        catch (e) { var casesLocNum = 0 }

                        try { var palletsLocNum = convertNanToZero(this.state.LocationDict[l][i]['Pallets']) }
                        catch (e) { var palletsLocNum = 0 }

                        try { var WarehouseLocationIDNum = convertNanToZero(this.state.LocationDict[l][i]['WarehouseLocationID']) }
                        catch (e) { var WarehouseLocationIDNum = 0 }

                        try { var locOversize = formatTrueFalseToBit(this.state.LocationDict[l][i]['Oversize']) }
                        catch (e) { var locOversize = null }

                        if ((casesLocNum === null || casesLocNum == 0) && (palletsLocNum === null || palletsLocNum == 0)) {
                        }
                        else {
                            try {
                                this.props.transPoLocationPost(
                                    this.props.currentUserEmail,
                                    this.props.userSignInData.AuthToken,
                                    poID,
                                    WarehouseLocationIDNum,
                                    casesLocNum,
                                    palletsLocNum,
                                    null,
                                    this.props.warehouseSelection,
                                    this.props.customerSelection,
                                    locOversize
                                )
                            }
                            catch (e) { }
                        }

                    }

                }






                try {
                    // check customs city api for events
                    this.props.customsCityAPIMessages(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        null,
                        this.state.receiveDataRowData.Container,
                        null
                    )
                }
                catch (e) { }
                this.setState({
                    unsavedReceiveChanges: false,
                    showReceiveDrawer: false
                });
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    closeReceiveDrawer() {
        if (this.state.unsavedReceiveChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setReceiveDrawerToClose()
            }
        }
        else {
            this.setReceiveDrawerToClose()
        }
    }

    setReceiveDrawerToClose() {
        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,

            showReceiveDrawer: false,

            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
        });
    }





    //Airline Recovery drawer
    // onClickAirlineRecovery(e, rowData, history) {
    //   // this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.ContainerID, null, null, null, null, null, null, null, null, null, null, null, null)
    //   // this.preloadReceiveState()
    //   this.setState({
    //     AirlineRecoveryDict: {},
    //     unsavedAirlineRecoveryChanges: false,
    //     showAirlineRecoveryDrawer: true,


    //     AirlineRecoveryPlannedPickupDT: null,
    //     AirlineRecoveryDriverAssigned: null,
    //     AirlineRecoveryNote: null,
    //     AirlineRecoveryTripMade: null,
    //     AirlineRecoveryFreightRecovered: null,
    //     AirlineRecoveryPickupDate: null,
    //     AirlineRecoveryCartonsRecovered: null,
    //     AirlineRecoveryPickedUpBy: null,
    //     AirlineRecoveryCarrier: null,


    //     // receiveDataRowData: rowData,


    //     //Add Evolve Data Values
    //     ReceiveDrawerHouse: rowData.HouseRef,
    //     ReceiveDrawerNo: rowData.No,
    //     ReceiveDrawerPMC: rowData.PMC,
    //     ReceiveDrawerPickupBy: rowData.PickupBy,
    //     ReceiveDrawerTrips: rowData.Trips,
    //     ReceiveDrawerArrivalTime: rowData.ArrivalTime,
    //     ReceiveDrawerLFD: rowData.LFD,
    //     ReceiveDrawerArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
    //     ReceiveDrawerDeliveryTime: rowData.DeliveryTime,
    //     ReceiveDrawerNotes: rowData.Notes
    //   });
    //   this.preloadReceiveState()
    // }
    onClickAirlineRecoveryButton(rowData, keyColumn) {
        var plannedPickupDT = null
        try {
            plannedPickupDT = rowData.PlannedPickupDT
        }
        catch (e) { }
        var lfd = null
        try {
            lfd = rowData.LFD
        }
        catch (e) { }
        var pmc = null
        try {
            pmc = rowData.PMC
        }
        catch (e) { }

        this.setState({
            AirlineRecoveryDict: {
                AirlineRecoveryDriverAssigned: null,
                AirlineRecoveryNote: null,
                AirlineRecoveryCartonsRecovered: null,
                AirlineRecoveryPickedUpBy: null,
                AirlineRecoveryCarrier: null,

            },
            evolveDataRowData: rowData,
            unsavedAirlineRecoveryChanges: false,
            showAirlineRecoveryDrawer: true,
            AirlineRecoveryFreightRecovered: null,
            AirlineRecoveryTripMade: null,
            AirlineRecoveryPlannedPickupDT: plannedPickupDT,
            AirlineRecoveryPickupDate: null,
            AirlineRecoveryLFD: lfd,
            AirlineRecoveryPMC: pmc,

        });
    }


    onChangeAirlineRecoveryDrawer = (event, label) => {
        var currentReceiveDict = this.state.AirlineRecoveryDict

        currentReceiveDict[label] = event

        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryDict: currentReceiveDict
        });
    };
    onChangePlannedPickupDT = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryPlannedPickupDT: event
        });
    }
    onChangeLFD = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryLFD: event
        });
    }
    onChangeAirlineRecoveryPMC = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryPMC: event
        });
    }
    onChangeAirlineTripMade = (event) => {
        // var currentReceiveDict = this.state.AirlineRecoveryDict
        // currentReceiveDict["AirlineRecoveryTripMade"] = event

        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryTripMade: event
        });
    }
    onChangeAirlineFreightRecovered = (event) => {
        if (event == 0) {
            var currentReceiveDict = this.state.AirlineRecoveryDict

            currentReceiveDict["AirlineRecoveryCartonsRecovered"] = null
            currentReceiveDict["AirlineRecoveryPickedUpBy"] = null
            currentReceiveDict["AirlineRecoveryCarrier"] = null

            this.setState({
                AirlineRecoveryDict: currentReceiveDict,
                AirlineRecoveryPickupDate: null
            });
        }

        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryFreightRecovered: event,
        });

    }
    onChangePickupDT = (event) => {
        this.setState({
            unsavedAirlineRecoveryChanges: true,
            AirlineRecoveryPickupDate: event
        });
    }
    onClickAirlineRecoveryDrawerSave(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            // if (window.confirm('Save Data?')) {

                try {
                    this.props.mawbRecoveryPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,

                        formatNumberForAPI(this.state.evolveDataRowData.ContainerID),

                        formatDateForUrlSearch(this.state.AirlineRecoveryPlannedPickupDT),
                        formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryDriverAssigned),
                        formatAllTextNumbersSymbolsForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryNote),
                        formatNumberForAPI(this.state.AirlineRecoveryTripMade),
                        formatNumberForAPI(this.state.AirlineRecoveryFreightRecovered),
                        formatDateForUrlSearch(this.state.AirlineRecoveryPickupDate),
                        formatNumberForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryCartonsRecovered),
                        formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryPickedUpBy),
                        formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryCarrier),

                        formatDateForUrlSearch(this.state.AirlineRecoveryLFD),
                        formatNumberForAPI(this.state.AirlineRecoveryPMC),

                        this.props.warehouseSelection,
                        this.props.customerSelection
                    )
                }
                catch (e) { }
            // }

            this.setState({
                unsavedAirlineRecoveryChanges: false,
                showAirlineRecoveryDrawer: false
            });
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeAirlineRecoveryDrawer() {
        if (this.state.unsavedAirlineRecoveryChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setAirlineReceiveDrawerToClose()
            }
        }
        else {
            this.setAirlineReceiveDrawerToClose()
        }
    }
    setAirlineReceiveDrawerToClose() {
        this.setState({
            AirlineRecoveryDict: {
                AirlineRecoveryDriverAssigned: null,
                AirlineRecoveryNote: null,

                AirlineRecoveryCartonsRecovered: null,
                AirlineRecoveryPickedUpBy: null,
                AirlineRecoveryCarrier: null,
            },
            unsavedAirlineRecoveryChanges: false,
            showAirlineRecoveryDrawer: false,
            AirlineRecoveryTripMade: null,
            AirlineRecoveryFreightRecovered: null,
            AirlineRecoveryPlannedPickupDT: null,
            AirlineRecoveryPickupDate: null,
            AirlineRecoveryLFD: null,
            AirlineRecoveryPMC: null,

        });
    }


    //Add new HAWB modal
    onClickAddMissing() {
        this.handleOpenAddNewModal()
    }
    handleOpenAddNewModal() {
        this.setState({
            addNewModalShown: true,

            newModalHouseRef: null,
            newModalConsigneeID: null,

            newModalCartons: null,

        });
    }

    onChangeAddNewModal = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
    };
    handleCloseAddNewModal() {
        this.setState({
            addNewModalShown: false,

            newModalHouseRef: null,
            newModalConsigneeID: null,

            newModalCartons: null,

        });
    }
    handleSaveAddNewModal() {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (window.confirm('Do you wish to add missing House Ref?')) {
                this.props.hawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.receiveDataRowData.ContainerID, formatAllTextNumbersSymbolsForAPI(this.state.newModalHouseRef), null, this.state.newModalConsigneeID, formatStringForAPI('Manual Add'), null, null, null, null, null, null, null, null, null, this.state.newModalCartons, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
            }
            this.handleCloseAddNewModal()
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }



    //Add new Container drawer
    addMawb(searchValue) {
        if (searchValue === null || searchValue == 'null') {

        }
        else if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else if (window.confirm(searchValue + ' not found, do you wish to add?')) {
            this.onClickAddNew()
            this.setState({
                Container: searchValue,
                unsavedNewContainerChanges: true,
            });
        }
    }
    onClickAddNew(placement) {
        this.setState({
            placement,
            showAddNewContainerDrawer: true,
            unsavedNewContainerChanges: true,
        });
    }
    onChangeAddNewContainer = (event, field) => {
        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
        this.setState({
            unsavedNewContainerChanges: true,
        });
    };
    onClickConfirmAddNewContainer(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            if (this.state.Container == null || this.state.Container == '') {
                window.alert('Please enter new Container.')
            }
            // else if (window.confirm('Add New Container?')) {
            else {
                // this.props.transContainerPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Container), null, formatNumberForAPI(this.state.TotWeight), formatNumberForAPI(this.state.TotChargeable), formatNumberForAPI(this.state.TotVolume), formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), formatStringForAPI(this.state.AgentNote), formatNumberForAPI(this.state.CurrentMawbStatusID ))
                this.props.transContainerPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    formatStringForAPI(this.state.Container),
                    formatStringForAPI(this.state.LclFcl),
                    formatDateTimeForUrlSearch(this.state.DeliveryAppointment),
                    formatDateForUrlSearch(this.state.ETD),
                    formatDateForUrlSearch(this.state.ETA),
                    1,
                    formatAllTextNumbersSymbolsForAPI(this.state.Notes),
                    this.props.warehouseSelection,
                    this.props.customerSelection
                )
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }
    closeAddNewDrawer() {
        if (this.state.unsavedNewContainerChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showAddNewContainerDrawer: false,
            unsavedNewContainerChanges: false,
            Container: null,
            LclFcl: null,
            Notes: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null
        });
    }
    setInitialTreeSelection(NewContainerId, NewContainerLabel) {
        this.setState({
            selectedType: 'container',
            selectedLabel: NewContainerLabel,
            selectedID: NewContainerId,
            addNewText: null,

        });
    }
    //     if (e['value'].includes('C')) {
    //         this.setState({
    //             selectedType: 'container',
    //             selectedLabel: e['label'],
    //             selectedID: e['value'].substring(1)
    //         });
    //     }
    //     this.props.ContainerJsonTreeData
    // (*&^%&*()_JIO)



    //Add To Container Drawer
    onClickAddToContainer(placement) {

        this.setState({
            placement,

            showAddToContainerDrawer: true,

            MBOL: null,
            HBOL: null,
            PO: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            addNewText: null,

            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,

            selectedPoCases: null,
            selectedPoPallets: null,
            selectedPoNotes: null,
            selectedPoCustomerRef: null,
            selectedPoProject: null
        });
    }
    onClickTableLinkEditContainer(rowData, keyColumn) {
        var newContainerID = null
        try {
            newContainerID = rowData['ContainerID']

            this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, newContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        }
        catch (e) { }

        this.setState({
            // placement,
            EditContainerID: newContainerID,
            showAddToContainerDrawer: true,

            MBOL: null,
            HBOL: null,
            PO: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            addNewText: null,

            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,

            selectedPoCases: null,
            selectedPoPallets: null,
            selectedPoNotes: null,
            selectedPoCustomerRef: null,
            selectedPoProject: null,
        });

    }
    onChangeAddToContainer = (e) => {
        this.setState({
            addNewText: e
        });
    }

    onChangeCases = (e) => {
        this.setState({
            newPoCases: e
        });
    }
    onChangePallets = (e) => {
        this.setState({
            newPoPallets: e
        });
    }
    onChangeNotes = (e) => {
        this.setState({
            newPoNotes: e
        });
    }
    onChangeCustomerRef = (e, value, item) => {
        this.setState({
            newPoCustomerRef: e
        });
    }
    onChangeProject = (e, value, item) => {
        this.setState({
            newPoProject: e
        });
    }


    onClickTree(e) {
        this.setState({
            addNewText: null,
            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,
        });

        var selectedArray = []
        const findById = (dataset, id) => {
            for (let c of dataset) {
                if (c.value === id) {
                    selectedArray = c
                    return selectedArray
                }
                else {
                    for (let m of c.children) {
                        if (m.value === id) {
                            selectedArray = m
                            return selectedArray
                        }
                        else {
                            for (let h of m.children) {
                                if (h.value === id) {
                                    selectedArray = m
                                    return selectedArray
                                }
                                else {
                                    for (let p of h.children) {
                                        if (p.value === id) {
                                            selectedArray = p
                                            return selectedArray
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        selectedArray = findById(this.props.ContainerJsonTreeData, e['value']);

        if (e['value'].includes('C')) {
            this.setState({
                selectedType: 'container',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('M')) {
            this.setState({
                selectedType: 'mbol',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('H')) {
            this.setState({
                selectedType: 'hbol',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1)
            });
        }
        else if (e['value'].includes('P')) {
            this.setState({
                selectedType: 'po',
                selectedLabel: e['label'],
                selectedID: e['value'].substring(1),

                selectedPoCases: selectedArray['CaseCount'],
                selectedPoPallets: selectedArray['PalletCount'],
                selectedPoNotes: selectedArray['Notes'],
                selectedPoCustomerRef: selectedArray['custref'][0]['CustomerRef'],
                selectedPoProject: selectedArray['custref'][0]['Project'],
            });
        }
    }
    onClickConfirmAddToContainer(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            if (this.state.addNewText == null || this.state.addNewText == '') {
                window.alert('Please enter data.')
            }
            // else if (window.confirm('Confirm Save?')) {
            else {
                // save child of selected type
                if (this.state.selectedType === 'container') {

                    this.props.transMbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatAllTextNumbersSymbolsForAPI(this.state.addNewText), this.state.selectedID, 0, this.props.warehouseSelection, this.props.customerSelection)
                }
                else if (this.state.selectedType === 'mbol') {
                    this.props.transHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatAllTextNumbersSymbolsForAPI(this.state.addNewText), this.state.selectedID, 0, this.props.warehouseSelection, this.props.customerSelection)
                }
                else if (this.state.selectedType === 'hbol') {
                    this.props.transPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatAllTextNumbersSymbolsForAPI(this.state.addNewText), this.state.selectedID, formatStringForAPI(this.state.newPoCustomerRef), this.state.newPoCases, this.state.newPoPallets, formatAllTextNumbersSymbolsForAPI(this.state.newPoNotes), formatStringForAPI(this.state.newPoProject), 0, this.props.warehouseSelection, this.props.customerSelection)
                }
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }





    setNewMbolTreeSelection(NewMbolID, NewMbolLabel) {
        if (NewMbolID === null) {
            this.setState({
                selectedType: null,
                selectedLabel: null,
                selectedID: null,
                addNewText: null,
            });
        }
        else {
            var concatSelectedLabel = "MBOL: " + NewMbolLabel
            this.setState({
                selectedType: 'mbol',
                selectedLabel: concatSelectedLabel,
                selectedID: NewMbolID,
                addNewText: null,
            });
        }
    }



    setNewHbolTreeSelection(NewHbolID, NewHbolLabel) {
        if (NewHbolID === null) {
            this.setState({
                selectedType: null,
                selectedLabel: null,
                selectedID: null,
                addNewText: null,
            });
        }
        else {
            var concatSelectedLabel = "HBOL: " + NewHbolLabel
            this.setState({
                selectedType: 'hbol',
                selectedLabel: concatSelectedLabel,
                selectedID: NewHbolID,
                addNewText: null,

            });
        }
    }




    onClickDeleteMbol(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (window.confirm('Confirm Delete?')) {

                this.setState({
                    selectedType: null,
                    selectedLabel: null,
                    selectedID: null,
                });

                this.props.transMbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.selectedID, null, null, 1, this.props.warehouseSelection, this.props.customerSelection)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    onClickDeleteHbol(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            if (window.confirm('Confirm Delete?')) {

                this.setState({
                    selectedType: null,
                    selectedLabel: null,
                    selectedID: null,
                });

                this.props.transHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.selectedID, null, null, 1, this.props.warehouseSelection, this.props.customerSelection)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    onClickDeletePo(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            if (window.confirm('Confirm Delete?')) {

                this.setState({
                    selectedType: null,
                    selectedLabel: null,
                    selectedID: null,
                });

                this.props.transPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.selectedID, null, null, null, null, null, null, null, 1, this.props.warehouseSelection, this.props.customerSelection)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }





    closeAddToContainerDrawer() {
        this.setState({
            EditContainerID: null,
            showAddToContainerDrawer: false,

            MBOL: null,
            HBOL: null,
            PO: null,

            selectedType: null,
            selectedLabel: null,
            selectedID: null,
            addNewText: null,
            newPoCases: null,
            newPoPallets: null,
            newPoNotes: null,
            newPoCustomerRef: null,
            newPoProject: null,
            selectedPoCases: null,
            selectedPoPallets: null,
            selectedPoNotes: null,
            selectedPoCustomerRef: null,
            selectedPoProject: null
        });
    }


    //Change MAWB row color
    onChangeColor(e, rowData) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            this.props.userColorPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData['ContainerID'], e, this.props.warehouseSelection, this.props.customerSelection)
            var updatedColors = this.state.userColorArray
            updatedColors[rowData['ContainerID']] = e
            this.setState({
                userColorArray: updatedColors
            })
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }


    render() {



        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Container',
                                this.onChangeSearchContainer,
                                this.props.history,
                                'container',
                                this.state.searchContainer
                            )}
                        </Row>

                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'MBOL',
                                this.onChangeSearchMBOL,
                                this.props.history,
                                'mbol',
                                this.state.searchMBOL
                            )}
                        </Row>

                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'HBOL',
                                this.onChangeSearchHBOL,
                                this.props.history,
                                'hbol',
                                this.state.searchHBOL
                            )}
                        </Row>

                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PO',
                                this.onChangeSearchPO,
                                this.props.history,
                                'po',
                                this.state.searchPO
                            )}
                        </Row>
                    </Panel>
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                mawbStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        {/* <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.MawbCarrierData,
                                this.state.searchCarrier,
                                'Carrier',
                                this.onChangeSearchCarrier,
                                this.props.history
                            )}
                        </Row> */}

                        {/* <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Planned Pickup Date',
                                this.onChangeSearchPlannedPickupStart,
                                this.onChangeSearchPlannedPickupEnd,
                                this.state.searchPlannedPickupStart,
                                this.state.searchPlannedPickupEnd
                            )}
                        </Row> */}

                        {/* <Row>
                            <div >
                                <ControlLabel>Assigned Driver</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.serachAssignedDriver} onChange={(e) => { this.onChangeSearchAssigedDriver(e) }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                </InputGroup>
                            </div>
                        </Row> */}

                        {/* <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                colorColumnsValuesDropDownFilter,
                                this.state.searchMawbColorCode,
                                'Color',
                                this.onChangeSearchMawbColorCode,
                                this.props.history
                            )}
                        </Row> */}

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isContainerLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.ContainerData) !== 'undefined' && this.props.ContainerData != null) {

            rTable = RTableContainer(
                this.props.ContainerData,        // tableData,
                ContainerDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isContainerLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                false,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                checkedKeys,                       // checkedKeys,
                this.handleCheckAll,                       // handleCheckAll,
                this.handleCheck,                       // handleCheck,
                true,                      // boolSelectButton,
                this.onClickReceive,     // onClickSelect,
                'Receive',                       // selectButtonText,
                this.onClickAdd,     // onClickSelect,
                'Add',                       // selectButtonText,

                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey

                true,                           // editLink,
                'Edit',                         // editLinkText,
                this.onClickTableLinkEditContainer,                 // editLinkOnClick

                this.onChangeColor,
                this.state.userColorArray
            )

            mawbDiv = <div>

                {/* <Button style={{ marginRight: '10px' }} onClick={(e) => { this.onClickAddNew(e) }} >Add New</Button> */}

                {/* <Button onClick={(e) => { this.onClickCustomsCleared(e) }}>Customs Cleared - Selected</Button> */}
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Shipment_' + currentDateTime}>
                                <ExcelSheet data={this.props.ContainerData} name="Shipment">
                                    {
                                        ContainerDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }

        let currentEvolveDataContainer;
        try {
            if (typeof (this.state.evolveDataRowData.Container) !== 'undefined' && this.props.ContainerData != null) {

                currentEvolveDataContainer = this.state.evolveDataRowData.Container
            }
            else {
                currentEvolveDataContainer = ''
            }
        }
        catch (e) {
            currentEvolveDataContainer = ''
        }

        let currentReceiveContainer;
        try {
            if (typeof (this.state.receiveDataRowData.Container) !== 'undefined' && this.props.ContainerData != null) {

                currentReceiveContainer = this.state.receiveDataRowData.Container
            }
            else {
                currentReceiveContainer = ''
            }
        }
        catch (e) {
            currentReceiveContainer = ''
        }



        let receiveDrawer;
        if (this.props.isShipmentReceiveLoading) {
            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (this.props.isPoLocationLoading) {

            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (!this.state.showReceiveDrawer) {
            receiveDrawer = <div></div>
        }
        else if (Object.keys(this.state.LocationDict).length > 0 && typeof (this.state.LocationDict) !== 'undefined' && this.state.LocationDict != null) {

            receiveDrawer = <ReceiveContainerDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} onChangeLocation={this.onChangeReceiveDrawerLocations} onClickAddLocation={this.onClickAddLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onClickAddMissing={this.onClickAddMissing} masterLabel={currentReceiveContainer} />
        }


        return (
            <div>
                <h3>
                    Transload - Shipment
                </h3>
                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}



                <AddNewContainerDrawer {...this.props} state={this.state} onChangeAddNewContainer={this.onChangeAddNewContainer} confirm={this.onClickConfirmAddNewContainer} close={this.closeAddNewDrawer} />

                <AddToContainerDrawer {...this.props} state={this.state} onChangeAddToContainer={this.onChangeAddToContainer} onClickTree={this.onClickTree} onClickDeleteMbol={this.onClickDeleteMbol} onClickDeleteHbol={this.onClickDeleteHbol} onClickDeletePo={this.onClickDeletePo} onChangeCases={this.onChangeCases} onChangePallets={this.onChangePallets} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeProject={this.onChangeProject} confirm={this.onClickConfirmAddToContainer} close={this.closeAddToContainerDrawer} />

                {receiveDrawer}

            </div>
        );

    }
}

export default withRouter(Shipment);