export const InventoryLoadLotFileUploadsDataElements = [

    {
        dBKey: 'FileName',
        displayName: 'File Name',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'Carrier',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'UploadDT',
        displayName: 'Upload Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'EmailAddr',
        displayName: 'Uploaded By User',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },
]


export default InventoryLoadLotFileUploadsDataElements

