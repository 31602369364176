import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoadTender from './LoadTenderDetails';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../redux/actions/actionsUser';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isEdi204Loading: store.ctrlTwrReducer.isEdi204Loading,
    Edi204Data: store.ctrlTwrReducer.Edi204Data,

    Edi990QueuePostSuccessful: store.ctrlTwrReducer.Edi990QueuePostSuccessful,

    isEdi204OIDLoading: store.ctrlTwrReducer.isEdi204OIDLoading,
    Edi204OIDData: store.ctrlTwrReducer.Edi204OIDData,

    isEdi204L5Loading: store.ctrlTwrReducer.isEdi204L5Loading,
    Edi204L5Data: store.ctrlTwrReducer.Edi204L5Data,
});

const mapDispatchToProps = dispatch =>
({

    edi204Get: (_email, _authToken, _HdrID, _LoadTenderNo, _CustomerID, _QueueStatus, _LoadDtStart, _LoadDtEnd) => {
        dispatch(ctrlTwrActions.edi204Get(_email, _authToken, _HdrID, _LoadTenderNo, _CustomerID, _QueueStatus, _LoadDtStart, _LoadDtEnd))
    },

    edi990QueuePost: (_email, _authToken, _CustomerID, _AcceptDecline, _ID, _HdrId204, _B101_SCAC, _N902_ProRef, _SE02_ControlNumber, _QueueStatus) => {
        dispatch(ctrlTwrActions.edi990QueuePost(_email, _authToken, _CustomerID, _AcceptDecline, _ID, _HdrId204, _B101_SCAC, _N902_ProRef, _SE02_ControlNumber, _QueueStatus))
    },

    edi204OIDGet: (_email, _authToken, _HdrId, _LoadTenderNo, _ProRef) => {
        dispatch(ctrlTwrActions.edi204OIDGet(_email, _authToken, _HdrId, _LoadTenderNo, _ProRef))
    },

    edi204L5Get: (_email, _authToken, _HdrId, _LoadTenderNo, _ProRef) => {
        dispatch(ctrlTwrActions.edi204L5Get(_email, _authToken, _HdrId, _LoadTenderNo, _ProRef))
    },


});

class LoadTenderContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.Edi990QueuePostSuccessful !== this.props.Edi990QueuePostSuccessful && this.props.Edi990QueuePostSuccessful) {
            this.handleUrlParam()
        }
        // if (prevProps.CtrlTwrShipmentFileUploadPostSuccessful !== this.props.CtrlTwrShipmentFileUploadPostSuccessful && this.props.CtrlTwrShipmentFileUploadPostSuccessful) {
        //     const values = queryString.parse(this.props.location.search)
        //     try {
        //         var paramPro = formatStringForAPI(values.pro)
        //     }
        //     catch (e) {
        //         var paramPro = null
        //     }
        //     this.props.ctrlTwrShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
        // }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramID = formatStringForAPI(values.id)
        }
        catch (e) {
            var paramID = null
        }

        this.props.edi204Get(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramID, null, this.props.customerSelection, null, null, null)

        this.props.edi204OIDGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramID, null, null)

        this.props.edi204L5Get(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramID, null, null)

    }



    render() {

        return (

            <LoadTender
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isEdi204Loading={this.props.isEdi204Loading}
                Edi204Data={this.props.Edi204Data}

                Edi990QueuePostSuccessful={this.props.Edi990QueuePostSuccessful}
                edi990QueuePost={this.props.edi990QueuePost}

                isEdi204OIDLoading={this.props.isEdi204OIDLoading}
                Edi204OIDData={this.props.Edi204OIDData}
            
                isEdi204L5Loading={this.props.isEdi204L5Loading}
                Edi204L5Data={this.props.Edi204L5Data}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadTenderContainer));



