export const InventoryOrderOutboundDataElements = [
    {
        dBKey: 'OrderRef',
        displayName: 'Order Ref',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },


    {
        dBKey: 'OrderStatus',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'OrderType',
        displayName: 'Type',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'OrderPriority',
        displayName: 'Priority',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },




    {
        dBKey: 'Notes',
        displayName: 'Notes',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },






]


export default InventoryOrderOutboundDataElements

