
export const UserAccountWarehouseDataElements = [
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse Name',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehousePort',
        displayName: 'Warehouse Port',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'WarehouseCode',
        displayName: 'Warehouse Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Admin',
        displayName: 'Admin',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ReadWrite',
        displayName: 'Read/Write',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },

    
]

export default UserAccountWarehouseDataElements

