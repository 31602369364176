//TODO make dynamic

////npm imports
import React, {
    PureComponent
} from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withRouter } from 'react-router-dom';
import { Row, Button, Panel, Loader, InputPicker, Col, ButtonToolbar } from 'rsuite';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import CtrlTwrSeaportDataElements from '../../dataComponentsUser/CtrlTwrSeaportDataElements';
import ReactExport from "react-export-excel";
import * as moment from 'moment'


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


class LabelGenConfiguration extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {


        }

        //Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);


    }


    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
        //   this.setState({
        //     checkedKeys: []
        //   });
        // }
        // if (prevProps.customerSelection !== this.props.customerSelection) {
        //   this.setState({
        //     checkedKeys: []
        //   });
        // }
    }

    getsessionStorageWarehouse() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                warehouseMenuList = warehouseMenuList.filter(x => x.Admin === true)
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }
        try {
            let moduleMenuList = JSON.parse(sessionStorage.getItem('AppConfig.ModuleArray'));
            if (moduleMenuList != null && moduleMenuList != undefined) {
                moduleMenuList = moduleMenuList.filter(x => x.Admin === true)
                this.setState({
                    moduleArray: moduleMenuList
                })
            }
        }
        catch (e) { }
        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                customerMenuList = customerMenuList.filter(x => x.Admin === true)
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
    }



    //Table Sort
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 500);
    }



    render() {
        const { sortColumn, sortType } = this.state;






        let seaportDiv;
        let seaportTable;
        if (this.props.isCtrlTwrSeaportLoading) {

            seaportDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.CtrlTwrSeaportData) !== 'undefined' && this.props.CtrlTwrSeaportData != null) {

            seaportTable = RTableGeneral(
                'SeaportCodeID',     //   keyColumn,
                this.props.CtrlTwrSeaportData,     //   tableData,
                CtrlTwrSeaportDataElements,       //   DataElements,
                this.props.isCtrlTwrSeaportLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                false,       //   link1bool,
                null,     //   link1text,
                null,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick

            )
            seaportDiv = <div>
                <h4>
                    Seaports
                </h4>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Seaports_' + currentDateTime}>
                                <ExcelSheet data={this.props.CtrlTwrSeaportData} name="Seaports">
                                    {
                                        CtrlTwrSeaportDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
                {seaportTable}

            </div>






            // milestoneTable = <Panel
            //   bordered style={{ marginBottom: 10 }}
            //   // header='Milestones'
            //   header={
            //     <Row>
            //       <Col md={16} sm={24}>
            //         <span>Milestones</span>
            //       </Col>
            //       <Col md={8} sm={24}>
            //         <ButtonToolbar >
            //           <Button style={{ float: 'right' }} onClick={(e) => { this.onClickOpenShipManagmenetDrawer(e) }} >Add Milestone</Button>
            //         </ButtonToolbar>
            //       </Col>
            //     </Row>
            //   }
            // >
            //   {milestoneTableComponent}
            //   {downloadMilestoneDiv}
            // </Panel>

        }
        else {
            seaportTable = <div></div>
            seaportDiv = <div></div>
        }






        return (

            <div>
                <h3>
                    Label Generation - Configuration
                </h3>

                {seaportDiv}


            </div>
        );
    }
}

export default withRouter(LabelGenConfiguration);
