import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoadDetails from './LoadDetails';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as ctrlTwrActions from '../../../../redux/actions/ctrlTwrActions';

const mapStateToProps = store =>
({


    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,


    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,

    isInventoryLotSkuLoading: store.inventoryReducer.isInventoryLotSkuLoading,
    InventoryLotSkuData: store.inventoryReducer.InventoryLotSkuData,

    // isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    // InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    SkuLocationDataGrouped: store.inventoryReducer.SkuLocationDataGrouped,

    InventoryLoadPalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadPalletCaseSkuPostSuccessful,

    InventoryLoadRemoveSkuPostSuccessful: store.inventoryReducer.InventoryLoadRemoveSkuPostSuccessful,

    isInventorySkuWarehouseLocationHistoryLoading: store.inventoryReducer.isInventorySkuWarehouseLocationHistoryLoading,
    InventorySkuWarehouseLocationHistoryData: store.inventoryReducer.InventorySkuWarehouseLocationHistoryData,

    isInventoryLoadSkuLocationHistoryLoading: store.inventoryReducer.isInventoryLoadSkuLocationHistoryLoading,
    InventoryLoadSkuLocationHistoryData: store.inventoryReducer.InventoryLoadSkuLocationHistoryData,



    isInventoryLoadPalletCaseUnitLoading: store.inventoryReducer.isInventoryLoadPalletCaseUnitLoading,
    InventoryLoadPalletCaseUnitData: store.inventoryReducer.InventoryLoadPalletCaseUnitData,


    isInventoryLoadSKUsHistoryLoading: store.inventoryReducer.isInventoryLoadSKUsHistoryLoading,
    InventoryLoadSKUsHistoryData: store.inventoryReducer.InventoryLoadSKUsHistoryData,

    isInventoryLoadCasesHistoryLoading: store.inventoryReducer.isInventoryLoadCasesHistoryLoading,
    InventoryLoadCasesHistoryData: store.inventoryReducer.InventoryLoadCasesHistoryData,

    isInventoryLoadPalletsHistoryLoading: store.inventoryReducer.isInventoryLoadPalletsHistoryLoading,
    InventoryLoadPalletsHistoryData: store.inventoryReducer.InventoryLoadPalletsHistoryData,

    isInventoryLoadLotLoading: store.inventoryReducer.isInventoryLoadLotLoading,
    InventoryLoadLotData: store.inventoryReducer.InventoryLoadLotData,

    InventoryLoadRemovePalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadRemovePalletCaseSkuPostSuccessful,




    isInventoryLotLoadFileUploadLoading: store.inventoryReducer.isInventoryLotLoadFileUploadLoading,
    InventoryLotLoadFileUploadData: store.inventoryReducer.InventoryLotLoadFileUploadData,

    InventoryLotLoadFileUploadPostSuccessful: store.inventoryReducer.InventoryLotLoadFileUploadPostSuccessful,




});

const mapDispatchToProps = dispatch =>
({

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },


    inventoryLoadRemoveSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _LoadSkuID, _LoadID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseLocationID, _SkuWarehouseLocationID, _WarehouseLocations) => {
        dispatch(inventoryActions.inventoryLoadRemoveSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _LoadSkuID, _LoadID, _SkuID, _Units, _Cases, _Pallets, _Oversize, _WarehouseLocationID, _SkuWarehouseLocationID, _WarehouseLocations))
    },
    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },




    inventoryLotSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventorySkuWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventoryLoadSkuLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID, _Oversize) => {
        dispatch(inventoryActions.inventoryLoadSkuLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID, _Oversize))
    },

    inventoryLoadPalletCaseUnitGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _SkuID, _CaseSkuID) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseUnitGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _SkuID, _CaseSkuID))
    },
    inventoryLoadSKUsHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID,) => {
        dispatch(inventoryActions.inventoryLoadSKUsHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID,))
    },
    inventoryLoadCasesHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID,) => {
        dispatch(inventoryActions.inventoryLoadCasesHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID,))
    },
    inventoryLoadPalletsHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode,) => {
        dispatch(inventoryActions.inventoryLoadPalletsHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode,))
    },


    inventoryLoadLotGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadLotGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID))
    },


    inventoryLoadRemovePalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _NewPalletID, _CaseSkuID, _UnitsToRemove, _NewCaseID, _DeleteLoad,) => {
        dispatch(inventoryActions.inventoryLoadRemovePalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _NewPalletID, _CaseSkuID, _UnitsToRemove, _NewCaseID, _DeleteLoad,))
    },

    inventoryLoadPalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes))
    },

    inventoryLotLoadFileUploadGet: (_email, _authToken, _WarehouseID, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(inventoryActions.inventoryLotLoadFileUploadGet(_email, _authToken, _WarehouseID, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete))
    },
    inventoryLotLoadFileUploadPost: (_email, _authToken, _WarehouseID, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(inventoryActions.inventoryLotLoadFileUploadPost(_email, _authToken, _WarehouseID, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete))
    },



});

class LoadDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLoadRemoveSkuPostSuccessful !== this.props.InventoryLoadRemoveSkuPostSuccessful && this.props.InventoryLoadRemoveSkuPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }
        if (prevProps.InventoryLoadPalletCaseSkuPostSuccessful !== this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.props.InventoryLoadPalletCaseSkuPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }

        if (prevProps.InventoryLoadRemovePalletCaseSkuPostSuccessful !== this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful && this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful) {
            this.handleUrlParam()
        }
        else { }





        if (prevProps.InventoryLotLoadFileUploadPostSuccessful !== this.props.InventoryLotLoadFileUploadPostSuccessful && this.props.InventoryLotLoadFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }
            this.props.inventoryLotLoadFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramLoadID, null, null, null, 0)
        }



    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramLoadID = queryStringParse(values.loadid)
        }
        catch (e) {
            var paramLoadID = null
        }

        try {
            var paramWarehouseID = queryStringParse(values.warehouseid)
        }
        catch (e) {
            var paramWarehouseID = null
        }

        try {
            var paramCustomerID = queryStringParse(values.customerid)
        }
        catch (e) {
            var paramCustomerID = null
        }


        this.props.inventoryLoadPalletCaseUnitGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, null, null, null, null)

        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID);

        // this.props.inventoryLotSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, null, null, null, null, 1, null)
        // this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, null, null, null, null, 1, null)




        this.props.inventoryLoadSKUsHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, paramLoadID, null, null, null, null, null, null)

        this.props.inventoryLoadCasesHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, paramLoadID, null, null, null, null, null)
        this.props.inventoryLoadPalletsHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, paramLoadID, null, null)

        // this does not load
        this.props.inventoryLoadLotGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)



        this.props.inventoryLotLoadFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramLoadID, null, null, null, 0)
   


    }



    render() {

        return (

            <LoadDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                inventoryLotSkuGet={this.props.inventoryLotSkuGet}
                isInventoryLotSkuLoading={this.props.isInventoryLotSkuLoading}
                InventoryLotSkuData={this.props.InventoryLotSkuData}

                isInventoryDestinationLoading={this.props.isInventoryDestinationLoading}
                InventoryDestinationData={this.props.InventoryDestinationData}

                inventorySkuWarehouseLocGet={this.props.inventorySkuWarehouseLocGet}
                // isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                // InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}
                SkuLocationDataGrouped={this.props.SkuLocationDataGrouped}

                InventoryLoadPalletCaseSkuPostSuccessful={this.props.InventoryLoadPalletCaseSkuPostSuccessful}
                inventoryLoadPalletCaseSkuPost={this.props.inventoryLoadPalletCaseSkuPost}

                inventoryLoadRemoveSkuPost={this.props.inventoryLoadRemoveSkuPost}
                InventoryLoadRemoveSkuPostSuccessful={this.props.InventoryLoadRemoveSkuPostSuccessful}




                inventorySkuWarehouseLocationHistoryGet={this.props.inventorySkuWarehouseLocationHistoryGet}
                isInventorySkuWarehouseLocationHistoryLoading={this.props.isInventorySkuWarehouseLocationHistoryLoading}
                InventorySkuWarehouseLocationHistoryData={this.props.InventorySkuWarehouseLocationHistoryData}


                inventoryLoadSkuLocationHistoryGet={this.props.inventoryLoadSkuLocationHistoryGet}
                isInventoryLoadSkuLocationHistoryLoading={this.props.isInventoryLoadSkuLocationHistoryLoading}
                InventoryLoadSkuLocationHistoryData={this.props.InventoryLoadSkuLocationHistoryData}


                isInventoryLoadPalletCaseUnitLoading={this.props.isInventoryLoadPalletCaseUnitLoading}
                InventoryLoadPalletCaseUnitData={this.props.InventoryLoadPalletCaseUnitData}

                inventoryLoadPalletCaseUnitGet={this.props.inventoryLoadPalletCaseUnitGet}



                isInventoryLoadSKUsHistoryLoading={this.props.isInventoryLoadSKUsHistoryLoading}
                InventoryLoadSKUsHistoryData={this.props.InventoryLoadSKUsHistoryData}


                isInventoryLoadCasesHistoryLoading={this.props.isInventoryLoadCasesHistoryLoading}
                InventoryLoadCasesHistoryData={this.props.InventoryLoadCasesHistoryData}

                isInventoryLoadPalletsHistoryLoading={this.props.isInventoryLoadPalletsHistoryLoading}
                InventoryLoadPalletsHistoryData={this.props.InventoryLoadPalletsHistoryData}


                isInventoryLoadLotLoading={this.props.isInventoryLoadLotLoading}
                InventoryLoadLotData={this.props.InventoryLoadLotData}

                InventoryLoadRemovePalletCaseSkuPostSuccessful={this.props.InventoryLoadRemovePalletCaseSkuPostSuccessful}
                inventoryLoadRemovePalletCaseSkuPost={this.props.inventoryLoadRemovePalletCaseSkuPost}






                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}

                isInventoryLotLoadFileUploadLoading={this.props.isInventoryLotLoadFileUploadLoading}
                InventoryLotLoadFileUploadData={this.props.InventoryLotLoadFileUploadData}
                InventoryLotLoadFileUploadPostSuccessful={this.props.InventoryLotLoadFileUploadPostSuccessful}



                inventoryLotLoadFileUploadGet={this.props.inventoryLotLoadFileUploadGet}
                inventoryLotLoadFileUploadPost={this.props.inventoryLotLoadFileUploadPost}


            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadDetailsContainer));




