/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';



/* ################################### EXTERNAL API ACTION TYPES ################################### */
export const CUSTOMS_CITY_API_GET = 'CUSTOMS_CITY_API_GET';
export const CUSTOMS_CITY_API_COMPLETE = 'CUSTOMS_CITY_API_COMPLETE';
export const CUSTOMS_CITY_API_ERROR = 'CUSTOMS_CITY_API_ERROR';



/* ################################### VAS ACTIONS TYPES ################################### */
export const ON_ERROR = 'ON_ERROR';




export const CUSTOMS_CITY_MESSAGES_GET = 'CUSTOMS_CITY_MESSAGES_GET';
export const CUSTOMS_CITY_MESSAGES_COMPLETE = 'CUSTOMS_CITY_MESSAGES_COMPLETE';
export const CUSTOMS_CITY_MESSAGES_ERROR = 'CUSTOMS_CITY_MESSAGES_ERROR';


export const DASHBOARD_SUMMARY_GET = 'DASHBOARD_SUMMARY_GET';
export const DASHBOARD_SUMMARY_COMPLETE = 'DASHBOARD_SUMMARY_COMPLETE';
export const DASHBOARD_SUMMARY_ERROR = 'DASHBOARD_SUMMARY_ERROR';

export const MAWB_GET = 'MAWB_GET';
export const MAWB_COMPLETE = 'MAWB_COMPLETE';
export const MAWB_ERROR = 'MAWB_ERROR';

export const POST_MAWB_GET = 'POST_MAWB_GET';
export const POST_MAWB_COMPLETE = 'POST_MAWB_COMPLETE';
export const POST_MAWB_ERROR = 'POST_MAWB_ERROR';

export const POST_MAWB_ADD_GET = 'POST_MAWB_ADD_GET';
export const POST_MAWB_ADD_COMPLETE = 'POST_MAWB_ADD_COMPLETE';
export const POST_MAWB_ADD_ERROR = 'POST_MAWB_ADD_ERROR';

export const MAWB_RECOVERY_GET = 'MAWB_RECOVERY_GET';
export const MAWB_RECOVERY_COMPLETE = 'MAWB_RECOVERY_COMPLETE';
export const MAWB_RECOVERY_ERROR = 'MAWB_RECOVERY_ERROR';

export const POST_MAWB_RECOVERY_GET = 'POST_MAWB_RECOVERY_GET';
export const POST_MAWB_RECOVERY_COMPLETE = 'POST_MAWB_RECOVERY_COMPLETE';
export const POST_MAWB_RECOVERY_ERROR = 'POST_MAWB_RECOVERY_ERROR';

export const POST_MAWB_PICKUP_GET = 'POST_MAWB_PICKUP_GET';
export const POST_MAWB_PICKUP_COMPLETE = 'POST_MAWB_PICKUP_COMPLETE';
export const POST_MAWB_PICKUP_ERROR = 'POST_MAWB_PICKUP_ERROR';

export const MAWB_STATUS_GET = 'MAWB_STATUS_GET';
export const MAWB_STATUS_COMPLETE = 'MAWB_STATUS_COMPLETE';
export const MAWB_STATUS_ERROR = 'MAWB_STATUS_ERROR';

export const POST_MAWB_STATUS_GET = 'POST_MAWB_STATUS_GET';
export const POST_MAWB_STATUS_COMPLETE = 'POST_MAWB_STATUS_COMPLETE';
export const POST_MAWB_STATUS_ERROR = 'POST_MAWB_STATUS_ERROR';

export const POST_MAWB_EVOLVE_DATA_GET = 'POST_MAWB_EVOLVE_DATA_GET';
export const POST_MAWB_EVOLVE_DATA_COMPLETE = 'POST_MAWB_EVOLVE_DATA_COMPLETE';
export const POST_MAWB_EVOLVE_DATA_ERROR = 'POST_MAWB_EVOLVE_DATA_ERROR';

export const MAWB_CARRIER_GET = 'MAWB_CARRIER_GET';
export const MAWB_CARRIER_COMPLETE = 'MAWB_CARRIER_COMPLETE';
export const MAWB_CARRIER_ERROR = 'MAWB_CARRIER_ERROR';

export const HAWB_GET = 'HAWB_GET';
export const HAWB_COMPLETE = 'HAWB_COMPLETE';
export const HAWB_ERROR = 'HAWB_ERROR';

export const POST_HAWB_GET = 'POST_HAWB_GET';
export const POST_HAWB_COMPLETE = 'POST_HAWB_COMPLETE';
export const POST_HAWB_ERROR = 'POST_HAWB_ERROR';

export const HAWB_RECOVERY_GET = 'HAWB_RECOVERY_GET';
export const HAWB_RECOVERY_COMPLETE = 'HAWB_RECOVERY_COMPLETE';
export const HAWB_RECOVERY_ERROR = 'HAWB_RECOVERY_ERROR';

export const POST_HAWB_RECOVERY_GET = 'POST_HAWB_RECOVERY_GET';
export const POST_HAWB_RECOVERY_COMPLETE = 'POST_HAWB_RECOVERY_COMPLETE';
export const POST_HAWB_RECOVERY_ERROR = 'POST_HAWB_RECOVERY_ERROR';

export const HAWB_CONSIGNEE_GET = 'HAWB_CONSIGNEE_GET';
export const HAWB_CONSIGNEE_COMPLETE = 'HAWB_CONSIGNEE_COMPLETE';
export const HAWB_CONSIGNEE_ERROR = 'HAWB_CONSIGNEE_ERROR';

export const HAWB_DESTINATION_GET = 'HAWB_DESTINATION_GET';
export const HAWB_DESTINATION_COMPLETE = 'HAWB_DESTINATION_COMPLETE';
export const HAWB_DESTINATION_ERROR = 'HAWB_DESTINATION_ERROR';


export const LOAD_GET = 'LOAD_GET';
export const LOAD_COMPLETE = 'LOAD_COMPLETE';
export const LOAD_ERROR = 'LOAD_ERROR';

export const LOAD_HAWB_GET = 'LOAD_HAWB_GET';
export const LOAD_HAWB_COMPLETE = 'LOAD_HAWB_COMPLETE';
export const LOAD_HAWB_ERROR = 'LOAD_HAWB_ERROR';

export const POST_LOAD_GET = 'POST_LOAD_GET';
export const POST_LOAD_COMPLETE = 'POST_LOAD_COMPLETE';
export const POST_LOAD_ERROR = 'POST_LOAD_ERROR';

export const POST_LOAD_REMOVE_GET = 'POST_LOAD_REMOVE_GET';
export const POST_LOAD_REMOVE_COMPLETE = 'POST_LOAD_REMOVE_COMPLETE';
export const POST_LOAD_REMOVE_ERROR = 'POST_LOAD_REMOVE_ERROR';


export const POST_LOAD_EDIT_GET = 'POST_LOAD_EDIT_GET';
export const POST_LOAD_EDIT_COMPLETE = 'POST_LOAD_EDIT_COMPLETE';
export const POST_LOAD_EDIT_ERROR = 'POST_LOAD_EDIT_ERROR';

export const LOAD_HAWB_HISTORY_GET = 'LOAD_HAWB_HISTORY_GET';
export const LOAD_HAWB_HISTORY_COMPLETE = 'LOAD_HAWB_HISTORY_COMPLETE';
export const LOAD_HAWB_HISTORY_ERROR = 'LOAD_HAWB_HISTORY_ERROR';

export const OUTBOUND_GET = 'OUTBOUND_GET';
export const OUTBOUND_COMPLETE = 'OUTBOUND_COMPLETE';
export const OUTBOUND_ERROR = 'OUTBOUND_ERROR';


export const POST_USER_COLOR_GET = 'POST_USER_COLOR_GET';
export const POST_USER_COLOR_COMPLETE = 'POST_USER_COLOR_COMPLETE';
export const POST_USER_COLOR_ERROR = 'POST_USER_COLOR_ERROR';

export const XDOCK_POST_HAWB_RECOVERED_SHORT_GET = 'XDOCK_POST_HAWB_RECOVERED_SHORT_GET';
export const XDOCK_POST_HAWB_RECOVERED_SHORT_COMPLETE = 'XDOCK_POST_HAWB_RECOVERED_SHORT_COMPLETE';
export const XDOCK_POST_HAWB_RECOVERED_SHORT_ERROR = 'XDOCK_POST_HAWB_RECOVERED_SHORT_ERROR';



export const XDOCK_MAWB_FILEUPLOAD_GET = 'XDOCK_MAWB_FILEUPLOAD_GET';
export const XDOCK_MAWB_FILEUPLOAD_COMPLETE = 'XDOCK_MAWB_FILEUPLOAD_COMPLETE';
export const XDOCK_MAWB_FILEUPLOAD_ERROR = 'XDOCK_MAWB_FILEUPLOAD_ERROR';

export const XDOCK_POST_MAWB_FILEUPLOAD_GET = 'XDOCK_POST_MAWB_FILEUPLOAD_GET';
export const XDOCK_POST_MAWB_FILEUPLOAD_COMPLETE = 'XDOCK_POST_MAWB_FILEUPLOAD_COMPLETE';
export const XDOCK_POST_MAWB_FILEUPLOAD_ERROR = 'XDOCK_POST_MAWB_FILEUPLOAD_ERROR';



export const XDOCK_LOAD_FILEUPLOAD_GET = 'XDOCK_LOAD_FILEUPLOAD_GET';
export const XDOCK_LOAD_FILEUPLOAD_COMPLETE = 'XDOCK_LOAD_FILEUPLOAD_COMPLETE';
export const XDOCK_LOAD_FILEUPLOAD_ERROR = 'XDOCK_LOAD_FILEUPLOAD_ERROR';

export const XDOCK_POST_LOAD_FILEUPLOAD_GET = 'XDOCK_POST_LOAD_FILEUPLOAD_GET';
export const XDOCK_POST_LOAD_FILEUPLOAD_COMPLETE = 'XDOCK_POST_LOAD_FILEUPLOAD_COMPLETE';
export const XDOCK_POST_LOAD_FILEUPLOAD_ERROR = 'XDOCK_POST_LOAD_FILEUPLOAD_ERROR';

export const XDOCK_TRINITY_DATA_FILE_GET = 'XDOCK_TRINITY_DATA_FILE_GET';
export const XDOCK_TRINITY_DATA_FILE_GET_COMPLETE = 'XDOCK_TRINITY_DATA_FILE_GET_COMPLETE';
export const XDOCK_TRINITY_DATA_FILE_GET_ERROR = 'XDOCK_TRINITY_DATA_FILE_GET_ERROR';

export const XDOCK_TRINITY_DATA_GET = 'XDOCK_TRINITY_DATA_GET';
export const XDOCK_TRINITY_DATA_GET_COMPLETE = 'XDOCK_TRINITY_DATA_GET_COMPLETE';
export const XDOCK_TRINITY_DATA_GET_ERROR = 'XDOCK_TRINITY_DATA_GET_ERROR';


export const XDOCK_LOAD_PALLET_CARTON_GET = 'XDOCK_LOAD_PALLET_CARTON_GET';
export const XDOCK_LOAD_PALLET_CARTON_GET_COMPLETE = 'XDOCK_LOAD_PALLET_CARTON_GET_COMPLETE';
export const XDOCK_LOAD_PALLET_CARTON_GET_ERROR = 'XDOCK_LOAD_PALLET_CARTON_GET_ERROR';

export const XDOCK_LOAD_PALLET_CARTON_POST = 'XDOCK_LOAD_PALLET_CARTON_POST';
export const XDOCK_LOAD_PALLET_CARTON_POST_COMPLETE = 'XDOCK_LOAD_PALLET_CARTON_POST_COMPLETE';
export const XDOCK_LOAD_PALLET_CARTON_POST_ERROR = 'XDOCK_LOAD_PALLET_CARTON_POST_ERROR';

export const XDOCK_LOAD_PALLET_POST = 'XDOCK_LOAD_PALLET_POST';
export const XDOCK_LOAD_PALLET_POST_COMPLETE = 'XDOCK_LOAD_PALLET_POST_COMPLETE';
export const XDOCK_LOAD_PALLET_POST_ERROR = 'XDOCK_LOAD_PALLET_POST_ERROR';

export const XDOCK_LOAD_PALLET_GET = 'XDOCK_LOAD_PALLET_GET';
export const XDOCK_LOAD_PALLET_GET_COMPLETE = 'XDOCK_LOAD_PALLET_GET_COMPLETE';
export const XDOCK_LOAD_PALLET_GET_ERROR = 'XDOCK_LOAD_PALLET_GET_ERROR';



export const XDOCK_CUSTOMS_CITY_MANIFEST_HISTORY_GET = 'XDOCK_CUSTOMS_CITY_MANIFEST_HISTORY_GET';
export const XDOCK_CUSTOMS_CITY_MANIFEST_HISTORY_GET_COMPLETE = 'XDOCK_CUSTOMS_CITY_MANIFEST_HISTORY_GET_COMPLETE';
export const XDOCK_CUSTOMS_CITY_MANIFEST_HISTORY_GET_ERROR = 'XDOCK_CUSTOMS_CITY_MANIFEST_HISTORY_GET_ERROR';