import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import {
    Form,
    Col,
    Loader,
    Row,
    Button,
    Panel
} from 'rsuite';
import { queryStringParse, queryStringParseWithZero, findLowestMissingNumber, formatDateForUrlSearch, stripCharacters, formatStringForAPI } from '../../../resources/logic/functions';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import VisibilityPackageDataElements from '../../dataComponentsVisibility/VisibilityPackageDataElements';
import queryString from 'query-string';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "PackageID"




class Tracking extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Search Values
            isFilterShown: true,
            searchDateStart: null,
            searchDateEnd: null,
            searchTrackingNo: null

        };

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        this.onClickResetFilters = this.onClickResetFilters.bind(this);
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);

        //Search Values
        this.onChangeSearchDateStart = this.onChangeSearchDateStart.bind(this);
        this.onChangeSearchDateEnd = this.onChangeSearchDateEnd.bind(this);
        this.onChangeSearchTrackingNo = this.onChangeSearchTrackingNo.bind(this);

        //Shipment List 
        this.onClickShipDetails = this.onClickShipDetails.bind(this);


    }





    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection
        this.loadSortFromStorage()

        this.updateParamsFromURL()

    }

    getsessionStorageWarehouse() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
    }


    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var date = queryStringParse(values.datestart)
            if (date === null) {
                var paramDateStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramDateStart = dayAdd
            }
        }
        catch (e) {
            var paramDateStart = null
        }
        if (paramDateStart != null) {
            this.onChangeSearchDateStart(paramDateStart)
        }

        try {
            var date = queryStringParse(values.dateend)
            if (date === null) {
                var paramDateEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramDateEnd = dayAdd
            }
        }
        catch (e) {
            var paramDateEnd = null
        }
        this.onChangeSearchDateEnd(paramDateEnd)


        try {
            var paramTrackingNo = stripCharacters(values.trackingno)
        }
        catch (e) {
            var paramTrackingNo = null
        }
        if (paramTrackingNo != null) {
            this.onChangeSearchTrackingNo(paramTrackingNo)
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                // checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                // checkedKeys: []
            });
        }

    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }

    // Search Filters
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchDateStart: null,
            searchDateEnd: null,
            searchTrackingNo: null

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        try {
            var searchDateStart = this.state.searchDateStart
            var searchDateEnd = this.state.searchDateEnd
            var searchTrackingNo = formatStringForAPI(this.state.searchTrackingNo)

            if (searchDateStart == null && searchTrackingNo == null) {
                window.alert('Please select Start Date.')
            }
            else {
                this.props.history.push({
                    search:
                        'datestart=' + formatDateForUrlSearch(searchDateStart) + '&' +
                        'dateend=' + formatDateForUrlSearch(searchDateEnd) + '&' +
                        'trackingno=' + formatStringForAPI( searchTrackingNo)
                });
            }
        }
        catch (e) { }

    }

    onSearchClearOtherFilters() {
        this.setState({
            searchDateStart: null,
            searchDateEnd: null,
            searchTrackingNo: null

        });
    }
    onChangeSearchDateStart(e) {
        this.setState({
            searchDateStart: e
        });
    }
    onChangeSearchDateEnd(e) {
        this.setState({
            searchDateEnd: e
        });
    }

    onChangeSearchTrackingNo(e) {
        this.setState({
            searchTrackingNo: e
        });
    }

    //Table on click details
    onClickShipDetails(e, rowData, history) {
        try {
            var paramID = rowData['PackageID']
            history.push({
                pathname: 'packagedetails',
                search: 'packageid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }

    }



    render() {
        const { sortColumn, sortType } = this.state;


        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >

                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Tracking #',
                                this.onChangeSearchTrackingNo,
                                this.props.history,
                                'trackingno',
                                this.state.searchTrackingNo
                            )}
                        </Row>

                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Date',
                                this.onChangeSearchDateStart,
                                this.onChangeSearchDateEnd,
                                this.state.searchDateStart,
                                this.state.searchDateEnd
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }




        let trackingTableDiv;
        if (this.props.isVisibilityDashboardPackageListLoading) {
            trackingTableDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.VisibilityDashboardPackageListData[0]) !== 'undefined' && this.props.VisibilityDashboardPackageListData != null) {
            trackingTableDiv =
                <Form fluid>
                    {RTableGeneral(
                        tableIdCol,     //   keyColumn,
                        this.props.VisibilityDashboardPackageListData,     //   tableData,
                        VisibilityPackageDataElements,       //   DataElements,
                        this.props.isVisibilityDashboardPackageListLoading,        //   loading,

                        this.props.isMobile,        //   isMobile,
                        this.props.history,     //   history,

                        null,
                        null,
                        null,

                        // sortColumn,     //   sortColumn,
                        // sortType,       //   sortType,
                        // handleSortColumn,      //   handleSortColumn,

                        null, // checkColEntry,
                        null,       //   checkedKeys,
                        null,       //   handleCheckAll,
                        null,       //   handleCheck,

                        false,      //   onClickAddMilestone,
                        null,       //   onClickSelect,
                        null,       //   selectButtonText,
                        null,       //   selectHighlightRowKey,

                        true,       //   link1bool,
                        'Details',     //   link1text,
                        this.onClickShipDetails,     //   link1onClick,

                        false,       //   link2bool,
                        null,       //   link2text,
                        null  //   link2onClick
                    )
                    }

                    <div>

                        {(this.props.isMobile == true) ? <div></div> :
                            <div>
                                <Row>
                                    <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'PackageList_' + currentDateTime}>
                                        <ExcelSheet data={this.props.isVisibilityDashboardPackageListLoading} name="PackageList">
                                            {
                                                VisibilityPackageDataElements.map(
                                                    ({ dBKey, displayName }, idx) => {
                                                        return (
                                                            <ExcelColumn label={displayName} value={dBKey} />
                                                        );
                                                    }
                                                )
                                            }
                                        </ExcelSheet>
                                    </ExcelFile>
                                </Row>
                            </div>
                        }

                    </div>

                </Form >

        }







        return (
            <div>
                <h3>
                    Visibility - Tracking
                </h3>

                <br />
                <br />

                {hideShowSearch}
                {searchDiv}

                {trackingTableDiv}
            </div >
        );

    }
}

export default withRouter(Tracking);


