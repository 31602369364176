import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import { Row, Col, Panel, Loader, Button, Nav, } from 'rsuite';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatStringForAPI, formatDateForUrlSearch, formatNumberForAPI, convertNanToZero, isNumeric, formatDateTimeForUrlSearch, formatTrueFalseToBit } from '../../../../resources/logic/functions';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { containerStatus } from '../../../../app/appConfig';
import RSearchInputNumberAsStringWithoutUrlPush from '../../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsStringWithoutUrlPush';
import RDatePickerRange from '../../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import InventoryLotReportingStorageDataElements from '../../../dataComponentsInventory/InventoryLotReportingStorageDataElements';
import InventoryLotReportingInboundDataElements from '../../../dataComponentsInventory/InventoryLotReportingInboundDataElements';

const tableIdCol = 'id'

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

const styles = {
    marginBottom: 15
};


const CustomNav = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={styles}>

            <Nav.Item eventKey="inbound">Inbound</Nav.Item>
            <Nav.Item eventKey="storage">Storage</Nav.Item>

        </Nav>
    );
};

class Reporting extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //Nav
            reportNav: 'inbound',

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,
            searchRecoveryDtStart: null,
            searchRecoveryDtEnd: null,

            isFilterShown: true,


        };
        //Nav
        this.handleReportNavSelect = this.handleReportNavSelect.bind(this);
        this.onClickReportNav = this.onClickReportNav.bind(this);

        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeSearchContainer = this.onChangeSearchContainer.bind(this);
        this.onChangeSearchSKU = this.onChangeSearchSKU.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);
        this.onChangesearchRecoveryDtStart = this.onChangesearchRecoveryDtStart.bind(this);
        this.onChangesearchRecoveryDtEnd = this.onChangesearchRecoveryDtEnd.bind(this);

    }


    //Nav
    handleReportNavSelect(activeKey) {
        this.setState({ reportNav: activeKey });
    }

    onClickReportNav(activeKey) {
        this.handleReportNavSelect(activeKey)



        if (activeKey != this.state.reportnav) {
            // this.onClickApplySearch()

            // this.props.history.push({
            //     search:
            //         'reportnav=' + activeKey
            // });



            var sDT = null
            var eDT = null

            if (this.state.searchRecoveryDtStart === null) {
                sDT = null
            }
            else {
                sDT = "'" + moment(this.state.searchRecoveryDtStart).format('YYYY-MM-DD') + "'"
            }
            if (this.state.searchRecoveryDtEnd === null) {
                eDT = null
            }
            else {
                eDT = "'" + moment(this.state.searchRecoveryDtEnd).format('YYYY-MM-DD') + "'"
            }


            if (this.props.warehouseSelection == null) {
                // window.alert('Please select a warehouse and customer.')
            }
            else if (this.props.customerSelection == null) {
                // window.alert('Please select a customer.')
            }
            else {
                try {
                    // var reportNav = this.state.reportNav

                    var searchStatus = this.state.searchStatus
                    var searchContainer = this.state.searchContainer
                    var searchSKU = this.state.searchSKU

                    this.props.history.push({
                        search:
                            'reportnav=' + activeKey + '&' +
                            'container=' + searchContainer + '&' +
                            'sku=' + searchSKU + '&' +
                            'containerstatus=' + searchStatus + '&' +
                            'recoverydtstart=' + sDT + '&' +
                            'recoverydtend=' + eDT


                    });
                }
                catch (e) { }
            }
        }
        else {

        }
    }



    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
        this.getsessionStorageData() //this will preserve menu selection
        this.loadWarehouseToState()
        this.loadCustomerToState()

        const values = queryString.parse(this.props.location.search)

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            })
        }

        if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            })
        }

        try {
            var date = queryStringParse(values.recoverydtstart)
            if (date === null) {
                var paramRecoveryDtStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDtStart = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDtStart = null
        }
        this.onChangesearchRecoveryDtStart(paramRecoveryDtStart)

        try {
            var date = queryStringParse(values.recoverydtend)
            if (date === null) {
                var paramRecoveryDtEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDtEnd = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDtEnd = null
        }
        this.onChangesearchRecoveryDtEnd(paramRecoveryDtEnd)
    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramReportNav = queryStringParse(values.reportnav)
        }
        catch (e) {
            var paramReportNav = null
        }
        if (paramReportNav != null) {
            this.handleReportNavSelect(paramReportNav)
        }

        try {
            var paramContainer = queryStringParse(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        if (paramContainer != null) {
            this.onChangeSearchContainer(paramContainer)
        }

        try {
            var paramSKU = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        if (paramSKU != null) {
            this.onChangeSearchSKU(paramSKU)
        }

        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }






        try {
            var date = queryStringParse(values.recoverydtstart)
            if (date === null) {
                var paramRecoveryDateStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateStart = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        if (paramRecoveryDateStart != null) {
            this.onChangesearchRecoveryDtStart(paramRecoveryDateStart)
        }

        try {
            var date = queryStringParse(values.recoverydtend)
            if (date === null) {
                var paramRecoveryDateEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateEnd = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }
        this.onChangesearchRecoveryDtEnd(paramRecoveryDateEnd)


    }




    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


    }

    loadWarehouseToState() {
        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
    }

    loadCustomerToState() {
        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
    }



    getsessionStorageData() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }
        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }












    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,
            searchRecoveryDtStart: null,
            searchRecoveryDtEnd: null,
        });

        // this.props.history.push({
        //     search: ''
        // });

        var reportNav = this.state.reportNav

        this.props.history.push({
            search:
                'reportnav=' + reportNav
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {


        var sDT = null
        var eDT = null

        if (this.state.searchRecoveryDtStart === null) {
            sDT = null
        }
        else {
            sDT = "'" + moment(this.state.searchRecoveryDtStart).format('YYYY-MM-DD') + "'"
        }
        if (this.state.searchRecoveryDtEnd === null) {
            eDT = null
        }
        else {
            eDT = "'" + moment(this.state.searchRecoveryDtEnd).format('YYYY-MM-DD') + "'"
        }


        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var reportNav = this.state.reportNav

                var searchStatus = this.state.searchStatus
                var searchContainer = this.state.searchContainer
                var searchSKU = this.state.searchSKU

                this.props.history.push({
                    search:
                        'reportnav=' + reportNav + '&' +
                        'container=' + searchContainer + '&' +
                        'sku=' + searchSKU + '&' +
                        'containerstatus=' + searchStatus + '&' +
                        'recoverydtstart=' + sDT + '&' +
                        'recoverydtend=' + eDT


                });
            }
            catch (e) { }
        }


    }


    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchRecoveryDtStart: null,
            searchRecoveryDtEnd: null,
        });

    }

    onChangeSearchContainer(e) {
        // this.onSearchClearOtherFilters()
        this.setState({
            searchContainer: e,
            // searchSKU: ''
        });
    }

    onChangeSearchSKU(e) {
        // this.onSearchClearOtherFilters()
        this.setState({
            searchSKU: e,
            // searchContainer: ''
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }

    onChangesearchRecoveryDtStart(e) {
        if (this.state.searchRecoveryDtEnd === null) {
            var endDt = moment().format('dddd DD MMMM YYYY')
            this.setState({
                searchRecoveryDtStart: e,
                searchRecoveryDtEnd: endDt
            });
        }
        else {
            this.setState({
                searchRecoveryDtStart: e
            });
        }
    }
    onChangesearchRecoveryDtEnd(e) {
        this.setState({
            searchRecoveryDtEnd: e
        });
    }



    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'containerdetails',
                search: 'containerid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }


    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;


        if (this.props.isInventoryLotReportingStorageLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Reports can take a while to generate, please be patient...</p>
                </div>
            );
        }

        // if (this.props.isInventoryLotReportingStorageLoading) {

        //     storageDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //                 size="md"
        //                 content="Reports can take a while to generate, please be patient..."
        //             />
        //         </div>
        // }





        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let inboundSearch;
        inboundSearch = <div> <Row>
            <Col md={24} sm={24} >
                {RSearchInputNumberAsStringWithoutUrlPush(
                    this.props.isMobile,
                    'Container',
                    this.onChangeSearchContainer,
                    this.props.history,
                    'container',
                    this.state.searchContainer
                )}
            </Col>
        </Row>

            <Row>
                <Col md={24} sm={24} >
                    {RSearchInputNumberAsStringWithoutUrlPush(
                        this.props.isMobile,
                        'SKU',
                        this.onChangeSearchSKU,
                        this.props.history,
                        'sku',
                        this.state.searchSKU
                    )}
                </Col>
            </Row>

            <Row>
                {RSelectPicker(
                    this.props.isMobile,
                    containerStatus,
                    this.state.searchStatus,
                    'Container Status',
                    this.onChangeSearchStatus,
                    this.props.history,
                    mountRef
                )}
            </Row>

            <Row>
                {RDatePickerRange(
                    this.props.isMobile,
                    'Recovery Date',
                    this.onChangesearchRecoveryDtStart,
                    this.onChangesearchRecoveryDtEnd,
                    this.state.searchRecoveryDtStart,
                    this.state.searchRecoveryDtEnd
                )}
            </Row>
        </div>


        let storageSearch;
        storageSearch = <div>
            <Row>
                {RDatePickerRange(
                    this.props.isMobile,
                    'Date',
                    this.onChangesearchRecoveryDtStart,
                    this.onChangesearchRecoveryDtEnd,
                    this.state.searchRecoveryDtStart,
                    this.state.searchRecoveryDtEnd
                )}
            </Row>
        </div>

        let searchDiv;
        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <CustomNav appearance="tabs" active={this.state.reportNav} onSelect={this.onClickReportNav} />
                        <div>
                            {(this.state.reportNav === 'storage') ?
                                storageSearch :
                                inboundSearch
                            }
                        </div>


                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isInventoryLotReportingInboundLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                        size="md"
                        content="Reports can take a while to generate, please be patient..."
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotReportingInboundData) !== 'undefined' && this.props.InventoryLotReportingInboundData != null) {



            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.InventoryLotReportingInboundData,     //   tableData,
                InventoryLotReportingInboundDataElements,       //   DataElements,
                this.props.isInventoryLotReportingInboundLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                false,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick

            )



            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'InventoryReporting_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryLotReportingInboundData} name="InventoryReporting">
                                    {
                                        InventoryLotReportingInboundDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }


        let storageDiv;
        let storageTable;
        if (this.props.isInventoryLotReportingStorageLoading) {

            storageDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                        size="md"
                        content="Reports can take a while to generate, please be patient..."
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotReportingStorageData) !== 'undefined' && this.props.InventoryLotReportingStorageData != null) {



            storageTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.InventoryLotReportingStorageData,     //   tableData,
                InventoryLotReportingStorageDataElements,       //   DataElements,
                this.props.isInventoryLotReportingStorageLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                false,       //   link1bool,
                null,     //   link1text,
                this.onClickDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick

            )



            storageDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'InventoryReporting_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryLotReportingStorageData} name="InventoryReporting">
                                    {
                                        InventoryLotReportingStorageDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            storageTable = <div></div>
            storageDiv = <div></div>
        }




        let tableDiv;
        if (this.state.reportNav === 'storage') {
            tableDiv = <div>
                {storageDiv}
                {storageTable}
            </div>
        }
        else {
            tableDiv = <div>
                {mawbDiv}
                {rTable}
            </div>
        }



        return (
            <div>
                <h3>
                    Inventory Management - Reporting
                </h3>


                {hideShowSearch}
                {searchDiv}

                {tableDiv}




            </div>
        );

    }
}

export default withRouter(Reporting);

