import { connect } from 'react-redux';
import React, { Component } from 'react';
import QuoteShipmentDetails from './QuoteShipmentDetails';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../redux/actions/actionsUser';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isCtrlTwrCountryLoading: store.ctrlTwrReducer.isCtrlTwrCountryLoading,
    CtrlTwrCountryData: store.ctrlTwrReducer.CtrlTwrCountryData,

    isCtrlTwrStateLoading: store.ctrlTwrReducer.isCtrlTwrStateLoading,
    CtrlTwrStateData: store.ctrlTwrReducer.CtrlTwrStateData,

    isCtrlTwrModeRefLoading: store.ctrlTwrReducer.isCtrlTwrModeRefLoading,
    CtrlTwrModeRefData: store.ctrlTwrReducer.CtrlTwrModeRefData,

    isCtrlTwrAirportLoading: store.ctrlTwrReducer.isCtrlTwrAirportLoading,
    CtrlTwrAirportData: store.ctrlTwrReducer.CtrlTwrAirportData,

    isCtrlTwrSeaportLoading: store.ctrlTwrReducer.isCtrlTwrSeaportLoading,
    CtrlTwrSeaportData: store.ctrlTwrReducer.CtrlTwrSeaportData,

    isCtrlTwrQuotingShipmentLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentLoading,
    CtrlTwrQuotingShipmentData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentData,

    isCtrlTwrQuotingShipmentLegsQuoteLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentLegsQuoteLoading,
    CtrlTwrQuotingShipmentLegsQuoteData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentLegsQuoteData,

    CtrlTwrQuotingShipmentLegsPostSuccessful: store.ctrlTwrReducer.CtrlTwrQuotingShipmentLegsPostSuccessful,

    CtrlTwrQuotingShipmentPostSuccessful: store.ctrlTwrReducer.CtrlTwrQuotingShipmentPostSuccessful,

    CtrlTwrShipmentLegsCustomerRefPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsCustomerRefPostSuccessful,

    isCtrlTwrShipmentLegsCustomerRefLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsCustomerRefLoading,
    CtrlTwrShipmentLegsCustomerRefData: store.ctrlTwrReducer.CtrlTwrShipmentLegsCustomerRefData,


    isCtrlTwrTimezoneLoading: store.ctrlTwrReducer.isCtrlTwrTimezoneLoading,
    CtrlTwrTimezoneData: store.ctrlTwrReducer.CtrlTwrTimezoneData,


    isCtrlTwrQuotingShipmentFileUploadLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentFileUploadLoading,
    CtrlTwrQuotingShipmentFileUploadData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentFileUploadData,

    CtrlTwrQuotingShipmentFileUploadPostSuccessful: store.ctrlTwrReducer.CtrlTwrQuotingShipmentFileUploadPostSuccessful,


    isCtrlTwrQuotingShipmentNotesPrivateLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentNotesPrivateLoading,
    CtrlTwrQuotinghipmentNotesPrivateData: store.ctrlTwrReducer.CtrlTwrQuotinghipmentNotesPrivateData,

    CtrlTwrShipmentNotesPrivatePostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPrivatePostSuccessful,

    isCtrlTwrQuotingShipmentNotesPublicLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentNotesPublicLoading,
    CtrlTwrQuotingShipmentNotesPublicData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentNotesPublicData,

    CtrlTwrShipmentNotesPublicPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPublicPostSuccessful,

    CtrlTwrQuotingShipmentStatusActionPostSuccessful: store.ctrlTwrReducer.CtrlTwrQuotingShipmentStatusActionPostSuccessful,


    isCtrlTwrQuotingShipmentLineItemsLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentLineItemsLoading,
    CtrlTwrQuotingShipmentLineItemsData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentLineItemsData,

    isCtrlTwrQuotingLineItemLoading: store.ctrlTwrReducer.isCtrlTwrQuotingLineItemLoading,
    CtrlTwrQuotingLineItemData: store.ctrlTwrReducer.CtrlTwrQuotingLineItemData,

    CtrlTwrShipmentLegsLineItemPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsLineItemPostSuccessful,


    isCtrlTwrQuotingLineItemUomLoading: store.ctrlTwrReducer.isCtrlTwrQuotingLineItemUomLoading,
    CtrlTwrQuotingLineItemUomData: store.ctrlTwrReducer.CtrlTwrQuotingLineItemUomData,

    isCtrlTwrQuotingLineItemRateTypeLoading: store.ctrlTwrReducer.isCtrlTwrQuotingLineItemRateTypeLoading,
    CtrlTwrQuotingLineItemRateTypeData: store.ctrlTwrReducer.CtrlTwrQuotingLineItemRateTypeData,

});

const mapDispatchToProps = dispatch =>
({

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },

    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrCountryGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrCountryGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrStateGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrStateGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrAirportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrAirportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrSeaportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrSeaportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    ctrlTwrQuotingShipmentGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _QuotingStatusID) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _QuotingStatusID))
    },

    ctrlTwrQuotingShipmentLegsPost: (_email, _authToken, _LegID, _DeleteLeg, _ProRef, _LegNumber, _ModeID, _Carrier, _Shipper_Name, _Origin_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentLegsPost(_email, _authToken, _LegID, _DeleteLeg, _ProRef, _LegNumber, _ModeID, _Carrier, _Shipper_Name, _Origin_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode))
    },
    ctrlTwrQuotingShipmentStatusActionGet: (_email, _authToken, _ProRef, _ShipmentQuoteActive, _QuotingStatusID,) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentStatusActionGet(_email, _authToken, _ProRef, _ShipmentQuoteActive, _QuotingStatusID))
    },
    ctrlTwrQuotingShipmentLegsQuoteGet: (_email, _authToken, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentLegsQuoteGet(_email, _authToken, _ProRef))
    },

    ctrlTwrShipmentLegsCustomerRefGet: (_email, _authToken, _ProRef, _LegID, _HbolID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsCustomerRefGet(_email, _authToken, _ProRef, _LegID, _HbolID))
    },



    ctrlTwrShipmentLegsCustomerRefPost: (_email, _authToken, _CustomerRefID, _LegID, _HbolID, _CustomerRef, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsCustomerRefPost(_email, _authToken, _CustomerRefID, _LegID, _HbolID, _CustomerRef, _Delete))
    },



    ctrlTwrQuotingShipmentPost: (_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _ShipmentQuoteActive, _QuotingStatusID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentPost(_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _ShipmentQuoteActive, _QuotingStatusID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity))
    },


    ctrlTwrTimezoneGet: (_email, _authToken,) => {
        dispatch(ctrlTwrActions.ctrlTwrTimezoneGet(_email, _authToken,))
    },


    ctrlTwrQuotingShipmentFileUploadGet: (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentFileUploadGet(_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete))
    },
    ctrlTwrQuotingShipmentFileUploadPost: (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete, MoveFileToCtrlTwr) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentFileUploadPost(_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete, MoveFileToCtrlTwr))
    },

    ctrlTwrQuotingShipmentPrivateNotesGet: (_email, _authToken, _ShipmentNoteID, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentPrivateNotesGet(_email, _authToken, _ShipmentNoteID, _ProRef))
    },
    ctrlTwrShipmentPrivateNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPrivateNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete))
    },
    ctrlTwrQuotingShipmentPublicNotesGet: (_email, _authToken, _ShipmentNoteID, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentPublicNotesGet(_email, _authToken, _ShipmentNoteID, _ProRef))
    },
    ctrlTwrShipmentPublicNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPublicNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete))
    },

    ctrlTwrQuotingShipmentLineItemsGet: (_email, _authToken, _ProRef, _LegID, _LegLineItemID) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentLineItemsGet(_email, _authToken, _ProRef, _LegID, _LegLineItemID))
    },

    ctrlTwrQuotingLineItemGet: (_email, _authToken, _ModeID, _QuoteLineItemID) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingLineItemGet(_email, _authToken, _ModeID, _QuoteLineItemID))
    },

    ctrlTwrQuotingShipmentLegsLineItemPost: (_email, _authToken, _LegLineItemID, _LegID, _QuoteLineItemID, _ProRef, _LegNumber, _ModeID, _DeleteLineItem, _SellRate, _BuyRate, _QuoteLineItemUom, _QuoteLineItemRateType, _LineItemNotes, _LineItemSeq) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentLegsLineItemPost(_email, _authToken, _LegLineItemID, _LegID, _QuoteLineItemID, _ProRef, _LegNumber, _ModeID, _DeleteLineItem, _SellRate, _BuyRate, _QuoteLineItemUom, _QuoteLineItemRateType, _LineItemNotes, _LineItemSeq))
    },


    ctrlTwrQuotingLineItemUomGet: (_email, _authToken, _ModeID, _QuoteLineItemUomID) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingLineItemUomGet(_email, _authToken, _ModeID, _QuoteLineItemUomID))
    },
    ctrlTwrQuotingLineItemRateTypeGet: (_email, _authToken, _ModeID, _QuoteLineItemRateTypeID) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingLineItemRateTypeGet(_email, _authToken, _ModeID, _QuoteLineItemRateTypeID))
    },

});

class QuoteShipmentDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.ctrlTwrQuotingLineItemGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null)


        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }


        if (prevProps.CtrlTwrQuotingShipmentLegsPostSuccessful !== this.props.CtrlTwrQuotingShipmentLegsPostSuccessful && this.props.CtrlTwrQuotingShipmentLegsPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.CtrlTwrQuotingShipmentStatusActionPostSuccessful !== this.props.CtrlTwrQuotingShipmentStatusActionPostSuccessful && this.props.CtrlTwrQuotingShipmentStatusActionPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.CtrlTwrQuotingShipmentFileUploadPostSuccessful !== this.props.CtrlTwrQuotingShipmentFileUploadPostSuccessful && this.props.CtrlTwrQuotingShipmentFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrQuotingShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
        }



        if (prevProps.CtrlTwrQuotingShipmentPostSuccessful !== this.props.CtrlTwrQuotingShipmentPostSuccessful && this.props.CtrlTwrQuotingShipmentPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrQuotingShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, null, null, null, null, null)

        }


    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = formatStringForAPI(values.pro)
        }
        catch (e) {
            var paramPro = null
        }
        this.props.ctrlTwrQuotingShipmentLineItemsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        this.props.ctrlTwrQuotingLineItemUomGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)
        this.props.ctrlTwrQuotingLineItemRateTypeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)

        this.props.ctrlTwrModeRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrAirportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrSeaportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.ctrlTwrCountryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrStateGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.ctrlTwrQuotingShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, null, null, null, null, null)

        this.props.ctrlTwrQuotingShipmentLegsQuoteGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
        this.props.ctrlTwrShipmentLegsCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        this.props.ctrlTwrTimezoneGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken)
        this.props.ctrlTwrQuotingShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
        this.props.ctrlTwrQuotingShipmentPrivateNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)
        this.props.ctrlTwrQuotingShipmentPublicNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)


    }



    render() {

        return (

            <QuoteShipmentDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                organizationSelection={this.props.organizationSelection}

                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}

                isCtrlTwrCountryLoading={this.props.isCtrlTwrCountryLoading}
                CtrlTwrCountryData={this.props.CtrlTwrCountryData}

                isCtrlTwrStateLoading={this.props.isCtrlTwrStateLoading}
                CtrlTwrStateData={this.props.CtrlTwrStateData}

                isCtrlTwrModeRefLoading={this.props.isCtrlTwrModeRefLoading}
                CtrlTwrModeRefData={this.props.CtrlTwrModeRefData}

                isCtrlTwrAirportLoading={this.props.isCtrlTwrAirportLoading}
                CtrlTwrAirportData={this.props.CtrlTwrAirportData}

                isCtrlTwrSeaportLoading={this.props.isCtrlTwrSeaportLoading}
                CtrlTwrSeaportData={this.props.CtrlTwrSeaportData}

                isCtrlTwrQuotingShipmentLoading={this.props.isCtrlTwrQuotingShipmentLoading}
                CtrlTwrQuotingShipmentData={this.props.CtrlTwrQuotingShipmentData}

                ctrlTwrQuotingShipmentLegsPost={this.props.ctrlTwrQuotingShipmentLegsPost}

                ctrlTwrQuotingShipmentLegsQuoteGet={this.props.ctrlTwrQuotingShipmentLegsQuoteGet}
                isCtrlTwrQuotingShipmentLegsQuoteLoading={this.props.isCtrlTwrQuotingShipmentLegsQuoteLoading}
                CtrlTwrQuotingShipmentLegsQuoteData={this.props.CtrlTwrQuotingShipmentLegsQuoteData}

                CtrlTwrShipmentLegsCustomerRefPostSuccessful={this.props.CtrlTwrShipmentLegsCustomerRefPostSuccessful}

                isCtrlTwrShipmentLegsCustomerRefLoading={this.props.isCtrlTwrShipmentLegsCustomerRefLoading}
                CtrlTwrShipmentLegsCustomerRefData={this.props.CtrlTwrShipmentLegsCustomerRefData}

                ctrlTwrShipmentLegsCustomerRefGet={this.props.ctrlTwrShipmentLegsCustomerRefGet}

                ctrlTwrShipmentLegsCustomerRefPost={this.props.ctrlTwrShipmentLegsCustomerRefPost}

                CtrlTwrQuotingShipmentPostSuccessful={this.props.CtrlTwrQuotingShipmentPostSuccessful}
                ctrlTwrQuotingShipmentPost={this.props.ctrlTwrQuotingShipmentPost}

                isCtrlTwrTimezoneLoading={this.props.isCtrlTwrTimezoneLoading}
                CtrlTwrTimezoneData={this.props.CtrlTwrTimezoneData}

                CtrlTwrHotShipmentPostSuccessful={this.props.CtrlTwrHotShipmentPostSuccessful}
                ctrlTwrHotShipmentPost={this.props.ctrlTwrHotShipmentPost}

                ctrlTwrQuotingShipmentFileUploadGet={this.props.ctrlTwrQuotingShipmentFileUploadGet}
                ctrlTwrQuotingShipmentFileUploadPost={this.props.ctrlTwrQuotingShipmentFileUploadPost}
                isCtrlTwrQuotingShipmentFileUploadLoading={this.props.isCtrlTwrQuotingShipmentFileUploadLoading}
                CtrlTwrQuotingShipmentFileUploadData={this.props.CtrlTwrQuotingShipmentFileUploadData}
                CtrlTwrQuotingShipmentFileUploadPostSuccessful={this.props.CtrlTwrQuotingShipmentFileUploadPostSuccessful}

                isCtrlTwrQuotingShipmentNotesPrivateLoading={this.props.isCtrlTwrQuotingShipmentNotesPrivateLoading}
                CtrlTwrQuotinghipmentNotesPrivateData={this.props.CtrlTwrQuotinghipmentNotesPrivateData}

                CtrlTwrShipmentNotesPrivatePostSuccessful={this.props.CtrlTwrShipmentNotesPrivatePostSuccessful}

                isCtrlTwrQuotingShipmentNotesPublicLoading={this.props.isCtrlTwrQuotingShipmentNotesPublicLoading}
                CtrlTwrQuotingShipmentNotesPublicData={this.props.CtrlTwrQuotingShipmentNotesPublicData}

                CtrlTwrShipmentNotesPublicPostSuccessful={this.props.CtrlTwrShipmentNotesPublicPostSuccessful}

                ctrlTwrQuotingShipmentPrivateNotesGet={this.props.ctrlTwrQuotingShipmentPrivateNotesGet}
                ctrlTwrShipmentPrivateNotesPost={this.props.ctrlTwrShipmentPrivateNotesPost}
                ctrlTwrQuotingShipmentPublicNotesGet={this.props.ctrlTwrQuotingShipmentPublicNotesGet}
                ctrlTwrShipmentPublicNotesPost={this.props.ctrlTwrShipmentPublicNotesPost}

                CtrlTwrQuotingShipmentLegsPostSuccessful={this.props.CtrlTwrQuotingShipmentLegsPostSuccessful}

                ctrlTwrQuotingShipmentStatusActionGet={this.props.ctrlTwrQuotingShipmentStatusActionGet}
                CtrlTwrQuotingShipmentStatusActionPostSuccessful={this.props.CtrlTwrQuotingShipmentStatusActionPostSuccessful}


                isCtrlTwrQuotingLineItemLoading={this.props.isCtrlTwrQuotingLineItemLoading}
                CtrlTwrQuotingLineItemData={this.props.CtrlTwrQuotingLineItemData}

                isCtrlTwrQuotingShipmentLineItemsLoading={this.props.isCtrlTwrQuotingShipmentLineItemsLoading}
                CtrlTwrQuotingShipmentLineItemsData={this.props.CtrlTwrQuotingShipmentLineItemsData}

                CtrlTwrShipmentLegsLineItemPostSuccessful={this.props.CtrlTwrShipmentLegsLineItemPostSuccessful}
                ctrlTwrQuotingShipmentLegsLineItemPost={this.props.ctrlTwrQuotingShipmentLegsLineItemPost}

                ctrlTwrQuotingLineItemGet={this.props.ctrlTwrQuotingLineItemGet}


                isCtrlTwrQuotingLineItemUomLoading={this.props.isCtrlTwrQuotingLineItemUomLoading}
                CtrlTwrQuotingLineItemUomData={this.props.CtrlTwrQuotingLineItemUomData}
            
                isCtrlTwrQuotingLineItemRateTypeLoading={this.props.isCtrlTwrQuotingLineItemRateTypeLoading}
                CtrlTwrQuotingLineItemRateTypeData={this.props.CtrlTwrQuotingLineItemRateTypeData}


            />



        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteShipmentDetailsContainer));



