
export const UserAccountCustomerDataElements = [
    {
        dBKey: 'CustomerName',
        displayName: 'Customer Name',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CustomerCode',
        displayName: 'Customer Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Admin',
        displayName: 'Admin',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ReadWrite',
        displayName: 'Read/Write',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },

    
]

export default UserAccountCustomerDataElements

