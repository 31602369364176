import React, {
    PureComponent
} from 'react';

import { withRouter } from 'react-router-dom';
import OutboundDataElements from '../../dataComponentsXdock/OutboundDataElements';
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import { Loader, Button, Row, Col, Panel } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import { loadStatus } from '../../../app/appConfig';
import queryString from 'query-string';
import { queryStringParse, formatDateForUrlSearch } from '../../../resources/logic/functions';
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "HouseID"

const mountRef = React.createRef();

class Outbound extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchHouseRef: '',
            searchMaster: '',
            searchOrderNo: '',
            searchSealNo: '',
            searchDepartDateStart: null,
            searchDepartDateEnd: null,
            searchDestinationID: null,



            // @HouseRef varchar(50)  = null,
            // @Master varchar(50)  = null,
            // @OrderNo varchar(50) = null,
            // @DepartDateStart datetime = null,
            // @DepartDateEnd datetime = null,
            // @DestinationID int = null,


            isFilterShown: true,


        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeSearchHouseRef = this.onChangeSearchHouseRef.bind(this);
        this.onChangeSearchMaster = this.onChangeSearchMaster.bind(this);
        this.onChangeSearchOrderNo = this.onChangeSearchOrderNo.bind(this);
        this.onChangeSearchSealNo = this.onChangeSearchSealNo.bind(this);
        this.onChangeSearchDepartDateStart = this.onChangeSearchDepartDateStart.bind(this);
        this.onChangeSearchDepartDateEnd = this.onChangeSearchDepartDateEnd.bind(this);
        this.onChangeSearchDestinationID = this.onChangeSearchDestinationID.bind(this);


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

    }




    componentDidMount() {
        this.loadSortFromStorage()
        const values = queryString.parse(this.props.location.search)
        // var defaultWarehouse = null
        // try {
        //     var defaultWarehouse = queryStringParse(values.warehouseid)
        // }
        // catch (e) {
        //     var defaultWarehouse = null
        // }
        // this.onChangeWarehouseSelection(defaultWarehouse)


        try {
            var paramHouseRef = queryStringParse(values.houseref)
        }
        catch (e) {
            var paramHouseRef = null
        }
        if (paramHouseRef != null) {
            this.onChangeSearchHouseRef(paramHouseRef)
        }
        try {
            var paramMaster = queryStringParse(values.master)
        }
        catch (e) {
            var paramMaster = null
        }
        if (paramMaster != null) {
            this.onChangeSearchMaster(paramMaster)
        }
        try {
            var paramOrderNo = queryStringParse(values.orderno)
        }
        catch (e) {
            var paramOrderNo = null
        }
        if (paramOrderNo != null) {
            this.onChangeSearchOrderNo(paramOrderNo)
        }
        try {
            var paramSealNo = queryStringParse(values.sealno)
        }
        catch (e) {
            var paramSealNo = null
        }
        if (paramSealNo != null) {
            this.onChangeSearchSealNo(paramSealNo)
        }
        try {
            var paramDepartDateStart = queryStringParse(values.departdatestart)
        }
        catch (e) {
            var paramDepartDateStart = null
        }
        this.onChangeSearchDepartDateStart(paramDepartDateStart)
        try {
            var paramDepartDateEnd = queryStringParse(values.departdateend)
        }
        catch (e) {
            var paramDepartDateEnd = null
        }
        this.onChangeSearchDepartDateEnd(paramDepartDateEnd)
        try {
            var paramDestination = queryStringParse(values.destinationid)
        }
        catch (e) {
            var paramDestination = null
        }
        this.onChangeSearchDestinationID(paramDestination)

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.UserWarehouseData !== this.props.UserWarehouseData && this.props.UserWarehouseData.length > 0) {
    //         var defaultWarehouse = null
    //         try {
    //             if (this.props.UserWarehouseData.length == 1) {
    //                 defaultWarehouse = this.props.UserWarehouseData[0]['value']
    //             }
    //             else {
    //                 defaultWarehouse = null
    //             }
    //         }
    //         catch (e) {
    //         }
    //         this.onChangeWarehouseSelection(defaultWarehouse)

    //         try {
    //             const values = queryString.parse(this.props.location.search)
    //             var defaultWarehouse = null
    //             try {
    //                 var defaultWarehouse = queryStringParse(values.warehouseid)
    //             }
    //             catch (e) {
    //                 var defaultWarehouse = null
    //             }
    //             this.onChangeWarehouseSelection(defaultWarehouse)
    //         }
    //         catch (e) { }

    //     }
    // }





    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType){
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) {}       
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) {}
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) {}
    }
    loadSortFromStorage(){
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) {}
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) {}
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) {}
        if ( pathName === this.props.location.pathname){
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    //Search Functions
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        // var defaultWarehouse = null
        // try {
        //     if (this.props.UserWarehouseData.length == 1) {
        //         defaultWarehouse = this.props.UserWarehouseData[0]['value']
        //     }
        //     else {
        //         defaultWarehouse = null
        //     }
        // }
        // catch (e) {
        // }


        this.setState({
            searchHouseRef: '',
            searchMaster: '',
            searchOrderNo: '',
            searchSealNo: '',
            searchDepartDateStart: null,
            searchDepartDateEnd: null,
            searchDestinationID: null
            // warehouseSelection: defaultWarehouse

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchHouseRef: '',
            searchMaster: '',
            searchOrderNo: '',
            searchSealNo: '',
        });

        try {
            // var searchHouseRef = this.state.searchHouseRef
            // var searchMaster = this.state.searchMaster
            // var searchOrderNo = this.state.searchOrderNo
            var searchDepartDateStart = this.state.searchDepartDateStart
            var searchDepartDateEnd = this.state.searchDepartDateEnd
            var searchDestinationID = this.state.searchDestinationID

            this.props.history.push({
                search:
                    // 'houseref=' + searchHouseRef + '&' +
                    // 'master=' + searchMaster + '&' +
                    // 'orderno=' + searchOrderNo + '&' +
                    'departdatestart=' + formatDateForUrlSearch(searchDepartDateStart) + '&' +
                    'departdateend=' + formatDateForUrlSearch(searchDepartDateEnd) + '&' +
                    'destinationid=' + searchDestinationID
                    // 'warehouseid=' + this.state.warehouseSelection
            });
        }
        catch (e) { }
    }


    onSearchClearOtherFilters(){
        this.setState({
            searchDepartDateStart: null,
            searchDepartDateEnd: null,
            searchDestinationID: null
        });
    }


    onChangeSearchMaster(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchMaster: e,
            searchHouseRef: '',
            searchOrderNo: '',
            searchSealNo: '',
        });
    }
    onChangeSearchHouseRef(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchHouseRef: e,
            searchMaster: '',
            searchOrderNo: '',
            searchSealNo: '',
        });
    }
    onChangeSearchOrderNo(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchOrderNo: e,
            searchHouseRef: '',
            searchMaster: '',
            searchSealNo: '',
        });
    }
    onChangeSearchSealNo(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchOrderNo: '',
            searchHouseRef: '',
            searchMaster: '',
            searchSealNo: e,
        });
    }
    onChangeSearchDepartDateStart(e) {
        this.setState({
            searchDepartDateStart: e
        });
    }
    onChangeSearchDepartDateEnd(e) {
        this.setState({
            searchDepartDateEnd: e
        });
    }
    onChangeSearchDestinationID(e) {
        this.setState({
            searchDestinationID: e
        });
    }


    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'housedetails',
                search: 'houseid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }





    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;




        let hideShowSearch;
        hideShowSearch = <Row  style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>


        // let warehouseSelectionDiv;
        // if (this.props.isUserWarehouseLoading) {
        //     warehouseSelectionDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else {

        //     warehouseSelectionDiv =
        //         <Row>
        //             {RSelectPicker(
        //                 this.props.isMobile,
        //                 this.props.UserWarehouseData,
        //                 this.state.warehouseSelection,
        //                 'Warehouse',
        //                 this.onChangeWarehouseSelection,
        //                 this.props.history
        //             )}
        //         </Row>


        // }




        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Master',
                                this.onChangeSearchMaster,
                                this.props.history,
                                'master',
                                this.state.searchMaster
                            )}
                        </Row>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'House Ref',
                                this.onChangeSearchHouseRef,
                                this.props.history,
                                'houseref',
                                this.state.searchHouseRef
                            )}
                        </Row>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PO #',
                                this.onChangeSearchOrderNo,
                                this.props.history,
                                'orderno',
                                this.state.searchOrderNo
                            )}
                        </Row>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Seal #',
                                this.onChangeSearchSealNo,
                                this.props.history,
                                'sealno',
                                this.state.searchSealNo
                            )}
                        </Row>
                    </Panel>
                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.HawbDestinationData,
                                this.state.searchDestinationID,
                                'Destination',
                                this.onChangeSearchDestinationID,
                                this.props.history,
                                mountRef
                            )}
                        </Row>
                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Depart Date',
                                this.onChangeSearchDepartDateStart,
                                this.onChangeSearchDepartDateEnd,
                                this.state.searchDepartDateStart,
                                this.state.searchDepartDateEnd
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }




        let loadDiv;
        let rTable;
        if (this.props.isOutboundLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.OutboundData) !== 'undefined' && this.props.OutboundData != null) {

            rTable = RTable(
                this.props.OutboundData,        // tableData,
                OutboundDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isOutboundLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                false, // checkColEntry,
                // true,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                checkedKeys,                       // checkedKeys,
                this.handleCheckAll,                       // handleCheckAll,
                this.handleCheck,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey
                false,                            // editLink,
                null,                            // editLinkText,
                this.onClickExportLoad                            // editLinkOnClick
            )

            loadDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Outbound_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.OutboundData} name="Outbound">

                                    {
                                        OutboundDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }


        return (
            <div>
                <h3>
                    Airfreight - Outbound
                </h3>


                {hideShowSearch}
                {searchDiv}


                {loadDiv}
                {rTable}


            </div>
        );

    }
}

export default withRouter(Outbound);

