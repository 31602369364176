import { connect } from 'react-redux';
import React, { Component } from 'react';
import Warehouse from './Warehouse'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({
    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    isInventoryDestinationLoading: store.inventoryReducer.isInventoryDestinationLoading,
    InventoryDestinationData: store.inventoryReducer.InventoryDestinationData,

});

const mapDispatchToProps = dispatch =>
({
    inventorySkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventoryDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

});

class WarehouseContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.LoadPostSuccessful !== this.props.LoadPostSuccessful && this.props.LoadPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatus = queryStringParse(values.skustatusid)
        }
        catch (e) {
            var paramStatus = null
        }
        try {
            var paramMbol = formatStringForAPI(values.mbol)
        }
        catch (e) {
            var paramMbol = null
        }
        try {
            var paramHbol = formatStringForAPI(values.hbol)
        }
        catch (e) {
            var paramHbol = null
        }
        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }


        try {
            var paramRecoveryDateStart = formatDateForAPI(values.recoverydatestart)
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        try {
            var paramRecoveryDateEnd = formatDateForAPI(values.recoverydateend)
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }


        try {
            var paramConsigneeID = formatDateForAPI(values.consigneeid)
        }
        catch (e) {
            var paramConsigneeID = null
        }

        try {
            var paramConsigneeName = formatDateForAPI(values.consigneename)
        }
        catch (e) {
            var paramConsigneeName = null
        }

        try {
            var paramCustomerRefID = queryStringParse(values.customerrefid)
        }
        catch (e) {
            var paramCustomerRefID = null
        }

        try {
            var paramCustomerRef = formatDateForAPI(values.customerref)
        }
        catch (e) {
            var paramCustomerRef = null
        }
        try {
            var paramWarehouseLocationId = formatDateForAPI(values.warehouselocationid)
        }
        catch (e) {
            var paramWarehouseLocationId = null
        }



        this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramSku, null, paramContainer, paramStatus, paramWarehouseLocationId)
        this.props.inventoryDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

    }



    render() {

        return (

            <Warehouse
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}

                isInventoryDestinationLoading={this.props.isInventoryDestinationLoading}
                InventoryDestinationData={this.props.InventoryDestinationData}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WarehouseContainer));



