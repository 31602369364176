export const AddToLoadPoDataElements = [
    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'PoStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerRef',
        displayName: 'Customer Ref',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Project',
        displayName: 'Project',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    // {
    //     dBKey: 'Weight',
    //     displayName: 'Weight',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    {
        dBKey: 'CaseCount',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CasesReceived',
        displayName: 'Cases Received',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CasesLoaded',
        displayName: 'Cases Loaded',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'PalletCount',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'LoadedPallets',
        displayName: 'Loaded Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    {
        dBKey: 'PoNotes',
        displayName: 'Notes',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    // {
    //     dBKey: 'CustomsCleared',
    //     displayName: 'Customs Cleared',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },

    // {
    //     dBKey: 'Orders',
    //     displayName: 'Orders',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },

    // {
    //     dBKey: 'DeliveryAddress',
    //     displayName: 'Destination',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    // {
    //     dBKey: 'DCCode',
    //     displayName: 'DC Code',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },


]


export default AddToLoadPoDataElements

