import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as actions from '../../../redux/actions/labelGenActions';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { appSettings } from '../../../app/appSettings'
import { withRouter } from 'react-router-dom';
import LabelGenHistory from './LabelGenHistory';
import queryString from 'query-string';
import { customerAccountInfo } from '../../../app/appConfig';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,


    isLabelGenResponsePackageLabelsLoading: store.labelGenReducer.isLabelGenResponsePackageLabelsLoading,
    LabelGenResponsePackageLabelsData: store.labelGenReducer.LabelGenResponsePackageLabelsData,














    isLabelGenApiRequestLoading: store.labelGenReducer.isLabelGenApiRequestLoading,
    LabelGenApiRequestErrorCode: store.labelGenReducer.LabelGenApiRequestErrorCode,
    LabelGenApiRequestJson: store.labelGenReducer.LabelGenApiRequestJson,

    isBulkShipRequestLoading: store.labelGenReducer.isBulkShipRequestLoading,
    BulkLabelGenApiRequestJson: store.labelGenReducer.BulkLabelGenApiRequestJson,
    BulkShipRequestResponseCounter: store.labelGenReducer.BulkShipRequestResponseCounter,

    isLabelCreateHistoryLoading: store.labelGenReducer.isLabelCreateHistoryLoading,
    LabelCreateHistoryData: store.labelGenReducer.LabelCreateHistoryData,



    isLabelGenRequestPackageLoading: store.labelGenReducer.isLabelGenRequestPackageLoading,
    LabelGenRequestPackageData: store.labelGenReducer.LabelGenRequestPackageData,

    LabelGenRequestPackagePostSuccessful: store.labelGenReducer.LabelGenRequestPackagePostSuccessful,
    RequestPackageID: store.labelGenReducer.RequestPackageID,

    isLabelGenRequestPackageProductInfoLoading: store.labelGenReducer.isLabelGenRequestPackageProductInfoLoading,
    LabelGenRequestPackageProductInfoData: store.labelGenReducer.LabelGenRequestPackageProductInfoData,

    LabelGenRequestPackageProductInfoPostSuccessful: store.labelGenReducer.LabelGenRequestPackageProductInfoPostSuccessful,
    RequestProductInfoID: store.labelGenReducer.RequestProductInfoID,

    LabelGenRequestPostSuccessful: store.labelGenReducer.LabelGenRequestPostSuccessful,
    LabelRequestID: store.labelGenReducer.LabelRequestID,


    isLabelGenRequestCustomerWarehouseUserSavedValuesLoading: store.labelGenReducer.isLabelGenRequestCustomerWarehouseUserSavedValuesLoading,
    LabelGenRequestCustomerWarehouseUserSavedValuesData: store.labelGenReducer.LabelGenRequestCustomerWarehouseUserSavedValuesData,

    LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful: store.labelGenReducer.LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful,
    UserSavedValuesID: store.labelGenReducer.UserSavedValuesID,



    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,



    
});

const mapDispatchToProps = dispatch =>
({

    labelGenResponsePackageLabelsGet: (_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID, _TrackingNumber, _ShipmentIdentificationNumber) => {
        dispatch(actions.labelGenResponsePackageLabelsGet(_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID, _TrackingNumber, _ShipmentIdentificationNumber))
    },






    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },




    UpsLabelGenerationAction: (_authToken, _param, _prodEnv) => {
        dispatch(actions.UpsLabelGenerationAction(_authToken, _param, _prodEnv))
    },

    BulkUpsLabelGenerationAction: (_authToken, _param, _prodEnv) => {
        dispatch(actions.BulkUpsLabelGenerationAction(_authToken, _param, _prodEnv))
    },







    labelGenRequestPost: (_email, _authToken,_LabelRequestID, _CustomerID, _WarehouseID, _CarrierID, _ProdEnvironment, _RequestDT, _ResponseSuccess, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipTo_Name, _ShipTo_AttentionName, _ShipTo_CompanyDisplayableName, _ShipTo_TaxIdentificationNumber, _ShipTo_Phone_Number, _ShipTo_Phone_Extension, _ShipTo_FaxNumber, _ShipTo_EMailAddress, _ShipTo_AddressLine1, _ShipTo_AddressLine2, _ShipTo_AddressLine3, _ShipTo_Address_City, _ShipTo_Address_StateProvinceCode, _ShipTo_Address_PostalCode, _ShipTo_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _CarrierServiceID, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _Delete, _LabelPortID) => {
        dispatch(actions.labelGenRequestPost(_email, _authToken,_LabelRequestID, _CustomerID, _WarehouseID, _CarrierID, _ProdEnvironment, _RequestDT, _ResponseSuccess, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipTo_Name, _ShipTo_AttentionName, _ShipTo_CompanyDisplayableName, _ShipTo_TaxIdentificationNumber, _ShipTo_Phone_Number, _ShipTo_Phone_Extension, _ShipTo_FaxNumber, _ShipTo_EMailAddress, _ShipTo_AddressLine1, _ShipTo_AddressLine2, _ShipTo_AddressLine3, _ShipTo_Address_City, _ShipTo_Address_StateProvinceCode, _ShipTo_Address_PostalCode, _ShipTo_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _CarrierServiceID, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _Delete, _LabelPortID))
    },

    labelGenRequestPackageGet: (_email, _authToken, _RequestPackageID, _LabelRequestID) => {
        dispatch(actions.labelGenRequestPackageGet(_email, _authToken, _RequestPackageID, _LabelRequestID))
    },

    labelGenRequestPackagePost: (_email, _authToken, _RequestPackageID, _LabelRequestID, _PackageIndex, _Package_Description, _Package_Packaging_Code, _Package_Dimensions_UOM_Code, _Package_Dimensions_Length, _Package_Dimensions_Width, _Package_Dimensions_Height, _Package_PackageWeight_UOM_Code, _Package_PackageWeight_UOM_Description, _Package_PackageWeight_Weight, _Package_PkgSO_DeliveryConfirmation_DCISType, _Package_PkgSO_DeclaredValue_CurrencyCode, _Package_PkgSO_DeclaredValue_MonetaryValue, _Delete,) => {
        dispatch(actions.labelGenRequestPackagePost(_email, _authToken, _RequestPackageID, _LabelRequestID, _PackageIndex, _Package_Description, _Package_Packaging_Code, _Package_Dimensions_UOM_Code, _Package_Dimensions_Length, _Package_Dimensions_Width, _Package_Dimensions_Height, _Package_PackageWeight_UOM_Code, _Package_PackageWeight_UOM_Description, _Package_PackageWeight_Weight, _Package_PkgSO_DeliveryConfirmation_DCISType, _Package_PkgSO_DeclaredValue_CurrencyCode, _Package_PkgSO_DeclaredValue_MonetaryValue, _Delete,))
    },

    labelGenRequestPackageProductInfoGet: (_email, _authToken, _RequestProductInfoID, _RequestPackageID, _LabelRequestID,) => {
        dispatch(actions.labelGenRequestPackageProductInfoGet(_email, _authToken, _RequestProductInfoID, _RequestPackageID, _LabelRequestID,))
    },

    labelGenRequestPackageProductInfoPost: (_email, _authToken, _RequestProductInfoID, _RequestPackageID, _PackageIndex, _LabelRequestID, _ProductInfoIndex, _ProductCode, _ProductDescription, _ProductUnitValue, _CurrencyCode, _ProductItemOrigin, _ProductHarmonizedCode, _ProductUnitWeight, _ProductQuantity, _ProductFabricContent, _Hazmat, _HazmatCode, _FDAFlag, _FDACode, _Delete) => {
        dispatch(actions.labelGenRequestPackageProductInfoPost(_email, _authToken, _RequestProductInfoID, _RequestPackageID, _PackageIndex, _LabelRequestID, _ProductInfoIndex, _ProductCode, _ProductDescription, _ProductUnitValue, _CurrencyCode, _ProductItemOrigin, _ProductHarmonizedCode, _ProductUnitWeight, _ProductQuantity, _ProductFabricContent, _Hazmat, _HazmatCode, _FDAFlag, _FDACode, _Delete))
    },




    labelGenRequestCustomerWarehouseUserSavedValuesGet: (_email, _authToken, _CustomerID, _WarehouseID, _CarrierID, _UserSavedValuesID, _ProdEnvironment) => {
        dispatch(actions.labelGenRequestCustomerWarehouseUserSavedValuesGet(_email, _authToken, _CustomerID, _WarehouseID, _CarrierID, _UserSavedValuesID, _ProdEnvironment))
    },
    labelGenRequestCustomerWarehouseUserSavedValuesPost: (_email, _authToken, _UserSavedValuesID, _CustomerID, _WarehouseID, _CarrierID, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _ProdEnvironment, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _PrinterIP, _PrinterPort, _CarrierServiceID, _Delete) => {
        dispatch(actions.labelGenRequestCustomerWarehouseUserSavedValuesPost(_email, _authToken, _UserSavedValuesID, _CustomerID, _WarehouseID, _CarrierID, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _ProdEnvironment, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _PrinterIP, _PrinterPort, _CarrierServiceID, _Delete))
    },



});





class LabelGenHistoryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CustomerName: '',
            EvolveClientID: '',
            CustomerNumber: '',
            ReturnName: '',
            ReturnAddressLine1: '',
            ReturnAddressLine2: '',
            ReturnCity: '',
            ReturnState: '',
            ReturnPostcode: '',
            ReturnCountryCode: ''
        };
    }


    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }


        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }


        // if (prevProps.HawbRecoverySaveSuccessful !== this.props.HawbRecoverySaveSuccessful && this.props.HawbRecoverySaveSuccessful) {
        //     setTimeout(() => {
        //         this.handleUrlParam()
        //     }, 1000);
        // }
        // else {
        // }


    }




    handleUrlParam = () => {
        var evolveCustomerInfo_CustomerName = ''
        var evolveCustomerInfo_EvolveClientID = ''
        var evolveCustomerInfo_CustomerNumber = ''

        var returnAddress_ReturnName = ''
        var returnAddress_ReturnAddressLine1 = ''
        var returnAddress_ReturnAddressLine2 = ''
        var returnAddress_ReturnCity = ''
        var returnAddress_ReturnState = ''
        var returnAddress_ReturnPostcode = ''
        var returnAddress_ReturnCountryCode = ''


        const values = queryString.parse(this.props.location.search)

        if (typeof (values.customer) !== 'undefined' && values.customer != null) {
            evolveCustomerInfo_CustomerName = values.customer
            evolveCustomerInfo_EvolveClientID = customerAccountInfo[evolveCustomerInfo_CustomerName].EvolveClientID
            evolveCustomerInfo_CustomerNumber = customerAccountInfo[evolveCustomerInfo_CustomerName].CustomerNumber

            returnAddress_ReturnName = customerAccountInfo[evolveCustomerInfo_CustomerName].ReturnName
            returnAddress_ReturnAddressLine1 = customerAccountInfo[evolveCustomerInfo_CustomerName].ReturnAddressLine1
            returnAddress_ReturnAddressLine2 = customerAccountInfo[evolveCustomerInfo_CustomerName].ReturnAddressLine2
            returnAddress_ReturnCity = customerAccountInfo[evolveCustomerInfo_CustomerName].ReturnCity
            returnAddress_ReturnState = customerAccountInfo[evolveCustomerInfo_CustomerName].ReturnState
            returnAddress_ReturnPostcode = customerAccountInfo[evolveCustomerInfo_CustomerName].ReturnPostcode
            returnAddress_ReturnCountryCode = customerAccountInfo[evolveCustomerInfo_CustomerName].ReturnCountryCode
        }

        this.setState({
            CustomerName: evolveCustomerInfo_CustomerName,
            EvolveClientID: evolveCustomerInfo_EvolveClientID,
            CustomerNumber: evolveCustomerInfo_CustomerNumber,
            ReturnName: returnAddress_ReturnName,
            ReturnAddressLine1: returnAddress_ReturnAddressLine1,
            ReturnAddressLine2: returnAddress_ReturnAddressLine2,
            ReturnCity: returnAddress_ReturnCity,
            ReturnState: returnAddress_ReturnState,
            ReturnPostcode: returnAddress_ReturnPostcode,
            ReturnCountryCode: returnAddress_ReturnCountryCode
        });




        // var paramLabelRequestID = null
        // if (typeof (values.labelrequestid) !== 'undefined' && values.labelrequestid != null) {
        //     paramLabelRequestID = values.labelrequestid
        // }


        //Load all label data if ID is specified
        // if (paramLabelRequestID !== null) {
        //     this.props.labelGenRequestPackageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramLabelRequestID)
        //     this.props.labelGenRequestPackageProductInfoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLabelRequestID)
        // }

      this.props.labelGenResponsePackageLabelsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, 1, null, null, 1, null, null, null);

    }


    render() {

        return (

            <LabelGenHistory

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}


                routeCustomer='evolve'



                isLabelGenApiRequestLoading={this.props.isLabelGenApiRequestLoading}
                LabelGenApiRequestErrorCode={this.props.LabelGenApiRequestErrorCode}
                LabelGenApiRequestJson={this.props.LabelGenApiRequestJson}

                UpsLabelGenerationAction={this.props.UpsLabelGenerationAction}

                BulkUpsLabelGenerationAction={this.props.BulkUpsLabelGenerationAction}
                isBulkShipRequestLoading={this.props.isBulkShipRequestLoading}
                BulkLabelGenApiRequestJson={this.props.BulkLabelGenApiRequestJson}
                BulkShipRequestResponseCounter={this.props.BulkShipRequestResponseCounter}

                CustomerName={this.state.CustomerName}
                EvolveClientID={this.state.EvolveClientID}
                CustomerNumber={this.state.CustomerNumber}

                ReturnName={this.state.ReturnName}
                ReturnAddressLine1={this.state.ReturnAddressLine1}
                ReturnAddressLine2={this.state.ReturnAddressLine2}
                ReturnCity={this.state.ReturnCity}
                ReturnState={this.state.ReturnState}
                ReturnPostcode={this.state.ReturnPostcode}
                ReturnCountryCode={this.state.ReturnCountryCode}











                isLabelGenResponsePackageLabelsLoading={this.props.isLabelGenResponsePackageLabelsLoading}
                LabelGenResponsePackageLabelsData={this.props.LabelGenResponsePackageLabelsData}

                isLabelGenRequestPackageLoading={this.props.isLabelGenRequestPackageLoading}
                LabelGenRequestPackageData={this.props.LabelGenRequestPackageData}

                LabelGenRequestPackagePostSuccessful={this.props.LabelGenRequestPackagePostSuccessful}
                RequestPackageID={this.props.RequestPackageID}

                isLabelGenRequestPackageProductInfoLoading={this.props.isLabelGenRequestPackageProductInfoLoading}
                LabelGenRequestPackageProductInfoData={this.props.LabelGenRequestPackageProductInfoData}

                LabelGenRequestPackageProductInfoPostSuccessful={this.props.LabelGenRequestPackageProductInfoPostSuccessful}
                RequestProductInfoID={this.props.RequestProductInfoID}

                LabelGenRequestPostSuccessful={this.props.LabelGenRequestPostSuccessful}
                LabelRequestID={this.props.LabelRequestID}


                isLabelGenRequestCustomerWarehouseUserSavedValuesLoading={this.props.isLabelGenRequestCustomerWarehouseUserSavedValuesLoading}
                LabelGenRequestCustomerWarehouseUserSavedValuesData={this.props.LabelGenRequestCustomerWarehouseUserSavedValuesData}

                LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful={this.props.LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful}
                UserSavedValuesID={this.props.UserSavedValuesID}

                labelGenRequestCustomerWarehouseUserSavedValuesGet={this.props.labelGenRequestCustomerWarehouseUserSavedValuesGet}
                labelGenRequestCustomerWarehouseUserSavedValuesPost={this.props.labelGenRequestCustomerWarehouseUserSavedValuesPost}





                labelGenResponsePackageLabelsGet={this.props.labelGenResponsePackageLabelsGet}
                labelGenRequestPackageGet={this.props.labelGenRequestPackageGet}
                labelGenRequestPackagePost={this.props.labelGenRequestPackagePost}
                labelGenRequestPackageProductInfoGet={this.props.labelGenRequestPackageProductInfoGet}
                labelGenRequestPackageProductInfoPost={this.props.labelGenRequestPackageProductInfoPost}
                labelGenRequestPost={this.props.labelGenRequestPost}



                downloadFileFromS3={this.props.downloadFileFromS3}


            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LabelGenHistoryContainer));



