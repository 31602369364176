import { connect } from 'react-redux';
import React, { Component } from 'react';
import ProductCatalog from './ProductCatalog'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../redux/actions/actionsUser';
import * as inventoryActions from '../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,




});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },



});

class ProductCatalogContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();

  }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }


    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

    }



    render() {

        return (

            <ProductCatalog
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}



            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductCatalogContainer));



