export const CtrlTwrLoadTenderDataElements = [

    {
        dBKey: 'N904',
        displayName: 'Load Tender Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 190
    },    
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'N902',
        displayName: 'PRO',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'B103',
        displayName: 'Estimated Pickup Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    }, 
    {
        dBKey: 'B101',
        displayName: 'Standard Carrier Alpha Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'B102',
        displayName: 'Shipment Identification Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 210
    },
    {
        dBKey: 'Response',
        displayName: 'Response',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
    {
        dBKey: 'N902',
        displayName: 'Reference Identification',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 190
    },    
    {
        dBKey: 'QueueStatus',
        displayName: 'Queue Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },    
    {
        dBKey: 'QueueCreatDT',
        displayName: 'Queue Create DT',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },    
    {
        dBKey: 'QueueUploadDT',
        displayName: 'Queue Upload DT',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },    





    // {
    //     dBKey: 'ST01',
    //     displayName: 'Transaction Set Identifier Code',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 210
    // },
    // {
    //     dBKey: 'ST02',
    //     displayName: 'Transaction Set Control Number',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 210
    // },
    // {
    //     dBKey: 'B1Hdr',
    //     displayName: 'B1Hdr',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 250
    // },



   

    // {
    //     dBKey: 'N9Hdr',
    //     displayName: 'N9Hdr',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },  
    // {
    //     dBKey: 'N901',
    //     displayName: 'Reference Identification Qualifier',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 220
    // },    




    // {
    //     dBKey: 'N905',
    //     displayName: 'Time',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 120
    // },    
    // {
    //     dBKey: 'SEHdr',
    //     displayName: 'SEHdr',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },    
    // {
    //     dBKey: 'SE01',
    //     displayName: 'Number of Included Segments',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },    
    // {
    //     dBKey: 'SE02',
    //     displayName: 'Transaction Set Control Number',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 210
    // },    

]


export default CtrlTwrLoadTenderDataElements

