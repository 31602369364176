import React from 'react'
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton'
import { Link } from 'react-router-dom'
import { state } from 'aws-cognito-redux-saga'
import { withRouter } from 'react-router-dom';
import { appSettings } from '../../../app/appSettings'
import Button from 'react-bootstrap/Button'

class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      width: window.innerWidth,
    };

  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  signOut = () => {
    this.props.signOut()
    this.props.appSignOut()
    this.props.history.push('/signin')
  };

  // changePassword = () => {
  //   this.props.history.push('/vas/userchangepassword')
  // };


  //   // Things to do before unloading/closing the tab
  //   doSomethingBeforeUnload = () => {
  //       this.signOut();
  // }

  // // Setup the `beforeunload` event listener
  // setupBeforeUnloadListener = () => {
  //     window.addEventListener("beforeunload", (ev) => {
  //         // ev.preventDefault();
  //         return this.doSomethingBeforeUnload();
  //     });
  // };

  // componentDidMount() {
  //     // Activate the event listener
  //       this.setupBeforeUnloadListener();   
  // }




  render() {
    const { auth } = this.props
    const { width } = this.state;
    const isMobile = width <= appSettings.mobileWidth;


    let mobileHeader;
    mobileHeader =
      <header
        className="header fixed--header">
        <a href="#/wms"
          className="logo">
          <img src={appSettings.smallLogo} />
        </a>


        <header className="header--left">
          {auth.isSignedIn !== state.AUTH_SUCCESS ? (
            <div>

            </div>
          ) : (
            <div>

              <div className="mockverticalMenuButton"   >
                <Button variant="light" size="sm" title="Hide/Show Menu"   >
                  <i className="fa fa-bars"></i>
                </Button>
              </div>

            </div>
          )}
        </header>


        <header className="header--right">
          {auth.isSignedIn !== state.AUTH_SUCCESS ? (
            <div>
              <FlatButton
                containerElement={<Link to="/signin" />}
                label="Sign In"
                onClick={this.signIn}
              />
              <FlatButton
                containerElement={<Link to="/signup" />}
                label="Sign Up"
              // onClick={this.signIn}
              />
            </div>
          ) : (
            <div>
              <FlatButton label="Sign Out" onClick={this.signOut} />
            </div>
          )}
        </header>
        {/* <div style={{ 'border-bottom': '#E78200', 'border-bottom-style': 'solid'}}></div> */}
        <div style={{ 'border-bottom-width': 'thick', 'border-bottom': '#3396E5', 'border-bottom-style': 'solid' }}></div>
      </header>


    let fullScreenHeader;
    fullScreenHeader =
      <header
        className="header fixed--header">
        <a className="logo">
          <img src={appSettings.logo} />
        </a>


        <header className="header--left">
          {auth.isSignedIn !== state.AUTH_SUCCESS ? (
            <div>

            </div>
          ) : (
            <div>

              <div className="mockverticalMenuButton"   >
                <Button variant="light" size="sm" title="Hide/Show Menu"   >
                  <i className="fa fa-bars"></i>
                </Button>
              </div>

            </div>
          )}
        </header>



        <header className="header--right">
          {auth.isSignedIn !== state.AUTH_SUCCESS ? (
            <div>
              <FlatButton
                containerElement={<Link to="/signin" />}
                label="Sign In"
                onClick={this.signIn}
              />
              <FlatButton
                containerElement={<Link to="/signup" />}
                label="Sign Up"
              // onClick={this.signIn}
              />
            </div>
          ) : (


            <div>
              {
                appSettings.devEnvironment === true ?
                  "Test Environment                 "
                  :
                  ""
              }

              <i className="fa fa-user" />
              {" " + auth.info.idToken.payload.email + " "}
              <FlatButton label="Sign Out" onClick={this.signOut} />

            </div>
          )}
        </header>


        {/* <div style={{ 'border-bottom': '#E78200', 'border-bottom-style': 'solid'}}></div> */}
        <div style={{ 'border-bottom-width': 'thick', 'border-bottom': '#3396E5', 'border-bottom-style': 'solid' }}></div>


      </header>



    if (isMobile) {
      return mobileHeader;
    }
    else {
      return fullScreenHeader;
    }






  }
}







export default withRouter(HeaderComponent)