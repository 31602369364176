import { connect } from 'react-redux';
import React, { Component } from 'react';
import ShipmentEntry from './ShipmentEntry'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatStringForAPI, formatDateForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isCtrlTwrGenerateProLoading:                            store.ctrlTwrReducer.isCtrlTwrGenerateProLoading,
    CtrlTwrGenerateProData:                                 store.ctrlTwrReducer.CtrlTwrGenerateProData,

    isCtrlTwrModeRefLoading:                                store.ctrlTwrReducer.isCtrlTwrModeRefLoading,
    CtrlTwrModeRefData:                                     store.ctrlTwrReducer.CtrlTwrModeRefData,

    isCtrlTwrCountryLoading:                                store.ctrlTwrReducer.isCtrlTwrCountryLoading,
    CtrlTwrCountryData:                                     store.ctrlTwrReducer.CtrlTwrCountryData,

    CtrlTwrShipmentPostSuccessful:                          store.ctrlTwrReducer.CtrlTwrShipmentPostSuccessful,

    CtrlTwrShipmentLegsPostSuccessful:                      store.ctrlTwrReducer.CtrlTwrShipmentLegsPostSuccessful,


    CtrlTwrShipmentNotesPrivatePostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPrivatePostSuccessful,
    CtrlTwrShipmentNotesPublicPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPublicPostSuccessful,
});

const mapDispatchToProps = dispatch =>
({

    ctrlTwrGenerateProGet:( _email, _authToken  ) =>
    {
        dispatch( actions.ctrlTwrGenerateProGet( _email, _authToken ) )
    },

    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrCountryGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.ctrlTwrCountryGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrShipmentPost: (_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity) => {
        dispatch(actions.ctrlTwrShipmentPost(_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity))
    },
    ctrlTwrShipmentLegsPost: (_email, _authToken, _ProRef, _LegNumber, _ModeID, _DataJson, _LegID, _DeleteLeg,) => {
        dispatch(actions.ctrlTwrShipmentLegsPost(_email, _authToken, _ProRef, _LegNumber, _ModeID, _DataJson, _LegID, _DeleteLeg, ))
    },

    ctrlTwrShipmentPrivateNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete) => {
        dispatch(actions.ctrlTwrShipmentPrivateNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete))
    },
    ctrlTwrShipmentPublicNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete) => {
        dispatch(actions.ctrlTwrShipmentPublicNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete))
    },


});

class ShipmentEntryContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getNewProRef();
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.CtrlTwrShipmentPostSuccessful !== this.props.CtrlTwrShipmentPostSuccessful && this.props.CtrlTwrShipmentPostSuccessful) {
            this.getNewProRef();
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }
    }

    getNewProRef = () => {
        this.props.ctrlTwrGenerateProGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken)
    }

    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        // try {
        //     var paramPO = formatStringForAPI(values.po)
        // }
        // catch (e) {
        //     var paramPO = null
        // }


        // if( paramPO == null && paramContainer == null && paramSealNo == null && paramDepartDateStart == null && paramDepartDateEnd == null ){

        // }
        // else{
        //     this.props.transShipmentEntryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainer, null, null, null, null, null, paramPO, paramDepartDateStart, paramDepartDateEnd, null, null, paramSealNo, null, null, null  )
        // }

        this.props.ctrlTwrModeRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrCountryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        
    }



    render() {

        return (

            <ShipmentEntry
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                organizationSelection={this.props.organizationSelection}

                isCtrlTwrGenerateProLoading={this.props.isCtrlTwrGenerateProLoading}
                CtrlTwrGenerateProData={this.props.CtrlTwrGenerateProData}      
                
                isCtrlTwrModeRefLoading={this.props.isCtrlTwrModeRefLoading}
                CtrlTwrModeRefData={this.props.CtrlTwrModeRefData}

                isCtrlTwrCountryLoading={this.props.isCtrlTwrCountryLoading}
                CtrlTwrCountryData={this.props.CtrlTwrCountryData}
                

                CtrlTwrShipmentPostSuccessful={this.props.CtrlTwrShipmentPostSuccessful}
                ctrlTwrShipmentPost={this.props.ctrlTwrShipmentPost}

                CtrlTwrShipmentLegsPostSuccessful={this.props.CtrlTwrShipmentLegsPostSuccessful}
                ctrlTwrShipmentLegsPost={this.props.ctrlTwrShipmentLegsPost}


                CtrlTwrShipmentNotesPrivatePostSuccessful={this.props.CtrlTwrShipmentNotesPrivatePostSuccessful}
                CtrlTwrShipmentNotesPublicPostSuccessful={this.props.CtrlTwrShipmentNotesPublicPostSuccessful}
                ctrlTwrShipmentPrivateNotesPost={this.props.ctrlTwrShipmentPrivateNotesPost}
                ctrlTwrShipmentPublicNotesPost={this.props.ctrlTwrShipmentPublicNotesPost}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentEntryContainer));



