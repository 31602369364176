export const InventoryLoadSkuDataElements = [
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },



    {
        dBKey: 'Units',
        displayName: 'Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },

    {
        dBKey: 'Cases',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },


    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'Oversize',
        displayName: 'Oversize',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },


]


export default InventoryLoadSkuDataElements

