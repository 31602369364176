import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';

import _ from "lodash";
import * as moment from 'moment';
import queryString from 'query-string';
import ReactExport from "react-export-excel";
import { Button, Col, ControlLabel, Input, InputGroup, Loader, Nav, Panel, Row, Whisper, Tooltip, Icon } from 'rsuite';
import { orderStatus } from '../../../../app/appConfig';
import { convertNanToZero, findLowestMissingNumber, formatAllTextNumbersSymbolsForAPI, formatDateForAPI, formatDateForUrlSearch, formatDateTimeForUrlSearch, formatNumberForAPI, formatStringForAPI, formatTrueFalseToBit, isNumeric, queryStringParse } from '../../../../resources/logic/functions';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import RSearchInputNumberAsString from '../../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import { InventoryOrderOutboundDataElements } from '../../../dataComponentsInventory/InventoryOrderOutboundDataElements';
import { emptyReceiveCaseDict, emptyReceivePalletDict, emptyReceiveSkuDict } from '../../_References/DataStructures';
import AddNewOrderOutboundDrawer from './AddNewOrderOutboundDrawer';
// import AddToOrderDrawer from './AddToOrderDrawer';
// import ReceiveOrderDrawer from './ReceiveOrderDrawer';
import { textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import { emptyOrdersOutboundSkuDict } from '../../_References/DataStructures';
import EditOrderOutboundSkusDrawer from './EditOrderOutboundSkusDrawer';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';
import { handleAutoCompleteInputChange } from '../../../componentsGeneral/AutoCompleteInput/AutoCompleteInput';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


//need to make this outbound vs inbound
const tableOutboundIdCol = "OrderOutboundID"
const tableInboundIdCol = "OrderInboundID"

const styles = {
    marginBottom: 15
};


const tooltipSearch = (
    <Tooltip>
        Search
    </Tooltip>
);

const CustomNav = ({ active, onSelect, ...props }) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} style={styles}>

            <Nav.Item eventKey="inbound">Inbound</Nav.Item>
            <Nav.Item eventKey="outbound">Outbound</Nav.Item>
        </Nav>
    );
};


const mountRef = React.createRef();

class Orders extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {

            //Nav
            orderNav: 'outbound',

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            // Table Check Box
            checkedKeys: [],

            //Search Values
            isFilterShown: true,

            searchOrderRef: '',
            searchSKU: '',
            searchStatus: null,


            //ORDER:
            //Add new outbound order drawer:
            showAddNewOrderOutboundDrawer: false,
            unsavedNewOrderChanges: false,

            //Add new order state values (inbound or outbound):
            OrderID: '',
            OrderTypeID: 1,
            OrderRef: '',
            OrderDate: null,
            ShipByDate: null,
            DeliverByDate: null,
            ShipToName: '',
            ShipToAddressLine1: '',
            ShipToAddressLine2: '',
            ShipToCity: '',
            ShipToState: '',
            ShipToZip: '',
            ShipToCountry: '',
            OrderPriorityID: 1,
            Notes: '',

            //Order being edited: 
            EditOrderID: '',


            //SKU:

            //Outbound Order SKUs
            showOutboundSkuEdit: false, //edits existing sku
            showEditOrderOutboundSkusDrawer: false,
            OrderOutboundSkuID: '',

            //Add or Edit SKU state values (inbound or outbound)
            unsavedNewSkuChanges: false,
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',







        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Nav
        this.tableIdCol = this.state.orderNav === 'inbound' ? tableInboundIdCol : tableOutboundIdCol;

        this.handleOrderNavSelect = this.handleOrderNavSelect.bind(this);
        this.onClickOrderNav = this.onClickOrderNav.bind(this);

        this.onClickPickOrder = this.onClickPickOrder.bind(this);


        //State change
        this.onChangeState = this.onChangeState.bind(this);

        //Add new outbound order drawer
        // this.openAddNewOrderOutboundDrawer = this.openAddNewOrderOutboundDrawer.bind(this);
        // this.closeAddNewOrderOutboundDrawer = this.closeAddNewOrderOutboundDrawer.bind(this);
        // this.onClickSaveNewOrderOutbound = this.onClickSaveNewOrderOutbound.bind(this);

        //Edit Order Skus (inbound or outbound)



        this.handleAutoCompleteInputChange = handleAutoCompleteInputChange.bind(this)



        //Add To Order Drawer
        this.onClickAddNewOrder = this.onClickAddNewOrder.bind(this);
        this.resetAddToOrderState = this.resetAddToOrderState.bind(this);
        this.closeAddToOrderDrawer = this.closeAddToOrderDrawer.bind(this);
        this.onClickConfirmAddToOrder = this.onClickConfirmAddToOrder.bind(this);







        //Search Values
        this.onChangeSearchOrder = this.onChangeSearchOrder.bind(this);
        this.onChangeSearchSKU = this.onChangeSearchSKU.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);


        //Add New Order Values
        this.setNewOrderID = this.setNewOrderID.bind(this)

        // this.onClickAddSku = this.onClickAddSku.bind(this);
        this.onClickDeleteSku = this.onClickDeleteSku.bind(this);
        this.onClickConfirmAddNewOrderOutbound = this.onClickConfirmAddNewOrderOutbound.bind(this);
        this.closeAddNewOrderDrawer = this.closeAddNewOrderDrawer.bind(this);

        this.resetStateOutboundOrder = this.resetStateOutboundOrder.bind(this);

        //Load SKU data
        this.loadExistingSkus = this.loadExistingSkus.bind(this);


        //Outbound Sku Drawer
        this.onChangeSkuState = this.onChangeSkuState.bind(this);
        this.handleAutoCompleteSku = this.handleAutoCompleteSku.bind(this);
        this.onClickTableLinkEditOrderOutboundSku = this.onClickTableLinkEditOrderOutboundSku.bind(this);
        this.onClickConfirmOrderSkuOutbound = this.onClickConfirmOrderSkuOutbound.bind(this);
        // this.setStateToOutboundOrderSkus = this.setStateToOutboundOrderSkus.bind(this);
        this.closeOrderSkuOutboundDrawer = this.closeOrderSkuOutboundDrawer.bind(this);
        this.onClickEditSku = this.onClickEditSku.bind(this);
        this.onCloseEditSku = this.onCloseEditSku.bind(this);
        this.onCloseEditSkuCheckUnsavedChanges = this.onCloseEditSkuCheckUnsavedChanges.bind(this);

        this.resetStateOutboundSkus = this.resetStateOutboundSkus.bind(this);



        this.onClickDetails = this.onClickDetails.bind(this);

    }


    resetStateOutboundOrder() {
        this.setState({
            //Add new outbound order drawer:
            showAddNewOrderOutboundDrawer: false,
            unsavedNewOrderChanges: false,

            //Add new order state values (inbound or outbound):
            OrderID: '',
            OrderTypeID: 1,
            OrderRef: '',
            OrderDate: null,
            ShipByDate: null,
            DeliverByDate: null,
            ShipToName: '',
            ShipToAddressLine1: '',
            ShipToAddressLine2: '',
            ShipToCity: '',
            ShipToState: '',
            ShipToZip: '',
            ShipToCountry: '',
            OrderPriorityID: 1,
            Notes: '',
        });
    }

    resetStateOutboundSkus() {
        this.setState({
            //Outbound Order SKUs
            EditOrderID: '',
            showOutboundSkuEdit: false, //edits existing sku
            showEditOrderOutboundSkusDrawer: false,
            OrderOutboundSkuID: '',

            //Add or Edit SKU state values (inbound or outbound)
            unsavedNewSkuChanges: false,
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });
    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[this.tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {

            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }












    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
        this.getsessionStorageData() //this will preserve menu selection
        this.loadWarehouseToState()
        this.loadCustomerToState()

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            })
        }

        if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            })
        }


    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramOrderNav = queryStringParse(values.ordernav)
        }
        catch (e) {
            var paramOrderNav = null
        }
        if (paramOrderNav != null) {
            this.handleOrderNavSelect(paramOrderNav)
        }




        try {
            var paramOrder = queryStringParse(values.orderref)
        }
        catch (e) {
            var paramOrder = null
        }
        if (paramOrder != null) {
            this.onChangeSearchOrder(paramOrder)
        }


        try {
            var paramSKU = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        if (paramSKU != null) {
            this.onChangeSearchSKU(paramSKU)
        }

        try {
            var paramStatus = queryStringParse(values.orderstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

    }


    //State Change
    onChangeState(e, statename) {
        this.setState({
            [statename]: e,
            unsavedNewOrderChanges: true,
        });
    }





    setNewOrderID(newID) {
        this.setState({
            EditOrderID: newID
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
                this.setState({
                    WarehouseID: this.props.warehouseSelection
                })
            }
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
                this.setState({
                    CustomerID: this.props.customerSelection
                })
            }
        }

        if (prevState.CustomerID !== this.state.CustomerID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)

                this.loadExistingSkus(this.state.WarehouseID, this.state.CustomerID)

            }
        }

        if (prevState.WarehouseID !== this.state.WarehouseID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                this.loadExistingSkus(this.state.WarehouseID, this.state.CustomerID)

            }
        }





        if (prevProps.isInventoryLotOrderOutboundGetLoading === true && this.props.isInventoryLotOrderOutboundGetLoading === false && this.state.searchOrderRef !== '' && this.props.InventoryLotOrderOutboundData.length == 0) {
            if (this.state.showEditOrderOutboundSkusDrawer) {
            }
            else {
                this.addOrder(this.state.searchOrderRef)
            }
        }


        if (prevProps.InventoryLotOrderOutboundPostSuccessful !== this.props.InventoryLotOrderOutboundPostSuccessful && this.props.InventoryLotOrderOutboundPostSuccessful && this.props.isInventoryLotOrderOutboundGetLoading === false) { // && this.state.showReceiveDrawer === false) {

            this.props.inventoryLotOrderOutboundSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, this.props.NewOutboundOrderId, null, null, null, null, null)
            this.setNewOrderID(this.props.NewOutboundOrderId)

            this.setStateCloseAddNewDrawer()

            try {
                var searchCont = formatStringForAPI(this.state.OrderRef)
                this.props.history.push({
                    search:
                        // 'orderref=' + searchCont 

                        'orderref=' + searchCont + '&' +
                        'orderstatus=' + this.state.searchStatus + '&' +

                        'sku=' + this.state.searchSKU + '&' +
                        'ordernav=' + this.state.orderNav

                });
            }
            catch (e) { }


            this.onClickAddSkuToOrderOutbound()


            this.setInitialTreeSelection(this.props.NewOutboundOrderId, this.state.OrderRef)
        }

        if (prevProps.InventoryLotOrderOutboundSkuPostSuccessful !== this.props.InventoryLotOrderOutboundSkuPostSuccessful && this.props.InventoryLotOrderOutboundSkuPostSuccessful) {
            this.onCloseEditSku()
            try {
                this.props.inventoryLotOrderOutboundSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, this.state.EditOrderID, null, null, null, null, null)
            }
            catch (e) { }


        }


        if (prevProps.isInventoryLotOrderOutboundSkuGetLoading === true && this.props.isInventoryLotOrderOutboundSkuGetLoading === false) {
            // this.setStateToOutboundOrderSkus()


            // if (this.state.showEditOrderOutboundSkusDrawer) {
            // }
            // else {
            //     this.addOrder(this.state.searchOrderRef)
            // }
        }


    }




    loadWarehouseToState() {
        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
    }

    loadCustomerToState() {
        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
    }

    getsessionStorageData() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }
        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierArray'));
            if (carrierMenuList != null && carrierMenuList != undefined) {
                this.setState({
                    carrierArray: carrierMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierServiceMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierServiceArray'));
            if (carrierServiceMenuList != null && carrierServiceMenuList != undefined) {
                this.setState({
                    carrierServiceArray: carrierServiceMenuList
                })
            }
        }
        catch (e) { }
    }


    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchOrderRef: '',
            searchSKU: '',
            searchStatus: null,
        });
        this.props.history.push({
            search:
                // 'orderref=' + searchCont 

                'orderref=' + 'null' + '&' +
                'orderstatus=' + 'null' + '&' +

                'sku=' + 'null' + '&' +
                'ordernav=' + this.state.orderNav

        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }


    onClickSearchOrderRef(e) {

        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                this.props.history.push({
                    search:
                        'ordernav=' + this.state.orderNav + '&' +
                        'orderref=' + this.state.searchOrderRef

                    // 'orderstatus=' + searchStatus + '&' +
                    // 'sku=' + this.state.searchSKU + '&' +
                });
            }
            catch (e) { }
        }

    }

    onClickApplySearch(e) {

        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                this.props.history.push({
                    search:
                        'ordernav=' + this.state.orderNav + '&' +
                        'orderstatus=' + searchStatus + '&' +
                        'sku=' + this.state.searchSKU


                    // 'orderref=' + this.state.searchOrderRef + '&' +

                });
            }
            catch (e) { }
        }
    }



    onChangeSearchOrder(e) {

        this.setState({
            searchOrderRef: e,
            searchSKU: '',
            searchStatus: null
        });
    }

    onChangeSearchSKU(e) {
        this.setState({
            searchSKU: e,
            searchOrderRef: ''

        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e,
            searchOrderRef: ''
        });
    }

    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[this.tableIdCol]
            history.push({
                pathname: 'orderdetails',
                search: 'orderid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }






    //Nav
    handleOrderNavSelect(activeKey) {
        this.setState({
            orderNav: activeKey,
            checkedKeys: []
        });
    }

    onClickOrderNav(activeKey) {
        this.handleOrderNavSelect(activeKey)

        this.props.history.push({
            search:
                'ordernav=' + activeKey + '&' +
                'orderref=' + this.state.searchOrderRef + '&' +
                'orderstatus=' + this.state.searchStatus + '&' +
                'sku=' + this.state.searchSKU
        });

    }

    onClickPickOrder(e, rowData, history) {

        try {
            var paramID = rowData[this.tableIdCol]
            history.push({
                pathname: 'orderpicking',
                search: 'orderid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }




    //Load Existing SKUS
    loadExistingSkus(warehouseid, customerid) {
        this.props.inventorySkuRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, warehouseid, customerid, null, null, null, null, null, null)
    }




    //Add new Order drawer

    onClickAddNewOrder(e) {
        this.addOrder(-1)
    }


    addOrder(searchValue) {
        this.loadWarehouseToState()
        this.loadCustomerToState()

        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
        else {
            this.setState({
                WarehouseID: null
            });
        }

        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
        else {
            this.setState({
                CustomerID: null
            });
        }

        if (this.state.showEditOrderOutboundSkusDrawer) {

        }
        else if (searchValue === null || searchValue == 'null') {

        }
        else if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else if (searchValue === -1) {
            this.onClickAddNewEditable()
            this.setState({
                OrderRef: '',
                unsavedNewOrderChanges: false,
            });

        }
        else if (window.confirm(searchValue + ' not found, do you wish to add?')) {
            this.onClickAddNew()
            this.setState({
                OrderRef: searchValue,
                unsavedNewOrderChanges: true,
            });
        }
        this.forceUpdate()
    }

    onClickAddNew(placement) {

        // var newSkuDict = _.cloneDeep(emptyOrdersOutboundSkuDict);
        // this.setState({
        //     orderSkuDict: [newSkuDict]
        // });



        this.setState({
            placement,
            showAddNewOrderOutboundDrawer: true,
            unsavedNewOrderChanges: true,
        });
    }


    onClickAddNewEditable(placement) {

        // var newSkuDict = _.cloneDeep(emptyOrdersOutboundSkuDict);
        // this.setState({
        //     orderSkuDict: [newSkuDict]
        // });

        if (this.state.orderNav == 'outbound') {

            this.setState({
                placement,
                showAddNewOrderOutboundDrawer: true,
                showAddNewOrderInboundDrawer: false,
                unsavedNewOrderChanges: true,

            });
        }
        else if (this.state.orderNav == 'inbound') {

            this.setState({
                placement,
                showAddNewOrderInboundDrawer: true,
                showAddNewOrderOutboundDrawer: false,
                unsavedNewOrderChanges: true,
            });
        }

    }

    onClickConfirmAddNewOrderOutbound(e) {

        if (typeof (this.state.WarehouseID) === 'number' && typeof (this.state.CustomerID) === 'number') {
            if (this.state.OrderRef == null || this.state.OrderRef == '') {
                window.alert('Please enter new Order.')
            }
            else if (this.state.OrderTypeID == null || this.state.OrderTypeID == '') {
                window.alert('Please select Order Type.')
            }
            else if (this.state.OrderPriorityID == null || this.state.OrderPriorityID == '') {
                window.alert('Please select Order Priority.')
            }
            else if (this.state.OrderDate == null || this.state.OrderDate == '') {
                window.alert('Please select Order Date.')
            }
            else {
                this.props.inventoryLotOrderOutboundPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    formatStringForAPI(this.state.OrderRef),
                    this.state.WarehouseID,
                    this.state.CustomerID,
                    1,
                    formatNumberForAPI(this.state.OrderTypeID),
                    formatDateForUrlSearch(this.state.OrderDate),
                    formatDateForUrlSearch(this.state.ShipByDate),
                    formatDateForUrlSearch(this.state.DeliverByDate),
                    formatStringForAPI(this.state.ShipToName),
                    formatStringForAPI(this.state.ShipToAddressLine1),
                    formatStringForAPI(this.state.ShipToAddressLine2),
                    formatStringForAPI(this.state.ShipToCity),
                    formatStringForAPI(this.state.ShipToState),
                    formatStringForAPI(this.state.ShipToZip),
                    formatStringForAPI(this.state.ShipToCountry),
                    formatNumberForAPI(this.state.OrderPriorityID),
                    formatStringForAPI(this.state.Notes),
                    null
                )
            }
        }
        else { window.alert('Please select one Warehouse and one Customer to save data.') }
    }
    closeAddNewOrderDrawer() {
        if (this.state.unsavedNewOrderChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {

        this.resetStateOutboundOrder()
        this.resetStateOutboundSkus()

    }

    setInitialTreeSelection(NewOutboundOrderId, NewOrderLabel) {
        this.setState({
            // OrderTypeID: 1,
            selectedLabel: NewOrderLabel,
            EditOrderID: NewOutboundOrderId,
            addNewText: null,
        });
    }


    //Edit Order Outbound Skus Drawer
    onChangeSkuState(e, statename) {
        this.setState({
            [statename]: e,
            unsavedNewOrderChanges: true,
            unsavedNewSkuChanges: true,
        });
    }

    handleAutoCompleteSku(event, newValue, valueName) {
        handleAutoCompleteInputChange(this.props.InventorySkuRefData, this.state, this.setState.bind(this), newValue, valueName)
    }


    onClickAddSkuToOrderOutbound(placement) {

        this.setState({

            showEditOrderOutboundSkusDrawer: true,
            unsavedNewSkuChanges: false,

            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });
    }


    onClickConfirmAddToOrder(e) {

        if (this.state.addNewText == null || this.state.addNewText == '') {
            window.alert('Please enter data.')
        }
        // else if (window.confirm('Confirm Save?')) {
        else {
            // save child of selected type
            // if (this.state.selectedType === 'order') {

            this.props.inventoryOrderSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID, this.state.EditOrderID, null, formatStringForAPI(this.state.addNewText), this.state.newSkuUnits, this.state.newSkuCases, this.state.newSkuPallets, null, null, formatStringForAPI(this.state.newSkuCustomerRef), null, formatStringForAPI(this.state.newSkuProject), formatAllTextNumbersSymbolsForAPI(this.state.newSkuNotes))

            this.resetAddToOrderState()

        }
        // }

    }

    onClickEditSku(e, rowData, history) {

        this.setState({
            showOutboundSkuEdit: true,
            unsavedNewSkuChanges: false,

            OrderOutboundSkuID: rowData['OrderOutboundSkuID'],
            SkuID: rowData['SkuID'],
            SKU: rowData['SKU'],
            UomID: rowData['UomID'],
            Qty: rowData['Qty'],
            LotCode: rowData['LotCode'],
            SerialNumber: rowData['SerialNumber'],
            CustomerRefID: rowData['CustomerRefID'],
            CustomerRef: rowData['CustomerRef'],
            ProjectID: rowData['ProjectID'],
            Project: rowData['Project'],
            SkuNotes: rowData['SkuNotes'],

        });

        // need to find differnce from exsiting  since proc adds new number to existing
    }

    onCloseEditSkuCheckUnsavedChanges() {
        if (this.state.unsavedNewSkuChanges) {
            if (window.confirm('Save SKU changes before closing?')) {
                this.onClickConfirmOrderSkuOutbound()
            }
            else {
                this.setState({
                    //Outbound Order SKUs
                    showOutboundSkuEdit: false, //edits existing sku
                    OrderOutboundSkuID: '',
                    unsavedNewSkuChanges: false,

                    //Add or Edit SKU state values (inbound or outbound)
                    SkuID: '',
                    SKU: '',
                    UomID: null,
                    Qty: null,
                    LotCode: '',
                    SerialNumber: '',
                    CustomerRefID: null,
                    CustomerRef: '',
                    ProjectID: null,
                    Project: '',
                    SkuNotes: '',
                });
            }
        }

        else {
            this.setState({
                //Outbound Order SKUs
                showOutboundSkuEdit: false, //edits existing sku
                OrderOutboundSkuID: '',
                unsavedNewSkuChanges: false,

                //Add or Edit SKU state values (inbound or outbound)
                SkuID: '',
                SKU: '',
                UomID: null,
                Qty: null,
                LotCode: '',
                SerialNumber: '',
                CustomerRefID: null,
                CustomerRef: '',
                ProjectID: null,
                Project: '',
                SkuNotes: '',
            });
        }

    }





    // onCloseEditSkuCheckUnsavedChanges() {
    //     if (this.state.unsavedNewSkuChanges) {
    //         if (window.confirm('Leave without saving SKU changes?')) {

    //             this.setState({
    //                 //Outbound Order SKUs
    //                 showOutboundSkuEdit: false, //edits existing sku
    //                 OrderOutboundSkuID: '',
    //                 unsavedNewSkuChanges: false,

    //                 //Add or Edit SKU state values (inbound or outbound)
    //                 SkuID: '',
    //                 SKU: '',
    //                 UomID: null,
    //                 Qty: null,
    //                 LotCode: '',
    //                 SerialNumber: '',
    //                 CustomerRefID: null,
    //                 CustomerRef: '',
    //                 ProjectID: null,
    //                 Project: '',
    //                 SkuNotes: '',
    //             });
    //         }
    //     }

    //     else {
    //         this.setState({
    //             //Outbound Order SKUs
    //             showOutboundSkuEdit: false, //edits existing sku
    //             OrderOutboundSkuID: '',
    //             unsavedNewSkuChanges: false,

    //             //Add or Edit SKU state values (inbound or outbound)
    //             SkuID: '',
    //             SKU: '',
    //             UomID: null,
    //             Qty: null,
    //             LotCode: '',
    //             SerialNumber: '',
    //             CustomerRefID: null,
    //             CustomerRef: '',
    //             ProjectID: null,
    //             Project: '',
    //             SkuNotes: '',
    //         });
    //     }

    // }

    onCloseEditSku() {
        this.setState({
            //Outbound Order SKUs
            showOutboundSkuEdit: false, //edits existing sku
            OrderOutboundSkuID: '',

            unsavedNewSkuChanges: false,

            //Add or Edit SKU state values (inbound or outbound)
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });
    }

    onClickDeleteSku(e, rowData, history) {

        if (window.confirm('Confirm Delete?')) {
            this.props.inventoryLotOrderOutboundSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, formatNumberForAPI(rowData['OrderOutboundID']), formatNumberForAPI(rowData['OrderOutboundSkuID']), formatNumberForAPI(rowData['SkuID']), null, null, null, null, null, null, null, null, null, null, 1)
        }
    }



    resetAddToOrderState() {
        // this.resetStateOutboundSkus()
        this.setState({
            unsavedNewSkuChanges: false,
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });
    }

    closeAddToOrderDrawer() {

        this.resetStateOutboundSkus()

    }








    //Add New Order Drawer


    onClickTableLinkEditOrderOutboundSku(e, rowData, history) {
        var outboundOrderID = null
        var newOrder = null
        var newWarehouse = null
        var newCustomer = null
        try {
            outboundOrderID = rowData['OrderOutboundID']
            newOrder = rowData['OrderRef']
            newWarehouse = rowData['WarehouseID']
            newCustomer = rowData['CustomerID']

            this.props.inventoryLotOrderOutboundSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, outboundOrderID, null, null, null, null, null)
        }
        catch (e) { }

        this.setState({
            EditOrderID: outboundOrderID,
            showEditOrderOutboundSkusDrawer: true,
            unsavedNewSkuChanges: false,

            WarehouseID: newWarehouse,
            CustomerID: newCustomer,

            selectedLabel: newOrder,



            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',

        });

    }



    // setStateToOutboundOrderSkus() {
    //     // Determine newSkuDict based on the condition
    //     const newSkuDict = this.props.InventoryLotOrderOutboundSkuData.length === 0
    //         ? _.cloneDeep(emptyOrdersOutboundSkuDict)
    //         : _.cloneDeep(this.props.InventoryLotOrderOutboundSkuData);

    //     // Set all state updates in a single call
    //     this.setState({
    //         orderSkuDict: [newSkuDict],
    //         showEditOrderOutboundSkusDrawer: true,
    //         showAddNewOrderOutboundDrawer: false,

    //         // showAddNewOrderOutboundDrawer: true,
    //         // showAddNewOrderEditableDrawer: false,
    //         unsavedNewOrderChanges: true,

    //     });
    // }



    // onChangeSkuAddNewOrder(e, sku, label) {
    //     var skuSeq = sku['SkuSeq']
    //     var currentSkuDict = this.state.orderSkuDict
    //     var skuKeyToUpdate = currentSkuDict.findIndex(item => item.SkuSeq === skuSeq)
    //     currentSkuDict[skuKeyToUpdate][label] = e

    //     this.handleSetStateSkuAddNewOrder(currentSkuDict)
    // }




    // handleSetStateSkuAddNewOrder = (newSkuDict) => {
    //     this.setState({
    //         orderSkuDict: newSkuDict
    //     }, () => {
    //         this.forceUpdate()
    //     });
    // }



    // onClickAddSku(e) {
    //     // Initialize currentSkuDict to an empty array if it's undefined
    //     var currentSkuDict = this.state.orderSkuDict || [];
    //     const skuSeqArray = currentSkuDict.filter(x => typeof (x.SkuSeq) !== 'undefined').map(item => item.SkuSeq);
    //     var newID = findLowestMissingNumber(skuSeqArray);
    //     var newSku = _.cloneDeep(emptyOrdersOutboundSkuDict);
    //     newSku['SkuSeq'] = newID;
    //     currentSkuDict.push(newSku);
    //     this.handleSetStateSkuAddNewOrder(currentSkuDict);
    // }




    onClickConfirmOrderSkuOutbound() {

        if (!this.state.SKU) {
            window.alert('Please enter SKU.')
        }
        else if (!this.state.UomID) {
            window.alert('Please select UOM.')
        }
        else if (!this.state.Qty) {
            window.alert('Please enter Qty.')
        }
        else {
            this.props.inventoryLotOrderOutboundSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, formatNumberForAPI(this.state.EditOrderID), formatNumberForAPI(this.state.OrderOutboundSkuID), formatNumberForAPI(this.state.SkuID), formatStringForAPI(this.state.SKU), formatNumberForAPI(this.state.UomID), formatNumberForAPI(this.state.Qty), formatStringForAPI(this.state.LotCode), formatStringForAPI(this.state.SerialNumber), formatNumberForAPI(this.state.CustomerRefID), formatStringForAPI(this.state.CustomerRef), formatNumberForAPI(this.state.ProjectID), formatStringForAPI(this.state.Project), formatStringForAPI(this.state.SkuNotes), null)
        }
    }

    closeOrderSkuOutboundDrawer() {

        if (this.state.unsavedNewSkuChanges) {
            if (window.confirm('Save SKU changes before closing?')) {
                // this.resetStateOutboundSkus()
                this.onClickConfirmOrderSkuOutbound()
            }
            else {
                this.resetStateOutboundSkus()
            }
        }
        else {
            this.resetStateOutboundSkus()
        }

    }

    render() {



        if (this.props.InventoryRecoveryPostLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, receiving...</p>
                </div>
            );
        }


        if (this.props.isInventoryLotSkuLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, receiving...</p>
                </div>
            );
        }



        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;
        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>

                        {/* <CustomNav appearance="tabs" active={this.state.orderNav} onSelect={this.onClickOrderNav} /> */}

                        <Row>
                            <Col md={20} sm={20} >


                                <ControlLabel>{'Order Ref'}</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>

                                    <Input value={this.state.searchOrderRef} onChange={(e) => { this.onChangeSearchOrder(e) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} onKeyUp={(e) => (e.key === 'Enter' ? this.onClickSearchOrderRef(e) : null)} />

                                    <Whisper placement="top" trigger="hover" speaker={tooltipSearch}>
                                        <InputGroup.Button onClick={(e) => { this.onClickSearchOrderRef(e) }}   >

                                            <Icon icon="search" />
                                        </InputGroup.Button>
                                    </Whisper>

                                </InputGroup>


                            </Col>
                            <Col md={4} sm={4} >
                                <div >
                                    <ControlLabel>&nbsp;</ControlLabel>
                                    <InputGroup style={{ marginBottom: 10 }}>
                                        {/* <Button block onClick={(e) => { this.onClickAddNewOrder(e) }} > Add New </Button> */}
                                        <DebouncedButton block={true} onClick={this.onClickAddNewOrder} buttonText="Add New" />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </Panel>

                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>



                            <Col md={24} sm={24} >
                                <ControlLabel>SKU</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.searchSKU} onChange={(e) => { this.onChangeSearchSKU(e, 'searchSKU') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>
                            </Col>

                        </Row>

                        <Row style={{ marginBottom: '15px' }}>
                            <Col md={24} sm={24} >
                                {RSelectPicker(
                                    this.props.isMobile,
                                    orderStatus,
                                    this.state.searchStatus,
                                    'Status',
                                    this.onChangeSearchStatus,
                                    this.props.history,
                                    mountRef
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24} sm={24} >
                                <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                                <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                            </Col>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }








        let excelOutboundDiv;
        let rTableOutbound;
        let outboundTableDiv;
        if (this.props.isInventoryLotOrderOutboundGetLoading) {

            outboundTableDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotOrderOutboundData) !== 'undefined' && this.props.InventoryLotOrderOutboundData != null) {

            const boolSelectButton = [3, 4, 5].includes(this.state.searchStatus) ? false : true;

            rTableOutbound = RTableGeneral(
                this.tableIdCol,     //   keyColumn,
                this.props.InventoryLotOrderOutboundData,     //   tableData,
                InventoryOrderOutboundDataElements,       //   DataElements,
                this.props.isInventoryLotOrderOutboundGetLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                boolSelectButton,      //   boolSelectButton,
                this.onClickPickOrder,       //   onClickSelect,
                'Pick',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                true,       //   link2bool,
                'Edit',       //   link2text,
                this.onClickTableLinkEditOrderOutboundSku  //   link2onClick

            )

            excelOutboundDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Order_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryLotOrderOutboundData} name="Order">
                                    {
                                        InventoryOrderOutboundDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            outboundTableDiv = <div>
                {excelOutboundDiv}
                {rTableOutbound}
            </div>
        }
        else {
            outboundTableDiv = <div></div>
        }





        return (
            <div>
                <h3>
                    Inventory Management - Orders
                </h3>
                {hideShowSearch}
                {searchDiv}

                {outboundTableDiv}

                <AddNewOrderOutboundDrawer {...this.props} state={this.state} onChangeState={this.onChangeState} confirm={this.onClickConfirmAddNewOrderOutbound} close={this.closeAddNewOrderDrawer} />

                <EditOrderOutboundSkusDrawer {...this.props} state={this.state} onChangeState={this.onChangeSkuState} handleAutoCompleteSku={this.handleAutoCompleteSku} onClickEditSku={this.onClickEditSku} onCloseEditSku={this.onCloseEditSkuCheckUnsavedChanges} onClickDeleteSku={this.onClickDeleteSku} confirm={this.onClickConfirmOrderSkuOutbound} close={this.closeOrderSkuOutboundDrawer} />

            </div>
        );

    }
}

export default withRouter(Orders);