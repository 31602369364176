import React, {
    PureComponent
} from 'react';
import { Modal, Button, ControlLabel, Col, FormGroup, InputGroup, InputPicker, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import BarcodeMultipleCard from '../Barcode/BarcodeMultipleCard';
import PalletContentsNoMapping from '../Barcode/PalletContentsNoMapping';
import CaseContentsNoMapping from '../Barcode/CaseContentsNoMapping';

const mountRef = React.createRef();

function NewPalletBarcodeModal({
    newBarcodeModalShown,
    closePrintNewPalletBarcodes,
    // onPrintBarcodes,
    state, ...props
}) {


    //     const palletBarcodes = props.InventoryNewPalletBarcode
    //         ? 
    //             <BarcodeMultipleCard barcode={props.InventoryNewPalletBarcode.trim()} key={props.InventoryNewPalletBarcode} />
    //         : [];



    let BuildNewPalletDict = Array.isArray(state.BuildNewPalletDict) ? state.BuildNewPalletDict : [];

    let uniqueCombinations = {};

    BuildNewPalletDict.forEach(item => {
        let key = `${item.CaseSKU}-${item.LotCode}`;
        if (!uniqueCombinations[key]) {
            uniqueCombinations[key] = {
                CaseSKU: item.CaseSKU,
                LotCode: item.LotCode,
                CaseQty: 1
            };
        } else {
            uniqueCombinations[key].CaseQty++;
        }
    });

    let uniqueCombinationsArray = Object.values(uniqueCombinations);

    const palletBarcodes = props.InventoryNewPalletBarcode
        ?
        <BarcodeMultipleCard barcode={props.InventoryNewPalletBarcode.trim()} key={props.InventoryNewPalletBarcode}
            barcodeContent={
                [
                    ...uniqueCombinationsArray.slice(0, 9).map((content, index) => (
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Case SKU: {content.CaseSKU} (Qty: {content.CaseQty})
                            {content.LotCode ? ` Lot: ${content.LotCode}` : null}
                        </p>
                    )),
                    uniqueCombinationsArray.length > 9 ? <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>{uniqueCombinationsArray.length - 9} more rows...</p> : null
                ]
            }
        />
        : [];



    // let BuildNewPalletDict = state.BuildNewPalletDict || [];

    // let BuildNewPalletDict = Array.isArray(state.BuildNewPalletDict) ? state.BuildNewPalletDict : [];

    // let uniqueCombinations = {};

    // BuildNewPalletDict.forEach(item => {
    //     let key = `${item.CaseSKU}-${item.LotCode}`;
    //     if (!uniqueCombinations[key]) {
    //         uniqueCombinations[key] = {
    //             CaseSKU: item.CaseSKU,
    //             LotCode: item.LotCode,
    //             CaseQty: 1
    //         };
    //     } else {
    //         uniqueCombinations[key].CaseQty++;
    //     }
    // });

    // let uniqueCombinationsArray = Object.values(uniqueCombinations);

    // const palletBarcodes = props.InventoryNewPalletBarcode
    // ? 
    // <BarcodeMultipleCard barcode={props.InventoryNewPalletBarcode.trim()} key={props.InventoryNewPalletBarcode} 
    //     barcodeContent={
    //         uniqueCombinationsArray.map((content, index) => {
    //             let remainingItems = uniqueCombinationsArray.length - index - 10;
    //             return (
    //                 [...uniqueCombinationsArray.slice(index, index + 10).map((content, index) => (
    //                     <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
    //                         Case SKU: {content.CaseSKU} (Qty: {content.CaseQty})
    //                         {content.LotCode ? ` Lot: ${content.LotCode}` : null}
    //                     </p>
    //                 )), remainingItems > 0 ? <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>{remainingItems} more rows...</p> : null]
    //             );
    //         })
    //     }
    // />
    // : [];


    // const groupedPalletContents = props.InventoryRecoveryPalletContent
    //     ? props.InventoryRecoveryPalletContent.reduce((groups, content) => {
    //         const key = content.PalletBarcode;
    //         if (!groups[key]) {
    //             groups[key] = [];
    //         }
    //         groups[key].push(content);
    //         return groups;
    //     }, {})
    //     : {};



    // function onPrintBarcode() {
    //     // this.setState({ showPrintableBarcode: true }, () => {

    //     var container = document.getElementById("div-svg");
    //     var mySVG = document.getElementById("barcode-canvas");
    //     var width = "100%";
    //     var height = "100%";
    //     var printWindow = window.open('', 'PrintMap',
    //         'width=' + width + ',height=' + height);
    //     printWindow.document.writeln(container.innerHTML);
    //     printWindow.document.close();
    //     printWindow.print();
    //     printWindow.close();


    //     // this.setState({ showPrintableBarcode: false });

    // }


    function onPrintBarcode() {
        var container = document.getElementById("div-svg");
        var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();

    }



    function onPrintContent() {
        // this.setState({ showPrintableContent: true }, () => {
        // window.print();
        // var container = document.getElementById("div-content");
        // var mySVG = document.getElementById("barcode-canvas");
        // var width = "100%";
        // var height = "100%";
        // var printWindow = window.open('', 'PrintMap',
        //     'width=' + width + ',height=' + height);
        // printWindow.document.writeln(container.innerHTML);
        // printWindow.document.close();
        // printWindow.print();
        // printWindow.close();

        var container = document.getElementById("div-content");
        var mySVG = document.getElementById("barcode-canvas");
        var printWindow = window.open('', 'PrintMap',
            'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }


    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={newBarcodeModalShown} onHide={closePrintNewPalletBarcodes} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>New Pallet Barcode</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <FormGroup>


                        <div id="div-svg" style={{ display: 'flex', flexDirection: 'column' }}>
                            {palletBarcodes}
                        </div>

                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(e) => { onPrintBarcode(e) }} appearance="primary">
                        Print Barcodes
                    </Button>
                    <Button onClick={closePrintNewPalletBarcodes} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default NewPalletBarcodeModal;

