import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, Input } from 'rsuite';
import { lettersNumbersDashesSpacesCommasInputMask } from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

function QuoteShipmentEmailModal({
    quoteEmailModalShown,
    handleCloseEmailModal,
    // emailText,
    state, ...props

}) {
    return (

        <div className="modal-container">

            <Modal backdrop={'static'}size={"lg"}  keyboard={false} show={quoteEmailModalShown} onHide={handleCloseEmailModal} style={{ zIndex: '9999999999999', height: '800px' }}>
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {state.emailText}

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default QuoteShipmentEmailModal;