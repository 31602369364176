import React, {
  PureComponent
} from 'react';
import { Input, InputGroup, Icon, ControlLabel, Tooltip, Whisper } from 'rsuite';
import { formatStringForAPI } from '../../../../resources/logic/functions';
// import { lettersNumbersDashesInputMask } from '../../../../resources/logic/inputMasks';
import { lettersNumbersDashesInputMask } from '../../../../resources/logic/inputMasks';

const styles = {
  // width: 300,
  marginBottom: 10
};

const divStyle = {
  // marginLeft: 5,
  // marginRight: 5
};

const tooltipSearch = (
  <Tooltip>
    Search
  </Tooltip>
);

const tooltipClear = (
  <Tooltip>
    Clear
  </Tooltip>
);



function RSearchInputNumberAsStringWithoutUrlPush(
  isMobile,
  label,
  onChange,
  history,
  querySearch,
  searchValue,
) {

  let rInput;

  try {
    rInput = <div style={divStyle}>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup style={styles}>

        <Input value={searchValue} onChange={(e) => { onChange(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />

      </InputGroup>
    </div>
  }
  catch (e) { }

  return (

    rInput

  )
}


export default RSearchInputNumberAsStringWithoutUrlPush;



