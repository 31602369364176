export const uomData = [
    // { label: 'Pallet', value: 1 },
    { label: 'Case', value: 2 },
    { label: 'Each', value: 3 }
];

export const orderTypeData = [
    { label: 'Sales Order', value: 1 },
    { label: 'Purchase Order', value: 2 },
    { label: 'Transfer Order', value: 3 }
];

export const orderPriorityData = [
    { label: 'Standard', value: 1 },
    { label: 'Rush', value: 2 }
];

export const orderInboundOutboundData = [
    { label: 'Inbound', value: 'Inbound' },
    { label: 'Outbound', value: 'Outbound' }
];