import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Timeline, Button, ButtonToolbar } from 'rsuite';
import { formatNumberForAPI, isNumeric, formatStringForAPI, replaceNegativeWithZero, formatTrueFalseToBit, queryStringParse } from '../../../../resources/logic/functions';
import InventoryLoadSkuDataElements from '../../../dataComponentsInventory/InventoryLoadSkuDataElements';
import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import AddToLoadDrawer from './AddToLoadDrawer';
import AddNewModal from './AddNewModal';
import EditLoadDrawer from './EditLoadDrawer';
import LoadExportModal from '../../LoadExport/LoadExportModal';
import RemoveFromLoadModal from '../../_Non_Lot_Tracking_Components/SkuDetail/RemoveFromLoadModal';
import queryString from 'query-string';
import InventoryLoadPalletDataElements from '../../../dataComponentsInventory/InventoryLoadPalletDataElements';
import InventoryLoadCaseDataElements from '../../../dataComponentsInventory/InventoryLoadCaseDataElements';
import InventoryLoadUnitDataElements from '../../../dataComponentsInventory/InventoryLoadUnitDataElements';
import LoadSKUsHistoryDiv from '../../_Divs_Lot_History/LoadSKUsHistoryDiv';
import LoadCaseHistoryDiv from '../../_Divs_Lot_History/LoadCaseHistoryDiv';
import LoadPalletHistoryDiv from '../../_Divs_Lot_History/LoadPalletHistoryDiv';
import { appAPI } from '../../../../app/appConfig';
import RUploaderInventory from '../../../componentsGeneral/Rsuite/Uploader/RUploaderInventory';
import InventoryLoadLotFileUploadsDataElements from '../../../dataComponentsInventory/InventoryLoadLotFileUploadsDataElements';
//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "SkuID"

//For Rsuite NavBar
const styles = {
    marginBottom: 50
};


class LoadDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Edit Load Drawer
            showEditLoadDrawer: false,
            editLoadItem: {},
            unsavedEditLoadChanges: false,


            //Build Load Drawer
            showBuildLoadDrawer: false,
            item: {},
            SkuSearch: null,
            ContainerSearch: null,
            addNewModalShown: false,

            LocationDict: {},

            //Export Modal
            isModalShown: false,
            loadDataExportModal: {},

            //Remove From Load Modal
            removeFromModalShown: false,
            removeFromLoadRowData: {}

        };
        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.handleSortColumnDrawer = this.handleSortColumnDrawer.bind(this);

        // this.onClickEditRecovery = this.onClickEditRecovery.bind(this);
        this.onClickRemoveFromLoad = this.onClickRemoveFromLoad.bind(this);

        //Edit Load Drawer
        this.onChangeEditLoadDrawer = this.onChangeEditLoadDrawer.bind(this);
        this.onClickSaveEditLoadDrawer = this.onClickSaveEditLoadDrawer.bind(this);
        this.openEditLoadDrawer = this.openEditLoadDrawer.bind(this);
        this.closeEditLoadDrawer = this.closeEditLoadDrawer.bind(this);

        //Build Load Drawer
        this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onClickAddToLoad = this.onClickAddToLoad.bind(this);
        this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
        this.handleOpenAddNewModal = this.handleOpenAddNewModal.bind(this);
        this.handleCloseAddNewModal = this.handleCloseAddNewModal.bind(this);
        this.handleSaveAddNewModal = this.handleSaveAddNewModal.bind(this);
        this.onChangeExistingBuildLoadDrawer = this.onChangeExistingBuildLoadDrawer.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this)

        //Export Modal
        this.onClickExportLoad = this.onClickExportLoad.bind(this);
        this.handleCloseExportModal = this.handleCloseExportModal.bind(this);


        //Delete Load
        this.onClickDeleteLoad = this.onClickDeleteLoad.bind(this);

        //Remove From Load Modal
        this.handleOpenRemoveFromLoadModal = this.handleOpenRemoveFromLoadModal.bind(this);
        this.handleCloseRemoveFromLoadModal = this.handleCloseRemoveFromLoadModal.bind(this);
        this.handleSaveRemoveFromLoadModal = this.handleSaveRemoveFromLoadModal.bind(this);



        //Add to load
        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this)



        // Details tables 
        this.onClickPallet = this.onClickPallet.bind(this);
        this.onClickCase = this.onClickCase.bind(this);
        this.onClickUnit = this.onClickUnit.bind(this);

        this.onClickDeletePalletFromLoad = this.onClickDeletePalletFromLoad.bind(this);
        this.onClickDeleteCaseFromLoad = this.onClickDeleteCaseFromLoad.bind(this);
        this.onClickDeleteUnitFromLoad = this.onClickDeleteUnitFromLoad.bind(this);


        //File Upload
        this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);
        this.onClickDeleteFileUpload = this.onClickDeleteFileUpload.bind(this);

    }





    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 500);
    }
    handleSortColumnDrawer(sortColumnDrawer, sortTypeDrawer) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState({
                sortColumnDrawer,
                sortTypeDrawer,
                loading: false
            });
        }, 500);
    }




    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData["SkuID"]
            history.push({
                pathname: 'skudetails',
                search: 'skuid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    onClickRemoveFromLoad(rowData, keyColumn) {
        // inventorySkuWarehouseLocationHistoryGet: (this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLoadLotData[0].WarehouseID, this.props.InventoryLoadLotData[0].CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID)
        this.props.inventoryLoadSkuLocationHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLoadLotData[0].WarehouseID, this.props.InventoryLoadLotData[0].CustomerID, rowData["LoadID"], rowData["SkuID"], formatTrueFalseToBit(rowData["Oversize"]))

        this.handleOpenRemoveFromLoadModal(rowData)

        // if (window.confirm('Remove from load?')) {
        //     // this.props.transLoadRemovePost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData["LoadID"], rowData["SkuID"], rowData["PCS"], rowData["Pallets"], null /* this is new json with location */ , this.props.warehouseSelection, this.props.customerSelection)
        // }
    }






    onClickAddToLoad(e) {
        var buildLoadArray = {};
        buildLoadArray = this.props.InventoryLotSkuData

        var newLoadDict = {}
        newLoadDict['SkuSearch'] = null
        newLoadDict['ContainerSearch'] = null

        for (let k in buildLoadArray) {
            // buildLoadArray[k]['NewCasesReceived'] = buildLoadArray[k]['CasesReceived'] - buildLoadArray[k]['CasesLoaded']
            buildLoadArray[k]['NewUnitsReceived'] = buildLoadArray[k]['Units']
            buildLoadArray[k]['NewCasesReceived'] = buildLoadArray[k]['Cases']
            // buildLoadArray[k]['NewPallets'] = replaceNegativeWithZero(buildLoadArray[k]['PalletsReceived'] - buildLoadArray[k]['PalletsLoaded'])
            buildLoadArray[k]['NewPallets'] = replaceNegativeWithZero(buildLoadArray[k]['Pallets'])
        }
        this.setState({
            BuildLoadDict: buildLoadArray,
            showBuildLoadDrawer: true,
        });
    }









    //Edit Load Drawer
    openEditLoadDrawer(e) {
        var editInventoryLoadLotData = {}
        // editInventoryLoadLotData = this.props.InventoryLoadLotData[0]

        editInventoryLoadLotData['LoadID'] = this.props.InventoryLoadLotData[0].LoadID
        editInventoryLoadLotData['PickupDate'] = this.props.InventoryLoadLotData[0].PickupDate
        editInventoryLoadLotData['DeliveryDate'] = this.props.InventoryLoadLotData[0].DeliveryDate
        editInventoryLoadLotData['Carrier'] = this.props.InventoryLoadLotData[0].Carrier
        editInventoryLoadLotData['BOL'] = this.props.InventoryLoadLotData[0].BOL
        editInventoryLoadLotData['Trailer'] = this.props.InventoryLoadLotData[0].Trailer
        editInventoryLoadLotData['Seal'] = this.props.InventoryLoadLotData[0].Seal
        editInventoryLoadLotData['Destination'] = this.props.InventoryLoadLotData[0].DestinationID



        editInventoryLoadLotData['MC'] = this.props.InventoryLoadLotData[0].MC
        editInventoryLoadLotData['DOT'] = this.props.InventoryLoadLotData[0].DOT
        editInventoryLoadLotData['Driver'] = this.props.InventoryLoadLotData[0].Driver
        editInventoryLoadLotData['Truck'] = this.props.InventoryLoadLotData[0].Truck
        editInventoryLoadLotData['TruckPlate'] = this.props.InventoryLoadLotData[0].TruckPlate
        editInventoryLoadLotData['TrailerPlate'] = this.props.InventoryLoadLotData[0].TrailerPlate
        editInventoryLoadLotData['Notes'] = this.props.InventoryLoadLotData[0].Notes






        this.setState({
            unsavedEditLoadChanges: false,
            editLoadItem: editInventoryLoadLotData,
            showEditLoadDrawer: true
        });
    }

    onChangeEditLoadDrawer(event, label) {
        var currentInventoryLoadLotData = {}
        currentInventoryLoadLotData = this.state.editLoadItem
        currentInventoryLoadLotData[label] = event
        this.setState({
            unsavedEditLoadChanges: true,
            editLoadItem: currentInventoryLoadLotData
        });
        this.forceUpdate()
    }

    onClickSaveEditLoadDrawer(e) {
        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

        try {
            var paramPostLoadID = this.state.editLoadItem.LoadID
        }
        catch (e) {
            var paramPostLoadID = null
        }
        try {
            if (this.state.editLoadItem.PickupDate != null) {
                var paramPostPickupDate = "'" + moment(this.state.editLoadItem.PickupDate).format('YYYY-MM-DD') + "'"
            }
            else {
                var paramPostPickupDate = null
            }
        }
        catch (e) {
            var paramPostPickupDate = null
        }
        try {
            if (this.state.editLoadItem.DeliveryDate != null) {
                var paramPostDeliveryDate = "'" + moment(this.state.editLoadItem.DeliveryDate).format('YYYY-MM-DD') + "'"
            }
            else {
                var paramPostDeliveryDate = null
            }
        }
        catch (e) {
            var paramPostDeliveryDate = null
        }
        try {
            var paramPostCarrier = formatStringForAPI(this.state.editLoadItem.Carrier)
        }
        catch (e) {
            var paramPostCarrier = null
        }
        try {
            var paramPostBOL = formatStringForAPI(this.state.editLoadItem.BOL)
        }
        catch (e) {
            var paramPostBOL = null
        }
        try {
            var paramPostTrailer = formatStringForAPI(this.state.editLoadItem.Trailer)
        }
        catch (e) {
            var paramPostTrailer = null
        }
        try {
            var paramPostSeal = formatStringForAPI(this.state.editLoadItem.Seal)
        }
        catch (e) {
            var paramPostSeal = null
        }
        try {
            var paramPostDesintation = formatStringForAPI(this.state.editLoadItem.Destination)
        }
        catch (e) {
            var paramPostDesintation = null
        }



        try {
            var paramPostMC = formatStringForAPI(this.state.editLoadItem.MC)
        }
        catch (e) {
            var paramPostMC = null
        }
        try {
            var paramPostDOT = formatStringForAPI(this.state.editLoadItem.DOT)
        }
        catch (e) {
            var paramPostDOT = null
        }
        try {
            var paramPostDriver = formatStringForAPI(this.state.editLoadItem.Driver)
        }
        catch (e) {
            var paramPostDriver = null
        }
        try {
            var paramPostTruck = formatStringForAPI(this.state.editLoadItem.Truck)
        }
        catch (e) {
            var paramPostTruck = null
        }
        try {
            var paramPostTruckPlate = formatStringForAPI(this.state.editLoadItem.TruckPlate)
        }
        catch (e) {
            var paramPostTruckPlate = null
        }
        try {
            var paramPostTrailerPlate = formatStringForAPI(this.state.editLoadItem.TrailerPlate)
        }
        catch (e) {
            var paramPostTrailerPlate = null
        }
        try {
            var paramPostNotes = formatStringForAPI(this.state.editLoadItem.Notes)
        }
        catch (e) {
            var paramPostNotes = null
        }



        this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, paramPostLoadID, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, null, null, paramPostMC, paramPostDOT, paramPostDriver, paramPostTruck, paramPostTruckPlate, paramPostTrailerPlate, paramPostNotes) 

        this.setState({
            showEditLoadDrawer: false,
            unsavedEditLoadChanges: false,
            editLoadItem: {}
        });
        // }
        // else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    closeEditLoadDrawer(e) {
        if (this.state.unsavedEditLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setState({
                    showEditLoadDrawer: false,
                    unsavedEditLoadChanges: false,
                    editLoadItem: {}
                });
            }
        }
        else {
            this.setState({
                showEditLoadDrawer: false,
                unsavedEditLoadChanges: false,
                editLoadItem: {}
            });
        }
    }











    //Build Load Drawer
    onClickBuildLoad(e) {

        this.setState({
            showBuildLoadDrawer: true
        });

    }
    onChangeBuildLoadDrawer = (event, label) => {

        var poState = null
        if (label == 'SkuSearch') {
            poState = event
        }
        var containerState = null
        if (label == 'ContainerSearch') {
            containerState = event
        }

        this.setState({
            SkuSearch: poState,
            ContainerSearch: containerState

        });

    };


    onClickAdd(e, item) {
        var poID = item['SkuID']
        var SkuLocationDataGrouped = JSON.parse(JSON.stringify(this.props.SkuLocationDataGrouped));

        var newLocationDict = Object.keys(SkuLocationDataGrouped).
            filter((key) => poID === (Number(key))).
            reduce((cur, key) => { return Object.assign(cur, { [key]: SkuLocationDataGrouped[key] }) }, {});

        var newItem = {};
        newItem = item

        try {
            newItem['NewUnitsReceived'] = replaceNegativeWithZero((newLocationDict[newItem['SkuID']].reduce((a, v) => a = a + v.Units, 0)))
            newItem['NewCasesReceived'] = replaceNegativeWithZero((newLocationDict[newItem['SkuID']].reduce((a, v) => a = a + v.Cases, 0)))
            newItem['NewPallets'] = replaceNegativeWithZero((newLocationDict[newItem['SkuID']].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            // newItem['NewCasesReceived'] = replaceNegativeWithZero(item['CasesReceived'] - item['CasesLoaded'])
            // newItem['NewPallets'] = replaceNegativeWithZero(item['PalletsReceived'] - item['PalletsLoaded'])
            newItem['NewUnitsReceived'] = replaceNegativeWithZero(item['Units'])
            newItem['NewCasesReceived'] = replaceNegativeWithZero(item['Cases'])
            newItem['NewPallets'] = replaceNegativeWithZero(item['Pallets'])
        }

        this.setState({
            LocationDict: newLocationDict,
            item: newItem
        });
        this.handleOpenAddNewModal()
    }

    closeBuildLoadDrawer() {
        this.setBuildLoadDrawerToClose()
    }

    setBuildLoadDrawerToClose() {
        this.setState({
            LocationDict: {},
            showBuildLoadDrawer: false
            // item: {}
        });
    }



    onClickSearch(e, searchType) {

        if (searchType == 'SkuSearch') {
            this.setState({
                ContainerSearch: null,
                item: {}
            });
            this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLoadLotData[0].WarehouseID, this.props.InventoryLoadLotData[0].CustomerID, null, formatStringForAPI(this.state.SkuSearch), null, null, null, null)
            this.props.inventoryLotSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLoadLotData[0].WarehouseID, this.props.InventoryLoadLotData[0].CustomerID, null, formatStringForAPI(this.state.SkuSearch), null, null, null, null)
        }
        else if (searchType == 'ContainerSearch') {
            this.setState({
                SkuSearch: null,
                item: {}
            });
            this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLoadLotData[0].WarehouseID, this.props.InventoryLoadLotData[0].CustomerID, null, null, null, formatStringForAPI(this.state.ContainerSearch), null, null)
            this.props.inventoryLotSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLoadLotData[0].WarehouseID, this.props.InventoryLoadLotData[0].CustomerID, null, null, null, formatStringForAPI(this.state.ContainerSearch), null, null)
        }
    }


    onClickReset(e, searchType) {
        this.setState({
            SkuSearch: null,
            ContainerSearch: null,
            item: {},
            LocationDict: {},
        });

        const values = queryString.parse(this.props.location.search)
        try {
            var paramWarehouseID = queryStringParse(values.warehouseid)
        }
        catch (e) {
            var paramWarehouseID = null
        }

        try {
            var paramCustomerID = queryStringParse(values.customerid)
        }
        catch (e) {
            var paramCustomerID = null
        }
        this.props.inventoryLotSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramWarehouseID, paramCustomerID, null, null, null, null, 1, null)
    }


    handleOpenAddNewModal() {
        this.setState({
            addNewModalShown: true
        });
    }
    handleCloseAddNewModal() {
        this.setState({
            addNewModalShown: false
        });
    }


    onChangeExistingBuildLoadDrawer = (event, item, label) => {
        var existingItem = this.state.item

        existingItem[label] = isNumeric(event)

        this.setState({
            item: existingItem
        });
    };

    onChangeLocation = (e, locationitem, label, SkuWarehouseLocationID) => {

        var poId = this.state.item.SkuID;
        var itemIndex = this.state.LocationDict[poId].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === SkuWarehouseLocationID);
        var newLocationBuildDict = this.state.LocationDict

        newLocationBuildDict[poId][itemIndex][label] = isNumeric(e)

        // var currentBuildLoadDictIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === poId);
        var currentBuildLoadDict = this.state.item

        try {
            currentBuildLoadDict['NewUnitsReceived'] = replaceNegativeWithZero((newLocationBuildDict[poId].reduce((a, v) => a = a + v.Units, 0)))
            currentBuildLoadDict['NewCasesReceived'] = replaceNegativeWithZero((newLocationBuildDict[poId].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict['NewPallets'] = replaceNegativeWithZero((newLocationBuildDict[poId].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            currentBuildLoadDict['NewUnitsReceived'] = replaceNegativeWithZero(currentBuildLoadDict['Units'])
            currentBuildLoadDict['NewCasesReceived'] = replaceNegativeWithZero(currentBuildLoadDict['Cases'])
            currentBuildLoadDict['NewPallets'] = replaceNegativeWithZero(currentBuildLoadDict['Pallets'])
        }

        this.setState({
            // unsavedBuildLoadChanges: true,
            item: currentBuildLoadDict,
            LocationDict: newLocationBuildDict,
        });

        this.forceUpdate()
    }

    onClickRemoveFromAdd = (e, locationitem, SkuWarehouseLocationID) => {
        var currentList = JSON.parse(JSON.stringify(this.state.LocationDict));

        var poID = this.state.item.SkuID

        var remainingItems = currentList[poID].filter((item) => { return item.SkuWarehouseLocationID !== SkuWarehouseLocationID });

        currentList[poID] = JSON.parse(JSON.stringify(remainingItems));
        var currentBuildLoadDict = this.state.item

        try {
            currentBuildLoadDict['NewUnitsReceived'] = replaceNegativeWithZero((currentList[poID].reduce((a, v) => a = a + v.Units, 0)))
            currentBuildLoadDict['NewCasesReceived'] = replaceNegativeWithZero((currentList[poID].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict['NewPallets'] = replaceNegativeWithZero((currentList[poID].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            currentBuildLoadDict['NewUnitsReceived'] = replaceNegativeWithZero(currentBuildLoadDict['UnitsReceived'] - currentBuildLoadDict['UnitsLoaded'])
            currentBuildLoadDict['NewCasesReceived'] = replaceNegativeWithZero(currentBuildLoadDict['CasesReceived'] - currentBuildLoadDict['CasesLoaded'])
            currentBuildLoadDict['NewPallets'] = replaceNegativeWithZero(currentBuildLoadDict['PalletsReceived'] - currentBuildLoadDict['PalletsLoaded'])
        }

        this.setState({
            // unsavedBuildLoadChanges: true,
            item: currentBuildLoadDict,
            LocationDict: currentList,
        });

        this.forceUpdate()
    }

    handleSaveAddNewModal() {
        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

        const currentListLocation = this.state.LocationDict

        var newLocationToPost = []
        for (let x in currentListLocation) {
            for (let i in currentListLocation[x]) {
                try {
                    newLocationToPost.push({ 'SkuID': isNumeric(x), 'WarehouseLocationID': currentListLocation[x][i]['WarehouseLocationID'], 'SkuWarehouseLocationID': currentListLocation[x][i]['SkuWarehouseLocationID'], 'Units': currentListLocation[x][i]['Units'], 'Cases': currentListLocation[x][i]['Cases'], 'Pallets': currentListLocation[x][i]['Pallets'], 'Oversize': formatTrueFalseToBit(currentListLocation[x][i]['Oversize']) })
                }
                catch (e) { }
            }
        }

        var locationString = JSON.stringify(newLocationToPost)
        try {
            var paramPostLocationPoData = "'" + locationString + "'"
        }
        catch (e) {
            var paramPostLocationPoData = null
        }

        //NOT IN USE!!!!
        // NewUnitsReceived
        // NewOversize
        // this.props.inventoryLoadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, , null, null, null, null, null, null, null, null, null, null, null, null, null, , null, null, null, this.state.item.SkuID, this.state.item.NewUnitsReceived, this.state.item.NewCasesReceived, this.state.item.NewPallets, null, null, null)
        // this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPostLocationPoData, null, this.props.InventoryLoadLotData[0].LoadID, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, 2)


        this.handleCloseAddNewModal()

        this.setBuildLoadDrawerToClose()
        // }
        // else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }


    //Export Modal
    onClickExportLoad(e) {
        this.setState({
            loadDataExportModal: this.props.InventoryLoadLotData[0],
            isModalShown: true
        });

    }
    handleCloseExportModal() {
        this.setState({
            isModalShown: false
        });
    }


    //Delete Load
    onClickDeleteLoad(e) {
        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
        if (this.props.InventoryLoadSkuData !== undefined && this.props.InventoryLoadSkuData.length > 0) {
            window.alert('Please first remove all SKUs from Load')
        }
        else if (window.confirm('Are you sure you want to delete this load?')) {
            if (this.props.InventoryLoadPalletCaseUnitData !== undefined && this.props.InventoryLoadPalletCaseUnitData.length > 0) {
                this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, this.props.InventoryLoadLotData[0].LoadID, null, null, null, null, null, null, null, null, 1)
            }
            else {
                this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, this.props.InventoryLoadLotData[0].LoadID, null, null, null, null, null, null, null, null, null, null, null, 4, null, null, null, null, null, null, null, null)

            }
        }

    }

    //Depart Load
    onClickDepartLoad(e) {
        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

        if (this.props.InventoryLoadLotData[0].Trailer === null || this.props.InventoryLoadLotData[0].Trailer == 'null') {
            window.alert('Please enter Trailer.')
        }
        else if (this.props.InventoryLoadLotData[0].Carrier === null || this.props.InventoryLoadLotData[0].Carrier == 'null') {
            window.alert('Please enter Carrier.')
        }
        else if (this.props.InventoryLoadLotData[0].BOL === null || this.props.InventoryLoadLotData[0].BOL == 'null') {
            window.alert('Please enter BOL.')
        }
        else if (this.props.InventoryLoadLotData[0].Seal === null || this.props.InventoryLoadLotData[0].Seal == 'null') {
            window.alert('Please enter Seal.')
        }
        else if (this.props.InventoryLoadLotData[0].PickupDate === null || this.props.InventoryLoadLotData[0].PickupDate == 'null') {
            window.alert('Please enter Pickup Date.')
        }
        else if (window.confirm('Depart Load?')) {
            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, this.props.InventoryLoadLotData[0].LoadID, null, null, null, null, null, null, null, null, null, null, null, 2, null, null, null, null, null, null, null, null)

        }
        // }
        // else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }


    //Remove from load modal
    handleOpenRemoveFromLoadModal(rowData) {
        this.setState({
            removeFromModalShown: true,
            removeFromLoadRowData: rowData
        });
    }
    handleCloseRemoveFromLoadModal() {
        this.setState({
            removeFromModalShown: false,
            removeFromLoadRowData: {}
        });
    }
    handleSaveRemoveFromLoadModal() {
        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

        const currentListLocation = this.props.InventoryLoadSkuLocationHistoryData

        var newLocationToPost = []
        for (let x in currentListLocation) {
            try {
                newLocationToPost.push({ 'SkuID': isNumeric(currentListLocation[x]["SkuID"]), 'WarehouseLocationID': currentListLocation[x]['WarehouseLocationID'], 'Units': currentListLocation[x]['NetUnits'], 'Cases': currentListLocation[x]['NetCases'], 'Pallets': currentListLocation[x]['NetPallets'], 'Oversize': formatTrueFalseToBit(currentListLocation[x]['Oversize']) })
            }
            catch (e) { }
        }
        var locationString = JSON.stringify(newLocationToPost)
        try {
            var paramPostLocationPoData = "'" + locationString + "'"
        }
        catch (e) {
            var paramPostLocationPoData = null
        }

        if (this.props.InventoryLoadSkuLocationHistoryData.length === 0) {
            paramPostLocationPoData = null
        }

        this.props.inventoryLoadRemoveSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, this.state.removeFromLoadRowData["LoadID"], this.state.removeFromLoadRowData["SkuID"], this.state.removeFromLoadRowData["Units"], this.state.removeFromLoadRowData["Cases"], this.state.removeFromLoadRowData["Pallets"], this.state.removeFromLoadRowData["Oversize"], null, null, paramPostLocationPoData)

        this.handleCloseRemoveFromLoadModal()
        // }
        // else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }


    // Details tables 

    onClickPallet(e, rowData, history) {
        try {
            var paramID = rowData["PalletID"]
            history.push({
                pathname: 'palletdetails',
                search: 'palletid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }




    onClickCase(e, rowData, history) {
        try {
            var paramID = rowData["CaseID"]
            history.push({
                pathname: 'casedetails',
                search: 'caseid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }



    onClickUnit(e, rowData, history) {
        try {
            var paramSkuID = rowData['SkuID']
            var paramPalletID = rowData['SkuPalletID']
            var paramCaseID = rowData['SkuCaseID']
            var paramUnitID = rowData['UnitCaseSkuID']

            history.push({
                pathname: 'unitdetails',
                search: 'skuid=' + paramSkuID + '&' +
                    'palletid=' + paramPalletID + '&' +
                    'unitid=' + paramUnitID + '&' +
                    'caseid=' + paramCaseID
            });
        }
        catch (e) {
        }
    }

    onClickDeletePalletFromLoad(e, rowData) {
        if (window.confirm('Remove from load?')) {

            const values = queryString.parse(this.props.location.search)

            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }

            this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, rowData['PalletID'], null, null, null, null, null, null, null, null)
        }
    }

    onClickDeleteCaseFromLoad(e, rowData) {
        if (window.confirm('Remove from load?')) {

            const values = queryString.parse(this.props.location.search)

            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }

            this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, rowData['CaseID'], null, null, null, null, null, null)
        }
    }

    onClickDeleteUnitFromLoad(e, rowData) {
        if (window.confirm('Remove from load?')) {

            const values = queryString.parse(this.props.location.search)

            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }

            this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, null, null, null, rowData['UnitCaseSkuID'], rowData['SkuUnits'], null, null)
        }
    }







    //File Upload
    onClickDownloadFileUpload(e, rowData, history) {
        try {
            this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.API.data.FileDownloadInventoryS3)
        }
        catch (e) {
            window.alert("Error Downloading File.")
        }
    }

    onClickDeleteFileUpload(e, rowData, history) {
        if (window.confirm('Do you wish to delete this file?'))
            this.props.inventoryLotLoadFileUploadPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                this.props.warehouseSelection,
                this.props.customerSelection,
                formatNumberForAPI(rowData.FileUploadID),
                null,
                null,
                null,
                null,
                1
            )
    }






    render() {
        const { sortColumn, sortType, sortColumnDrawer, sortTypeDrawer } = this.state;

        let pageHeader;
        let detailsDiv;
        let editLoadDrawerDiv;
        let houseButtonDiv;
        let fileUploadDiv;
        if (this.props.isInventoryLoadLotLoading) {
            houseButtonDiv = <div></div>
            editLoadDrawerDiv = <div></div>
            detailsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadLotData[0]) !== 'undefined' && this.props.InventoryLoadLotData != null) {

            var currentLoadStatus = this.props.InventoryLoadLotData[0].CurrentLoadStatusID


            var loadID = this.props.InventoryLoadLotData[0].LoadID


            var s3FilePrefix = 'Load_' + loadID



            fileUploadDiv = RUploaderInventory(
                this.props.isMobile,                          // isMobile,
                this.props.history,                           // history,
                this.props.currentUserEmail,                  //currentUserEmail,
                this.props.userSignInData,                    //userSignInData
                this.props.warehouseSelection,                //warehouseSelection,
                this.props.customerSelection,                 //customerSelection
                this.props.InventoryLotLoadFileUploadData,      //fileList,
                this.props.inventoryLotLoadFileUploadPost,     //onUploadAction,
                null,                                         // loading,
                loadID,                                       // dbID,
                s3FilePrefix,     // s3FilePrefix,
                appAPI.API.data.FileDownloadInventoryS3   //s3 bucket
            )

            houseButtonDiv = currentLoadStatus != 4 ? <Row>
                SKU List
                <Button onClick={(e) => { this.onClickAddToLoad(e) }} style={{ float: 'right' }}> Add to Load </Button>
            </Row> : <div></div>

            editLoadDrawerDiv = <EditLoadDrawer {...this.props} state={this.state}
                onChange={this.onChangeEditLoadDrawer}
                onClickSave={this.onClickSaveEditLoadDrawer}
                close={this.closeEditLoadDrawer}
            />

            pageHeader = 'Inventory Management - Load Details'
            detailsDiv =
                <div>


                    <Row style={{ marginBottom: 10 }} >
                        <Col md={24} sm={24}>
                            {currentLoadStatus != 4 ? <ButtonToolbar style={{ float: 'right' }}>  {this.props.InventoryLoadLotData[0].CurrentLoadStatusID == 1 ? <Button onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Load</Button> : ''}  <Button onClick={(e) => { this.openEditLoadDrawer(e) }} > Edit Load </Button> <Button onClick={(e) => { this.onClickExportLoad(e) }} > Manifest </Button><Button onClick={(e) => { this.onClickDeleteLoad(e) }} > Delete Load </Button></ButtonToolbar> : ''}
                        </Col>
                    </Row>



                    <Row >

                        <Col md={12} sm={12}>
                            <Panel bordered style={{ marginBottom: 10 }}  >
                                Warehouse: <strong>{this.props.InventoryLoadLotData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{this.props.InventoryLoadLotData[0].CustomerName}</strong>
                                <br />
                                Carrier: <strong>{this.props.InventoryLoadLotData[0].Carrier}</strong>
                                <br />
                                Trailer: <strong>{this.props.InventoryLoadLotData[0].Trailer}</strong>
                                <br />
                                BOL: <strong>{this.props.InventoryLoadLotData[0].BOL}</strong>
                                <br />
                                Seal: <strong>{this.props.InventoryLoadLotData[0].Seal}</strong>
                                <br />
                                {/* Consignee: <strong>{this.props.InventoryLoadLotData[0].ConsigneeName}</strong> */}
                                {/* <br /> */}
                                Destination: <strong>{this.props.InventoryLoadLotData[0].Destination}</strong>
                                <br />
                                Load Status: <strong>{this.props.InventoryLoadLotData[0].LoadStatusName}</strong>
                                <br />

                                
                            </Panel>
                        </Col>


                        <Col md={12} sm={12}>
                            <Panel bordered style={{ marginBottom: 10 }}  >
                                MC #: <strong>{this.props.InventoryLoadLotData[0].MC}</strong>
                                <br />
                                DOT #: <strong>{this.props.InventoryLoadLotData[0].DOT}</strong>
                                <br />
                                Driver: <strong>{this.props.InventoryLoadLotData[0].Driver}</strong>
                                <br />
                                Truck #: <strong>{this.props.InventoryLoadLotData[0].Truck}</strong>
                                <br />
                                Truck Plate: <strong>{this.props.InventoryLoadLotData[0].TruckPlate}</strong>
                                <br />
                                Trailer Plate: <strong>{this.props.InventoryLoadLotData[0].TrailerPlate}</strong>
                                <br />
                                Notes: <strong>{this.props.InventoryLoadLotData[0].Notes}</strong>
                                <br />
                                <br />

                                
                            </Panel>
                        </Col>



                    </Row>


                    {
                        (this.props.InventoryLoadLotData[0].TotalUnits == null && this.props.InventoryLoadLotData[0].TotalCases == null && this.props.InventoryLoadLotData[0].TotalPallets == null) ||
                            (this.props.InventoryLoadLotData[0].TotalUnits == 0 && this.props.InventoryLoadLotData[0].TotalCases == 0 && this.props.InventoryLoadLotData[0].TotalPallets == 0)

                            ? <div></div> :

                            <Row >
                                <Col md={12} sm={24}>
                                    <Panel bordered style={{ marginBottom: 10 }}>
                                        Total Units: <strong>{this.props.InventoryLoadLotData[0].TotalUnits}</strong>
                                        <br />
                                        Total Cases: <strong>{this.props.InventoryLoadLotData[0].TotalCases}</strong>
                                        <br />
                                        Total Pallets: <strong>{this.props.InventoryLoadLotData[0].TotalPallets}</strong>
                                        <br />

                                    </Panel>
                                </Col>
                                <Col md={12} sm={24}>
                                    <Panel bordered style={{ marginBottom: 10 }}>

                                        Pickup Date: <strong>{this.props.InventoryLoadLotData[0].PickupDate}</strong>
                                        <br />

                                        Delivery Date: <strong>{this.props.InventoryLoadLotData[0].DeliveryDate}</strong>
                                        <br />
                                        <br />


                                    </Panel>
                                </Col>
                            </Row>
                    }
                </div>

        }
        else {
            detailsDiv = <div></div>;
            editLoadDrawerDiv = <div></div>;
            houseButtonDiv = <div></div>
        }



        let palletCaseUnitdiv;
        if (this.props.isInventoryLoadPalletCaseUnitLoading) {
            palletCaseUnitdiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadPalletCaseUnitData[0]) !== 'undefined' && this.props.InventoryLoadPalletCaseUnitData != null) {

            let palletTable;
            let caseTable;
            let unitTable;

            try {
                var palletTableData = this.props.InventoryLoadPalletCaseUnitData.filter(x => x.LoadPalletID != null)
                if (palletTableData.length > 0) {


                    palletTable = <Panel header={'Load Pallets'} bordered style={{ marginBottom: 10 }}>
                        <div>

                            {(this.props.isMobile == true) ? <div></div> :
                                <div>
                                    <Row>
                                        <ExcelFile element={<Button >Export Excel</Button>} filename={'Pallet_' + currentDateTime}>
                                            <ExcelSheet data={palletTableData} name="Pallet">

                                                {
                                                    InventoryLoadPalletDataElements.map(
                                                        ({ dBKey, displayName }, idx) => {
                                                            return (
                                                                <ExcelColumn label={displayName} value={dBKey} />
                                                            );
                                                        }
                                                    )
                                                }

                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Row>
                                </div>
                            }

                        </div>

                        {RTableGeneral(
                            'LoadPalletID',     //   keyColumn,
                            palletTableData,     //   tableData,
                            InventoryLoadPalletDataElements,       //   DataElements,
                            this.props.isInventoryLoadPalletCaseUnitLoading,        //   loading,

                            this.props.isMobile,        //   isMobile,
                            this.props.history,     //   history,

                            null,     //   sortColumn,
                            null,       //   sortType,
                            null,      //   handleSortColumn,

                            null, // checkColEntry,
                            null,       //   checkedKeys,
                            null,       //   handleCheckAll,
                            null,       //   handleCheck,

                            null,      //   onClickAddMilestone,
                            null,       //   onClickSelect,
                            null,       //   selectButtonText,
                            null,       //   selectHighlightRowKey,

                            true,       //   link1bool,
                            'Details',     //   link1text,
                            this.onClickPallet,     //   link1onClick,

                            true,       //   link2bool,
                            'Delete',       //   link2text,
                            this.onClickDeletePalletFromLoad  //   link2onClick
                        )
                        }
                    </Panel>


                }
                else {
                    palletTable = <div></div>
                }

            }
            catch (e) {
                palletTable = <div></div>
            }

            try {
                var caseTableData = this.props.InventoryLoadPalletCaseUnitData.filter(x => x.LoadCaseID != null)
                if (caseTableData.length > 0) {
                    caseTable = <Panel header={'Load Cases'} bordered style={{ marginBottom: 10 }}>
                        <div>
                            {(this.props.isMobile == true) ? <div></div> :
                                <div>
                                    <Row>
                                        <ExcelFile element={<Button >Export Excel</Button>} filename={'Case_' + currentDateTime}>
                                            <ExcelSheet data={caseTableData} name="Case">
                                                {
                                                    InventoryLoadCaseDataElements.map(
                                                        ({ dBKey, displayName }, idx) => {
                                                            return (
                                                                <ExcelColumn label={displayName} value={dBKey} />
                                                            );
                                                        }
                                                    )
                                                }
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Row>
                                </div>
                            }

                        </div>

                        {RTableGeneral(
                            'LoadCaseID',     //   keyColumn,
                            caseTableData,     //   tableData,
                            InventoryLoadCaseDataElements,       //   DataElements,
                            this.props.isInventoryLoadPalletCaseUnitLoading,        //   loading,

                            this.props.isMobile,        //   isMobile,
                            this.props.history,     //   history,

                            null,     //   sortColumn,
                            null,       //   sortType,
                            null,      //   handleSortColumn,

                            null, // checkColEntry,
                            null,       //   checkedKeys,
                            null,       //   handleCheckAll,
                            null,       //   handleCheck,

                            null,      //   onClickAddMilestone,
                            null,       //   onClickSelect,
                            null,       //   selectButtonText,
                            null,       //   selectHighlightRowKey,

                            true,       //   link1bool,
                            'Details',     //   link1text,
                            this.onClickCase,     //   link1onClick,

                            true,       //   link2bool,
                            'Delete',       //   link2text,
                            this.onClickDeleteCaseFromLoad  //   link2onClick
                        )
                        }
                    </Panel>
                }
                else {
                    caseTable = <div></div>
                }
            }
            catch (e) {
                caseTable = <div></div>
            }

            try {
                var unitTableData = this.props.InventoryLoadPalletCaseUnitData.filter(x => x.LoadSkuID != null)
                if (unitTableData.length > 0) {
                    unitTable = <Panel header={'Load Units'} bordered style={{ marginBottom: 10 }}>
                        <div>
                            {(this.props.isMobile == true) ? <div></div> :
                                <div>
                                    <Row>
                                        <ExcelFile element={<Button >Export Excel</Button>} filename={'Unit_' + currentDateTime}>
                                            <ExcelSheet data={caseTableData} name="Unit">
                                                {
                                                    InventoryLoadUnitDataElements.map(
                                                        ({ dBKey, displayName }, idx) => {
                                                            return (
                                                                <ExcelColumn label={displayName} value={dBKey} />
                                                            );
                                                        }
                                                    )
                                                }
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Row>
                                </div>
                            }

                        </div>
                        {
                            RTableGeneral(
                                'LoadSkuID',     //   keyColumn,
                                unitTableData,     //   tableData,
                                InventoryLoadUnitDataElements,       //   DataElements,
                                this.props.isInventoryLoadPalletCaseUnitLoading,        //   loading,

                                this.props.isMobile,        //   isMobile,
                                this.props.history,     //   history,

                                null,     //   sortColumn,
                                null,       //   sortType,
                                null,      //   handleSortColumn,

                                null, // checkColEntry,
                                null,       //   checkedKeys,
                                null,       //   handleCheckAll,
                                null,       //   handleCheck,

                                null,      //   onClickAddMilestone,
                                null,       //   onClickSelect,
                                null,       //   selectButtonText,
                                null,       //   selectHighlightRowKey,

                                true,       //   link1bool,
                                'Details',     //   link1text,
                                this.onClickUnit,     //   link1onClick,

                                true,       //   link2bool,
                                'Delete',       //   link2text,
                                this.onClickDeleteUnitFromLoad  //   link2onClick
                            )
                        }
                    </Panel>
                }
                else {
                    unitTable = <div></div>
                }
            }
            catch (e) {
                unitTable = <div></div>
            }

            palletCaseUnitdiv = <div>
                {palletTable}
                {caseTable}
                {unitTable}
            </div>




        }
        else {
            palletCaseUnitdiv = <duv></duv>
        }




        let buildLoadDrawer;
        if (this.props.isInventoryLotSkuLoading) {
            buildLoadDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (typeof (this.state.BuildLoadDict) !== 'undefined' && this.state.BuildLoadDict != null && this.state.BuildLoadDict.length > 0 && this.state.showBuildLoadDrawer) {
            buildLoadDrawer = <AddToLoadDrawer {...this.props} state={this.state}
                onClickAdd={this.onClickAdd}
                onChange={this.onChangeBuildLoadDrawer}
                close={this.closeBuildLoadDrawer}
                handleSortColumn={this.handleSortColumnDrawer}
                sortColumn={this.state.sortColumnDrawer}
                sortType={this.state.sortTypeDrawer}
                onClickSearch={this.onClickSearch}
                onClickReset={this.onClickReset}
                tableIdCol={tableIdCol}
            />
        }


        let addNewModalDiv;
        if (!this.state.addNewModalShown) {
            addNewModalDiv = <div></div>
        }
        else if (this.props.isPoLocationLoading) {
            addNewModalDiv = <div></div>
        }
        else {
            addNewModalDiv = <AddNewModal {...this.props} state={this.state} addNewModalShown={this.state.addNewModalShown} onChangeExisting={this.onChangeExistingBuildLoadDrawer} onChangeLocation={this.onChangeLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseAddNewModal} handleSave={this.handleSaveAddNewModal} />
        }

        let removeFromLoadModalDiv;
        if (!this.state.removeFromModalShown) {
            removeFromLoadModalDiv = <div></div>
        }
        else if (this.props.isInventoryLoadSkuLocationHistoryLoading) {
            removeFromLoadModalDiv = <div></div>
        }
        else {
            removeFromLoadModalDiv = <RemoveFromLoadModal {...this.props} state={this.state} titlePO={this.state.removeFromLoadRowData["SKU"]} removeFromModalShown={this.state.removeFromModalShown} onChangeExisting={this.onChangeExistingBuildLoadDrawer} onChangeLocation={this.onChangeLocation} handleOpen={this.handleOpenRemoveFromLoadModal} handleClose={this.handleCloseRemoveFromLoadModal} handleSave={this.handleSaveRemoveFromLoadModal} />
        }






        let fileUploadsTable;
        if (this.props.isInventoryLotLoadFileUploadLoading) {

            fileUploadsTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotLoadFileUploadData) !== 'undefined' && this.props.InventoryLotLoadFileUploadData != null && this.props.InventoryLotLoadFileUploadData.length > 0) {
            var milestoneTableComponent = RTableGeneral(
                "FileUploadID",     //   keyColumn,
                this.props.InventoryLotLoadFileUploadData,     //   tableData,
                InventoryLoadLotFileUploadsDataElements,       //   DataElements,
                this.props.isInventoryLotLoadFileUploadLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   onClickEditMilestone,
                this.onClickDownloadFileUpload,       //   onClickSelect,
                'Download',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Delete',     //   link1text,
                this.onClickDeleteFileUpload,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )

            fileUploadsTable = <Panel
                bordered style={{ marginBottom: 10 }}
                header='File Uploads'
            >
                {milestoneTableComponent}
            </Panel>


        }
        else {
            fileUploadsTable = <div></div>
        }






        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>
                    {detailsDiv}
                </PanelGroup>



                {palletCaseUnitdiv}




                <LoadPalletHistoryDiv
                    isInventoryLoadPalletsHistoryLoading={this.props.isInventoryLoadPalletsHistoryLoading}
                    InventoryLoadPalletsHistoryData={this.props.InventoryLoadPalletsHistoryData}
                />
                <LoadCaseHistoryDiv
                    isInventoryLoadCasesHistoryLoading={this.props.isInventoryLoadCasesHistoryLoading}
                    InventoryLoadCasesHistoryData={this.props.InventoryLoadCasesHistoryData}
                />
                <LoadSKUsHistoryDiv
                    isInventoryLoadSKUsHistoryLoading={this.props.isInventoryLoadSKUsHistoryLoading}
                    InventoryLoadSKUsHistoryData={this.props.InventoryLoadSKUsHistoryData}
                />




                {buildLoadDrawer}
                {editLoadDrawerDiv}
                {addNewModalDiv}
                <LoadExportModal {...this.props} state={this.state}
                    isModalShown={this.state.isModalShown}
                    handleClose={this.handleCloseExportModal}
                />

                {removeFromLoadModalDiv}



                {fileUploadsTable}
                {fileUploadDiv}



            </div>
        );
    }
}

export default withRouter(LoadDetails);

