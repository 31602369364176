import { connect } from 'react-redux';
import React, { Component } from 'react';
import Dashboard from './Dashboard'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/visibilityActions';

import { formatDateForAPI, queryStringParse, queryStringParseWithZero } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isVisibilityDashboardStatsLoading: store.visibilityReducer.isVisibilityDashboardStatsLoading,
    VisibilityDashboardStatsData: store.visibilityReducer.VisibilityDashboardStatsData,

    isVisibilityDashboardPackageListLoading: store.visibilityReducer.isVisibilityDashboardPackageListLoading,
    VisibilityDashboardPackageListData: store.visibilityReducer.VisibilityDashboardPackageListData,
});

const mapDispatchToProps = dispatch =>
({
    visibilityDashboardStatsGet: (_email, _authToken, _WarehouseID, _CustomerID, _PackageID, _DateStart, _DateEnd) => {
        dispatch(actions.visibilityDashboardStatsGet(_email, _authToken, _WarehouseID, _CustomerID, _PackageID, _DateStart, _DateEnd))
    },
    visibilityDashboardPackageListGet: (_email, _authToken, _WarehouseID, _CustomerID, _PackageID, _TrackingNo, _DateStart, _DateEnd, _AddressUpdate, _TransitException, _DeliveryException, _Delivered, _Damaged, _InsufficientAdd, _RTS) => {
        dispatch(actions.visibilityDashboardPackageListGet(_email, _authToken, _WarehouseID, _CustomerID, _PackageID, _TrackingNo, _DateStart, _DateEnd, _AddressUpdate, _TransitException, _DeliveryException, _Delivered, _Damaged, _InsufficientAdd, _RTS))
    },
});

class DashboardContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

    }

    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)



        try {
            var paramDateStart = formatDateForAPI(values.datestart)
        }
        catch (e) {
            var paramDateStart = null
        }
        try {
            var paramDateEnd = formatDateForAPI(values.dateend)
        }
        catch (e) {
            var paramDateEnd = null
        }

        this.props.visibilityDashboardStatsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramDateStart, paramDateEnd)
   
    }



    render() {

        return (

            <Dashboard
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isVisibilityDashboardStatsLoading={this.props.isVisibilityDashboardStatsLoading}
                VisibilityDashboardStatsData={this.props.VisibilityDashboardStatsData}

                visibilityDashboardPackageListGet={this.props.visibilityDashboardPackageListGet}
                isVisibilityDashboardPackageListLoading={this.props.isVisibilityDashboardPackageListLoading}
                VisibilityDashboardPackageListData={this.props.VisibilityDashboardPackageListData}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardContainer));



