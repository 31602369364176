import * as moment from 'moment';
import React, {
    PureComponent
} from 'react';
import ReactExport from "react-export-excel";
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { Button, Col, Panel, PanelGroup, Row, Table } from 'rsuite';
import { formatStringForAPI } from '../../../../resources/logic/functions';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryLoadSkuDetailsDataElements from '../../../dataComponentsInventory/InventoryLoadSkuDetailsDataElements';
// import InventorySkuWarehouseDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseDataElements';
import InventoryPalletWarehouseDataElements from '../../../dataComponentsInventory/InventoryPalletWarehouseDataElements';
import CaseSKUsHistoryDiv from '../../_Divs_Lot_History/CaseSKUsHistoryDiv';
import LoadSKUsHistoryDiv from '../../_Divs_Lot_History/LoadSKUsHistoryDiv';
import EditSkuDetailsDrawer from './EditSkuDetailsDrawer';
import EditUnitDrawer from './EditUnitDrawer';

const tableIdCol = "CaseSkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

const { Column, HeaderCell, Cell } = Table;

class UnitDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            sortTableColumn: '',
            sortTableType: null,

            // //Edit Po Values
            // showEditSkuDetailsDrawer: false,
            // unsavedEditSkuValues: false,
            // // Po: null,
            // CustomerRef: null,
            // SKU: null,
            // Project: null,
            // // ExpectedCases: null,
            // // ExpectedPallets: null,
            // Notes: null,


            //Edit Case
            showEditUnitDrawer: false,
            unsavedEditUnitValues: false,
            LotCode: null,
            SerialNumber: null,
            CustomerRef: null,
            SKU: null,
            Project: null,
            Notes: null,
        };


        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        // Warehouse Location Details
        this.onClickWarehouseLocationDetails = this.onClickWarehouseLocationDetails.bind(this);

        this.onClickLoadDetails = this.onClickLoadDetails.bind(this);



        // //Edit Po Drawer
        // this.closeEditSkuDrawer = this.closeEditSkuDrawer.bind(this);
        // this.onClickConfirmEditSku = this.onClickConfirmEditSku.bind(this);
        // this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)

        // this.onChangeCustomerRef = this.onChangeCustomerRef.bind(this)
        // this.onChangeSKU = this.onChangeSKU.bind(this)
        // this.onChangeProject = this.onChangeProject.bind(this)
        // this.onChangeNotes = this.onChangeNotes.bind(this)


        this.onClickPalletDetails = this.onClickPalletDetails.bind(this);
        this.onClickCaseDetails = this.onClickCaseDetails.bind(this);
        this.onClickUnitDetails = this.onClickUnitDetails.bind(this);



        //Edit Unit Drawer
        this.closeEditUnitDrawer = this.closeEditUnitDrawer.bind(this);
        this.onClickConfirmEditUnit = this.onClickConfirmEditUnit.bind(this);
        this.setStateCloseEditUnitDrawer = this.setStateCloseEditUnitDrawer.bind(this)
        this.onChangeEditUnit = this.onChangeEditUnit.bind(this)

    }




    componentDidMount() {
        this.loadSortFromStorage()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


        if (prevProps.InventoryLotSkuData !== this.props.InventoryLotSkuData) {
            try {
                var skuWarehouseID = this.props.InventoryLotSkuData[0].WarehouseID
                var skuCustomerID = this.props.InventoryLotSkuData[0].CustomerID

                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, skuWarehouseID, skuCustomerID)
            }
            catch (e) { }
        }


        if (prevProps.InventorySkuPostSuccessful !== this.props.InventorySkuPostSuccessful && this.props.InventorySkuPostSuccessful) {
            this.setStateCloseAddNewDrawer()
        }


        if (prevProps.InventoryLotUnitCaseSkuRefEditPostSuccessful !== this.props.InventoryLotUnitCaseSkuRefEditPostSuccessful && this.props.InventoryLotUnitCaseSkuRefEditPostSuccessful) {
            this.setStateCloseEditUnitDrawer()
        }
    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    //Change State
    onChangeState(e, statename) {
        this.setState({
            [statename]: e
        });
    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }




    //Table buttons
    onClickWarehouseLocationDetails(e, rowData, history) {
        try {
            var paramID = rowData["WarehouseLocationID"]
            var paramWarehouseID = rowData["WarehouseID"]
            history.push({
                pathname: 'warehouselocationdetails',
                search: 'warehouselocationid=' + paramID + '&' +
                    'warehouseid=' + paramWarehouseID
            });
        }
        catch (e) {
        }
    }



    //On Click Details
    onClickLoadDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }
    }



    // //Edit Po Drawer
    // onClickEditSku() {
    //     this.openEditSku()
    //     this.setState({
    //         // Po: this.props.InventoryLotSkuData[0].PO,

    //         CustomerRef: this.props.InventoryLotSkuData[0].CustomerRefID,
    //         SKU: this.props.InventoryLotSkuData[0].SKU,
    //         Project: this.props.InventoryLotSkuData[0].ProjectID,
    //         // ExpectedCases: this.props.InventoryLotSkuData[0].CaseCount,
    //         // ExpectedPallets: this.props.InventoryLotSkuData[0].PalletCount,
    //         Notes: this.props.InventoryLotSkuData[0].Notes
    //     });
    // }
    // openEditSku() {
    //     this.setState({
    //         // placement,
    //         showEditSkuDetailsDrawer: true,
    //         // unsavedEditSkuValues: true,
    //     });
    // }

    // onChangeNotes = (e) => {
    //     this.setState({
    //         unsavedEditSkuValues: true,
    //         Notes: e
    //     });
    // }

    // onChangeSKU = (e) => {
    //     this.setState({
    //         unsavedEditSkuValues: true,
    //         SKU: e
    //     });
    // }


    // onChangeCustomerRef = (e, value, item) => {
    //     this.setState({
    //         unsavedEditSkuValues: true,
    //         CustomerRef: e
    //     });
    // }

    // onChangeProject = (e, value, item) => {
    //     this.setState({
    //         unsavedEditSkuValues: true,
    //         Project: e
    //     });
    // }

    // onClickConfirmEditSku(e) {

    //     this.props.inventorySkuPost(
    //         this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLotSkuData[0].SkuID, formatStringForAPI(this.state.SKU), null, null, null, null, null, formatStringForAPI(this.state.CustomerRef), null, formatStringForAPI(this.state.Project), formatAllTextNumbersSymbolsForAPI(this.state.Notes)
    //     )


    // }
    // closeEditSkuDrawer() {
    //     if (this.state.unsavedEditSkuValues) {
    //         if (window.confirm('Leave without saving changes?')) {
    //             this.setStateCloseAddNewDrawer()
    //             window.location.reload();
    //         }
    //     }
    //     else {
    //         this.setStateCloseAddNewDrawer()
    //     }
    // }
    // setStateCloseAddNewDrawer() {
    //     this.setState({
    //         showEditSkuDetailsDrawer: false,
    //         unsavedEditSkuValues: false,

    //         Po: null,
    //         CustomerRef: null,
    //         Project: null,
    //         ExpectedCases: null,
    //         ExpectedPallets: null,
    //         Notes: null,
    //     });
    // }






















    getTableData = (data) => {
        const { sortTableColumn, sortTableType } = this.state;
        const sortedData = [...data]; // Copy the data

        if (sortTableColumn && sortTableType) {
            sortedData.sort((a, b) => {
                let x = a[sortTableColumn];
                let y = b[sortTableColumn];
                if (typeof x === 'object') {
                    x = x.location.toLowerCase();
                    y = y.location.toLowerCase();
                } else if (typeof x === 'string') {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }
                if (sortTableType === 'asc') {
                    return x > y ? 1 : -1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }

        return sortedData;


    }



    handleTableSortColumn(sortTableColumn, sortTableType) {
        this.setState({ loading: true });

        // If the same column was sorted, toggle the sort type
        if (this.state.sortTableColumn === sortTableColumn) {
            sortTableType = this.state.sortTableType === 'asc' ? 'desc' : 'asc';
        }

        // this.saveSortTosessionStorage(sortTableColumn, sortTableType)

        setTimeout(() => {
            this.setState({
                sortTableColumn,
                sortTableType,
                loading: false
            });
        }, 100);
    }







    // onClickPalletDetails(pid) {
    //     var path = 'palletdetails';
    //     var pathid = 'palletid';
    //     var paramID = pid;
    //     this.props.history.push({
    //         pathname: path,
    //         search: pathid + '=\'' + paramID + '\''
    //     });
    // }

    // onClickCaseDetails(cid) {
    //     var path = 'casedetails';
    //     var pathid = 'caseid';
    //     var paramID = cid;
    //     this.props.history.push({
    //         pathname: path,
    //         search: pathid + '=\'' + paramID + '\''
    //     });
    // }





    //save customer selection
    saveCustomerSelectionTosessionStorage(customerToSave) {
        try {
            sessionStorage.setItem('currentCustomer', customerToSave)
        }
        catch (e) {

        }
    }

    //Table on click details
    // onClickDetails(e, rowData, history) {
    //     try {
    //         var paramID = rowData[tableIdCol]
    //         history.push({
    //             pathname: 'skudetails',
    //             search: 'skuid=\'' + paramID + '\''
    //         });
    //     }
    //     catch (e) {
    //     }
    // }

    // onClickPalletDetails(e, rowData, history) {
    //     var newCustID = rowData.CustomerID
    //     this.saveCustomerSelectionTosessionStorage(newCustID)

    //     var selectedWarehouseID = rowData.WarehouseLocationID
    //     var selectedOversize = rowData.Oversize
    //     var selectedStatus = rowData.PalletStatusID

    //     let searchParams = new URLSearchParams(this.props.location.search);
    //     searchParams.set('warehouselocationid', selectedWarehouseID);
    //     searchParams.set('palletoverize', selectedOversize);
    //     searchParams.set('reportnav', 'pallet');
    //     searchParams.set('statusid', selectedStatus);
    //     searchParams.set('skuid', this.props.InventoryLotSkuData[0].SkuID);

    //     let newSearchString = searchParams.toString();

    //     try {
    //         history.push({
    //             pathname: 'inventorypcs',
    //             search: newSearchString
    //         });

    //         window.location.reload();
    //     }
    //     catch (e) {
    //     }
    // }

    // onClickCaseDetails(e, rowData, history) {

    //     var newCustID = rowData.CustomerID
    //     this.saveCustomerSelectionTosessionStorage(newCustID)

    //     // var newSkuID = rowData.SkuID
    //     var newSku = rowData.CaseSKU
    //     var newLotCode = rowData.LotCode
    //     var newSerial = rowData.SerialNumber
    //     var selectedWarehouseID = rowData.WarehouseLocationID
    //     var selectedStatusID = rowData.CaseStatusID



    //     let searchParams = new URLSearchParams(this.props.location.search);
    //     searchParams.set('warehouselocationid', selectedWarehouseID);
    //     // searchParams.set('caseskuid', newSkuID);
    //     searchParams.set('casesku', newSku);
    //     searchParams.set('caselotcode', newLotCode);
    //     searchParams.set('caseserial', newSerial);
    //     searchParams.set('reportnav', 'case');
    //     searchParams.set('statusid', selectedStatusID);
    //     // searchParams.set('casesku', this.props.InventorySkuRefData[0].SKU.toString());
    //     searchParams.set('skuid', this.props.InventoryLotSkuData[0].SkuID);

    //     let newSearchString = searchParams.toString();

    //     try {
    //         history.push({
    //             pathname: 'inventorypcs',
    //             search: newSearchString
    //         });

    //         window.location.reload();
    //     }
    //     catch (e) {
    //     }

    // }


    onClickUnitDetails(e, rowData, history) {

        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        var newSku = rowData.SKU
        var newLotCode = rowData.LotCode
        var newSerial = rowData.SerialNumber
        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedStatusID = rowData.SkuStatusID

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        searchParams.set('unitsku', newSku);
        searchParams.set('unitlotcode', newLotCode);
        searchParams.set('unitserial', newSerial);
        searchParams.set('reportnav', 'sku');
        searchParams.set('statusid', selectedStatusID);
        // searchParams.set('unitsku', this.props.InventorySkuRefData[0].SKU.toString());
        searchParams.set('skuid', this.props.InventoryLotSkuData[0].SkuID);
        searchParams.set('filtershown', false);
        
        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }
    }

    onClickPalletDetails = (e) => {
        var paramID = this.props.InventoryPalletWarehouseData[0].PalletID
        this.props.history.push({
            pathname: 'palletdetails',
            search: 'palletid=\'' + paramID + '\''
        });
    }



    onClickCaseDetails = (e) => {
        var paramID = this.props.InventoryCaseWarehouseData[0].CaseID
        this.props.history.push({
            pathname: 'casedetails',
            search: 'caseid=\'' + paramID + '\''
        });
    }




    //Edit Unit Drawer
    onClickEditUnit() {
        this.setState({
            LotCode: this.props.InventorySkuWarehouseData[0].LotCode,
            SerialNumber: this.props.InventorySkuWarehouseData[0].SerialNumber,
            CustomerRef: this.props.InventorySkuWarehouseData[0].CustomerRefID,
            SKU: null,
            Project: this.props.InventorySkuWarehouseData[0].ProjectID,
            Notes: this.props.InventorySkuWarehouseData[0].Notes,
            showEditUnitDrawer: true,
        });

        // this.openEditSku()
    }
    openEditSku(placement) {
        this.setState({
            placement,
            showEditUnitDrawer: true,

        });
    }


    onChangeEditUnit = (event, label) => {
        this.setState({
            [label]: event,
            unsavedEditUnitValues: true
        });
    }

    onClickConfirmEditUnit(e) {
        this.props.inventoryLotUnitCaseSkuRefEditPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventorySkuWarehouseData[0].CaseSkuID, null, null, formatStringForAPI(this.state.LotCode), formatStringForAPI(this.state.SerialNumber), null, formatStringForAPI(this.state.CustomerRef), null, formatStringForAPI(this.state.Project), null, formatStringForAPI(this.state.Notes))
    }
    closeEditUnitDrawer() {
        if (this.state.unsavedEditUnitValues) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseEditUnitDrawer()
                // window.location.reload();
            }
        }
        else {
            this.setStateCloseEditUnitDrawer()
        }
    }
    setStateCloseEditUnitDrawer() {
        this.setState({
            showEditUnitDrawer: false,
            unsavedEditUnitValues: false,

            LotCode: null,
            SerialNumber: null,
            CustomerRef: null,
            SKU: null,
            Project: null,
            Notes: null,
        });
    }




    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;
        const { sortTableColumn, sortTableType } = this.state;

        let skuDetailsDiv;
        let pageHeader;
        if (this.props.isInventorySkuWarehouseGroupedLoading) {
            pageHeader = <div>Inventory Management - SKU</div>

            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (this.props.isInventoryLotSkuLoading) {
            pageHeader = <div>Inventory Management - SKU</div>

            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }
        else if (this.props.isInventorySkuWarehouseLoading) {
            pageHeader = <div>Inventory Management - SKU</div>

            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }
        else if (typeof (this.props.InventorySkuWarehouseGroupedData[0]) !== 'undefined' && this.props.InventorySkuWarehouseGroupedData != null && typeof (this.props.InventoryLotSkuData[0]) !== 'undefined' && this.props.InventoryLotSkuData != null
            && typeof (this.props.InventorySkuWarehouseData[0]) !== 'undefined' && this.props.InventorySkuWarehouseData != null) {
            try {
                var skuName = this.props.InventorySkuWarehouseData[0].SKU.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - SKU: ' + skuName} </div>
            }
            catch (e) {
                pageHeader = <div>Inventory Management - SKU</div>
            }

            skuDetailsDiv =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div style={{ float: 'right' }}>
                                    <Button style={{ float: 'right' }} onClick={(e) => { this.onClickEditUnit(e) }} >Edit</Button>
                                </div>
                            }>

                                <Col md={12} sm={12}>
                                    Warehouse Location Name: <strong>{this.props.InventorySkuWarehouseData[0].WarehouseLocationName}</strong>
                                    <br />
                                    Warehouse: <strong>{this.props.InventorySkuWarehouseData[0].WarehouseName}</strong>
                                    <br />
                                    Customer: <strong>{this.props.InventorySkuWarehouseData[0].CustomerName}</strong>
                                    <br />
                                    Recovery Date: <strong>{this.props.InventorySkuWarehouseData[0].CreateDT}</strong>
                                    <br />
                                    Status: <strong>{this.props.InventorySkuWarehouseData[0].SkuStatusName}</strong>

                                    <br />
                                    <br />
                                </Col>
                                <Col md={12} sm={12}>
                                    Customer Ref: <strong>{this.props.InventorySkuWarehouseData[0].CustomerRef}</strong>
                                    <br />
                                    Project: <strong>{this.props.InventorySkuWarehouseData[0].Project}</strong>
                                    <br />
                                    Lot Code: <strong>{this.props.InventorySkuWarehouseData[0].LotCode}</strong>
                                    <br />
                                    Serial Number: <strong>{this.props.InventorySkuWarehouseData[0].SerialNumber}</strong>
                                    <br />
                                    Notes: <strong>{this.props.InventorySkuWarehouseData[0].UnitNotes}</strong>
                                    <br />
                                    <br />
                                </Col>


                            </Panel>
                        </Col>
                    </Row>
                    <Row>

                        {/* <Col md={6} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsExpected}</strong>
                                <br />
                                Cases Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesExpected}</strong>
                                <br />
                                Pallets Expected: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsExpected}</strong>
                                <br />
                            </Panel>
                        </Col> */}
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsRecovered}</strong>
                                <br />
                                Cases Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesReceived}</strong>
                                <br />
                                {/* Pallets Received: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsReceived}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsInWhse}</strong>
                                <br />
                                Cases In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesInWhse}</strong>
                                <br />
                                {/* Pallets In Whse: <strong>{this.props.InventorySkuWarehouseGroupedData[0].Pallets}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>
                        <Col md={8} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].UnitsLoaded}</strong>
                                <br />
                                Cases Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].CasesLoaded}</strong>
                                <br />
                                {/* Pallets Shipped: <strong>{this.props.InventorySkuWarehouseGroupedData[0].PalletsLoaded}</strong> */}
                                {/* <br /> */}
                            </Panel>
                        </Col>

                    </Row>
                </div>



        }
        else {
            pageHeader = <div>Inventory Management - SKU</div>
            skuDetailsDiv = <div></div>
        }



        let loadDiv;
        let rTable;
        let loadsDataDiv;
        if (this.props.isInventoryLoadLotLoading) {
            rTable = <div></div>
            loadsDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadLotData) !== 'undefined' && this.props.InventoryLoadLotData != null) {

            if (this.props.InventoryLoadLotData.length === 0) {
                rTable = <div></div>
                loadsDataDiv = <div></div>
            }
            else {

                // let rTable;
                rTable = RTableGeneral(
                    'LoadID',     //   keyColumn,
                    this.props.InventoryLoadLotData,     //   tableData,
                    InventoryLoadSkuDetailsDataElements,       //   DataElements,
                    this.props.isInventoryLoadLotLoading,        //   loading,

                    this.props.isMobile,        //   isMobile,
                    this.props.history,     //   history,

                    sortColumn,     //   sortColumn,
                    sortType,       //   sortType,
                    this.handleSortColumn,      //   handleSortColumn,

                    false, // checkColEntry,
                    null,       //   checkedKeys,
                    null,       //   handleCheckAll,
                    null,       //   handleCheck,

                    false,      //   boolSelectButton,
                    null,       //   onClickSelect,
                    null,       //   selectButtonText,
                    null,       //   selectHighlightRowKey,

                    true,       //   link1bool,
                    'Details',     //   link1text,
                    this.onClickLoadDetails,     //   link1onClick,

                    false,       //   link2bool,
                    null,       //   link2text,
                    null  //   link2onClick
                )

                loadDiv = <div>


                    {(this.props.isMobile == true) ? <div></div> :
                        <div>
                            <Row>
                                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>
                                    <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                        {
                                            InventoryLoadSkuDetailsDataElements.map(
                                                ({ dBKey, displayName }, idx) => {
                                                    return (
                                                        <ExcelColumn label={displayName} value={dBKey} />
                                                    );
                                                }
                                            )
                                        }

                                    </ExcelSheet>
                                </ExcelFile>
                            </Row>
                        </div>
                    }

                </div>


loadsDataDiv = <Row >
<Col md={24} sm={24}>
    <Panel bordered header="Loads" style={{ marginBottom: 10 }}>
        {loadDiv}
        {rTable}
    </Panel>
</Col>
</Row>
            }
        }
        else {
            rTable = <div></div>
            loadsDataDiv = <div></div>
        }



        // let palletsDiv;
        // let casesDiv;
        // if (this.props.isInventorySkuWarehouseLoading) {
        //     shipmentSkuTable = <div></div>
        //     tableSkuDataDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin" 
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventorySkuWarehouseData) !== 'undefined' && this.props.InventorySkuWarehouseData != null) {


        //     const palletBarcodeObject = this.props.InventorySkuWarehouseData.reduce((acc, item) => {
        //         if (!acc[item.PalletBarcode]) {
        //             acc[item.PalletBarcode] = {
        //                 location: item.WarehouseLocationName,
        //                 palletId: item.PalletID
        //             };
        //         }
        //         return acc;
        //     }, {});

        //     const caseBarcodeObject = this.props.InventorySkuWarehouseData.reduce((acc, item) => {
        //         if (!acc[item.CaseBarcode]) {
        //             acc[item.CaseBarcode] = {
        //                 location: item.WarehouseLocationName,
        //                 caseId: item.CaseID
        //             };
        //         }
        //         return acc;
        //     }, {});

        //     const palletBarcodeData = this.getTableData(Object.entries(palletBarcodeObject).map(([pbarcode, { location: plocation, palletId }], index) => ({
        //         id: index,
        //         pbarcode,
        //         plocation,
        //         palletId
        //     })));

        //     const caseBarcodeData = this.getTableData(Object.entries(caseBarcodeObject).map(([cbarcode, { location: clocation, caseId }], index) => ({
        //         id: index,
        //         cbarcode,
        //         clocation,
        //         caseId
        //     })));


        //     palletsDiv =
        //         <Table
        //             data={palletBarcodeData}
        //             autoHeight
        //             sortTableColumn={sortTableColumn}
        //             sortTableType={sortTableType}
        //             onSortColumn={this.handleTableSortColumn}
        //         >
        //             <Column width={250} sortable>
        //                 <HeaderCell>Barcode</HeaderCell>
        //                 <Cell dataKey="pbarcode" />
        //             </Column>

        //             <Column width={200} sortable>
        //                 <HeaderCell>Warehouse Location</HeaderCell>
        //                 <Cell dataKey="plocation" />
        //             </Column>

        //             <Column width={200}>
        //                 <HeaderCell></HeaderCell>
        //                 <Cell>
        //                     {rowData => {
        //                         return (
        //                             <a
        //                                 href="#"
        //                                 onClick={(e) => {
        //                                     e.preventDefault();
        //                                     this.onClickPalletDetails(rowData.palletId);
        //                                 }}
        //                             >
        //                                 Details
        //                             </a>
        //                         );
        //                     }}
        //                 </Cell>
        //             </Column>
        //         </Table>

        //     casesDiv =
        //         <Table
        //             data={caseBarcodeData}
        //             autoHeight
        //             sortTableColumn={sortTableColumn}
        //             sortTableType={sortTableType}
        //             onSortColumn={this.handleTableSortColumn}
        //         >
        //             <Column width={250} sortable>
        //                 <HeaderCell>Barcode</HeaderCell>
        //                 <Cell dataKey="cbarcode" />
        //             </Column>

        //             <Column width={200} sortable>
        //                 <HeaderCell>Warehouse Location</HeaderCell>
        //                 <Cell dataKey="clocation" />
        //             </Column>

        //             <Column width={200}>
        //                 <HeaderCell></HeaderCell>
        //                 <Cell>
        //                     {rowData => {
        //                         return (
        //                             <a
        //                                 href="#"
        //                                 onClick={(e) => {
        //                                     e.preventDefault();
        //                                     this.onClickCaseDetails(rowData.caseId);
        //                                 }}
        //                             >
        //                                 Details
        //                             </a>
        //                         );
        //                     }}
        //                 </Cell>
        //             </Column>
        //         </Table>



        // }
        // else {

        // }




        let shipmentPalletTable;
        let tablePalletDataDiv;
        let palletDiv;
        if (this.props.isInventoryPalletWarehouseLoading) {
            palletDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryPalletWarehouseData) !== 'undefined' && this.props.InventoryPalletWarehouseData != null) {

            shipmentPalletTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryPalletWarehouseData,     //   tableData,
                InventoryPalletWarehouseDataElements,       //   DataElements,
                this.props.isInventoryPalletWarehouseLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Pallets',     //   link1text,
                this.onClickPalletDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            tablePalletDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Pallets_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryPalletWarehouseData} name="Pallets">
                                    {
                                        InventoryPalletWarehouseDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryPalletWarehouseData.length > 0) {
                palletDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Pallets" style={{ marginBottom: 10 }}>
                            {tablePalletDataDiv}
                            {shipmentPalletTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                palletDiv = <div></div>
            }

        }
        else {
            palletDiv = <div></div>
        }






        let palletDetails;

        if (this.props.isInventoryPalletWarehouseLoading) {
            palletDetails =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>

        }

        else if (typeof (this.props.InventoryPalletWarehouseData[0]) !== 'undefined' && this.props.InventoryPalletWarehouseData != null) {
            let pageHeader
            let palletLabel;
            let palletContents;
            try {
                var skuName = this.props.InventoryPalletWarehouseData[0].PalletBarcode.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Pallet: ' + skuName} </div>
            }
            catch (e) {

            }
            palletDetails = <div>

                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} header={
                            <div>
                                {pageHeader}
                                {/* <div style={{ float: 'right' }}> */}
                                <Button style={{ float: 'right' }} onClick={(e) => { this.onClickPalletDetails(e) }} >Pallet Details</Button>
                                {/* </div> */}
                            </div>
                        }>
                            Warehouse Location: <strong>{this.props.InventoryPalletWarehouseData[0].WarehouseLocationName}</strong>
                            <br />
                            Warehouse: <strong>{this.props.InventoryPalletWarehouseData[0].WarehouseName}</strong>
                            <br />
                            Customer: <strong>{this.props.InventoryPalletWarehouseData[0].CustomerName}</strong>
                            <br />
                            Pallet Barcode: <strong>{this.props.InventoryPalletWarehouseData[0].PalletBarcode}</strong>
                            <br />
                            Pallet Status: <strong>{this.props.InventoryPalletWarehouseData[0].PalletStatusName}</strong>
                            <br />
                        </Panel>
                    </Col>
                </Row>




            </div>
        }
        else {
            palletDetails = <div></div>
        }





        let caseDetails;
        if (this.props.isInventoryCaseWarehouseLoading) {
            caseDetails = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }

        else if (typeof (this.props.InventoryCaseWarehouseData[0]) !== 'undefined' && this.props.InventoryCaseWarehouseData != null && typeof (this.props.InventoryCaseWarehouseData[0]) !== 'undefined' && this.props.InventoryCaseWarehouseData != null) {
            try {
                var skuName = this.props.InventoryCaseWarehouseData[0].CaseBarcode.toString()
            }
            catch (e) {

            }

            if (this.props.isInventorySkuWarehouseLoading) {
                caseDetails = <div></div>
                var caseHeader = <div> </div>

            }
            else {
                var caseName = this.props.InventoryCaseWarehouseData[0].CaseBarcode.toString()
                var caseHeader = <div style={{ marginBottom: '15px' }}>  {'Case: ' + caseName} </div>
            }


            caseDetails =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} header={
                                <div>
                                    {caseHeader}
                                    {/* <div style={{ float: 'right' }}> */}
                                    <Button style={{ float: 'right' }} onClick={(e) => { this.onClickCaseDetails(e) }} >Case Details</Button>
                                    {/* </div> */}
                                </div>
                            }>
                                Warehouse Location: <strong>{this.props.InventoryCaseWarehouseData[0].WarehouseLocationName}</strong>
                                <br />
                                Warehouse: <strong>{this.props.InventoryCaseWarehouseData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{this.props.InventoryCaseWarehouseData[0].CustomerName}</strong>
                                <br />
                                Case Barcode: <strong>{this.props.InventoryCaseWarehouseData[0].CaseBarcode}</strong>
                                <br />
                                Case SKU: <strong>{this.props.InventoryCaseWarehouseData[0].CaseSKU}</strong>
                                <br />
                                Case Status: <strong>{this.props.InventoryCaseWarehouseData[0].CaseStatusName}</strong>
                                <br />
                                Lot Code: <strong>{this.props.InventoryCaseWarehouseData[0].LotCode}</strong>
                                <br />
                                Serial Number: <strong>{this.props.InventoryCaseWarehouseData[0].SerialNumber}</strong>
                                <br />

                                Pallet Barcode: <strong>{this.props.InventoryCaseWarehouseData[0].PalletBarcode}</strong>
                                <br />

                            </Panel>
                        </Col>
                    </Row>

                </div>



        }
        else {
            caseDetails = <div></div>
        }




        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>
                    {skuDetailsDiv}
                </PanelGroup>

                {palletDetails}
                {caseDetails}

                {loadsDataDiv}



                <CaseSKUsHistoryDiv
                    isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                    InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}
                />

                <LoadSKUsHistoryDiv
                    isInventoryLoadSKUsHistoryLoading={this.props.isInventoryLoadSKUsHistoryLoading}
                    InventoryLoadSKUsHistoryData={this.props.InventoryLoadSKUsHistoryData}
                />

                <EditSkuDetailsDrawer {...this.props} state={this.state} onChangeProject={this.onChangeProject} onChangeNotes={this.onChangeNotes} onChangeCustomerRef={this.onChangeCustomerRef} onChangeSKU={this.onChangeSKU} confirm={this.onClickConfirmEditSku} close={this.closeEditSkuDrawer} unsavedChanges={this.state.unsavedEditSkuValues} />



                <EditUnitDrawer {...this.props} state={this.state} show={this.state.showEditUnitDrawer} onChangeEditUnit={this.onChangeEditUnit} confirm={this.onClickConfirmEditUnit} close={this.closeEditUnitDrawer} />

            </div>
        );

    }
}

export default withRouter(UnitDetail);


