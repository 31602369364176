// HistoryTypeModal.js
import React from 'react';
import { Modal, Button, Loader, Row, FormGroup, InputGroup, InputPicker, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

export default function HistoryTypeModal({ open, handleClose, data, loading, handleSelect }) {
    const [selectedValue, setSelectedValue] = React.useState('');

    const handleChange = (e) => {
        setSelectedValue(e);
    };

    const handleSubmit = () => {
        handleSelect(selectedValue);
        handleClose();
    };

    const handleCloseAndReset = () => {
        setSelectedValue('');
        handleClose();
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
                <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, receiving...</p>
            </div>
        );
    }

    return (
        <div className="modal-container">
            <Modal backdrop={'static'} keyboard={false} show={open} onHide={handleCloseAndReset} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>Adjust Inventory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <InputPicker preventOverflow
                            placeholder={'Reasons...'}
                            value={selectedValue}
                            onChange={(e) => { handleChange(e) }}
                            data={data}
                            cleanable={true}
                            block
                        />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <DebouncedButton onClick={handleSubmit} buttonText="Submit" />
                    <Button onClick={handleCloseAndReset} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}