import * as types from '../constants/visibilityActionTypes';

const initialState = {

    isVisibilityDashboardStatsLoading: false,
    VisibilityDashboardStatsData: [],

    isVisibilityDashboardPackageListLoading: false,
    VisibilityDashboardPackageListData: [],

    isVisibilityPackageLoading: false,
    VisibilityPackageData: [],

    isVisibilityPackageUpsEventsLoading: false,
    VisibilityPackageUpsEventsData: [],

};

function checkStatusCode(payload) {
    var errorType = 'None'
    try {
        errorType = payload['errorType']
    }
    catch (e) {
    }
    if (typeof (errorType) !== 'undefined') {
        return false
    }
    else {
        var res = payload['statusCode']
        try {
            if (res === "200" || res == 200 || res == 600 || res === "600") { return true }
            else { return false }
        }
        catch (e) {
            return false
        }
    }
}

function actionOnError(proc) {

    return initialState


}


export const VisibilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state


        case types.ON_ERROR:
            var errorstate = actionOnError(state)
            return errorstate;



        /* ################################### VISIBILITY ACTIONS ################################### */



        case types.VISIBILITY_DASHBOARD_STATS_GET:
            return Object.assign({}, state, {
                isVisibilityDashboardStatsLoading: true,
            });
        case types.VISIBILITY_DASHBOARD_STATS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isVisibilityDashboardStatsLoading: false,
                VisibilityDashboardStatsData: newData.data
            });

        case types.VISIBILITY_DASHBOARD_PACKAGE_LIST_GET:
            return Object.assign({}, state, {
                isVisibilityDashboardPackageListLoading: true,
            });
        case types.VISIBILITY_DASHBOARD_PACKAGE_LIST_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isVisibilityDashboardPackageListLoading: false,
                VisibilityDashboardPackageListData: newData.data
            });

        case types.VISIBILITY_PACKAGE_GET:
            return Object.assign({}, state, {
                isVisibilityPackageLoading: true,
            });
        case types.VISIBILITY_PACKAGE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isVisibilityPackageLoading: false,
                VisibilityPackageData: newData.data
            });

        case types.VISIBILITY_PACKAGE_UPS_EVENTS_GET:
            return Object.assign({}, state, {
                isVisibilityPackageUpsEventsLoading: true,
            });
        case types.VISIBILITY_PACKAGE_UPS_EVENTS_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isVisibilityPackageUpsEventsLoading: false,
                VisibilityPackageUpsEventsData: newData.data
            });


        default:
            return state;
    }
};





export default VisibilityReducer;
