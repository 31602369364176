import { connect } from 'react-redux';
import React, { Component } from 'react';
import Reporting from './Reporting';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatStringForAPI, formatDateForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryLotReportingInboundLoading: store.inventoryReducer.isInventoryLotReportingInboundLoading,
    InventoryLotReportingInboundData: store.inventoryReducer.InventoryLotReportingInboundData,

    isInventoryLotReportingStorageLoading: store.inventoryReducer.isInventoryLotReportingStorageLoading,
    InventoryLotReportingStorageData: store.inventoryReducer.InventoryLotReportingStorageData,

});

const mapDispatchToProps = dispatch =>
({

    inventoryLotReportingInboundGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _StartRecoveryDt, _EndRecoveryDt) => {
        dispatch(inventoryActions.inventoryLotReportingInboundGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _StartRecoveryDt, _EndRecoveryDt))
    },

    inventoryLotReportingStorageGet: (_email, _authToken, _WarehouseID, _CustomerID, _StartDT, _EndDT) => {
        dispatch(inventoryActions.inventoryLotReportingStorageGet(_email, _authToken, _WarehouseID, _CustomerID, _StartDT, _EndDT))
    },


});

class ReportingContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }


    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramReportNav = queryStringParse(values.reportnav)
        }
        catch (e) {
            var paramReportNav = null
        }

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }

        try {
            var paramSkuID = queryStringParse(values.skuid)
        }
        catch (e) {
            var paramSkuID = null
        }
        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }

        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }

        try {
            var paramRecoveryDtStart = formatDateForAPI(values.recoverydtstart)
        }
        catch (e) {
            var paramRecoveryDtStart = null
        }
        try {
            var paramRecoveryDtEnd = formatDateForAPI(values.recoverydtend)
        }
        catch (e) {
            var paramRecoveryDtEnd = null
        }


        if (paramRecoveryDtEnd !== null && paramRecoveryDtStart === null) {
            window.alert('Please select start date.')
        }
        else {
            if (paramReportNav === 'inbound') {
                this.props.inventoryLotReportingInboundGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, paramContainer, paramStatus, paramSkuID, paramSku, paramRecoveryDtStart, paramRecoveryDtEnd)
            }
            else if (paramReportNav === 'storage') {
                this.props.inventoryLotReportingStorageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramRecoveryDtStart, paramRecoveryDtEnd)
            }
        }
    }



    render() {

        return (

            <Reporting
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}


                isInventoryLotReportingInboundLoading={this.props.isInventoryLotReportingInboundLoading}
                InventoryLotReportingInboundData={this.props.InventoryLotReportingInboundData}
            
                isInventoryLotReportingStorageLoading={this.props.isInventoryLotReportingStorageLoading}
                InventoryLotReportingStorageData={this.props.InventoryLotReportingStorageData}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportingContainer));



