import { connect } from 'react-redux';
import React, { Component } from 'react';
import Reporting from './Reporting'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI, formatDateForAPI } from '../../../resources/logic/functions';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isXdockTrinityDataLoading: store.vasReducer.isXdockTrinityDataLoading,
    XdockTrinityData: store.vasReducer.XdockTrinityData,


    // isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    // FileDownloadData: store.ctrlTwrReducer.FileDownloadData,



});

const mapDispatchToProps = dispatch =>
({
    // downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
    //     dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    // },


    xDockTrinityDataGet: (_email, _authToken, _CustomerID, _WarehouseID, _TrinityDataID, _Master, _HouseRef, _HAWBDestination, _ConsigneeName, _ETALastDischargeStartDT, _ETALastDischargeEndDT) => {
        dispatch(actions.xDockTrinityDataGet(_email, _authToken, _CustomerID, _WarehouseID, _TrinityDataID, _Master, _HouseRef, _HAWBDestination, _ConsigneeName, _ETALastDischargeStartDT, _ETALastDischargeEndDT))
    },

});

class ReportingContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
        // this.props.userWarehousesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        // if (prevProps.PostLoadSuccessful !== this.props.PostLoadSuccessful && this.props.PostLoadSuccessful) {
        //     setTimeout(() => {
        //         this.handleUrlParam()
        //     }, 1000);
        // }
        // else {
        // }


    }



    handleUrlParam = () => {

        const values = queryString.parse(this.props.location.search)

        try {
            var paramDateStart = formatDateForAPI(values.startdate)
        }
        catch (e) {
            var paramDateStart = null
        }
        try {
            var paramDateEnd = formatDateForAPI(values.enddate)
        }
        catch (e) {
            var paramDateEnd = null
        }
        
        this.props.xDockTrinityDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, this.props.warehouseSelection, null, null, null, null, null, paramDateStart, paramDateEnd)

    }



    render() {

        return (

            <Reporting
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isXdockTrinityDataLoading={this.props.isXdockTrinityDataLoading}
                XdockTrinityData={this.props.XdockTrinityData}


                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}


            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportingContainer));



