import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Tree } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventorySkuDataElements from '../../../dataComponentsInventory/InventorySkuDataElements';
import queryString from 'query-string';
import { formatBitToTrueFalse, formatStringForAPI, formatDateTimeForUrlSearch, formatDateForUrlSearch, convertNanToZero, isNumeric, formatNumberForAPI, formatTrueFalseToBit, formatAllTextNumbersSymbolsForAPI } from '../../../../resources/logic/functions';
import EditContainerDrawer from './EditContainerDrawer';
import ReceiveContainerDrawer from '../Inbound_Lot/ReceiveContainerDrawer';
import InventoryPalletWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryPalletWarehouseGroupedDataElements';
import InventoryCaseWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryCaseWarehouseGroupedDataElements';
import InventoryUnitWarehouseGroupedDataElements from '../../../dataComponentsInventory/InventoryUnitWarehouseGroupedDataElements';
import HistoryTypeModal from '../_GeneralLotComponents/HistoryTypeModal';


const tableIdCol = "SkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class ContainerDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Add New Container Values
            showEditContainerDrawer: false,
            unsavedNewContainerChanges: false,
            Container: null,
            Notes: null,
            LclFcl: null,
            ContainerSize: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null,
            Palletized: null,
            CustomerID: null,
            WarehouseID: null,


            //Receive Container Drawer
            ReceiveDict: {},
            LocationDict: {},
            unsavedReceiveChanges: false,
            showReceiveDrawer: false,
            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
            ReceiveDate: null,


            //History Type Modal
            historyTypeModalOpen: false,
            historyTypeID: null,
        };


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        // Warehouse Location Details
        this.onClickWarehouseLocationDetails = this.onClickWarehouseLocationDetails.bind(this);


        //Table buttons
        this.onClickDetails = this.onClickDetails.bind(this);
        this.onClickPalletDetails = this.onClickPalletDetails.bind(this);
        this.onClickCaseDetails = this.onClickCaseDetails.bind(this);
        this.onClickUnitDetails = this.onClickUnitDetails.bind(this);

        //Edit Container Drawer
        this.onClickEditContainer = this.onClickEditContainer.bind(this);
        this.onClickCloseContainer = this.onClickCloseContainer.bind(this);

        //Edit Container drawer
        this.preloadContainerValues = this.preloadContainerValues.bind(this);
        this.closeAddNewDrawer = this.closeAddNewDrawer.bind(this);
        this.onClickConfirmAddNewContainer = this.onClickConfirmAddNewContainer.bind(this);
        this.setStateCloseAddNewDrawer = this.setStateCloseAddNewDrawer.bind(this)

        //Receive drawer
        // this.onClickAdd = this.onClickAdd.bind(this);
        this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);

        this.onClickReceive = this.onClickReceive.bind(this);
        this.onChangePalletized = this.onChangePalletized.bind(this);
        this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
        this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);
        this.onChangeReceiveDate = this.onChangeReceiveDate.bind(this);


        this.onClickAddLocation = this.onClickAddLocation.bind(this);

        this.handleAddNewLocation = this.handleAddNewLocation.bind(this);
        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);

    }




    componentDidMount() {
        this.loadSortFromStorage()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.InventoryLotContainerPostSuccessful !== this.props.InventoryLotContainerPostSuccessful && this.props.InventoryLotContainerPostSuccessful) {
            this.setStateCloseAddNewDrawer()
        }

        if (prevProps.InventoryLotContainerData !== this.props.InventoryLotContainerData) {
            try {
                this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLotContainerData[0].WarehouseID, this.props.InventoryLotContainerData[0].CustomerID)
                this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLotContainerData[0].WarehouseID, this.props.InventoryLotContainerData[0].CustomerID)
                this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLotContainerData[0].WarehouseID, this.props.InventoryLotContainerData[0].CustomerID);

                if (this.props.InventoryLotContainerData.length > 0) {
                    this.setState({
                        receiveDataRowData: this.props.InventoryLotContainerData[0],
                        ReceiveDrawerContainerID: this.props.InventoryLotContainerData[0].ContainerID,
                        WarehouseID: this.props.InventoryLotContainerData[0].WarehouseID,
                        CustomerID: this.props.InventoryLotContainerData[0].CustomerID,
                        //Add Evolve Data Values
                        ReceiveDrawerContainer: this.props.InventoryLotContainerData[0].Container,
                        ReceiveDrawerNotes: this.props.InventoryLotContainerData[0].Notes
                    });
                }
            }
            catch (e) { }
        }

        if (prevProps.InventoryLotSkuData !== this.props.InventoryLotSkuData) {
            if (this.props.InventoryLotSkuData.length > 0) {
                this.preloadReceiveState()
            }
            else {
                window.alert('Nothing to receive.')
            }
        }


        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.setReceiveDrawerToClose()
        }


    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }

    //Table buttons
    onClickWarehouseLocationDetails(e, rowData, history) {
        try {
            var paramID = rowData["WarehouseLocationID"]
            var paramWarehouseID = rowData["WarehouseID"]
            history.push({
                pathname: 'warehouselocationdetails',
                search: 'warehouselocationid=' + paramID + '&' +
                    'warehouseid=' + paramWarehouseID
            });
        }
        catch (e) {
        }
    }


    //save customer selection
    saveCustomerSelectionTosessionStorage(customerToSave) {
        try {
            sessionStorage.setItem('currentCustomer', customerToSave)
        }
        catch (e) {

        }
    }

    //Table on click details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'skudetails',
                search: 'skuid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    onClickPalletDetails(e, rowData, history) {
        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedOversize = rowData.Oversize
        var selectedStatus = rowData.PalletStatusID

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        searchParams.set('palletoverize', selectedOversize);
        searchParams.set('reportnav', 'pallet');
        searchParams.set('statusid', selectedStatus);
        searchParams.set('container', this.props.InventoryLotContainerData[0].Container);
        searchParams.set('filtershown', false);
        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }
    }

    onClickCaseDetails(e, rowData, history) {

        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        // var newSkuID = rowData.SkuID
        var newSku = rowData.CaseSKU
        var newLotCode = rowData.LotCode
        var newSerial = rowData.SerialNumber
        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedStatusID = rowData.CaseStatusID



        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        // searchParams.set('caseskuid', newSkuID);
        searchParams.set('casesku', newSku);
        searchParams.set('caselotcode', newLotCode);
        searchParams.set('caseserial', newSerial);
        searchParams.set('reportnav', 'case');
        searchParams.set('statusid', selectedStatusID);
        searchParams.set('container', this.props.InventoryLotContainerData[0].Container);
        searchParams.set('filtershown', false);
        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }

    }

    onClickUnitDetails(e, rowData, history) {

        var newCustID = rowData.CustomerID
        this.saveCustomerSelectionTosessionStorage(newCustID)

        var newSku = rowData.SKU
        var newLotCode = rowData.LotCode
        var newSerial = rowData.SerialNumber
        var selectedWarehouseID = rowData.WarehouseLocationID
        var selectedStatusID = rowData.SkuStatusID

        let searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('warehouselocationid', selectedWarehouseID);
        searchParams.set('unitsku', newSku);
        searchParams.set('unitlotcode', newLotCode);
        searchParams.set('unitserial', newSerial);
        searchParams.set('reportnav', 'sku');
        searchParams.set('statusid', selectedStatusID);
        searchParams.set('container', this.props.InventoryLotContainerData[0].Container);
        searchParams.set('filtershown', false);
        let newSearchString = searchParams.toString();

        try {
            history.push({
                pathname: 'inventorypcs',
                search: newSearchString
            });

            // window.location.reload();
        }
        catch (e) {
        }
    }




    //Edit Container Container drawer

    onClickEditContainer() {
        this.preloadContainerValues()
        this.setState({
            showEditContainerDrawer: true,
            unsavedNewContainerChanges: false,
        });
    }

    onClickCloseContainer() {
        if (window.confirm('Set container to closed?')) {
            this.props.inventoryContainerStatusPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.InventoryLotContainerData[0].ContainerID, 4)
        }
    }

    preloadContainerValues() {

        var editContainer = this.props.InventoryLotContainerData[0].Container
        var editLclFcl = this.props.InventoryLotContainerData[0].LCL_FCL
        var editContainerSize = this.props.InventoryLotContainerData[0].ContainerSize
        var editDeliveryAppointment = this.props.InventoryLotContainerData[0].DeliveryAppointment
        var editETD = this.props.InventoryLotContainerData[0].ETD
        var editETA = this.props.InventoryLotContainerData[0].ETA
        var editNotes = this.props.InventoryLotContainerData[0].Notes
        var editPalletized = this.props.InventoryLotContainerData[0].Palletized
        var editCustomerID = this.props.InventoryLotContainerData[0].CustomerID
        var editWarehouseID = this.props.InventoryLotContainerData[0].WarehouseID

        this.setState({
            Container: editContainer,
            LclFcl: editLclFcl,
            ContainerSize: editContainerSize,
            Notes: editNotes,
            DeliveryAppointment: editDeliveryAppointment,
            ETD: editETD,
            ETA: editETA,
            Palletized: editPalletized,
            CustomerID: editCustomerID,
            WarehouseID: editWarehouseID
        });
        this.forceUpdate()

    }



    onChangeAddNewContainer = (event, field) => {
        if (field === 'LclFcl' && (event === 'LCL' || event == null)) {
            this.setState({
                ContainerSize: null,
            });
        }

        var dict = {};
        dict[field] = event;
        this.setState(
            dict
        );
        this.setState({
            unsavedNewContainerChanges: true,
        });
    };
    onClickConfirmAddNewContainer(e) {

        if (this.state.Container == null || this.state.Container == '') {
            window.alert('Please enter Container.')
        }
        else {
            // this.props.inventoryLotContainerPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.Container), null, formatNumberForAPI(this.state.TotWeight), formatNumberForAPI(this.state.TotChargeable), formatNumberForAPI(this.state.TotVolume), formatDateForUrlSearch(this.state.MinETADischarge), formatStringForAPI(this.state.CarrierName), formatStringForAPI(this.state.FlightVoyage), formatStringForAPI(this.state.AgentNote), formatNumberForAPI(this.state.CurrentMawbStatusID ))
            this.props.inventoryLotContainerPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                this.props.InventoryLotContainerData[0].ContainerID,
                formatStringForAPI(this.state.Container),
                formatStringForAPI(this.state.LclFcl),
                formatDateTimeForUrlSearch(this.state.DeliveryAppointment),
                formatDateForUrlSearch(this.state.ETD),
                formatDateForUrlSearch(this.state.ETA),
                null,
                formatAllTextNumbersSymbolsForAPI(this.state.Notes),
                this.state.WarehouseID,
                this.state.CustomerID,
                this.state.Palletized,
                this.state.ContainerSize
                // this.props.warehouseSelection,
                // this.props.customerSelection
            )
        }


    }
    closeAddNewDrawer() {
        if (this.state.unsavedNewContainerChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {
        this.setState({
            showEditContainerDrawer: false,
            unsavedNewContainerChanges: false,
            Container: null,
            LclFcl: null,
            ContainerSize: null,
            Notes: null,
            DeliveryAppointment: null,
            ETD: null,
            ETA: null,
            Palletized: null,
            CustomerID: null,
            WarehouseID: null
        });
    }





    //Receive Container drawer
    onClickReceive(e) {

        this.setState({
            // ReceiveDict: {},
            // LocationDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: this.props.InventoryLotContainerData[0],
            showReceiveDrawer: true,
            ReceiveDrawerContainerID: this.props.InventoryLotContainerData[0].ContainerID,
            WarehouseID: this.props.InventoryLotContainerData[0].WarehouseID,
            CustomerID: this.props.InventoryLotContainerData[0].CustomerID,
            ReceiveDate: null,

            //Add Evolve Data Values
            ReceiveDrawerContainer: this.props.InventoryLotContainerData[0].Container,
            ReceiveDrawerNotes: this.props.InventoryLotContainerData[0].Notes
        });
    }
    preloadReceiveState() {
        var receivedDict = {};
        for (let k in this.props.InventoryLotSkuData) {
            // receivedDict[this.props.InventoryLotSkuData[k].HouseID] = { 'Cases': this.props.InventoryLotSkuData[k].Cartons - this.props.InventoryLotSkuData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
            if (this.props.InventoryLotSkuData[k].SkuID === null) {
            }
            else {
                receivedDict[this.props.InventoryLotSkuData[k].SkuID] = { 'Units': null, 'Cases': null, 'Pallets': null, 'Notes': '', 'Customs': false, 'Oversize': false }
            }
        }

        var locationDict = {};
        for (let k in this.props.InventoryLotSkuData) {
            if (this.props.InventoryLotSkuData[k].SkuID === null) {
            }
            else {
                // locationDict[this.props.InventoryLotSkuData[k].SkuID] = [{'WarehouseLocationID': null, 'WarehouseID': null, 'Cases': null, 'Pallets': null,  }]
                locationDict[this.props.InventoryLotSkuData[k].SkuID] = [{ 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': null, 'Cases': null, 'Pallets': null, 'Oversize': false }]
            }
        }

        try {
            var receivedDictLength = Object.keys(receivedDict).length
        }
        catch (e) {
            var receivedDictLength = 0
        }
        if (typeof (receivedDict) === 'undefined' || receivedDict === null || receivedDictLength === 0) {
            window.alert('No SKUs exist for Receiving.')
        }
        else {
            this.setState({
                unsavedReceiveChanges: false,
                ReceiveDict: receivedDict,
                LocationDict: locationDict
            });
        }
    }
    onChangeReceiveDrawer = (event, item, label) => {
        var currentReceiveDict = this.state.ReceiveDict
        var skuID = item.SkuID;

        currentReceiveDict[skuID][label] = event

        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: currentReceiveDict
        });

    };
    onChangeReceiveDate(e) {
        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDate: e
        });

    }
    // check if same location but oversize
    onChangeReceiveDrawerLocations = (event, item, locationitem, label, existinglocationname) => {

        if (label === 'WarehouseLocationID') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event).length > 1) {
                window.alert('Duplicate location entry, please use existing.')
            }
            else if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event).length > 0) {
                var existingLoc = this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === event)
                if (existingLoc[0]['Oversize'] === true) {
                    var newOversize = false
                }
                else {
                    var newOversize = true
                }
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

                warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event
                warehouseLocationDictUpdated[item.SkuID][keyToUpdate]['Oversize'] = newOversize

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
            else {
                //save warehouse name:
                var warehouseLocationDictUpdated = this.state.LocationDict
                var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

                warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event

                this.setState({
                    LocationDict: warehouseLocationDictUpdated,
                    unsavedReceiveChanges: true
                });
            }
        }
        else if (label === 'Oversize' && (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === existinglocationname).length > 1)) {
            window.alert('Duplicate location and oversize entry, please use existing.')
        }
        else {
            var warehouseLocationDictUpdated = this.state.LocationDict
            var keyToUpdate = warehouseLocationDictUpdated[item.SkuID].findIndex(item => item.WarehouseLocationID === existinglocationname && item.Oversize === locationitem['Oversize'])

            warehouseLocationDictUpdated[item.SkuID][keyToUpdate][label] = event

            this.setState({
                LocationDict: warehouseLocationDictUpdated,
                unsavedReceiveChanges: true
            });

            //Use warehouseLocationDictUpdated to update totals of Pallets and cases
            var currentReceiveDict = this.state.ReceiveDict
            var skuID = item.SkuID;

            var sumNewValue = 0;

            for (var x in warehouseLocationDictUpdated[skuID]) {
                sumNewValue += convertNanToZero(parseInt(warehouseLocationDictUpdated[skuID][x][label]));
            }

            currentReceiveDict[skuID][label] = sumNewValue

            this.setState({
                ReceiveDict: currentReceiveDict
            });
        }
        this.forceUpdate()
    };



    handleAddNewLocation = (newLocationDict) => {
        this.setState({
            unsavedReceiveChanges: true,
            LocationDict: newLocationDict
        }, () => {
            // Do an API call with this.state.searchTerm

        });
    }
    onClickAddLocation = (event, item) => {
        //Check if any Warehouse names are null
        if (this.state.LocationDict[item.SkuID].filter(item => item.WarehouseLocationID === null || item.WarehouseLocationID === '').length > 0) {
            window.alert('Please enter all location values first.')
        }
        else {
            //save warehouse name
            var currentLocationDict = this.state.LocationDict

            currentLocationDict[item.SkuID].push({ 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': null, 'Cases': null, 'Pallets': null, 'Oversize': false })

            this.handleAddNewLocation(currentLocationDict)

            this.forceUpdate()
        }
    }

    onClickRemoveFromAdd = (e, item, locationitem, warehouseLocationID) => {
        var currentList = JSON.parse(JSON.stringify(this.state.LocationDict));
        var skuID = item.SkuID
        if (currentList[skuID].length === 1) {
            currentList[skuID][0] = { 'WarehouseLocationID': null, 'WarehouseID': null, 'Units': 0, 'Cases': 0, 'Pallets': 0, 'Oversize': false }
        }
        else {
            var remainingItems = currentList[skuID].filter((item) => { return !(item.WarehouseLocationID === warehouseLocationID && item.Oversize === locationitem['Oversize']) });
            currentList[skuID] = JSON.parse(JSON.stringify(remainingItems));
        }

        var currentBuildLoadDict = this.state.ReceiveDict
        try {
            currentBuildLoadDict[skuID]['Units'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Units), 0))
            currentBuildLoadDict[skuID]['Cases'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Cases), 0))
            currentBuildLoadDict[skuID]['Pallets'] = (currentList[skuID].reduce((a, v) => a = convertNanToZero(a) + isNumeric(v.Pallets), 0))
        }
        catch (e) {
            // currentBuildLoadDict[skuID]['Cases'] = replaceNegativeWithZero(currentBuildLoadDict['CasesReceived'] - currentBuildLoadDict['CasesLoaded'])
            // currentBuildLoadDict[skuID]['Pallets'] = replaceNegativeWithZero(currentBuildLoadDict['PalletsReceived'] - currentBuildLoadDict['PalletsLoaded'])
        }
        this.setState({
            // unsavedBuildLoadChanges: true,
            ReceiveDict: currentBuildLoadDict,
            LocationDict: currentList,
        });

        this.forceUpdate()
    }
    onChangePalletized(checked) {
        var containerDataUpdated = this.state.receiveDataRowData
        containerDataUpdated['Palletized'] = checked
        this.setState({
            receiveDataRowData: containerDataUpdated
        });
        this.forceUpdate()
    }
    onClickReceiveDrawerSave(e) {


        this.props.inventoryLotContainerPost(
            this.props.currentUserEmail,
            this.props.userSignInData.AuthToken,
            formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
            formatStringForAPI(this.state.receiveDataRowData.Container),
            formatStringForAPI(this.state.receiveDataRowData.LclFcl),
            formatDateTimeForUrlSearch(this.state.receiveDataRowData.DeliveryAppointment),
            formatDateForUrlSearch(this.state.receiveDataRowData.ETD),
            formatDateForUrlSearch(this.state.receiveDataRowData.ETA),
            1,
            formatAllTextNumbersSymbolsForAPI(this.state.receiveDataRowData.Notes),
            formatNumberForAPI(this.state.receiveDataRowData.WarehouseID),
            formatNumberForAPI(this.state.receiveDataRowData.CustomerID),
            formatTrueFalseToBit(this.state.receiveDataRowData.Palletized),
            this.receiveDataRowData.ContainerSize

        )

        // for (let k in this.state.ReceiveDict) {
        //     try { var unitsNum = this.state.ReceiveDict[k]['Units'] }
        //     catch (e) { var unitsNum = 0 }
        //     try { var recoveredNum = this.state.ReceiveDict[k]['Cases'] }
        //     catch (e) { var recoveredNum = 0 }
        //     try { var palletNum = this.state.ReceiveDict[k]['Pallets'] }
        //     catch (e) { var palletNum = 0 }

        //     var skuid = k


        //     if ((unitsNum === null || unitsNum == 0) && (recoveredNum === null || recoveredNum == 0) && (palletNum === null || palletNum == 0)) {
        //     }
        //     else {
        //         try {
        //         this.props.inventorySkuRecoveryPost(
        //             this.props.currentUserEmail,
        //             this.props.userSignInData.AuthToken,
        //             formatNumberForAPI(skuid),
        //             formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
        //             null,
        //             formatStringForAPI(this.state.ReceiveDict[k]['SkuDescription']),
        //             formatNumberForAPI(this.state.ReceiveDict[k]['Units']),
        //             formatNumberForAPI(this.state.ReceiveDict[k]['Cases']),
        //             formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
        //             formatTrueFalseToBit(this.state.ReceiveDict[k]['Oversize']),
        //             null, //_CustomsCleared
        //             formatDateTimeForUrlSearch(this.state.ReceiveDate)
        //         )
        //         }
        //         catch (e) { }
        //     }
        // }

        for (let l in this.state.LocationDict) {

            try { var skuID = l }
            catch (e) { var skuID = null }

            for (let i in this.state.LocationDict[l]) {

                try { var unitsLocNum = convertNanToZero(this.state.LocationDict[l][i]['Units']) }
                catch (e) { var unitsLocNum = 0 }

                try { var casesLocNum = convertNanToZero(this.state.LocationDict[l][i]['Cases']) }
                catch (e) { var casesLocNum = 0 }

                try { var palletsLocNum = convertNanToZero(this.state.LocationDict[l][i]['Pallets']) }
                catch (e) { var palletsLocNum = 0 }

                try { var WarehouseLocationIDNum = convertNanToZero(this.state.LocationDict[l][i]['WarehouseLocationID']) }
                catch (e) { var WarehouseLocationIDNum = 0 }

                try { var locOversize = formatTrueFalseToBit(this.state.LocationDict[l][i]['Oversize']) }
                catch (e) { var locOversize = null }

                if ((unitsLocNum === null || unitsLocNum == 0) && (casesLocNum === null || casesLocNum == 0) && (palletsLocNum === null || palletsLocNum == 0)) {
                }
                else {
                    var warehouseID = this.props.InventoryLotContainerData[0].WarehouseID

                    // try {
                    this.props.inventorySkuWarehouseLocationPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        skuID,
                        null,
                        WarehouseLocationIDNum,
                        unitsLocNum,
                        casesLocNum,
                        palletsLocNum,
                        locOversize,
                        null,
                        warehouseID,
                        this.state.CustomerID,
                        1,
                        formatNumberForAPI(this.state.receiveDataRowData.ContainerID),
                        formatDateTimeForUrlSearch(this.state.ReceiveDate),
                        formatAllTextNumbersSymbolsForAPI(this.state.ReceiveDict[skuID]['SkuDescription'])

                    )
                    // }
                    // catch (e) { }
                }

            }
        }



    }

    closeReceiveDrawer() {
        if (this.state.unsavedReceiveChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setReceiveDrawerToClose()
            }
        }
        else {
            this.setReceiveDrawerToClose()
        }
    }

    setReceiveDrawerToClose() {
        this.setState({
            // ReceiveDict: {},
            // LocationDict: {},
            unsavedReceiveChanges: false,

            showReceiveDrawer: false,

            // ReceiveDrawerContainerID: null,
            // ReceiveDrawerContainer: null,
            // ReceiveDrawerNo: null,
            // ReceiveDrawerPMC: null,
            // ReceiveDrawerPickupBy: null,
            // ReceiveDrawerTrips: null,
            // ReceiveDrawerArrivalTime: null,
            // ReceiveDrawerLFD: null,
            // ReceiveDrawerArrivalAtWarehouse: null,
            // ReceiveDrawerDeliveryTime: null,
            // ReceiveDrawerNotes: null,

            // WarehouseID: null,
            // CustomerID: null,
        });
    }



    //History Type Modal
    handleModalOpen = () => {

        this.setState({ historyTypeModalOpen: true });

    };

    handleModalClose = () => {
        this.setState({ historyTypeModalOpen: false });
    };

    handleModalSelect = (value) => {
        if (window.confirm('Are you sure you want to delete this container?  This will also remove contents from inventory.')) {
            this.props.inventoryLotDeleteContainerPalletCaseUnitPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, value, formatStringForAPI(this.props.InventoryLotContainerData[0].ContainerID), null, null, null, null, null, null)
        }
    };

    onClickInventoryDelete = (e) => {
        this.handleModalOpen();
    };


    render() {

        const { sortColumn, sortType } = this.state;


        if (this.props.isInventoryPostLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Loading...</p>
                </div>
            );
        }

        let skuDetailsDiv;
        let pageHeader;
        if (this.props.isInventoryLotContainerLoading) {
            pageHeader = <div>Inventory Management - Container</div>

            skuDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }
        else if (typeof (this.props.InventoryLotContainerData[0]) !== 'undefined' && this.props.InventoryLotContainerData != null) {
            try {
                var containerName = this.props.InventoryLotContainerData[0].Container.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - Container: ' + containerName} </div>
            }
            catch (e) {
                pageHeader = <div>Inventory Management - Container</div>
            }

            skuDetailsDiv =
                <div>
                    <Row>
                        <div style={{ float: 'right', marginBottom: 10 }}>
                            <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.onClickInventoryDelete(e) }} >Delete Container</Button>
                            <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.onClickCloseContainer(e) }} >Close Container</Button>
                            <Button style={{ float: 'right', marginRight: '10px' }} onClick={(e) => { this.onClickEditContainer(e) }} >Edit</Button>
                        </div>
                    </Row>
                    <Row >
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Warehouse: <strong>{this.props.InventoryLotContainerData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{this.props.InventoryLotContainerData[0].CustomerName}</strong>
                                <br />
                                Status: <strong>{this.props.InventoryLotContainerData[0].ContainerStatusName}</strong>
                                <br />
                                Palletized: <strong>{formatBitToTrueFalse(this.props.InventoryLotContainerData[0].Palletized)}</strong>
                                <br />
                                Notes: <strong>{this.props.InventoryLotContainerData[0].Notes}</strong>
                                <br />
                            </Panel>
                        </Col>
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                LCL/FCL: <strong>{this.props.InventoryLotContainerData[0].LCL_FCL}</strong>
                                <br />
                                Container Size: <strong>{this.props.InventoryLotContainerData[0].ContainerSize}</strong>
                                <br />
                                DeliveryAppointment: <strong>{this.props.InventoryLotContainerData[0].DeliveryAppointment}</strong>
                                <br />
                                ETD: <strong>{this.props.InventoryLotContainerData[0].ETD}</strong>
                                <br />
                                ETA: <strong>{this.props.InventoryLotContainerData[0].ETA}</strong>
                                <br />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Expected: <strong>{this.props.InventoryLotContainerData[0].UnitsExpected}</strong>
                                <br />
                                Cases Expected: <strong>{this.props.InventoryLotContainerData[0].CasesExpected}</strong>
                                <br />
                                Pallets Expected: <strong>{this.props.InventoryLotContainerData[0].PalletsExpected}</strong>
                                <br />
                            </Panel>
                        </Col>

                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Received: <strong>{this.props.InventoryLotContainerData[0].UnitsReceived}</strong>
                                <br />
                                Cases Received: <strong>{this.props.InventoryLotContainerData[0].CasesReceived}</strong>
                                <br />
                                Pallets Received: <strong>{this.props.InventoryLotContainerData[0].PalletsReceived}</strong>
                                <br />
                            </Panel>
                        </Col>

                        {/* <Col md={6} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units In Whse: <strong>{this.props.InventoryLotContainerData[0].UnitsInWhse}</strong>
                                <br />
                                Cases In Whse: <strong>{this.props.InventoryLotContainerData[0].CasesInWhse}</strong>
                                <br />
                                Pallets In Whse: <strong>{this.props.InventoryLotContainerData[0].PalletsInWhse}</strong>
                                <br />
                            </Panel>
                        </Col>

                        <Col md={6} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units Shipped: <strong>{this.props.InventoryLotContainerData[0].UnitsLoaded}</strong>
                                <br />
                                Cases Shipped: <strong>{this.props.InventoryLotContainerData[0].CasesLoaded}</strong>
                                <br />
                                Pallets Shipped: <strong>{this.props.InventoryLotContainerData[0].PalletsLoaded}</strong>
                                <br />
                            </Panel>
                        </Col> */}

                    </Row>
                </div>
        }
        else {
            pageHeader = <div>Inventory Management - Container</div>
            skuDetailsDiv = <div></div>
        }








        let containerHistoryTimeline;
        if (this.props.isInventoryContainerHistoryLoading) {
            containerHistoryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryContainerHistoryData[0]) !== 'undefined' && this.props.InventoryContainerHistoryData != null) {
            containerHistoryTimeline =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered header="Container History" style={{ marginBottom: 10 }}>
                                <Timeline>
                                    {this.props.InventoryContainerHistoryData.map(item => (
                                        <Timeline.Item>
                                            {item.ContainerHistoryDT}  &nbsp;
                                            {item.EmailAddr}
                                            <l><br />{item.ContainerHistoryNotes} &nbsp;
                                            </l>
                                            {item.Container != null ? <l><br /> Container:  {item.Container} </l> : ' '}
                                            {item.LCL_FCL != null ? <l><br /> LCL/FCL:  {item.LCL_FCL} </l> : ' '}
                                            {item.DeliveryAppointment != null ? <l><br /> DeliveryAppointment:  {item.DeliveryAppointment} </l> : ' '}
                                            {item.ETD != null ? <l><br /> ETD:  {item.ETD} </l> : ' '}
                                            {item.ETA != null ? <l><br /> ETA:  {item.ETA} </l> : ' '}
                                            {item.Palletized != null ? <l><br /> Palletized:  {item.Palletized === true ? "True" : "False"} </l> : ' '}





                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </Panel>
                        </Col>
                    </Row>

                </div>
        }
        else {
            containerHistoryTimeline = <div></div>
        }


        // let skuRecoveryTimeline;
        // if (this.props.isInventorySkuRecoveryHistoryLoading) {
        //     skuRecoveryTimeline =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventorySkuRecoveryHistoryData[0]) !== 'undefined' && this.props.InventorySkuRecoveryHistoryData != null) {
        //     skuRecoveryTimeline =
        //         <div>
        //             <Row >
        //                 <Col md={24} sm={24}>
        //                     <Panel bordered header="SKU Recovery History" style={{ marginBottom: 10 }}>
        //                         <Timeline>
        //                             {this.props.InventorySkuRecoveryHistoryData.map(item => (
        //                                 <Timeline.Item>
        //                                     {item.SkuRecoveryDT}  &nbsp;
        //                                     {item.EmailAddr}
        //                                     <l></l>
        //                                     {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '}
        //                                     {item.UnitsReceived != null ? <l><br /> Units Received:  {item.UnitsReceived} </l> : ' '}
        //                                     {item.CasesReceived != null ? <l><br /> Cases Received:  {item.CasesReceived} </l> : ' '}
        //                                     {item.PalletsReceived != null ? <l><br /> Pallets Received:  {item.PalletsReceived} </l> : ' '}
        //                                     {/* {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '} */}
        //                                     {item.SkuDescription != null ? <l><br /> Description:  {item.SkuDescription} </l> : ' '}
        //                                 </Timeline.Item>
        //                             ))}
        //                         </Timeline>

        //                     </Panel>
        //                 </Col>
        //             </Row>

        //         </div>
        // }
        // else {
        //     skuRecoveryTimeline = <div></div>
        // }





        let receiveDrawer;
        if (this.props.isInventoryLotSkuLoading) {
            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        // else if (this.props.isPoLocationLoading) {

        //     receiveDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        else if (!this.state.showReceiveDrawer) {
            receiveDrawer = <div></div>
        }
        else if (Object.keys(this.state.LocationDict).length > 0 && typeof (this.state.LocationDict) !== 'undefined' && this.state.LocationDict != null) {

            receiveDrawer = <ReceiveContainerDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} onChangeReceiveDate={this.onChangeReceiveDate} onChangeLocation={this.onChangeReceiveDrawerLocations} onClickAddLocation={this.onClickAddLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onChangePalletized={this.onChangePalletized} masterLabel={containerName} />
        }





        let shipmentPalletTable;
        let tablePalletDataDiv;
        let palletDiv;
        if (this.props.isInventoryPalletWarehouseGroupedLoading) {
            palletDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryPalletWarehouseGroupedData) !== 'undefined' && this.props.InventoryPalletWarehouseGroupedData != null) {

            shipmentPalletTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryPalletWarehouseGroupedData,     //   tableData,
                InventoryPalletWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryPalletWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Pallets',     //   link1text,
                this.onClickPalletDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            tablePalletDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Pallets_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryPalletWarehouseGroupedData} name="Pallets">
                                    {
                                        InventoryPalletWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryPalletWarehouseGroupedData.length > 0) {
                palletDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Pallets" style={{ marginBottom: 10 }}>
                            {tablePalletDataDiv}
                            {shipmentPalletTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                palletDiv = <div></div>
            }

        }
        else {
            palletDiv = <div></div>
        }




        let shipmentCaseTable;
        let tableCaseDataDiv;
        let caseDiv;
        if (this.props.isInventoryCaseWarehouseGroupedLoading) {
            caseDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryCaseWarehouseGroupedData) !== 'undefined' && this.props.InventoryCaseWarehouseGroupedData != null) {
            shipmentCaseTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryCaseWarehouseGroupedData,     //   tableData,
                InventoryCaseWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryCaseWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Cases',     //   link1text,
                this.onClickCaseDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            tableCaseDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Cases_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryCaseWarehouseGroupedData} name="Cases">
                                    {
                                        InventoryCaseWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>


            if (this.props.InventoryCaseWarehouseGroupedData.length > 0) {
                caseDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Cases" style={{ marginBottom: 10 }}>
                            {tableCaseDataDiv}
                            {shipmentCaseTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                caseDiv = <div></div>
            }
        }
        else {
            caseDiv = <div></div>
        }


        let lotSkuTable;
        let lotSkuDataDiv;
        let unitDiv;
        if (this.props.isInventoryUnitWarehouseGroupedLoading) {
            unitDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryUnitWarehouseGroupedData) !== 'undefined' && this.props.InventoryUnitWarehouseGroupedData != null) {

            lotSkuTable = RTableGeneral(
                'ID',     //   keyColumn,
                this.props.InventoryUnitWarehouseGroupedData,     //   tableData,
                InventoryUnitWarehouseGroupedDataElements,       //   DataElements,
                this.props.isInventoryUnitWarehouseGroupedLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,
                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Units',     //   link1text,
                this.onClickUnitDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )
            lotSkuDataDiv = <div>
                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Units_' + currentDateTime}>
                                <ExcelSheet data={this.props.InventoryUnitWarehouseGroupedData} name="Units">
                                    {
                                        InventoryUnitWarehouseGroupedDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }
            </div>

            if (this.props.InventoryUnitWarehouseGroupedData.length > 0) {
                unitDiv = <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="Units" style={{ marginBottom: 10 }}>
                            {lotSkuDataDiv}
                            {lotSkuTable}
                        </Panel>
                    </Col>
                </Row>

            }
            else {
                unitDiv = <div></div>
            }
        }
        else {
            unitDiv = <div></div>
        }










        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>
                    {skuDetailsDiv}
                </PanelGroup>


                {palletDiv}
                {caseDiv}
                {unitDiv}


                {containerHistoryTimeline}

                {/* {skuRecoveryTimeline} */}

                {/* <PalletWarehouseLocationHistoryDiv
                    isInventoryPalletWarehouseLocationHistoryLoading={this.props.isInventoryPalletWarehouseLocationHistoryLoading}
                    InventoryPalletWarehouseLocationHistoryData={this.props.InventoryPalletWarehouseLocationHistoryData}
                /> */}
                {/* <CaseHistoryDiv
                    isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                    InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}
                />
                <CaseSKUsHistoryDiv
                    isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                    InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}
                /> */}


                <EditContainerDrawer {...this.props} state={this.state} onChangeAddNewContainer={this.onChangeAddNewContainer} confirm={this.onClickConfirmAddNewContainer} close={this.closeAddNewDrawer} />

                {receiveDrawer}



                <HistoryTypeModal
                    open={this.state.historyTypeModalOpen}
                    handleClose={this.handleModalClose}
                    data={this.props.InventoryLotHistoryTypeData}
                    loading={this.props.isInventoryLotHistoryTypeLoading}
                    handleSelect={this.handleModalSelect}
                />

            </div>
        );

    }
}

export default withRouter(ContainerDetail);

