import React, {
    PureComponent
  }                         from 'react';
import { IconButton, Icon, Whisper, ButtonToolbar, Tooltip } from 'rsuite';

const tooltipHideShow = (
    <Tooltip>
      Hide/Show Filters
    </Tooltip>
  );

  const tooltipReset = (
    <Tooltip>
      Reset Filters
    </Tooltip>
  );

function RButtonHideShowFilter(
    isMobile,
    isFilterShown,
    label,
    onClick,
    onClickReset
    
  ) {

    let rButton;

    try{

        rButton =    
                    <div >

                        <ButtonToolbar style={{ textAlign: 'right' }}>
                            {label} &nbsp;
                            <Whisper placement="top" trigger="hover" speaker={tooltipHideShow}>
                                <IconButton icon={<Icon icon={isFilterShown ? 'minus-square-o' : 'plus-square-o' } />} onClick={(e) => onClick(e, isFilterShown)} size="sm" style={isMobile ? {marginBottom:'5px'} : {} }></IconButton>
                            </Whisper>

                            <Whisper placement="top" trigger="hover" speaker={tooltipReset}>
                                <IconButton icon={<Icon icon={'ban' } />} onClick={(e) => onClickReset(e)} size="sm" style={isMobile ? {marginBottom:'5px'} : {} }></IconButton>
                            </Whisper>
                        </ButtonToolbar>
                    </div>

    }
    catch(e){}

    return (

        rButton

    )
    }


export default RButtonHideShowFilter;
