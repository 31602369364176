
export const CustomersDataElements = [
    {
        dBKey: 'CustomerName',
        displayName: 'Customer Name',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CustomerCode',
        displayName: 'Customer Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Active',
        displayName: 'Active',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

]

export default CustomersDataElements

