export const InventorySkuWarehouseGroupedDataElements = [
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    // {
    //     dBKey: 'SkuType',
    //     displayName: 'Case/Unit',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 100
    // },
    {
        dBKey: 'StatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CasesInWhse',
        displayName: 'Cases In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'UnitsInWhse',
        displayName: 'Units In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },


]


export default InventorySkuWarehouseGroupedDataElements

