import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Button,
    ButtonToolbar,
    Form,
    FormGroup,
    ControlLabel,
    InputPicker,
    DatePicker,
    Loader,
    Row,
    InputGroup,
    Panel,
    Input,
    Col,
    InputNumber,
    ButtonGroup,
    Divider
} from 'rsuite';
import { lettersNumbersDashesInputMask } from '../../../resources/logic/inputMasks';
import RTableBottomDrawer from '../../componentsGeneral/Rsuite/Table/RTableBottomDrawer';
import CtrlTwrShipmentAlertsDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentAlertsDataElements';
import ReactExport from "react-export-excel";
import * as moment from 'moment'


const styles = {
    marginTop: '5px',
    width: '100%'
};


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "ShipmentLegMilestoneID"


const mountRef = React.createRef();


const DashboardShipListDrawer = ({ onChange, placement, onClickShipDetails, shipListDrawerDataElements, close, masterLabel, onChangeWarehouse, onChangeCustomer, onChangePO, onChangeOriginCountry, onChangeETD, onClickDelete, state, ...props }) => (

    state.showShipListDrawer === false ?
        <div align="center">
        </div>

        :

        props.isCtrlTwrShipmentLegsMilestonesLoading ?

            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

            :

            <Drawer size={"full"}  show={state.showShipListDrawer} onHide={close} placement={'bottom'} style={{ zIndex: '9999999999999999999' } }    >
                <Drawer.Header>
                    {state.ShipListDrawerType}
                </Drawer.Header>
                <Drawer.Body> <div ref={mountRef}>

                    <Form fluid>


                        {RTableBottomDrawer(
                            tableIdCol,     //   keyColumn,
                            state.ShipmentListDrawerData,     //   tableData,
                            shipListDrawerDataElements,       //   DataElements,
                            props.isCtrlTwrShipmentLoading,        //   loading,

                            props.isMobile,        //   isMobile,
                            props.history,     //   history,

                            null,
                            null,
                            null,

                            // sortColumn,     //   sortColumn,
                            // sortType,       //   sortType,
                            // handleSortColumn,      //   handleSortColumn,

                            null, // checkColEntry,
                            null,       //   checkedKeys,
                            null,       //   handleCheckAll,
                            null,       //   handleCheck,

                            false,      //   onClickAddMilestone,
                            null,       //   onClickSelect,
                            null,       //   selectButtonText,
                            null,       //   selectHighlightRowKey,

                            true,       //   link1bool,
                            'Details',     //   link1text,
                            onClickShipDetails,     //   link1onClick,

                            false,       //   link2bool,
                            null,       //   link2text,
                            null  //   link2onClick
                        )
                        }

                        <div>

                            {(props.isMobile == true) ? <div></div> :
                                <div>
                                    <Row>
                                        <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'ShipmentManagement_' + currentDateTime}>
                                            <ExcelSheet data={state.ShipmentListDrawerData} name="ShipmentManagement">
                                                {
                                                    CtrlTwrShipmentAlertsDataElements.map(
                                                        ({ dBKey, displayName }, idx) => {
                                                            return (
                                                                <ExcelColumn label={displayName} value={dBKey} />
                                                            );
                                                        }
                                                    )
                                                }
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Row>
                                </div>
                            }

                        </div>

                    </Form >
                </div> </Drawer.Body>
                <Drawer.Footer>

                    <br />

                </Drawer.Footer>


            </Drawer >

);



export default DashboardShipListDrawer;


