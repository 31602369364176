import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    ButtonGroup,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,

    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Tooltip

} from 'rsuite';

import { lettersNumbersDashesSpacesInputMask, limitCharacterLength, numbersInputMask, textNumbersSymbolsInputmask } from '../../../resources/logic/inputMasks';

import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import AddToLoadHawbDataElements from '../../dataComponentsXdock/AddToLoadHawbDataElements';
import * as moment from 'moment'

const styles = {
    width: '100%'
};


const tooltipSearch = (
    <Tooltip>
        Search
    </Tooltip>
);

const tooltipClear = (
    <Tooltip>
        Clear
    </Tooltip>
);


function limitNumberToExistingValue(e, item) {
    try {
        var limitValue = item['RecoveredPCS'] - item['LoadedPCS']
    }
    catch (e) {
        var limitValue = 0
    }
    if (e.target.value <= limitValue) {
        return e.target.value
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }

}

const mountRef = React.createRef();


const EditCartonDrawer = ({
    onChange,
    placement,
    close,
    onClickSave,
    state,
    ...props }) => (

    <Drawer show={state.showEditCartonDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '655px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit Carton</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body > <div ref={mountRef}>


            <Panel bordered style={{ marginBottom: '15px' }}>
                <Row>
                    <Col md={24} sm={24}>
                        <FormGroup >

   
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Carton Qty:</InputGroup.Addon>
                                <InputNumber style={styles} defaultValue={state.newCartonQty} className={'custom-input-number'} onChange={(e) => onChange(e, 'newCartonQty')} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                            </InputGroup>


                        </FormGroup>
                    </Col>
                </Row>





                <Row >
                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <Button block onClick={(e) => { onClickSave(e) }} appearance="primary">Save</Button>
                        </ButtonToolbar>
                    </Col>
                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <Button block onClick={(e) => { close(e) }} appearance="default">Cancel</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>

            </Panel>






        </div> </Drawer.Body>
        <Drawer.Footer>

            <br />

        </Drawer.Footer>
    </Drawer>
);



export default EditCartonDrawer;
















