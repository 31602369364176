import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Tree } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryWarehouseLocationDetailsDataElements from '../../../dataComponentsInventory/InventoryWarehouseLocationDetailsDataElements';
import queryString from 'query-string';
import { formatBitToTrueFalse } from '../../../../resources/logic/functions';

const tableIdCol = "SkuWarehouseLocationID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class WarehouseLocationDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},
            CustomerID: null,
            WarehouseID: null,


        };


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);
        //Table buttons
        this.onClickDetails = this.onClickDetails.bind(this);


    }




    componentDidMount() {
        this.loadSortFromStorage()
        this.getsessionStorageData() //this will preserve menu selection
        this.loadWarehouseToState()
        this.loadCustomerToState()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }



    loadWarehouseToState() {
        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
    }

    loadCustomerToState() {
        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
    }

    getsessionStorageData() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }
        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierArray'));
            if (carrierMenuList != null && carrierMenuList != undefined) {
                this.setState({
                    carrierArray: carrierMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierServiceMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierServiceArray'));
            if (carrierServiceMenuList != null && carrierServiceMenuList != undefined) {
                this.setState({
                    carrierServiceArray: carrierServiceMenuList
                })
            }
        }
        catch (e) { }
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    //Table on click details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData["SkuID"]
            history.push({
                pathname: 'skudetails',
                search: 'skuid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    render() {
        const { sortColumn, sortType } = this.state;

        let warehouseLocationsDetailsDiv;
        let pageHeader;
        if (this.props.isInventoryWarehouseLocationLoading) {
            pageHeader = <div>Inventory Management - Warehouse Location Details</div>

            warehouseLocationsDetailsDiv = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

        }
        else if (typeof (this.props.InventoryWarehouseLocationData[0]) !== 'undefined' && this.props.InventoryWarehouseLocationData != null) {
            try {
                var warehouseLocationName = this.props.InventoryWarehouseLocationData[0].WarehouseLocationName.toString()
                pageHeader = <div style={{ marginBottom: '15px' }}>  {'Inventory Management - Warehouse Location Details: ' + warehouseLocationName} </div>
            }
            catch (e) {
                pageHeader = <div>Inventory Management - Warehouse Location Details</div>
            }


            let warehouseString = ''
            try {
                if (typeof (this.props.warehouseSelection) === 'number') {
                    var warehousefiltered = this.state.warehouseArray.filter(x => x.value === this.props.warehouseSelection)
                    warehouseString = warehousefiltered.map(x => x.WarehouseName)
                }
                else {
                    var warehousefiltered = this.state.warehouseArray.filter(x => this.props.warehouseSelection.includes(x.value))
                    var warehouseStringList = warehousefiltered.map(x => x.WarehouseName)
                    warehouseString = warehouseStringList.toString()
                }
            }
            catch (e) { }

            let customerString = ''
            try {
                if (typeof (this.props.customerSelection) === 'number') {
                    var customerfiltered = this.state.customerArray.filter(x => x.value === this.props.customerSelection)
                    customerString = customerfiltered.map(x => x.CustomerName)
                }
                else {
                    var customerfiltered = this.state.customerArray.filter(x => this.props.customerSelection.includes(x.value))
                    var customerStringList = customerfiltered.map(x => x.CustomerName)
                    customerString = customerStringList.toString()
                }
            }
            catch (e) { }


            warehouseLocationsDetailsDiv =
                <div>
                    <Row >
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Warehouse Location: <strong>{this.props.InventoryWarehouseLocationData[0].WarehouseLocationName}</strong>
                                <br />
                                Warehouse: <strong>{warehouseString}</strong>
                                <br />
                                Customer: <strong>{customerString}</strong>
                                <br />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Units In Whse: <strong>{this.props.InventoryWarehouseLocationData[0].UnitsInWhse}</strong>
                                <br />
                                Cases In Whse: <strong>{this.props.InventoryWarehouseLocationData[0].CasesInWhse}</strong>
                                <br />
                                Pallets In Whse: <strong>{this.props.InventoryWarehouseLocationData[0].PalletsInWhse}</strong>
                                <br />
                            </Panel>
                        </Col>
                    </Row>
                </div>
        }
        else {
            pageHeader = <div>Inventory Management - Warehouse Location Details</div>
            warehouseLocationsDetailsDiv = <div></div>
        }


        let warehouseLocationsTable;
        let tableDataDiv;
        if (this.props.isInventorySkuWarehouseLocationLoading) {
            warehouseLocationsTable = <div></div>
            tableDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationData) !== 'undefined' && this.props.InventorySkuWarehouseLocationData != null) {
            warehouseLocationsTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.InventorySkuWarehouseLocationData,     //   tableData,
                InventoryWarehouseLocationDetailsDataElements,       //   DataElements,
                this.props.isInventorySkuWarehouseLocationLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )

            tableDataDiv = <Row>
                <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'WarehouseLocation_' + currentDateTime}>
                    <ExcelSheet data={this.props.InventorySkuWarehouseLocationData} name="WarehouseLocation">
                        {
                            InventoryWarehouseLocationDetailsDataElements.map(
                                ({ dBKey, displayName }, idx) => {
                                    return (
                                        <ExcelColumn label={displayName} value={dBKey} />
                                    );
                                }
                            )
                        }
                    </ExcelSheet>
                </ExcelFile>
            </Row>
        }
        else {
            warehouseLocationsTable = <div></div>
            tableDataDiv = <div></div>
        }







        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>
                    {warehouseLocationsDetailsDiv}
                </PanelGroup>


                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered header="SKUs" style={{ marginBottom: 10 }}>
                            {tableDataDiv}
                            {warehouseLocationsTable}
                        </Panel>
                    </Col>
                </Row>




            </div>
        );

    }
}

export default withRouter(WarehouseLocationDetails);
