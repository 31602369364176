import React from 'react';
import Barcode from 'react-barcode';
import { Card, CardTitle, CardText, CardMedia } from 'material-ui/Card';
import { Row, Col, Panel, PanelGroup, Button, FlexboxGrid, Tree, Container, Sidebar, Header, Footer, Content } from 'rsuite';

const parentStyles = {
    position: 'relative',
    // width: '500px', height: '750px',
};




export default class PalletContentsNoMapping extends React.Component {
    render() {
        const { content } = this.props;

        return (



            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid black', margin: '10px', padding: '10px' }}>
                <div style={parentStyles}>
                    <div style={{ marginBottom: '5px', marginRight: '40px' }}>
                        <strong>Case:</strong> {content.Case_SKU} (Qty: {content.CaseQty}) <br />
                        <strong>Case Lot:</strong> {content.LotCode}<br />
                        <strong>Case Serial:</strong> {content.SerialNumber}<br />
                        {content.SKU != null ? <strong>Units:</strong> : ''}
                        {content.SKU != null ? content.SKU.split(',').map((i, index) => <div key={index}>{i}<br /></div>) : ''}
                    </div>
                </div>
            </div>



            // <div id="div-svg" style={{ display: 'flex'}}>


            //     <div style={parentStyles}>


            //             <div style={{ outline: 'inset', marginBottom: '5px', marginRight: '40px' }}>

            //                 <strong>Case:</strong> {content.Case_SKU} (Qty: {content.CaseQty}) <br />
            //                 -  Case Lot: {content.LotCode}<br />
            //                 -  Case Serial: {content.SerialNumber}<br />

            //                 {content.SKU != null ? <strong>Units:</strong> : ''}
            //                 {content.SKU != null ? content.SKU.split(',').map(i => <div>{i}<br /></div>) : ''}

            //                 <br />


            //             </div>


            //     </div>
            //     <br />
            //     <br />

            // </div>


        );
    }
}

