export const LabelGenBulkTemplateDataElements = [
    {
        dBKey: 'Unique_ID',
        displayName: 'Unique_ID',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Package_Height',
        displayName: 'Package_Height',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Package_Length',
        displayName: 'Package_Length',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Package_Width',
        displayName: 'Package_Width',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Package_Dimensions_UnitOfMeasurement(IN/CM)',
        displayName: 'Package_Dimensions_UnitOfMeasurement(IN/CM)',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'Package_Weight',
        displayName: 'Package_Weight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Package_Weight_UnitOfMeasurement(LBS/KGS)',
        displayName: 'Package_Weight_UnitOfMeasurement(LBS/KGS)',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },
    {
        dBKey: 'ProductInfo',
        displayName: 'ProductInfo',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipTo_Name',
        displayName: 'ShipTo_Name',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipTo_AddressLine1',
        displayName: 'ShipTo_AddressLine1',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipTo_Address_City',
        displayName: 'ShipTo_Address_City',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipTo_Address_StateProvinceCode',
        displayName: 'ShipTo_Address_StateProvinceCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipTo_Address_PostalCode',
        displayName: 'ShipTo_Address_PostalCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipTo_Address_CountryCode',
        displayName: 'ShipTo_Address_CountryCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipTo_Phone_Number',
        displayName: 'ShipTo_Phone_Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipFrom_Name',
        displayName: 'ShipFrom_Name',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipFrom_Address_AddressLine1',
        displayName: 'ShipFrom_Address_AddressLine1',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipFrom_Address_City',
        displayName: 'ShipFrom_Address_City',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipFrom_Address_StateProvinceCode',
        displayName: 'ShipFrom_Address_StateProvinceCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipFrom_Address_PostalCode',
        displayName: 'ShipFrom_Address_PostalCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ShipFrom_Address_CountryCode',
        displayName: 'ShipFrom_Address_CountryCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Shipper_Name',
        displayName: 'Shipper_Name',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Shipper_Address_AddressLine1',
        displayName: 'Shipper_Address_AddressLine1',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Shipper_Address_City',
        displayName: 'Shipper_Address_City',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Shipper_Address_StateProvinceCode',
        displayName: 'Shipper_Address_StateProvinceCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Shipper_Address_PostalCode',
        displayName: 'Shipper_Address_PostalCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Shipper_Address_CountryCode',
        displayName: 'Shipper_Address_CountryCode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Shipper_Phone_Number',
        displayName: 'Shipper_Phone_Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },


]


export default LabelGenBulkTemplateDataElements

