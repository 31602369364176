export const InventoryLoadDataElements = [
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Trailer',
        displayName: 'Trailer',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Carrier',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'BOL',
        displayName: 'BOL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Seal',
        displayName: 'Seal',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    {
        dBKey: 'MC',
        displayName: 'MC #',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'DOT',
        displayName: 'DOT #',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Driver',
        displayName: 'Driver',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Truck',
        displayName: 'Truck #',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'TruckPlate',
        displayName: 'Truck Plate',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'TrailerPlate',
        displayName: 'Trailer Plate',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    
    {
        dBKey: 'LoadStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'TotalPallets',
        displayName: 'Total Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'TotalCases',
        displayName: 'Total Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },

    {
        dBKey: 'TotalUnits',
        displayName: 'Total Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },





    {
        dBKey: 'PickupDate',
        displayName: 'Pickup Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
        {
        dBKey: 'DeliveryDate',
        displayName: 'Delivery Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'Destination',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

]


export default InventoryLoadDataElements

