import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { lettersNumbersDashesInputMask } from '../../../resources/logic/inputMasks';

const AutoCompleteInput = ({ state, data, valueName, onChangeInput, placeholder }) => {


    return (

        <Autocomplete
            freeSolo
            options={data}
            getOptionLabel={(option) => option.label}
            value={data.find(option => option.label === state[valueName]) || null}

            onChange={(event, newValue) => {
                // Extract the label value and pass it to onChangeAddToContainer
                const labelValue = typeof newValue === 'string' ? newValue : newValue?.label;
                onChangeInput(event, labelValue, valueName);
            }}


            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    onBlur={(e) => {
                        const typedValue = e.target.value;
                        if (typeof typedValue !== 'undefined' && typedValue !== null && typedValue !== '') {
                            onChangeInput(null, typedValue, valueName);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.target.blur(); // Remove focus from the input
                        }
                    }}
                    onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true, // Remove the underline
                        style: {
                            padding: '6px 10px', // Adjust padding to reduce height
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            fontSize: '14px',
                            boxSizing: 'border-box',
                            height: '36px', // Set a fixed height to match rsuitejs
                        },
                    }}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            padding: 0, // Remove padding from the input element
                            height: '100%', // Ensure the input element takes the full height
                        },
                    }}
                    style={{
                        width: '100%',
                    }}
                />
            )}
            style={{
                width: '100%',
            }}
        />


    );
};

export default AutoCompleteInput;




export const handleAutoCompleteInputChange = (data, state, setState, newValue, stateName)  => {
    const exists = data.some(option => {
        const labelToCheck = typeof newValue === 'string' ? newValue : newValue?.label;
        return option.label === labelToCheck;
    });

    if (exists) {
        // Handle the case where newValue already exists in InventorySkuRefData
        setState({
            ...state,
            [stateName]: newValue
        });
    } else if (!exists && newValue && typeof newValue === 'string') {
        // Handle the case where newValue is a new option created by the user
        const newOption = { label: newValue, value: newValue };
        data.push(newOption);
        setState({
            ...state,
            [stateName]: newValue
        });
    } else if (typeof newValue === 'string') {
        // Handle the case where newValue is a typed string
        setState({
            ...state,
            [stateName]: newValue
        });
    } else if (newValue && newValue.inputValue) {
        // Handle the case where newValue is a new option created by the user
        const newOption = { label: newValue.inputValue, value: newValue.inputValue };
        data.push(newOption);
        setState({
            ...state,
            [stateName]: newValue.inputValue
        });
    } else {
        // Handle the case where newValue is an existing option selected from the dropdown
        setState({
            ...state,
            [stateName]: newValue ? newValue.label : ''
        });
    }
};