import _ from "lodash"; 


export default function debounceClick(func) {
    const onClickDebounced = _.debounce(func, 400, true); 

    return onClickDebounced

}

// Call like this:  <Button onClick={debounceClick(confirm)} appearance="primary">Save</Button>