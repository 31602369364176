import * as types from '../constants/vasActionTypes';


/* ################################### EXTERNAL API ACTIONS ################################### */
export const customsCityAPIMessages = (_email, _authToken, _paramHouse, _paramMaster, _getOnly) =>
({
    type: types.CUSTOMS_CITY_API_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        paramHouse: _paramHouse,
        paramMaster: _paramMaster,
        getOnly: _getOnly


    }
});





/* ################################### VAS ACTIONS ################################### */



export const customsCityMessageGet = (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master) =>
({
    type: types.CUSTOMS_CITY_MESSAGES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HouseID: _HouseID,
        HouseRef: _HouseRef,
        MasterID: _MasterID,
        Master: _Master,


    }
});


export const dashboardSummaryGet = (_email, _authToken, _WarehouseID, _WarehousePort, _ConsigneeID, _DestinationID, _CustomerID) =>
({
    type: types.DASHBOARD_SUMMARY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        WarehousePort: _WarehousePort,
        ConsigneeID: _ConsigneeID,
        DestinationID: _DestinationID,
        CustomerID: _CustomerID


    }
});


export const mawbGet = (_email, _authToken, _MasterID, _Master, _MinETADischargeStart, _MinETADischargeEnd, _CarrierName, _MawbStatusID, _WarehouseID, _OrderNo, _PlannedPickupStart, _PlannedPickupEnd, _AssignedDriver, _MawbColorCode, _CustomerID) =>
({
    type: types.MAWB_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        Master: _Master,

        MinETADischargeStart: _MinETADischargeStart,
        MinETADischargeEnd: _MinETADischargeEnd,
        CarrierName: _CarrierName,
        MawbStatusID: _MawbStatusID,
        WarehouseID: _WarehouseID,
        OrderNo: _OrderNo,

        PlannedPickupStart: _PlannedPickupStart,
        PlannedPickupEnd: _PlannedPickupEnd,
        AssignedDriver: _AssignedDriver,

        MawbColorCode: _MawbColorCode,
        CustomerID: _CustomerID
    }
});

export const mawbPost = (_email, _authToken, _MasterID, _Master, _TotCartons, _TotWeight, _TotChargeable, _TotVolume, _MinETADischarge, _CarrierName, _FlightVoyage, _AgentNote, _CurrentMawbStatusID, _WarehouseID, _CustomerID, _ExpectedCartons) =>
({
    type: types.POST_MAWB_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        Master: _Master,
        TotCartons: _TotCartons,
        TotWeight: _TotWeight,
        TotChargeable: _TotChargeable,
        TotVolume: _TotVolume,
        MinETADischarge: _MinETADischarge,
        CarrierName: _CarrierName,
        FlightVoyage: _FlightVoyage,
        AgentNote: _AgentNote,
        CurrentMawbStatusID: _CurrentMawbStatusID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ExpectedCartons: _ExpectedCartons


    }
});

export const mawbEvolveDataEntryPost = (_email, _authToken, _MasterID, _Master, _No, _PMC, _PickupBy, _Trips, _ArrivalTime, _LFD, _ArrivalAtWarehouse, _DeliveryTime, _Notes, _Recovered, _WarehouseID, _CustomerID) =>
({
    type: types.POST_MAWB_EVOLVE_DATA_GET,
    payload: {
        email: _email,
        authToken: _authToken,


        MasterID: _MasterID,
        Master: _Master,

        No: _No,
        PMC: _PMC,
        PickupBy: _PickupBy,
        Trips: _Trips,
        ArrivalTime: _ArrivalTime,
        LFD: _LFD,
        ArrivalAtWarehouse: _ArrivalAtWarehouse,
        DeliveryTime: _DeliveryTime,
        Notes: _Notes,
        Recovered: _Recovered,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID

    }
});

export const mawbAddPost = (_email, _authToken, _MasterID, _Master, _WarehouseID, _CustomerID) =>
({
    type: types.POST_MAWB_ADD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        Master: _Master,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID


    }
});

export const mawbRecoveryGet = (_email, _authToken, _MasterID, _WarehouseID, _CustomerID) =>
({
    type: types.MAWB_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID

    }
});




export const mawbRecoveryPost = (_email, _authToken, _MasterID, _PlannedPickupDT, _DriverAssigned, _Note, _TripMade, _FreightRecovered, _PickupDT, _CartonsRecovered, _PickedUpBy, _Carrier, _LFD, _PMC, _WarehouseID, _CustomerID) =>
({
    type: types.POST_MAWB_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        PlannedPickupDT: _PlannedPickupDT,
        DriverAssigned: _DriverAssigned,
        Note: _Note,
        TripMade: _TripMade,
        FreightRecovered: _FreightRecovered,
        PickupDT: _PickupDT,
        CartonsRecovered: _CartonsRecovered,
        PickedUpBy: _PickedUpBy,
        Carrier: _Carrier,
        LFD: _LFD,
        PMC: _PMC,


        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID

    }
});


export const transportationMawbPickupPost = (_email, _authToken, _MasterID, _PlannedPickupDT, _DriverAssigned, _Note, _TripMade, _FreightRecovered, _PickupDT, _CartonsRecovered, _PickedUpBy, _Carrier, _LFD, _PMC, _WarehouseID, _CustomerID, _PartialPickup) =>
({
    type: types.POST_MAWB_PICKUP_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        PlannedPickupDT: _PlannedPickupDT,
        DriverAssigned: _DriverAssigned,
        Note: _Note,
        TripMade: _TripMade,
        FreightRecovered: _FreightRecovered,
        PickupDT: _PickupDT,
        CartonsRecovered: _CartonsRecovered,
        PickedUpBy: _PickedUpBy,
        Carrier: _Carrier,
        LFD: _LFD,
        PMC: _PMC,


        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,

        PartialPickup: _PartialPickup

    }
});

export const mawbStatusGet = (_email, _authToken, _MasterID, _Master, _WarehouseID, _CustomerID) =>
({
    type: types.MAWB_STATUS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        Master: _Master,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const mawbStatusPost = (_email, _authToken, _MasterID, _Master, _MawbStatusID, _MawbStatusName, _WarehouseID, _CustomerID) =>
({
    type: types.POST_MAWB_STATUS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        Master: _Master,
        MawbStatusID: _MawbStatusID,
        MawbStatusName: _MawbStatusName,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID


    }
});

export const mawbCarrierRefGet = (_email, _authToken) =>
({
    type: types.MAWB_CARRIER_GET,
    payload: {
        email: _email,
        authToken: _authToken


    }
});


export const hawbGet = (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID) =>
({
    type: types.HAWB_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HouseID: _HouseID,
        HouseRef: _HouseRef,
        MasterID: _MasterID,
        Master: _Master,

        ShipmentETDStart: _ShipmentETDStart,
        ShipmentETDEnd: _ShipmentETDEnd,
        Origin: _Origin,
        LastDischargePort: _LastDischargePort,
        ETADischargeStart: _ETADischargeStart,
        ETADischargeEnd: _ETADischargeEnd,
        EstCartageDeliveryStart: _EstCartageDeliveryStart,
        EstCartageDeliveryEnd: _EstCartageDeliveryEnd,
        ActualCartageDeliveryStart: _ActualCartageDeliveryStart,
        ActualCartageDeliveryEnd: _ActualCartageDeliveryEnd,
        HawbStatusID: _HawbStatusID,
        ConsigneeID: _ConsigneeID,
        Destination: _Destination,
        WarehouseID: _WarehouseID,
        WarehousePort: _WarehousePort,
        OrderNo: _OrderNo,
        CustomerID: _CustomerID


    }
});

export const hawbPost = (_email, _authToken, _MasterID, _HouseRef, _ConsigneeName, _ConsigneeID, _Notes, _Origin, _HAWBDestination, _LastDischargePort, _DeliverToAddress, _Orders, _ShipmentETD, _EstCartageDelivery, _ETADischarge, _ActualCartageDelivery, _Cartons, _NewPallets, _Weight, _Chargeable, _Volume, _WarehouseID, _CustomerID) =>
({
    type: types.POST_HAWB_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        HouseRef: _HouseRef,
        ConsigneeName: _ConsigneeName,
        ConsigneeID: _ConsigneeID,
        Notes: _Notes,
        Origin: _Origin,
        HAWBDestination: _HAWBDestination,
        LastDischargePort: _LastDischargePort,
        DeliverToAddress: _DeliverToAddress,
        Orders: _Orders,
        ShipmentETD: _ShipmentETD,
        EstCartageDelivery: _EstCartageDelivery,
        ETADischarge: _ETADischarge,
        ActualCartageDelivery: _ActualCartageDelivery,
        Cartons: _Cartons,
        NewPallets: _NewPallets,
        Weight: _Weight,
        Chargeable: _Chargeable,
        Volume: _Volume,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const hawbRecoveryGet = (_email, _authToken, _HouseID, _HouseRef, _WarehouseID, _CustomerID) =>
({
    type: types.HAWB_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HouseID: _HouseID,
        HouseRef: _HouseRef,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID


    }
});

export const hawbRecoveryPost = (_email, _authToken, _MasterID, _HouseID, _HouseRef, _RecoveredPCS, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID) =>
({
    type: types.POST_HAWB_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        HouseID: _HouseID,
        HouseRef: _HouseRef,
        RecoveredPCS: _RecoveredPCS,
        Pallets: _Pallets,
        Notes: _Notes,
        CustomsCleared: _CustomsCleared,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID


    }
});

export const hawbConsigneeGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.HAWB_CONSIGNEE_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID


    }
});

export const hawbDestinationGet = (_email, _authToken, _HawbStatusID) =>
({
    type: types.HAWB_DESTINATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        HawbStatusID: _HawbStatusID


    }
});



export const loadGet = (_email, _authToken, _LoadID, _HouseID, _MasterID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _LoadStatusID, _Carrier, _BOL, _Trailer, _Seal, _ConsigneeID, _DestinationID, _WarehouseID, _CustomerID) =>
({
    type: types.LOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        LoadID: _LoadID,
        HouseID: _HouseID,
        MasterID: _MasterID,
        PickupDateStart: _PickupDateStart,
        PickupDateEnd: _PickupDateEnd,
        DeliveryDateStart: _DeliveryDateStart,
        DeliveryDateEnd: _DeliveryDateEnd,
        LoadStatusID: _LoadStatusID,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        ConsigneeID: _ConsigneeID,
        DestinationID: _DestinationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const loadHawbGet = (_email, _authToken, _LoadID, _HouseID, _MasterID, _WarehouseID, _CustomerID) =>
({
    type: types.LOAD_HAWB_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        HouseID: _HouseID,
        MasterID: _MasterID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const loadPost = (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes,) =>
({
    type: types.POST_LOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        PickupDate: _PickupDate,
        DeliveryDate: _DeliveryDate,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        TotalPCS: _TotalPCS,
        TotalPallets: _TotalPallets,
        LoadStatusID: _LoadStatusID,
        LoadHawbData: _LoadHawbData,

        HouseID: _HouseID,
        PCS: _PCS,
        Pallets: _Pallets,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,

        MC: _MC,
        DOT: _DOT,
        Driver: _Driver,
        Truck: _Truck,
        TruckPlate: _TruckPlate,
        TrailerPlate: _TrailerPlate,
        Notes: _Notes,


    }
});

export const loadRemovePost = (_email, _authToken, _LoadID, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID) =>
({
    type: types.POST_LOAD_REMOVE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        HouseID: _HouseID,
        PCS: _PCS,
        Pallets: _Pallets,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const loadEditPost = (_email, _authToken, _LoadID, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID) =>
    ({
        type: types.POST_LOAD_EDIT_GET,
        payload: {
            email: _email,
            authToken: _authToken,
    
            LoadID: _LoadID,
            HouseID: _HouseID,
            PCS: _PCS,
            Pallets: _Pallets,
            WarehouseID: _WarehouseID,
            CustomerID: _CustomerID
        }
    });
export const loadHawbHistoryGet = (_email, _authToken, _HouseID, _LoadID, _WarehouseID, _CustomerID) =>
({
    type: types.LOAD_HAWB_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HouseID: _HouseID,
        LoadID: _LoadID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});


export const outboundGet = (_email, _authToken, _LoadID, _HouseID, _HouseRef, _MasterID, _Master, _OrderNo, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _DCCode, _Carrier, _Trailer, _Seal, _BOL, _WarehouseID, _CustomerID) =>
({
    type: types.OUTBOUND_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        HouseID: _HouseID,
        HouseRef: _HouseRef,
        MasterID: _MasterID,
        Master: _Master,
        OrderNo: _OrderNo,
        DepartDateStart: _DepartDateStart,
        DepartDateEnd: _DepartDateEnd,
        DestinationID: _DestinationID,
        DestinationName: _DestinationName,
        DCCode: _DCCode,
        Carrier: _Carrier,
        Trailer: _Trailer,
        Seal: _Seal,
        BOL: _BOL,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});



export const userColorPost = (_email, _authToken, _MasterID, _MawbColorCode, _WarehouseID, _CustomerID) =>
({
    type: types.POST_USER_COLOR_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        MawbColorCode: _MawbColorCode,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const hawbRecoveredShortPost = (_email, _authToken, _MasterID, _TotalPCS, _RecoveredPCS, _WarehouseID, _CustomerID,) =>
({
    type: types.XDOCK_POST_HAWB_RECOVERED_SHORT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MasterID: _MasterID,
        TotalPCS: _TotalPCS,
        RecoveredPCS: _RecoveredPCS,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});







export const xDockMawbFileUploadGet = (_email, _authToken, _CustomerID, _FileUploadID, _MasterID, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.XDOCK_MAWB_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        MasterID: _MasterID,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});


export const xDockMawbFileUploadPost = (_email, _authToken, _CustomerID, _FileUploadID, _MasterID, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.XDOCK_POST_MAWB_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        MasterID: _MasterID,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});








export const xDockLoadFileUploadGet = (_email, _authToken, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.XDOCK_LOAD_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        LoadID: _LoadID,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});


export const xDockLoadFileUploadPost = (_email, _authToken, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.XDOCK_POST_LOAD_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        LoadID: _LoadID,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});



export const xDockTrinityDataFileGet = (_email, _authToken, _CustomerID, _TrinityDataFileID, _MasterID, _FileName, _S3FileName, _FileType, _FileDelete, _StartDT, _EndDT,) =>
({
    type: types.XDOCK_TRINITY_DATA_FILE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        TrinityDataFileID: _TrinityDataFileID,
        MasterID: _MasterID,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,
        StartDT: _StartDT,
        EndDT: _EndDT,

    }
});


export const xDockTrinityDataGet = (_email, _authToken, _CustomerID, _WarehouseID, _TrinityDataID, _Master, _HouseRef, _HAWBDestination, _ConsigneeName, _ETALastDischargeStartDT, _ETALastDischargeEndDT,) =>
({
    type: types.XDOCK_TRINITY_DATA_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        WarehouseID: _WarehouseID,
        TrinityDataID: _TrinityDataID,
        Master: _Master,
        HouseRef: _HouseRef,
        HAWBDestination: _HAWBDestination,
        ConsigneeName: _ConsigneeName,
        ETALastDischargeStartDT: _ETALastDischargeStartDT,
        ETALastDischargeEndDT: _ETALastDischargeEndDT,

    }
});



export const xDockLoadPalletCartonGet = (_email, _authToken, _LoadID, _LoadPalletID, _LoadPalletCartonID,) =>
({
    type: types.XDOCK_LOAD_PALLET_CARTON_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        LoadPalletID: _LoadPalletID,
        LoadPalletCartonID: _LoadPalletCartonID,

    }
});




export const xDockLoadPalletCartonPost = (_email, _authToken, _LoadPalletCartonID, _LoadPalletID, _LoadID, _HouseID, _CartonID, _CartonQty, _Delete,) =>
({
    type: types.XDOCK_LOAD_PALLET_CARTON_POST,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadPalletCartonID: _LoadPalletCartonID,
        LoadPalletID: _LoadPalletID,
        LoadID: _LoadID,
        HouseID: _HouseID,
        CartonID: _CartonID,
        CartonQty: _CartonQty,
        Delete: _Delete,

    }
});


export const xDockLoadPalletPost = (_email, _authToken, _LoadPalletID, _LoadID, _HouseID, _PalletSeq, _Delete,) =>
({
    type: types.XDOCK_LOAD_PALLET_POST,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadPalletID: _LoadPalletID,
        LoadID: _LoadID,
        HouseID: _HouseID,
        PalletSeq: _PalletSeq,
        Delete: _Delete,

    }
});




export const xDockLoadPalletGet = (_email, _authToken, _LoadID, _LoadPalletID) =>
({
    type: types.XDOCK_LOAD_PALLET_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        LoadPalletID: _LoadPalletID,

    }
});


export const xDockCustomsCityManifestHistoryGet = (_email, _authToken, _CCManifestPostID, _MasterID, _BOLNumber, _HouseID, _houseBOLNumber,) =>
    ({
        type: types.XDOCK_CUSTOMS_CITY_MANIFEST_HISTORY_GET,
        payload: {
            email: _email,
            authToken: _authToken,
    
            CCManifestPostID: _CCManifestPostID,
            MasterID: _MasterID,
            BOLNumber: _BOLNumber,
            HouseID: _HouseID,
            houseBOLNumber: _houseBOLNumber,
    
        }
    });