import React from 'react';
import Barcode from 'react-barcode';
import { Card, CardTitle, CardText, CardMedia } from 'material-ui/Card';
import { Row, Col, Panel, PanelGroup, Button, FlexboxGrid, Tree, Container, Sidebar, Header, Footer, Content } from 'rsuite';

const parentStyles = {
    position: 'relative',
    // width: '500px', height: '750px',
};




export default class PalletContents extends React.Component {
    render() {
        const { product, barcode, content } = this.props;

        return (

            <div id="div-svg" style={{ display: 'flex'}}>


                <div style={parentStyles}>

                    <strong>Pallet: {barcode}</strong>
                    <br />
                    <br />
                    <strong>Contents:</strong>
                    
                    <br />
                    
                    {content.map(item => (
                        <div style={{ outline: 'inset', marginBottom: '5px', marginRight: '40px' }}>

                            <strong>Case:</strong> {item.Case_SKU} (Qty: {item.CaseQty}) <br />
                            -  Case Lot: {item.LotCode}<br />
                            -  Case Serial: {item.SerialNumber}<br />

                            {item.SKU != null ? <strong>Units:</strong> : ''}
                            {item.SKU != null ? item.SKU.split(',').map(i => <div>{i}<br /></div>) : ''}

                            <br />


                        </div>


                    ))}

                </div>


            </div>


        );
    }
}

