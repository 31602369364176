export const Edi204L5DataElements = [
    {
        dBKey: 'LineNumber',
        displayName: 'Line Number',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 120
    },

    {
        dBKey: 'Description',
        displayName: 'Description',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CommodityCode',
        displayName: 'Commodity Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 130
    },
    {
        dBKey: 'CommodityCodeQualifier',
        displayName: 'Commodity Code Qualifier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CommodityCode2',
        displayName: 'Commodity Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },


    {
        dBKey: 'CommodityCodeQualifier2',
        displayName: 'Commodity Code Qualifier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    
]


export default Edi204L5DataElements

