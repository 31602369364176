import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import WarehouseContainer from '../../../components/componentsTrans/Warehouse/WarehouseContainer';

class transWarehouseView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

    }

    render() {

        return (
            <AnimatedView>
                <WarehouseContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default transWarehouseView;
