import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,

    FormGroup,
 
    Row,
    Col,
    Panel,
    InputGroup,

    Input,
    Icon,
    Tooltip,
    Whisper

} from 'rsuite';

import {  lettersNumbersDashesSpacesInputMask } from '../../../../resources/logic/inputMasks';

import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import AddToLoadSkuDataElements from '../../../dataComponentsInventory/AddToLoadSkuDataElements';
import { zIndex } from 'material-ui/styles';


const styles = {
    width: '100%'
};


const tooltipSearch = (
    <Tooltip>
        Search
    </Tooltip>
);

const tooltipClear = (
    <Tooltip>
        Clear
    </Tooltip>
);




const mountRef = React.createRef();

const AddToLoadDrawer = ({ onClickAdd,
    onChange,
 

    placement,
    close,
    handleSortColumn,
    sortColumn,
    sortType,
    tableIdCol,
    onClickSearch,
    onClickReset,
    state,
    ...props }) => (

    <Drawer show={state.showBuildLoadDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '250px', zIndex:'999999999' } : {paddingLeft: '50px', width: '100%', zIndex:'999999999'}}    >
        <Drawer.Header>
            <Drawer.Title>Add To Load</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body > <div ref={mountRef}> 

            <Panel bordered style={{ marginBottom: '15px' }}>
                <Row>
                    <Col md={24} sm={24}>
                        <FormGroup >


                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>SKU:</InputGroup.Addon>
                                <Input defaultValue={state.SkuSearch} onChange={(e) => { onChange(e, 'SkuSearch') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />

                                <Whisper placement="top" trigger="hover" speaker={tooltipClear}>
                                    <InputGroup.Button onClick={(e) => { onClickReset(e, 'SkuSearch') }}>
                                        <Icon icon="close" />
                                    </InputGroup.Button>
                                </Whisper>

                                <Whisper placement="top" trigger="hover" speaker={tooltipSearch}>
                                    <InputGroup.Button onClick={(e) => { onClickSearch(e, 'SkuSearch') }}>
                                        <Icon icon="search" />
                                    </InputGroup.Button>
                                </Whisper>
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '15px', width: '100%' }}>
                                <InputGroup.Addon>Container:</InputGroup.Addon>
                                <Input defaultValue={state.ContainerSearch} onChange={(e) => { onChange(e, 'ContainerSearch') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />

                                <Whisper placement="top" trigger="hover" speaker={tooltipClear}>
                                    <InputGroup.Button onClick={(e) => { onClickReset(e, 'ContainerSearch') }}>
                                        <Icon icon="close" />
                                    </InputGroup.Button>
                                </Whisper>

                                <Whisper placement="top" trigger="hover" speaker={tooltipSearch}>
                                    <InputGroup.Button onClick={(e) => { onClickSearch(e, 'ContainerSearch') }}>
                                        <Icon icon="search" />
                                    </InputGroup.Button>
                                </Whisper>
                            </InputGroup>

                        </FormGroup>
                    </Col>
                </Row>
            </Panel>


            {RTable(
                props.InventoryLotSkuData,        // tableData,
                AddToLoadSkuDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                props.isInventoryLotSkuLoading,         // loading,
                handleSortColumn,      // handleSortColumn,
                null,        // onClickDetails,
                false, // checkColEntry,
                tableIdCol,                 // keyColumn,
                null,                       // checkedKeys,
                null,                       // handleCheckAll,
                null,                       // handleCheck,
                true,                      // boolSelectButton,
                onClickAdd,     // onClickSelect,
                'Add',                       // selectButtonText,
                props.isMobile,        // isMobile,
                props.history,         // history,
                null                        // highlightRowKey
            )}



        </div> </Drawer.Body> 
        <Drawer.Footer>

            <br />

        </Drawer.Footer>
    </Drawer>
);



export default AddToLoadDrawer;
















