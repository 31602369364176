import React, {
  PureComponent
} from 'react';
// import { ResponsiveBar } from '@nivo/bar'
import { withRouter } from 'react-router-dom';
import {
  Loader, Panel
} from 'rsuite';

import {
  BarChart, Bar, Brush, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import {
  ResponsiveContainer,
} from 'recharts';
import * as moment from 'moment'
import queryString from 'query-string';
import { formatStringForAPI, queryStringParse, queryStringParseWithZero } from '../../../../resources/logic/functions';


class ShipmentDeliveryHistoryChart extends PureComponent {
  constructor(props) {
    super(props);
  }



  onClick = (bar, i) => {

    var start = new Date();
    start.setDate(start.getDate() - 90);

    // var start = new Date( getDate() - 90 )

    var sDT = new Date(bar.Date);
    var eDT = new Date(bar.Date);
    // eDT.setDate( eDT.getDate() + 6 );//use 6 if grouped by week
    eDT.setDate(eDT.getDate() + 1);//grouped by day


    if (start > sDT) {

      sDT = start

    }

    var startDT = moment(sDT).format('YYYY-MM-DD')
    var endDT = moment(eDT).format('YYYY-MM-DD')

    // this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, "'" + i + "'", null, "'" + startDT + "'", "'" + endDT + "'", this.props.modeGlobalFilter, this.props.forwarderGlobalFilter, this.props.serviceGlobalFilter, this.props.laneGlobalFilter, null, null, null, null, null, null, null, null, null, null)

    const values = queryString.parse(this.props.location.search)


    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    try {
      var paramPo = formatStringForAPI(values.po)
    }
    catch (e) {
      var paramPo = null
    }

    try {
      var paramCountry = queryStringParse(values.country)
    }
    catch (e) {
      var paramCountry = null
    }

    try {
      var paramStatus = queryStringParseWithZero(values.status)
    }
    catch (e) {
      var paramStatus = null
    }

    this.props.ctrlTwrChartShipmentDeliveryHistoryDetailsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPro, paramPo, paramCountry, null, null, "'" + startDT + "'", "'" + endDT + "'", null, null, null, null, 1)

    this.props.onClickShipDeliveryHistoryChart()
  }





  render() {

    let shipGraph;
    if (this.props.isCtrlTwrChartShipmentDeliveryHistoryLoading) {
      shipGraph =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.CtrlTwrChartShipmentDeliveryHistoryData.length === 0) {
      shipGraph =

        <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Shipment Delivery (Delivery Date)'}
            </header>
          </section>
          <div className="panel-body table-responsive"
            style={{ backgroundColor: '#f5f5f5' }}>
            No data to display
          </div>
        </div>

    }

    else if (typeof (this.props.CtrlTwrChartShipmentDeliveryHistoryData) !== 'undefined' && this.props.CtrlTwrChartShipmentDeliveryHistoryData != null) {
      shipGraph =
        <Panel bordered>
          <section className="panel">
            <header className="panel-heading">
              {'Shipment Delivery (Delivery Date)'}
            </header>
            <div style={{ height: 400, background: "#FFF" }}>
              <div style={{ width: '100%', height: 400 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={this.props.CtrlTwrChartShipmentDeliveryHistoryData}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Date" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                    <ReferenceLine y={0} stroke="#000" />

                    <Bar dataKey="Late" stackId="a" fill="#f43e1f" onClick={(bar, i) => this.onClick(bar, 'late')} />

                    <Bar dataKey="On Time" stackId="a" fill="#37ba30" onClick={(bar, i) => this.onClick(bar, 'onSchedule')} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </section>
        </Panel>
    }

    else {
      shipGraph = <div></div>;
    }




    return (

      <div>
        {shipGraph}
      </div>
    );
  }
}




export default withRouter(ShipmentDeliveryHistoryChart);

