export const WarehouseDataElements = [


    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'MBOL',
        displayName: 'MBOL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'HBOL',
        displayName: 'HBOL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'PoStatusName',
        displayName: 'PO Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    
    {
        dBKey: 'CustomerRef',
        displayName: 'Customer Ref',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Project',
        displayName: 'Project',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },




    {
        dBKey: 'CaseCount',
        displayName: 'Expected CTNS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'CasesReceived',
        displayName: 'Received CTNS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'InWarehouseCases',
        displayName: 'In Whse CTNS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'CasesLoaded',
        displayName: 'Shipped CTNS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },







    {
        dBKey: 'PalletCount',
        displayName: 'Expected Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'PalletsReceived',
        displayName: 'Received Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'InWarehousePallets',
        displayName: 'In Whse Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'PalletsLoaded',
        displayName: 'Shipped Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'RcdDate',
        displayName: 'Rcd Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },


]


export default WarehouseDataElements

