import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import {
    Form,
    FormGroup,
    ButtonToolbar,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    InputPicker,
    Loader,
    Row,
    Button,
    Divider,
    DatePicker,
    List,
    Checkbox
} from 'rsuite';
import { lettersNumbersDashesInputMask, numbersInputMask, hundredthsDecimalInputMask, textStringInputMask, limitCharacterLength, textNumbersSymbolsInputmask } from '../../../resources/logic/inputMasks';
import { isNumeric, replaceNegativeWithZero, formatNumberForAPI, formatStringForAPI, findLowestMissingNumber, formatDateForUrlSearch, formatTextNumbersSymbolsForAPI, formatAllTextNumbersSymbolsForAPI } from '../../../resources/logic/functions';
import { weightUnits } from '../../../app/appConfig';


const styles = {
    marginBottom: '10px',
    width: '100%'
};



class ShipmentEntry extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            //Shipment Entry Form
            ShipEntryPO: '',
            ShipEntryCustomer: null,
            ShipEntryOriginCountry: null,
            ShipEntryDestinationCountry: null,
            ShipEntryETD: null,
            ShipEntryLegs: {},
            ShipEntryPallets: null,
            ShipEntryCases: null,
            ShipEntryUnitCount: null,
            ShipEntryWeightUnits: null,

            SpecialRequirements: null,
            GrossWeight: null,
            ChargeableWeight: null,
            Length: null,
            Width: null,
            Height: null,
            DimUnits: null,
            Commodity: null,

            UnsavedShipEntryChanges: false,

            NewlyCreatedPros: [],

            ShipmentNote: '',
            NoteIsPrivate: true


        };

        //Shipment Entry Form
        this.onClickAddLeg = this.onClickAddLeg.bind(this);
        this.onChangeLeg = this.onChangeLeg.bind(this);
        this.onClickDeleteLeg = this.onClickDeleteLeg.bind(this);
        this.onClickSaveShipEntry = this.onClickSaveShipEntry.bind(this);
        this.onClickResetShipEntry = this.onClickResetShipEntry.bind(this);

        this.onChangeCheckBoxOrgNote = this.onChangeCheckBoxOrgNote.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
    }



    componentDidMount() {

        this.getsessionStorageWarehouse() //this will preserve menu selection

        this.setInitalLegsDict()

    }



    getsessionStorageWarehouse() {

        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                // checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                // checkedKeys: []
            });
        }
        if (prevProps.CtrlTwrShipmentPostSuccessful !== this.props.CtrlTwrShipmentPostSuccessful && this.props.CtrlTwrShipmentPostSuccessful) {
            this.resetShipEntry()
            window.alert('Save succcessful.')
        }

    }



    //Shipment Entry Form
    onChangeWarehouse(e) {
        this.setState({
            ShipEntryWarehouse: e,
        });
    }

    onChangeCustomer(e) {
        this.setState({
            ShipEntryCustomer: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangePO(e) {
        this.setState({
            ShipEntryPO: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeOriginCountry(e) {
        this.setState({
            ShipEntryOriginCountry: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeDestinationCountry(e) {
        this.setState({
            ShipEntryDestinationCountry: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeETD(e) {
        this.setState({
            ShipEntryETD: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangePallets(e) {
        this.setState({
            ShipEntryPallets: e,
            UnsavedShipEntryChanges: true
        });
    }

    onChangeCases(e) {
        this.setState({
            ShipEntryCases: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeUnitCount(e) {
        this.setState({
            ShipEntryUnitCount: e,
            UnsavedShipEntryChanges: true
        });
    }
    onChangeGrossWeight(e) {
        this.setState({
          GrossWeight: e,
          UnsavedShipEntryChanges: true
        });
      }
      onChangeChargeableWeight(e) {
        this.setState({
          ChargeableWeight: e,
          UnsavedShipEntryChanges: true
        });
      }
    onChangeWeightUnits(e) {
        this.setState({
            ShipEntryWeightUnits: e,
            UnsavedShipEntryChanges: true
        });
    }


    setInitalLegsDict() {
        var ShipEntryLegs = {};
        ShipEntryLegs[0] = [{ 'Mode': null, 'Leg': '1' }]
        // ShipEntryLegs[0] = []

        this.setState({
            ShipEntryLegs: ShipEntryLegs,
        });

    }

    onClickAddLeg(e) {
        var currentLegsDict = this.state.ShipEntryLegs

        const legNumbersArray = currentLegsDict[0].map(item => item.Leg);
        try {
            var newLegNumber = findLowestMissingNumber(legNumbersArray)
        }
        catch (e) {
            var newLegNumber = 1
        }

        currentLegsDict[0].push({ 'Mode': null, 'Leg': String(newLegNumber) })

        this.handleAddNewLeg(currentLegsDict)

        this.forceUpdate()

    }

    handleAddNewLeg = (newLegsDict) => {
        this.setState({
            ShipEntryLegs: newLegsDict,
            UnsavedShipEntryChanges: true
        }, () => {
            // Do an API call with this.state.searchTerm
        });
    }

    onChangeLeg = (event, index, itemLocation, label, existinglocationname) => {

        if (label === 'Mode') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            // if (this.state.ShipEntryLegs[0].filter(item => item.Mode === event).length > 0) {
            //     window.alert('Duplicate mode entry, please use existing.')
            // }
            // else {
            //save warehouse name:
            var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs


            warehouseShipEntryLegsUpdated[0][index][label] = event

            this.setState({
                ShipEntryLegs: warehouseShipEntryLegsUpdated,
                UnsavedShipEntryChanges: true
            });
            // }
        }

        else if (label === 'Leg') {
            // find new warehouse name in existing state:
            //This will be changed to dropdown choice:
            if (this.state.ShipEntryLegs[0].filter(item => item.Leg === event).length > 0) {
                window.alert('Duplicate leg entry, please use existing.')
            }
            // else if (this.state.LocationToEditDict.Mode === event) {
            //     window.alert('Location already exists, please choose new location.')
            // }
            else {
                //save warehouse name:
                var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs
                // var keyToUpdate = warehouseShipEntryLegsUpdated.findIndex(item => item.Mode === existinglocationname)

                // warehouseShipEntryLegsUpdated[keyToUpdate][label] = event

                warehouseShipEntryLegsUpdated[0][index][label] = String(event)

                this.setState({
                    ShipEntryLegs: warehouseShipEntryLegsUpdated,
                    UnsavedShipEntryChanges: true
                });
            }
        }

        this.forceUpdate()
    };

    onClickDeleteLeg = (e, index) => {

        if (this.state.ShipEntryLegs[0].length > 1) {

            if (window.confirm('Delete Leg?')) {

                var warehouseShipEntryLegsUpdated = this.state.ShipEntryLegs

                warehouseShipEntryLegsUpdated[0].splice(index, 1)

                this.setState({
                    ShipEntryLegs: warehouseShipEntryLegsUpdated,
                    UnsavedShipEntryChanges: true
                });
                this.forceUpdate()

            }
        }
        else {
            window.alert('Error: Cannot delete all legs.')
        }
    }

    async onClickSaveShipEntry(e) {

        if (this.state.ShipEntryCustomer === null || this.state.ShipEntryCustomer === '') {
            window.alert('Please select a Customer.')
        }
        else if (this.state.ShipEntryOriginCountry === null || this.state.ShipEntryOriginCountry === '') {
            window.alert('Please select an Origin Country.')
        }
        else if (this.state.ShipEntryETD === null || this.state.ShipEntryETD === '') {
            window.alert('Please enter Planned Pickup.')
        }
        else if (this.state.ShipEntryLegs[0].some(e => e.Mode === null)) {
            window.alert('Please complete Mode selections.')
        }
        else if (this.state.ShipEntryLegs[0].some(e => e.Leg === null || e.Leg === '')) {
            window.alert('Please complete Leg Number selections.')
        }
        else if ((this.state.GrossWeight > 0 || this.state.ChargeableWeight > 0) && (this.state.ShipEntryWeightUnits === null || this.state.ShipEntryWeightUnits === '')) {
            window.alert('Please enter weight units.')
        }
        else {
            try {
                await this.props.ctrlTwrShipmentPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef),

                    formatNumberForAPI(this.state.ShipEntryCustomer),
                    formatNumberForAPI(this.state.ShipEntryOriginCountry),

                    formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryPO),

                    formatDateForUrlSearch(this.state.ShipEntryETD),

                    0,

                    formatNumberForAPI(this.state.ShipEntryPallets),
                    formatNumberForAPI(this.state.ShipEntryCases),
                    formatNumberForAPI(this.state.ShipEntryUnitCount),

                    formatStringForAPI(this.state.ShipEntryWeightUnits),
                    formatNumberForAPI(this.state.ShipEntryDestinationCountry),

                    formatAllTextNumbersSymbolsForAPI(this.state.SpecialRequirements),
                    formatNumberForAPI(this.state.GrossWeight),
                    formatNumberForAPI(this.state.ChargeableWeight),
                    formatNumberForAPI(this.state.Length),
                    formatNumberForAPI(this.state.Width),
                    formatNumberForAPI(this.state.Height),
                    formatStringForAPI(this.state.DimUnits),
                    formatAllTextNumbersSymbolsForAPI(this.state.Commodity)


                    
                )
            }
            catch (e) { }


            try{
                if (typeof (this.props.organizationSelection) === 'number' && this.state.NoteIsPrivate ) {
                    if ( this.state.ShipmentNote !== ''){
                        await this.props.ctrlTwrShipmentPrivateNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef), formatTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
                    }


                }
                else{
                    //not assigned to organization can only write public notes:
                    if ( this.state.ShipmentNote !== ''){
                        await this.props.ctrlTwrShipmentPublicNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef), formatTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
                    }
                }
            }
            catch(e){

            }

            for (let l in this.state.ShipEntryLegs[0]) {

                try {
                    await this.props.ctrlTwrShipmentLegsPost(
                        this.props.currentUserEmail,
                        this.props.userSignInData.AuthToken,
                        formatNumberForAPI(this.props.CtrlTwrGenerateProData[0].ProRef),
                        formatNumberForAPI(this.state.ShipEntryLegs[0][l].Leg),
                        formatNumberForAPI(this.state.ShipEntryLegs[0][l].Mode),
                        null,
                        null,
                        null

                    )
                }
                catch (e) { }

            }

            try {
                var customerObj = this.state.customerArray.filter(x => x.value === this.state.ShipEntryCustomer)
                var newProString = this.props.CtrlTwrGenerateProData[0].ProRef + '-  ' + customerObj[0].label

                this.setState({ NewlyCreatedPros: [...this.state.NewlyCreatedPros, newProString] })
            }
            catch (e) { }
        }
    }

    onClickResetShipEntry(e) {
        if (this.state.UnsavedShipEntryChanges === true) {
            if (window.confirm('You will lose unsaved changes, reset?')) {
                this.resetShipEntry()
            }
        }
        else {
            this.resetShipEntry()
        }
    }

    resetShipEntry() {
        this.setState({
            ShipEntryPO: '',
            ShipEntryCustomer: null,
            ShipEntryOriginCountry: null,
            ShipEntryDestinationCountry: null,
            ShipEntryETD: null,
            ShipEntryLegs: {},
            ShipEntryPallets: null,
            ShipEntryCases: null,
            ShipEntryUnitCount: null,
            ShipEntryWeightUnits: null,
            SpecialRequirements: null,
            GrossWeight: null,
            ChargeableWeight: null,
            Length: null,
            Width: null,
            Height: null,
            DimUnits: null,
            Commodity: null,

            UnsavedShipEntryChanges: false,
            ShipmentNote: '',
            NoteIsPrivate: true
        });

        this.setInitalLegsDict()
        this.forceUpdate()
    }



    onChangeCheckBoxOrgNote(e, value) {
        this.setState({
            NoteIsPrivate: value
        });
    }
    onChangeNote(e) {
        this.setState({
            ShipmentNote: e
        });
    }


    render() {
        let shipEntryDiv;
        if (this.props.isCtrlTwrGenerateProLoading) {
            shipEntryDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (this.props.isCtrlTwrModeRefLoading) {
            shipEntryDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (this.props.isCtrlTwrCountryLoading) {
            shipEntryDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.CtrlTwrGenerateProData[0]) !== 'undefined' && this.props.CtrlTwrGenerateProData != null) {

            shipEntryDiv =
                <Form fluid>

                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                            <FormGroup >




                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>PRO:</InputGroup.Addon>
                                    <Input value={this.props.CtrlTwrGenerateProData[0].ProRef} readOnly />
                                </InputGroup>


                                <div style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputPicker preventOverflow
                                        placeholder={'Customer...'}
                                        value={this.state.ShipEntryCustomer}
                                        onChange={(e) => { this.onChangeCustomer(e) }}
                                        data={this.state.customerArray}
                                        cleanable={true}
                                        block
                                    />
                                </div>

                                <div style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputPicker preventOverflow
                                        placeholder={'Origin Country...'}
                                        value={this.state.ShipEntryOriginCountry}
                                        onChange={(e) => { this.onChangeOriginCountry(e) }}
                                        data={this.props.CtrlTwrCountryData}
                                        cleanable={true}
                                        block
                                    />
                                </div>

                                <div style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputPicker preventOverflow
                                        placeholder={'Destination Country (Optional)...'}
                                        value={this.state.ShipEntryDestinationCountry}
                                        onChange={(e) => { this.onChangeDestinationCountry(e) }}
                                        data={this.props.CtrlTwrCountryData}
                                        cleanable={true}
                                        block
                                    />
                                </div>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Planned Pickup:</InputGroup.Addon>
                                    <DatePicker
                                        placeholder="Planned Pickup"
                                        onChange={(e) => { this.onChangeETD(e) }}
                                        value={this.state.ShipEntryETD}
                                        format="YYYY-MM-DD"
                                        showMeridian
                                        size="md"
                                        ranges={[
                                            {
                                                label: 'Now',
                                                value: new Date()
                                            }
                                        ]}
                                        style={{ width: '100%' }}
                                        oneTap
                                    />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>PO (Optional):</InputGroup.Addon>
                                    <Input value={this.state.ShipEntryPO} onChange={(e) => { this.onChangePO(e) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                </InputGroup>




                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Pallets (Optional):</InputGroup.Addon>
                                    <InputNumber style={{ width: '100%' }} value={this.state.ShipEntryPallets} onChange={(e) => this.onChangePallets(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Cases (Optional):</InputGroup.Addon>
                                    <InputNumber style={{ width: '100%' }} value={this.state.ShipEntryCases} onChange={(e) => this.onChangeCases(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                </InputGroup>

                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                    <InputGroup.Addon>Units (Optional):</InputGroup.Addon>
                                    <InputNumber style={{ width: '100%' }} value={this.state.ShipEntryUnitCount} onChange={(e) => this.onChangeUnitCount(e)} className={'custom-input-number'} scrollable={false} step={1} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                </InputGroup>

                                <Row>
                                    <Col md={8} sm={24}>
                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                            <InputGroup.Addon>Gross Wt (Optional):</InputGroup.Addon>
                                            <InputNumber style={{ width: '100%' }} value={this.state.GrossWeight} onChange={(e) => this.onChangeGrossWeight(e)} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                        </InputGroup>

                                    </Col>

                                    <Col md={8} sm={24}>
                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                            <InputGroup.Addon>Chargeable Wt (Optional):</InputGroup.Addon>
                                            <InputNumber style={{ width: '100%' }} value={this.state.ChargeableWeight} onChange={(e) => this.onChangeChargeableWeight(e)} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} scrollable={false} />
                                        </InputGroup>

                                    </Col>

                                    <Col md={8} sm={24}>
                                        <FormGroup>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Weight Units:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    // placeholder={label}
                                                    // placeholder={'Weight Units...'}
                                                    value={this.state.ShipEntryWeightUnits}
                                                    onChange={(e) => { this.onChangeWeightUnits(e) }}
                                                    data={weightUnits}
                                                    block
                                                    style={{ width: '100%' }}
                                                    cleanable={true}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>


                                {typeof (this.props.organizationSelection) === 'number' ?

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Notes (Optional):</InputGroup.Addon>
                                        <Checkbox checked={this.state.NoteIsPrivate} onChange={(e, value) => this.onChangeCheckBoxOrgNote(e, value)}>{this.state.NoteIsPrivate ? 'Private:  ' : 'Public:  '} </Checkbox>
                                        <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                                    </InputGroup>

                                    :

                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Notes (Optional):</InputGroup.Addon>
                                        <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />

                                    </InputGroup>

                                }



                                {(typeof (this.state.ShipEntryLegs[0]) !== 'undefined' && this.state.ShipEntryLegs != null) ?

                                    this.state.ShipEntryLegs[0].map((locationitem, index) => (

                                        <div style={{ marginBottom: '10px', width: '100%' }}>

                                            <Row>
                                                <Col md={6} sm={6}>

                                                    <InputGroup style={{ width: '100%' }}>
                                                        <InputGroup.Addon>Leg:</InputGroup.Addon>
                                                        <InputNumber
                                                            placeholder={'Leg...'}
                                                            value={locationitem['Leg']}
                                                            onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Leg', locationitem['Leg']) }}
                                                            // onInput={(e) => e.target.value = numbersInputMask(e)}
                                                            min={1}
                                                            block
                                                            step={1}
                                                            // defaultValue={1}
                                                            cleanable={false}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </InputGroup>

                                                </Col>

                                                <Col md={14} sm={14}>

                                                    <InputPicker preventOverflow
                                                        // placeholder={label}
                                                        placeholder={'Mode...'}
                                                        value={locationitem['Mode']}
                                                        onChange={(e) => { this.onChangeLeg(e, index, locationitem, 'Mode', locationitem['Mode']) }}
                                                        data={this.props.CtrlTwrModeRefData}
                                                        block
                                                        cleanable={true}
                                                    />
                                                </Col>

                                                <Col md={4} sm={4}>
                                                    <Button block onClick={(e) => { this.onClickDeleteLeg(e, index) }} appearance="default">Delete Leg</Button>
                                                </Col>

                                            </Row>
                                        </div>

                                    ))

                                    :

                                    <div></div>
                                }


                                < Row >
                                    <Col md={8} sm={8}>
                                        <Button style={{ marginBottom: '10px' }} onClick={(e) => this.onClickAddLeg(e)} appearance="default">+  Add Leg</Button>
                                    </Col>
                                </Row>

                                <Divider />

                                <FormGroup>
                                    <ButtonToolbar >
                                        <Button onClick={(e) => { this.onClickSaveShipEntry(e) }} appearance="primary">Save</Button>
                                        <Button onClick={(e) => { this.onClickResetShipEntry(e) }} appearance="default">Reset</Button>
                                    </ButtonToolbar>
                                </FormGroup>


                            </FormGroup>
                        </Panel>

                    </Col>

                </Form >



        }


        let newlyCreatedProsList;
        if (this.state.NewlyCreatedPros.length > 0) {
            newlyCreatedProsList =
                <Form fluid>

                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: '15px' }} header="New PRO's:" >
                            <List>
                                {this.state.NewlyCreatedPros.map(item => (
                                    <List.Item>{item}</List.Item>
                                ))
                                }
                            </List>
                        </Panel>
                    </Col>
                </Form>
        }
        else {
            newlyCreatedProsList = <div></div>
        }

        return (
            <div>
                <h3>
                    Control Tower - Shipment Entry
                </h3>

                <br />
                <br />


                {shipEntryDiv}

                {newlyCreatedProsList}



            </div >
        );

    }
}

export default withRouter(ShipmentEntry);





// <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
// <InputGroup.Addon>Cartons Recovered:</InputGroup.Addon>
// <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => this.onChange(e, 'AirlineRecoveryCartonsRecovered')} scrollable={false} />
// </InputGroup>