import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, FormGroup, DatePicker, Input } from 'rsuite';
import { tenthsDecimalInputMask, numbersInputMask, lettersNumbersDashesInputMask, zipCodeInputMask, numbersDashInputMask, textStringInputMask } from '../../../resources/logic/inputMasks';
import { LabelGenLengthUnits, LabelGenWeightUnits, UpsPackagingCode } from '../../../app/appConfig';
import CustomInputPicker from '../../componentsGeneral/Rsuite/InputPicker/CustomInputPicker';
import CustomInputPickerStringValues from '../../componentsGeneral/Rsuite/InputPicker/CustomInputPickerStringValues';
const styles = {
    marginBottom: '10px',
    width: '100%'
};
const stylesRequiredInput = {
    backgroundColor: '#FFE4E1'
};
const stylesInputPicker = {
    width: '100%'
};
const stylesRequiredAddOn = {
    backgroundColor: "rgb(255,228,225)"
};

const mountRef = React.createRef();

function AddPackageInputDiv({
    handleSave,
    onChange,
    resetRows,
    handleClose,
    onClickSaveAndAddPackage,
    onClickSaveAndAddProduct,
    state, ...props
}) {
    return (

        <Panel bordered style={{ marginBottom: '15px' }} >
            <div ref={mountRef}>

                <Col md={24} sm={24} style={styles}>

                    <FormGroup >

                        {/* <InputGroup style={styles}>
                            <InputGroup.Addon>Description:</InputGroup.Addon>
                            <Input value={state.new_Package_Description}
                                onChange={(e) => { onChange(e, 'new_Package_Description') }}
                                onInput={(e) => e.target.value = textStringInputMask(e)}
                            />
                        </InputGroup> */}

                        <InputGroup style={styles}>
                            <InputGroup.Addon>Packaging Code (Required):</InputGroup.Addon>
                            <CustomInputPickerStringValues
                                selectionValue={state.new_Package_Packaging_Code}
                                onChange={onChange}
                                menuData={UpsPackagingCode}
                                stateName={'new_Package_Packaging_Code'}
                                color={'#FFE4E1'}
                                {...props}
                            />

                        </InputGroup>

                        <InputGroup style={styles}>
                            <InputGroup.Addon>Dimensions UOM Code (Required):</InputGroup.Addon>
                            <CustomInputPickerStringValues
                                selectionValue={state.new_Package_Dimensions_UOM_Code}
                                onChange={onChange}
                                menuData={LabelGenLengthUnits}
                                stateName={'new_Package_Dimensions_UOM_Code'}
                                color={'#FFE4E1'}
                                {...props}
                            />
                        </InputGroup>

                        <InputGroup style={styles}>
                            <InputGroup.Addon>Length (Required):</InputGroup.Addon>
                            <Input value={state.new_Package_Dimensions_Length}
                                onChange={(e) => { onChange(e, 'new_Package_Dimensions_Length') }}
                                onInput={(e) => e.target.value = tenthsDecimalInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>

                        <InputGroup style={styles}>
                            <InputGroup.Addon>Width (Required):</InputGroup.Addon>
                            <Input value={state.new_Package_Dimensions_Width}
                                onChange={(e) => { onChange(e, 'new_Package_Dimensions_Width') }}
                                onInput={(e) => e.target.value = tenthsDecimalInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>

                        <InputGroup style={styles}>
                            <InputGroup.Addon>Height (Required):</InputGroup.Addon>
                            <Input value={state.new_Package_Dimensions_Height}
                                onChange={(e) => { onChange(e, 'new_Package_Dimensions_Height') }}
                                onInput={(e) => e.target.value = tenthsDecimalInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>

                        <InputGroup style={styles}>
                            <InputGroup.Addon>Weight UOM Code (Required):</InputGroup.Addon>
                            <CustomInputPickerStringValues
                                selectionValue={state.new_Package_PackageWeight_UOM_Code}
                                onChange={onChange}
                                menuData={LabelGenWeightUnits}
                                stateName={'new_Package_PackageWeight_UOM_Code'}
                                color={'#FFE4E1'}
                                {...props}
                            />
                        </InputGroup>

                        <InputGroup style={styles}>
                            <InputGroup.Addon>Weight (Required):</InputGroup.Addon>
                            <Input value={state.new_Package_PackageWeight_Weight}
                                onChange={(e) => { onChange(e, 'new_Package_PackageWeight_Weight') }}
                                onInput={(e) => e.target.value = tenthsDecimalInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>




                    </FormGroup>





                </Col>

                {state.PackageDict.length > 0 ?
                    <div>
                        <Button onClick={handleSave} appearance="primary">
                            Save
                        </Button>
                        <Button onClick={handleClose} appearance="subtle">
                            Cancel
                        </Button>
                    </div>
                    :
                    <div>
                        <Button onClick={onClickSaveAndAddPackage} appearance="primary" style={{ marginRight: '5px' }}>
                            Add Another Package
                        </Button>
                        <Button onClick={onClickSaveAndAddProduct} appearance="primary">
                            Add Product
                        </Button>
                    </div>
                }

            </div>
        </Panel>

    );
}


export default AddPackageInputDiv;