import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Tree } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventorySkuWarehouseLocationDataElements from '../../../dataComponentsInventory/InventorySkuWarehouseLocationDataElements';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import { hawbStatus } from '../../../../app/appConfig';
import RSearchInputNumberAsString from '../../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import RDatePickerRange from '../../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import queryString from 'query-string';
import { queryStringParse, replaceNegativeWithZero, isNumeric, formatStringForAPI, uniqueArray, convertNanToZero } from '../../../../resources/logic/functions';
import RSelectPickerCreatable from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPickerCreatable';

const tableIdCol = "SkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class Warehouse extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            //Search Values
            isFilterShown: true,
            searchContainer: '',
            searchSKU: '',
            searchStatus: 1,
            searchCustomerRef: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,

        };

        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeSearchContainer = this.onChangeSearchContainer.bind(this);
        this.onChangeSearchSKU = this.onChangeSearchSKU.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);
        this.onChangeSearchCustomerRef = this.onChangeSearchCustomerRef.bind(this)
        this.onChangeSearchRecoveryDateStart = this.onChangeSearchRecoveryDateStart.bind(this);
        this.onChangeSearchRecoveryDateEnd = this.onChangeSearchRecoveryDateEnd.bind(this);



        //Table buttons
        this.onClickDetails = this.onClickDetails.bind(this);


    }




    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainer = queryStringParse(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        if (paramContainer != null) {
            this.onChangeSearchContainer(paramContainer)
        }

        try {
            var paramSKU = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        if (paramSKU != null) {
            this.onChangeSearchSKU(paramSKU)
        }

        try {
            var paramStatus = queryStringParse(values.skustatusid)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }


        try {
            var paramCustomerRefID = queryStringParse(values.customerrefid)
        }
        catch (e) {
            var paramCustomerRefID = null
        }
        if (paramCustomerRefID != null) {
            this.onChangeSearchCustomerRef(paramCustomerRefID)
        }

        try {
            var date = queryStringParse(values.recoverydatestart)
            if (date === null) {
                var paramRecoveryDateStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateStart = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        if (paramRecoveryDateStart != null) {
            this.onChangeSearchRecoveryDateStart(paramRecoveryDateStart)
        }

        try {
            var date = queryStringParse(values.recoverydateend)
            if (date === null) {
                var paramRecoveryDateEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateEnd = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }
        this.onChangeSearchRecoveryDateEnd(paramRecoveryDateEnd)

    }


    //Table on click details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'skudetails',
                search: 'skuid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }



    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const currentCheckedRow = this.props.InventorySkuWarehouseLocationData.filter(x => value === x[tableIdCol])



        const currentStatusID = currentCheckedRow[0]['CurrentSkuStatusID']

        if (currentStatusID === 1) {
            const { checkedKeys } = this.state;
            const nextCheckedKeys = checked
                ? [...checkedKeys, value]
                : checkedKeys.filter(item => item !== value);
            this.setState({
                checkedKeys: nextCheckedKeys
            });
        }
        else {
            window.alert('Nothing remaining to load.')
        }



        // // const remainingToLoad = currentCheckedRow[0]['CasesReceived'] - currentCheckedRow[0]['CasesLoaded']
        // const remainingToLoad = currentCheckedRow[0]['InWarehouseCases']
        // if (remainingToLoad < 1) {
        //     window.alert('Nothing remaining to load.')
        // }
        // else {


        // }
    }


    //Filter
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }
    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,
            searchCustomerRef: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchContainer: '',
            searchSKU: '',
        });

        var sDT = null
        var eDT = null


        if (this.state.searchRecoveryDateStart === null) {
            sDT = null
        }
        else {
            sDT = "'" + moment(this.state.searchRecoveryDateStart).format('YYYY-MM-DD') + "'"
        }
        if (this.state.searchRecoveryDateEnd === null) {
            eDT = null
        }
        else {
            eDT = "'" + moment(this.state.searchRecoveryDateEnd).format('YYYY-MM-DD') + "'"
        }

        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                var searchCustomerRef = this.state.searchCustomerRef
                var searchCarrier = this.state.searchCarrier
                var serachAssignedDriver = this.state.serachAssignedDriver
                var searchMawbColorCode = this.state.searchMawbColorCode
                this.props.history.push({
                    search:
                        'skustatusid=' + searchStatus + '&' +
                        'customerrefid=' + searchCustomerRef + '&' +
                        // 'carrier=' + searchCarrier + '&' +
                        // 'assigneddriver=' + serachAssignedDriver + '&' +
                        // 'mawbcolorcode=' + searchMawbColorCode + '&' +
                        'recoverydatestart=' + sDT + '&' +
                        'recoverydateend=' + eDT
                });
            }
            catch (e) { }
        }
    }


    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchCustomerRef: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null
        });

    }

    onChangeSearchContainer(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchContainer: e,
            searchSKU: '',
        });
    }

    onChangeSearchSKU(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchSKU: e,
            searchContainer: '',
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCustomerRef(e) {
        this.setState({
            searchCustomerRef: e
        });
    }


    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }

    onChangeSearchMinETADischargeStart(e) {
        this.setState({
            searchMinETADischargeStart: e
        });
    }
    onChangeSearchMinETADischargeEnd(e) {
        this.setState({
            searchMinETADischargeEnd: e
        });
    }

    onChangeSearchRecoveryDateStart(e) {
        this.setState({
            searchRecoveryDateStart: e
        });
    }
    onChangeSearchRecoveryDateEnd(e) {
        this.setState({
            searchRecoveryDateEnd: e
        });
    }
    onChangeSearchAssigedDriver(e) {
        this.setState({
            serachAssignedDriver: e
        });
    }
    onChangeSearchMawbColorCode(e) {
        this.setState({
            searchMawbColorCode: e
        });
    }


    //Table buttons
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData["WarehouseLocationID"]
            var paramWarehouseID = rowData["WarehouseID"]
            history.push({
                pathname: 'warehouselocationdetails',
                search: 'warehouselocationid=' + paramID + '&' +
                'warehouseid=' + paramWarehouseID
            });
        }
        catch (e) {
        }
    }




    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;



        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown ){
            // && !this.props.isTransConsigneeLoading) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        {/* <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Container',
                                this.onChangeSearchContainer,
                                this.props.history,
                                'container',
                                this.state.searchContainer
                            )}
                        </Row> */}

                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'SKU',
                                this.onChangeSearchSKU,
                                this.props.history,
                                'sku',
                                this.state.searchSKU
                            )}
                        </Row>

                        {/* <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Warehouse Location',
                                this.onChangeSearchContainer,
                                this.props.history,
                                'container',
                                this.state.searchContainer
                            )}
                        </Row> */}

                    </Panel>
                    
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }




        let shipmentPoTable;
        let tableDataDiv;
        if (this.props.isInventorySkuWarehouseLocationLoading) {
            shipmentPoTable = <div></div>
            tableDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuWarehouseLocationData) !== 'undefined' && this.props.InventorySkuWarehouseLocationData != null) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPoStatusID = queryStringParse(values.skustatusid)
            }
            catch (e) {
                var paramPoStatusID = null
            }

            shipmentPoTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.InventorySkuWarehouseLocationData,     //   tableData,
                InventorySkuWarehouseLocationDataElements,       //   DataElements,
                this.props.isInventorySkuWarehouseLocationLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                false, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick

            )



            tableDataDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :

                    <div>

                        <Row>

                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>

                                <ExcelSheet data={this.props.InventorySkuWarehouseLocationData} name="Warehouse">

                                    {
                                        InventorySkuWarehouseLocationDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>

                        </Row>
                    </div>
                }

            </div>


        }
        else {
            shipmentPoTable = <div></div>
            tableDataDiv = <div></div>
        }




        return (
            <div>
                <h3>
                    Inventory Management - Warehouse
                </h3>

                {hideShowSearch}
                {searchDiv}

                <PanelGroup>

                </PanelGroup>

                {tableDataDiv}
                {shipmentPoTable}

            </div>
        );

    }
}

export default withRouter(Warehouse);
