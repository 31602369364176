/* ################################### SIGNIN ACTIONS TYPES ################################### */
export const APP_SIGNIN = 'APP_SIGNIN';
export const APP_SIGNIN_COMPLETE = 'APP_SIGNIN_COMPLETE';
export const APP_SIGNIN_ERROR = 'APP_SIGNIN_ERROR';

export const GET_PAGE_CONFIG = 'GET_PAGE_CONFIG';
export const COMPLETE_PAGE_CONFIG = 'COMPLETE_PAGE_CONFIG';
export const ERROR_PAGE_CONFIG = 'ERROR_PAGE_CONFIG';

export const USER_ORGANIZATION_GET = 'USER_ORGANIZATION_GET';
export const USER_ORGANIZATION_COMPLETE = 'USER_ORGANIZATION_COMPLETE';
export const USER_ORGANIZATION_ERROR = 'USER_ORGANIZATION_ERROR';

export const USER_WAREHOUSES_GET = 'USER_WAREHOUSES_GET';
export const USER_WAREHOUSES_COMPLETE = 'USER_WAREHOUSES_COMPLETE';
export const USER_WAREHOUSES_ERROR = 'USER_WAREHOUSES_ERROR';

export const POST_USER_WAREHOUSES_GET = 'POST_USER_WAREHOUSES_GET';
export const POST_USER_WAREHOUSES_COMPLETE = 'POST_USER_WAREHOUSES_COMPLETE';
export const POST_USER_WAREHOUSES_ERROR = 'POST_USER_WAREHOUSES_ERROR';

export const USER_WAREHOUSE_LOCATIONS_GET = 'USER_WAREHOUSE_LOCATIONS_GET';
export const USER_WAREHOUSE_LOCATIONS_COMPLETE = 'USER_WAREHOUSE_LOCATIONS_COMPLETE';
export const USER_WAREHOUSE_LOCATIONS_ERROR = 'USER_WAREHOUSE_LOCATIONS_ERROR';

export const USER_ADMIN_CUSTOMERS_ALL_GET = 'USER_ADMIN_CUSTOMERS_ALL_GET';
export const USER_ADMIN_CUSTOMERS_ALL_COMPLETE = 'USER_ADMIN_CUSTOMERS_ALL_COMPLETE';
export const USER_ADMIN_CUSTOMERS_ALL_ERROR = 'USER_ADMIN_CUSTOMERS_ALL_ERROR';

export const USER_CUSTOMERS_GET = 'USER_CUSTOMERS_GET';
export const USER_CUSTOMERS_COMPLETE = 'USER_CUSTOMERS_COMPLETE';
export const USER_CUSTOMERS_ERROR = 'USER_CUSTOMERS_ERROR';

export const POST_USER_CUSTOMERS_GET = 'POST_USER_CUSTOMERS_GET';
export const POST_USER_CUSTOMERS_COMPLETE = 'POST_USER_CUSTOMERS_COMPLETE';
export const POST_USER_CUSTOMERS_ERROR = 'POST_USER_CUSTOMERS_ERROR';

export const USER_LABELGEN_CARRIER_GET = 'USER_LABELGEN_CARRIER_GET';
export const USER_LABELGEN_CARRIER_COMPLETE = 'USER_LABELGEN_CARRIER_COMPLETE';
export const USER_LABELGEN_CARRIER_ERROR = 'USER_LABELGEN_CARRIER_ERROR';

export const POST_USER_LABELGEN_CARRIER_GET = 'POST_USER_LABELGEN_CARRIER_GET';
export const POST_USER_LABELGEN_CARRIER_COMPLETE = 'POST_USER_LABELGEN_CARRIER_COMPLETE';
export const POST_USER_LABELGEN_CARRIER_ERROR = 'POST_USER_LABELGEN_CARRIER_ERROR';

export const USER_LABELGEN_CARRIER_SERVICE_GET = 'USER_LABELGEN_CARRIER_SERVICE_GET';
export const USER_LABELGEN_CARRIER_SERVICE_COMPLETE = 'USER_LABELGEN_CARRIER_SERVICE_COMPLETE';
export const USER_LABELGEN_CARRIER_SERVICE_ERROR = 'USER_LABELGEN_CARRIER_SERVICE_ERROR';

export const USER_MODULES_GET = 'USER_MODULES_GET';
export const USER_MODULES_COMPLETE = 'USER_MODULES_COMPLETE';
export const USER_MODULES_ERROR = 'USER_MODULES_ERROR';

export const POST_USER_MODULES_GET = 'POST_USER_MODULES_GET';
export const POST_USER_MODULES_COMPLETE = 'POST_USER_MODULES_COMPLETE';
export const POST_USER_MODULES_ERROR = 'POST_USER_MODULES_ERROR';

export const ACCOUNT_DETAILS_USER_WAREHOUSES_GET = 'ACCOUNT_DETAILS_USER_WAREHOUSES_GET';
export const ACCOUNT_DETAILS_USER_WAREHOUSES_COMPLETE = 'ACCOUNT_DETAILS_USER_WAREHOUSES_COMPLETE';
export const ACCOUNT_DETAILS_USER_WAREHOUSES_ERROR = 'ACCOUNT_DETAILS_USER_WAREHOUSES_ERROR';

export const ACCOUNT_DETAILS_USER_MODULES_GET = 'ACCOUNT_DETAILS_USER_MODULES_GET';
export const ACCOUNT_DETAILS_USER_MODULES_COMPLETE = 'ACCOUNT_DETAILS_USER_MODULES_COMPLETE';
export const ACCOUNT_DETAILS_USER_MODULES_ERROR = 'ACCOUNT_DETAILS_USER_MODULES_ERROR';

export const ACCOUNT_DETAILS_USER_CUSTOMERS_GET = 'ACCOUNT_DETAILS_USER_CUSTOMERS_GET';
export const ACCOUNT_DETAILS_USER_CUSTOMERS_COMPLETE = 'ACCOUNT_DETAILS_USER_CUSTOMERS_COMPLETE';
export const ACCOUNT_DETAILS_USER_CUSTOMERS_ERROR = 'ACCOUNT_DETAILS_USER_CUSTOMERS_ERROR';

/* ################################### SIGN OUT ACTIONS TYPES ################################### */
export const SIGN_OUT = 'SIGN_OUT';


/* ################################### ADD USER ACTIONS TYPES ################################### */
export const USER_SIGNUP_POST = 'USER_SIGNUP_POST';
export const USER_SIGNUP_COMPLETE = 'USER_SIGNUP_COMPLETE';
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR';

export const GET_ALL_USERS_GET = 'GET_ALL_USERS_GET';
export const GET_ALL_USERS_COMPLETE = 'GET_ALL_USERS_COMPLETE';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';

export const POST_APPROVE_USER_GET = 'POST_APPROVE_USER_GET';
export const POST_APPROVE_USER_COMPLETE = 'POST_APPROVE_USER_COMPLETE';
export const POST_APPROVE_USER_ERROR = 'POST_APPROVE_USER_ERROR';

export const POST_DELETE_USER_GET = 'POST_DELETE_USER_GET';
export const POST_DELETE_USER_COMPLETE = 'POST_DELETE_USER_COMPLETE';
export const POST_DELETE_USER_ERROR = 'POST_DELETE_USER_ERROR';

export const POST_USER_ACCOUNT_EDIT_GET = 'POST_USER_ACCOUNT_EDIT_GET';
export const POST_USER_ACCOUNT_EDIT_COMPLETE = 'POST_USER_ACCOUNT_EDIT_COMPLETE';
export const POST_USER_ACCOUNT_EDIT_ERROR = 'POST_USER_ACCOUNT_EDIT_ERROR';

export const USER_COMPANY_GET = 'USER_COMPANY_GET';
export const USER_COMPANY_COMPLETE = 'USER_COMPANY_COMPLETE';
export const USER_COMPANY_ERROR = 'USER_COMPANY_ERROR';

export const USER_STORE_GET = 'USER_STORE_GET';
export const USER_STORE_COMPLETE = 'USER_STORE_COMPLETE';
export const USER_STORE_ERROR = 'USER_STORE_ERROR';

export const USER_POSTATUS_GET = 'USER_POSTATUS_GET';
export const USER_POSTATUS_COMPLETE = 'USER_POSTATUS_COMPLETE';
export const USER_POSTATUS_ERROR = 'USER_POSTATUS_ERROR';

export const PENDING_USER_GET = 'PENDING_USER_GET';
export const PENDING_USER_COMPLETE = 'PENDING_USER_COMPLETE';
export const PENDING_USER_ERROR = 'PENDING_USER_ERROR';

export const USER_UPDATE_POST = 'USER_UPDATE_POST';
export const USER_UPDATE_COMPLETE = 'USER_UPDATE_COMPLETE';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_ACCOUNT_GET = 'USER_ACCOUNT_GET';
export const USER_ACCOUNT_COMPLETE = 'USER_ACCOUNT_COMPLETE';
export const USER_ACCOUNT_ERROR = 'USER_ACCOUNT_ERROR';

// export const USER_DELETE_POST = 'USER_DELETE_POST';
// export const USER_DELETE_COMPLETE = 'USER_DELETE_COMPLETE';
// export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

export const USER_PERMISSIONS_POST = 'USER_PERMISSIONS_POST';
export const USER_PERMISSIONS_COMPLETE = 'USER_PERMISSIONS_COMPLETE';
export const USER_PERMISSIONS_ERROR = 'USER_PERMISSIONS_ERROR';


export const USER_AWS_COGNITO_DELETE_USER_POST_GET = 'USER_AWS_COGNITO_DELETE_USER_POST_GET';
export const USER_AWS_COGNITO_DELETE_USER_POST_COMPLETE = 'USER_AWS_COGNITO_DELETE_USER_POST_COMPLETE';
export const USER_AWS_COGNITO_DELETE_USER_POST_ERROR = 'USER_AWS_COGNITO_DELETE_USER_POST_ERROR';


export const USER_AWS_COGNITO_INVITE_USER_POST_GET = 'USER_AWS_COGNITO_INVITE_USER_POST_GET';
export const USER_AWS_COGNITO_INVITE_USER_POST_COMPLETE = 'USER_AWS_COGNITO_INVITE_USER_POST_COMPLETE';
export const USER_AWS_COGNITO_INVITE_USER_POST_ERROR = 'USER_AWS_COGNITO_INVITE_USER_POST_ERROR';

export const USER_INVITE_NEW_USER_POST = 'USER_INVITE_NEW_USER_POST';
export const USER_INVITE_NEW_USER_POST_COMPLETE = 'USER_INVITE_NEW_USER_POST_COMPLETE';
export const USER_INVITE_NEW_USER_POST_ERROR = 'USER_INVITE_NEW_USER_POST_ERROR';

/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';




/* ################################### ADMIN ACTIONS TYPES ################################### */

export const ADMIN_POST_NEW_SEAPORT_GET = 'ADMIN_POST_NEW_SEAPORT_GET';
export const ADMIN_POST_NEW_SEAPORT_COMPLETE = 'ADMIN_POST_NEW_SEAPORT_COMPLETE';
export const ADMIN_POST_NEW_SEAPORT_ERROR = 'ADMIN_POST_NEW_SEAPORT_ERROR';

export const ADMIN_POST_NEW_CUSTOMER_GET = 'ADMIN_POST_NEW_CUSTOMER_GET';
export const ADMIN_POST_NEW_CUSTOMER_COMPLETE = 'ADMIN_POST_NEW_CUSTOMER_COMPLETE';
export const ADMIN_POST_NEW_CUSTOMER_ERROR = 'ADMIN_POST_NEW_CUSTOMER_ERROR';








