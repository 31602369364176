import { connect } from 'react-redux';
import React, { Component } from 'react';
import QuoteEntry from './QuoteEntry'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatStringForAPI, formatDateForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isCtrlTwrGenerateProLoading:                            store.ctrlTwrReducer.isCtrlTwrGenerateProLoading,
    CtrlTwrGenerateProData:                                 store.ctrlTwrReducer.CtrlTwrGenerateProData,

    isCtrlTwrModeRefLoading:                                store.ctrlTwrReducer.isCtrlTwrModeRefLoading,
    CtrlTwrModeRefData:                                     store.ctrlTwrReducer.CtrlTwrModeRefData,

    isCtrlTwrCountryLoading:                                store.ctrlTwrReducer.isCtrlTwrCountryLoading,
    CtrlTwrCountryData:                                     store.ctrlTwrReducer.CtrlTwrCountryData,

    CtrlTwrQuotingShipmentPostSuccessful:                   store.ctrlTwrReducer.CtrlTwrQuotingShipmentPostSuccessful,

    CtrlTwrQuotingShipmentLegsPostSuccessful:                   store.ctrlTwrReducer.CtrlTwrQuotingShipmentLegsPostSuccessful,

    CtrlTwrShipmentNotesPrivatePostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPrivatePostSuccessful,
    CtrlTwrShipmentNotesPublicPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPublicPostSuccessful,

    isCtrlTwrQuotingLineItemLoading: store.ctrlTwrReducer.isCtrlTwrQuotingLineItemLoading,
    CtrlTwrQuotingLineItemData: store.ctrlTwrReducer.CtrlTwrQuotingLineItemData,

    isCtrlTwrQuotingLineItemUomLoading: store.ctrlTwrReducer.isCtrlTwrQuotingLineItemUomLoading,
    CtrlTwrQuotingLineItemUomData: store.ctrlTwrReducer.CtrlTwrQuotingLineItemUomData,

    isCtrlTwrQuotingLineItemRateTypeLoading: store.ctrlTwrReducer.isCtrlTwrQuotingLineItemRateTypeLoading,
    CtrlTwrQuotingLineItemRateTypeData: store.ctrlTwrReducer.CtrlTwrQuotingLineItemRateTypeData,

    CtrlTwrShipmentLegsLineItemPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsLineItemPostSuccessful
});

const mapDispatchToProps = dispatch =>
({

    ctrlTwrGenerateProGet:( _email, _authToken  ) =>
    {
        dispatch( actions.ctrlTwrGenerateProGet( _email, _authToken ) )
    },

    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrCountryGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.ctrlTwrCountryGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrQuotingShipmentPost: (_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _ShipmentQuoteActive, _QuotingStatusID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity) => {
        dispatch(actions.ctrlTwrQuotingShipmentPost(_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _ShipmentQuoteActive, _QuotingStatusID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity))
    },

    ctrlTwrQuotingShipmentLegsPost: (_email, _authToken, _LegID, _DeleteLeg, _ProRef, _LegNumber, _ModeID, _Carrier, _Shipper_Name, _Origin_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode) => {
        dispatch(actions.ctrlTwrQuotingShipmentLegsPost(_email, _authToken, _LegID, _DeleteLeg, _ProRef, _LegNumber, _ModeID, _Carrier, _Shipper_Name, _Origin_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode))
    },

    ctrlTwrQuotingLineItemGet: (_email, _authToken, _ModeID, _QuoteLineItemID) => {
        dispatch(actions.ctrlTwrQuotingLineItemGet(_email, _authToken, _ModeID, _QuoteLineItemID))
    },

    ctrlTwrQuotingLineItemUomGet: (_email, _authToken, _ModeID, _QuoteLineItemUomID) => {
        dispatch(actions.ctrlTwrQuotingLineItemUomGet(_email, _authToken, _ModeID, _QuoteLineItemUomID))
    },
    ctrlTwrQuotingLineItemRateTypeGet: (_email, _authToken, _ModeID, _QuoteLineItemRateTypeID) => {
        dispatch(actions.ctrlTwrQuotingLineItemRateTypeGet(_email, _authToken, _ModeID, _QuoteLineItemRateTypeID))
    },

    ctrlTwrQuotingShipmentLegsLineItemPost: (_email, _authToken, _LegLineItemID, _LegID, _QuoteLineItemID, _ProRef, _LegNumber, _ModeID, _DeleteLineItem, _SellRate, _BuyRate, _QuoteLineItemUom, _QuoteLineItemRateType, _LineItemNotes, _LineItemSeq) => {
        dispatch(actions.ctrlTwrQuotingShipmentLegsLineItemPost(_email, _authToken, _LegLineItemID, _LegID, _QuoteLineItemID, _ProRef, _LegNumber, _ModeID, _DeleteLineItem, _SellRate, _BuyRate, _QuoteLineItemUom, _QuoteLineItemRateType, _LineItemNotes, _LineItemSeq))
    },

    ctrlTwrShipmentPrivateNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete) => {
        dispatch(actions.ctrlTwrShipmentPrivateNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete))
    },
    ctrlTwrShipmentPublicNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete) => {
        dispatch(actions.ctrlTwrShipmentPublicNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete))
    },


});

class QuoteEntryContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getNewProRef();
        this.handleUrlParam();



    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.CtrlTwrQuotingShipmentPostSuccessful !== this.props.CtrlTwrQuotingShipmentPostSuccessful && this.props.CtrlTwrQuotingShipmentPostSuccessful) {
            this.getNewProRef();
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }
    }

    getNewProRef = () => {
        this.props.ctrlTwrGenerateProGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken)
    }

    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        // try {
        //     var paramPO = formatStringForAPI(values.po)
        // }
        // catch (e) {
        //     var paramPO = null
        // }


        // if( paramPO == null && paramContainer == null && paramSealNo == null && paramDepartDateStart == null && paramDepartDateEnd == null ){

        // }
        // else{
        //     this.props.transQuoteEntryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainer, null, null, null, null, null, paramPO, paramDepartDateStart, paramDepartDateEnd, null, null, paramSealNo, null, null, null  )
        // }

        this.props.ctrlTwrModeRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrCountryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        
        this.props.ctrlTwrQuotingLineItemGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null)

        this.props.ctrlTwrQuotingLineItemUomGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)
        this.props.ctrlTwrQuotingLineItemRateTypeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)

    }



    render() {

        return (

            <QuoteEntry
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                organizationSelection={this.props.organizationSelection}

                isCtrlTwrGenerateProLoading={this.props.isCtrlTwrGenerateProLoading}
                CtrlTwrGenerateProData={this.props.CtrlTwrGenerateProData}      
                
                isCtrlTwrModeRefLoading={this.props.isCtrlTwrModeRefLoading}
                CtrlTwrModeRefData={this.props.CtrlTwrModeRefData}

                isCtrlTwrCountryLoading={this.props.isCtrlTwrCountryLoading}
                CtrlTwrCountryData={this.props.CtrlTwrCountryData}
                

                CtrlTwrQuotingShipmentPostSuccessful={this.props.CtrlTwrQuotingShipmentPostSuccessful}
                ctrlTwrQuotingShipmentPost={this.props.ctrlTwrQuotingShipmentPost}

                CtrlTwrQuotingShipmentLegsPostSuccessful={this.props.CtrlTwrQuotingShipmentLegsPostSuccessful}
                ctrlTwrQuotingShipmentLegsPost={this.props.ctrlTwrQuotingShipmentLegsPost}

                isCtrlTwrQuotingLineItemLoading={this.props.isCtrlTwrQuotingLineItemLoading}
                CtrlTwrQuotingLineItemData={this.props.CtrlTwrQuotingLineItemData}

                isCtrlTwrQuotingLineItemUomLoading={this.props.isCtrlTwrQuotingLineItemUomLoading}
                CtrlTwrQuotingLineItemUomData={this.props.CtrlTwrQuotingLineItemUomData}
            
                isCtrlTwrQuotingLineItemRateTypeLoading={this.props.isCtrlTwrQuotingLineItemRateTypeLoading}
                CtrlTwrQuotingLineItemRateTypeData={this.props.CtrlTwrQuotingLineItemRateTypeData}



                CtrlTwrShipmentLegsLineItemPostSuccessful={this.props.CtrlTwrShipmentLegsLineItemPostSuccessful}
                ctrlTwrQuotingShipmentLegsLineItemPost={this.props.ctrlTwrQuotingShipmentLegsLineItemPost}

                CtrlTwrShipmentNotesPrivatePostSuccessful={this.props.CtrlTwrShipmentNotesPrivatePostSuccessful}
                CtrlTwrShipmentNotesPublicPostSuccessful={this.props.CtrlTwrShipmentNotesPublicPostSuccessful}
                ctrlTwrShipmentPrivateNotesPost={this.props.ctrlTwrShipmentPrivateNotesPost}
                ctrlTwrShipmentPublicNotesPost={this.props.ctrlTwrShipmentPublicNotesPost}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteEntryContainer));



