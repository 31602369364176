import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import QuoteShipmentDetailsContainer from '../../../components/componentsControlTower/QuoteShipmentDetails/QuoteShipmentDetailsContainer';

class ctrlTwrQuoteShipmentDetailsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

    }

    render() {

        return (
            <AnimatedView>
                <QuoteShipmentDetailsContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection} organizationSelection={this.props.organizationSelection}/>
            </AnimatedView>

        );
    }
}

export default ctrlTwrQuoteShipmentDetailsView;
