import { connect } from 'react-redux';
import React, { Component } from 'react';
import WarehouseLocationDetails from './WarehouseLocationDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryContainerLoading: store.inventoryReducer.isInventoryContainerLoading,
    InventoryContainerData: store.inventoryReducer.InventoryContainerData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    isInventorySkuLoading: store.inventoryReducer.isInventorySkuLoading,
    InventorySkuData: store.inventoryReducer.InventorySkuData,

    isInventorySkuRecoveryLoading: store.inventoryReducer.isInventorySkuRecoveryLoading,
    InventorySkuRecoveryData: store.inventoryReducer.InventorySkuRecoveryData,

    isInventorySkuRecoveryHistoryLoading: store.inventoryReducer.isInventorySkuRecoveryHistoryLoading,
    InventorySkuRecoveryHistoryData: store.inventoryReducer.InventorySkuRecoveryHistoryData,

    isInventoryContainerHistoryLoading: store.inventoryReducer.isInventoryContainerHistoryLoading,
    InventoryContainerHistoryData: store.inventoryReducer.InventoryContainerHistoryData,


    isInventoryWarehouseLocationLoading: store.inventoryReducer.isInventoryWarehouseLocationLoading,
    InventoryWarehouseLocationData: store.inventoryReducer.InventoryWarehouseLocationData,

});

const mapDispatchToProps = dispatch =>
({
    inventoryWarehouseLocationGet: (_email, _authToken, _WarehouseID, _CustomerID, _WarehouseLocationID,) => {
        dispatch(inventoryActions.inventoryWarehouseLocationGet(_email, _authToken, _WarehouseID, _CustomerID, _WarehouseLocationID,))
    },





    inventoryContainerGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryContainerGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },
    inventorySkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuRecoverGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventorySkuRecoverHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventoryContainerHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _SkuID, _SKU,) => {
        dispatch(inventoryActions.inventoryContainerHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _SkuID, _SKU,))
    }, 

});

class WarehouseLocationDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramWarehouseLocationID = formatStringForAPI(values.warehouselocationid)
        }
        catch (e) {
            var paramWarehouseLocationID = null
        }
        try {
            var paramWarehouseID = formatStringForAPI(values.warehouseid)
        }
        catch (e) {
            var paramWarehouseID = null
        }
        
        this.props.inventoryWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramWarehouseLocationID) 
        this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null , null, null, paramWarehouseLocationID)
    }



    render() {


        return (

            <WarehouseLocationDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventoryContainerLoading={this.props.isInventoryContainerLoading}
                InventoryContainerData={this.props.InventoryContainerData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}

                isInventorySkuLoading={this.props.isInventorySkuLoading}
                InventorySkuData={this.props.InventorySkuData}

                isInventorySkuRecoveryLoading={this.props.isInventorySkuRecoveryLoading}
                InventorySkuRecoveryData={this.props.InventorySkuRecoveryData}

                isInventorySkuRecoveryHistoryLoading={this.props.isInventorySkuRecoveryHistoryLoading}
                InventorySkuRecoveryHistoryData={this.props.InventorySkuRecoveryHistoryData}

                isInventoryContainerHistoryLoading={this.props.isInventoryContainerHistoryLoading}
                InventoryContainerHistoryData={this.props.InventoryContainerHistoryData}









    isInventoryWarehouseLocationLoading={this.props.isInventoryWarehouseLocationLoading}
    InventoryWarehouseLocationData={this.props.InventoryWarehouseLocationData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WarehouseLocationDetailsContainer));



