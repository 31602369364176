import { connect } from 'react-redux'
import PrivateRouteComponent from './PrivateRoute'
import * as actions from '../../../redux/actions/actionsUser';

const mapStatetoProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {

    appSignIn:( currentUserEmail ) =>
    {
        dispatch( actions.appSignIn( currentUserEmail ) )
    }

  }
}


export default connect(mapStatetoProps, mapDispatchToProps)(
  PrivateRouteComponent
)

