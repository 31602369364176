export const InventoryPalletWarehouseGroupedDataElements = [
    {
        dBKey: 'WarehouseLocationName',
        displayName: 'Warehouse Location',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'PalletCount',
        displayName: 'Pallet Count',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'Oversize',
        displayName: 'Oversize',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'PalletStatusName',
        displayName: 'Pallet Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    {
        dBKey: 'CreateDT',
        displayName: 'Min Recovery Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    // {
    //     dBKey: 'Container',
    //     displayName: 'Container',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 180
    // },

    {
        dBKey: 'CasesInWhse',
        displayName: 'Cases In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },


    
    {
        dBKey: 'UnitsInWhse',
        displayName: 'Units In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    // {
    //     dBKey: 'CaseSKU',
    //     displayName: 'Case SKU',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 300
    // },
    // {
    //     dBKey: 'SKU',
    //     displayName: 'Unit SKU',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 300
    // },

]


export default InventoryPalletWarehouseGroupedDataElements

