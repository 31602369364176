import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Tree } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventorySkuWarehouseLocationDataElements from '../../dataComponentsInventory/InventorySkuWarehouseLocationDataElements';
import queryString from 'query-string';
import { queryStringParse, replaceNegativeWithZero, isNumeric, formatStringForAPI, uniqueArray, convertNanToZero, formatTrueFalseToBit, formatNumberForAPI, formatAllTextNumbersSymbolsForAPI } from '../../../resources/logic/functions';
import CustomsProductCatalogDataElements from '../../dataComponentsCustomsBrokerage/CustomsProductCatalogDataElements';

const tableIdCol = "SkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class ProductCatalog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };


        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);



    }




    componentDidMount() {
        this.loadSortFromStorage()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    render() {
        const { sortColumn, sortType } = this.state;




        let loadDiv;
        let rTable;
        // if (this.props.isInventoryLoadLotLoading) {
        //     rTable = <div></div>
        //     loadDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else if (typeof (this.props.InventoryLoadLotData) !== 'undefined' && this.props.InventoryLoadLotData != null) {

        //     if (this.props.InventoryLoadLotData.length === 0) {
        //         rTable = <div></div>
        //         loadDiv = <div></div>
        //     }
        //     else {

        //         // let rTable;
        //         rTable = RTableGeneral(
        //             'SkuID',     //   keyColumn,
        //             {},     //   tableData,
        //             CustomsProductCatalogDataElements,       //   DataElements,
        //             false,        //   loading,

        //             this.props.isMobile,        //   isMobile,
        //             this.props.history,     //   history,

        //             sortColumn,     //   sortColumn,
        //             sortType,       //   sortType,
        //             this.handleSortColumn,      //   handleSortColumn,

        //             false, // checkColEntry,
        //             null,       //   checkedKeys,
        //             null,       //   handleCheckAll,
        //             null,       //   handleCheck,

        //             false,      //   boolSelectButton,
        //             null,       //   onClickSelect,
        //             null,       //   selectButtonText,
        //             null,       //   selectHighlightRowKey,

        //             false,       //   link1bool,
        //             null,     //   link1text,
        //             null,     //   link1onClick,

        //             false,       //   link2bool,
        //             null,       //   link2text,
        //             null  //   link2onClick
        //         )

        //         loadDiv = <div>


        //             {(this.props.isMobile == true) ? <div></div> :
        //                 <div>
        //                     <Row>
        //                         <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>
        //                             <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

        //                                 {
        //                                     InventoryLoadSkuDetailsDataElements.map(
        //                                         ({ dBKey, displayName }, idx) => {
        //                                             return (
        //                                                 <ExcelColumn label={displayName} value={dBKey} />
        //                                             );
        //                                         }
        //                                     )
        //                                 }

        //                             </ExcelSheet>
        //                         </ExcelFile>
        //                     </Row>
        //                 </div>
        //             }

        //         </div>
        //     }
        // }
        // else {
        //     rTable = <div></div>
        //     loadDiv = <div></div>
        // }





        // let rTable;
        rTable = RTableGeneral(
            'SkuID',     //   keyColumn,
            {},     //   tableData,
            CustomsProductCatalogDataElements,       //   DataElements,
            false,        //   loading,

            this.props.isMobile,        //   isMobile,
            this.props.history,     //   history,

            sortColumn,     //   sortColumn,
            sortType,       //   sortType,
            this.handleSortColumn,      //   handleSortColumn,

            false, // checkColEntry,
            null,       //   checkedKeys,
            null,       //   handleCheckAll,
            null,       //   handleCheck,

            false,      //   boolSelectButton,
            null,       //   onClickSelect,
            null,       //   selectButtonText,
            null,       //   selectHighlightRowKey,

            false,       //   link1bool,
            null,     //   link1text,
            null,     //   link1onClick,

            false,       //   link2bool,
            null,       //   link2text,
            null  //   link2onClick
        )

        loadDiv = <div>


            {(this.props.isMobile == true) ? <div></div> :
                <div>
                    <Row>
                        <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'ProductCatalog_' + currentDateTime}>
                            <ExcelSheet data={{}} name="ProductCatalog">

                                {
                                    CustomsProductCatalogDataElements.map(
                                        ({ dBKey, displayName }, idx) => {
                                            return (
                                                <ExcelColumn label={displayName} value={dBKey} />
                                            );
                                        }
                                    )
                                }

                            </ExcelSheet>
                        </ExcelFile>
                    </Row>
                </div>
            }

        </div>


        return (
            <div>
                <h3>
                    Customs Brokerage - Product Catalog
                </h3>

                <br />


                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }}>
                            {loadDiv}
                            {rTable}
                        </Panel>
                    </Col>
                </Row>


            </div>
        );

    }
}

export default withRouter(ProductCatalog);
