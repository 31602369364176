import { connect } from 'react-redux';
import React, { Component } from 'react';
import ContainerDetail from './ContainerDetail'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as userActions from '../../../../redux/actions/actionsUser';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryPostLoading: store.inventoryReducer.isInventoryPostLoading,

    isInventoryLotContainerLoading: store.inventoryReducer.isInventoryLotContainerLoading,
    InventoryLotContainerData: store.inventoryReducer.InventoryLotContainerData,


    isInventoryLotSkuLoading: store.inventoryReducer.isInventoryLotSkuLoading,
    InventoryLotSkuData: store.inventoryReducer.InventoryLotSkuData,

    isInventorySkuRecoveryHistoryLoading: store.inventoryReducer.isInventorySkuRecoveryHistoryLoading,
    InventorySkuRecoveryHistoryData: store.inventoryReducer.InventorySkuRecoveryHistoryData,

    isInventoryContainerHistoryLoading: store.inventoryReducer.isInventoryContainerHistoryLoading,
    InventoryContainerHistoryData: store.inventoryReducer.InventoryContainerHistoryData,

    InventoryLotContainerPostSuccessful: store.inventoryReducer.InventoryLotContainerPostSuccessful,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,

    // InventorySkuRecoveryPostSuccessful: store.inventoryReducer.InventorySkuRecoveryPostSuccessful,

    InventorySkuWarehouseLocationPostSuccessful: store.inventoryReducer.InventorySkuWarehouseLocationPostSuccessful,


    InventoryContainerStatusPostSuccessful: store.inventoryReducer.InventoryContainerStatusPostSuccessful,

    isInventoryUnitWarehouseGroupedLoading: store.inventoryReducer.isInventoryUnitWarehouseGroupedLoading,
    InventoryUnitWarehouseGroupedData: store.inventoryReducer.InventoryUnitWarehouseGroupedData,

    isInventoryCaseWarehouseGroupedLoading: store.inventoryReducer.isInventoryCaseWarehouseGroupedLoading,
    InventoryCaseWarehouseGroupedData: store.inventoryReducer.InventoryCaseWarehouseGroupedData,

    isInventoryPalletWarehouseGroupedLoading: store.inventoryReducer.isInventoryPalletWarehouseGroupedLoading,
    InventoryPalletWarehouseGroupedData: store.inventoryReducer.InventoryPalletWarehouseGroupedData,

    isInventoryCaseSkusHistoryLoading: store.inventoryReducer.isInventoryCaseSkusHistoryLoading,
    InventoryCaseSkusHistoryData: store.inventoryReducer.InventoryCaseSkusHistoryData,

    isInventoryCaseHistoryLoading: store.inventoryReducer.isInventoryCaseHistoryLoading,
    InventoryCaseHistoryData: store.inventoryReducer.InventoryCaseHistoryData,

    isInventoryPalletWarehouseLocationHistoryLoading: store.inventoryReducer.isInventoryPalletWarehouseLocationHistoryLoading,
    InventoryPalletWarehouseLocationHistoryData: store.inventoryReducer.InventoryPalletWarehouseLocationHistoryData,

    isInventoryLotHistoryTypeLoading: store.inventoryReducer.isInventoryLotHistoryTypeLoading,
    InventoryLotHistoryTypeData: store.inventoryReducer.InventoryLotHistoryTypeData,

    InventoryDeleteContainerPalletCaseUnitPostSuccessful: store.inventoryReducer.InventoryDeleteContainerPalletCaseUnitPostSuccessful,
    isInventoryDeleteContainerPalletCaseUnitLoading: store.inventoryReducer.isInventoryDeleteContainerPalletCaseUnitLoading,

});

const mapDispatchToProps = dispatch =>
({

    inventoryLotContainerGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotContainerGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },
    inventoryLotSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },

    inventoryContainerHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _SkuID, _SKU,) => {
        dispatch(inventoryActions.inventoryContainerHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _SkuID, _SKU,))
    },
    inventoryLotContainerPost: (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized, _ContainerSize) => {
        dispatch(inventoryActions.inventoryLotContainerPost(_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized, _ContainerSize))
    },

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    // inventorySkuRecoveryPost: (_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate) => {
    //     dispatch(inventoryActions.inventorySkuRecoveryPost(_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate))
    // },
    // inventorySkuWarehouseLocationPost: (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) => {
    //     dispatch(inventoryActions.inventorySkuWarehouseLocationPost(_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription))
    // },

    inventoryContainerStatusPost: (_email, _authToken, _ContainerID, _ContainerStatusID) => {
        dispatch(inventoryActions.inventoryContainerStatusPost(_email, _authToken, _ContainerID, _ContainerStatusID))
    },

    inventoryUnitWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryUnitWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventoryCaseWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },

    inventoryPalletWarehouseLocationGroupedGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGroupedGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },

    inventoryCaseSkusHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID) => {
        dispatch(inventoryActions.inventoryCaseSkusHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID))
    },

    inventoryCaseHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _ContainerID) => {
        dispatch(inventoryActions.inventoryCaseHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _ContainerID))
    },

    inventoryPalletWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _ContainerID) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _ContainerID))
    },

    inventoryLotHistoryTypeGet: (_email, _authToken, _InventoryDelete) => {
        dispatch(inventoryActions.inventoryLotHistoryTypeGet(_email, _authToken, _InventoryDelete))
    },

    inventoryLotDeleteContainerPalletCaseUnitPost: (_email, _authToken, _WarehouseID, _CustomerID, _HistoryTypeID, _ContainerIdList, _PalletIdList, _PalletBarcode, _CaseIdList, _CaseBarcode, _UnitIdList, _UnitsToRemove) => {
        dispatch(inventoryActions.inventoryLotDeleteContainerPalletCaseUnitPost(_email, _authToken, _WarehouseID, _CustomerID, _HistoryTypeID, _ContainerIdList, _PalletIdList, _PalletBarcode, _CaseIdList, _CaseBarcode, _UnitIdList, _UnitsToRemove))
    },
});

class ContainerDetailContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLotContainerPostSuccessful !== this.props.InventoryLotContainerPostSuccessful && this.props.InventoryLotContainerPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.handleUrlParam();
        }

        if (prevProps.InventoryContainerStatusPostSuccessful !== this.props.InventoryContainerStatusPostSuccessful && this.props.InventoryContainerStatusPostSuccessful) {
            this.handleUrlParam();
        }

        if (prevProps.InventoryDeleteContainerPalletCaseUnitPostSuccessful !== this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful && this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful) {
            this.handleUrlParam()
        }
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = formatStringForAPI(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }

        this.props.inventoryLotHistoryTypeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, 1)
        

        //Lot Tracking
        this.props.inventoryPalletWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramContainerID, null, null, null, null)
        this.props.inventoryCaseWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramContainerID, null, null, null)
        this.props.inventoryUnitWarehouseLocationGroupedGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramContainerID, null, null, null, null)
 
        this.props.inventoryLotContainerGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, null, null, null, null, null)
        this.props.inventoryLotSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainerID, null, null, null)

        this.props.inventoryContainerHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, null, null, null)
    }



    render() {

        return (

            <ContainerDetail
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventoryPostLoading={this.props.isInventoryPostLoading}

                isInventoryLotContainerLoading={this.props.isInventoryLotContainerLoading}
                InventoryLotContainerData={this.props.InventoryLotContainerData}


                isInventoryLotSkuLoading={this.props.isInventoryLotSkuLoading}
                InventoryLotSkuData={this.props.InventoryLotSkuData}

                isInventorySkuRecoveryHistoryLoading={this.props.isInventorySkuRecoveryHistoryLoading}
                InventorySkuRecoveryHistoryData={this.props.InventorySkuRecoveryHistoryData}

                isInventoryContainerHistoryLoading={this.props.isInventoryContainerHistoryLoading}
                InventoryContainerHistoryData={this.props.InventoryContainerHistoryData}

                inventoryLotContainerPost={this.props.inventoryLotContainerPost}

                InventoryLotContainerPostSuccessful={this.props.InventoryLotContainerPostSuccessful}

                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}

                // inventorySkuRecoveryPost={this.props.inventorySkuRecoveryPost}
                // InventorySkuRecoveryPostSuccessful={this.props.InventorySkuRecoveryPostSuccessful}


                InventorySkuWarehouseLocationPostSuccessful={this.props.InventorySkuWarehouseLocationPostSuccessful}
                inventorySkuWarehouseLocationPost={this.props.inventorySkuWarehouseLocationPost}

                inventoryContainerStatusPost={this.props.inventoryContainerStatusPost}

                isInventoryUnitWarehouseGroupedLoading={this.props.isInventoryUnitWarehouseGroupedLoading}
                InventoryUnitWarehouseGroupedData={this.props.InventoryUnitWarehouseGroupedData}

                isInventoryCaseWarehouseGroupedLoading={this.props.isInventoryCaseWarehouseGroupedLoading}
                InventoryCaseWarehouseGroupedData={this.props.InventoryCaseWarehouseGroupedData}

                isInventoryPalletWarehouseGroupedLoading={this.props.isInventoryPalletWarehouseGroupedLoading}
                InventoryPalletWarehouseGroupedData={this.props.InventoryPalletWarehouseGroupedData}



                isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}

                isInventoryPalletWarehouseLocationHistoryLoading={this.props.isInventoryPalletWarehouseLocationHistoryLoading}
                InventoryPalletWarehouseLocationHistoryData={this.props.InventoryPalletWarehouseLocationHistoryData}

                isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}


                isInventoryLotHistoryTypeLoading={this.props.isInventoryLotHistoryTypeLoading}
                InventoryLotHistoryTypeData={this.props.InventoryLotHistoryTypeData}

                InventoryDeleteContainerPalletCaseUnitPostSuccessful={this.props.InventoryDeleteContainerPalletCaseUnitPostSuccessful}  
                isInventoryDeleteContainerPalletCaseUnitLoading={this.props.isInventoryDeleteContainerPalletCaseUnitLoading}
                inventoryLotDeleteContainerPalletCaseUnitPost={this.props.inventoryLotDeleteContainerPalletCaseUnitPost}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContainerDetailContainer));



