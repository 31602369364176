import React      from 'react';
import PropTypes  from 'prop-types';
import VasHomeMenu         from './HomeMenu/VasHomeMenu';
import VasHomeMainContent  from './VasHomeMainContent/VasHomeMainContent';
import {MainRoutes, NavigationLinks}      from '../../../routes/MainRoutes'

const VasHome = ({
                sideMenuIsCollapsed,
                // email,             
                // firstName,
                // lastName,              
                // pendingApproval,     
                admin,              
                // group,    
                role,           
                isMobile, 
                warehouseArray,
                warehouseSelection,
                warehouseMultiSelection,

                organizationSelection,

                customerArray,
                customerSelection,
                customerMultiSelection,

                moduleArray,
                onChangeWarehouseSelection,
                onChangeCustomerSelection
                // navSelection,
                // dropDownOnChange,
                // languageSelection       
                   }) => (

    <div className="wrapper row-offcanvas row-offcanvas-left">
    <VasHomeMenu
              isAnimated={true}
            //   isCollapsed={isMobile === false ? false : true}   //is menu hidden or shown  
              currentView={true}
              // sideMenu={NavigationLinks( {admin, navSelection, role} )}
              sideMenu={NavigationLinks( {admin, role, warehouseArray, customerArray, moduleArray, organizationSelection} )}
              helloWord={'true'}
              connectionStatus={'true'}
              userIsConnected={'true'}
              username={'test'}
              isCollapsed={sideMenuIsCollapsed}
              isMobile={isMobile}
              // navSelection={navSelection}
              admin={admin}
              // group={group}
              role={role}
              warehouseArray={warehouseArray}
              warehouseSelection={warehouseSelection}
              organizationSelection={organizationSelection}
              warehouseMultiSelection={warehouseMultiSelection}
            
              customerArray={customerArray}
              customerSelection={customerSelection}
              customerMultiSelection={customerMultiSelection}
              
              onChangeWarehouseSelection={onChangeWarehouseSelection}
              onChangeCustomerSelection={onChangeCustomerSelection}
              // dropDownOnChange={dropDownOnChange}
              // languageSelection={languageSelection}
    />
    <VasHomeMainContent 
    isAnimated={true} 
    isExpanded={sideMenuIsCollapsed} 
    isMobile={isMobile} 
    warehouseSelection={warehouseSelection}
    organizationSelection={organizationSelection}
    customerSelection={customerSelection}
    // languageSelection={languageSelection}
    >
        <MainRoutes 
          // pendingApproval={pendingApproval}
          admin={admin}
          // group={group}
          role={role}

          warehouseArray={ warehouseArray }
          customerArray={ customerArray }
          moduleArray={ moduleArray }

          organizationSelection={ organizationSelection }

          // navSelection={navSelection}
          // languageSelection={languageSelection}
          />
    </VasHomeMainContent>
    </div>

);

VasHome.propTypes = {
    sideMenuIsCollapsed:    PropTypes.bool,
    // email:                  PropTypes.string, 
    // firstName:              PropTypes.string,
    // lastName:               PropTypes.string,
    // pendingApproval:        PropTypes.bool,     //if user account is pending approval
    admin:                  PropTypes.bool,     //if user has admin rights
    // group:                  PropTypes.string,   //this is organizaton (Omni, google)
    // role:                   PropTypes.node,   //this is role in organization (operations, sales)
    role:           PropTypes.arrayOf(
      PropTypes.shape(
          {
              Admin: PropTypes.string,
              RoleID: PropTypes.string,
              RoleName: PropTypes.string
          }
      )
  ),
    isMobile:               PropTypes.bool,
    // warehouseSelection:   PropTypes.any
    // navSelection:           PropTypes.string,
    // dropDownOnChange:       PropTypes.func,
    // languageSelection:      PropTypes.string 

};


export default VasHome;
