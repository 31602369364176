import { connect } from 'react-redux';
import React, { Component } from 'react';
import ContainerDetail from './ContainerDetail'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as userActions from '../../../../redux/actions/actionsUser';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryContainerLoading: store.inventoryReducer.isInventoryContainerLoading,
    InventoryContainerData: store.inventoryReducer.InventoryContainerData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    isInventorySkuLoading: store.inventoryReducer.isInventorySkuLoading,
    InventorySkuData: store.inventoryReducer.InventorySkuData,

    isInventorySkuRecoveryLoading: store.inventoryReducer.isInventorySkuRecoveryLoading,
    InventorySkuRecoveryData: store.inventoryReducer.InventorySkuRecoveryData,

    isInventorySkuRecoveryHistoryLoading: store.inventoryReducer.isInventorySkuRecoveryHistoryLoading,
    InventorySkuRecoveryHistoryData: store.inventoryReducer.InventorySkuRecoveryHistoryData,

    isInventoryContainerHistoryLoading: store.inventoryReducer.isInventoryContainerHistoryLoading,
    InventoryContainerHistoryData: store.inventoryReducer.InventoryContainerHistoryData,

    InventoryContainerPostSuccessful: store.inventoryReducer.InventoryContainerPostSuccessful,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,

    // InventorySkuRecoveryPostSuccessful: store.inventoryReducer.InventorySkuRecoveryPostSuccessful,

    InventorySkuWarehouseLocationPostSuccessful: store.inventoryReducer.InventorySkuWarehouseLocationPostSuccessful,


    InventoryContainerStatusPostSuccessful: store.inventoryReducer.InventoryContainerStatusPostSuccessful,

    isInventorySkuWarehouseLoading: store.inventoryReducer.isInventorySkuWarehouseLoading,
    InventorySkuWarehouseData: store.inventoryReducer.InventorySkuWarehouseData,

    isInventoryCaseWarehouseLoading: store.inventoryReducer.isInventoryCaseWarehouseLoading,
    InventoryCaseWarehouseData: store.inventoryReducer.InventoryCaseWarehouseData,

    isInventoryPalletWarehouseLoading: store.inventoryReducer.isInventoryPalletWarehouseLoading,
    InventoryPalletWarehouseData: store.inventoryReducer.InventoryPalletWarehouseData,

    isInventoryCaseSkusHistoryLoading: store.inventoryReducer.isInventoryCaseSkusHistoryLoading,
    InventoryCaseSkusHistoryData: store.inventoryReducer.InventoryCaseSkusHistoryData,


    isInventoryCaseHistoryLoading: store.inventoryReducer.isInventoryCaseHistoryLoading,
    InventoryCaseHistoryData: store.inventoryReducer.InventoryCaseHistoryData,

    isInventoryPalletWarehouseLocationHistoryLoading: store.inventoryReducer.isInventoryPalletWarehouseLocationHistoryLoading,
    InventoryPalletWarehouseLocationHistoryData: store.inventoryReducer.InventoryPalletWarehouseLocationHistoryData,


});

const mapDispatchToProps = dispatch =>
({

    inventoryContainerGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryContainerGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },
    inventorySkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuRecoverGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventorySkuRecoverHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventoryContainerHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _SkuID, _SKU,) => {
        dispatch(inventoryActions.inventoryContainerHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _SkuID, _SKU,))
    },
    inventoryContainerPost: (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized) => {
        dispatch(inventoryActions.inventoryContainerPost(_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized))
    },

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    // inventorySkuRecoveryPost: (_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate) => {
    //     dispatch(inventoryActions.inventorySkuRecoveryPost(_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate))
    // },
    inventorySkuWarehouseLocationPost: (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationPost(_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription))
    },

    inventoryContainerStatusPost: (_email, _authToken, _ContainerID, _ContainerStatusID) => {
        dispatch(inventoryActions.inventoryContainerStatusPost(_email, _authToken, _ContainerID, _ContainerStatusID))
    },

    inventoryLotSkuWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryLotSkuWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _CaseOrUnitSkuID))
    },

    inventoryCaseWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(inventoryActions.inventoryCaseWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd))
    },

    inventoryPalletWarehouseLocationGet: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },


    inventoryCaseSkusHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID) => {
        dispatch(inventoryActions.inventoryCaseSkusHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _UnitSkuID, _ContainerID))
    },

    inventoryCaseHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _ContainerID) => {
        dispatch(inventoryActions.inventoryCaseHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _ContainerID))
    },

    inventoryPalletWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _ContainerID) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PalletID, _PalletBarcode, _CaseID, _ContainerID))
    },
});

class ContainerDetailContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryContainerPostSuccessful !== this.props.InventoryContainerPostSuccessful && this.props.InventoryContainerPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.handleUrlParam();
        }

        if (prevProps.InventoryContainerStatusPostSuccessful !== this.props.InventoryContainerStatusPostSuccessful && this.props.InventoryContainerStatusPostSuccessful) {
            this.handleUrlParam();
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = formatStringForAPI(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }

        // this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramContainerID, null, null, null, null)
        // this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramContainerID, null, null, null)
        // this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, paramContainerID, null, null, null, null)

        this.props.inventoryContainerGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, null, null, null, null, null)
        this.props.inventorySkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainerID, null, null, null)
        this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainerID, null, null, null)

        this.props.inventorySkuRecoverGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainerID, null)
        this.props.inventorySkuRecoverHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainerID, null)
        this.props.inventoryContainerHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, null, null, null)



        // this.props.inventoryCaseHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, paramContainerID)
        // this.props.inventoryPalletWarehouseLocationHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramContainerID)
        // this.props.inventoryCaseSkusHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, paramContainerID)

    }



    render() {

        return (

            <ContainerDetail
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventoryContainerLoading={this.props.isInventoryContainerLoading}
                InventoryContainerData={this.props.InventoryContainerData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}

                isInventorySkuLoading={this.props.isInventorySkuLoading}
                InventorySkuData={this.props.InventorySkuData}

                isInventorySkuRecoveryLoading={this.props.isInventorySkuRecoveryLoading}
                InventorySkuRecoveryData={this.props.InventorySkuRecoveryData}

                isInventorySkuRecoveryHistoryLoading={this.props.isInventorySkuRecoveryHistoryLoading}
                InventorySkuRecoveryHistoryData={this.props.InventorySkuRecoveryHistoryData}

                isInventoryContainerHistoryLoading={this.props.isInventoryContainerHistoryLoading}
                InventoryContainerHistoryData={this.props.InventoryContainerHistoryData}

                inventoryContainerPost={this.props.inventoryContainerPost}

                InventoryContainerPostSuccessful={this.props.InventoryContainerPostSuccessful}

                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}

                // inventorySkuRecoveryPost={this.props.inventorySkuRecoveryPost}
                // InventorySkuRecoveryPostSuccessful={this.props.InventorySkuRecoveryPostSuccessful}


                InventorySkuWarehouseLocationPostSuccessful={this.props.InventorySkuWarehouseLocationPostSuccessful}
                inventorySkuWarehouseLocationPost={this.props.inventorySkuWarehouseLocationPost}

                inventoryContainerStatusPost={this.props.inventoryContainerStatusPost}

                isInventorySkuWarehouseLoading={this.props.isInventorySkuWarehouseLoading}
                InventorySkuWarehouseData={this.props.InventorySkuWarehouseData}

                isInventoryCaseWarehouseLoading={this.props.isInventoryCaseWarehouseLoading}
                InventoryCaseWarehouseData={this.props.InventoryCaseWarehouseData}

                isInventoryPalletWarehouseLoading={this.props.isInventoryPalletWarehouseLoading}
                InventoryPalletWarehouseData={this.props.InventoryPalletWarehouseData}



                isInventoryCaseHistoryLoading={this.props.isInventoryCaseHistoryLoading}
                InventoryCaseHistoryData={this.props.InventoryCaseHistoryData}

                isInventoryPalletWarehouseLocationHistoryLoading={this.props.isInventoryPalletWarehouseLocationHistoryLoading}
                InventoryPalletWarehouseLocationHistoryData={this.props.InventoryPalletWarehouseLocationHistoryData}

                isInventoryCaseSkusHistoryLoading={this.props.isInventoryCaseSkusHistoryLoading}
                InventoryCaseSkusHistoryData={this.props.InventoryCaseSkusHistoryData}


            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContainerDetailContainer));



