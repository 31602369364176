export const CtrlTwrShipmentAlertsDataElements = [
    {
        dBKey: 'Alert',
        displayName: 'Alert Type',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'ProRef',
        displayName: 'ProRef',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Leg',
        displayName: 'Leg',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Mode',
        displayName: 'Mode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    
        

]


export default CtrlTwrShipmentAlertsDataElements

