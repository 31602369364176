export const XdockLoadPalletCartonExportDataElements = [


    {
        dBKey: 'HouseRef',
        displayName: 'House Ref',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },

    


    {
        dBKey: 'PalletSeq',
        displayName: 'Pallet Seq',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },

    {
        dBKey: 'CartonID',
        displayName: 'Carton ID',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },
 


    {
        dBKey: 'CartonQty',
        displayName: 'Carton Qty',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 130
    },
 




    
    
]


export default XdockLoadPalletCartonExportDataElements

