import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, FormGroup, DatePicker, Input } from 'rsuite';
import { lettersNumbersDashesSpacesInputMask, lettersNumbersInputMask, lettersNumbersDashesInputMask, numbersInputMask, numbersDashInputMask, textStringInputMask, tenthsDecimalInputMask, limitNumberLengthMask, hundredthsDecimalInputMask, limitCharacterLength } from '../../../resources/logic/inputMasks';
import CustomInputPickerStringValues from '../../componentsGeneral/Rsuite/InputPicker/CustomInputPickerStringValues';

const styles = {
    marginBottom: '10px',
    width: '100%'
};
const stylesRequiredInput = {
    backgroundColor: '#FFE4E1'
};

const mountRef = React.createRef();

function AddProductModal({
    addNewProductModalShown,
    handleSave,
    handleClose,
    onChange,
    resetRows,
    state, ...props
}) {
    return (

        <div className="modal-container" >
            <Modal backdrop={'static'} keyboard={false} show={addNewProductModalShown} onHide={handleClose} onExited={resetRows}>
                <Modal.Header>
                    <Modal.Title>Product:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Panel bordered style={{ marginBottom: '15px' }} >
                        <div ref={mountRef}>

                            <Col md={24} sm={24} style={styles}>

                                <FormGroup >


                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Product ID (Required):</InputGroup.Addon>
                                        <Input value={state.new_ProductCode}
                                            onChange={(e) => { onChange(e, 'new_ProductCode') }}
                                            onInput={(e) => e.target.value = lettersNumbersInputMask(e)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Description (Required):</InputGroup.Addon>
                                        <Input value={state.new_ProductDescription}
                                            onChange={(e) => { onChange(e, 'new_ProductDescription') }}
                                            onInput={(e) => e.target.value = textStringInputMask(e)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Unit Value (Required):</InputGroup.Addon>
                                        <Input value={state.new_ProductUnitValue}
                                            onChange={(e) => { onChange(e, 'new_ProductUnitValue') }}
                                            onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Currency Code (Required):</InputGroup.Addon>
                                        <Input value={state.new_CurrencyCode}
                                            onChange={(e) => { onChange(e, 'new_CurrencyCode') }}
                                            onInput={(e) => e.target.value = textStringInputMask(e)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Item Origin (Required):</InputGroup.Addon>
                                        <Input value={state.new_ProductItemOrigin}
                                            onChange={(e) => { onChange(e, 'new_ProductItemOrigin') }}
                                            onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Harmonized Code (Required):</InputGroup.Addon>
                                        <Input value={state.new_ProductHarmonizedCode}
                                            onChange={(e) => { onChange(e, 'new_ProductHarmonizedCode') }}
                                            onInput={(e) => e.target.value = limitNumberLengthMask(e, 10)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Unit Weight (Required):</InputGroup.Addon>
                                        <Input value={state.new_ProductUnitWeight}
                                            onChange={(e) => { onChange(e, 'new_ProductUnitWeight') }}
                                            onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Quantity (Required):</InputGroup.Addon>
                                        <Input value={state.new_ProductQuantity}
                                            onChange={(e) => { onChange(e, 'new_ProductQuantity') }}
                                            onInput={(e) => e.target.value = numbersInputMask(e)}
                                            style={stylesRequiredInput}
                                        />
                                    </InputGroup>


                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Fabric Content:</InputGroup.Addon>
                                        <Input value={state.new_ProductFabricContent}
                                            onChange={(e) => { onChange(e, 'new_ProductFabricContent') }}
                                            onInput={(e) => e.target.value = textStringInputMask(e)}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Hazmat:</InputGroup.Addon>
                                        <Input value={state.new_Hazmat}
                                            onChange={(e) => { onChange(e, 'new_Hazmat') }}
                                            onInput={(e) => e.target.value = limitCharacterLength( textStringInputMask(e), 1)}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Hazmat Code:</InputGroup.Addon>
                                        <Input value={state.new_HazmatCode}
                                            onChange={(e) => { onChange(e, 'new_HazmatCode') }}
                                            onInput={(e) => e.target.value = textStringInputMask(e)}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>FDA Flag:</InputGroup.Addon>
                                        <Input value={state.new_FDAFlag}
                                            onChange={(e) => { onChange(e, 'new_FDAFlag') }}
                                            onInput={(e) => e.target.value = limitCharacterLength( textStringInputMask(e), 1)}
                                        />
                                    </InputGroup>

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>FDA Code:</InputGroup.Addon>
                                        <Input value={state.new_FDACode}
                                            onChange={(e) => { onChange(e, 'new_FDACode') }}
                                            onInput={(e) => e.target.value = textStringInputMask(e)}
                                        />
                                    </InputGroup>




                                </FormGroup>





                            </Col>

                        </div>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default AddProductModal;