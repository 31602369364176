import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import ShipmentDetailsManagementContainer from '../../../components/componentsControlTower/ShipmentDetailsManagement/ShipmentDetailsManagementContainer';

class ctrlTwrShipmentDetailsManagementView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

    }

    render() {

        return (
            <AnimatedView>
                <ShipmentDetailsManagementContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection} organizationSelection={this.props.organizationSelection}/>
            </AnimatedView>

        );
    }
}

export default ctrlTwrShipmentDetailsManagementView;
