import React, {
    PureComponent
  }                         from 'react';
import { Table , FormGroup, ControlLabel, FormControl} from 'rsuite';

const { Column, HeaderCell, Cell } = Table;


function RFormControl(
    DataElements,
    isMobile,
    changeHandler
  ) {

    return (


                    Object.keys(DataElements).map(function(key) {
                        var primaryCol = false 
                        if(DataElements[key]["primaryCol"]){
                        primaryCol = true
                        }

                        if(!DataElements[key]["mobileDisplay"] && isMobile ){

                        }
                        else{
                        return (

            
                            <FormGroup>
                                <ControlLabel>{DataElements[key]["displayName"]}</ControlLabel>
                                <FormControl name={DataElements[key]["dBKey"]} value={`${DataElements[key]["dBKey"]}`} onChange={(e) => {changeHandler(e,  `'${DataElements[key]["dBKey"]}'`   )}}/>
                            </FormGroup>


                            )

                        }

                    })




    )
    }


export default RFormControl;
