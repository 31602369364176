import React, {
    PureComponent
} from 'react';
import { Input, Button, ButtonToolbar, Row, Col, FormGroup, InputGroup, Panel, } from 'rsuite';
import AddPackageInputDiv from './AddPackageInputDiv';


const styles = {
    marginBottom: '10px',
    width: '100%'
};
const stylesRequiredInput = {
    backgroundColor: '#FFE4E1'
};
const stylesInputPicker = {
    width: '100%'
};
const stylesRequiredAddOn = {
    backgroundColor: "rgb(255,228,225)"
};

const mountRef = React.createRef();

function StepPackage({
    handleChangeFunc,

    onClickAddPackage,
    onClickEditPackage,
    onClickDeletePackage,

    onClickAddProduct,
    onClickEditProduct,
    onClickDeleteProduct,

    handleSaveAddNewPackageModal,
    onClickSaveAndAddPackage,
    onClickSaveAndAddProduct,
    state, ...props
}) {

    return (

        <Panel bordered style={{ marginBottom: 10 }}
            header={
                <Row>

                    <span>Packages and Products</span>


                </Row>
            }
        >

            {
                state.PackageDict.length < 1 ?
                    <div>

                        <AddPackageInputDiv {...props} state={state} onChange={handleChangeFunc} handleSave={handleSaveAddNewPackageModal} onClickSaveAndAddPackage={onClickSaveAndAddPackage} onClickSaveAndAddProduct={onClickSaveAndAddProduct}/>

                    </div>
                    :
                    <div>

                        <Row>
                                <ButtonToolbar >
                                    <Button appearance="primary" style={{ float: 'right', marginBottom: '10px'}} onClick={(e) => { onClickAddPackage(e) }} >Add Package</Button>
                                </ButtonToolbar>
                        </Row>


                        {state.PackageDict.map(packageItem => (



                            <Panel bordered style={{ marginBottom: 10 }}
                                header={
                                    <Row>
                                        <Col md={8} sm={24}>
                                            <span>Package</span>
                                        </Col>
                                        <Col md={16} sm={24}>
                                            <ButtonToolbar style={{ float: 'right' }}>
                                                <Button appearance="primary" onClick={(e) => { onClickEditPackage(e, packageItem) }} >Edit</Button>
                                                <Button appearance="primary" onClick={(e) => { onClickAddProduct(e, packageItem['PackageIndex']) }} >Add Product</Button>
                                                <Button onClick={(e) => { onClickDeletePackage(e, packageItem) }} >Remove Package</Button>
                                            </ButtonToolbar>
                                        </Col>
                                    </Row>
                                }>


                                <FormGroup >
                                    <Row>
                                        {/* <Col md={16} sm={24}>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Description:</InputGroup.Addon>
                                                <Input value={packageItem['Package_Description']} readOnly />
                                            </InputGroup>
                                        </Col> */}
                                        <Col md={8} sm={24}>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Packaging Code:</InputGroup.Addon>
                                                <Input value={packageItem['Package_Packaging_Code']} readOnly />
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={24}>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Dimensions (LxWxH):</InputGroup.Addon>
                                                <Input value={packageItem['Package_Dimensions_Length'] + ' x ' + packageItem['Package_Dimensions_Width'] + ' x ' + packageItem['Package_Dimensions_Height'] + ' ' + packageItem['Package_Dimensions_UOM_Code']} readOnly />
                                            </InputGroup>
                                        </Col>

                                        <Col md={12} sm={24}>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Weight:</InputGroup.Addon>
                                                <Input value={packageItem['Package_PackageWeight_Weight'] + ' ' + packageItem['Package_PackageWeight_UOM_Code']} readOnly />
                                            </InputGroup>
                                        </Col>
                                    </Row>



                                </FormGroup>




                                {
                                    state.ProductDict.filter(x => x.ProductPackageIndex === packageItem.PackageIndex).map(productItem => (
                                        // props.LabelGenRequestPackageProductInfoData.filter(x => x.RequestPackageID === packageItem.RequestPackageID).map(productItem => (


                                        <Panel bordered style={{ marginBottom: 10 }}
                                            header={
                                                <Row>
                                                    <Col md={16} sm={24}>
                                                        <span>Product</span>
                                                    </Col>
                                                    <Col md={8} sm={24}>
                                                        <ButtonToolbar style={{ float: 'right' }}>
                                                            <Button appearance="primary" onClick={(e) => { onClickEditProduct(e, productItem) }} >Edit</Button>
                                                            <Button onClick={(e) => { onClickDeleteProduct(e, productItem) }} >Remove Product</Button>
                                                        </ButtonToolbar>
                                                    </Col>
                                                </Row>
                                            }
                                        >

                                            <FormGroup >


                                                <Row>
                                                    <Col md={12} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Description:</InputGroup.Addon>
                                                            <Input value={productItem['ProductDescription']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={12} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Product ID:</InputGroup.Addon>
                                                            <Input value={productItem['ProductCode']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={8} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Unit Value:</InputGroup.Addon>
                                                            <Input value={productItem['ProductUnitValue']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={8} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Currency Code:</InputGroup.Addon>
                                                            <Input value={productItem['CurrencyCode']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={8} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Item Origin:</InputGroup.Addon>
                                                            <Input value={productItem['ProductItemOrigin']} readOnly />
                                                        </InputGroup>

                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Unit Weight:</InputGroup.Addon>
                                                            <Input value={productItem['ProductUnitWeight']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Quantity:</InputGroup.Addon>
                                                            <Input value={productItem['ProductQuantity']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Harmonized Code:</InputGroup.Addon>
                                                            <Input value={productItem['ProductHarmonizedCode']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6} sm={24}>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Fabric Content:</InputGroup.Addon>
                                                            <Input value={productItem['ProductFabricContent']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Hazmat:</InputGroup.Addon>
                                                            <Input value={productItem['Hazmat']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Hazmat Code:</InputGroup.Addon>
                                                            <Input value={productItem['HazmatCode']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>FDA Flag:</InputGroup.Addon>
                                                            <Input value={productItem['FDAFlag']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6} sm={24}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>FDA Code:</InputGroup.Addon>
                                                            <Input value={productItem['FDACode']} readOnly />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </FormGroup>

                                        </Panel>




                                    ))
                                }
                            </Panel>


                        ))}
                    </div>
            }



        </Panel>

    );
}


export default StepPackage;