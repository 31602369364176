
import { combineReducers } from 'redux'
import { reducer as authReducer } from 'aws-cognito-redux-saga'
import { reducer as formReducer } from 'redux-form'

import { 
  SignOutReducer,
  UserServicesReducer,
  }                                         from './reducerUser';

import VasReducer                           from './vasReducer';
import TransReducer                         from './transReducer';
import CtrlTwrReducer                       from './ctrlTwrReducer'; 
import LabelGenReducer                      from './labelGenReducer'; 
import InventoryReducer                     from './inventoryReducer';
import VisibilityReducer                    from './visibilityReducer';

const reducers = combineReducers({
  auth: authReducer.reducer,
  form: formReducer,

  //User Reducers
  signOutReducer: SignOutReducer,
  userServicesReducer: UserServicesReducer,

  //VAS Reducers
  vasReducer : VasReducer,

  //Trans Reducers
  transReducer : TransReducer,

  //Control Tower Reducers
  ctrlTwrReducer : CtrlTwrReducer,

  //Label Gen Reducers
  labelGenReducer : LabelGenReducer,


  //Inventory Reducer
  inventoryReducer : InventoryReducer,


  //Visibility Reducer
  visibilityReducer : VisibilityReducer,

})

export default reducers

