
import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import NewUserSignUpContainer from '../../../components/componentsUser/NewUserSignUp/NewUserSignUpContainer';

class userSignUpView extends PureComponent {


    render() {

        return(
            <AnimatedView>

                <NewUserSignUpContainer />

            </AnimatedView>



        );
    }
}

export default userSignUpView;

