/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';

/* ################################### VISIBILITY ACTIONS TYPES ################################### */
export const ON_ERROR = 'ON_ERROR';


export const VISIBILITY_DASHBOARD_STATS_GET = 'VISIBILITY_DASHBOARD_STATS_GET';
export const VISIBILITY_DASHBOARD_STATS_COMPLETE = 'VISIBILITY_DASHBOARD_STATS_COMPLETE';
export const VISIBILITY_DASHBOARD_STATS_ERROR = 'VISIBILITY_DASHBOARD_STATS_ERROR';

export const VISIBILITY_DASHBOARD_PACKAGE_LIST_GET = 'VISIBILITY_DASHBOARD_PACKAGE_LIST_GET';
export const VISIBILITY_DASHBOARD_PACKAGE_LIST_COMPLETE = 'VISIBILITY_DASHBOARD_PACKAGE_LIST_COMPLETE';
export const VISIBILITY_DASHBOARD_PACKAGE_LIST_ERROR = 'VISIBILITY_DASHBOARD_PACKAGE_LIST_ERROR';

export const VISIBILITY_PACKAGE_GET = 'VISIBILITY_PACKAGE_GET';
export const VISIBILITY_PACKAGE_COMPLETE = 'VISIBILITY_PACKAGE_COMPLETE';
export const VISIBILITY_PACKAGE_ERROR = 'VISIBILITY_PACKAGE_ERROR';

export const VISIBILITY_PACKAGE_UPS_EVENTS_GET = 'VISIBILITY_PACKAGE_UPS_EVENTS_GET';
export const VISIBILITY_PACKAGE_UPS_EVENTS_COMPLETE = 'VISIBILITY_PACKAGE_UPS_EVENTS_COMPLETE';
export const VISIBILITY_PACKAGE_UPS_EVENTS_ERROR = 'VISIBILITY_PACKAGE_UPS_EVENTS_ERROR';





