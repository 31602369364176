export const LabelGenResponsePackageLabelsDataElements = [
    {
        dBKey: 'CreatedDT',
        displayName: 'Label Create DT',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },

    
    {
        dBKey: 'TrackingNumber',
        displayName: 'Tracking Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },



    // {
    //     dBKey: 'Environment',
    //     displayName: 'Environment',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 120
    // },


    {
        dBKey: 'Package_PackageWeight_Weight',
        displayName: 'Package Weight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Package_PackageWeight_UOM_Code',
        displayName: 'Weight UOM',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Package_Dimensions_Length',
        displayName: 'Package Length',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Package_Dimensions_Width',
        displayName: 'Package Width',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Package_Dimensions_Height',
        displayName: 'Package Height',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Package_Dimensions_UOM_Code',
        displayName: 'Dimensional UOM',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },



    {
        dBKey: 'ShipTo_Name',
        displayName: 'Ship To Name',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ShipFrom_Name',
        displayName: 'Ship From Name',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 400
    },
   
    {
        dBKey: 'CarrierName',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'CarrierServiceName',
        displayName: 'Service',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
]


export default LabelGenResponsePackageLabelsDataElements

