import { connect } from 'react-redux';
import React, { Component } from 'react';
import Reporting from './Reporting'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryContainerLoading: store.inventoryReducer.isInventoryContainerLoading,
    InventoryContainerData: store.inventoryReducer.InventoryContainerData,

    InventoryContainerPostSuccessful: store.inventoryReducer.InventoryContainerPostSuccessful,
    NewContainerId: store.inventoryReducer.NewContainerId,

    InventoryContainerPostSuccessful2: store.inventoryReducer.InventoryContainerPostSuccessful2,
    NewContainerId2: store.inventoryReducer.NewContainerId2,

    isInventoryContainerSkuLoading: store.inventoryReducer.isInventoryContainerSkuLoading,
    InventoryContainerSkuData: store.inventoryReducer.InventoryContainerSkuData,



    InventoryContainerSkuPostSuccessful: store.inventoryReducer.InventoryContainerSkuPostSuccessful,

    isInventorySkuRecoveryLoading: store.inventoryReducer.isInventorySkuRecoveryLoading,
    InventorySkuRecoveryData: store.inventoryReducer.InventorySkuRecoveryData,

    // InventorySkuRecoveryPostSuccessful: store.inventoryReducer.InventorySkuRecoveryPostSuccessful,

    InventorySkuWarehouseLocationPostSuccessful: store.inventoryReducer.InventorySkuWarehouseLocationPostSuccessful,


    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,


    isInventoryReportingInboundLoading: store.inventoryReducer.isInventoryReportingInboundLoading,
    InventoryReportingInboundData: store.inventoryReducer.InventoryReportingInboundData,


    isInventoryReportingStorageLoading: store.inventoryReducer.isInventoryReportingStorageLoading,
    InventoryReportingStorageData: store.inventoryReducer.InventoryReportingStorageData
});

const mapDispatchToProps = dispatch =>
({

    inventoryReportingInboundGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _StartRecoveryDt, _EndRecoveryDt) => {
        dispatch(inventoryActions.inventoryReportingInboundGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _StartRecoveryDt, _EndRecoveryDt))
    },


    inventoryReportingStorageGet: (_email, _authToken, _WarehouseID, _CustomerID, _StartDT, _EndDT) => {
        dispatch(inventoryActions.inventoryReportingStorageGet(_email, _authToken, _WarehouseID, _CustomerID, _StartDT, _EndDT))
    },

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryContainerGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryContainerGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },

    inventoryContainerPost: (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized) => {
        dispatch(inventoryActions.inventoryContainerPost(_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized))
    },
    inventoryContainerPost2: (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized) => {
        dispatch(inventoryActions.inventoryContainerPost2(_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized))
    },

    inventorySkuPost: (_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) => {
        dispatch(inventoryActions.inventorySkuPost(_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes))
    },


    inventoryContainerSkuGet: (_email, _authToken, _ContainerID, _SkuID) => {
        dispatch(inventoryActions.inventoryContainerSkuGet(_email, _authToken, _ContainerID, _SkuID))
    },

    inventoryContainerSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _SkuID, _SKU, _Units, _Cases, _Pallets, _Delete, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) => {
        dispatch(inventoryActions.inventoryContainerSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _SkuID, _SKU, _Units, _Cases, _Pallets, _Delete, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes))
    },

    inventorySkuRecoverGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container) => {
        dispatch(inventoryActions.inventorySkuRecoverGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container))
    },




    inventorySkuWarehouseLocationPost: (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationPost(_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription))
    },

    // inventorySkuRecoveryPost: (_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate) => {
    //     dispatch(inventoryActions.inventorySkuRecoveryPost(_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate))
    // },

    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

});

class ReportingContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.handleUrlParam();
        // this.props.userWarehousesGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }


        if (prevProps.InventoryContainerPostSuccessful !== this.props.InventoryContainerPostSuccessful && this.props.InventoryContainerPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }




    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramReportNav = queryStringParse(values.reportnav)
        }
        catch (e) {
            var paramReportNav = null
        }

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }

        try {
            var paramSkuID = queryStringParse(values.skuid)
        }
        catch (e) {
            var paramSkuID = null
        }
        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }

        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }

        try {
            var paramRecoveryDtStart = formatDateForAPI(values.recoverydtstart)
        }
        catch (e) {
            var paramRecoveryDtStart = null
        }
        try {
            var paramRecoveryDtEnd = formatDateForAPI(values.recoverydtend)
        }
        catch (e) {
            var paramRecoveryDtEnd = null
        }


        if (paramRecoveryDtEnd !== null && paramRecoveryDtStart === null) {
            window.alert('Please select start date.')
        }
        else {
            if (paramReportNav === 'inbound') {
                this.props.inventoryReportingInboundGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, paramContainer, paramStatus, paramSkuID, paramSku, paramRecoveryDtStart, paramRecoveryDtEnd)
            }
            else if (paramReportNav === 'storage') {
                this.props.inventoryReportingStorageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramRecoveryDtStart, paramRecoveryDtEnd)
            }
        }







    }



    render() {

        return (

            <Reporting
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isInventoryContainerLoading={this.props.isInventoryContainerLoading}
                InventoryContainerData={this.props.InventoryContainerData}

                inventoryContainerPost={this.props.inventoryContainerPost}

                InventoryContainerPostSuccessful={this.props.InventoryContainerPostSuccessful}
                NewContainerId={this.props.NewContainerId}

                inventoryContainerPost2={this.props.inventoryContainerPost2}
                InventoryContainerPostSuccessful2={this.props.InventoryContainerPostSuccessful2}
                NewContainerId2={this.props.NewContainerId2}

                inventoryContainerSkuGet={this.props.inventoryContainerSkuGet}
                isInventoryContainerSkuLoading={this.props.isInventoryContainerSkuLoading}
                InventoryContainerSkuData={this.props.InventoryContainerSkuData}

                inventorySkuPost={this.props.inventorySkuPost}

                inventorySkuRecoverGet={this.props.inventorySkuRecoverGet}
                isInventorySkuRecoveryLoading={this.props.isInventorySkuRecoveryLoading}
                InventorySkuRecoveryData={this.props.InventorySkuRecoveryData}

                inventoryContainerSkuPost={this.props.inventoryContainerSkuPost}

                InventoryContainerSkuPostSuccessful={this.props.InventoryContainerSkuPostSuccessful}

                InventorySkuWarehouseLocationPostSuccessful={this.props.InventorySkuWarehouseLocationPostSuccessful}
                inventorySkuWarehouseLocationPost={this.props.inventorySkuWarehouseLocationPost}

                // inventorySkuRecoveryPost={this.props.inventorySkuRecoveryPost}
                // InventorySkuRecoveryPostSuccessful={this.props.InventorySkuRecoveryPostSuccessful}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}




                inventoryReportingInboundGet={this.props.inventoryReportingInboundGet}

                isInventoryReportingInboundLoading={this.props.isInventoryReportingInboundLoading}
                InventoryReportingInboundData={this.props.InventoryReportingInboundData}


                inventoryReportingStorageGet={this.props.inventoryReportingStorageGet}

                isInventoryReportingStorageLoading={this.props.isInventoryReportingStorageLoading}
                InventoryReportingStorageData={this.props.InventoryReportingStorageData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportingContainer));



