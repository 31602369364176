import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Tree,
    Panel,
    InputNumber,
    InputGroup,
    Input,
    Row,
    Col

} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textNumbersSymbolsInputmask, limitCharacterLength } from '../../../../resources/logic/inputMasks';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryContainerSkuDataElements from '../../../dataComponentsInventory/InventoryContainerSkuDataElements';

const tableIdCol = "SkuID"

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const dropDownLclFcl = [
    {
        "label": "LCL",
        "value": "LCL"
    },
    {
        "label": "FCL",
        "value": "FCL"
    }
]


// const treeRef = React.useRef();
// const [index, setIndex] = React.useState(1);

// function onClick={() => {
//     // https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#scrolltorow-index-number
//     treeRef.current.list.scrollToRow(index);
//   }}

const mountRef = React.createRef();

const AddToContainerDrawer = ({ onChangeAddToContainer, onClickTree, onClickDeleteSku, onChangeProject, onChangeUnits, onChangeCases, onChangePallets, onChangeNotes, onChangeCustomerRef, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showAddToContainerDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Build Container</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                {/* { state.selectedType === null ? <div></div> : */}

                <Panel bordered style={{ height: '420px', marginBottom: 10 }} >
                    <div>
                        <h5 style={{ marginBottom: 5 }}>
                            Add new SKU to {state.selectedLabel}
                        </h5>

                        <FormGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <FormControl placeholder="SKU" name="SKU" value={state.addNewText} onChange={(e) => { onChangeAddToContainer(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                            </InputGroup>

                            <div style={{ marginBottom: '5px', width: '100%' }}>
                                <InputPicker preventOverflow
                                    container={() => mountRef.current}
                                    block
                                    creatable
                                    placeholder={'Customer Ref...'}
                                    value={state.newSkuCustomerRef}
                                    onChange={(e, value, label) => { onChangeCustomerRef(e, value, label) }}
                                    data={props.InventoryCustomerRefData}
                                    cleanable={true}
                                />
                            </div>

                            <div style={{ marginBottom: '5px', width: '100%' }}>
                                <InputPicker preventOverflow
                                    container={() => mountRef.current}
                                    block
                                    creatable
                                    placeholder={'Project...'}
                                    value={state.newSkuProject}
                                    onChange={(e, value, label) => { onChangeProject(e, value, label) }}
                                    data={props.InventoryProjectData}
                                    cleanable={true}
                                />
                            </div>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Expected Units:</InputGroup.Addon>
                                {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeUnits(e)} scrollable={false} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Expected Cases:</InputGroup.Addon>
                                {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeCases(e)} scrollable={false} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Expected Pallets:</InputGroup.Addon>
                                <InputNumber defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangePallets(e)} scrollable={false} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Notes:</InputGroup.Addon>
                                <Input onChange={(e) => { onChangeNotes(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e),400)} />
                            </InputGroup>

                        </FormGroup>

                        <FormGroup>
                            <ButtonToolbar>
                                <Button onClick={confirm} appearance="primary">Add</Button>
                                <Button onClick={close} appearance="default">Close</Button>
                            </ButtonToolbar>
                        </FormGroup>

                    </div>

                </Panel>





                <FormGroup>
                    <Panel bordered  >

                        <Row>
                            {RTableGeneral(
                                tableIdCol,     //   keyColumn,
                                props.InventoryContainerSkuData,     //   tableData,
                                InventoryContainerSkuDataElements,       //   DataElements,
                                props.isInventoryContainerSkuLoading,        //   loading,

                                props.isMobile,        //   isMobile,
                                props.history,     //   history,

                                null,     //   sortColumn,
                                null,       //   sortType,
                                null,      //   handleSortColumn,

                                null, // checkColEntry,
                                null,       //   checkedKeys,
                                null,       //   handleCheckAll,
                                null,       //   handleCheck,

                                false,      //   boolSelectButton,
                                null,       //   onClickSelect,
                                null,       //   selectButtonText,
                                null,       //   selectHighlightRowKey,

                                true,       //   link1bool,
                                'Delete',     //   link1text,
                                onClickDeleteSku,     //   link1onClick,

                                false,       //   link2bool,
                                null,       //   link2text,
                                null  //   link2onClick

                            )}
                        </Row>


                    </Panel>
                </FormGroup>


            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer >

);



AddToContainerDrawer.defaultProps = {
    show: false
};

export default AddToContainerDrawer;


