import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withRouter } from 'react-router-dom';
import RButtonPrimary from '../../componentsGeneral/Rsuite/Button/RButtonPrimary';
import { Row, Col, Panel, PanelGroup } from 'rsuite';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import UserAccountWarehouseDataElements from '../../dataComponentsUser/UserAccountWarehouseDataElements';

const tableIdCol = "WarehouseID"

class MyProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      warehouseArray: [],

    };
    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);

    this.onClickButtonDelete = this.onClickButtonDelete.bind(this);
  }


  // Table Sort
  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });
    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }



  onClickButtonDelete = (e) => {
    if (window.confirm('Delete Your Account?')) {

      this.props.postDeleteUser(this.props.userSignInData.EmailAddr, this.props.userSignInData.AuthToken, this.props.userSignInData.UserID)
      
      this.props.userAwsCognitoDeleteUserPost(this.props.userSignInData.EmailAddr);

    }
  }
  
  onClickChangePW = (e) => {
    if (window.confirm('Change Password?')) {
      this.props.history.push('/wms/userchangepassword')
    }
  }

  componentDidMount() {

    this.getsessionStorageWarehouse() //this will preserve menu selection

  }

  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }
  }


  render() {
    const { sortColumn, sortType } = this.state;

    let myProfileDiv;
    let buttonsDiv;

    // if (this.props.isUserAccountsLoading) {
    //   myProfileDiv=
    //     <div align="center">
    //     <section className="panel">
    //       </section>
    //       <Loader 
    //         type="TailSpin"
    //         color="#3396E5"
    //         height="50"	
    //         width="50"
    //       /> 
    //     </div>
    // }
    // else 
    if (typeof (this.props.userSignInData) !== 'undefined' && this.props.userSignInData != null) {
      buttonsDiv = <Row>
        <Col md={24} sm={24} >
          <Panel bordered style={{ marginBottom: '10px' }}>
            <Row>
              <Col md={12} sm={24} >
                {RButtonPrimary(this.props.isMobile, 'Change Password', this.onClickChangePW)}
              </Col>
              <Col md={12} sm={24} >
                {RButtonPrimary(this.props.isMobile, 'Delete Account', this.onClickButtonDelete)}
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>


      myProfileDiv = <div>
        <PanelGroup>
          <Row style={{ marginBottom: 10 }}>
            <Col md={12} sm={24}>
              <Panel bordered header="Personal Information" style={{ marginBottom: '10px' }}>
                First: {this.props.userSignInData.FirstName}
                <br />
                Last: {this.props.userSignInData.LastName}
                <br />
              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered header="Account Information" style={{ marginBottom: '10px' }}>
                Email: {this.props.userSignInData.EmailAddr}
                <br />
                Admin: {this.props.userSignInData.Admin === true ? "True" : (this.props.userSignInData.Admin === false ? "False" : this.props.userSignInData.Admin)}
                <br />
                {/* Permissions: {this.props.userSignInData.RoleName} */}
              </Panel>
            </Col>
          </Row>
        </PanelGroup>
      </div>




    }
    else {
      myProfileDiv = <div></div>;
    }


    let warehouseDiv;
    if (this.state.warehouseArray === null) {
      warehouseDiv = <div align="center"><Loader /></div>
    }
    else if (typeof (this.state.warehouseArray) !== 'undefined' && this.state.warehouseArray != null) {


      warehouseDiv = <div style={{ marginBottom: '10px' }}>
        <Panel bordered style={{ marginBottom: '10px' }} header={<Row>Warehouse</Row>}>

          {RTableGeneral(
            tableIdCol,     //   keyColumn,
            this.state.warehouseArray,     //   tableData,
            UserAccountWarehouseDataElements,       //   DataElements,
            this.props.isAccountDetailsUserWarehouseLoading,        //   loading,

            this.props.isMobile,        //   isMobile,
            this.props.history,     //   history,

            sortColumn,     //   sortColumn,
            sortType,       //   sortType,
            this.handleSortColumn,      //   handleSortColumn,

            false,      //   checkColEntry,
            null,       //   checkedKeys,
            null,       //   handleCheckAll,
            null,       //   handleCheck,

            false,      //   boolSelectButton,
            null,       //   onClickSelect,
            null,       //   selectButtonText,
            null,       //   selectHighlightRowKey,

            false,       //   link1bool,
            null,     //   link1text,
            null,     //   link1onClick,

            false,       //   link2bool,
            null,       //   link2text,
            null  //   link2onClick

          )}

        </Panel>
      </div>
    }
    else {
      warehouseDiv = <div></div>
    }


    return (
      <div>
        <h3>
          My Profile
        </h3>

        {buttonsDiv}

        {myProfileDiv}

        {warehouseDiv}

      </div>

    );
  }
}

export default withRouter(MyProfile);






















