import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel, Divider, Input, IconButton } from 'rsuite';
import { numbersInputMask } from '../../../resources/logic/inputMasks';
import CloseIcon from '@rsuite/icons/Close';

function limitNumberToExistingValueLocation(e, locationitem, item, originalProps) {
    var itemIndex = originalProps[item.PoID].findIndex(({ WarehouseLocationID }) => WarehouseLocationID === locationitem['WarehouseLocationID']);

    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)

    try {
        var limitValue = originalProps[item.PoID][itemIndex]['Cases']
    }
    catch (e) {
        var limitValue = 0
    }

    if (nonNegativeValue <= limitValue) {
        return nonNegativeValue
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}


function limitNumberToExistingValue(e, item) {
    try {
        // var limitValue = item['CasesReceived'] - item['CasesLoaded']
        var limitValue = item['InWarehouseCases']

    }
    catch (e) {
        var limitValue = 0
    }
    if (e.target.value <= limitValue) {
        return e.target.value
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }

}


function AddNewModal({
    addNewModalShown,
    handleSave,
    handleClose,
    onChangeExisting,
    onChangeLocation,
    onClickRemoveFromAdd,
    resetRows,
    state, ...props

}) {
    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={addNewModalShown} onHide={handleClose} onExited={resetRows} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>Add PO: {state.item.PO}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Panel bordered style={{ marginBottom: '15px' }}>
                        <Row>
                            <Col md={24} sm={24}>
                                <FormGroup >
                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Total Cases:</InputGroup.Addon>
                                        <InputNumber readOnly value={state.item.NewCasesReceived} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, state.item, 'NewCasesReceived')} scrollable={false} onInput={(e) => e.target.value = limitNumberToExistingValue(e, state.item)} />
                                    </InputGroup>

                                    <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                        <InputGroup.Addon>Total Pallets:</InputGroup.Addon>
                                        <InputNumber readOnly value={state.item.NewPallets} className={'custom-input-number'} onChange={(e) => onChangeExisting(e, state.item, 'NewPallets')} scrollable={false} />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Divider />


                        {
                            state.item.PoID in state.LocationDict ?
                                state.LocationDict[state.item.PoID].map(locationitem => (
                                    <div>
                                        <Row>
                                            <Col md={7} sm={7}>
                                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                    {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                    <Input value={locationitem['WarehouseLocationName']} readOnly />
                                                </InputGroup>
                                            </Col>
                                            <Col md={7} sm={7}>
                                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                    <InputGroup.Addon>Cases:</InputGroup.Addon>
                                                    <InputNumber defaultValue={locationitem['Cases']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, locationitem, 'Cases', locationitem['WarehouseLocationID'])} scrollable={false} onInput={(e) => e.target.value = limitNumberToExistingValueLocation(e, locationitem, state.item, props.PoLocationDataGrouped)} />
                                                </InputGroup>
                                            </Col>
                                            <Col md={7} sm={7}>
                                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                    <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                                    <InputNumber defaultValue={locationitem['Pallets']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, locationitem, 'Pallets', locationitem['WarehouseLocationID'])} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                                </InputGroup>
                                            </Col>

                                            <Col md={2} sm={2}>

                                                <IconButton size="md" icon={<CloseIcon />}  onClick={(e) => onClickRemoveFromAdd(e, locationitem, locationitem['WarehouseLocationID'])} />
                                                
                                            </Col>


                                        </Row>
                                    </div>
                                ))
                                :

                                <div></div>
                        }


                    </Panel>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default AddNewModal;

