import React, {
    PureComponent
} from 'react';
import { Modal, Button, ControlLabel, Col, FormGroup, InputGroup, InputPicker, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import BarcodeMultipleCard from '../Barcode/BarcodeMultipleCard';


const mountRef = React.createRef();

function PrintBarcodesModal({
    printBarcodeModalShown,
    closePrintBarcodes,
    barcodePrintList,
    // onPrintBarcodes,
    state, ...props
}) {



    const barcodes = barcodePrintList
        ? barcodePrintList.split(',').map(barcode =>
            <BarcodeMultipleCard barcode={barcode.trim()} key={barcode} />
        )
        : [];

    function onPrintBarcode() {

        // var container = document.getElementById("div-svg");
        // var mySVG = document.getElementById("barcode-canvas");
        // var width = "100%";
        // var height = "100%";
        // var printWindow = window.open('', 'PrintMap',
        //     'width=' + width + ',height=' + height);
        // printWindow.document.writeln(container.innerHTML);
        // printWindow.document.close();
        // printWindow.print();
        // printWindow.close();



        var container = document.getElementById("div-svg");
        var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();

    }



    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={printBarcodeModalShown} onHide={closePrintBarcodes} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>Print Barcodes</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <FormGroup>

                        <div id="div-svg" style={{ display: 'flex', flexDirection: 'column' }}>
                            {barcodes}
                        </div>

                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    {barcodePrintList && (
                        <Button onClick={(e) => { onPrintBarcode(e) }} appearance="primary">
                            Print Barcodes
                        </Button>
                    )}

                    <Button onClick={closePrintBarcodes} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default PrintBarcodesModal;

