export const Edi204OIDDataElements = [
    {
        dBKey: 'ReferenceIdentification',
        displayName: 'Reference Identification',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },

    {
        dBKey: 'PurchaseOrderNumber',
        displayName: 'Purchase Order Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'UnitorBasisforMeasurementCode',
        displayName: 'Unit or Basis for Measurement Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 240
    },
    {
        dBKey: 'Quantity',
        displayName: 'Quantity',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'WeightUnitCode',
        displayName: 'Weight Unit Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },


    {
        dBKey: 'Weight',
        displayName: 'Weight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'VolumeUnitQualifier',
        displayName: 'Volume Unit Qualifier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },    
    {
        dBKey: 'Volume',
        displayName: 'Volume',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },    
   
]


export default Edi204OIDDataElements

