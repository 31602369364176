import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,

    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Loader,
    InputPicker,

    Divider,
    IconButton

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, textNumbersSymbolsInputmask } from '../../../resources/logic/inputMasks';
import RButtonSmall from '../../componentsGeneral/Rsuite/Button/RButtonSmall';
import PlusIcon from '@rsuite/icons/Plus';
import { convertNanToZero } from '../../../resources/logic/functions';
import CloseIcon from '@rsuite/icons/Close';

const styles = {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
};

// style={{ overflowX: 'hidden!important' }} 

const mountRef = React.createRef();

const ReceiveContainerDrawer = ({ onChange, onChangeLocation, onClickAddLocation, onClickRemoveFromAdd, show, placement, confirm, close, onClickAddMissing, masterLabel, state, ...props }) => (

    state.showReceiveDrawer === false ?
        <div align="center">
        </div>
        :

        props.isShipmentReceiveLoading ?

            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
            :
            props.isPoLocationLoading ?

                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
                :
                <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '750px' }}    >
                    <Drawer.Header>
                        <Drawer.Title style={{ marginBottom: '15px' }}>Container: {masterLabel}</Drawer.Title>

                        { }

                        <FormGroup>

                            <ButtonToolbar >
                                <Button onClick={confirm} appearance="primary">Save</Button>
                                <Button onClick={close} appearance="default">Cancel</Button>

                                {/* <InputPicker preventOverflow
    container={() => mountRef.current}
                                // placeholder={label}
                                placeholder={'Select Warehouse...'}
                                value={state.ReceiveDrawerWarehouse}
                                onChange={(e) => { onChangeWarehouse(e) }}
                                data={props.UserWarehouseData}
                            
                                cleanable={true}
                            /> */}

                                {/* {state.unsavedReceiveChanges ? '' : <Button style={{ float: 'right' }} onClick={onClickAddMissing} appearance="default">Add Missing</Button>} */}

                            </ButtonToolbar>

                        </FormGroup>
                    </Drawer.Header>

                    <Drawer.Body> <div ref={mountRef}>

                        <Form fluid>

                            {props.ShipmentReceiveData.map(item => (

                                <Row >
                                    <Col md={24} sm={24}>
                                        <Panel bordered style={{ marginBottom: '10px' }}>

                                            <Row>
                                                <Col md={8} sm={24}>
                                                    PO: <strong>{item.PO}</strong>
                                                    <br />
                                                    HBOL: <strong>{item.HBOL}</strong>
                                                    <br />
                                                    MBOL: <strong>{item.MBOL}</strong>
                                                    <br />
                                                    Remaining Cases: <strong>{convertNanToZero(item.CaseCount) - convertNanToZero(item.CasesReceived) - convertNanToZero(state.ReceiveDict[item.PoID].Cases)}</strong>
                                                    <br />
                                                    {/* <Checkbox onChange={(e, value) => { onChange(value, item, 'Customs') }}> Customs Cleared</Checkbox> */}
                                                </Col>

                                                <Col md={16} sm={24}>
                                                    <FormGroup >
                                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                            <InputGroup.Addon>Total Recovered Cases:</InputGroup.Addon>
                                                            {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                                            <InputNumber readOnly value={state.ReceiveDict[item.PoID].Cases} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Cases')} scrollable={false} />
                                                        </InputGroup>
                                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                            <InputGroup.Addon>Total Pallets:</InputGroup.Addon>
                                                            <InputNumber readOnly value={state.ReceiveDict[item.PoID].Pallets} className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Pallets')} scrollable={false} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>


                                            </Row>
                                            <Row>

                                                <Col md={24} sm={24}>
                                                    <FormGroup >
                                                        <InputGroup style={{ marginTop: '5px', width: '100%' }}>
                                                            <InputGroup.Addon>Notes:</InputGroup.Addon>
                                                            <Input onChange={(e) => { onChange(e, item, 'Notes') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                            <Divider />


                                            {state.LocationDict[item.PoID].map(locationitem => (
                                                <div>

                                                    <Row>
                                                        <Col md={5} sm={5}>

                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                <InputPicker preventOverflow
                                                                    container={() => mountRef.current}
                                                                    // placeholder={label}
                                                                    placeholder={'Location...'}
                                                                    value={locationitem['WarehouseLocationID']}
                                                                    onChange={(e) => { onChangeLocation(e, item, locationitem, 'WarehouseLocationID', locationitem['WarehouseLocationID']) }}
                                                                    data={props.WarehouseLocationsData}
                                                                    block
                                                                    cleanable={true}
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md={6} sm={6}>
                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                <InputGroup.Addon>Cases:</InputGroup.Addon>
                                                                <InputNumber value={locationitem['Cases']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, item, locationitem, 'Cases', locationitem['WarehouseLocationID'])} scrollable={false} />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md={6} sm={6}>
                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                                                <InputNumber value={locationitem['Pallets']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, item, locationitem, 'Pallets', locationitem['WarehouseLocationID'])} scrollable={false} />
                                                            </InputGroup>
                                                        </Col>

                                                        <Col md={5} sm={5}>
                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                <Checkbox checked={locationitem['Oversize']} onChange={(e, checked) => onChangeLocation(checked, item, locationitem, 'Oversize', locationitem['WarehouseLocationID'])} >Oversize</Checkbox>
                                                            </InputGroup>
                                                        </Col>

                                                        <Col md={2} sm={2}>

                                                            <IconButton size="md" icon={<CloseIcon />} onClick={(e) => onClickRemoveFromAdd(e, item, locationitem, locationitem['WarehouseLocationID'])} />

                                                        </Col>

                                                    </Row>
                                                    {/* <Row>
                                                <Col md={8} sm={8}>
                                                    <Button onClick={close} appearance="default">+  Add Location</Button>
                                                </Col>
                                            </Row> */}
                                                </div>


                                            ))}


                                            <Row>
                                                <Col md={8} sm={8}>
                                                    <Button onClick={(e) => onClickAddLocation(e, item)} appearance="default">+  Add Location</Button>
                                                </Col>
                                            </Row>

                                        </Panel>


                                    </Col>
                                </Row>





                            ))}



                        </Form>

                    </div> </Drawer.Body>



                    <Drawer.Footer>



                    </Drawer.Footer>


                </Drawer>

);


ReceiveContainerDrawer.defaultProps = {
    show: false
};

export default ReceiveContainerDrawer;


