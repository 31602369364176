import { FormControlLabel } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InputPicker, Loader } from 'rsuite';
import validator from 'validator';
// import { customerConfig } from '../../../app/appCustomerConfig';
import { userInviteNewUserPost } from '../../../redux/actions/actionsUser';
import { formatStringForAPI } from '../../../resources/logic/functions';
import { userAwsCognitoInviteUserPost } from '../../../redux/actions/actionsUser';

const required = value => (value ? undefined : 'Required');
const validateEmail = value => validator.isEmail(value) ? undefined : 'Not Valid Email';

const InviteUser = ({ currentUserEmail, AuthToken }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');

    const [isAdmin, setIsAdmin] = useState(false);
    const [isReadWrite, setIsReadWrite] = useState(true);
    const [isInternalOrganization, setIsInternalOrganization] = useState(true);

    const [moduleArray, setModuleArray] = useState([]);
    const [selectedModule, setSelectedModule] = useState(null);

    const [warehouseArray, setWarehouseArray] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    const [customerArray, setCustomerArray] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);


    const dispatch = useDispatch();

    const isUserInviteCognitoPostLoading = useSelector(state => state.userServicesReducer.isUserInviteCognitoPostLoading);
    const UserInviteCognitoPostSuccessful = useSelector(state => state.userServicesReducer.UserInviteCognitoPostSuccessful);
    const UserInviteCognitoResponseMSG = useSelector(state => state.userServicesReducer.UserInviteCognitoResponseMSG);


    const isUserInviteNewUserPostLoading = useSelector(state => state.userServicesReducer.isUserInviteNewUserPostLoading);
    const UserInviteNewUserPostSuccessful = useSelector(state => state.userServicesReducer.UserInviteNewUserPostSuccessful);


    useEffect(() => {
        if (UserInviteCognitoPostSuccessful) {
            // handle successful invite
            var orgID;
            if (isInternalOrganization) {
                orgID = 1
            } else {
                orgID = null;
            }

            // Save new user to database:
            dispatch(userInviteNewUserPost(currentUserEmail, AuthToken, formatStringForAPI(email), formatStringForAPI(firstname), formatStringForAPI(lastname), null, null, isAdmin, isReadWrite, selectedModule, selectedWarehouse, selectedCustomer, orgID))

            setSuccess('User invited successfully');
            setError(null);
        } else {
            // handle error
            setError(UserInviteCognitoResponseMSG);
        }
    }, [UserInviteCognitoPostSuccessful, UserInviteCognitoResponseMSG]);



    useEffect(() => {
        if (UserInviteNewUserPostSuccessful) {
            setEmail('');
            setFirstname('');
            setLastname('');
        }
    }, [UserInviteNewUserPostSuccessful]);

    useEffect(() => {
        const getSessionStorageWarehouse = () => {
            try {
                let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
                if (warehouseMenuList != null && warehouseMenuList != undefined) {
                    warehouseMenuList = warehouseMenuList.filter(x => x.Admin === true)
                    setWarehouseArray(warehouseMenuList);

                    // Check if the length is one and set selectedWarehouse
                    if (warehouseMenuList.length === 1) {
                        setSelectedWarehouse(warehouseMenuList[0].value);
                    }
                }
                // } catch (e) { }
            } catch (error) {
                console.error(error);
            }

            try {
                let moduleMenuList = JSON.parse(sessionStorage.getItem('AppConfig.ModuleArray'));
                if (moduleMenuList != null && moduleMenuList != undefined) {
                    moduleMenuList = moduleMenuList.filter(x => x.Admin === true)
                    setModuleArray(moduleMenuList);

                    if (moduleMenuList.length === 1) {
                        setSelectedModule(moduleMenuList[0].value);
                    }
                }
            } catch (e) { }

            try {
                let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
                if (customerMenuList != null && customerMenuList != undefined) {
                    customerMenuList = customerMenuList.filter(x => x.Admin === true)
                    setCustomerArray(customerMenuList);

                    if (customerMenuList.length === 1) {
                        setSelectedCustomer(customerMenuList[0].value);
                    }
                }
            } catch (e) { }
        };

        getSessionStorageWarehouse();
    }, []);





    const handleInvite = async (e) => {
        e.preventDefault();

        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        if (!selectedModule || !selectedWarehouse || !selectedCustomer) {
            setError('Please make all selections');
            return;
        }

        dispatch(userAwsCognitoInviteUserPost(email));



    };






    if (isUserInviteCognitoPostLoading) {
        return (

            <Container style={{ width: '100%' }}>
                <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  }}>
                        <Loader
                            type="TailSpin"
                            color="#3396E5"
                            height="50"
                            width="50"
                        />
                        <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait...</p>
                    </div>
                </Paper>
            </Container>
        );
    }




    return (


        <Container style={{ width: '100%' }}>
            <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>
                <h3>Invite New User</h3>
                <Box
                    component="form"
                    onSubmit={handleInvite}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        width: '100%',
                    }}
                >
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        fullWidth
                        style={{ marginBottom: '16px' }}
                    />


                    <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                        <Typography component="div" style={{ width: '50%', marginRight: '8px' }}>
                            <TextField
                                label="First Name"
                                type="text"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                required
                                fullWidth
                                style={{ marginBottom: '16px' }}
                            />
                        </Typography>
                        <Typography component="div" style={{ width: '50%' }}>
                            <TextField
                                label="Last Name"
                                type="text"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                required
                                fullWidth
                                style={{ marginBottom: '16px' }}
                            />
                        </Typography>
                    </Box>





                    <Typography component="div" style={{ width: '100%' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked
                                    checked={isInternalOrganization}
                                    onChange={(e) => setIsInternalOrganization(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={"Evolve" + " (Select if internal user)"}
                            style={{ marginRight: '10px' }}
                        />
                    </Typography>


                    {/* <Typography component="div" style={{ width: '100%' }}>
                        <FormControlLabel
                            control={<Switch checked={isInternalOrganization} onChange={(e) => setIsInternalOrganization(e.target.checked)} color="primary" />}
                            label={customerConfig.customerName} 
                            style={{ marginRight: '10px' }}
                        />
                    </Typography> */}

                    <Typography component="div" style={{ width: '100%' }}>
                        <FormControlLabel
                            control={<Switch checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} color="primary" />}
                            label="Admin (Allows this user to invite other users and give permissions)"
                            style={{ marginRight: '10px' }}
                        />
                    </Typography>


                    <Typography component="div" style={{ width: '100%' }}>

                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked
                                    checked={isReadWrite}
                                    onChange={(e) => setIsReadWrite(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={isReadWrite ? "Read/Write (Allows this user to make updates to data)" : "Read Only (This user may only view data)"}
                        />
                    </Typography>


                    <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                        <Typography component="div" style={{ width: '30%' }}>
                            <Box fontWeight="fontWeightBold" m={1}>Module:</Box>
                            <InputPicker preventOverflow
                                preventOverflow
                                placeholder={'Module...'}
                                value={selectedModule}
                                onChange={(e) => { setSelectedModule(e) }}
                                data={moduleArray}
                                block
                                cleanable={false}
                            />
                        </Typography>
                        <Typography component="div" style={{ width: '30%' }}>
                            <Box fontWeight="fontWeightBold" m={1}>Warehouse:</Box>
                            <InputPicker preventOverflow
                                preventOverflow
                                placeholder={'Warehouse...'}
                                value={selectedWarehouse}
                                onChange={(e) => { setSelectedWarehouse(e) }}
                                data={warehouseArray}
                                block
                                cleanable={false}
                            />
                        </Typography>
                        <Typography component="div" style={{ width: '30%' }}>
                            <Box fontWeight="fontWeightBold" m={1}>Customer:</Box>
                            <InputPicker preventOverflow
                                preventOverflow
                                placeholder={'Customer...'}
                                value={selectedCustomer}
                                onChange={(e) => { setSelectedCustomer(e) }}
                                data={customerArray}
                                block
                                cleanable={false}
                            />
                        </Typography>
                    </Box>

                    <Button type="submit" variant="contained" fullWidth>Invite User</Button>
                    {error && <Typography color="error" style={{ width: '100%' }}>{error}</Typography>}
                    {success && <Typography style={{ color: 'green', width: '100%' }}>{success}</Typography>}
                </Box>
            </Paper>
        </Container>






        // <Container style={{ width: '100%' }}>
        //     <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>
        //         <h3>
        //             Invite New User
        //         </h3>

        //         <Box
        //             component="form"
        //             onSubmit={handleInvite}
        //             style={{
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //                 alignItems: 'flex-start',
        //                 gap: '16px',
        //                 width: '100%',
        //             }}
        //         >
        //             <TextField
        //                 label="Email"
        //                 type="email"
        //                 value={email}
        //                 onChange={(e) => setEmail(e.target.value)}
        //                 required
        //                 fullWidth
        //                 style={{ marginBottom: '16px' }}
        //             />

        //             <Typography component="div" style={{ width: '100%' }}>
        //                 <Box fontWeight="fontWeightBold" m={1}>
        //                     Admin:
        //                 </Box>
        //                 <Switch checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} color="primary" />
        //             </Typography>
        //             <Typography component="div" style={{ width: '100%' }}>
        //                 <Box fontWeight="fontWeightBold" m={1}>
        //                     Read/Write:
        //                 </Box>
        //                 <Switch checked={isReadWrite} onChange={(e) => setIsReadWrite(e.target.checked)} color="primary" />
        //             </Typography>

        //             <Typography component="div" style={{ width: '100%' }}>
        //                 <Box fontWeight="fontWeightBold" m={1}>
        //                     Module:
        //                 </Box>
        //                 <InputPicker preventOverflow
        // preventOverflow
        //                     placeholder={'Module...'}
        //                     value={selectedModule}
        //                     onChange={(e) => { setSelectedModule(e) }}
        //                     data={moduleArray}
        //                     block
        //                     cleanable={false}
        //                 />
        //             </Typography>

        //             <Typography component="div" style={{ width: '100%' }}>
        //                 <Box fontWeight="fontWeightBold" m={1}>
        //                     Warehouse:
        //                 </Box>
        //                 <InputPicker preventOverflow
        // preventOverflow
        //                     placeholder={'Warehouse...'}
        //                     value={selectedWarehouse}
        //                     onChange={(e) => { setSelectedWarehouse(e) }}
        //                     data={warehouseArray}
        //                     block
        //                     cleanable={false}
        //                 />
        //             </Typography>

        //             <Typography component="div" style={{ width: '100%', marginBottom: '15px' }}>
        //                 <Box fontWeight="fontWeightBold" m={1}>
        //                     Customer:
        //                 </Box>
        //                 <InputPicker preventOverflow
        // preventOverflow
        //                     placeholder={'Customer...'}
        //                     value={selectedCustomer}
        //                     onChange={(e) => { setSelectedCustomer(e) }}
        //                     data={customerArray}
        //                     block
        //                     cleanable={false}
        //                 />
        //             </Typography>

        //             <Button type="submit" variant="contained" fullWidth>Invite User</Button>
        //             {error && <Typography color="error" style={{ width: '100%' }}>{error}</Typography>}
        //             {success && <Typography style={{ color: 'green', width: '100%' }}>{success}</Typography>}
        //         </Box>
        //     </Paper>
        // </Container>
    );
};

export default withRouter(InviteUser);