export const CustomsProductCatalogDataElements = [

    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 70
    },    
    {
        dBKey: 'Description',
        displayName: 'Description',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Country of Origin',
        displayName: 'Country of Origin',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 130
    },
    {
        dBKey: 'Content (fabric/material)',
        displayName: 'Content (fabric/material)',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 170
    }, 
    {
        dBKey: 'Construction (knitted, etc)',
        displayName: 'Construction (knitted, etc)',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Manufacturer',
        displayName: 'Manufacturer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'Cost Per Unit',
        displayName: 'Cost Per Unit',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },    
    {
        dBKey: 'Case pack quantity',
        displayName: 'Case pack quantity',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },    
    {
        dBKey: 'PGA code',
        displayName: 'PGA code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },    
    {
        dBKey: 'HTS Code Current',
        displayName: 'HTS Code Current',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },    
    {
        dBKey: 'Duty Amount',
        displayName: 'Duty Amount',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },    





    {
        dBKey: 'Tariff Amount',
        displayName: 'Tariff Amount',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'HTS Code Last Updated',
        displayName: 'HTS Code Last Updated',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 170
    },
    // {
    //     dBKey: 'B1Hdr',
    //     displayName: 'B1Hdr',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 250
    // },



   

    // {
    //     dBKey: 'N9Hdr',
    //     displayName: 'N9Hdr',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },  
    // {
    //     dBKey: 'N901',
    //     displayName: 'Reference Identification Qualifier',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 220
    // },    




    // {
    //     dBKey: 'N905',
    //     displayName: 'Time',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 120
    // },    
    // {
    //     dBKey: 'SEHdr',
    //     displayName: 'SEHdr',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },    
    // {
    //     dBKey: 'SE01',
    //     displayName: 'Number of Included Segments',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },    
    // {
    //     dBKey: 'SE02',
    //     displayName: 'Transaction Set Control Number',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 210
    // },    

]


export default CustomsProductCatalogDataElements

