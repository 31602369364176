import { connect } from 'react-redux';
import React, { Component } from 'react';
import ShipmentDeliveryHistoryChart from './ShipmentDeliveryHistoryChart'
import * as actions from '../../../../redux/actions/ctrlTwrActions';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { formatStringForAPI, queryStringParse, queryStringParseWithZero } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isCtrlTwrChartShipmentDeliveryHistoryLoading: store.ctrlTwrReducer.isCtrlTwrChartShipmentDeliveryHistoryLoading,
    CtrlTwrChartShipmentDeliveryHistoryData: store.ctrlTwrReducer.CtrlTwrChartShipmentDeliveryHistoryData,

});

const mapDispatchToProps = dispatch =>
({
    ctrlTwrChartShipmentDeliveryHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData) => {
        dispatch(actions.ctrlTwrChartShipmentDeliveryHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData))
    },

    ctrlTwrChartShipmentDeliveryHistoryDetailsGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData) => {
        dispatch(actions.ctrlTwrChartShipmentDeliveryHistoryDetailsGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData))
    },
});


class ShipmentDeliveryHistoryChartContainer extends Component {
    constructor(props) {
        super(props);

    }


    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }

    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)


        try {
            var paramPro = formatStringForAPI(values.pro)
        }
        catch (e) {
            var paramPro = null
        }

        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }

        try {
            var paramCountry = queryStringParse(values.country)
        }
        catch (e) {
            var paramCountry = null
        }

        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }

        this.props.ctrlTwrChartShipmentDeliveryHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPro, paramPo, paramCountry, null, null, null, null, null, null, null, null, 0  )
    }


    render() {

        return (

            <ShipmentDeliveryHistoryChart
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isCtrlTwrChartShipmentDeliveryHistoryLoading={this.props.isCtrlTwrChartShipmentDeliveryHistoryLoading}
                CtrlTwrChartShipmentDeliveryHistoryData={this.props.CtrlTwrChartShipmentDeliveryHistoryData}

                ctrlTwrChartShipmentDeliveryHistoryGet={this.props.ctrlTwrChartShipmentDeliveryHistoryGet}

                onClickShipDeliveryHistoryChart={this.props.onClickShipDeliveryHistoryChart}

                ctrlTwrChartShipmentDeliveryHistoryDetailsGet={this.props.ctrlTwrChartShipmentDeliveryHistoryDetailsGet}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentDeliveryHistoryChartContainer));


