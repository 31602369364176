import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel, Divider, Input } from 'rsuite';
import { numbersInputMask } from '../../../resources/logic/inputMasks';


function limitNumberToExistingValueLocation(e, locationitem, item, originalProps) {
    var itemIndex = originalProps[item.PoID].findIndex(({ WarehouseLocationID }) => WarehouseLocationID === locationitem['WarehouseLocationID']);

    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)

    try {
        var limitValue = originalProps[item.PoID][itemIndex]['Cases']
    }
    catch (e) {
        var limitValue = 0
    }

    if (nonNegativeValue <= limitValue) {
        return nonNegativeValue
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }
}


function limitNumberToExistingValue(e, item) {
    try {
        // var limitValue = item['CasesReceived'] - item['CasesLoaded']
        var limitValue = item['InWarehouseCases'] 
    }
    catch (e) {
        var limitValue = 0
    }
    if (e.target.value <= limitValue) {
        return e.target.value
    }
    else {
        window.alert('Must not be greater than ' + limitValue.toString())
        return limitValue
    }

}


function RemoveFromLoadModal({
    removeFromModalShown,
    handleSave,
    handleClose,
    onChangeExisting,
    onChangeLocation,
    titlePO,
    resetRows,
    state, ...props

}) {
    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={removeFromModalShown} onHide={handleClose} onExited={resetRows} style={{ zIndex: '9999999999999' }}>

                {props.PoLoadLocationHistoryData.length > 0 ?
                    <div>

                        <Modal.Header>
                            <Modal.Title>Return {titlePO} to Previous Locations:</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Panel bordered style={{ marginBottom: '15px' }}>
                                {
                                    props.PoLoadLocationHistoryData.map(locationitem => (
                                        <div>
                                            <Row>
                                                <Col md={8} sm={8}>
                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <Input value={locationitem['WarehouseLocationName']} readOnly />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={8} sm={8}>
                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Cases:</InputGroup.Addon>
                                                        <InputNumber defaultValue={locationitem['NetCases']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, locationitem, 'Cases', locationitem['WarehouseLocationID'])} scrollable={false} onInput={(e) => e.target.value = limitNumberToExistingValueLocation(e, locationitem, state.item, props.PoLocationDataGrouped)} />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={8} sm={8}>
                                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                        <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                                        <InputNumber defaultValue={locationitem['NetPallets']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, locationitem, 'Pallets', locationitem['WarehouseLocationID'])} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))
                                }


                            </Panel>

                        </Modal.Body>


                        <Modal.Footer>
                            <Button onClick={handleSave} appearance="primary">
                                Save
                            </Button>
                            <Button onClick={handleClose} appearance="subtle">
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </div>

                    :
                    
                    <div>


                        <div>

                            <Modal.Header>
                                <Modal.Title>Remove {titlePO} from Load?</Modal.Title>
                                <Modal.Title>No previous locations exist, will be set to No Location</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                            </Modal.Body>


                            <Modal.Footer>
                                <Button onClick={handleSave} appearance="primary">
                                    Save
                                </Button>
                                <Button onClick={handleClose} appearance="subtle">
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </div>



                    </div>}


            </Modal>
        </div>



    );
}


export default RemoveFromLoadModal;

