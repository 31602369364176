import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../../components/componentsGeneral/AnimatedView/AnimatedView';
import CaseDetailContainer from '../../../../components/componentsInventoryManagement/_Lot_Tracking_Components/CaseDetail/CaseDetailContainer';

class invManCaseDetailView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    componentDidMount() {
    }

    render() {

        return (
            <AnimatedView>
                <CaseDetailContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default invManCaseDetailView;
