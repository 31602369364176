import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Toggle

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const mountRef = React.createRef();

const AccountDetailsEditDrawer = ({ onChangeAccountEdit, title, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showAccountDetailsEditDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>{title}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}> 

            <Form fluid>

                <FormGroup>
                    <ControlLabel>Admin</ControlLabel>
                    <Toggle appearance="primary" checkedChildren="On" block  defaultChecked={state.AccountAdmin ? true : false }      onChange={(e) => onChangeAccountEdit(e, 'AccountAdmin')} size={props.isMobile ? 'sm' : 'md'} style={props.isMobile ? { marginBottom: '5px' } : { fontWeight: 'bold' }} /> Admin
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={confirm} appearance="primary">Save</Button>
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>


        </div> </Drawer.Body> 
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer>

);




AccountDetailsEditDrawer.defaultProps = {
    show: false
};

export default AccountDetailsEditDrawer;


