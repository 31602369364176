import React, {
  PureComponent
} from 'react';
import { Input, InputGroup, Icon, ControlLabel, Tooltip, Whisper } from 'rsuite';
import { formatStringForAPI } from '../../../../resources/logic/functions';
// import { lettersNumbersDashesInputMask } from '../../../../resources/logic/inputMasks';
import { lettersNumbersDashesInputMask } from '../../../../resources/logic/inputMasks';

const styles = {
  // width: 300,
  marginBottom: 10
};

const divStyle = {
  // marginLeft: 5,
  // marginRight: 5
};

const tooltipSearch = (
  <Tooltip>
    Search
  </Tooltip>
);

const tooltipClear = (
  <Tooltip>
    Clear
  </Tooltip>
);


function onClickSearch(e, history, querySearch, searchValue) {
  if (searchValue != '' && searchValue != null) {
    var param = null
    param = formatStringForAPI(searchValue)
    try {
      history.push({
        // search: querySearch + '=\'' + param +'\'' 
        search: querySearch + '=' + param
      });
    }
    catch (e) { }
  }
  else {
    window.alert('Enter Search Value')
  }
}

function onClickReset(e, history, onChange) {
  onChange('')
  try {
    history.push({
    });
  }
  catch (e) { }
}

function RSearchInputNumberAsString(
  isMobile,
  label,
  onChange,
  history,
  querySearch,
  searchValue,
  // onClick
  // inputMask
) {

  let rInput;

  try {
    rInput = <div style={divStyle}>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup style={styles}>

        <Input value={searchValue} onChange={(e) => { onChange(e) }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} onKeyUp={(e) => (e.key === 'Enter' ? onClickSearch(e, history, querySearch, searchValue) : null)} />

        <Whisper placement="top" trigger="hover" speaker={tooltipSearch}>
          <InputGroup.Button onClick={(e) => { onClickSearch(e, history, querySearch, searchValue) }}   >

            <Icon icon="search" />
          </InputGroup.Button>
        </Whisper>

      </InputGroup>
    </div>
  }
  catch (e) { }

  return (

    rInput

  )
}


export default RSearchInputNumberAsString;



