import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, Loader, Button, ControlLabel, InputGroup, Input } from 'rsuite';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatStringForAPI, formatDateForUrlSearch, formatNumberForAPI, isNumeric, formatDateTimeForUrlSearch, replaceNegativeWithZero, findLowestMissingNumber, queryStringParseWithZero } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { ctrlTwrShipmentStatus } from '../../../app/appConfig';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import MilestoneEventEntryDrawer from './MilestoneEventEntryDrawer';
import RSearchInput from '../../componentsGeneral/Rsuite/SearchInput/RSearchInput';
import CtrlTwrShipmentDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentDataElements';
import MilestoneEventModal from './MilestoneEventModal';
import { dbValues } from '../../../app/appConfig';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "ProRef"

const mountRef = React.createRef();

class MilestoneEventEntry extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},


            // Table Check Box
            // checkedKeys: [],

            //Search Values
            searchPro: '',
            searchPO: '',
            // searchCustomer: '',
            searchCountry: '',
            searchStatus: '',
            isFilterShown: true,

            //Ship Management Drawer
            showMilestoneEntryDrawer: false,
            ShipMilestonesExistingDict: {},
            unsavedShipManagmentChanges: false,
            ShipManagementProRef: null,
            AddNewLegDict: {},


            //Add new Milestone Modal
            addNewMilestoneModalShown: false,

            newMilestoneModalLegID: null,
            newMilestoneModalMilestoneID: null,
            newMilestoneModalException: false,

            newMilestoneModalModeID: null,
            newMilestoneModalMode: null,
            newMilestoneModalMilestoneDT: null,
            newMilestoneModalMilestoneTimezone: null,
            newMilestoneModalCountryCodeID: null,
            newMilestoneModalCity: null,
            newMilestoneModalState: null,
            newMilestoneModalStateCodeID: null,
            newMilestoneModalZip: null,
            newMilestoneModalSeaportCodeID: null,
            newMilestoneModalAirportCodeID: null,
            newMilestoneModalWarehouseID: null,

            


            //Milestone Event Entry

            //Receive Container Drawer
            ReceiveDict: {},
            LocationDict: {},
            unsavedShipManagmentChanges: false,

            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,


        };
        // // Table Check Box
        // this.handleCheckAll = this.handleCheckAll.bind(this);
        // this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);


        //Search Values
        this.onChangeSearchPro = this.onChangeSearchPro.bind(this);
        this.onChangeSearchCustomer = this.onChangeSearchCustomer.bind(this);
        this.onChangeSearchCountry = this.onChangeSearchCountry.bind(this);
        this.onChangeSearchPO = this.onChangeSearchPO.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);

        //Ship Management Drawer
        this.onClickOpenShipManagmenetDrawer = this.onClickOpenShipManagmenetDrawer.bind(this);
        this.onChangeMilestoneEventEntry = this.onChangeMilestoneEventEntry.bind(this);
        this.onClickMilestoneEventEntryDrawerSave = this.onClickMilestoneEventEntryDrawerSave.bind(this);
        this.onClickAddMilestone = this.onClickAddMilestone.bind(this);
        this.onClickAddMissingMilestone = this.onClickAddMissingMilestone.bind(this);
        this.onClickShowAddLeg = this.onClickShowAddLeg.bind(this);
        this.closeMilestoneEventEntryDrawer = this.closeMilestoneEventEntryDrawer.bind(this);
        this.setMilestoneEventEntryDrawerToClose = this.setMilestoneEventEntryDrawerToClose.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);

        //Add new Milestone Modal
        this.handleSaveAddNewModal = this.handleSaveAddNewModal.bind(this);
        this.handleCloseAddNewModal = this.handleCloseAddNewModal.bind(this);
        this.onChangeAddNewMilestone = this.onChangeAddNewMilestone.bind(this);

    }



    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection

        this.loadSortFromStorage()
        this.updateParamsFromURL()

    }

    getsessionStorageWarehouse() {

        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }

    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = queryStringParse(values.pro)
        }
        catch (e) {
            var paramPro = null
        }
        if (paramPro != null) {
            this.onChangeSearchPro(paramPro)
        }

        try {
            var paramPO = queryStringParse(values.po)
        }
        catch (e) {
            var paramPO = null
        }
        if (paramPO != null) {
            this.onChangeSearchPO(paramPO)
        }

        // try {
        //     var paramCustomer = queryStringParse(values.customer)
        // }
        // catch (e) {
        //     var paramCustomer = null
        // }
        // if (paramCustomer != null) {
        //     this.onChangeSearchCustomer(paramCustomer)
        // }

        try {
            var paramCountry = queryStringParse(values.country)
        }
        catch (e) {
            var paramCountry = null
        }
        if (paramCountry != null) {
            this.onChangeSearchCountry(paramCountry)
        }


        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

    }




    componentDidUpdate(prevProps, prevState) {

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }


        if (prevProps.CtrlTwrShipmentLegsData !== this.props.CtrlTwrShipmentLegsData && this.props.CtrlTwrShipmentLegsData.length > 0) {
            this.preloadShipManagement()
        }


        if (prevProps.CtrlTwrShipmentLegsDataPostSuccessful !== this.props.CtrlTwrShipmentLegsDataPostSuccessful && this.props.CtrlTwrShipmentLegsDataPostSuccessful) {
            setTimeout(() => {
                try {
                    this.setMilestoneEventEntryDrawerToClose();
                }
                catch (e) { }
            }, 100);

        }

        if (prevProps.CtrlTwrShipmentLegsPostSuccessful !== this.props.CtrlTwrShipmentLegsPostSuccessful && this.props.CtrlTwrShipmentLegsPostSuccessful) {
            setTimeout(() => {
                try {
                    this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef)
                }
                catch (e) { }
            }, 100);

        }

        if (prevProps.CtrlTwrShipmentLegsMilestonesPostSuccessful !== this.props.CtrlTwrShipmentLegsMilestonesPostSuccessful && this.props.CtrlTwrShipmentLegsMilestonesPostSuccessful) {
            setTimeout(() => {
                try {
                    this.props.ctrlTwrShipmentLegsMilestonesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.ShipManagementProRef, null)
                }
                catch (e) { }
            }, 100);

        }


        // if (prevProps.isCtrlTwrShipmentLoading === true && this.props.isCtrlTwrShipmentLoading === false && this.state.searchPro !== '' && this.props.CtrlTwrShipmentData.length == 0) {
        //     window.alert('Not found.')
        // }
        // if (prevProps.ContainerPostSuccessful !== this.props.ContainerPostSuccessful && this.props.ContainerPostSuccessful && this.props.isCtrlTwrShipmentLoading === false) {
        //     // try {
        //     this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.NewContainerId, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        //     this.setNewContainerID(this.props.NewContainerId)
        //     // }
        //     // catch (e) { }

        //     this.setStateCloseAddNewDrawer()

        //     // if (window.confirm('Build new container?')) {
        //     this.onClickAddToContainer()
        //     // }

        //     this.setInitialTreeSelection(this.props.NewContainerId, this.state.Container)
        // }

        // new container id needs to be selected row not row zero
        // if (prevProps.MbolPostSuccessful !== this.props.MbolPostSuccessful && this.props.MbolPostSuccessful) {
        //     try {
        //         this.setNewMbolTreeSelection(this.props.NewMbolId, this.state.addNewText)
        //     }
        //     catch (e) { }

        //     setTimeout(() => {
        //         try {
        //             this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        //         }
        //         catch (e) { }
        //     }, 100);


        // }


        // if (prevProps.PoPostSuccessful !== this.props.PoPostSuccessful && this.props.PoPostSuccessful) {
        //     try {
        //         this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.EditContainerID, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        //         this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        //         this.props.transProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

        //     }
        //     catch (e) { }
        // }


    }




    // // Table Check Box
    // handleCheckAll(value, checked, data) {
    //     const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
    //     this.setState({
    //         checkedKeys
    //     });
    // }
    // handleCheck(value, checked) {
    //     const { checkedKeys } = this.state;
    //     const nextCheckedKeys = checked
    //         ? [...checkedKeys, value]
    //         : checkedKeys.filter(item => item !== value);

    //     this.setState({
    //         checkedKeys: nextCheckedKeys
    //     });
    // }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }

    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchPro: '',
            // searchCustomer: null,
            searchCountry: null,
            searchPO: '',
            searchStatus: null,

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchPro: '',
            searchPO: '',
        });


        // try {
        //     var searchCustomer = this.state.searchCustomer
        // }
        // catch (e) {
        //     var searchCustomer = null
        // }

        try {
            var searchCountry = this.state.searchCountry
        }
        catch (e) {
            var searchCountry = null
        }

        try {
            var searchStatus = this.state.searchStatus
        }
        catch (e) {
            var searchStatus = null
        }


        try {
            var searchStatus = this.state.searchStatus
            this.props.history.push({
                search:
                    // 'customer=' + searchCustomer + '&' +
                    'country=' + searchCountry + '&' +
                    'status=' + searchStatus
            });
        }
        catch (e) { }

    }

    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            // searchCustomer: null,
            searchCountry: null,

        });
    }

    onChangeSearchPro(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchPO: '',
            searchPro: e,
            // searchCustomer: null,
            searchCountry: null,
            searchStatus: null,
        });
    }

    onChangeSearchPO(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchPO: e,
            searchPro: '',
            // searchCustomer: null,
            searchCountry: null,
            searchStatus: null,

        });
    }

    onChangeSearchCustomer(e) {
        this.setState({
            // searchCustomer: e,
        });
    }
    onChangeSearchCountry(e) {
        this.setState({
            searchCountry: e,
        });
    }
    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }


    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'containerdetails',
                search: 'containerid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }


    //Ship Management Drawer
    onClickOpenShipManagmenetDrawer(e, rowData, history) {


        this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ProRef)

        this.props.ctrlTwrShipmentLegsMilestonesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.ProRef, null)

        this.setState({
            //Ship Management Drawer
            showMilestoneEntryDrawer: true,
            ShipMilestonesExistingDict: {},
            unsavedShipManagmentChanges: false,
            ShipManagementProRef: rowData.ProRef,
            addNewMilestoneModalShown: false,
            AddNewLegDict: {},
        });
    }

    preloadShipManagement() {
        var legsData = this.props.CtrlTwrShipmentLegsData

        this.setState({
            ShipMilestonesExistingDict: legsData,
            unsavedShipManagmentChanges: false,
        });
        this.forceUpdate()


        
        // var milestonesArray = this.props.CtrlTwrShipmentLegsMilestonesData.filter(x => [9].includes(x.LegID))

        // var milestonesModeFilter = this.props.CtrlTwrMilestoneRefData.filter(x => [1].includes(x.ModeID) && x.Required )

        // const milestoneIdList = milestonesArray.map(item => item.MilestoneID);

        // var filterMissingMilestones =   milestonesModeFilter.filter(x => !milestoneIdList.includes(x.value))

    }

    onClickMilestoneEventEntryDrawerSave(e) {
        // if (window.confirm('Save Data?')) {
        for (let l in this.state.ShipMilestonesExistingDict) {
            try {
                this.props.ctrlTwrShipmentLegsDataPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,

                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].ProRef),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].LegNumber),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].ModeID),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].LegID),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].WarehouseID),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].BOL),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].MBOL),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].HBOL),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].PO),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Container),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Carrier),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Seal),

                    formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].PlannedDepartureDate),
                    formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].EstimatedDeliveryDate),

                    // formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].EstimatedPickupDate),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].PickupAddress),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].DeliveryAddress),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Trailer),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Vessel),
                    // formatDateForUrlSearch(this.state.ShipMilestonesExistingDict[l].SailingDate),
                    // formatDateForUrlSearch(this.state.ShipMilestonesExistingDict[l].SailingEstimatedArrivalDate),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].SeaportOriginID),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].SeaportDestID),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Flight),
                    // formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].FlightETD),
                    // formatDateTimeForUrlSearch(this.state.ShipMilestonesExistingDict[l].FlightETA),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].AirportOriginID),
                    formatNumberForAPI(this.state.ShipMilestonesExistingDict[l].AirportDestID),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].CustomerRef),

                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Shipper_Name), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_AddressLine), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_City), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_State), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_PostalCode), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Origin_CountryCode), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Consignee_Name), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_AddressLine), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_City), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_State), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_PostalCode), formatStringForAPI(this.state.ShipMilestonesExistingDict[l].Destination_CountryCode),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].PickupAppointmentTime),
                    formatStringForAPI(this.state.ShipMilestonesExistingDict[l].DeliveryAppointmentTime)



                )
            }
            catch (e) { }
        }

        this.setState({
            unsavedShipManagmentChanges: false,
            showMilestoneEntryDrawer: false,
            addNewMilestoneModalShown: false
        });
        // }
    }


    onClickShowAddLeg(e) {
        if (this.state.unsavedShipManagmentChanges) {
            window.alert('Please save changes before adding new leg.')
        }
        else {
            const legNumbersArray = this.state.ShipMilestonesExistingDict.map(item => item.LegNumber);
            try {
                var newLegNumber = findLowestMissingNumber(legNumbersArray)
            }
            catch (e) {
                var newLegNumber = 1
            }
            this.setState({
                addNewMilestoneModalShown: true,
                newMilestoneModalLegID: newLegNumber,
                newMilestoneModalMilestoneID: null,
            });
        }
    }

    onChangeMilestoneEventEntry(e, legID, stateName) {

        var shipManagementDictUpdated = this.state.ShipMilestonesExistingDict
        var keyToUpdate = shipManagementDictUpdated.findIndex(item => item.LegID === legID)

        shipManagementDictUpdated[keyToUpdate][stateName] = e

        this.setState({
            ShipMilestonesExistingDict: shipManagementDictUpdated,
            unsavedShipManagmentChanges: true
        });

        this.forceUpdate()
    }

    onClickDelete(e, rowData, history) {
        if (window.confirm('Delete Milestone?')) {
            try {
                this.props.ctrlTwrShipmentLegsMilestonesPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(rowData.ShipmentLegMilestoneID),
                    formatNumberForAPI(rowData.ProRef),
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    1,
                    null
                )
            }
            catch (e) { }
        }
    }


    closeMilestoneEventEntryDrawer() {
        if (this.state.unsavedShipManagmentChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setMilestoneEventEntryDrawerToClose()
            }
        }
        else {
            this.setMilestoneEventEntryDrawerToClose()
        }
    }

    setMilestoneEventEntryDrawerToClose() {
        this.setState({
            ReceiveDict: {},
            LocationDict: {},
            AddNewLegDict: {},
            unsavedShipManagmentChanges: false,

            showMilestoneEntryDrawer: false,
            addNewMilestoneModalShown: false,

            ReceiveDrawerContainerID: null,
            ReceiveDrawerContainer: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
        });
    }



    //Add new Milestone Modal

    onClickAddMilestone(e, legID, modeID, mode) {
        this.setState({
            addNewMilestoneModalShown: true,
            newMilestoneModalLegID: legID,
            newMilestoneModalModeID: modeID,
            newMilestoneModalMode: mode,

            newMilestoneModalMilestoneID: null,
            newMilestoneModalException: false,
            newMilestoneModalMilestoneDT: null,
            newMilestoneModalMilestoneTimezone: null,
            newMilestoneModalCountryCodeID: dbValues.USCountryCodeID,
            newMilestoneModalCity: null,
            newMilestoneModalState: null,
            newMilestoneModalStateCodeID: null,
            newMilestoneModalZip: null,
            newMilestoneModalSeaportCodeID: null,
            newMilestoneModalAirportCodeID: null,
            newMilestoneModalWarehouseID: null,
        });
    }



    onClickAddMissingMilestone(e, rowData, history, additionalClickdata) {

        this.setState({
            addNewMilestoneModalShown: true,
            newMilestoneModalLegID: additionalClickdata.LegID,
            newMilestoneModalModeID: additionalClickdata.ModeID,
            newMilestoneModalMode: additionalClickdata.ModeName,

            newMilestoneModalMilestoneID: rowData.value,
            newMilestoneModalException: rowData.ExceptionMilestone,
            newMilestoneModalMilestoneDT: null,
            newMilestoneModalMilestoneTimezone: null,
            newMilestoneModalCountryCodeID: dbValues.USCountryCodeID,
            newMilestoneModalCity: null,
            newMilestoneModalState: null,
            newMilestoneModalStateCodeID: null,
            newMilestoneModalZip: null,
            newMilestoneModalSeaportCodeID: null,
            newMilestoneModalAirportCodeID: null,
            newMilestoneModalWarehouseID: null,
        });
    }

    onChangeAddNewMilestone(e, label) {
        if (label === 'newMilestoneModalMilestoneID') {
            var milestoneData=this.props.CtrlTwrMilestoneRefData.filter(x => [e].includes(x.value))
            var exceptionMilestone = milestoneData[0].ExceptionMilestone
            this.setState({
                newMilestoneModalMilestoneID: e,
                newMilestoneModalException: exceptionMilestone
            });
        }
        else if (label === 'newMilestoneModalMilestoneDT') {
            this.setState({
                newMilestoneModalMilestoneDT: e
            });
        }
        else if (label === 'newMilestoneModalMilestoneTimezone') {
            this.setState({
                newMilestoneModalMilestoneTimezone: e
            });
        }
        else if (label === 'newMilestoneModalCountryCodeID') {
            this.setState({
                newMilestoneModalCountryCodeID: e
            });
            if ( e !== dbValues.USCountryCodeID ){
                this.setState({
                    newMilestoneModalStateCodeID: null
                });
            }
            else if ( e === dbValues.USCountryCodeID ){
                this.setState({
                    newMilestoneModalState: null
                });
            }
        }
        else if (label === 'newMilestoneModalCity') {
            this.setState({
                newMilestoneModalCity: e
            });
        }
        else if (label === 'newMilestoneModalState') {
            this.setState({
                newMilestoneModalState: e,
                newMilestoneModalStateCodeID: null
            });
        }
        else if (label === 'newMilestoneModalStateCodeID') {
            this.setState({
                newMilestoneModalStateCodeID: e,
                newMilestoneModalState: null
            });
        }
        else if (label === 'newMilestoneModalZip') {
            this.setState({
                newMilestoneModalZip: e
            });
        }
        else if (label === 'newMilestoneModalSeaportCodeID') {
            this.setState({
                newMilestoneModalSeaportCodeID: e
            });
        }
        else if (label === 'newMilestoneModalAirportCodeID') {
            this.setState({
                newMilestoneModalAirportCodeID: e
            });
        }
        else if (label === 'newMilestoneModalWarehouseID') {
            this.setState({
                newMilestoneModalWarehouseID: e
            });
        }
    }


    handleCloseAddNewModal() {
        this.setState({
            addNewMilestoneModalShown: false,

            newMilestoneModalLegID: null,
            newMilestoneModalMilestoneID: null,
            newMilestoneModalException: false,

            newMilestoneModalModeID: null,
            newMilestoneModalMode: null,
            newMilestoneModalMilestoneDT: null,
            newMilestoneModalMilestoneTimezone: null,
            newMilestoneModalCountryCodeID: null,
            newMilestoneModalCity: null,
            newMilestoneModalState: null,
            newMilestoneModalStateCodeID: null,
            newMilestoneModalZip: null,
            newMilestoneModalSeaportCodeID: null,
            newMilestoneModalAirportCodeID: null,
            newMilestoneModalWarehouseID: null,
        });
    }

    handleSaveAddNewModal() {

        try{
        var milestoneIdData = this.props.CtrlTwrMilestoneRefData.filter(x => x.value === this.state.newMilestoneModalMilestoneID)
        }
        catch(e){
            var milestoneIdData = []
        }


        if (this.state.newMilestoneModalMilestoneID === null || this.state.newMilestoneModalMilestoneID === '') {
            window.alert('Please enter a milestone.')
        }
        else if (this.state.newMilestoneModalMilestoneDT === null || this.state.newMilestoneModalMilestoneDT === '') {
            window.alert('Please enter date.')
        }
        else if (this.state.newMilestoneModalMilestoneTimezone === null || this.state.newMilestoneModalMilestoneTimezone === '') {
            window.alert('Please enter timezone.')
        }

        else if (milestoneIdData[0].DepartureMilestone && (this.state.newMilestoneModalCountryCodeID === null || this.state.newMilestoneModalCountryCodeID === '')) {
            window.alert('Departure Milestones require location data.')
        }
        else if (milestoneIdData[0].DepartureMilestone && (this.state.newMilestoneModalCity === null || this.state.newMilestoneModalCity === '' )) {
            window.alert('Departure Milestones require location data.')
        }
        else if (milestoneIdData[0].DepartureMilestone && this.state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID && ( this.state.newMilestoneModalStateCodeID === null || this.state.newMilestoneModalStateCodeID === '' )) {
            window.alert('Departure Milestones require location data.')
        }
        else if (milestoneIdData[0].DepartureMilestone && this.state.newMilestoneModalCountryCodeID !== dbValues.USCountryCodeID && ( this.state.newMilestoneModalState === null || this.state.newMilestoneModalState === '' )) {
            window.alert('Departure Milestones require location data.')
        }

        else if (milestoneIdData[0].DeliveryMilestone && (this.state.newMilestoneModalCountryCodeID === null || this.state.newMilestoneModalCountryCodeID === '')) {
            window.alert('Delivery Milestones require location data.')
        }
        else if (milestoneIdData[0].DeliveryMilestone && (this.state.newMilestoneModalCity === null || this.state.newMilestoneModalCity === '' )) {
            window.alert('Delivery Milestones require location data.')
        }
        else if (milestoneIdData[0].DeliveryMilestone && this.state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID && ( this.state.newMilestoneModalStateCodeID === null || this.state.newMilestoneModalStateCodeID === '' )) {
            window.alert('Delivery Milestones require location data.')
        }
        else if (milestoneIdData[0].DeliveryMilestone && this.state.newMilestoneModalCountryCodeID !== dbValues.USCountryCodeID && ( this.state.newMilestoneModalState === null || this.state.newMilestoneModalState === '' )) {
            window.alert('Delivery Milestones require location data.')
        }


        else {
            this.props.ctrlTwrShipmentLegsMilestonesPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                null,
                formatNumberForAPI(this.state.ShipManagementProRef),
                formatNumberForAPI(this.state.newMilestoneModalLegID),
                formatNumberForAPI(this.state.newMilestoneModalModeID),
                formatNumberForAPI(this.state.newMilestoneModalMilestoneID),
                formatDateTimeForUrlSearch(this.state.newMilestoneModalMilestoneDT),
                formatNumberForAPI(this.state.newMilestoneModalMilestoneTimezone),
                formatNumberForAPI(this.state.newMilestoneModalCountryCodeID),
                formatStringForAPI(this.state.newMilestoneModalCity),
                formatStringForAPI(this.state.newMilestoneModalState),
                formatStringForAPI(this.state.newMilestoneModalZip),
                formatNumberForAPI(this.state.newMilestoneModalSeaportCodeID),
                formatNumberForAPI(this.state.newMilestoneModalAirportCodeID),
                formatNumberForAPI(this.state.newMilestoneModalWarehouseID),
                0,
                formatNumberForAPI(this.state.newMilestoneModalStateCodeID)
            )

            this.handleCloseAddNewModal()
        }
    }



    //Table on click details
    onClickShipDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'shipmentdetailsmanagement',
                search: 'pro=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }




    render() {

        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PRO #',
                                this.onChangeSearchPro,
                                this.props.history,
                                'pro',
                                this.state.searchPro
                            )}
                        </Row>

                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PO',
                                this.onChangeSearchPO,
                                this.props.history,
                                'po',
                                this.state.searchPO
                            )}
                        </Row>
                    </Panel>

                    <Panel bordered style={{ marginBottom: '10px' }}>

                        {/* <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.state.customerArray,
                                this.state.searchCustomer,
                                'Customer',
                                this.onChangeSearchCustomer,
                                this.props.history,
                                mountRef
                            )}
                        </Row> */}
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.CtrlTwrCountryData,
                                this.state.searchCountry,
                                'Origin Country',
                                this.onChangeSearchCountry,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                ctrlTwrShipmentStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isCtrlTwrShipmentLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.CtrlTwrShipmentData) !== 'undefined' && this.props.CtrlTwrShipmentData != null) {
            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.CtrlTwrShipmentData,     //   tableData,
                CtrlTwrShipmentDataElements,       //   DataElements,
                this.props.isCtrlTwrShipmentLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   onClickAddMilestone,
                this.onClickOpenShipManagmenetDrawer,       //   onClickSelect,
                'Milestones',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickShipDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )


            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'MilestoneEventEntry_' + currentDateTime}>
                                <ExcelSheet data={this.props.CtrlTwrShipmentData} name="MilestoneEventEntry">
                                    {
                                        CtrlTwrShipmentDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }


        let selectedPro;
        try {
            if (typeof (this.state.ShipManagementProRef) !== 'undefined' && this.state.ShipManagementProRef != null) {

                selectedPro = this.state.ShipManagementProRef
            }
            else {
                selectedPro = ''
            }
        }
        catch (e) {
            selectedPro = ''
        }


        let shipmentManagementDrawer;
        // if (this.props.isCtrlTwrShipmentLegsMilestonesLoading) {
        //     shipmentManagementDrawer = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        // }
        if (!this.state.showMilestoneEntryDrawer) {
            shipmentManagementDrawer = <div>  </div>
        }
        else if (Object.keys(this.state.ShipMilestonesExistingDict).length > 0 && typeof (this.state.ShipMilestonesExistingDict) !== 'undefined' && this.state.ShipMilestonesExistingDict != null) {
            shipmentManagementDrawer = <MilestoneEventEntryDrawer {...this.props} state={this.state} onChange={this.onChangeMilestoneEventEntry} confirm={this.onClickMilestoneEventEntryDrawerSave} close={this.closeMilestoneEventEntryDrawer} onClickAddMilestone={this.onClickAddMilestone} onClickAddMissingMilestone={this.onClickAddMissingMilestone} onClickShowAddLeg={this.onClickShowAddLeg} onChangeNewLeg={this.onChangeNewLeg} onClickDelete={this.onClickDelete} masterLabel={selectedPro} />
        }


        return (
            <div>
                <h3>
                    Control Tower - Milestone Event Entry
                </h3>
                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}

                {shipmentManagementDrawer}


                <MilestoneEventModal {...this.props} state={this.state} addNewMilestoneModalShown={this.state.addNewMilestoneModalShown} onChangeAddNewMilestone={this.onChangeAddNewMilestone} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseAddNewModal} handleSave={this.handleSaveAddNewModal} />


            </div>
        );
    }
}

export default withRouter(MilestoneEventEntry);