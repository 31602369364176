import { connect } from 'react-redux';
import React, { Component } from 'react';
import Load from './Load';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventorySkuLoading: store.inventoryReducer.isInventorySkuLoading,
    InventorySkuData: store.inventoryReducer.InventorySkuData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,
    SkuLocationDataGrouped: store.inventoryReducer.SkuLocationDataGrouped,


    isInventoryLoadLotLoading: store.inventoryReducer.isInventoryLoadLotLoading,
    InventoryLoadLotData: store.inventoryReducer.InventoryLoadLotData,


    isInventoryLoadSkuLoading: store.inventoryReducer.isInventoryLoadSkuLoading,
    InventoryLoadSkuData: store.inventoryReducer.InventoryLoadSkuData,


    InventoryLoadPalletCaseSkuPostSuccessful: store.inventoryReducer.InventoryLoadPalletCaseSkuPostSuccessful,


});

const mapDispatchToProps = dispatch =>
({


    inventoryLoadLotGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID) => {
        dispatch(inventoryActions.inventoryLoadLotGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _Container, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseSku, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal, _DestinationID, _OrderOutboundID))
    },

    inventoryLoadSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) => {
        dispatch(inventoryActions.inventoryLoadSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID))
    },
    inventoryLoadPalletCaseSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes) => {
        dispatch(inventoryActions.inventoryLoadPalletCaseSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _DataType, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalUnits, _TotalCases, _TotalPallets, _Destination, _DestinationID, _LoadStatusID, _OrderOutboundID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes))
    },
});

class LoadContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventoryLoadPalletCaseSkuPostSuccessful !== this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.props.InventoryLoadPalletCaseSkuPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramLoadStatus = queryStringParse(values.loadstatus)
        }
        catch (e) {
            var paramLoadStatus = null
        }

        this.props.inventoryLoadLotGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramLoadStatus, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null) 
   



    }



    render() {

        return (

            <Load
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventorySkuLoading={this.props.isInventorySkuLoading}
                InventorySkuData={this.props.InventorySkuData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}
                SkuLocationDataGrouped={this.props.SkuLocationDataGrouped}


                isInventoryLoadLotLoading={this.props.isInventoryLoadLotLoading}
                InventoryLoadLotData={this.props.InventoryLoadLotData}

                inventoryLoadSkuGet={this.props.inventoryLoadSkuGet}
                isInventoryLoadSkuLoading={this.props.isInventoryLoadSkuLoading}
                InventoryLoadSkuData={this.props.InventoryLoadSkuData}


                InventoryLoadPalletCaseSkuPostSuccessful={this.props.InventoryLoadPalletCaseSkuPostSuccessful}
                inventoryLoadPalletCaseSkuPost={this.props.inventoryLoadPalletCaseSkuPost}



            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadContainer));



