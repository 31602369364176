export const InventoryLotReportingInboundDataElements = [
    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'ContainerStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Palletized',
        displayName: 'Palletized',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },


    {
        dBKey: 'UnitsExpected',
        displayName: 'Units Expected',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'CasesExpected',
        displayName: 'Cases Expected',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'PalletsExpected',
        displayName: 'Pallets Expected',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'LCL_FCL',
        displayName: 'LCL/FCL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'DeliveryAppointment',
        displayName: 'Delivery Appointment',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    {
        dBKey: 'ETD',
        displayName: 'ETD',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'ETA',
        displayName: 'ETA',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    // {
    //     dBKey: 'Notes',
    //     displayName: 'Container Notes',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },





    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'SkuType',
        displayName: 'SKU Type',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'RecoveryDT',
        displayName: 'Received DT',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'RecdCount',
        displayName: 'Received Count',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'RecdPalletCount',
        displayName: 'Pallet Received Count',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 160
    },

    // {
    //     dBKey: 'Oversize',
    //     displayName: 'Oversize',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 140
    // },

    {
        dBKey: 'CustomerRef',
        displayName: 'CustomerRef',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    {
        dBKey: 'Project',
        displayName: 'Project',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    // {
    //     dBKey: 'SkuNotes',
    //     displayName: 'SKU Notes',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 140
    // },

    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },


]


export default InventoryLotReportingInboundDataElements

