import React from 'react';
import Barcode from 'react-barcode';
import { Card, CardTitle, CardText, CardMedia } from 'material-ui/Card';
import { Row, Col, Panel, PanelGroup, Button, FlexboxGrid, Tree, Container, Sidebar, Header, Footer, Content } from 'rsuite';

const parentStyles = {
    position: 'relative',
    // width: '500px', height: '750px'
    width: '3.8in',
    height: '5.8in',
};


const barcodeStyles = {
    transform: 'translate(-50%, -50%) rotate(270deg)',
    top: '50%',
    left: '50%',
    position: 'absolute',
    textAlign: 'center'
};



export default class BarcodeCard extends React.Component {
    render() {
        const { barcode } = this.props;

        return (

            <div id="div-svg" style={{ display: 'flex'}}>
                <div style={parentStyles}>
                    <div style={barcodeStyles}>
                        <Barcode value={barcode} format="CODE128" />
                        {this.props.barcodeContent ?
                            this.props.barcodeContent :
                            null
                        }
                    </div>
                </div>
            </div>



        );
    }
}
