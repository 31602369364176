import React, {
    PureComponent
} from 'react';
import { Modal, Button, ControlLabel, Col, FormGroup, InputGroup, InputPicker, Panel, Divider, Input, IconButton, Checkbox } from 'rsuite';
import BarcodeMultipleCard from '../Barcode/BarcodeMultipleCard';


const mountRef = React.createRef();

function PrintBarcodesModal({
    printBarcodeModalShown,
    closePrintBarcodes,
    barcodePrintList,

    PalletBarcodeData,
    CaseBarcodeData,
    // onPrintBarcodes,
    state, ...props
}) {





    let barcodes = [];

    if (PalletBarcodeData !== null) {
        barcodes = Object.keys(PalletBarcodeData).map((id, index) => {

            const contents = PalletBarcodeData[id];

            return (
                <BarcodeMultipleCard barcode={contents.PalletBarcode.trim()} key={contents.PalletBarcode} barcodeContent={
                    <div>
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Case SKU: {contents.CaseSKU}
                        </p>
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Case Qty: {contents.CasesInWhse}
                        </p>
                    </div>
                } />
            );
        });
    }


    else if (CaseBarcodeData !== null) {
        barcodes = Object.keys(CaseBarcodeData).map((id, index) => {
            const contents = CaseBarcodeData[id];
            return (
                <BarcodeMultipleCard barcode={contents.CaseBarcode.trim()} key={contents.PalletBarcode} barcodeContent={
                    <div>
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Case SKU: {contents.CaseSKU}
                        </p>
                        {contents.LotCode ?
                            <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                                Lot: {contents.LotCode}
                            </p>
                            : null}
                        <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
                            Pallet Barcode: {contents.PalletBarcode}
                        </p>
                    </div>

                } />
            );
        });
    }

    else {
        barcodes = barcodePrintList
            ? barcodePrintList.split(',').map(barcode =>
                <BarcodeMultipleCard barcode={barcode.trim()} key={barcode} />
            )
            : [];
    }












    // if (CaseBarcodeData !== null) {
    //     barcodes.push(
    //         <BarcodeMultipleCard barcode={CaseBarcodeData.trim()} key={CaseBarcodeData} data={CaseBarcodeData} />
    //     );
    // }





    // //only displays first 10
    // const palletBarcodes = Object.keys(groupedPalletContents).map((barcode, index) => {
    //     const contents = groupedPalletContents[barcode];
    //     const remainingItems = Math.max(contents.length - 10, 0);
    //     return (
    //         <BarcodeMultipleCard barcode={barcode.trim()} key={barcode} barcodeContent={
    //             [...contents.slice(0, 10).map((content, index) => (
    //                 <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
    //                     Case SKU: {content.Case_SKU} (Qty: {content.CaseQty})
    //                     {content.LotCode ? ` Lot: ${content.LotCode}` : null}
    //                 </p>
    //             )), remainingItems > 0 ? <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>{remainingItems} more rows...</p> : null]
    //         } />
    //     );
    // });


    // const caseBarcodes = props.InventoryRecoveryCaseInfo
    // ? Object.keys(props.InventoryRecoveryCaseInfo).map(barcode => {
    //     const contents = props.InventoryRecoveryCaseInfo[barcode];
    //     return (
    //         <BarcodeMultipleCard barcode={contents['CaseBarcode'].trim()} key={contents['CaseBarcode'].trim()} barcodeContent={
    //             <div>
    //                 <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
    //                     Case SKU: {contents.Case_SKU}
    //                 </p>
    //                 {contents.Case_LotCode ?
    //                     <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
    //                         Lot: {contents.Case_LotCode}
    //                     </p>
    //                     : null}
    //                 <p style={{ fontSize: '12px', margin: '0', textAlign: 'left' }}>
    //                     Pallet Barcode: {contents.PalletBarcode}
    //                 </p>
    //             </div>
    //         } />
    //     );
    // })
    // : null;




    // const barcodes = barcodePrintList
    //     ? barcodePrintList.split(',').map(barcode =>
    //         <BarcodeMultipleCard barcode={barcode.trim()} key={barcode} />
    //     )
    //     : [];






    function onPrintBarcode() {

        // var container = document.getElementById("div-svg");
        // var mySVG = document.getElementById("barcode-canvas");
        // var width = "100%";
        // var height = "100%";
        // var printWindow = window.open('', 'PrintMap',
        //     'width=' + width + ',height=' + height);
        // printWindow.document.writeln(container.innerHTML);
        // printWindow.document.close();
        // printWindow.print();
        // printWindow.close();



        var container = document.getElementById("div-svg");
        var printWindow = window.open('', 'PrintMap', 'width=' + window.innerWidth + ',height=' + window.innerHeight);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();

    }



    return (

        <div className="modal-container">

            <Modal backdrop={'static'} keyboard={false} show={printBarcodeModalShown} onHide={closePrintBarcodes} style={{ zIndex: '9999999999999' }}>
                <Modal.Header>
                    <Modal.Title>Print Barcodes</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <FormGroup>

                        <div id="div-svg" style={{ display: 'flex', flexDirection: 'column' }}>
                            {barcodes}
                        </div>

                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    {barcodePrintList && (
                        <Button onClick={(e) => { onPrintBarcode(e) }} appearance="primary">
                            Print Barcodes
                        </Button>
                    )}

                    <Button onClick={closePrintBarcodes} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>



    );
}


export default PrintBarcodesModal;

