import React from 'react';
import {
    Button,
    ButtonToolbar,
    Drawer,
    Form,
    FormControl,
    FormGroup,
    Input,
    InputGroup,
    InputNumber,
    InputPicker,
    Panel,
    Row,
    Loader
} from 'rsuite';
import { lettersNumbersDashesInputMask, limitCharacterLength, textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import { uomData } from '../../inventoryValues';
import InventoryLotOrderOutboundSkuDataElements from '../../../dataComponentsInventory/InventoryLotOrderOutboundSkuDataElements';

import AutoCompleteInput from '../../../componentsGeneral/AutoCompleteInput/AutoCompleteInput';


const tableIdCol = "OrderOutboundSkuID"

const styles = {
    marginBottom: 10,
    width: '100%'
};



const mountRef = React.createRef();

const EditOrderOutboundSkusDrawer = ({ onChangeState, handleAutoCompleteSku, onClickTree, onClickEditSku, onConfirmEditSku, onCloseEditSku, onClickDeleteSku, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showEditOrderOutboundSkusDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Outbound Order SKUs</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                {state.showOutboundSkuEdit ?

                    <Panel bordered style={{ marginBottom: 10 }} >
                        <div>
                            <h5 style={{ marginBottom: 5 }}>
                                Edit SKU: {state.SKU}
                            </h5>

                            <FormGroup>

                                <Row >
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>UOM:</InputGroup.Addon>
                                        <InputPicker preventOverflow
                                            container={() => mountRef.current}
                                            placeholder={'UOM...'}
                                            value={state.UomID}
                                            onChange={(e) => { onChangeState(e, 'UomID') }}
                                            data={uomData}
                                            style={{ width: '100%' }}
                                            block
                                            cleanable={true}
                                        />
                                    </InputGroup>
                                </Row>

                                <Row>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                                        <Input value={state.LotCode} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 50)} />
                                    </InputGroup>
                                </Row>

                                <Row>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                                        <Input value={state.SerialNumber} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'SerialNumber')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 50)} />
                                    </InputGroup>
                                </Row>

                                <Row>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Qty:</InputGroup.Addon>
                                        <InputNumber min={1} value={state.Qty} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'Qty')} scrollable={false} />
                                    </InputGroup>
                                </Row>

                            </FormGroup>

                            <FormGroup>
                                <ButtonToolbar>
                                    <DebouncedButton onClick={confirm} buttonText="Save" />
                                    <Button onClick={onCloseEditSku} appearance="default">Cancel</Button>
                                </ButtonToolbar>
                            </FormGroup>

                        </div>

                    </Panel>



                    :
                    <Panel bordered style={{ height: '420px', marginBottom: 10 }} >
                        <div>
                            <h5 style={{ marginBottom: 5 }}>
                                Add new SKU to {state.selectedLabel}
                            </h5>

                            <FormGroup>

                                <Row >
                                    {/* <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <FormControl placeholder="SKU" name="SKU" value={state.SKU} onChange={(e) => { onChangeState(e, 'SKU') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                    </InputGroup> */}



                                    {!props.isInventorySkuRefLoading && (
                                         <div style={{ marginBottom: '5px', width: '100%' }}>
                                        <AutoCompleteInput
                                            state={state}
                                            data={props.InventorySkuRefData}
                                            // options={props.InventorySkuRefData}
                                            // value={props.InventorySkuRefData.find(option => option.label === state.SKU) || null}
                                            valueName="SKU"
                                            onChangeInput={handleAutoCompleteSku}
                                            placeholder="SKU..."
                                        />
                                        </div>
                                    )}


                                </Row>

                                <Row >
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>UOM:</InputGroup.Addon>
                                        <InputPicker preventOverflow
                                            container={() => mountRef.current}
                                            placeholder={'UOM...'}
                                            value={state.UomID}
                                            onChange={(e) => { onChangeState(e, 'UomID') }}
                                            data={uomData}
                                            style={{ width: '100%' }}
                                            block
                                            cleanable={true}
                                        />
                                    </InputGroup>
                                </Row>

                                <Row>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                                        <Input value={state.LotCode} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 50)} />
                                    </InputGroup>
                                </Row>

                                <Row>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                                        <Input value={state.SerialNumber} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'SerialNumber')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 50)} />
                                    </InputGroup>
                                </Row>

                                <Row>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Qty:</InputGroup.Addon>
                                        <InputNumber min={1} value={state.Qty} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'Qty')} scrollable={false} />
                                    </InputGroup>
                                </Row>

                            </FormGroup>

                            <FormGroup>
                                <ButtonToolbar>
                                    <DebouncedButton onClick={confirm} buttonText="Add" />
                                    <Button onClick={close} appearance="default">Close</Button>
                                </ButtonToolbar>
                            </FormGroup>

                        </div>

                    </Panel>
                }



                {props.isInventoryLotOrderOutboundSkuGetLoading ? <Loader backdrop content="loading..." vertical size="lg" /> :

                    props.InventoryLotOrderOutboundSkuData.length == 0 ? <div></div> :

                        <FormGroup>
                            <Panel bordered>
                                <Row>
                                    {RTableGeneral(
                                        tableIdCol, // keyColumn,
                                        props.InventoryLotOrderOutboundSkuData, // tableData,
                                        InventoryLotOrderOutboundSkuDataElements, // DataElements,
                                        props.isInventoryLotOrderOutboundSkuGetLoading, // loading,

                                        props.isMobile, // isMobile,
                                        props.history, // history,

                                        null, // sortColumn,
                                        null, // sortType,
                                        null, // handleSortColumn,

                                        null, // checkColEntry,
                                        null, // checkedKeys,
                                        null, // handleCheckAll,
                                        null, // handleCheck,

                                        false, // boolSelectButton,
                                        null, // onClickSelect,
                                        null, // selectButtonText,
                                        null, // selectHighlightRowKey,

                                        true, // link1bool,
                                        'Edit', // link1text,
                                        onClickEditSku, // link1onClick,

                                        true, // link2bool,
                                        'Delete', // link2text,
                                        onClickDeleteSku // link2onClick
                                    )}
                                </Row>
                            </Panel>
                        </FormGroup>


                }
            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer >

);



EditOrderOutboundSkusDrawer.defaultProps = {
    show: false
};

export default EditOrderOutboundSkusDrawer;







