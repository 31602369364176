import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, FormGroup, DatePicker, Input } from 'rsuite';
import { lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';
import { dbValues } from '../../../app/appConfig';

const styles = {
    marginBottom: '10px',
    width: '100%'
};



const mountRef = React.createRef();

function MilestoneEventModal({
    addNewMilestoneModalShown,
    handleSave,
    handleClose,
    onChangeAddNewMilestone,
    resetRows,
    state, ...props

}) {
    return (

        <div className="modal-container" >
            <Modal backdrop={'static'} keyboard={false} show={addNewMilestoneModalShown} onHide={handleClose} onExited={resetRows}>
                <Modal.Header>
                    <Modal.Title>{state.newMilestoneModalMode} Milestone:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Panel bordered style={{ marginBottom: '15px' }} >
                        <div ref={mountRef}>

                            {state.newMilestoneModalModeID === 1 ? //Ocean

                                <Col md={24} sm={24} style={styles}>

                                    <FormGroup >
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Milestone:</InputGroup.Addon>
                                            <InputPicker preventOverflow
                                                // //container={() => mountRef.current}
                                                placeholder={'Milestone...'}
                                                value={state.newMilestoneModalMilestoneID}
                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                block
                                                // size="sm"
                                                style={{ width: '100%' }}
                                                cleanable={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                    {/* {
                                        state.newMilestoneModalException === true ?

                                        <FormGroup >
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Exception Reason:</InputGroup.Addon>
                                            <InputPicker preventOverflow
                                                // //container={() => mountRef.current}
                                                placeholder={'Milestone...'}
                                                value={state.newMilestoneModalMilestoneID}
                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                block
                                                // size="sm"
                                                style={{ width: '100%' }}
                                                cleanable={true}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    :
                                    <div></div>
                                    } */}

                                    <FormGroup>
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Date:</InputGroup.Addon>
                                            <DatePicker
                                                //container={() => mountRef.current}
                                                placeholder="Milestone Date"
                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneDT') }}
                                                value={state.newMilestoneModalMilestoneDT}
                                                format="YYYY-MM-DD hh:mm aa"
                                                defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                showMeridian
                                                // size="sm"
                                                style={{ width: '100%' }}
                                                ranges={[
                                                    {
                                                        label: 'Now',
                                                        value: new Date()
                                                    }
                                                ]}
                                            //oneTap
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup>
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Timezone:</InputGroup.Addon>
                                            <InputPicker preventOverflow
                                                // //container={() => mountRef.current}
                                                placeholder={'Timezone...'}
                                                value={state.newMilestoneModalMilestoneTimezone}
                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneTimezone') }}
                                                data={props.CtrlTwrTimezoneData}
                                                cleanable={true}
                                                style={{ width: '100%' }}
                                                // size="sm"
                                                block
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup>
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Warehouse:</InputGroup.Addon>
                                            <InputPicker preventOverflow
                                                // container={() => mountRef.current}
                                                placeholder={'Warehouse...'}
                                                value={state.newMilestoneModalWarehouseID}
                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalWarehouseID') }}
                                                data={state.warehouseArray}
                                                style={{ width: '100%' }}
                                                cleanable={true}
                                                placement={'auto'}
                                            />
                                        </InputGroup>
                                    </FormGroup>


                                    <FormGroup >
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Port:</InputGroup.Addon>
                                            <InputPicker preventOverflow
                                                // //container={() => mountRef.current}
                                                placeholder={'Port...'}
                                                value={state.newMilestoneModalSeaportCodeID}
                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalSeaportCodeID') }}
                                                data={props.CtrlTwrSeaportData}
                                                block
                                                // size="sm"
                                                style={{ width: '100%' }}
                                                cleanable={true}
                                                placement={'bottomStart'}
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup >
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Country:</InputGroup.Addon>
                                            <InputPicker preventOverflow
                                                // //container={() => mountRef.current}
                                                // defaultValue={dbValues.USCountryCodeID}
                                                placeholder={'Country...'}
                                                value={state.newMilestoneModalCountryCodeID}
                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCountryCodeID') }}
                                                data={props.CtrlTwrCountryData}
                                                cleanable={true}
                                                style={{ width: '100%' }}
                                                // size="sm"
                                                block
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup >
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>City:</InputGroup.Addon>
                                            <Input value={state.newMilestoneModalCity} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCity') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>
                                    </FormGroup>

                                    {state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID ?
                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>State:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    // //container={() => mountRef.current}
                                                    // defaultValue={dbValues.USCountryCodeID}
                                                    placeholder={'State...'}
                                                    value={state.newMilestoneModalStateCodeID}
                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalStateCodeID') }}
                                                    data={props.CtrlTwrStateData}
                                                    cleanable={true}
                                                    style={{ width: '100%' }}
                                                    // size="sm"
                                                    block
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        :
                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>State:</InputGroup.Addon>
                                                <Input value={state.newMilestoneModalState} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalState') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                            </InputGroup>
                                        </FormGroup>
                                    }


                                    <FormGroup >
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Zip:</InputGroup.Addon>
                                            <Input value={state.newMilestoneModalZip} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalZip') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                        </InputGroup>
                                    </FormGroup>

                                </Col>

                                :

                                state.newMilestoneModalModeID === 2 ? //Air

                                    <Col md={24} sm={24} style={styles}>

                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Milestone:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    // //container={() => mountRef.current}
                                                    placeholder={'Milestone...'}
                                                    value={state.newMilestoneModalMilestoneID}
                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                    data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                    block
                                                    // size="sm"
                                                    style={{ width: '100%' }}
                                                    cleanable={true}
                                                />
                                            </InputGroup>
                                        </FormGroup>



                                        <FormGroup>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Date:</InputGroup.Addon>
                                                <DatePicker
                                                    //container={() => mountRef.current}
                                                    placeholder="Milestone Date"
                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneDT') }}
                                                    value={state.newMilestoneModalMilestoneDT}
                                                    format="YYYY-MM-DD hh:mm aa"
                                                    defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                    showMeridian
                                                    // size="sm"
                                                    style={{ width: '100%' }}
                                                    ranges={[
                                                        {
                                                            label: 'Now',
                                                            value: new Date()
                                                        }
                                                    ]}
                                                //oneTap
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Timezone:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    // //container={() => mountRef.current}
                                                    placeholder={'Timezone...'}
                                                    value={state.newMilestoneModalMilestoneTimezone}
                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneTimezone') }}
                                                    data={props.CtrlTwrTimezoneData}
                                                    cleanable={true}
                                                    style={{ width: '100%' }}
                                                    // size="sm"
                                                    block
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup>
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Warehouse:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    // container={() => mountRef.current}
                                                    placeholder={'Warehouse...'}
                                                    value={state.newMilestoneModalWarehouseID}
                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalWarehouseID') }}
                                                    data={state.warehouseArray}
                                                    style={{ width: '100%' }}
                                                    cleanable={true}
                                                    placement={'auto'}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Port:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    // //container={() => mountRef.current}
                                                    placeholder={'Port...'}
                                                    value={state.newMilestoneModalAirportCodeID}
                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalAirportCodeID') }}
                                                    data={props.CtrlTwrAirportData}
                                                    block
                                                    // size="sm"
                                                    style={{ width: '100%' }}
                                                    cleanable={true}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Country:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    // //container={() => mountRef.current}
                                                    placeholder={'Country...'}
                                                    value={state.newMilestoneModalCountryCodeID}
                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCountryCodeID') }}
                                                    data={props.CtrlTwrCountryData}
                                                    cleanable={true}
                                                    style={{ width: '100%' }}
                                                    // size="sm"
                                                    block
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>City:</InputGroup.Addon>
                                                <Input value={state.newMilestoneModalCity} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCity') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                            </InputGroup>
                                        </FormGroup>

                                        {state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID ?
                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>State:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        // //container={() => mountRef.current}
                                                        // defaultValue={dbValues.USCountryCodeID}
                                                        placeholder={'State...'}
                                                        value={state.newMilestoneModalStateCodeID}
                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalStateCodeID') }}
                                                        data={props.CtrlTwrStateData}
                                                        cleanable={true}
                                                        style={{ width: '100%' }}
                                                        // size="sm"
                                                        block
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            :
                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>State:</InputGroup.Addon>
                                                    <Input value={state.newMilestoneModalState} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalState') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>
                                        }

                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Zip:</InputGroup.Addon>
                                                <Input value={state.newMilestoneModalZip} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalZip') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                            </InputGroup>
                                        </FormGroup>

                                    </Col>


                                    :

                                    state.newMilestoneModalModeID === 3 ? //Truck Load

                                        <Col md={24} sm={24} style={styles}>

                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Milestone:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        // //container={() => mountRef.current}
                                                        placeholder={'Milestone...'}
                                                        value={state.newMilestoneModalMilestoneID}
                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                        data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                        block
                                                        // size="sm"
                                                        style={{ width: '100%' }}
                                                        cleanable={true}
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Date:</InputGroup.Addon>
                                                    <DatePicker
                                                        //container={() => mountRef.current}
                                                        placeholder="Milestone Date"
                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneDT') }}
                                                        value={state.newMilestoneModalMilestoneDT}
                                                        format="YYYY-MM-DD hh:mm aa"
                                                        defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                        showMeridian
                                                        // size="sm"
                                                        style={{ width: '100%' }}
                                                        ranges={[
                                                            {
                                                                label: 'Now',
                                                                value: new Date()
                                                            }
                                                        ]}
                                                    //oneTap
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Timezone:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        // //container={() => mountRef.current}
                                                        placeholder={'Timezone...'}
                                                        value={state.newMilestoneModalMilestoneTimezone}
                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneTimezone') }}
                                                        data={props.CtrlTwrTimezoneData}
                                                        cleanable={true}
                                                        style={{ width: '100%' }}
                                                        // size="sm"
                                                        block
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Warehouse:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        // container={() => mountRef.current}
                                                        placeholder={'Warehouse...'}
                                                        value={state.newMilestoneModalWarehouseID}
                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalWarehouseID') }}
                                                        data={state.warehouseArray}
                                                        style={{ width: '100%' }}
                                                        cleanable={true}
                                                        placement={'auto'}
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Country:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        // //container={() => mountRef.current}
                                                        placeholder={'Country...'}
                                                        value={state.newMilestoneModalCountryCodeID}
                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCountryCodeID') }}
                                                        data={props.CtrlTwrCountryData}
                                                        cleanable={true}
                                                        style={{ width: '100%' }}
                                                        // size="sm"
                                                        block
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>City:</InputGroup.Addon>
                                                    <Input value={state.newMilestoneModalCity} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCity') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>

                                            {state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID ?
                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>State:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            // //container={() => mountRef.current}
                                                            // defaultValue={dbValues.USCountryCodeID}
                                                            placeholder={'State...'}
                                                            value={state.newMilestoneModalStateCodeID}
                                                            onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalStateCodeID') }}
                                                            data={props.CtrlTwrStateData}
                                                            cleanable={true}
                                                            style={{ width: '100%' }}
                                                            // size="sm"
                                                            block
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                                :
                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>State:</InputGroup.Addon>
                                                        <Input value={state.newMilestoneModalState} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalState') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>
                                            }

                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Zip:</InputGroup.Addon>
                                                    <Input value={state.newMilestoneModalZip} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalZip') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>

                                        </Col>

                                        :

                                        state.newMilestoneModalModeID === 4 ? //LTL

                                            <Col md={24} sm={24} style={styles}>

                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Milestone:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            // //container={() => mountRef.current}
                                                            placeholder={'Milestone...'}
                                                            value={state.newMilestoneModalMilestoneID}
                                                            onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                            data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                            block
                                                            // size="sm"
                                                            style={{ width: '100%' }}
                                                            cleanable={true}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Date:</InputGroup.Addon>
                                                        <DatePicker
                                                            //container={() => mountRef.current}
                                                            placeholder="Milestone Date"
                                                            onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneDT') }}
                                                            value={state.newMilestoneModalMilestoneDT}
                                                            format="YYYY-MM-DD hh:mm aa"
                                                            defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                            showMeridian
                                                            // size="sm"
                                                            style={{ width: '100%' }}
                                                            ranges={[
                                                                {
                                                                    label: 'Now',
                                                                    value: new Date()
                                                                }
                                                            ]}
                                                        //oneTap
                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Timezone:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            // //container={() => mountRef.current}
                                                            placeholder={'Timezone...'}
                                                            value={state.newMilestoneModalMilestoneTimezone}
                                                            onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneTimezone') }}
                                                            data={props.CtrlTwrTimezoneData}
                                                            cleanable={true}
                                                            style={{ width: '100%' }}
                                                            // size="sm"
                                                            block
                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Warehouse:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            // container={() => mountRef.current}
                                                            placeholder={'Warehouse...'}
                                                            value={state.newMilestoneModalWarehouseID}
                                                            onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalWarehouseID') }}
                                                            data={state.warehouseArray}
                                                            style={{ width: '100%' }}
                                                            cleanable={true}
                                                            placement={'auto'}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>


                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Country:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            // //container={() => mountRef.current}
                                                            placeholder={'Country...'}
                                                            value={state.newMilestoneModalCountryCodeID}
                                                            onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCountryCodeID') }}
                                                            data={props.CtrlTwrCountryData}
                                                            cleanable={true}
                                                            style={{ width: '100%' }}
                                                            // size="sm"
                                                            block
                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>City:</InputGroup.Addon>
                                                        <Input value={state.newMilestoneModalCity} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCity') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>

                                                {state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID ?
                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>State:</InputGroup.Addon>
                                                            <InputPicker preventOverflow
                                                                // //container={() => mountRef.current}
                                                                // defaultValue={dbValues.USCountryCodeID}
                                                                placeholder={'State...'}
                                                                value={state.newMilestoneModalStateCodeID}
                                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalStateCodeID') }}
                                                                data={props.CtrlTwrStateData}
                                                                cleanable={true}
                                                                style={{ width: '100%' }}
                                                                // size="sm"
                                                                block
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                    :
                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>State:</InputGroup.Addon>
                                                            <Input value={state.newMilestoneModalState} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalState') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                }

                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Zip:</InputGroup.Addon>
                                                        <Input value={state.newMilestoneModalZip} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalZip') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>

                                            </Col>

                                            :

                                            state.newMilestoneModalModeID === 5 ? //Rail

                                                <Col md={24} sm={24} style={styles}>

                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Milestone:</InputGroup.Addon>
                                                            <InputPicker preventOverflow
                                                                // //container={() => mountRef.current}
                                                                placeholder={'Milestone...'}
                                                                value={state.newMilestoneModalMilestoneID}
                                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                                data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                                block
                                                                // size="sm"
                                                                style={{ width: '100%' }}
                                                                cleanable={true}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Date:</InputGroup.Addon>
                                                            <DatePicker
                                                                //container={() => mountRef.current}
                                                                placeholder="Milestone Date"
                                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneDT') }}
                                                                value={state.newMilestoneModalMilestoneDT}
                                                                format="YYYY-MM-DD hh:mm aa"
                                                                defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                showMeridian
                                                                // size="sm"
                                                                style={{ width: '100%' }}
                                                                ranges={[
                                                                    {
                                                                        label: 'Now',
                                                                        value: new Date()
                                                                    }
                                                                ]}
                                                            //oneTap
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Timezone:</InputGroup.Addon>
                                                            <InputPicker preventOverflow
                                                                // //container={() => mountRef.current}
                                                                placeholder={'Timezone...'}
                                                                value={state.newMilestoneModalMilestoneTimezone}
                                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneTimezone') }}
                                                                data={props.CtrlTwrTimezoneData}
                                                                cleanable={true}
                                                                style={{ width: '100%' }}
                                                                // size="sm"
                                                                block
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Warehouse:</InputGroup.Addon>
                                                            <InputPicker preventOverflow
                                                                // container={() => mountRef.current}
                                                                placeholder={'Warehouse...'}
                                                                value={state.newMilestoneModalWarehouseID}
                                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalWarehouseID') }}
                                                                data={state.warehouseArray}
                                                                style={{ width: '100%' }}
                                                                cleanable={true}
                                                                placement={'auto'}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>


                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Country:</InputGroup.Addon>
                                                            <InputPicker preventOverflow
                                                                // //container={() => mountRef.current}
                                                                placeholder={'Country...'}
                                                                value={state.newMilestoneModalCountryCodeID}
                                                                onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCountryCodeID') }}
                                                                data={props.CtrlTwrCountryData}
                                                                cleanable={true}
                                                                style={{ width: '100%' }}
                                                                // size="sm"
                                                                block
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>City:</InputGroup.Addon>
                                                            <Input value={state.newMilestoneModalCity} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCity') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    {state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID ?
                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>State:</InputGroup.Addon>
                                                                <InputPicker preventOverflow
                                                                    // //container={() => mountRef.current}
                                                                    // defaultValue={dbValues.USCountryCodeID}
                                                                    placeholder={'State...'}
                                                                    value={state.newMilestoneModalStateCodeID}
                                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalStateCodeID') }}
                                                                    data={props.CtrlTwrStateData}
                                                                    cleanable={true}
                                                                    style={{ width: '100%' }}
                                                                    // size="sm"
                                                                    block
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                        :
                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>State:</InputGroup.Addon>
                                                                <Input value={state.newMilestoneModalState} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalState') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    }

                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Zip:</InputGroup.Addon>
                                                            <Input value={state.newMilestoneModalZip} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalZip') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>

                                                </Col>

                                                :

                                                state.newMilestoneModalModeID === 6 ? //Brokerage

                                                    <Col md={24} sm={24} style={styles}>

                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Milestone:</InputGroup.Addon>
                                                                <InputPicker preventOverflow
                                                                    // //container={() => mountRef.current}
                                                                    placeholder={'Milestone...'}
                                                                    value={state.newMilestoneModalMilestoneID}
                                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                                    data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                                    block
                                                                    // size="sm"
                                                                    style={{ width: '100%' }}
                                                                    cleanable={true}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Date:</InputGroup.Addon>
                                                                <DatePicker
                                                                    //container={() => mountRef.current}
                                                                    placeholder="Milestone Date"
                                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneDT') }}
                                                                    value={state.newMilestoneModalMilestoneDT}
                                                                    format="YYYY-MM-DD hh:mm aa"
                                                                    defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                    showMeridian
                                                                    // size="sm"
                                                                    style={{ width: '100%' }}
                                                                    ranges={[
                                                                        {
                                                                            label: 'Now',
                                                                            value: new Date()
                                                                        }
                                                                    ]}
                                                                //oneTap
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Timezone:</InputGroup.Addon>
                                                                <InputPicker preventOverflow
                                                                    // //container={() => mountRef.current}
                                                                    placeholder={'Timezone...'}
                                                                    value={state.newMilestoneModalMilestoneTimezone}
                                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneTimezone') }}
                                                                    data={props.CtrlTwrTimezoneData}
                                                                    cleanable={true}
                                                                    style={{ width: '100%' }}
                                                                    // size="sm"
                                                                    block
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Warehouse:</InputGroup.Addon>
                                                                <InputPicker preventOverflow
                                                                    // container={() => mountRef.current}
                                                                    placeholder={'Warehouse...'}
                                                                    value={state.newMilestoneModalWarehouseID}
                                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalWarehouseID') }}
                                                                    data={state.warehouseArray}
                                                                    style={{ width: '100%' }}
                                                                    cleanable={true}
                                                                    placement={'auto'}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>


                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Country:</InputGroup.Addon>
                                                                <InputPicker preventOverflow
                                                                    // //container={() => mountRef.current}
                                                                    placeholder={'Country...'}
                                                                    value={state.newMilestoneModalCountryCodeID}
                                                                    onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCountryCodeID') }}
                                                                    data={props.CtrlTwrCountryData}
                                                                    cleanable={true}
                                                                    style={{ width: '100%' }}
                                                                    // size="sm"
                                                                    block
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>

                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>City:</InputGroup.Addon>
                                                                <Input value={state.newMilestoneModalCity} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCity') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                            </InputGroup>
                                                        </FormGroup>

                                                        {state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID ?
                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>State:</InputGroup.Addon>
                                                                    <InputPicker preventOverflow
                                                                        // //container={() => mountRef.current}
                                                                        // defaultValue={dbValues.USCountryCodeID}
                                                                        placeholder={'State...'}
                                                                        value={state.newMilestoneModalStateCodeID}
                                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalStateCodeID') }}
                                                                        data={props.CtrlTwrStateData}
                                                                        cleanable={true}
                                                                        style={{ width: '100%' }}
                                                                        // size="sm"
                                                                        block
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                            :
                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>State:</InputGroup.Addon>
                                                                    <Input value={state.newMilestoneModalState} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalState') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        }

                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Zip:</InputGroup.Addon>
                                                                <Input value={state.newMilestoneModalZip} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalZip') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                            </InputGroup>
                                                        </FormGroup>

                                                    </Col>


                                                    :



                                                    state.newMilestoneModalModeID === 7 ? //Drayage

                                                        <Col md={24} sm={24} style={styles}>

                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Milestone:</InputGroup.Addon>
                                                                    <InputPicker preventOverflow
                                                                        // //container={() => mountRef.current}
                                                                        placeholder={'Milestone...'}
                                                                        value={state.newMilestoneModalMilestoneID}
                                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneID') }}
                                                                        data={props.CtrlTwrMilestoneRefData.filter(x => [state.newMilestoneModalModeID].includes(x.ModeID))}
                                                                        block
                                                                        // size="sm"
                                                                        style={{ width: '100%' }}
                                                                        cleanable={true}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Date:</InputGroup.Addon>
                                                                    <DatePicker
                                                                        //container={() => mountRef.current}
                                                                        placeholder="Milestone Date"
                                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneDT') }}
                                                                        value={state.newMilestoneModalMilestoneDT}
                                                                        format="YYYY-MM-DD hh:mm aa"
                                                                        defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                        showMeridian
                                                                        // size="sm"
                                                                        style={{ width: '100%' }}
                                                                        ranges={[
                                                                            {
                                                                                label: 'Now',
                                                                                value: new Date()
                                                                            }
                                                                        ]}
                                                                    //oneTap
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Timezone:</InputGroup.Addon>
                                                                    <InputPicker preventOverflow
                                                                        // //container={() => mountRef.current}
                                                                        placeholder={'Timezone...'}
                                                                        value={state.newMilestoneModalMilestoneTimezone}
                                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalMilestoneTimezone') }}
                                                                        data={props.CtrlTwrTimezoneData}
                                                                        cleanable={true}
                                                                        style={{ width: '100%' }}
                                                                        // size="sm"
                                                                        block
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Warehouse:</InputGroup.Addon>
                                                                    <InputPicker preventOverflow
                                                                        // container={() => mountRef.current}
                                                                        placeholder={'Warehouse...'}
                                                                        value={state.newMilestoneModalWarehouseID}
                                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalWarehouseID') }}
                                                                        data={state.warehouseArray}
                                                                        style={{ width: '100%' }}
                                                                        cleanable={true}
                                                                        placement={'auto'}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>


                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Port:</InputGroup.Addon>
                                                                    <InputPicker preventOverflow
                                                                        // //container={() => mountRef.current}
                                                                        placeholder={'Port...'}
                                                                        value={state.newMilestoneModalSeaportCodeID}
                                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalSeaportCodeID') }}
                                                                        data={props.CtrlTwrSeaportData}
                                                                        block
                                                                        // size="sm"
                                                                        style={{ width: '100%' }}
                                                                        cleanable={true}
                                                                        placement={'bottomStart'}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Country:</InputGroup.Addon>
                                                                    <InputPicker preventOverflow
                                                                        // //container={() => mountRef.current}
                                                                        placeholder={'Country...'}
                                                                        value={state.newMilestoneModalCountryCodeID}
                                                                        onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCountryCodeID') }}
                                                                        data={props.CtrlTwrCountryData}
                                                                        cleanable={true}
                                                                        style={{ width: '100%' }}
                                                                        // size="sm"
                                                                        block
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>City:</InputGroup.Addon>
                                                                    <Input value={state.newMilestoneModalCity} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalCity') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            {state.newMilestoneModalCountryCodeID === dbValues.USCountryCodeID ?
                                                                <FormGroup >
                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>State:</InputGroup.Addon>
                                                                        <InputPicker preventOverflow
                                                                            // //container={() => mountRef.current}
                                                                            // defaultValue={dbValues.USCountryCodeID}
                                                                            placeholder={'State...'}
                                                                            value={state.newMilestoneModalStateCodeID}
                                                                            onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalStateCodeID') }}
                                                                            data={props.CtrlTwrStateData}
                                                                            cleanable={true}
                                                                            style={{ width: '100%' }}
                                                                            // size="sm"
                                                                            block
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                                :
                                                                <FormGroup >
                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>State:</InputGroup.Addon>
                                                                        <Input value={state.newMilestoneModalState} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalState') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            }

                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Zip:</InputGroup.Addon>
                                                                    <Input value={state.newMilestoneModalZip} onChange={(e) => { onChangeAddNewMilestone(e, 'newMilestoneModalZip') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>

                                                        </Col>

                                                        :


                                                        <div></div>

                            }




                        </div>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

// newMilestoneModalMilestoneID: null,
// newMilestoneModalMilestoneDT: null,
// newMilestoneModalCountryCodeID: null,
// newMilestoneModalCity: null,
// newMilestoneModalState: null,
// newMilestoneModalZip: null,
// newMilestoneModalSeaportCodeID: null,
// newMilestoneModalAirportCodeID: null,

export default MilestoneEventModal;