export const VisibilityPackageDataElements = [
    {
        dBKey: 'TrackingNumber',
        displayName: 'Tracking Number',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'OrderNo',
        displayName: 'Order No',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'MC',
        displayName: 'MC',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },   
    {
        dBKey: 'ParcelWeight',
        displayName: 'Parcel Weight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },     
    {
        dBKey: 'DestinationAirport',
        displayName: 'Destination Airport',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'R_PostCode',
        displayName: 'Ship To Postal Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'R_State',
        displayName: 'Ship To State',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },

    {
        dBKey: 'R_CountryCode',
        displayName: 'Ship To Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 220
    },    

    


]


export default VisibilityPackageDataElements

