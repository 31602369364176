import { connect } from 'react-redux';
import React, { Component } from 'react';
import Outbound from './Outbound';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryOutboundLoading: store.inventoryReducer.isInventoryOutboundLoading,
    InventoryOutboundData: store.inventoryReducer.InventoryOutboundData,



});

const mapDispatchToProps = dispatch =>
({

    inventoryOutboundGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _ContainerID, _Container, _SkuID, _SKU, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _Seal, _Carrier, _Trailer, _BOL) => {
        dispatch(inventoryActions.inventoryOutboundGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _ContainerID, _Container, _SkuID, _SKU, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _Seal, _Carrier, _Trailer, _BOL))
    },


});

class OutboundContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }

    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramSKU = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramSealNo = formatStringForAPI(values.sealno)
        }
        catch (e) {
            var paramSealNo = null
        }
        try {
            var paramDepartDateStart = formatDateForAPI(values.departdatestart)
        }
        catch (e) {
            var paramDepartDateStart = null
        }
        try {
            var paramDepartDateEnd = formatDateForAPI(values.departdateend)
        }
        catch (e) {
            var paramDepartDateEnd = null
        }

        if (paramSKU == null && paramContainer == null && paramSealNo == null && paramDepartDateStart == null && paramDepartDateEnd == null) {

        }
        else {
            this.props.inventoryOutboundGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, paramContainer, null, paramSKU, paramDepartDateStart, paramDepartDateEnd, null, null, paramSealNo, null, null, null)
        }


    }



    render() {

        return (

            <Outbound
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventoryOutboundLoading={this.props.isInventoryOutboundLoading}
                InventoryOutboundData={this.props.InventoryOutboundData}


            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundContainer));





