import { connect } from 'react-redux';
import React, { Component } from 'react';
import Inbound from './Inbound'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryLotContainerLoading: store.inventoryReducer.isInventoryLotContainerLoading,
    InventoryLotContainerData: store.inventoryReducer.InventoryLotContainerData,

    InventoryLotContainerPostSuccessful: store.inventoryReducer.InventoryLotContainerPostSuccessful,
    NewLotContainerId: store.inventoryReducer.NewLotContainerId,




    // InventoryLotContainerPostSuccessful2: store.inventoryReducer.InventoryLotContainerPostSuccessful2,
    // NewLotContainerId2: store.inventoryReducer.NewLotContainerId2,

    isInventoryContainerSkuLoading: store.inventoryReducer.isInventoryContainerSkuLoading,
    InventoryContainerSkuData: store.inventoryReducer.InventoryContainerSkuData,

    isInventoryPostLoading: store.inventoryReducer.isInventoryPostLoading,

    InventoryContainerSkuPostSuccessful: store.inventoryReducer.InventoryContainerSkuPostSuccessful,

    isInventoryLotSkuLoading: store.inventoryReducer.isInventoryLotSkuLoading,
    InventoryLotSkuData: store.inventoryReducer.InventoryLotSkuData,

    // InventorySkuRecoveryPostSuccessful: store.inventoryReducer.InventorySkuRecoveryPostSuccessful,

    InventorySkuWarehouseLocationPostSuccessful: store.inventoryReducer.InventorySkuWarehouseLocationPostSuccessful,


    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,


    InventoryRecoveryPostLoading: store.inventoryReducer.InventoryRecoveryPostLoading,
    InventoryRecoveryPostSuccessful: store.inventoryReducer.InventoryRecoveryPostSuccessful,
    InventoryRecoveryPalletBarcodes: store.inventoryReducer.InventoryRecoveryPalletBarcodes,
    InventoryRecoveryCaseBarcodes: store.inventoryReducer.InventoryRecoveryCaseBarcodes,

    InventoryRecoveryPalletContent: store.inventoryReducer.InventoryRecoveryPalletContent,
    InventoryRecoveryCaseSku: store.inventoryReducer.InventoryRecoveryCaseSku,
    InventoryRecoveryCaseInfo: store.inventoryReducer.InventoryRecoveryCaseInfo,

    isInventoryPalletWarehouseLoading2: store.inventoryReducer.isInventoryPalletWarehouseLoading2,
    InventoryPalletWarehouseData2: store.inventoryReducer.InventoryPalletWarehouseData2,



    isInventorySkuRefLoading: store.inventoryReducer.isInventorySkuRefLoading,
    InventorySkuRefData: store.inventoryReducer.InventorySkuRefData,


});

const mapDispatchToProps = dispatch =>
({

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryLotContainerGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotContainerGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _ContainerStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },

    inventoryLotContainerPost: (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized, _ContainerSize) => {
        dispatch(inventoryActions.inventoryLotContainerPost(_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized, _ContainerSize))
    },
    // inventoryLotContainerPost2: (_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized) => {
    //     dispatch(inventoryActions.inventoryLotContainerPost2(_email, _authToken, _ContainerID, _Container, _LCL_FCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID, _Palletized))
    // },

    inventorySkuPost: (_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) => {
        dispatch(inventoryActions.inventorySkuPost(_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes))
    },


    inventoryContainerSkuGet: (_email, _authToken, _ContainerID, _SkuID) => {
        dispatch(inventoryActions.inventoryContainerSkuGet(_email, _authToken, _ContainerID, _SkuID))
    },

    inventoryContainerSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _SkuID, _SKU, _Units, _Cases, _Pallets, _Delete, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) => {
        dispatch(inventoryActions.inventoryContainerSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _SkuID, _SKU, _Units, _Cases, _Pallets, _Delete, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes))
    },

    inventoryLotSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },




    inventorySkuWarehouseLocationPost: (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationPost(_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription))
    },

    // inventorySkuRecoveryPost: (_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate) => {
    //     dispatch(inventoryActions.inventorySkuRecoveryPost(_email, _authToken, _SkuID, _ContainerID, _Container, _SkuDescription, _UnitsReceived, _CasesReceived, _PalletsReceived, _Oversize, _CustomsCleared, _RecoveryDate))
    // },

    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    inventoryRecoveryPost: (_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _ContainerID, _CustomsCleared, _Palletized, _RecoveryDate) => {
        dispatch(inventoryActions.inventoryRecoveryPost(_email, _authToken, _WarehouseID, _CustomerID, _DataJson, _ContainerID, _CustomsCleared, _Palletized, _RecoveryDate))
    },


    inventoryPalletWarehouseLocationGet2: (_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize) => {
        dispatch(inventoryActions.inventoryPalletWarehouseLocationGet2(_email, _authToken, _WarehouseLocationID, _WarehouseID, _CustomerID, _PalletStatusID, _CaseStatusID, _SkuStatusID, _PalletID, _PalletBarcode, _CaseID, _CaseBarcode, _CaseSkuID, _CaseLotCode, _CaseSerialNumber, _SkuID, _SKU, _SkuLotCode, _SkuSerialNumber, _ContainerID, _Container, _RecoveryDateStart, _RecoveryDateEnd, _PalletOversize))
    },


    inventorySkuRefGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber) => {
        dispatch(inventoryActions.inventorySkuRefGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber))
    },

});

class InboundContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.handleUrlParam();
        // this.props.userWarehousesGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }


        if (prevProps.InventoryLotContainerPostSuccessful !== this.props.InventoryLotContainerPostSuccessful && this.props.InventoryLotContainerPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }


        if (prevProps.InventoryContainerSkuPostSuccessful !== this.props.InventoryContainerSkuPostSuccessful && this.props.InventoryContainerSkuPostSuccessful) {
            this.handleUrlParam();
        }


        if (prevProps.InventoryRecoveryPostSuccessful !== this.props.InventoryRecoveryPostSuccessful && this.props.InventoryRecoveryPostSuccessful) {
            this.handleUrlParam();
        }



    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }

        try {
            var paramSkuID = queryStringParse(values.skuid)
        }
        catch (e) {
            var paramSkuID = null
        }
        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }

        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }



        this.props.inventoryLotContainerGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, paramContainer, paramStatus, paramSkuID, paramSku, null);





    }



    render() {

        return (

            <Inbound
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isInventoryLotContainerLoading={this.props.isInventoryLotContainerLoading}
                InventoryLotContainerData={this.props.InventoryLotContainerData}

                inventoryLotContainerPost={this.props.inventoryLotContainerPost}

                InventoryLotContainerPostSuccessful={this.props.InventoryLotContainerPostSuccessful}
                NewLotContainerId={this.props.NewLotContainerId}

                // inventoryLotContainerPost2={this.props.inventoryLotContainerPost2}
                // InventoryLotContainerPostSuccessful2={this.props.InventoryLotContainerPostSuccessful2}
                // NewLotContainerId2={this.props.NewLotContainerId2}

                inventoryContainerSkuGet={this.props.inventoryContainerSkuGet}
                isInventoryContainerSkuLoading={this.props.isInventoryContainerSkuLoading}
                InventoryContainerSkuData={this.props.InventoryContainerSkuData}

                inventorySkuPost={this.props.inventorySkuPost}


                inventoryLotSkuGet={this.props.inventoryLotSkuGet}
                isInventoryLotSkuLoading={this.props.isInventoryLotSkuLoading}
                InventoryLotSkuData={this.props.InventoryLotSkuData}

                inventoryContainerSkuPost={this.props.inventoryContainerSkuPost}

                InventoryContainerSkuPostSuccessful={this.props.InventoryContainerSkuPostSuccessful}

                InventorySkuWarehouseLocationPostSuccessful={this.props.InventorySkuWarehouseLocationPostSuccessful}
                inventorySkuWarehouseLocationPost={this.props.inventorySkuWarehouseLocationPost}

                // inventorySkuRecoveryPost={this.props.inventorySkuRecoveryPost}
                // InventorySkuRecoveryPostSuccessful={this.props.InventorySkuRecoveryPostSuccessful}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}

                InventoryRecoveryPostLoading={this.props.InventoryRecoveryPostLoading}
                InventoryRecoveryPostSuccessful={this.props.InventoryRecoveryPostSuccessful}
                InventoryRecoveryPalletBarcodes={this.props.InventoryRecoveryPalletBarcodes}
                InventoryRecoveryCaseBarcodes={this.props.InventoryRecoveryCaseBarcodes}
                InventoryRecoveryPalletContent={this.props.InventoryRecoveryPalletContent}
                InventoryRecoveryCaseSku={this.props.InventoryRecoveryCaseSku}
                InventoryRecoveryCaseInfo={this.props.InventoryRecoveryCaseInfo}
                inventoryRecoveryPost={this.props.inventoryRecoveryPost}


                inventoryPalletWarehouseLocationGet2={this.props.inventoryPalletWarehouseLocationGet2}

                isInventoryPalletWarehouseLoading2={this.props.isInventoryPalletWarehouseLoading2}
                InventoryPalletWarehouseData2={this.props.InventoryPalletWarehouseData2}


                inventorySkuRefGet={this.props.inventorySkuRefGet}
                isInventorySkuRefLoading={this.props.isInventorySkuRefLoading}
                InventorySkuRefData={this.props.InventorySkuRefData}


                isInventoryPostLoading={this.props.isInventoryPostLoading}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundContainer));



