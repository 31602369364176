import React from 'react';
import Barcode from 'react-barcode';
import { Card, CardTitle, CardText, CardMedia } from 'material-ui/Card';
import { Row, Col, Panel, PanelGroup, Button, FlexboxGrid, Tree, Container, Sidebar, Header, Footer, Content } from 'rsuite';

const parentStyles = {
    position: 'relative',
    // width: '500px', height: '750px',
};




export default class CaseContentsNoMapping extends React.Component {
    render() {
        const { content } = this.props;

        return (

            <div id="div-svg" style={{ display: 'flex', flexDirection: 'column'}}>


                <div style={parentStyles}>

                    <br />

                        <div style={{ outline: 'inset', marginBottom: '5px', marginRight: '40px' }}>

                            <strong>Unit:</strong> {content.SKU} (Qty: {content.UnitsInWhse}) <br />
                            -  Unit Lot: {content.LotCode}<br />
                            -  Unit Serial: {content.SerialNumber}<br />

                            <br />


                        </div>

                </div>

                <br />
                <br />
            </div>


        );
    }
}

