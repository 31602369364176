import {connect} from 'react-redux';
import React, {Component} from 'react';
import PendingUsers from './PendingUsers'
import * as actions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'

//add props from reducer here
const mapStateToProps = store =>
({
    isPendingUserRequestsLoading:   store.userServicesReducer.isPendingUserRequestsLoading,
    pendingUserRequestsData:        store.userServicesReducer.pendingUserRequestsData,
    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail
});


//add actions here
const mapDispatchToProps = dispatch =>
({

    getPendingUserAccounts:( _email ) =>
    {
        dispatch( actions.getPendingUserAccounts( _email ) )
    },

    postUserUpdate:( _email, _emailToUpdate, _firstname, _lastname, _active, _admin, _pending ) =>
    {
        dispatch( actions.postUserUpdate( _email, _emailToUpdate, _firstname, _lastname, _active, _admin, _pending ) )
    },

    // init: () => {
    //     dispatch(reducer.init())
    //   },

    //look at HeaderContainer
    signUp: (username, password) => 
    {
        dispatch( reducer.signUp(username, password) )
    }

});



class PendingUsersContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	componentDidMount()
	{
        this.props.getPendingUserAccounts( this.props.currentUserEmail );
	}
	
    render()
    {
    	return (
            <PendingUsers 
            isPendingUserRequestsLoading={ this.props.isPendingUserRequestsLoading }
            pendingUserRequestsData={ this.props.pendingUserRequestsData }
            postUserUpdate={ this.props.postUserUpdate }
            signUp={ this.props.signUp }
            userSignInData={ this.props.userSignInData }
            currentUserEmail={this.props.currentUserEmail}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingUsersContainer);




