export const ContainerDataElements = [
    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'ContainerStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'LCL_FCL',
        displayName: 'LCL/FCL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'DeliveryAppointment',
        displayName: 'Delivery Appointment',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    // {
    //     dBKey: 'ReceivedDT',
    //     displayName: 'Received DT',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    {
        dBKey: 'ETD',
        displayName: 'ETD',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'ETA',
        displayName: 'ETA',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'Notes',
        displayName: 'Notes',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },


]


export default ContainerDataElements

