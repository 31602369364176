export const CtrlTwrShipmentMilestoneAlertDataElements = [
    {
        dBKey: 'ProRef',
        displayName: 'PRO',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Alpha2Code',
        displayName: 'Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'ETD',
        displayName: 'Planned Pickup',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },


    {
        dBKey: 'LegNumber',
        displayName: 'Current Leg Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ModeName',
        displayName: 'Current Mode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },    
    {
        dBKey: 'EstimatedDeliveryDate',
        displayName: 'Estimated Delivery Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
    {
        dBKey: 'Milestone',
        displayName: 'Current Milestone',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },  
    {
        dBKey: 'MilestoneDT',
        displayName: 'Current Milestone DT',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    

    {
        dBKey: 'HrsSinceLastMilestone',
        displayName: 'Hours Since Last Milestone',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    



    {
        dBKey: 'MissingMilestone',
        displayName: 'Milestone Alert',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
    // {
    //     dBKey: 'CarrierYellowAlerts',
    //     displayName: 'Carrier Yellow',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 120,
    //     flag: true,
    //     flagColor: 'yellow'
    // },    
        
    // {
    //     dBKey: 'CarrierRedAlerts',
    //     displayName: 'Carrier Red',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 120,
    //     flag: true,
    //     flagColor: 'red'
    // },    
    {
        dBKey: 'CustomerYellowAlerts',
        displayName: 'Customer Yellow',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 130,
        flag: true,
        flagColor: 'yellow'
    },    
    {
        dBKey: 'CustomerRedAlerts',
        displayName: 'Customer Red',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120,
        flag: true,
        flagColor: 'red'
    },   
    






    {
        dBKey: 'LateShipment',
        displayName: 'Late Shipment',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },    
]


export default CtrlTwrShipmentMilestoneAlertDataElements

