import { connect } from 'react-redux';
import React, { Component } from 'react';
import Outbound from './Outbound'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI, formatDateForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isOutboundLoading:                                  store.vasReducer.isOutboundLoading,
    OutboundData:                                       store.vasReducer.OutboundData,

    isHawbDestinationLoading: store.vasReducer.isHawbDestinationLoading,
    HawbDestinationData: store.vasReducer.HawbDestinationData,



});

const mapDispatchToProps = dispatch =>
({

    outboundGet:( _email, _authToken, _LoadID, _HouseID, _HouseRef, _MasterID, _Master, _OrderNo, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _DCCode, _Carrier, _Trailer, _Seal, _BOL, _WarehouseID, _CustomerID  ) =>
    {
        dispatch( actions.outboundGet( _email, _authToken, _LoadID, _HouseID, _HouseRef, _MasterID, _Master, _OrderNo, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _DCCode, _Carrier, _Trailer, _Seal, _BOL, _WarehouseID, _CustomerID  ) )
    },

    hawbDestinationGet: (_email, _authToken, _HawbStatusID) => {
        dispatch(actions.hawbDestinationGet(_email, _authToken, _HawbStatusID))
    },


});

class OutboundContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
        // this.props.userWarehousesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.PostLoadSuccessful !== this.props.PostLoadSuccessful && this.props.PostLoadSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }


    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramHouseRef = formatStringForAPI(values.houseref)
        }
        catch (e) {
            var paramHouseRef = null
        }
        try {
            var paramMaster = formatStringForAPI(values.master)
        }
        catch (e) {
            var paramMaster = null
        }
        try {
            var paramOrderNo = formatStringForAPI(values.orderno)
        }
        catch (e) {
            var paramOrderNo = null
        }
        try {
            var paramSealNo = formatStringForAPI(values.sealno)
        }
        catch (e) {
            var paramSealNo = null
        }
        try {
            var paramDepartDateStart = formatDateForAPI(values.departdatestart)
        }
        catch (e) {
            var paramDepartDateStart = null
        }
        try {
            var paramDepartDateEnd = formatDateForAPI(values.departdateend)
        }
        catch (e) {
            var paramDepartDateEnd = null
        }
        try {
            var paramDestination = queryStringParse(values.destinationid)
        }
        catch (e) {
            var paramDestination = null
        }


        // try {
        //     var paramWarehouseID = queryStringParse(values.warehouseid)
        // }
        // catch (e) {
        //     var paramWarehouseID = null
        // }



        if( paramHouseRef == null && paramMaster == null && paramOrderNo == null && paramSealNo == null && paramDepartDateStart == null && paramDepartDateEnd == null && paramDestination == null ){

        }
        else{
            this.props.outboundGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramHouseRef, null, paramMaster, paramOrderNo, paramDepartDateStart, paramDepartDateEnd, paramDestination, null, null, null, null, paramSealNo, null, this.props.warehouseSelection, this.props.customerSelection )
        }

        this.props.hawbDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null)

    }



    render() {

        return (

            <Outbound
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isOutboundLoading={this.props.isOutboundLoading}
                OutboundData={this.props.OutboundData}                
                
                isHawbDestinationLoading={this.props.isHawbDestinationLoading}
                HawbDestinationData={this.props.HawbDestinationData}

            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundContainer));



