import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, Input } from 'rsuite';
import  {lettersNumbersDashesSpacesCommasInputMask, textNumbersSymbolsInputmask} from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

function ShipmentManagementMultiModal({
    multiModalShown,
    handleSave,
    handleClose,
    onChangeMultiModal,
    resetRows,
    state, ...props

}) {
    return (

        <div className="modal-container">
            <Modal backdrop={'static'} keyboard={false} show={multiModalShown} onHide={handleClose} onExited={resetRows}>
                <Modal.Header>
                    <Modal.Title>Add {state.multiModalStateName}:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Panel bordered style={{ marginBottom: '15px' }}>
                        <Row>
                            <Form fluid>
                                <Col md={24} sm={24} >

                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>{state.multiModalStateName}:</InputGroup.Addon>
                                        <Input value={state.multiModalNewValue} onChange={(e) => { onChangeMultiModal(e) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                    </InputGroup>

                                    <p>
                                        To enter multiple values, separate using commas.
                                    </p>

                                </Col>
                            </Form>
                        </Row>
                    </Panel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSave} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default ShipmentManagementMultiModal;