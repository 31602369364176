import React, {
    PureComponent
} from 'react';
import { Message } from 'rsuite';
import AnimatedView from '../../../../components/componentsGeneral/AnimatedView/AnimatedView';
import OrderPickingContainer from '../../../../components/componentsInventoryManagement/_Lot_Tracking_Components/OrderPicking/OrderPickingContainer';
import { lotTrackingWarehouseIDs } from '../../../../app/appConfig';

class invManInventoryLotOrderPickingView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    componentDidMount() {

    }

    render() {
        if (!lotTrackingWarehouseIDs.includes(this.props.warehouseSelection)) {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please only select Lot Tracking warehouses" /></AnimatedView>;
        }

        return (
            <AnimatedView>
                <OrderPickingContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default invManInventoryLotOrderPickingView;
