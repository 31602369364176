import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { lettersNumbersDashesSpacesInputMask, hundredthsDecimalInputMask, limitUpperCaseLengthMask, zipCodeInputMask, limitCharacterLength, textNumbersSymbolsInputmask, textStringInputMask } from '../../../resources/logic/inputMasks';
import { Row, Col, Panel, PanelGroup, Button, Timeline, ButtonToolbar, FormGroup, InputGroup, InputPicker, Input, Whisper, DatePicker, Tooltip, IconButton, Form, Checkbox } from 'rsuite';
import { formatNumberForAPI, formatStringForAPI, formatDateForUrlSearch, formatDateTimeForUrlSearch, findLowestMissingNumber, formatTrueFalseToBit, formatTextNumbersSymbolsForAPI, formatAllTextNumbersSymbolsForAPI, findMaxNumberInArray } from '../../../resources/logic/functions';
import MinusIcon from '@rsuite/icons/Minus';
import CloseIcon from '@rsuite/icons/Close';
import queryString from 'query-string';
import ReactExport from "react-data-export";
import ShipmentManagementAddNewLegModal from '../ShipmentManagement/ShipmentManagementAddNewLegModal';
import ShipmentManagementMultiModal from '../ShipmentManagement/ShipmentManagementMultiModal';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';

import * as moment from 'moment'

import QuoteShipmentDetailsEditDrawer from './QuoteShipmentDetailsEditDrawer';
import RUploaderQuoting from '../../componentsGeneral/Rsuite/Uploader/RUploaderQuoting';
import { appAPI } from '../../../app/appConfig';
import CtrlTwrShipmentFileUploadsDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentFileUploadsDataElements';
import QuoteShipmentStatusModal from './QuoteShipmentStatusModal';
import QuoteShipmentEmailModal from './QuoteShipmentEmailModal';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "ShipmentLegMilestoneID"

const tooltipDelete = (
  <Tooltip>
    Delete
  </Tooltip>
);

const styles = {
  marginTop: '5px',
  width: '100%'
};

const inputStyle = {
  width: '100%'
};


const mountRef = React.createRef();





class QuoteShipmentDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

      //Warehouse & Customer Arrays
      warehouseArray: {},
      customerArray: {},

      //Ship Management Drawer
      ShipManagementExistingDict: {},
      LegsExistingLineItems: {},
      unsavedLegsChanges: false,
      ShipManagementProRef: null,
      AddNewLegDict: {},

      LineItemsToDelete: [],

      //Shipment Details Edit Drawer
      showQuoteShipmentDetailsEditDrawer: false,
      ShipEntryPO: '',
      ShipEntryCustomer: null,
      ShipEntryOriginCountry: '',
      ShipEntryDestinationCountry: '',
      ShipEntryETD: null,
      ShipEntryPallets: null,
      ShipEntryCases: null,
      ShipEntryUnitCount: null,
      ShipEntryWeightUnits: null,
      UnsavedShipEntryChanges: false,

      ShipmentQuoteActive: null,
      QuotingStatusID: null,

      SpecialRequirements: null,
      GrossWeight: null,
      ChargeableWeight: null,
      Length: null,
      Width: null,
      Height: null,
      DimUnits: null,
      Commodity: null,



      //Add new Leg Modal
      addNewLegModalShown: false,
      newLegModalLegNumber: null,
      newLegModalMode: null,

      //Multi Modal
      multiModalShown: false,
      multiModalLegNumber: null,
      multiModalStateName: null,
      multiModalHbolID: null,
      multiModalNewValue: '',

      multiModalValuesSaving: false,

      //Quote Action Modal:
      quoteActionModalShown: false,


      //Ship Management Drawer
      showMilestoneEntryDrawer: false,

      unsavedLegsChanges: false,
      ShipManagementProRef: null,
      AddNewLegDict: {},

      //Notes
      ShipmentNote: '',
      NoteIsPrivate: true,


      //Email Modal
      quoteEmailModalShown: false,
      emailText: ''

    };


    this.onClickEditShipmentDetails = this.onClickEditShipmentDetails.bind(this);
    this.onClickEmailText = this.onClickEmailText.bind(this);
    this.preloadShipEditDrawer = this.preloadShipEditDrawer.bind(this);

    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);


    //Ship Management Drawer
    this.onClickOpenShipManagmenetDrawer = this.onClickOpenShipManagmenetDrawer.bind(this);

    //Ship Management Drawer
    this.loadShipLegData = this.loadShipLegData.bind(this);
    this.onChangeShipmentManagement = this.onChangeShipmentManagement.bind(this);
    this.onClickShipmentManagementDrawerSave = this.onClickShipmentManagementDrawerSave.bind(this);
    this.onClickDeleteLeg = this.onClickDeleteLeg.bind(this);
    this.onClickShowAddLeg = this.onClickShowAddLeg.bind(this);
    this.onClickShowAddInitialLeg = this.onClickShowAddInitialLeg.bind(this);
    this.closeShipmentManagementDrawer = this.closeShipmentManagementDrawer.bind(this);
    this.setShipmentManagementDrawerToClose = this.setShipmentManagementDrawerToClose.bind(this);
    this.onClickMulti = this.onClickMulti.bind(this);
    this.onClickDeleteMulti = this.onClickDeleteMulti.bind(this);

    this.onClickDeleteLineItem = this.onClickDeleteLineItem.bind(this);

    //Shipment Details Edit Drawer
    this.onClickSaveShipDetailsEdit = this.onClickSaveShipDetailsEdit.bind(this);
    this.setShipDetailsEditDrawerToClose = this.setShipDetailsEditDrawerToClose.bind(this);
    this.closeShipDetailsEditDrawer = this.closeShipDetailsEditDrawer.bind(this);
    this.onChangeWarehouse = this.onChangeWarehouse.bind(this);
    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.onChangePO = this.onChangePO.bind(this);
    this.onChangeOriginCountry = this.onChangeOriginCountry.bind(this);
    this.onChangeDestinationCountry = this.onChangeDestinationCountry.bind(this);
    this.onChangeETD = this.onChangeETD.bind(this);

    this.onChangePallets = this.onChangePallets.bind(this);
    this.onChangeCases = this.onChangeCases.bind(this);
    this.onChangeUnitCount = this.onChangeUnitCount.bind(this);
    this.onChangeWeightUnits = this.onChangeWeightUnits.bind(this);

    this.onChangeFunc = this.onChangeFunc.bind(this);

    //Add new Leg Modal
    this.handleSaveAddNewLegModal = this.handleSaveAddNewLegModal.bind(this);
    this.handleCloseAddNewLegModal = this.handleCloseAddNewLegModal.bind(this);
    this.onChangeAddNewLegModal = this.onChangeAddNewLegModal.bind(this);

    //Multi Modal
    this.onChangeMultiModal = this.onChangeMultiModal.bind(this);
    this.handleCloseMultiModal = this.handleCloseMultiModal.bind(this);
    this.handleSaveMultiModal = this.handleSaveMultiModal.bind(this);
    this.setMultiModalToSaving = this.setMultiModalToSaving.bind(this);

    //Quote Action Modal
    this.onClickOpenQuoteActionModal = this.onClickOpenQuoteActionModal.bind(this);
    this.onClickApproveRejectQuote = this.onClickApproveRejectQuote.bind(this);
    this.handleCloseActionModal = this.handleCloseActionModal.bind(this);


    //File Upload
    this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);
    this.onClickDeleteFileUpload = this.onClickDeleteFileUpload.bind(this);

    //Notes
    this.handleSaveAddNewNote = this.handleSaveAddNewNote.bind(this);
    this.onChangeCheckBoxOrgNote = this.onChangeCheckBoxOrgNote.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onClickDeletePublicNote = this.onClickDeletePublicNote.bind(this);
    this.onClickDeletePrivateNote = this.onClickDeletePrivateNote.bind(this);


    //EMail modal
    this.handleCloseEmailModal = this.handleCloseEmailModal.bind(this);
  }


  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection

    this.loadShipLegData()

  }


  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }

    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }

  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.CtrlTwrQuotingShipmentLegsQuoteData !== this.props.CtrlTwrQuotingShipmentLegsQuoteData && this.props.CtrlTwrQuotingShipmentLegsQuoteData.length > 0) {
      this.preloadShipManagement()
    }

    if (prevProps.CtrlTwrQuotingShipmentLineItemsData !== this.props.CtrlTwrQuotingShipmentLineItemsData && this.props.CtrlTwrQuotingShipmentLineItemsData.length > 0) {
      this.props.ctrlTwrQuotingLineItemGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null)

      this.preloadLineItemsManagement()
    }


    if (prevProps.CtrlTwrShipmentLegsDataPostSuccessful !== this.props.CtrlTwrShipmentLegsDataPostSuccessful && this.props.CtrlTwrShipmentLegsDataPostSuccessful) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrQuotingShipmentLegsQuoteGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
        }
        catch (e) { }
      }, 100);
    }


    if (prevProps.CtrlTwrQuotingShipmentLegsPostSuccessful !== this.props.CtrlTwrQuotingShipmentLegsPostSuccessful && this.props.CtrlTwrQuotingShipmentLegsPostSuccessful) {
      setTimeout(() => {
        try {
          const values = queryString.parse(this.props.location.search)

          try {
            var paramPro = formatStringForAPI(values.pro)
          }
          catch (e) {
            var paramPro = null
          }
          this.props.ctrlTwrQuotingShipmentLegsQuoteGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
        }
        catch (e) { }
      }, 100);
    }



    if (prevProps.CtrlTwrShipmentNotesPrivatePostSuccessful !== this.props.CtrlTwrShipmentNotesPrivatePostSuccessful && this.props.CtrlTwrShipmentNotesPrivatePostSuccessful) {
      const values = queryString.parse(this.props.location.search)
      try {
        var paramPro = formatStringForAPI(values.pro)
      }
      catch (e) {
        var paramPro = null
      }
      this.setState({
        ShipmentNote: '',
        NoteIsPrivate: true
      });
      this.props.ctrlTwrQuotingShipmentPrivateNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)
    }

    if (prevProps.CtrlTwrShipmentNotesPublicPostSuccessful !== this.props.CtrlTwrShipmentNotesPublicPostSuccessful && this.props.CtrlTwrShipmentNotesPublicPostSuccessful) {
      const values = queryString.parse(this.props.location.search)
      try {
        var paramPro = formatStringForAPI(values.pro)
      }
      catch (e) {
        var paramPro = null
      }
      this.setState({
        ShipmentNote: '',
        NoteIsPrivate: true
      });
      this.props.ctrlTwrQuotingShipmentPublicNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)
    }



  }


  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }




  //Shipment Details Edit Drawer
  onClickEditShipmentDetails(e) {
    this.preloadShipEditDrawer()

    this.setState({
      showQuoteShipmentDetailsEditDrawer: true,
    });

  }

  preloadShipEditDrawer() {
    var poEdit = this.props.CtrlTwrQuotingShipmentData[0].PO
    var customerEdit = this.props.CtrlTwrQuotingShipmentData[0].CustomerID
    var countryEdit = this.props.CtrlTwrQuotingShipmentData[0].OriginCountryID
    var destinationEdit = this.props.CtrlTwrQuotingShipmentData[0].DestinationCountryID
    var etdEdit = this.props.CtrlTwrQuotingShipmentData[0].ETD

    var palletsEdit = this.props.CtrlTwrQuotingShipmentData[0].Pallets
    var casesEdit = this.props.CtrlTwrQuotingShipmentData[0].Cases
    var unitCountEdit = this.props.CtrlTwrQuotingShipmentData[0].UnitCount
    var weightUnitsEdit = this.props.CtrlTwrQuotingShipmentData[0].WeightUnits


    var editShipmentQuoteActive = this.props.CtrlTwrQuotingShipmentData[0].ShipmentQuoteActive
    var editQuotingStatusID = this.props.CtrlTwrQuotingShipmentData[0].QuotingStatusID
    var editSpecialRequirements = this.props.CtrlTwrQuotingShipmentData[0].SpecialRequirements
    var editGrossWeight = this.props.CtrlTwrQuotingShipmentData[0].GrossWeight
    var editChargeableWeight = this.props.CtrlTwrQuotingShipmentData[0].ChargeableWeight
    var editLength = this.props.CtrlTwrQuotingShipmentData[0].Length
    var editWidth = this.props.CtrlTwrQuotingShipmentData[0].Width
    var editHeight = this.props.CtrlTwrQuotingShipmentData[0].Height
    var editDimUnits = this.props.CtrlTwrQuotingShipmentData[0].DimUnits
    var editCommodity = this.props.CtrlTwrQuotingShipmentData[0].Commodity

    this.setState({
      ShipEntryPO: poEdit,
      ShipEntryCustomer: customerEdit,
      ShipEntryOriginCountry: countryEdit,
      ShipEntryDestinationCountry: destinationEdit,
      ShipEntryETD: etdEdit,

      ShipEntryPallets: palletsEdit,
      ShipEntryCases: casesEdit,
      ShipEntryUnitCount: unitCountEdit,
      ShipEntryWeightUnits: weightUnitsEdit,
      ShipmentQuoteActive: editShipmentQuoteActive,
      QuotingStatusID: editQuotingStatusID,
      SpecialRequirements: editSpecialRequirements,
      GrossWeight: editGrossWeight,
      ChargeableWeight: editChargeableWeight,
      Length: editLength,
      Width: editWidth,
      Height: editHeight,
      DimUnits: editDimUnits,
      Commodity: editCommodity,

      UnsavedShipEntryChanges: false,
    });
    // this.forceUpdate()




  }


  onClickEmailText(e, legItem) {

    try {
      var ModeName = <><strong>Ship Mode:</strong> {legItem.ModeName}</>
    }
    catch (e) {
      var ModeName = <div></div>
    }

    try {
      var OriginAddress = <><strong>Origin Address:</strong> {legItem.Shipper_Name}{(legItem.Shipper_Name !== '' && legItem.Origin_AddressLine !== null ? ', ' : '')}{legItem.Origin_AddressLine}{(legItem.Origin_City !== null ? ', ' : '')}{legItem.Origin_City}{(legItem.Origin_State !== null ? ', ' : '')}{legItem.Origin_State}{(legItem.Origin_PostalCode !== null ? ' ' : '')}{legItem.Origin_PostalCode}{(legItem.Origin_CountryCode !== null ? ', ' : '')}{legItem.Origin_CountryCode}</>
    }
    catch (e) {
      var OriginAddress = <div></div>
    }

    try {
      var DestinationAddress = <><strong>Destination Address:</strong> {legItem.Consignee_Name}{(legItem.Consignee_Name !== '' && legItem.Destination_AddressLine !== null ? ', ' : '')}{legItem.Destination_AddressLine}{(legItem.Destination_City !== null ? ', ' : '')}{legItem.Destination_City}{(legItem.Destination_State !== null ? ', ' : '')}{legItem.Destination_State}{(legItem.Destination_PostalCode !== null ? ' ' : '')}{legItem.Destination_PostalCode}{(legItem.Destination_CountryCode !== null ? ', ' : '')}{legItem.Destination_CountryCode}</>
    }
    catch (e) {
      var DestinationAddress = <div></div>
    }



    try {
      var pickupDt = <><strong>Planned Pickup Date:</strong> {this.props.CtrlTwrQuotingShipmentData[0].ETD}</>
    }
    catch (e) {
      var pickupDt = <div></div>
    }




    try {
      var commodity = <><strong>Commodity:</strong> {this.props.CtrlTwrQuotingShipmentData[0].Commodity}</>
    }
    catch (e) {
      var commodity = <div></div>
    }


    try {
      var Pallets = <><strong>Pallets:</strong> {this.props.CtrlTwrQuotingShipmentData[0].Pallets}</>
    }
    catch (e) {
      var Pallets = <div></div>
    }

    try {
      var Cases = <><strong>Cases:</strong> {this.props.CtrlTwrQuotingShipmentData[0].Cases}</>
    }
    catch (e) {
      var Cases = <div></div>
    }


    try {
      var SpecialRequirements = <><strong>Special Requirements:</strong> {this.props.CtrlTwrQuotingShipmentData[0].SpecialRequirements}</>
    }
    catch (e) {
      var SpecialRequirements = <div></div>
    }

    try {
      var GrossWeight = <><strong>Gross Weight:</strong> {this.props.CtrlTwrQuotingShipmentData[0].GrossWeight} {this.props.CtrlTwrQuotingShipmentData[0].WeightUnits}</>
    }
    catch (e) {
      var GrossWeight = <div></div>
    }

    try {
      var ChargeableWeight = <><strong>Chargeable Weight:</strong> {this.props.CtrlTwrQuotingShipmentData[0].ChargeableWeight} {this.props.CtrlTwrQuotingShipmentData[0].WeightUnits}</>
    }
    catch (e) {
      var ChargeableWeight = <div></div>
    }



    var emailText = <div>
      Hello,
      <br />
      May we please get a quote and transit time for the following?
      <br />
      <br />
      {pickupDt}
      <br />
      {OriginAddress}
      <br />
      {DestinationAddress}
      <br />
      {commodity}
      <br />
      {Pallets}
      <br />
      {Cases}
      <br />
      {GrossWeight}
      <br />
      {ChargeableWeight}
      <br />
      {SpecialRequirements}
      <br />
      {ModeName}
      <br />
      <br />
      Thank you,
      <br />
      Evolve Logistics Team
      <br />
    </div>


    // window.alert(toString(emailText))
    // CtrlTwrQuotingShipmentData

    // Hello,

    // May we please get a quote and transit time for the following?

    // Planned Pickup Date:
    // Origin Address:
    // Destination Address:
    // Commodity:
    // Pallets:
    // Cases:
    // Weight:
    // Special Requirements:
    // Ship Mode:

    // Thank you,

    // Evolve Logistics Team


    this.setState({
      quoteEmailModalShown: true,
      emailText: emailText
    });


  }


  handleCloseEmailModal() {
    this.setState({
      quoteEmailModalShown: false,
      emailText: ''
    });
  }



  onChangeWarehouse(e) {
    this.setState({
      ShipEntryWarehouse: e,
    });
  }

  onChangeCustomer(e) {
    this.setState({
      ShipEntryCustomer: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangePO(e) {
    this.setState({
      ShipEntryPO: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeOriginCountry(e) {
    this.setState({
      ShipEntryOriginCountry: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeDestinationCountry(e) {
    this.setState({
      ShipEntryDestinationCountry: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeETD(e) {
    this.setState({
      ShipEntryETD: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangePallets(e) {
    this.setState({
      ShipEntryPallets: e,
      UnsavedShipEntryChanges: true
    });
  }

  onChangeCases(e) {
    this.setState({
      ShipEntryCases: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeUnitCount(e) {
    this.setState({
      ShipEntryUnitCount: e,
      UnsavedShipEntryChanges: true
    });
  }
  onChangeWeightUnits(e) {
    this.setState({
      ShipEntryWeightUnits: e,
      UnsavedShipEntryChanges: true
    });
  }

  onChangeFunc(e, statename) {
    this.setState({
      [statename]: e,
      UnsavedShipEntryChanges: true
    });
  }


  onClickSaveShipDetailsEdit(e) {
    if (this.state.ShipEntryCustomer === null || this.state.ShipEntryCustomer === '') {
      window.alert('Please select a Customer.')
    }
    else if (this.state.ShipEntryOriginCountry === null || this.state.ShipEntryOriginCountry === '') {
      window.alert('Please select an Origin Country.')
    }

    else if ((this.state.GrossWeight > 0 || this.state.ChargeableWeight > 0) && (this.state.ShipEntryWeightUnits === null || this.state.ShipEntryWeightUnits === '')) {
      window.alert('Please enter weight units.')
    }

    else {
      try {
        this.props.ctrlTwrQuotingShipmentPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,
          formatNumberForAPI(this.props.CtrlTwrQuotingShipmentData[0].ProRef),

          formatNumberForAPI(this.state.ShipEntryCustomer),
          formatNumberForAPI(this.state.ShipEntryOriginCountry),

          formatAllTextNumbersSymbolsForAPI(this.state.ShipEntryPO),

          formatDateForUrlSearch(this.state.ShipEntryETD),

          formatTrueFalseToBit(this.state.ShipHotShipment),

          formatNumberForAPI(this.state.ShipEntryPallets),
          formatNumberForAPI(this.state.ShipEntryCases),
          formatNumberForAPI(this.state.ShipEntryUnitCount),
          formatStringForAPI(this.state.ShipEntryWeightUnits),
          formatNumberForAPI(this.state.ShipEntryDestinationCountry),
          formatNumberForAPI(this.state.ShipmentQuoteActive),
          formatNumberForAPI(this.state.QuotingStatusID),
          formatAllTextNumbersSymbolsForAPI(this.state.SpecialRequirements),
          formatNumberForAPI(this.state.GrossWeight),
          formatNumberForAPI(this.state.ChargeableWeight),
          formatNumberForAPI(this.state.Length),
          formatNumberForAPI(this.state.Width),
          formatNumberForAPI(this.state.Height),
          formatStringForAPI(this.state.DimUnits),
          formatAllTextNumbersSymbolsForAPI(this.state.Commodity)

        )
      }
      catch (e) { }
      this.setShipDetailsEditDrawerToClose()
    }
  }


  closeShipDetailsEditDrawer() {
    if (this.state.UnsavedShipEntryChanges) {
      if (window.confirm('Leave without saving changes?')) {
        this.setShipDetailsEditDrawerToClose()
      }
    }
    else {
      this.setShipDetailsEditDrawerToClose()
    }
  }

  setShipDetailsEditDrawerToClose() {
    this.setState({
      showQuoteShipmentDetailsEditDrawer: false,
      ShipEntryPO: '',
      ShipEntryCustomer: null,
      ShipEntryOriginCountry: '',
      ShipEntryDestinationCountry: '',
      ShipEntryETD: null,
      ShipEntryPallets: null,
      ShipEntryCases: null,
      ShipEntryUnitCount: null,
      ShipEntryWeightUnits: null,
      ShipHotShipment: null,
      UnsavedShipEntryChanges: false,
      ShipmentQuoteActive: null,
      QuotingStatusID: null,
      SpecialRequirements: null,
      GrossWeight: null,
      ChargeableWeight: null,
      Length: null,
      Width: null,
      Height: null,
      DimUnits: null,
      Commodity: null,

    });
  }


  //Ship Management Drawer
  onClickOpenShipManagmenetDrawer(e) {


    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    this.props.ctrlTwrQuotingShipmentLegsQuoteGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)

    this.setState({
      //Ship Management Drawer
      showMilestoneEntryDrawer: true,

      unsavedLegsChanges: false,
      ShipManagementProRef: paramPro,
      addNewMilestoneModalShown: false,
      AddNewLegDict: {},
    });
  }



  loadShipLegData() {
    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    this.setState({
      //Ship Management Drawer
      ShipManagementExistingDict: {},
      LegsExistingLineItems: {},
      LineItemsToDelete: [],
      unsavedLegsChanges: false,
      ShipManagementProRef: paramPro,
      addNewLegModalShown: false,
      AddNewLegDict: {},
      multiModalShown: false,
      multiModalValuesSaving: false,
      ShipManagementHbolData: {},
      ShipManagementPoData: {},
      ShipManagementCustRefData: {},
    });
  }


  preloadShipManagement() {
    var legsData = this.props.CtrlTwrQuotingShipmentLegsQuoteData

    this.setState({
      LineItemsToDelete: [],

      ShipManagementExistingDict: legsData,
      unsavedLegsChanges: false,
    });
    this.forceUpdate()

  }

  preloadLineItemsManagement() {

    var lineItemsData = this.props.CtrlTwrQuotingShipmentLineItemsData

    this.setState({
      LegsExistingLineItems: lineItemsData
    });
    this.forceUpdate()

  }


  onClickShipmentManagementDrawerSave(e) {

    try {
      // LineItemsToDelete //Line items to delete

      for (let d in this.state.LineItemsToDelete) {

        var idToDelete = this.state.LineItemsToDelete[d]

        if (typeof (idToDelete) === 'number') {

          this.props.ctrlTwrQuotingShipmentLegsLineItemPost(
            this.props.currentUserEmail,
            this.props.userSignInData.AuthToken,
            formatNumberForAPI(idToDelete),
            null,
            null,
            null,
            null,
            null,
            1,
            null,
            null,
            null,
            null,
            null,
            null

          )
        }

      }
    }
    catch (e) { }



    // try {
    // LegsExistingLineItems //Legs Line items
    for (let i in this.state.LegsExistingLineItems) {
      var quoteLineItemID = '0';
      if (this.state.LegsExistingLineItems[i].QuoteLineItemID === 0) {
        quoteLineItemID = '0'
      }
      else if (this.state.LegsExistingLineItems[i].QuoteLineItemID === null || this.state.LegsExistingLineItems[i].QuoteLineItemID === '') {
        quoteLineItemID = '0'
      }
      else {
        quoteLineItemID = formatStringForAPI(this.state.LegsExistingLineItems[i].QuoteLineItemID)
      }

      var legLineItemID = null
      if (this.state.LegsExistingLineItems[i].LegLineItemID === null || this.state.LegsExistingLineItems[i].LegLineItemID === '') {
        legLineItemID = null
      }
      else {
        legLineItemID = this.state.LegsExistingLineItems[i].LegLineItemID
      }

      this.props.ctrlTwrQuotingShipmentLegsLineItemPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        legLineItemID,
        formatNumberForAPI(this.state.LegsExistingLineItems[i].LegID),
        quoteLineItemID,
        formatNumberForAPI(this.props.CtrlTwrQuotingShipmentData[0].ProRef),
        formatNumberForAPI(this.state.LegsExistingLineItems[i].LegNumber),
        formatNumberForAPI(this.state.LegsExistingLineItems[i].ModeID),
        null,
        formatStringForAPI(this.state.LegsExistingLineItems[i].SellRate),
        formatStringForAPI(this.state.LegsExistingLineItems[i].BuyRate),
        formatStringForAPI(this.state.LegsExistingLineItems[i].QuoteLineItemUomID),
        formatStringForAPI(this.state.LegsExistingLineItems[i].QuoteLineItemRateTypeID),
        formatStringForAPI(this.state.LegsExistingLineItems[i].LineItemNotes),

        formatNumberForAPI(this.state.LegsExistingLineItems[i].LineItemSeq)


      )
    }
    // }
    // catch (e) {

    // }



    for (let l in this.state.ShipManagementExistingDict) {
      try {

        this.props.ctrlTwrQuotingShipmentLegsPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,
          formatNumberForAPI(this.state.ShipManagementExistingDict[l].LegID),
          null,
          formatNumberForAPI(this.state.ShipManagementExistingDict[l].ProRef),
          formatNumberForAPI(this.state.ShipManagementExistingDict[l].LegNumber),
          formatNumberForAPI(this.state.ShipManagementExistingDict[l].ModeID),
          formatStringForAPI(this.state.ShipManagementExistingDict[l].Carrier),

          formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Shipper_Name),
          null,
          formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Origin_AddressLine),
          formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Origin_City),
          formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_State),
          formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_PostalCode),
          formatStringForAPI(this.state.ShipManagementExistingDict[l].Origin_CountryCode),
          formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Consignee_Name),
          null,
          formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Destination_AddressLine),
          formatAllTextNumbersSymbolsForAPI(this.state.ShipManagementExistingDict[l].Destination_City),
          formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_State),
          formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_PostalCode),
          formatStringForAPI(this.state.ShipManagementExistingDict[l].Destination_CountryCode)

        )
      }
      catch (e) { }

    }






    this.setState({
      unsavedLegsChanges: false,
      addNewLegModalShown: false,
      multiModalShown: false,
      multiModalValuesSaving: false,
    });

  }

  onClickDeleteLeg(e, legID) {
    if (this.props.CtrlTwrQuotingShipmentLegsQuoteData.length === 1) {
      window.alert('Error: Cannot delete all legs.')
    }
    else if (this.state.unsavedLegsChanges) {
      window.alert('Please save changes before deleting leg.')
    }
    else if (this.state.UnsavedShipEntryChanges) {
      window.alert('Please save changes before deleting leg.')
    }
    else {
      if (window.confirm('Delete Leg?')) {
        this.props.ctrlTwrQuotingShipmentLegsPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,
          legID,
          1,
          null,
          null,
          null,
          null,

          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        )
      }
    }
  }

  onClickShowAddLeg(e) {
    if (this.state.unsavedLegsChanges) {
      window.alert('Please save changes before adding new leg.')
    }
    else if (this.state.UnsavedShipEntryChanges) {
      window.alert('Please save changes before adding new leg.')
    }
    else {
      const legNumbersArray = this.state.ShipManagementExistingDict.map(item => item.LegNumber);
      try {
        var newLegNumber = findLowestMissingNumber(legNumbersArray)
      }
      catch (e) {
        var newLegNumber = 1
      }
      this.setState({
        addNewLegModalShown: true,
        newLegModalLegNumber: newLegNumber,
        newLegModalMode: null,
      });
    }
  }

  onClickShowAddInitialLeg(e) {
    this.setState({
      addNewLegModalShown: true,
      newLegModalLegNumber: 1,
      newLegModalMode: null,
    });

  }

  onChangeLineItem = (event, index, lineItemIndex, itemLocation, label, LegLineItemID) => {
    var warehouseShipEntryLegsUpdated = this.state.LegsExistingLineItems

    if (typeof (itemLocation["newLineItemID"]) === 'number') {
      var keyToUpdate = warehouseShipEntryLegsUpdated.findIndex(item => item.newLineItemID === itemLocation["newLineItemID"])
      warehouseShipEntryLegsUpdated[keyToUpdate][label] = event
    }
    else {
      var keyToUpdate = warehouseShipEntryLegsUpdated.findIndex(item => item.LegLineItemID === itemLocation["LegLineItemID"])
      warehouseShipEntryLegsUpdated[keyToUpdate][label] = event
    }

    this.setState({
      LegsExistingLineItems: warehouseShipEntryLegsUpdated,
      unsavedLegsChanges: true
    });

    this.forceUpdate()
  };

  onClickAddLineItem(e, index, LegID, LegNumber, ProRef, ModeID) {

    try {
      var currentLegsDict = this.state.LegsExistingLineItems

      const legNumbersArray = currentLegsDict.filter(x => typeof (x.newLineItemID) !== 'undefined').map(item => item.newLineItemID);

      var newID = findLowestMissingNumber(legNumbersArray)


      try {
        const LineItemSeqArray = currentLegsDict.filter(x => x.LegID === LegID).map(item => item.LineItemSeq);
        var maxLineItemSeq = findMaxNumberInArray(LineItemSeqArray)
        var newLineItemSeq = maxLineItemSeq + 1
      }
      catch (e) {
        var newLineItemSeq = 1
      }

      currentLegsDict.push({
        "BuyRate": null,
        "CreatedDT": null,
        "LegID": LegID,
        "LegLineItemID": null,
        "LegNumber": LegNumber,
        "ProRef": ProRef,
        "QuoteLineItem": null,
        "QuoteLineItemID": 0,
        "SellRate": null,
        "UserID": null,
        "newLineItemID": newID,
        "ModeID": ModeID,

        "LineItemSeq": newLineItemSeq
      })
    }
    catch (e) {
      var currentLegsDict = [{
        "BuyRate": null,
        "CreatedDT": null,
        "LegID": LegID,
        "LegLineItemID": null,
        "LegNumber": LegNumber,
        "ProRef": ProRef,
        "QuoteLineItem": null,
        "QuoteLineItemID": 0,
        "SellRate": null,
        "UserID": null,
        "newLineItemID": 1,
        "ModeID": ModeID,

        "LineItemSeq": newLineItemSeq
      }]

    }

    this.handleAddNewLeg(currentLegsDict)

    this.forceUpdate()
  }

  handleAddNewLeg = (newLegsDict) => {
    this.setState({
      LegsExistingLineItems: newLegsDict,
      unsavedLegsChanges: true
    }, () => {
      // Do an API call with this.state.searchTerm
    });
  }


  onClickDeleteLineItem(e, index, lineItemIndex, LegLineItemID, lineItems) {

    var warehouseShipEntryLegsUpdated = this.state.LegsExistingLineItems

    if (typeof (lineItems["newLineItemID"]) === 'number') {
      var keyToUpdate = warehouseShipEntryLegsUpdated.findIndex(item => item.newLineItemID === lineItems["newLineItemID"])
    }
    else {
      var keyToUpdate = warehouseShipEntryLegsUpdated.findIndex(item => item.LegLineItemID === lineItems["LegLineItemID"])

      var lineItemID = lineItems["LegLineItemID"]

      this.setState(prevState => ({
        LineItemsToDelete: [...prevState.LineItemsToDelete, lineItemID]
      }));
    }


    warehouseShipEntryLegsUpdated.splice(keyToUpdate, 1)

    this.setState({
      LegsExistingLineItems: warehouseShipEntryLegsUpdated,
      unsavedLegsChanges: true
    });
    this.forceUpdate()






  }




  onChangeShipmentManagement(e, legID, stateName) {

    var shipManagementDictUpdated = this.state.ShipManagementExistingDict
    var keyToUpdate = shipManagementDictUpdated.findIndex(item => item.LegID === legID)

    shipManagementDictUpdated[keyToUpdate][stateName] = e

    this.setState({
      ShipManagementExistingDict: shipManagementDictUpdated,
      unsavedLegsChanges: true
    });

    this.forceUpdate()
  }

  closeShipmentManagementDrawer() {
    if (this.state.unsavedLegsChanges) {
      if (window.confirm('Leave without saving changes?')) {
        this.setShipmentManagementDrawerToClose()
      }
    }
    else {
      this.setShipmentManagementDrawerToClose()
    }
  }

  setShipmentManagementDrawerToClose() {
    this.setState({
      ReceiveDict: {},
      LocationDict: {},
      AddNewLegDict: {},
      unsavedLegsChanges: false,

      addNewLegModalShown: false,

      ReceiveDrawerContainerID: null,
      ReceiveDrawerContainer: null,
      ReceiveDrawerNo: null,
      ReceiveDrawerPMC: null,
      ReceiveDrawerPickupBy: null,
      ReceiveDrawerTrips: null,
      ReceiveDrawerArrivalTime: null,
      ReceiveDrawerLFD: null,
      ReceiveDrawerArrivalAtWarehouse: null,
      ReceiveDrawerDeliveryTime: null,
      ReceiveDrawerNotes: null,
    });
  }


  onClickMulti(e, legID, stateName, hbolID) {
    if (this.state.unsavedLegsChanges) {
      window.alert('Please save changes first.')
    }
    else {
      this.setState({
        multiModalShown: true,
        multiModalValuesSaving: false,
        multiModalLegNumber: legID,
        multiModalStateName: stateName,
        multiModalHbolID: hbolID,
        multiModalNewValue: ''
      });
    }

  }

  onClickDeleteMulti(e, stateName, hbolID, poID, custRefID) {

    if (stateName === 'HBOL') {
      if (window.confirm('Delete HBOL?')) {
        this.props.ctrlTwrShipmentLegsHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, hbolID, null, null, 1)
      }
    }
    else if (stateName === 'PO') {
      if (window.confirm('Delete PO?')) {
        this.props.ctrlTwrShipmentLegsPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, poID, null, 1)
      }
    }
    else if (stateName === 'CustomerRef') {
      if (window.confirm('Delete Customer Ref?')) {
        this.props.ctrlTwrShipmentLegsCustomerRefPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, custRefID, null, null, null, 1)
      }
    }
  }


  //Quote Action Modal
  onClickOpenQuoteActionModal() {
    if (this.props.CtrlTwrQuotingShipmentData[0].ShipmentQuoteActive != 1) {
      window.alert('Cannot modify previously accepted quotes.')
    }
    else {
      this.setState({
        quoteActionModalShown: true
      });
    }
  }

  onClickApproveRejectQuote(e, approved) {
    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    if (approved) {
      this.props.ctrlTwrQuotingShipmentStatusActionGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, 0, 2)
    }
    else {
      this.props.ctrlTwrQuotingShipmentStatusActionGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, 1, 3)
    }

    this.handleCloseActionModal()
  }

  handleCloseActionModal() {
    this.setState({
      quoteActionModalShown: false
    });
  }




  //Multi Modal

  onChangeMultiModal(e) {
    this.setState({
      multiModalNewValue: e
    });

  }

  handleCloseMultiModal() {
    this.setState({
      multiModalShown: false,
      multiModalValuesSaving: false,
      multiModalLegNumber: null,
      multiModalStateName: null,
      multiModalHbolID: null,
      multiModalNewValue: ''
    });
  }

  setMultiModalToSaving(trueOrFalse) {
    this.setState({
      multiModalValuesSaving: trueOrFalse,
    });
  }


  handleSaveMultiModal() {
    this.setMultiModalToSaving(true)

    // try

    var multiArray = this.state.multiModalNewValue.split(',').map(item => item.trim())

    const uniqueMultiArray = [...new Set(multiArray)];

    if (this.state.multiModalStateName === 'HBOL') {
      for (let x in uniqueMultiArray) {
        this.props.ctrlTwrShipmentLegsHbolPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.multiModalHbolID, this.state.multiModalLegNumber, formatStringForAPI(uniqueMultiArray[x]), null)
      }
    }
    else if (this.state.multiModalStateName === 'PO') {
      for (let x in uniqueMultiArray) {
        this.props.ctrlTwrShipmentLegsPoPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.multiModalHbolID, this.state.multiModalLegNumber, null, formatStringForAPI(uniqueMultiArray[x]), null)
      }
    }
    else if (this.state.multiModalStateName === 'CustomerRef') {
      for (let x in uniqueMultiArray) {
        this.props.ctrlTwrShipmentLegsCustomerRefPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.state.multiModalLegNumber, this.state.multiModalHbolID, formatStringForAPI(uniqueMultiArray[x]), null)
      }
    }

    this.setMultiModalToSaving(false)

    this.handleCloseMultiModal()

  }


  //Add new Leg Modal
  onChangeAddNewLegModal(e, label) {
    if (label === 'Mode') {
      this.setState({
        newLegModalMode: e
      });
    }
    else if (label === 'Leg') {
      if (this.state.ShipManagementExistingDict.filter(item => item.LegNumber === Number(e)).length > 0) {
        window.alert('Duplicate leg entry, please enter new leg number.')
      }
      else if (e === 0) {
        window.alert('Please select number greater than 0.')
      }
      else {
        this.setState({
          newLegModalLegNumber: String(e)
        });
      }
    }

    this.forceUpdate()
  }


  handleCloseAddNewLegModal() {
    this.setState({
      addNewLegModalShown: false,
      newLegModalLegNumber: null,
      newLegModalMode: null,
    });
  }

  handleSaveAddNewLegModal() {

    const values = queryString.parse(this.props.location.search)

    try {
      var paramPro = formatStringForAPI(values.pro)
    }
    catch (e) {
      var paramPro = null
    }

    // if (this.state.ShipManagementProRef === null || this.state.ShipManagementProRef === '') {
    //   window.alert('Error, please refresh and try again.')
    // }
    if (this.state.newLegModalLegNumber === null || this.state.newLegModalLegNumber === '') {
      window.alert('Please enter leg number.')
    }
    else if (this.state.newLegModalMode === null || this.state.newLegModalMode === '') {
      window.alert('Please select mode.')
    }
    else {

      this.props.ctrlTwrQuotingShipmentLegsPost(
        this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramPro, formatNumberForAPI(this.state.newLegModalLegNumber), formatNumberForAPI(this.state.newLegModalMode), null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
      )

      this.handleCloseAddNewLegModal()
      this.props.ctrlTwrQuotingShipmentLegsQuoteGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
      this.setState({
        //Ship Management Drawer
        ShipManagementExistingDict: {},
        LegsExistingLineItems: {},
        LineItemsToDelete: [],
        unsavedLegsChanges: false,
        addNewLegModalShown: false,
        AddNewLegDict: {},
        ShipManagementHbolData: {},
        ShipManagementPoData: {},
        ShipManagementCustRefData: {},
      });
    }
  }




  //Table on click details
  onClickShipDetails(e, rowData, history) {
    try {
      var paramID = rowData[tableIdCol]
      history.push({
        pathname: 'shipmentdetailsmanagement',
        search: 'pro=\'' + paramID + '\''
      });
    }
    catch (e) {
    }
  }



  //File Upload
  onClickDownloadFileUpload(e, rowData, history) {
    try {
      this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.API.data.FileDownloadCtrlTwrS3)
    }
    catch (e) {
      window.alert("Error Downloading File.")
    }
  }
  onClickDeleteFileUpload(e, rowData, history) {
    if (window.confirm('Do you wish to delete this file?'))
      this.props.ctrlTwrQuotingShipmentFileUploadPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        this.props.customerSelection,
        formatNumberForAPI(rowData.FileUploadID),
        null,
        null,
        null,
        null,
        1,
        null
      )
  }


  onChangeCheckBoxOrgNote(e, value) {
    this.setState({
      NoteIsPrivate: value
    });
  }
  onChangeNote(e) {
    this.setState({
      ShipmentNote: e
    });
  }

  handleSaveAddNewNote(e) {
    try {
      if (typeof (this.props.organizationSelection) === 'number' && this.state.NoteIsPrivate) {
        if (this.state.ShipmentNote !== '') {
          this.props.ctrlTwrShipmentPrivateNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrQuotingShipmentData[0].ProRef), formatAllTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
        }
      }
      else {
        //not assigned to organization can only write public notes:
        if (this.state.ShipmentNote !== '') {
          this.props.ctrlTwrShipmentPublicNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatNumberForAPI(this.props.CtrlTwrQuotingShipmentData[0].ProRef), formatAllTextNumbersSymbolsForAPI(this.state.ShipmentNote), null)
        }
      }
    }
    catch (e) {
    }

  }


  onClickDeletePrivateNote(e, noteid) {
    if (window.confirm('Confirm Delete Note?')) {
      this.props.ctrlTwrShipmentPrivateNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(noteid), null, null, 1)
    }
  }

  onClickDeletePublicNote(e, noteid) {
    if (window.confirm('Confirm Delete Note?')) {
      this.props.ctrlTwrShipmentPublicNotesPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(noteid), null, null, 1)
    }
  }


  renderLineItems(legLineItemData, legItem, index, ctrlTwrQuotingLineItemData) {

    try {
      if (typeof (legLineItemData[0]) !== 'undefined' && legLineItemData != null) {
        return <div>{legLineItemData.filter(x => x.LegID === legItem.LegID).map((lineItems, lineItemIndex) => (

          <div>
            <Panel bordered style={{ marginBottom: 10 }} >
              <Row >

                <Col md={2} sm={2}>
                  <ButtonToolbar >
                    <Button block onClick={(e) => { this.onClickDeleteLineItem(e, index, lineItemIndex, lineItems['LegLineItemID'], lineItems) }} appearance="secondary">Delete</Button>
                  </ButtonToolbar>
                </Col>

                <Col md={10} sm={10}>
                  <div style={{ marginBottom: '10px', width: '100%' }}>
                    <InputPicker preventOverflow
                      placeholder={'Line Item...'}
                      value={lineItems['QuoteLineItemID']}
                      creatable
                      onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'QuoteLineItemID', lineItems['LegLineItemID']) }}

                      data={ctrlTwrQuotingLineItemData.filter(x => x.ModeID === legItem['ModeID'])}

                      cleanable={true}
                      block
                    />
                  </div>
                </Col>

                <Col md={6} sm={6}>
                  <InputGroup style={styles}>
                    <InputGroup.Addon>Buy Rate ($):</InputGroup.Addon>
                    <Input style={{ width: '100%' }} value={lineItems['BuyRate']} onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'BuyRate', lineItems['LegLineItemID']) }} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} />
                  </InputGroup>
                </Col>

                <Col md={6} sm={6}>
                  <InputGroup style={styles}>
                    <InputGroup.Addon>Sell Rate ($):</InputGroup.Addon>
                    <Input style={{ width: '100%' }} value={lineItems['SellRate']} onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'SellRate', lineItems['LegLineItemID']) }} className={'custom-input-number'} onInput={(e) => e.target.value = hundredthsDecimalInputMask(e)} />
                  </InputGroup>
                </Col>



              </Row>


              <Row>

                <Col md={6} sm={6}>
                  <div style={{ marginBottom: '10px', width: '100%' }}>
                    <InputPicker preventOverflow
                      placeholder={'Unit of Measure...'}
                      value={lineItems['QuoteLineItemUomID']}
                      creatable
                      onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'QuoteLineItemUomID', lineItems['LineItemUOM']) }}

                      onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)}

                      data={this.props.CtrlTwrQuotingLineItemUomData}

                      cleanable={true}
                      block
                    />
                  </div>
                </Col>
                <Col md={6} sm={6}>
                  <div style={{ marginBottom: '10px', width: '100%' }}>
                    <InputPicker preventOverflow
                      placeholder={'Rate Type...'}
                      value={lineItems['QuoteLineItemRateTypeID']}
                      creatable
                      onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'QuoteLineItemRateTypeID', lineItems['LineItemRateType']) }}

                      data={this.props.CtrlTwrQuotingLineItemRateTypeData}

                      cleanable={true}
                      block
                    />
                  </div>
                </Col>

                <Col md={12} sm={24}>
                  <InputGroup style={styles}>
                    <InputGroup.Addon>Notes:</InputGroup.Addon>
                    <Input style={{ width: '100%' }} value={lineItems['LineItemNotes']} onChange={(e) => { this.onChangeLineItem(e, index, lineItemIndex, lineItems, 'LineItemNotes', lineItems['LineItemNotes']) }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                  </InputGroup>
                </Col>




              </Row>

            </Panel>

          </div>

        ))}
        </div>
      }

      else {
        return <div></div>
      }

    }
    catch (e) {
      return <div></div>
    }

  }




  render() {
    const { sortColumn, sortType } = this.state;
    let pageHeader;
    let fileUploadDiv;
    let detailsDiv;
    let editDrawer;
    if (this.props.isCtrlTwrQuotingShipmentLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
      editDrawer = <div></div>
    }
    else if (this.props.isCtrlTwrQuotingShipmentFileUploadLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrQuotingShipmentData[0]) !== 'undefined' && this.props.CtrlTwrQuotingShipmentData != null) {

      editDrawer = <QuoteShipmentDetailsEditDrawer {...this.props} state={this.state} onChangeMilestoneEventEntry={this.onChangeMilestoneEventEntry} onChangeWarehouse={this.onChangeWarehouse} onChangeCustomer={this.onChangeCustomer} onChangePO={this.onChangePO} onChangeOriginCountry={this.onChangeOriginCountry} onChangeDestinationCountry={this.onChangeDestinationCountry} onChangeETD={this.onChangeETD} onChangePallets={this.onChangePallets} onChangeCases={this.onChangeCases} onChangeFunc={this.onChangeFunc} onChangeUnitCount={this.onChangeUnitCount} onChangeWeightUnits={this.onChangeWeightUnits} onClickSaveShipDetailsEdit={this.onClickSaveShipDetailsEdit} close={this.closeShipDetailsEditDrawer} />

      pageHeader = <div style={{ float: 'left' }}>  {'Control Tower - Quote: ' + this.props.CtrlTwrQuotingShipmentData[0].ProRef} </div>

      fileUploadDiv = RUploaderQuoting(
        this.props.isMobile,                          // isMobile,
        this.props.history,                           // history,
        this.props.currentUserEmail,                  //currentUserEmail,
        this.props.userSignInData,                    //userSignInData
        this.props.customerSelection,                 //customerSelection
        this.props.CtrlTwrQuotingShipmentFileUploadData,      //fileList,
        this.props.ctrlTwrQuotingShipmentFileUploadPost,     //onUploadAction,
        null,                                         // loading,
        this.props.CtrlTwrQuotingShipmentData[0].ProRef,     // proRef,
        appAPI.API.data.FileDownloadCtrlTwrS3         //s3Bucket
      )

      detailsDiv =
        <div>
          <Row>
            <ButtonToolbar >
              {(this.props.CtrlTwrQuotingShipmentData[0].ShipmentQuoteActive != 1) ? '' : <Button style={{ float: 'right', marginBottom: 10 }} onClick={(e) => { this.onClickOpenQuoteActionModal(e) }} >Quote Action</Button>}
              <Button style={{ float: 'right', marginBottom: 10, marginRight: 10 }} onClick={(e) => { this.onClickEditShipmentDetails(e) }} >Edit</Button>
            </ButtonToolbar>
          </Row>



          <Row>
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}>
                Status: <strong>{this.props.CtrlTwrQuotingShipmentData[0].QuotingStatusName}</strong>
                <br />
                PO: <strong>{this.props.CtrlTwrQuotingShipmentData[0].PO}</strong>
                <br />
                Planned Pickup: <strong>{this.props.CtrlTwrQuotingShipmentData[0].ETD}</strong>
                <br />
                Customer: <strong>{this.props.CtrlTwrQuotingShipmentData[0].CustomerName}</strong>
                <br />
                Origin Country: <strong>{this.props.CtrlTwrQuotingShipmentData[0].CountryName}</strong>
                <br />
                Destination Country: <strong>{this.props.CtrlTwrQuotingShipmentData[0].DestCountryName}</strong>
                <br />
                Pallets: <strong>{this.props.CtrlTwrQuotingShipmentData[0].Pallets}</strong>
                <br />
                Cases: <strong>{this.props.CtrlTwrQuotingShipmentData[0].Cases}</strong>
                <br />
                Units: <strong>{this.props.CtrlTwrQuotingShipmentData[0].UnitCount}</strong>
                <br />
              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}>
                Gross Weight: <strong>{this.props.CtrlTwrQuotingShipmentData[0].GrossWeight} {this.props.CtrlTwrQuotingShipmentData[0].WeightUnits}</strong>
                <br />
                Chargeable Weight: <strong>{this.props.CtrlTwrQuotingShipmentData[0].ChargeableWeight} {this.props.CtrlTwrQuotingShipmentData[0].WeightUnits}</strong>
                <br />
                Length: <strong>{this.props.CtrlTwrQuotingShipmentData[0].Length} {this.props.CtrlTwrQuotingShipmentData[0].DimUnits}</strong>
                <br />
                Width: <strong>{this.props.CtrlTwrQuotingShipmentData[0].Width} {this.props.CtrlTwrQuotingShipmentData[0].DimUnits}</strong>
                <br />
                Height: <strong>{this.props.CtrlTwrQuotingShipmentData[0].Height} {this.props.CtrlTwrQuotingShipmentData[0].DimUnits}</strong>
                <br />
                Commodity: <strong>{this.props.CtrlTwrQuotingShipmentData[0].Commodity}</strong>
                <br />
                Special Requirements: <strong>{this.props.CtrlTwrQuotingShipmentData[0].SpecialRequirements}</strong>
                <br />
                <br />
                <br />
              </Panel>
            </Col>

          </Row>









        </div>
    }
    else {
      detailsDiv = <div></div>;
      editDrawer = <div></div>
    }




    let fileUploadsTable;
    if (this.props.isCtrlTwrQuotingShipmentFileUploadLoading) {

      fileUploadsTable =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrQuotingShipmentFileUploadData) !== 'undefined' && this.props.CtrlTwrQuotingShipmentFileUploadData != null && this.props.CtrlTwrQuotingShipmentFileUploadData.length > 0) {
      var milestoneTableComponent = RTableGeneral(
        'FileUploadID',     //   keyColumn,
        this.props.CtrlTwrQuotingShipmentFileUploadData,     //   tableData,
        CtrlTwrShipmentFileUploadsDataElements,       //   DataElements,
        this.props.isCtrlTwrQuotingShipmentFileUploadLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        true,      //   onClickEditMilestone,
        this.onClickDownloadFileUpload,       //   onClickSelect,
        'Download',       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        true,       //   link1bool,
        'Delete',     //   link1text,
        this.onClickDeleteFileUpload,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      fileUploadsTable = <Panel
        bordered style={{ marginBottom: 10 }}
        header='File Uploads'
      >
        {milestoneTableComponent}
      </Panel>


    }
    else {
      fileUploadsTable = <div></div>
    }


    let legLineItemData = [
      {
        "BuyRate": null,
        "CreatedDT": null,
        "LegID": null,
        "LegLineItemID": null,
        "LegNumber": null,
        "ProRef": null,
        "QuoteLineItem": null,
        "QuoteLineItemID": null,
        "SellRate": null,
        "UserID": null,
        "ModeID": null,

        "QuoteLineItemUomID": null,
        "QuoteLineItemRateTypeID": null,
        "LineItemNotes": null,

        "LineItemSeq": null


      }
    ]
    if (this.props.isCtrlTwrQuotingShipmentLineItemsLoading) {
      legLineItemData = [
        {
          "BuyRate": null,
          "CreatedDT": null,
          "LegID": null,
          "LegLineItemID": null,
          "LegNumber": null,
          "ProRef": null,
          "QuoteLineItem": null,
          "QuoteLineItemID": null,
          "SellRate": null,
          "UserID": null,
          "ModeID": null,
          "QuoteLineItemUomID": null,
          "QuoteLineItemRateTypeID": null,
          "LineItemNotes": null,

          "LineItemSeq": null
        }
      ]
    }
    else if (typeof ([0]) !== 'undefined' && this.props.CtrlTwrQuotingShipmentLineItemsData != null && typeof (this.state.LegsExistingLineItems[0]) !== 'undefined' && this.state.LegsExistingLineItems != null) {
      legLineItemData = this.state.LegsExistingLineItems
    }

    let ctrlTwrQuotingLineItemData;
    if (this.props.isCtrlTwrQuotingLineItemLoading) {
      ctrlTwrQuotingLineItemData = {}
    }
    else if (typeof (this.props.CtrlTwrQuotingLineItemData[0]) !== 'undefined' && this.props.CtrlTwrQuotingLineItemData != null) {
      ctrlTwrQuotingLineItemData = this.props.CtrlTwrQuotingLineItemData
    }


    let legsDataDiv;
    if (this.props.isCtrlTwrQuotingShipmentLegsQuoteLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isCtrlTwrQuotingShipmentLineItemsLoading) {
      legsDataDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrQuotingShipmentLegsQuoteData[0]) !== 'undefined' && this.props.CtrlTwrQuotingShipmentLegsQuoteData != null && typeof (this.state.ShipManagementExistingDict[0]) !== 'undefined' && this.state.ShipManagementExistingDict != null) {

      legsDataDiv =
        <div>
          <Panel bordered style={{ marginBottom: 10 }}>
            {
              this.state.ShipManagementExistingDict.map((legItem, index) => (

                <Panel
                  bordered
                  style={{ marginBottom: '10px' }}
                  header={
                    <Row>
                      <Col md={16} sm={24}>
                        <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                      </Col>
                      <Col md={8} sm={24}>
                        <ButtonToolbar >
                          <Button style={{ float: 'right' }} onClick={(e) => { this.onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                          <Button style={{ float: 'right', marginBottom: 10, marginRight: 10 }} onClick={(e) => { this.onClickEmailText(e, legItem) }} >Email Text</Button>
                        </ButtonToolbar>
                      </Col>
                    </Row>
                  }
                >

                  <FormGroup >
                    <Panel bordered style={{ marginBottom: 10 }} >

                      <Row style={{ marginBottom: 10 }}>
                        <FormGroup >
                          <Col md={24} sm={24}>
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Carrier:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Carrier']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>
                          </Col>
                        </FormGroup>
                      </Row>


                      {this.renderLineItems(legLineItemData, legItem, index, ctrlTwrQuotingLineItemData)}


                      <Row>
                        <Col md={24} sm={24}>
                          <ButtonToolbar >
                            <Button onClick={(e) => { this.onClickAddLineItem(e, index, legItem['LegID'], legItem['LegNumber'], legItem['ProRef'], legItem['ModeID']) }} appearance="primary">Add Line Item</Button>
                          </ButtonToolbar>
                        </Col>
                      </Row>

                    </Panel>


                    <Row>
                      <Col md={12} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                          <FormGroup>
                            <InputGroup style={styles}>
                              <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin City:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin State:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                            </InputGroup>

                          </FormGroup>
                        </Panel>
                      </Col>
                      <Col md={12} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} >
                          <FormGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination City:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination State:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                            </InputGroup>

                            <InputGroup style={styles}>
                              <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                              <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { this.onChangeShipmentManagement(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                            </InputGroup>

                          </FormGroup>
                        </Panel>
                      </Col>
                    </Row>





                  </FormGroup>









                </Panel>

              ))
            }




            < FormGroup >
              <ButtonToolbar>
                {
                  this.state.unsavedLegsChanges ?
                    <Button onClick={this.onClickShipmentManagementDrawerSave} appearance="primary">Save Changes</Button>
                    :
                    <div></div>
                }
                <Button style={{ float: 'right', marginBottom: '10px' }} onClick={(e) => { this.onClickShowAddLeg(e) }} >Add New Leg</Button>
              </ButtonToolbar>
            </FormGroup>




          </Panel>


        </div >
    }

    else {
      legsDataDiv = <div>
        <Panel bordered style={{ marginBottom: 10 }}>
          <Button block style={{ marginBottom: '10px' }} onClick={(e) => { this.onClickShowAddInitialLeg(e) }} >Add Leg</Button>
        </Panel>
      </div>;
    }





    let noteEntry;
    if (this.props.isCtrlTwrQuotingShipmentNotesPrivateLoading || this.props.isCtrlTwrQuotingShipmentNotesPublicLoading) {
      noteEntry =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else {
      noteEntry = <div>
        <Row >
          <Col md={24} sm={24}>
            <Panel bordered style={{ marginBottom: 10 }} >
              <FormGroup >

                {typeof (this.props.organizationSelection) === 'number' ?

                  <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <InputGroup.Addon>Add New Note:</InputGroup.Addon>
                    <Checkbox checked={this.state.NoteIsPrivate} onChange={(e, value) => this.onChangeCheckBoxOrgNote(e, value)}>{this.state.NoteIsPrivate ? 'Private:  ' : 'Public:  '} </Checkbox>
                    <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                    <Button onClick={(e) => { this.handleSaveAddNewNote(e) }}>Save</Button>
                  </InputGroup>

                  :

                  <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                    <InputGroup.Addon>Add New Note:</InputGroup.Addon>
                    <Input value={this.state.ShipmentNote} onChange={(e) => { this.onChangeNote(e) }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2000)} />
                    <Button onClick={(e) => { this.handleSaveAddNewNote(e) }}>Save</Button>
                  </InputGroup>

                }

              </FormGroup>
            </Panel>
          </Col>
        </Row>
      </div>
    }

    let publicNotes;
    if (this.props.isCtrlTwrQuotingShipmentNotesPublicLoading) {
      publicNotes =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrQuotingShipmentNotesPublicData[0]) !== 'undefined' && this.props.CtrlTwrQuotingShipmentNotesPublicData != null) {
      publicNotes =
        <div>
          <Row >
            <Col md={24} sm={24}>
              <Panel bordered header="Public Notes" style={{ marginBottom: 10 }}>
                <Timeline>
                  {this.props.CtrlTwrQuotingShipmentNotesPublicData.map(item => (
                    <Timeline.Item>
                      <strong>{item.NoteDT}  &nbsp;
                        {item.EmailAddr} : &nbsp; </strong>
                      <l>{item.PublicNote} &nbsp;
                      </l>

                      <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                        <IconButton icon={<CloseIcon />} onClick={(e) => { this.onClickDeletePublicNote(e, item.ShipmentNoteID) }} size="xs" style={{ float: 'right' }} />
                      </Whisper>

                    </Timeline.Item>
                  ))}
                </Timeline>

              </Panel>
            </Col>
          </Row>

        </div>
    }
    else {
      publicNotes = <div></div>
    }


    let privateNotes;
    if (this.props.isCtrlTwrQuotingShipmentNotesPrivateLoading) {
      privateNotes =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.CtrlTwrQuotinghipmentNotesPrivateData[0]) !== 'undefined' && this.props.CtrlTwrQuotinghipmentNotesPrivateData != null) {
      privateNotes =
        <div>
          <Row >
            <Col md={24} sm={24}>
              <Panel bordered header="Private Notes" style={{ marginBottom: 10 }}>
                <Timeline>
                  {this.props.CtrlTwrQuotinghipmentNotesPrivateData.map(item => (
                    <Timeline.Item>
                      <strong>{item.NoteDT}  &nbsp;
                        {item.EmailAddr} : &nbsp; </strong>
                      <l>{item.PrivateNote} &nbsp;
                      </l>

                      <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                        <IconButton icon={<CloseIcon />} onClick={(e) => { this.onClickDeletePrivateNote(e, item.ShipmentNoteID) }} size="xs" style={{ float: 'right' }} />
                      </Whisper>

                    </Timeline.Item>
                  ))}
                </Timeline>

              </Panel>
            </Col>
          </Row>

        </div>
    }
    else {
      privateNotes = <div></div>
    }



    return (
      <div>
        <h3>
          <Row>{pageHeader} </Row>
        </h3>


        <PanelGroup>
          {detailsDiv}
        </PanelGroup>


        {noteEntry}
        {publicNotes}
        {privateNotes}


        {legsDataDiv}



        <ShipmentManagementAddNewLegModal {...this.props} state={this.state} addNewLegModalShown={this.state.addNewLegModalShown} onChangeAddNewLegModal={this.onChangeAddNewLegModal} handleOpen={this.handleOpenAddNewLegModal} handleClose={this.handleCloseAddNewLegModal} handleSave={this.handleSaveAddNewLegModal} />

        <ShipmentManagementMultiModal {...this.props} state={this.state} multiModalShown={this.state.multiModalShown} onChangeMultiModal={this.onChangeMultiModal} handleClose={this.handleCloseMultiModal} handleSave={this.handleSaveMultiModal} />

        <QuoteShipmentStatusModal {...this.props} state={this.state} quoteActionModalShown={this.state.quoteActionModalShown} onClickApproveRejectQuote={this.onClickApproveRejectQuote} handleCloseActionModal={this.handleCloseActionModal} />

        <QuoteShipmentEmailModal {...this.props} state={this.state} quoteEmailModalShown={this.state.quoteEmailModalShown} handleCloseEmailModal={this.handleCloseEmailModal} />

        {editDrawer}
        {fileUploadsTable}
        {fileUploadDiv}



      </div>
    );

  }
}

export default withRouter(QuoteShipmentDetails);



