/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';




/* ################################### INVENTORY TYPES ################################### */
export const ON_ERROR = 'ON_ERROR';

export const INVENTORY_DESTINATION_GET = 'INVENTORY_DESTINATION_GET';
export const INVENTORY_DESTINATION_COMPLETE = 'INVENTORY_DESTINATION_COMPLETE';
export const INVENTORY_DESTINATION_ERROR = 'INVENTORY_DESTINATION_ERROR';

export const INVENTORY_CUSTOMERREF_GET = 'INVENTORY_CUSTOMERREF_GET';
export const INVENTORY_CUSTOMERREF_COMPLETE = 'INVENTORY_CUSTOMERREF_COMPLETE';
export const INVENTORY_CUSTOMERREF_ERROR = 'INVENTORY_CUSTOMERREF_ERROR';

export const INVENTORY_PROJECT_GET = 'INVENTORY_PROJECT_GET';
export const INVENTORY_PROJECT_COMPLETE = 'INVENTORY_PROJECT_COMPLETE';
export const INVENTORY_PROJECT_ERROR = 'INVENTORY_PROJECT_ERROR';

export const INVENTORY_LOT_SKU_GET = 'INVENTORY_LOT_SKU_GET';
export const INVENTORY_LOT_SKU_COMPLETE = 'INVENTORY_LOT_SKU_COMPLETE';
export const INVENTORY_LOT_SKU_ERROR = 'INVENTORY_LOT_SKU_ERROR';

export const INVENTORY_SKU_GET = 'INVENTORY_SKU_GET';
export const INVENTORY_SKU_COMPLETE = 'INVENTORY_SKU_COMPLETE';
export const INVENTORY_SKU_ERROR = 'INVENTORY_SKU_ERROR';

export const INVENTORY_POST_SKU_GET = 'INVENTORY_POST_SKU_GET';
export const INVENTORY_POST_SKU_COMPLETE = 'INVENTORY_POST_SKU_COMPLETE';
export const INVENTORY_POST_SKU_ERROR = 'INVENTORY_POST_SKU_ERROR';


export const INVENTORY_LOT_CONTAINER_GET = 'INVENTORY_LOT_CONTAINER_GET';
export const INVENTORY_LOT_CONTAINER_COMPLETE = 'INVENTORY_LOT_CONTAINER_COMPLETE';
export const INVENTORY_LOT_CONTAINER_ERROR = 'INVENTORY_LOT_CONTAINER_ERROR';

export const INVENTORY_CONTAINER_GET = 'INVENTORY_CONTAINER_GET';
export const INVENTORY_CONTAINER_COMPLETE = 'INVENTORY_CONTAINER_COMPLETE';
export const INVENTORY_CONTAINER_ERROR = 'INVENTORY_CONTAINER_ERROR';

export const INVENTORY_POST_CONTAINER_GET = 'INVENTORY_POST_CONTAINER_GET';
export const INVENTORY_POST_CONTAINER_COMPLETE = 'INVENTORY_POST_CONTAINER_COMPLETE';
export const INVENTORY_POST_CONTAINER_ERROR = 'INVENTORY_POST_CONTAINER_ERROR';

export const INVENTORY_POST_CONTAINER_GET2 = 'INVENTORY_POST_CONTAINER_GET2';
export const INVENTORY_POST_CONTAINER_COMPLETE2 = 'INVENTORY_POST_CONTAINER_COMPLETE2';
export const INVENTORY_POST_CONTAINER_ERROR2 = 'INVENTORY_POST_CONTAINER_ERROR2';

export const INVENTORY_POST_CONTAINER_STATUS_GET = 'INVENTORY_POST_CONTAINER_STATUS_GET';
export const INVENTORY_POST_CONTAINER_STATUS_COMPLETE = 'INVENTORY_POST_CONTAINER_STATUS_COMPLETE';
export const INVENTORY_POST_CONTAINER_STATUS_ERROR = 'INVENTORY_POST_CONTAINER_STATUS_ERROR';

export const INVENTORY_CONTAINER_HISTORY_GET = 'INVENTORY_CONTAINER_HISTORY_GET';
export const INVENTORY_CONTAINER_HISTORY_COMPLETE = 'INVENTORY_CONTAINER_HISTORY_COMPLETE';
export const INVENTORY_CONTAINER_HISTORY_ERROR = 'INVENTORY_CONTAINER_HISTORY_ERROR';

export const INVENTORY_CONTAINER_SKU_GET = 'INVENTORY_CONTAINER_SKU_GET';
export const INVENTORY_CONTAINER_SKU_COMPLETE = 'INVENTORY_CONTAINER_SKU_COMPLETE';
export const INVENTORY_CONTAINER_SKU_ERROR = 'INVENTORY_CONTAINER_SKU_ERROR';

export const INVENTORY_POST_CONTAINER_SKU_GET = 'INVENTORY_POST_CONTAINER_SKU_GET';
export const INVENTORY_POST_CONTAINER_SKU_COMPLETE = 'INVENTORY_POST_CONTAINER_SKU_COMPLETE';
export const INVENTORY_POST_CONTAINER_SKU_ERROR = 'INVENTORY_POST_CONTAINER_SKU_ERROR';

export const INVENTORY_SKU_RECOVERY_GET = 'INVENTORY_SKU_RECOVERY_GET';
export const INVENTORY_SKU_RECOVERY_COMPLETE = 'INVENTORY_SKU_RECOVERY_COMPLETE';
export const INVENTORY_SKU_RECOVERY_ERROR = 'INVENTORY_SKU_RECOVERY_ERROR';

export const INVENTORY_SKU_RECOVERY_HISTORY_GET = 'INVENTORY_SKU_RECOVERY_HISTORY_GET';
export const INVENTORY_SKU_RECOVERY_HISTORY_COMPLETE = 'INVENTORY_SKU_RECOVERY_HISTORY_COMPLETE';
export const INVENTORY_SKU_RECOVERY_HISTORY_ERROR = 'INVENTORY_SKU_RECOVERY_HISTORY_ERROR';

export const INVENTORY_POST_SKU_RECOVERY_GET = 'INVENTORY_POST_SKU_RECOVERY_GET';
export const INVENTORY_POST_SKU_RECOVERY_COMPLETE = 'INVENTORY_POST_SKU_RECOVERY_COMPLETE';
export const INVENTORY_POST_SKU_RECOVERY_ERROR = 'INVENTORY_POST_SKU_RECOVERY_ERROR';

export const INVENTORY_SKU_WAREHOUSE_LOC_GET = 'INVENTORY_SKU_WAREHOUSE_LOC_GET';
export const INVENTORY_SKU_WAREHOUSE_LOC_COMPLETE = 'INVENTORY_SKU_WAREHOUSE_LOC_COMPLETE';
export const INVENTORY_SKU_WAREHOUSE_LOC_ERROR = 'INVENTORY_SKU_WAREHOUSE_LOC_ERROR';

export const INVENTORY_POST_SKU_WAREHOUSE_LOCATION_GET = 'INVENTORY_POST_SKU_WAREHOUSE_LOCATION_GET';
export const INVENTORY_POST_SKU_WAREHOUSE_LOCATION_COMPLETE = 'INVENTORY_POST_SKU_WAREHOUSE_LOCATION_COMPLETE';
export const INVENTORY_POST_SKU_WAREHOUSE_LOCATION_ERROR = 'INVENTORY_POST_SKU_WAREHOUSE_LOCATION_ERROR';

export const INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_GET = 'INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_GET';
export const INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_COMPLETE = 'INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_COMPLETE';
export const INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_ERROR = 'INVENTORY_SKU_WAREHOUSE_LOCATION_HISTORY_ERROR';

export const INVENTORY_WAREHOUSE_LOCATION_GET = 'INVENTORY_WAREHOUSE_LOCATION_GET';
export const INVENTORY_WAREHOUSE_LOCATION_COMPLETE = 'INVENTORY_WAREHOUSE_LOCATION_COMPLETE';
export const INVENTORY_WAREHOUSE_LOCATION_ERROR = 'INVENTORY_WAREHOUSE_LOCATION_ERROR';

export const INVENTORY_LOAD_GET = 'INVENTORY_LOAD_GET';
export const INVENTORY_LOAD_COMPLETE = 'INVENTORY_LOAD_COMPLETE';
export const INVENTORY_LOAD_ERROR = 'INVENTORY_LOAD_ERROR';

export const INVENTORY_POST_LOAD_GET = 'INVENTORY_POST_LOAD_GET';
export const INVENTORY_POST_LOAD_COMPLETE = 'INVENTORY_POST_LOAD_COMPLETE';
export const INVENTORY_POST_LOAD_ERROR = 'INVENTORY_POST_LOAD_ERROR';

export const INVENTORY_POST_LOAD_REMOVE_SKU_GET = 'INVENTORY_POST_LOAD_REMOVE_SKU_GET';
export const INVENTORY_POST_LOAD_REMOVE_SKU_COMPLETE = 'INVENTORY_POST_LOAD_REMOVE_SKU_COMPLETE';
export const INVENTORY_POST_LOAD_REMOVE_SKU_ERROR = 'INVENTORY_POST_LOAD_REMOVE_SKU_ERROR';

export const INVENTORY_LOAD_SKU_GET = 'INVENTORY_LOAD_SKU_GET';
export const INVENTORY_LOAD_SKU_COMPLETE = 'INVENTORY_LOAD_SKU_COMPLETE';
export const INVENTORY_LOAD_SKU_ERROR = 'INVENTORY_LOAD_SKU_ERROR';

export const INVENTORY_LOAD_SKU_HISTORY_GET = 'INVENTORY_LOAD_SKU_HISTORY_GET';
export const INVENTORY_LOAD_SKU_HISTORY_COMPLETE = 'INVENTORY_LOAD_SKU_HISTORY_COMPLETE';
export const INVENTORY_LOAD_SKU_HISTORY_ERROR = 'INVENTORY_LOAD_SKU_HISTORY_ERROR';

export const INVENTORY_LOAD_SKU_LOCATION_HISTORY_GET = 'INVENTORY_LOAD_SKU_LOCATION_HISTORY_GET';
export const INVENTORY_LOAD_SKU_LOCATION_HISTORY_COMPLETE = 'INVENTORY_LOAD_SKU_LOCATION_HISTORY_COMPLETE';
export const INVENTORY_LOAD_SKU_LOCATION_HISTORY_ERROR = 'INVENTORY_LOAD_SKU_LOCATION_HISTORY_ERROR';

export const INVENTORY_OUTBOUND_GET = 'INVENTORY_OUTBOUND_GET';
export const INVENTORY_OUTBOUND_COMPLETE = 'INVENTORY_OUTBOUND_COMPLETE';
export const INVENTORY_OUTBOUND_ERROR = 'INVENTORY_OUTBOUND_ERROR';

export const INVENTORY_REPORTING_INBOUND_GET = 'INVENTORY_REPORTING_INBOUND_GET';
export const INVENTORY_REPORTING_INBOUND_COMPLETE = 'INVENTORY_REPORTING_INBOUND_COMPLETE';
export const INVENTORY_REPORTING_INBOUND_ERROR = 'INVENTORY_REPORTING_INBOUND_ERROR';

export const INVENTORY_REPORTING_STORAGE_GET = 'INVENTORY_REPORTING_STORAGE_GET';
export const INVENTORY_REPORTING_STORAGE_COMPLETE = 'INVENTORY_REPORTING_STORAGE_COMPLETE';
export const INVENTORY_REPORTING_STORAGE_ERROR = 'INVENTORY_REPORTING_STORAGE_ERROR';

export const INVENTORY_POST_RECOVERY_GET = 'INVENTORY_POST_RECOVERY_GET';
export const INVENTORY_POST_RECOVERY_COMPLETE = 'INVENTORY_POST_RECOVERY_COMPLETE';
export const INVENTORY_POST_RECOVERY_ERROR = 'INVENTORY_POST_RECOVERY_ERROR';

export const INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_GET = 'INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_GET';
export const INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_COMPLETE = 'INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_COMPLETE';
export const INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_ERROR = 'INVENTORY_SKU_WAREHOUSE_LOCATION_GROUPED_ERROR';

export const INVENTORY_SKU_WAREHOUSE_LOCATION_GET = 'INVENTORY_SKU_WAREHOUSE_LOCATION_GET';
export const INVENTORY_SKU_WAREHOUSE_LOCATION_COMPLETE = 'INVENTORY_SKU_WAREHOUSE_LOCATION_COMPLETE';
export const INVENTORY_SKU_WAREHOUSE_LOCATION_ERROR = 'INVENTORY_SKU_WAREHOUSE_LOCATION_ERROR';

export const INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_GET = 'INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_GET';
export const INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_COMPLETE = 'INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_COMPLETE';
export const INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_ERROR = 'INVENTORY_UNIT_WAREHOUSE_LOCATION_GROUPED_ERROR';

export const INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_GET = 'INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_GET';
export const INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_COMPLETE = 'INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_COMPLETE';
export const INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_ERROR = 'INVENTORY_CASE_WAREHOUSE_LOCATION_GROUPED_ERROR';

export const INVENTORY_CASE_WAREHOUSE_LOCATION_GET = 'INVENTORY_CASE_WAREHOUSE_LOCATION_GET';
export const INVENTORY_CASE_WAREHOUSE_LOCATION_COMPLETE = 'INVENTORY_CASE_WAREHOUSE_LOCATION_COMPLETE';
export const INVENTORY_CASE_WAREHOUSE_LOCATION_ERROR = 'INVENTORY_CASE_WAREHOUSE_LOCATION_ERROR';

export const INVENTORY_POST_CASE_WAREHOUSE_LOCATION_GET = 'INVENTORY_POST_CASE_WAREHOUSE_LOCATION_GET';
export const INVENTORY_POST_CASE_WAREHOUSE_LOCATION_COMPLETE = 'INVENTORY_POST_CASE_WAREHOUSE_LOCATION_COMPLETE';
export const INVENTORY_POST_CASE_WAREHOUSE_LOCATION_ERROR = 'INVENTORY_POST_CASE_WAREHOUSE_LOCATION_ERROR';

export const INVENTORY_PALLET_WAREHOUSE_LOCATION_GET = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_GET';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_ERROR = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_ERROR';

export const INVENTORY_PALLET_WAREHOUSE_LOCATION_GET2 = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_GET2';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE2 = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_COMPLETE2';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_ERROR2 = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_ERROR2';

export const INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_GET = 'INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_GET';
export const INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_COMPLETE = 'INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_COMPLETE';
export const INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_ERROR = 'INVENTORY_POST_PALLET_WAREHOUSE_LOCATION_ERROR';

export const INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_GET = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_GET';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_COMPLETE = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_COMPLETE';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_ERROR = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_GROUPED_ERROR';

export const INVENTORY_POST_LOAD_PALLET_CASE_SKU_GET = 'INVENTORY_POST_LOAD_PALLET_CASE_SKU_GET';
export const INVENTORY_POST_LOAD_PALLET_CASE_SKU_COMPLETE = 'INVENTORY_POST_LOAD_PALLET_CASE_SKU_COMPLETE';
export const INVENTORY_POST_LOAD_PALLET_CASE_SKU_ERROR = 'INVENTORY_POST_LOAD_PALLET_CASE_SKU_ERROR';

export const INVENTORY_LOAD_PALLET_CASE_UNIT_GET = 'INVENTORY_LOAD_PALLET_CASE_UNIT_GET';
export const INVENTORY_LOAD_PALLET_CASE_UNIT_COMPLETE = 'INVENTORY_LOAD_PALLET_CASE_UNIT_COMPLETE';
export const INVENTORY_LOAD_PALLET_CASE_UNIT_ERROR = 'INVENTORY_LOAD_PALLET_CASE_UNIT_ERROR';

export const INVENTORY_LOAD_LOT_GET = 'INVENTORY_LOAD_LOT_GET';
export const INVENTORY_LOAD_LOT_COMPLETE = 'INVENTORY_LOAD_LOT_COMPLETE';
export const INVENTORY_LOAD_LOT_ERROR = 'INVENTORY_LOAD_LOT_ERROR';

export const INVENTORY_PALLET_CONTENTS_GET = 'INVENTORY_PALLET_CONTENTS_GET';
export const INVENTORY_PALLET_CONTENTS_COMPLETE = 'INVENTORY_PALLET_CONTENTS_COMPLETE';
export const INVENTORY_PALLET_CONTENTS_ERROR = 'INVENTORY_PALLET_CONTENTS_ERROR';

export const INVENTORY_POST_LOAD_BARCODE_SCAN_GET = 'INVENTORY_POST_LOAD_BARCODE_SCAN_GET';
export const INVENTORY_POST_LOAD_BARCODE_SCAN_COMPLETE = 'INVENTORY_POST_LOAD_BARCODE_SCAN_COMPLETE';
export const INVENTORY_POST_LOAD_BARCODE_SCAN_ERROR = 'INVENTORY_POST_LOAD_BARCODE_SCAN_ERROR';

export const INVENTORY_POST_PALLET_CREATE_NEW_GET = 'INVENTORY_POST_PALLET_CREATE_NEW_GET';
export const INVENTORY_POST_PALLET_CREATE_NEW_COMPLETE = 'INVENTORY_POST_PALLET_CREATE_NEW_COMPLETE';
export const INVENTORY_POST_PALLET_CREATE_NEW_ERROR = 'INVENTORY_POST_PALLET_CREATE_NEW_ERROR';

export const INVENTORY_SKU_REF_GET = 'INVENTORY_SKU_REF_GET';
export const INVENTORY_SKU_REF_COMPLETE = 'INVENTORY_SKU_REF_COMPLETE';
export const INVENTORY_SKU_REF_ERROR = 'INVENTORY_SKU_REF_ERROR';

export const INVENTORY_CASE_HISTORY_GET = 'INVENTORY_CASE_HISTORY_GET';
export const INVENTORY_CASE_HISTORY_COMPLETE = 'INVENTORY_CASE_HISTORY_COMPLETE';
export const INVENTORY_CASE_HISTORY_ERROR = 'INVENTORY_CASE_HISTORY_ERROR';

export const INVENTORY_CASE_SKUS_HISTORY_GET = 'INVENTORY_CASE_SKUS_HISTORY_GET';
export const INVENTORY_CASE_SKUS_HISTORY_COMPLETE = 'INVENTORY_CASE_SKUS_HISTORY_COMPLETE';
export const INVENTORY_CASE_SKUS_HISTORY_ERROR = 'INVENTORY_CASE_SKUS_HISTORY_ERROR';

export const INVENTORY_LOAD_CASES_HISTORY_GET = 'INVENTORY_LOAD_CASES_HISTORY_GET';
export const INVENTORY_LOAD_CASES_HISTORY_COMPLETE = 'INVENTORY_LOAD_CASES_HISTORY_COMPLETE';
export const INVENTORY_LOAD_CASES_HISTORY_ERROR = 'INVENTORY_LOAD_CASES_HISTORY_ERROR';

export const INVENTORY_LOAD_PALLETS_HISTORY_GET = 'INVENTORY_LOAD_PALLETS_HISTORY_GET';
export const INVENTORY_LOAD_PALLETS_HISTORY_COMPLETE = 'INVENTORY_LOAD_PALLETS_HISTORY_COMPLETE';
export const INVENTORY_LOAD_PALLETS_HISTORY_ERROR = 'INVENTORY_LOAD_PALLETS_HISTORY_ERROR';

export const INVENTORY_LOAD_SKUS_HISTORY_GET = 'INVENTORY_LOAD_SKUS_HISTORY_GET';
export const INVENTORY_LOAD_SKUS_HISTORY_COMPLETE = 'INVENTORY_LOAD_SKUS_HISTORY_COMPLETE';
export const INVENTORY_LOAD_SKUS_HISTORY_ERROR = 'INVENTORY_LOAD_SKUS_HISTORY_ERROR';

export const INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_GET = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_GET';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_COMPLETE = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_COMPLETE';
export const INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_ERROR = 'INVENTORY_PALLET_WAREHOUSE_LOCATION_HISTORY_ERROR';

export const INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_GET = 'INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_GET';
export const INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_COMPLETE = 'INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_COMPLETE';
export const INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_ERROR = 'INVENTORY_POST_LOAD_REMOVE_PALLET_CASE_SKU_ERROR';

export const INVENTORY_LOT_BARCODE_SCAN_VALIDATE_GET = 'INVENTORY_LOT_BARCODE_SCAN_VALIDATE_GET';
export const INVENTORY_LOT_BARCODE_SCAN_VALIDATE_COMPLETE = 'INVENTORY_LOT_BARCODE_SCAN_VALIDATE_COMPLETE';
export const INVENTORY_LOT_BARCODE_SCAN_VALIDATE_ERROR = 'INVENTORY_LOT_BARCODE_SCAN_VALIDATE_ERROR';

export const INVENTORY_LOT_OUTBOUND_GET = 'INVENTORY_LOT_OUTBOUND_GET';
export const INVENTORY_LOT_OUTBOUND_COMPLETE = 'INVENTORY_LOT_OUTBOUND_COMPLETE';
export const INVENTORY_LOT_OUTBOUND_ERROR = 'INVENTORY_LOT_OUTBOUND_ERROR';

export const INVENTORY_LOT_REPORTING_INBOUND_GET = 'INVENTORY_LOT_REPORTING_INBOUND_GET';
export const INVENTORY_LOT_REPORTING_INBOUND_COMPLETE = 'INVENTORY_LOT_REPORTING_INBOUND_COMPLETE';
export const INVENTORY_LOT_REPORTING_INBOUND_ERROR = 'INVENTORY_LOT_REPORTING_INBOUND_ERROR';

export const INVENTORY_LOT_REPORTING_STORAGE_GET = 'INVENTORY_LOT_REPORTING_STORAGE_GET';
export const INVENTORY_LOT_REPORTING_STORAGE_COMPLETE = 'INVENTORY_LOT_REPORTING_STORAGE_COMPLETE';
export const INVENTORY_LOT_REPORTING_STORAGE_ERROR = 'INVENTORY_LOT_REPORTING_STORAGE_ERROR';

export const INVENTORY_LOT_HISTORY_TYPE_GET = 'INVENTORY_LOT_HISTORY_TYPE_GET';
export const INVENTORY_LOT_HISTORY_TYPE_COMPLETE = 'INVENTORY_LOT_HISTORY_TYPE_COMPLETE';
export const INVENTORY_LOT_HISTORY_TYPE_ERROR = 'INVENTORY_LOT_HISTORY_TYPE_ERROR';

export const INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_GET = 'INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_GET';
export const INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_COMPLETE = 'INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_COMPLETE';
export const INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_ERROR = 'INVENTORY_LOT_POST_DELETE_CONTAINER_PALLET_CASE_UNIT_ERROR';


export const INVENTORY_LOT_POST_CASE_REF_EDIT_GET = 'INVENTORY_LOT_POST_CASE_REF_EDIT_GET';
export const INVENTORY_LOT_POST_CASE_REF_EDIT_COMPLETE = 'INVENTORY_LOT_POST_CASE_REF_EDIT_COMPLETE';
export const INVENTORY_LOT_POST_CASE_REF_EDIT_ERROR = 'INVENTORY_LOT_POST_CASE_REF_EDIT_ERROR';

export const INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_GET = 'INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_GET';
export const INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_COMPLETE = 'INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_COMPLETE';
export const INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_ERROR = 'INVENTORY_LOT_POST_UNIT_CASESKU_REF_EDIT_ERROR';

export const INVENTORY_LOT_POST_PALLET_REF_EDIT_GET = 'INVENTORY_LOT_POST_PALLET_REF_EDIT_GET';
export const INVENTORY_LOT_POST_PALLET_REF_EDIT_COMPLETE = 'INVENTORY_LOT_POST_PALLET_REF_EDIT_COMPLETE';
export const INVENTORY_LOT_POST_PALLET_REF_EDIT_ERROR = 'INVENTORY_LOT_POST_PALLET_REF_EDIT_ERROR';

export const INVENTORY_LOT_POST_CONTAINER_GET = 'INVENTORY_LOT_POST_CONTAINER_GET';
export const INVENTORY_LOT_POST_CONTAINER_COMPLETE = 'INVENTORY_LOT_POST_CONTAINER_COMPLETE';
export const INVENTORY_LOT_POST_CONTAINER_ERROR = 'INVENTORY_LOT_POST_CONTAINER_ERROR';

export const INVENTORY_LOT_ORDER_OUTBOUND_POST = 'INVENTORY_LOT_ORDER_OUTBOUND_POST';
export const INVENTORY_LOT_ORDER_OUTBOUND_POST_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_POST_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_POST_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_POST_ERROR';

export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_POST_ERROR';

export const INVENTORY_LOT_ORDER_OUTBOUND_GET = 'INVENTORY_LOT_ORDER_OUTBOUND_GET';
export const INVENTORY_LOT_ORDER_OUTBOUND_GET_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_GET_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_GET_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_GET_ERROR';

export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_GET_ERROR';

export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_EACH_LOCATIONS_GET_ERROR';

export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_CASE_LOCATIONS_GET_ERROR';


export const INVENTORY_LOT_PALLET_SKU_UNITS_GET = 'INVENTORY_LOT_PALLET_SKU_UNITS_GET';
export const INVENTORY_LOT_PALLET_SKU_UNITS_GET_COMPLETE = 'INVENTORY_LOT_PALLET_SKU_UNITS_GET_COMPLETE';
export const INVENTORY_LOT_PALLET_SKU_UNITS_GET_ERROR = 'INVENTORY_LOT_PALLET_SKU_UNITS_GET_ERROR';

export const INVENTORY_LOT_PALLET_SKU_CASES_GET = 'INVENTORY_LOT_PALLET_SKU_CASES_GET';
export const INVENTORY_LOT_PALLET_SKU_CASES_GET_COMPLETE = 'INVENTORY_LOT_PALLET_SKU_CASES_GET_COMPLETE';
export const INVENTORY_LOT_PALLET_SKU_CASES_GET_ERROR = 'INVENTORY_LOT_PALLET_SKU_CASES_GET_ERROR';

export const INVENTORY_LOT_CASE_SKU_UNITS_GET = 'INVENTORY_LOT_CASE_SKU_UNITS_GET';
export const INVENTORY_LOT_CASE_SKU_UNITS_GET_COMPLETE = 'INVENTORY_LOT_CASE_SKU_UNITS_GET_COMPLETE';
export const INVENTORY_LOT_CASE_SKU_UNITS_GET_ERROR = 'INVENTORY_LOT_CASE_SKU_UNITS_GET_ERROR';

export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_SKU_PALLET_LOCATIONS_GET_ERROR';



export const INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET = 'INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET';
export const INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET_COMPLETE = 'INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET_COMPLETE';
export const INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET_ERROR = 'INVENTORY_LOT_ORDER_OUTBOUND_HISTORY_GET_ERROR';




export const INVENTORY_LOT_LOAD_FILEUPLOAD_GET = 'INVENTORY_LOT_LOAD_FILEUPLOAD_GET';
export const INVENTORY_LOT_LOAD_FILEUPLOAD_COMPLETE = 'INVENTORY_LOT_LOAD_FILEUPLOAD_COMPLETE';
export const INVENTORY_LOT_LOAD_FILEUPLOAD_ERROR = 'INVENTORY_LOT_LOAD_FILEUPLOAD_ERROR';

export const INVENTORY_LOT_POST_LOAD_FILEUPLOAD_GET = 'INVENTORY_LOT_POST_LOAD_FILEUPLOAD_GET';
export const INVENTORY_LOT_POST_LOAD_FILEUPLOAD_COMPLETE = 'INVENTORY_LOT_POST_LOAD_FILEUPLOAD_COMPLETE';
export const INVENTORY_LOT_POST_LOAD_FILEUPLOAD_ERROR = 'INVENTORY_LOT_POST_LOAD_FILEUPLOAD_ERROR';

