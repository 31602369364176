import * as moment from 'moment'


export default function uniqueBy(list, uniqueby) {
    const uniques = {}
    return list.reduce(
        (result, item) => {
            if (uniques[item[uniqueby]]) return result
            uniques[item[uniqueby]] = item
            return [...result, item]
        },
        [],
    )
}

export function removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

// const data = [
//     { group: 'A', name: 'SD' }, 
//     { group: 'B', name: 'FI' }, 
//     { group: 'A', name: 'MM' },
//     { group: 'B', name: 'CO'}
//   ];
//   const unique = [...new Set(data.map(item => item.group))]; // [ 'A', 'B']

export function uniqueArray(data, uniqueby) {

    let unique = [...new Set(data.map(item => item[uniqueby]))]

    return unique

}

// export function uniqueByDict(data, value){
//     let newDict = []
//     data.reduce((a, d) => {
//         
//         
//     if (!a.includes(d[value])) { a.push(d[value])
//         newDict.push( {'test':d[value]} ); }
//         return a;
//     }, []);

//     return newDict
//     } 


// export function inputSelectUniqueBy(arr, prop){
//         let newDict = []
//         arr.reduce((a, d) => {
//         if (!a.includes(d[prop])) { a.push(d[prop])
//            newDict.push( {'label':d[prop], 'value':d[prop]} ); }
//            return a;
//         }, []);
//         return newDict
//       }     


export function convertNanToZero(val) {
    if (isNaN(val)) {
        return 0;
    }
    return Number(val);
}


// This will replace a value of 0 with null
export function isNumeric(num) {
    if (!isNaN(num)) {
        if (Number(num) == 0) {
            return null
        }
        else {
            return Number(num)
        }
    }
    else {
        return num
    }
}

// This will allow zero's
export function isNumericWithZero(num) {
    if (!isNaN(num)) {
        return Number(num)
    }
    else {
        return num
    }
}

export function isNumericReplaceNullWithEmpty(num) {
    if (num == null) {
        return ''
    }
    else if (!isNaN(num)) {
        return Number(num)
    }
    else {
        return num
    }
}



export function replaceNullWithEmpty(value) {
    try {
        if (value == null) {
            return ''
        }
        else {
            return value
        }
    }
    catch (e) {
        return value
    }
}

export function replaceEmptyWithNull(value) {
    try {
        if (value == '') {
            return null
        }
        else {
            return value
        }
    }
    catch (e) {
        return value
    }
}




export function isNumericBool(num) {
    if (!isNaN(num)) {
        return false
    }
    else {
        return true
    }
}

export function replaceNegativeWithZero(num) {
    try {
        if (num < 0) {
            return 0
        }
        else {
            return num
        }
    }
    catch {
        return num
    }
}


export function inputSelectUniqueBy(arr, label, value) {
    let newDict = []
    try {
        arr.reduce((a, d) => {
            if (typeof (d[label]) !== 'undefined' && d[label] != null) {
                if (!a.includes(d[label])) {
                    a.push(d[label])
                    newDict.push({ 'label': d[label], 'value': d[value] });
                }
            }
            else { }
            return a;
        }, []);
        return newDict
    }
    catch (e) {
        return newDict
    }
}


export function sortData(data, sortColumn, sortType) {
    try {
        var collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: 'base'
        });
        data.sort(function (a, b) {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (sortType === 'asc') {
                return collator.compare(x, y)
            } else {
                return collator.compare(y, x)
            }
        });
    }
    catch (e) {
        return data
    }

}


export function queryStringParse(value) {
    if (typeof (value) !== 'undefined' && value != null && value != 'null') {
        try {
            var stripString = value.trim().replace(/[|&;$%"'` #!^*~<>()+,=]/g, '') //Add unwanted characters inside []
            return isNumeric(stripString)
        }
        catch (e) {
            return isNumeric(value)
        }
    }
    else {
        return null
    }

}

export function queryStringParseWithZero(value) {
    if (typeof (value) !== 'undefined' && value != null && value != 'null') {
        try {
            var stripString = value.trim().replace(/[|&;$%"'` #!^*~<>()+,=]/g, '') //Add unwanted characters inside []
            return isNumericWithZero(stripString)
        }
        catch (e) {
            return isNumericWithZero(value)
        }
    }
    else {
        return null
    }

}


export function stripCharacters(value) {
    if (typeof (value) !== 'undefined' && value != null && value != 'null') {
        try {
            var stripString = value.trim().replace(/[|&;$%"'` #!^*~<>()+,=]/g, '') //Add unwanted characters inside []
            return stripString
        }
        catch (e) {
            return value
        }
    }
    else {
        return null
    }

}



export function formatArrayAsStringForAPI(inputString) {
    var newString = null

    if (inputString === null) {
    }
    else if (inputString == 'null') {
    }
    else if (typeof (inputString) == 'undefined') {
    }
    else if (inputString == '') {
    }
    else {
        try {
            var stripString = inputString.toString().trim()
            var newString = "'" + stripString + "'"
        }
        catch (e) {
            var newString = "'" + stripString + "'"
        }
    }
    return newString
}



export function formatStringForAPI(inputString) {
    var newString = null

    if (inputString === null) {
    }
    else if (inputString == 'null') {
    }
    else if (typeof (inputString) == 'undefined') {
    }
    else if (inputString == '') {
    }
    else {
        try {
            // var stripString = inputString.toString().trim().replace(/[|;"'`!^*~<>=]/g, '') //Add unwanted characters inside []
            // var stripString = inputString.toString().trim().replace(/[|&;$%"'`!^*~<>()+=]/g, '') //Add unwanted characters inside []
            var stripString = inputString.toString().trim().replace(/['"]/g, '') //Add unwanted characters inside []

            var newString = "'" + stripString + "'"
        }
        catch (e) {
            var newString = "'" + stripString + "'"
        }
    }
    return newString
}

export function formatStringRemoveSpaces(inputString) {
    var newString = ''

    if (inputString === null) {
    }
    else if (inputString == 'null') {
    }
    else if (typeof (inputString) == 'undefined') {
    }
    else if (inputString == '') {
    }
    else {
        try {
            newString = inputString.replace(/\s/g, '');
        }
        catch (e) {
        }
    }
    return newString
}


export function formatTextNumbersSymbolsForAPI(inputString) {
    var newString = null

    if (inputString === null) {
    }
    else if (inputString == 'null') {
    }
    else if (typeof (inputString) == 'undefined') {
    }
    else if (inputString == '') {
    }
    else {
        try {
            // var stripString = inputString.toString().trim().replace(/[|;"'`!^*~<>=]/g, '') //Add unwanted characters inside []
            var stripString = inputString.toString().trim().replace(/[|&;$%"'`!^*~<>()+=]/g, '') //Add unwanted characters inside []
            // var stripString = inputString.toString().trim().replace(/['"]/g, '') //Add unwanted characters inside []

            var newString = "'" + stripString + "'"
        }
        catch (e) {
            var newString = "'" + stripString + "'"
        }
    }
    return newString
}



export function formatAllTextNumbersSymbolsForAPI(inputString) {
    var newString = null

    if (inputString === null) {
    }
    else if (inputString == 'null') {
    }
    else if (typeof (inputString) == 'undefined') {
    }
    else if (inputString == '') {
    }
    else {
        try {
            // var stripString = inputString.toString().trim().replace(/[|;"'`!^*~<>=]/g, '') //Add unwanted characters inside []
            var stripString = inputString.toString().trim().replace(/[;"'`<>()]/g, '') //Add unwanted characters inside []
            // var stripString = inputString.toString().trim().replace(/['"]/g, '') //Add unwanted characters inside []

            var newString = "'" + stripString + "'"
        }
        catch (e) {
            var newString = "'" + stripString + "'"
        }
    }
    return newString
}





export function formatStringWithoutQuotesForAPI(inputString) {
    var newString = null

    if (inputString === null) {
    }
    else if (inputString == 'null') {
    }
    else if (typeof (inputString) == 'undefined') {
    }
    else if (inputString == '') {
    }
    else {
        try {
            // var stripString = inputString.toString().trim().replace(/[|&;$%"'`#!^*~<>()+=]/g, '') //Add unwanted characters inside []
            var stripString = inputString.toString().trim().replace(/[^A-Za-z0-9_-\s,.@()]/gi, '') //Add unwanted characters inside []
            var newString = stripString
        }
        catch (e) {
            var newString = stripString
        }
    }
    return newString
}



export function formatDateForAPI(inputString) {
    var newString = null

    if (inputString === null || typeof (inputString) == 'undefined' || inputString == 'null') {
        return null
    }
    else {
        var stripString = inputString.trim().replace(/[|&;$%"'`#!^*~<>()+,=]/g, '') //Add unwanted characters inside []
        var newString = "'" + stripString + "'"
    }
    return newString
}

export function formatDateForUrlSearch(inputString) {
    var dt = null

    try {
        if (inputString === null || inputString == 'null') {
            dt = null
        }
        else {
            dt = "'" + moment(inputString).format('YYYY-MM-DD') + "'"
        }
    }
    catch (e) { }

    return dt

}


export function formatDateTimeForUrlSearch(inputString) {
    var dt = null

    try {
        if (inputString === null || inputString == 'null') {
            dt = null
        }
        else {
            dt = "'" + moment(inputString).format('YYYY-MM-DD hh:mm a') + "'"
        }
    }
    catch (e) { }

    return dt

}


export function formatTimeForUrlSearch(inputString) {
    var dt = null

    try {
        if (inputString === null || inputString == 'null') {
            dt = null
        }
        else {
            dt = "'" + moment(inputString).format('hh:mm a') + "'"
        }
    }
    catch (e) { }

    return dt

}


export function formatNumberForAPI(inputString) {
    var newString = null;

    if (inputString === null || typeof inputString === 'undefined' || inputString === 'null' || inputString === '') {
        // do nothing
    } else {
        newString = Number(inputString);
    }

    return newString;
}

// export function formatNumberForAPI(inputString) {
//     var newString = null

//     if (inputString == null || typeof (inputString) == 'undefined' || inputString == 'null' || inputString == '') {

//     }
//     else {
//         var newString = Number(inputString)
//     }
//     return newString
// }

export function formatJsonStringForAPI(inputString) {
    var newString = null

    if (inputString == null || typeof (inputString) == 'undefined' || inputString == 'null') {

    }
    else {
        var stripString = inputString.trim().replace(/[|&;$%"'`\[\]#!^*~<>()+=]/g, '') //Add unwanted characters inside []
        var newString = "'" + stripString + "'"
    }
    return newString
}

export function formatJsonStringForMsSql(inputString) {
    var newString = null

    if (inputString == null || typeof (inputString) == 'undefined' || inputString == 'null') {

    }
    else {
        var stripString = inputString.trim().replace(/[|&;$%"'`\[\]#!^*~<>()+=]/g, '') //Add unwanted characters inside []
        var newString = "'[" + stripString + "]'"
    }
    return newString
}


export function formatForExcelExport(inputString) {
    var newString = ""
    if (inputString === null || inputString == 'null') {
        return newString
    }
    else {
        return inputString
    }
}


export function findLowestMissingNumber(A) {
    let n = A.length;
    // To mark the occurrence of elements
    let present = new Array(n + 1);

    for (let i = 0; i < n + 1; i++) {
        present[i] = false;
    }
    // Mark the occurrences
    for (let i = 0; i < n; i++) {
        // Only mark the required elements
        // All non-positive elements and
        // the elements greater n + 1 will never
        // be the answer
        // For example, the array will be {1, 2, 3}
        // in the worst case and the result
        // will be 4 which is n + 1
        if (A[i] > 0 && A[i] <= n) {
            present[A[i]] = true;
        }
    }
    // Find the first element which didn't
    // appear in the original array

    for (let i = 1; i <= n; i++) {
        if (!present[i]) {
            return i;
        }
    }
    // If the original array was of the
    // type {1, 2, 3} in its sorted form
    return n + 1;
}



export function findMaxNumberInArray(A) {

    let m = 0
    try {
        m = Math.max(0, ...A)
    }
    catch (e) {
        m = 0
    }

    return m

}



export function formatTrueFalseToBit(inputString) {
    var returnBit = 0
    try {
        if (inputString === true || inputString == 'true' ||inputString === 1) {
            returnBit = 1
        }
        else if (inputString === false || inputString == 'false' || inputString === 0) {
            returnBit = 0
        }
        else {
            returnBit = 0
        }
    }
    catch (e) { }

    return returnBit

}

export function formatBitToTrueFalse(inputString) {
    var returnTF = ''
    try {
        if (inputString === true || inputString === 1) {
            returnTF = 'True'
        }
        else if (inputString === false || inputString === 0) {
            returnTF = 'False'
        }
    }
    catch (e) { }

    return returnTF

}

export function checkForNotNull(input) {
    try {
        if (typeof (input) === 'undefined') {
            return false
        }
        else if (input === null) {
            return false
        }
        else if (input === '') {
            return false
        }
        else {
            return true
        }
    }
    catch (e) {
        return false
    }


}

export function removeByKey(myObj, deleteKey) {
    return Object.keys(myObj)
        .filter(key => key !== deleteKey)
        .reduce((result, current) => {
            result[current] = myObj[current];
            return result;
        }, {});
}