import {connect} from 'react-redux';
import React, {Component} from 'react';
import AccountDetails from './AccountDetails'
import * as actions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';


//add props from reducer here
const mapStateToProps = store =>
({
    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail,

    isAllUsersLoading:                  store.userServicesReducer.isAllUsersLoading,
    AllUsersData:                       store.userServicesReducer.AllUsersData,

    PostDeleteUserSuccessful:       store.userServicesReducer.PostDeleteUserSuccessful,

    UserAccountEditSuccessful:                       store.userServicesReducer.UserAccountEditSuccessful,


    isAccountDetailsUserWarehouseLoading:                  store.userServicesReducer.isAccountDetailsUserWarehouseLoading,
    AccountDetailsUserWarehouseData:                       store.userServicesReducer.AccountDetailsUserWarehouseData,

    AccountDetailsUserModuleData:                       store.userServicesReducer.AccountDetailsUserModuleData,
    isAccountDetailsUserModuleLoading:                       store.userServicesReducer.isAccountDetailsUserModuleLoading,

    AccountDetailsUserCustomerData:                       store.userServicesReducer.AccountDetailsUserCustomerData,
    isAccountDetailsUserCustomerLoading:                       store.userServicesReducer.isAccountDetailsUserCustomerLoading,


    PostUserWarehouseSuccessful:                       store.userServicesReducer.PostUserWarehouseSuccessful,
    PostUserCustomerSuccessful:                       store.userServicesReducer.PostUserCustomerSuccessful,
    PostUserModuleSuccessful:                       store.userServicesReducer.PostUserModuleSuccessful,

    isUserDeleteCognitoPostLoading: store.userServicesReducer.isUserDeleteCognitoPostLoading,
    UserDeleteCognitoPostSuccessful: store.userServicesReducer.UserDeleteCognitoPostSuccessful,
});

//add actions here
const mapDispatchToProps = dispatch =>
({


    getAllUsers:( _email, _authToken, _userEmailToSearch, _userIdToSearch, _ModuleID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.getAllUsers( _email, _authToken, _userEmailToSearch, _userIdToSearch, _ModuleID, _WarehouseID, _CustomerID ) )
    },


    postDeleteUser:(_email, _authToken, _userIdToDelete ) =>
    {
        dispatch( actions.postDeleteUser( _email, _authToken, _userIdToDelete ) )
    },

    postAccountEdit:( _email, _authToken, _userIdToEdit, _firstName, _lastName, _active, _admin, _userIdList ) =>
    {
        dispatch( actions.postAccountEdit( _email, _authToken, _userIdToEdit, _firstName, _lastName, _active, _admin, _userIdList ) )
    },

    accountDetailsUserWarehousesGet:(_email, _otherUsersID ) =>
    {
        dispatch( actions.accountDetailsUserWarehousesGet( _email, _otherUsersID ) )
    },


    accountDetailsUserCustomersGet:(_email, _otherUsersID ) =>
    {
        dispatch( actions.accountDetailsUserCustomersGet( _email, _otherUsersID ) )
    },


    accountDetailsUserModulesGet:(_email, _otherUsersID ) =>
    {
        dispatch( actions.accountDetailsUserModulesGet( _email, _otherUsersID ) )
    },

    userWarehousesPost:( _email, _authToken, _userIdToEdit, _WarehouseID, _admin, _readwrite, _deleteFromWarehouse ) =>
    {
        dispatch( actions.userWarehousesPost( _email, _authToken, _userIdToEdit, _WarehouseID, _admin, _readwrite, _deleteFromWarehouse ) )
    },

    userCustomersPost:( _email, _authToken, _userIdToEdit, _CustomerID, _admin, _readwrite, _deleteFromCustomer ) =>
    {
        dispatch( actions.userCustomersPost( _email, _authToken, _userIdToEdit, _CustomerID, _admin, _readwrite, _deleteFromCustomer ) )
    },


    userModulesPost:( _email, _authToken, _userIdToEdit, _ModuleID, _admin, _readwrite, _deleteFromModule ) =>
    {
        dispatch( actions.userModulesPost( _email, _authToken, _userIdToEdit, _ModuleID, _admin, _readwrite, _deleteFromModule ) )
    },


    userAwsCognitoDeleteUserPost: (_usernameToDelete) => {
        dispatch(actions.userAwsCognitoDeleteUserPost(_usernameToDelete))
    },


    //change this to AWS delete user account
    //look at HeaderContainer
    // adminDeleteUser: (username) => 
    // {
    //     dispatch( reducer.adminDeleteUser(username) )
    // }



});

class AccountDetailsContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
    componentDidMount() {
        this.handleUrlParam();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.UserAccountEditSuccessful !== this.props.UserAccountEditSuccessful && this.props.UserAccountEditSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

        if (prevProps.PostUserWarehouseSuccessful !== this.props.PostUserWarehouseSuccessful && this.props.PostUserWarehouseSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

        if (prevProps.PostUserCustomerSuccessful !== this.props.PostUserCustomerSuccessful && this.props.PostUserCustomerSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }


        if (prevProps.PostUserModuleSuccessful !== this.props.PostUserModuleSuccessful && this.props.PostUserModuleSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

        if (prevProps.PostDeleteUserSuccessful !== this.props.PostDeleteUserSuccessful && this.props.PostDeleteUserSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }


    }
    
    handleUrlParam = () => {

        var paramuserid = -1

        const values = queryString.parse(this.props.location.search)

        if(typeof(values.userid) !== 'undefined' && values.userid != null ){
            paramuserid = values.userid
        }
        this.props.getAllUsers( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramuserid, null, null, null);

        this.props.accountDetailsUserWarehousesGet( this.props.currentUserEmail, paramuserid)
        this.props.accountDetailsUserCustomersGet( this.props.currentUserEmail, paramuserid)
        this.props.accountDetailsUserModulesGet( this.props.currentUserEmail, paramuserid)

    }
	
    render()
    {
    	return (
            <AccountDetails 

            isMobile={this.props.isMobile}

            userSignInData={this.props.userSignInData}
            currentUserEmail={this.props.currentUserEmail}
            // deleteUser={this.props.deleteUser}
            postDeleteUser={this.props.postDeleteUser}

            isAllUsersLoading={this.props.isAllUsersLoading}
            AllUsersData={this.props.AllUsersData}

            UserAccountEditSuccessful={this.props.UserAccountEditSuccessful}
            postAccountEdit={this.props.postAccountEdit}

            isAccountDetailsUserWarehouseLoading={this.props.isAccountDetailsUserWarehouseLoading}
            AccountDetailsUserWarehouseData={this.props.AccountDetailsUserWarehouseData}

            AccountDetailsUserModuleData={this.props.AccountDetailsUserModuleData}
            isAccountDetailsUserModuleLoading={this.props.isAccountDetailsUserModuleLoading}
        
            AccountDetailsUserCustomerData={this.props.AccountDetailsUserCustomerData}
            isAccountDetailsUserCustomerLoading={this.props.isAccountDetailsUserCustomerLoading}

            userWarehousesPost={this.props.userWarehousesPost}

            userCustomersPost={this.props.userCustomersPost}
            userModulesPost={this.props.userModulesPost}

            userAwsCognitoDeleteUserPost={this.props.userAwsCognitoDeleteUserPost}
            isUserDeleteCognitoPostLoading={this.props.isUserDeleteCognitoPostLoading}
            UserDeleteCognitoPostSuccessful={this.props.UserDeleteCognitoPostSuccessful}
            />
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountDetailsContainer));

