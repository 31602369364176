import React, {
  PureComponent
} from 'react';
import { DatePicker, InputGroup, ControlLabel, Row, FlexboxGrid, Col } from 'rsuite';
import { queryStringParse } from '../../../../resources/logic/functions';


const styles = {
  width: '100%',
  marginBottom: 10
  // width: 200
};

const divStyle = {
  // marginLeft: 5,
  // marginRight: 5
};

// const tooltipSearch = (
//     <Tooltip>
//       Search
//     </Tooltip>
//   );

// const tooltipClear = (
//     <Tooltip>
//       Clear
//     </Tooltip>
//   );  


// function onClickSearch(e, history, querySearch, searchValue) {  
//   if (searchValue != ''){
//     var param = null
//     param = queryStringParse(searchValue)
//     try{
//         history.push({
//         search: querySearch + '=\'' + param +'\'' 
//         }); 
//     }
//     catch(e){}
//   }
//   else{
//     window.alert('Enter Search Value')
//   }
// }

// function onClickReset(e, history, onChange) {   
//   onChange('')   
//   try{
//       history.push({
//       }); 
//   }
//   catch(e){}
// }

function RDatePickerRange(
  isMobile,
  label,
  onChangeStart,
  onChangeEnd,

  dateValueStart,
  dateValueEnd
  // onClick
  // inputMask
) {

  var defaultStart = null
  if (dateValueStart != null){
    defaultStart = dateValueStart
  }
  var defaultEnd = null
  if (dateValueEnd != null){
    defaultEnd = dateValueEnd
  }

  let rInput;

  try {
    rInput =
      <div style={divStyle}>
        <ControlLabel>{label}</ControlLabel>

        <FlexboxGrid justify="start">
          <FlexboxGrid.Item colspan={12}><DatePicker oneTap style={styles} placeholder='Start' value={defaultStart} onChange={(e) => { onChangeStart(e) }} /></FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={12}><DatePicker oneTap style={styles} placeholder='End' value={defaultEnd} onChange={(e) => { onChangeEnd(e) }} /></FlexboxGrid.Item>
        </FlexboxGrid>

      </div>
  }
  catch (e) { }

  return (

    rInput

  )
}


export default RDatePickerRange;




// defaultValue={(dateValueStart !== null ? '' : new Date(dateValueStart))}
// value={dateValueEnd}