import { connect } from 'react-redux';
import React, { Component } from 'react';
import Warehouse from './Warehouse'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isTransConsigneeLoading: store.transReducer.isTransConsigneeLoading,
    TransConsigneeData: store.transReducer.TransConsigneeData,

    isTransCustomerRefLoading: store.transReducer.isTransCustomerRefLoading,
    TransCustomerRefData: store.transReducer.TransCustomerRefData,

    isTransDestinationLoading: store.transReducer.isTransDestinationLoading,
    TransDestinationData: store.transReducer.TransDestinationData,

    isPoLoading: store.transReducer.isPoLoading,
    PoData: store.transReducer.PoData,

    LoadPostSuccessful: store.transReducer.LoadPostSuccessful,

    isPoLocationLoading: store.transReducer.isPoLocationLoading,
    PoLocationData: store.transReducer.PoLocationData,
    PoLocationDataGrouped: store.transReducer.PoLocationDataGrouped,

    PoLocationPostSuccessful: store.transReducer.PoLocationPostSuccessful,

});

const mapDispatchToProps = dispatch =>
({
    transPoGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID,  _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(actions.transPoGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID,  _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd))
    },
    transLoadPost: (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _Destination, _LoadStatusID, _LoadPoData, _LocationPoData, _PoID, _PCS, _Pallets, _WarehouseID, _CustomerID) => {
        dispatch(actions.transLoadPost(_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _Destination, _LoadStatusID, _LoadPoData, _LocationPoData, _PoID, _PCS, _Pallets, _WarehouseID, _CustomerID))
    },
    transConsigneeGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transConsigneeGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    transCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    transDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    transPoLocationGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoLocationGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID))
    },
    transPoLocationPost: (_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize) => {
        dispatch(actions.transPoLocationPost(_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize))
    },
});

class WarehouseContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.LoadPostSuccessful !== this.props.LoadPostSuccessful && this.props.LoadPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatus = queryStringParse(values.postatusid)
        }
        catch (e) {
            var paramStatus = null
        }
        try {
            var paramMbol = formatStringForAPI(values.mbol)
        }
        catch (e) {
            var paramMbol = null
        }
        try {
            var paramHbol = formatStringForAPI(values.hbol)
        }
        catch (e) {
            var paramHbol = null
        }
        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }


        try {
            var paramRecoveryDateStart = formatDateForAPI(values.recoverydatestart)
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        try {
            var paramRecoveryDateEnd = formatDateForAPI(values.recoverydateend)
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }


        try {
            var paramConsigneeID = formatDateForAPI(values.consigneeid)
        }
        catch (e) {
            var paramConsigneeID = null
        }

        try {
            var paramConsigneeName = formatDateForAPI(values.consigneename)
        }
        catch (e) {
            var paramConsigneeName = null
        }

        try {
            var paramCustomerRefID = queryStringParse(values.customerrefid)
        }
        catch (e) {
            var paramCustomerRefID = null
        }

        try {
            var paramCustomerRef = formatDateForAPI(values.customerref)
        }
        catch (e) {
            var paramCustomerRef = null
        }

        
        this.props.transDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transConsigneeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, paramContainer, null, paramMbol, null, paramHbol,null, paramPo, null, paramStatus, paramCustomerRefID,  paramCustomerRef, paramRecoveryDateStart, paramRecoveryDateEnd);
        this.props.transPoLocationGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramContainerID, paramContainer, null, paramMbol, null, paramHbol, null, paramPo, paramStatus, paramCustomerRefID, paramRecoveryDateStart, paramRecoveryDateEnd   ,this.props.warehouseSelection, this.props.customerSelection)

    }



    render() {

        return (

            <Warehouse
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isPoLoading={this.props.isPoLoading}
                PoData={this.props.PoData}

                transLoadPost={this.props.transLoadPost}
                LoadPostSuccessful={this.props.LoadPostSuccessful}

                isTransCustomerRefLoading={this.props.isTransCustomerRefLoading}
                TransCustomerRefData={this.props.TransCustomerRefData}

                isTransDestinationLoading={this.props.isTransDestinationLoading}
                TransDestinationData={this.props.TransDestinationData}

                isTransConsigneeLoading={this.props.isTransConsigneeLoading}
                TransConsigneeData={this.props.TransConsigneeData}

                transPoLocationGet={this.props.transPoLocationGet}
                isPoLocationLoading={this.props.isPoLocationLoading}
                PoLocationData={this.props.PoLocationData}
                PoLocationDataGrouped={this.props.PoLocationDataGrouped}

                transPoLocationPost={this.props.transPoLocationPost}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WarehouseContainer));



