import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import * as actions from '../../../redux/actions/labelGenActions';
import LabelGenExternalParcelTracking from './LabelGenExternalParcelTracking';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,


    isLabelGenExternalUpsTrackingLoading: store.labelGenReducer.isLabelGenExternalUpsTrackingLoading,
    LabelGenExternalUpsTrackingData: store.labelGenReducer.LabelGenExternalUpsTrackingData,




    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,



    
});

const mapDispatchToProps = dispatch =>
({

    labelGenExternalUpsTrackingGet: (_email, _authToken, _ExternalUserID, _TrackingNoList, _SINList, _USPSPICList, _InTransit,) => {
        dispatch(actions.labelGenExternalUpsTrackingGet(_email, _authToken, _ExternalUserID, _TrackingNoList, _SINList, _USPSPICList, _InTransit))
    },

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },





});





class LabelGenExternalParcelTrackingContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

    }




    handleUrlParam = () => {
   
    }


    render() {

        return (

            <LabelGenExternalParcelTracking

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                // warehouseSelection={this.props.warehouseSelection}
                // customerSelection={this.props.customerSelection}

                isLabelGenExternalUpsTrackingLoading={this.props.isLabelGenExternalUpsTrackingLoading}
                LabelGenExternalUpsTrackingData={this.props.LabelGenExternalUpsTrackingData}
                labelGenExternalUpsTrackingGet={this.props.labelGenExternalUpsTrackingGet}


                downloadFileFromS3={this.props.downloadFileFromS3}


            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LabelGenExternalParcelTrackingContainer));



