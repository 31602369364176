export const CtrlTwrQuoteShipmentDataElements = [
    {
        dBKey: 'ProRef',
        displayName: 'Quote ID',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    // {
    //     dBKey: 'CustomerRef',
    //     displayName: 'Customer Ref',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },    
    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'Alpha2Code',
        displayName: 'Origin Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'DestAlpha2Code',
        displayName: 'Destination Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ETD',
        displayName: 'Planned Pickup',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Cases',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'UnitCount',
        displayName: 'Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'GrossWeight',
        displayName: 'Gross Wt',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ChargeableWeight',
        displayName: 'Chargeable Wt',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'WeightUnits',
        displayName: 'Weight Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'QuotingStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    
]


export default CtrlTwrQuoteShipmentDataElements

