export const InventoryLoadCaseDataElements = [
    {
        dBKey: 'CaseBarcode',
        displayName: 'Case Barcode',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 220
    },
    {
        dBKey: 'LotCode',
        displayName: 'Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'SerialNumber',
        displayName: 'Serial Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'CasePalletBarcode',
        displayName: 'Pallet Barcode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 220
    },
    


]


export default InventoryLoadCaseDataElements

