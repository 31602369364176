import {connect} from 'react-redux';
import React, {Component} from 'react';
import GoogleMapTracker from './GoogleMapTracker'
// import * as actions from '../../actions/actions';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

//add props from reducer here
const mapStateToProps = store =>
({
    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail,

    shipmentGPSData:                [],
    isShipmentGPSLoading:           false,
    
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    // getShipmentGPSData:( _db, _email, _housebill, _jobid, _mode, _forwarder, _service, _lane, _param, _startDT, _endDT, _jobStatus ) =>
    // {
    //     dispatch( actions.getShipmentGPSData( _db, _email, _housebill, _jobid, _mode, _forwarder, _service, _lane, _param, _startDT, _endDT, _jobStatus ) )
    // },

});


class GoogleMapTrackerContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	


    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramInTransit = 'O'
    var paramMode = null
    var paramCarrier = null
    var paramFilter = null
    var paramFilterValue = null
  
    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.intransit) !== 'undefined' && values.intransit != null ){
        paramInTransit = values.intransit
    }
    if(typeof(values.mode) !== 'undefined' && values.mode != null ){
        paramMode = values.mode
    }
    if(typeof(values.carrier) !== 'undefined' && values.carrier != null ){
        paramCarrier = values.carrier
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }

    // this.props.getShipmentGPSData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, paramMode, paramCarrier, null, null, null, paramStartDT, paramEndDT, paramInTransit );
    
    }

	
    render()
    {
    	return (
            <GoogleMapTracker 
            currentUserEmail={this.props.currentUserEmail}
            userSignInData={this.props.userSignInData}
            isShipmentGPSLoading={this.props.isShipmentGPSLoading}
            shipmentGPSData={this.props.shipmentGPSData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleMapTrackerContainer));