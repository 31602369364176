import * as types from '../constants/actionsUserTypes';

/* ################################### SIGNIN ACTIONS ################################### */
// gets data for shipment history
export const appSignIn = (_email) =>
({
    type: types.APP_SIGNIN,
    payload: {
        email: _email
    }
});

export const getPageConfig = (_email, _filter) =>
({
    type: types.GET_PAGE_CONFIG,
    payload: {
        email: _email,
        filter: _filter
    }
});


export const userOrganizationGet = (_email, _otherUsersID) =>
({
    type: types.USER_ORGANIZATION_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});

export const userWarehousesGet = (_email, _otherUsersID) =>
({
    type: types.USER_WAREHOUSES_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});

export const userWarehousesPost = (_email, _authToken, _userIdToEdit, _WarehouseID, _admin, _readwrite, _deleteFromWarehouse) =>
({
    type: types.POST_USER_WAREHOUSES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        userIdToEdit: _userIdToEdit,
        WarehouseID: _WarehouseID,
        admin: _admin,
        readwrite: _readwrite,
        deleteFromWarehouse: _deleteFromWarehouse,
    }
});



export const userWarehouseLocationsGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.USER_WAREHOUSE_LOCATIONS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const userAdminCustomersAllGet = (_email, _authToken) =>
({
    type: types.USER_ADMIN_CUSTOMERS_ALL_GET,
    payload: {
        email: _email,
        authToken: _authToken,

    }
});

export const userCustomersGet = (_email, _otherUsersID) =>
({
    type: types.USER_CUSTOMERS_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});


export const userCustomersPost = (_email, _authToken, _userIdToEdit, _CustomerID, _admin, _readwrite, _deleteFromCustomer) =>
({
    type: types.POST_USER_CUSTOMERS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        userIdToEdit: _userIdToEdit,
        CustomerID: _CustomerID,
        admin: _admin,
        readwrite: _readwrite,
        deleteFromCustomer: _deleteFromCustomer,
    }
});


export const userLabelGenCarrierGet = (_email, _otherUsersID) =>
({
    type: types.USER_LABELGEN_CARRIER_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});


export const userLabelGenCarrierPost = (_email, _authToken, _userIdToEdit, _CarrierID, _admin, _readwrite, _deleteFromCarrier) =>
({
    type: types.POST_USER_LABELGEN_CARRIER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        userIdToEdit: _userIdToEdit,
        CarrierID: _CarrierID,
        admin: _admin,
        readwrite: _readwrite,
        deleteFromCarrier: _deleteFromCarrier,
    }
});


export const userLabelGenCarrierServiceGet = (_email, _otherUsersID) =>
({
    type: types.USER_LABELGEN_CARRIER_SERVICE_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});


export const userModulesGet = (_email, _otherUsersID) =>
({
    type: types.USER_MODULES_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});

export const userModulesPost = (_email, _authToken, _userIdToEdit, _ModuleID, _admin, _readwrite, _deleteFromModule) =>
({
    type: types.POST_USER_MODULES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        userIdToEdit: _userIdToEdit,
        ModuleID: _ModuleID,
        admin: _admin,
        readwrite: _readwrite,
        deleteFromModule: _deleteFromModule,
    }
});

export const accountDetailsUserWarehousesGet = (_email, _otherUsersID) =>
({
    type: types.ACCOUNT_DETAILS_USER_WAREHOUSES_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});

export const accountDetailsUserCustomersGet = (_email, _otherUsersID) =>
({
    type: types.ACCOUNT_DETAILS_USER_CUSTOMERS_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});

export const accountDetailsUserModulesGet = (_email, _otherUsersID) =>
({
    type: types.ACCOUNT_DETAILS_USER_MODULES_GET,
    payload: {
        email: _email,

        otherUsersID: _otherUsersID
    }
});


/* ################################### SIGNOUT - CLEAR CACHE ACTIONS ################################### */
export function appSignOut() {
    return {
        type: types.RESET_REDUX_STATE
    };
}

/* ################################### NEW USER SIGNUP ACTIONS ################################### */
// create new user requet
export const addNewUser = (_firstname, _lastname, _email, _customer, _warehouse) =>
({
    type: types.USER_SIGNUP_POST,
    payload: {
        firstname: _firstname,
        lastname: _lastname,
        email: _email,
        customer: _customer,
        warehouse: _warehouse
    }
});

export const getAllUsers = (_email, _authToken, _userEmailToSearch, _userIdToSearch, _ModuleID, _WarehouseID, _CustomerID) =>
({
    type: types.GET_ALL_USERS_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        userEmailToSearch: _userEmailToSearch,
        userIdToSearch: _userIdToSearch,

        ModuleID: _ModuleID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID

    }
});

export const postApproveUser = (_email, _authToken, _userIdToApprove, _admin, _accountApproved, _newUserReadWrite, _ModuleID, _WarehouseID, _CustomerID, _userIdList) =>
({
    type: types.POST_APPROVE_USER_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        userIdToApprove: _userIdToApprove,
        admin: _admin,
        accountApproved: _accountApproved,

        newUserReadWrite: _newUserReadWrite,
        ModuleID: _ModuleID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,

        userIdList: _userIdList
    }
});



export const postDeleteUser = (_email, _authToken, _userIdToDelete) =>
({
    type: types.POST_DELETE_USER_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        userIdToDelete: _userIdToDelete
    }
});


export const userAwsCognitoDeleteUserPost = (_usernameToDelete) =>
({
    type: types.USER_AWS_COGNITO_DELETE_USER_POST_GET,
    payload: {
        usernameToDelete: _usernameToDelete
    }
});

export const userAwsCognitoInviteUserPost = (_inviteEmail) =>
({
    type: types.USER_AWS_COGNITO_INVITE_USER_POST_GET,
    payload: {
        inviteEmail: _inviteEmail
    }
});


export const userInviteNewUserPost = (_email, _authToken, _newUserEmail, _FirstName, _LastName, _userList, _siteAdmin, _admin, _newUserReadWrite, _ModuleID, _WarehouseID, _CustomerID, _OrganizationID,) =>
    ({
        type: types.USER_INVITE_NEW_USER_POST,
        payload: {
            email: _email,
            authToken: _authToken,
    
            newUserEmail: _newUserEmail,
            FirstName: _FirstName,
            LastName: _LastName,
            userList: _userList,
            siteAdmin: _siteAdmin,
            admin: _admin,
            newUserReadWrite: _newUserReadWrite,
            ModuleID: _ModuleID,
            WarehouseID: _WarehouseID,
            CustomerID: _CustomerID,
            OrganizationID: _OrganizationID,
    
        }
    });

    
export const postAccountEdit = (_email, _authToken, _userIdToEdit, _firstName, _lastName, _active, _admin, _userIdList) =>
({
    type: types.POST_USER_ACCOUNT_EDIT_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        userIdToEdit: _userIdToEdit,
        firstName: _firstName,
        lastName: _lastName,
        active: _active,
        admin: _admin,
        userIdList: _userIdList
    }
});





















export const getUserCompany = (_email) =>
({
    type: types.USER_COMPANY_GET,
    payload: {
        email: _email
    }
});


export const getUserStore = (_email) =>
({
    type: types.USER_STORE_GET,
    payload: {
        email: _email
    }
});


export const getUserPOStatus = (_email) =>
({
    type: types.USER_POSTATUS_GET,
    payload: {
        email: _email
    }
});

// get pending user requests
export const getUserAccounts = (_email, _authToken) =>
({
    type: types.USER_ACCOUNT_GET,
    payload: {
        email: _email,
        authToken: _authToken
    }
});




// get pending user requests
export const getPendingUserAccounts = (_email) =>
({
    type: types.PENDING_USER_GET,
    payload: {
        email: _email
    }
});

// get pending user requests
export const postUserUpdate = (_email, _emailToUpdate, _firstname, _lastname, _active, _admin, _pending) =>
({
    type: types.USER_UPDATE_POST,
    payload: {
        email: _email,
        emailToUpdate: _emailToUpdate,
        firstname: _firstname,
        lastname: _lastname,
        active: _active,
        admin: _admin,
        pending: _pending
    }
});





export const postUserPermissions = (_email, _emailToUpdate, _permission, _newPermValue) =>
({
    type: types.USER_PERMISSIONS_POST,
    payload: {
        email: _email,
        emailToUpdate: _emailToUpdate,
        permission: _permission,
        newPermValue: _newPermValue
    }
});






/* ################################### Admin ACTIONS ################################### */
export const AdminNewSeaportPost = (_email, _authToken, _SeaportCode, _Port, _Country, _SeaportCodeID, _delete,) =>
({
    type: types.ADMIN_POST_NEW_SEAPORT_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        SeaportCode: _SeaportCode,
        Port: _Port,
        Country: _Country,
        SeaportCodeID: _SeaportCodeID,
        delete: _delete,
    }
});

export const AdminNewCustomerPost = (_email, _authToken, _CustomerName, _CustomerCode, _CustomerID, _delete,) =>
({
    type: types.ADMIN_POST_NEW_CUSTOMER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerName: _CustomerName,
        CustomerCode: _CustomerCode,
        CustomerID: _CustomerID,
        delete: _delete,
    }
});
