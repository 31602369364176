import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    ButtonGroup,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Tooltip

} from 'rsuite';

import { lettersNumbersDashesSpacesInputMask, limitCharacterLength, textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';

import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import AddToLoadSkuDataElements from '../../../dataComponentsInventory/AddToLoadSkuDataElements';
import * as moment from 'moment'
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const styles = {
    width: '100%'
};


const tooltipSearch = (
    <Tooltip>
        Search
    </Tooltip>
);

const tooltipClear = (
    <Tooltip>
        Clear
    </Tooltip>
);



const mountRef = React.createRef();

const EditLoadDrawer = ({
    onChange,
    placement,
    close,
    onClickSave,
    state,
    ...props }) => (

    <Drawer show={state.showEditLoadDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '655px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit Load</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body > <div ref={mountRef}>


            <Panel bordered style={{ marginBottom: '15px' }}>
                <Row>
                    <Col md={24} sm={24}>
                        <FormGroup >
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Pickup Date:</InputGroup.Addon>
                                <DatePicker
                                    container={() => mountRef.current} oneTap style={styles} cleanable={false} placeholder={(state.editLoadItem.PickupDate === null ? 'Pickup Date' : moment(state.editLoadItem.PickupDate).format('YYYY-MM-DD'))} onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'), 'PickupDate')} />
                            </InputGroup>


                            <InputGroup style={{ marginBottom: '15px', width: '100%' }}>
                                <InputGroup.Addon>Delivery Date:</InputGroup.Addon>
                                <DatePicker
                                    container={() => mountRef.current} style={{ right: '0px!important' }} oneTap style={styles} cleanable={false} placeholder={(state.editLoadItem.DeliveryDate === null ? 'Delivery Date' : moment(state.editLoadItem.DeliveryDate).format('YYYY-MM-DD'))} onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'), 'DeliveryDate')} />
                            </InputGroup>

                            <div style={{ marginBottom: '15px', width: '100%' }}>
                                <InputPicker preventOverflow
                                    container={() => mountRef.current}
                                    block
                                    creatable
                                    placeholder={'Destination...'}
                                    value={state.editLoadItem.Destination}
                                    // onChange={(e, value, label) => { onChangeDestination(e, value, label) }}
                                    onChange={(e) => { onChange(e, 'Destination') }}
                                    data={props.InventoryDestinationData}
                                    cleanable={true}
                                />
                            </div>


                        </FormGroup>
                    </Col>
                    <Col md={12} sm={24}>
                        <FormGroup >

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.Carrier} onChange={(e) => { onChange(e, 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Trailer #:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.Trailer} onChange={(e) => { onChange(e, 'Trailer') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>


                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>MC #:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.MC} onChange={(e) => { onChange(e, 'MC') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>DOT #:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.DOT} onChange={(e) => { onChange(e, 'DOT') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Driver:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.Driver} onChange={(e) => { onChange(e, 'Driver') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>



                        </FormGroup>
                    </Col>
                    <Col md={12} sm={24}>
                        <FormGroup >


                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>BOL #:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.BOL} onChange={(e) => { onChange(e, 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Seal #:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.Seal} onChange={(e) => { onChange(e, 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Truck #:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.Truck} onChange={(e) => { onChange(e, 'Truck') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Truck Plate:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.TruckPlate} onChange={(e) => { onChange(e, 'TruckPlate') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Trailer Plate:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.TrailerPlate} onChange={(e) => { onChange(e, 'TrailerPlate') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                        </FormGroup>
                    </Col>
                </Row>





                <Row>

                    <Col md={24} sm={24}>

                        <FormGroup >

                            <InputGroup style={{ marginBottom: '15px', width: '100%' }}>
                                <InputGroup.Addon>Notes:</InputGroup.Addon>
                                <Input defaultValue={state.editLoadItem.Notes} onChange={(e) => { onChange(e, 'Notes') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 300)} />
                            </InputGroup>

                        </FormGroup>
                    </Col>
                </Row>




                <Row >

                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <DebouncedButton onClick={(e) => { onClickSave(e) }} buttonText="Save" block={true} />
                        </ButtonToolbar>
                    </Col>
                    <Col md={12} sm={24}>
                        <ButtonToolbar >
                            <Button block onClick={(e) => { close(e) }} appearance="default">Cancel</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>

            </Panel>






        </div> </Drawer.Body>
        <Drawer.Footer>

            <br />

        </Drawer.Footer>
    </Drawer>
);



export default EditLoadDrawer;
















