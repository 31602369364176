//TODO make dynamic

////npm imports
import React, {
  PureComponent
} from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withRouter } from 'react-router-dom';
import { Row, Button, Panel, Loader, Card, Col, FlexboxGrid } from 'rsuite';
import CtrlTwrSeaportDataElements from '../../dataComponentsUser/CtrlTwrSeaportDataElements';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import ConfigTable from './ConfigTable';
import CustomersDataElements from '../../dataComponentsUser/CustomersDataElements';
import AddSeaportModal from './AddSeaportModal';
import AddCustomerModal from './AddCustomerModal';
import { formatStringForAPI } from '../../../resources/logic/functions';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")




const panelStyles = {
  height: '420px', marginBottom: 10
};

class AppConfiguration extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

      //Add new seaport modal
      addNewSeaportModalShown: false,
      SeaportCode: '',
      Port: '',
      Country: '',

      //Add new customer modal
      addNewCustomerModalShown: false,
      CustomerName: '',
      CustomerCode: '',





    }

    //Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);


    //Handle Change Function
    this.handleChangeFunc = this.handleChangeFunc.bind(this);

    //Add new seaport modal
    this.onClickOpenSeaportModal = this.onClickOpenSeaportModal.bind(this);
    this.handleSaveSeaportModal = this.handleSaveSeaportModal.bind(this);
    this.handleCloseSeaportModal = this.handleCloseSeaportModal.bind(this);
    this.onExitedSeaportModal = this.onExitedSeaportModal.bind(this);

    //Add new seaport modal
    this.onClickOpenCustomerModal = this.onClickOpenCustomerModal.bind(this);
    this.handleSaveCustomerModal = this.handleSaveCustomerModal.bind(this);
    this.handleCloseCustomerModal = this.handleCloseCustomerModal.bind(this);
    this.onExitedCustomerModal = this.onExitedCustomerModal.bind(this);
  }


  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.AdminNewSeaportPostSuccessful !== this.props.AdminNewSeaportPostSuccessful && this.props.AdminNewSeaportPostSuccessful) {
      this.handleCloseSeaportModal();
    }
    if (prevProps.AdminNewCustomerPostSuccessful !== this.props.AdminNewCustomerPostSuccessful && this.props.AdminNewCustomerPostSuccessful) {
      this.handleCloseCustomerModal();
    }
  }

  getsessionStorageWarehouse() {
    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        warehouseMenuList = warehouseMenuList.filter(x => x.Admin === true)
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }
    try {
      let moduleMenuList = JSON.parse(sessionStorage.getItem('AppConfig.ModuleArray'));
      if (moduleMenuList != null && moduleMenuList != undefined) {
        moduleMenuList = moduleMenuList.filter(x => x.Admin === true)
        this.setState({
          moduleArray: moduleMenuList
        })
      }
    }
    catch (e) { }
    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        customerMenuList = customerMenuList.filter(x => x.Admin === true)
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }
  }



  //Table Sort
  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });
    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }



  //On Change Function
  handleChangeFunc(e, statename) {
    this.setState({
      [statename]: e
    });

  }

  //Add new seaport modal
  onClickOpenSeaportModal() {
    this.setState({
      addNewSeaportModalShown: true,
      SeaportCode: '',
      Port: '',
      Country: '',
    });
  }
  handleSaveSeaportModal() {
    if ((this.state.SeaportCode == null || this.state.SeaportCode == '') || (this.state.Port == null || this.state.Port == '') || (this.state.Country == null || this.state.Country == '')) {
      window.alert('Please enter all data.')
    }
    else {
      this.props.AdminNewSeaportPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatStringForAPI(this.state.SeaportCode), formatStringForAPI(this.state.Port), formatStringForAPI(this.state.Country), null, null)
    }
  }
  handleCloseSeaportModal() {
    this.setState({
      addNewSeaportModalShown: false,
      SeaportCode: '',
      Port: '',
      Country: '',
    });
  }
  onExitedSeaportModal() {
    this.setState({
      addNewSeaportModalShown: false,
      SeaportCode: '',
      Port: '',
      Country: '',
    });
  }




  //Add new Customer modal
  onClickOpenCustomerModal() {
    this.setState({
      addNewCustomerModalShown: true,
      CustomerName: '',
      CustomerCode: '',
    });
  }
  handleSaveCustomerModal() {
    if ((this.state.CustomerName == null || this.state.CustomerName == '') || (this.state.CustomerCode == null || this.state.CustomerCode == '')) {
      window.alert('Please enter all data.')
    }
    else {
      this.props.AdminNewCustomerPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatStringForAPI(this.state.CustomerName), formatStringForAPI(this.state.CustomerCode), null, null)
    }
  }
  handleCloseCustomerModal() {
    this.setState({
      addNewCustomerModalShown: false,
      CustomerName: '',
      CustomerCode: '',
    });
  }
  onExitedCustomerModal() {
    this.setState({
      addNewCustomerModalShown: false,
      CustomerName: '',
      CustomerCode: '',
    });
  }



  render() {
    const { sortColumn, sortType } = this.state;

    let customerTableDiv = ConfigTable(
      this.props.isAdminCustomersAllLoading, // isLoading,
      'CustomerID',  // keyColumn,
      this.props.AdminCustomersAllData,  // tableData,
      CustomersDataElements, // tableDataElements,
      'Customer',  // title,
      currentDateTime,  // currentDateTime,
      this.onClickOpenCustomerModal,  // onClickAddNew,

      this.props.isMobile,  // isMobile,
      this.props.history, // history,

      sortColumn, // sortColumn,
      sortType, // sortType,
      this.handleSortColumn,  // handleSortColumn,
      this.state, // state, 
      this.props  // ...props
    )


    let seaportTableDiv = ConfigTable(
      this.props.isCtrlTwrSeaportLoading, // isLoading,
      'SeaportCodeID',  // keyColumn,
      this.props.CtrlTwrSeaportData,  // tableData,
      CtrlTwrSeaportDataElements, // tableDataElements,
      'Seaport',  // title,
      currentDateTime,  // currentDateTime,
      this.onClickOpenSeaportModal,  // onClickAddNew,

      this.props.isMobile,  // isMobile,
      this.props.history, // history,

      sortColumn, // sortColumn,
      sortType, // sortType,
      this.handleSortColumn,  // handleSortColumn,
      this.state, // state, 
      this.props  // ...props
    )



    let layoutDiv;
    layoutDiv = <div>
      <Row>
        <Col md={12} sm={24}>
          <Panel bordered style={panelStyles}>
            {customerTableDiv}
          </Panel>
        </Col>

        <Col md={12} sm={24}>
          <Panel bordered style={panelStyles}>
            {seaportTableDiv}
          </Panel>
        </Col>
      </Row>
    </div>





    return (

      <div>
        <h3>
          App Configuration
        </h3>

        <br />

        {layoutDiv}


        <AddSeaportModal {...this.props} state={this.state}
          addNewModalShown={this.state.addNewSeaportModalShown}
          handleSave={this.handleSaveSeaportModal}
          handleClose={this.handleCloseSeaportModal}
          onExited={this.onExitedSeaportModal}

          onChangeFunc={this.handleChangeFunc}
        />


        <AddCustomerModal {...this.props} state={this.state}
          addNewModalShown={this.state.addNewCustomerModalShown}
          handleSave={this.handleSaveCustomerModal}
          handleClose={this.handleCloseCustomerModal}
          onExited={this.onExitedCustomerModal}

          onChangeFunc={this.handleChangeFunc}
        />



      </div>
    );
  }
}

export default withRouter(AppConfiguration);
