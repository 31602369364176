import * as types from '../constants/ctrlTwrActionTypes';





/* ################################### CTRLTWR ACTIONS ################################### */


export const ctrlTwrGenerateProGet = (_email, _authToken) =>
({
    type: types.CTRLTWR_GENERATE_PRO_GET,
    payload: {
        email: _email,
        authToken: _authToken
    }
});



export const ctrlTwrModeRefGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.CTRLTWR_MODE_REF_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const ctrlTwrAirportGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.CTRLTWR_AIRPORT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const ctrlTwrSeaportGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.CTRLTWR_SEAPORT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const ctrlTwrCountryGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.CTRLTWR_COUNTRY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});

export const ctrlTwrStateGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.CTRLTWR_STATE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});


export const ctrlTwrMilestoneRefGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.CTRLTWR_MILESTONE_REF_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});


export const ctrlTwrShipmentGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert) =>
({
    type: types.CTRLTWR_SHIPMENT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        OriginCountryID: _OriginCountryID,
        PO: _PO,
        ShipmentClosed: _ShipmentClosed,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        HotShipment: _HotShipment,
        Subscription: _Subscription,
        LateShipment: _LateShipment,
        ShipmentAlert: _ShipmentAlert,
    }
});

export const ctrlTwrShipmentPost = (_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity,) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        CustomerID: _CustomerID,
        OriginCountryID: _OriginCountryID,
        PO: _PO,
        ETD: _ETD,
        HotShipment: _HotShipment,

        Pallets: _Pallets,
        Cases: _Cases,
        UnitCount: _UnitCount,
        WeightUnits: _WeightUnits,

        DestinationCountryID: _DestinationCountryID,
        SpecialRequirements: _SpecialRequirements,
        GrossWeight: _GrossWeight,
        ChargeableWeight: _ChargeableWeight,
        Length: _Length,
        Width: _Width,
        Height: _Height,
        DimUnits: _DimUnits,
        Commodity: _Commodity,
    }
});

export const ctrlTwrQuotingShipmentGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _QuotingStatusID,) =>
({
    type: types.CTRLTWR_QUOTING_SHIPMENT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        OriginCountryID: _OriginCountryID,
        PO: _PO,
        ShipmentClosed: _ShipmentClosed,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        QuotingStatusID: _QuotingStatusID,
    }
});

export const ctrlTwrQuotingShipmentPost = (_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _ShipmentQuoteActive, _QuotingStatusID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity,) =>
({
    type: types.CTRLTWR_POST_QUOTING_SHIPMENT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        CustomerID: _CustomerID,
        OriginCountryID: _OriginCountryID,
        PO: _PO,
        ETD: _ETD,
        HotShipment: _HotShipment,

        Pallets: _Pallets,
        Cases: _Cases,
        UnitCount: _UnitCount,
        WeightUnits: _WeightUnits,

        DestinationCountryID: _DestinationCountryID,

        ShipmentQuoteActive: _ShipmentQuoteActive,
        QuotingStatusID: _QuotingStatusID,

        SpecialRequirements: _SpecialRequirements,
        GrossWeight: _GrossWeight,
        ChargeableWeight: _ChargeableWeight,
        Length: _Length,
        Width: _Width,
        Height: _Height,
        DimUnits: _DimUnits,
        Commodity: _Commodity,
    }
});

export const ctrlTwrQuotingShipmentStatusActionGet = (_email, _authToken, _ProRef, _ShipmentQuoteActive, _QuotingStatusID) =>
({
    type: types.CTRLTWR_POST_QUOTING_SHIPMENT_STATUS_ACTION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        ShipmentQuoteActive: _ShipmentQuoteActive,
        QuotingStatusID: _QuotingStatusID,

    }
});


export const ctrlTwrQuotingShipmentLegsPost = (_email, _authToken, _LegID, _DeleteLeg, _ProRef, _LegNumber, _ModeID, _Carrier, _Shipper_Name, _Origin_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode,) =>
({
    type: types.CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LegID: _LegID,
        DeleteLeg: _DeleteLeg,
        ProRef: _ProRef,
        LegNumber: _LegNumber,
        ModeID: _ModeID,
        Carrier: _Carrier,

        Shipper_Name: _Shipper_Name,
        Origin_Name: _Origin_Name,
        Origin_AddressLine: _Origin_AddressLine,
        Origin_City: _Origin_City,
        Origin_State: _Origin_State,
        Origin_PostalCode: _Origin_PostalCode,
        Origin_CountryCode: _Origin_CountryCode,
        Consignee_Name: _Consignee_Name,
        Destination_Name: _Destination_Name,
        Destination_AddressLine: _Destination_AddressLine,
        Destination_City: _Destination_City,
        Destination_State: _Destination_State,
        Destination_PostalCode: _Destination_PostalCode,
        Destination_CountryCode: _Destination_CountryCode,
    }
});

export const ctrlTwrQuotingShipmentLegsQuoteGet = (_email, _authToken, _ProRef) =>
({
    type: types.CTRLTWR_QUOTING_SHIPMENT_LEGS_QUOTE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,

    }
});


export const ctrlTwrQuotingLineItemGet = (_email, _authToken, _ModeID, _QuoteLineItemID) =>
({
    type: types.CTRLTWR_QUOTING_LINE_ITEM_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ModeID: _ModeID,
        QuoteLineItemID: _QuoteLineItemID,

    }
});

export const ctrlTwrQuotingLineItemUomGet = (_email, _authToken, _QuoteLineItemUomID) =>
({
    type: types.CTRLTWR_QUOTING_LINE_ITEM_UOM_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        QuoteLineItemUomID: _QuoteLineItemUomID,

    }
});

export const ctrlTwrQuotingLineItemRateTypeGet = (_email, _authToken, _QuoteLineItemRateTypeID) =>
({
    type: types.CTRLTWR_QUOTING_LINE_ITEM_RATE_TYPE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        QuoteLineItemRateTypeID: _QuoteLineItemRateTypeID,

    }
});

export const ctrlTwrQuotingShipmentLineItemsGet = (_email, _authToken, _ProRef, _LegID, _LegLineItemID,) =>
({
    type: types.CTRLTWR_QUOTING_SHIPMENT_LEGS_LINE_ITEMS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        LegID: _LegID,
        LegLineItemID: _LegLineItemID,

    }
});




export const ctrlTwrQuotingShipmentLegsLineItemPost = (_email, _authToken, _LegLineItemID, _LegID, _QuoteLineItemID, _ProRef, _LegNumber, _ModeID, _DeleteLineItem, _SellRate, _BuyRate, _QuoteLineItemUom, _QuoteLineItemRateType, _LineItemNotes, _LineItemSeq ) =>
({
    type: types.CTRLTWR_POST_QUOTING_SHIPMENT_LEGS_LINE_ITEM_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LegLineItemID: _LegLineItemID,
        LegID: _LegID,
        QuoteLineItemID: _QuoteLineItemID,
        ProRef: _ProRef,
        LegNumber: _LegNumber,
        ModeID: _ModeID,
        DeleteLineItem: _DeleteLineItem,
        SellRate: _SellRate,
        BuyRate: _BuyRate,
        QuoteLineItemUom: _QuoteLineItemUom,
        QuoteLineItemRateType: _QuoteLineItemRateType,
        LineItemNotes: _LineItemNotes,
        LineItemSeq: _LineItemSeq



    }
});




export const ctrlTwrQuotingShipmentFileUploadGet = (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.CTRLTWR_QUOTING_SHIPMENT_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        ProRef: _ProRef,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});


export const ctrlTwrQuotingShipmentFileUploadPost = (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete, _MoveFileToCtrlTwr) =>
({
    type: types.CTRLTWR_POST_QUOTING_SHIPMENT_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        ProRef: _ProRef,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,
        MoveFileToCtrlTwr: _MoveFileToCtrlTwr

    }
});

export const ctrlTwrQuotingShipmentPrivateNotesGet = (_email, _authToken, _ShipmentNoteID, _ProRef) =>
({
    type: types.CTRLTWR_QUOTING_SHIPMENT_NOTES_PRIVATE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentNoteID: _ShipmentNoteID,
        ProRef: _ProRef,
    }
});

export const ctrlTwrQuotingShipmentPublicNotesGet = (_email, _authToken, _ShipmentNoteID, _ProRef) =>
({
    type: types.CTRLTWR_QUOTING_SHIPMENT_NOTES_PUBLIC_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentNoteID: _ShipmentNoteID,
        ProRef: _ProRef,
    }
});


export const ctrlTwrQuotingReportGet = (_email, _authToken, _WarehouseID, _CustomerID, _DateStart, _DateEnd, _ShipmentClosed, _QuotingStatusID,) =>
({
    type: types.CTRLTWR_QUOTING_REPORT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        ShipmentClosed: _ShipmentClosed,
        QuotingStatusID: _QuotingStatusID,

    }
});


export const ctrlTwrShipmentPrivateNotesGet = (_email, _authToken, _ShipmentNoteID, _ProRef) =>
({
    type: types.CTRLTWR_SHIPMENT_NOTES_PRIVATE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentNoteID: _ShipmentNoteID,
        ProRef: _ProRef,
    }
});

export const ctrlTwrShipmentPrivateNotesPost = (_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_NOTES_PRIVATE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentNoteID: _ShipmentNoteID,
        ProRef: _ProRef,
        PrivateNote: _PrivateNote,
        Delete: _Delete,
    }
});

export const ctrlTwrShipmentPublicNotesGet = (_email, _authToken, _ShipmentNoteID, _ProRef) =>
({
    type: types.CTRLTWR_SHIPMENT_NOTES_PUBLIC_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentNoteID: _ShipmentNoteID,
        ProRef: _ProRef,
    }
});

export const ctrlTwrShipmentPublicNotesPost = (_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_NOTES_PUBLIC_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentNoteID: _ShipmentNoteID,
        ProRef: _ProRef,
        PublicNote: _PublicNote,
        Delete: _Delete,
    }
});

export const ctrlTwrShipmentLegsPost = (_email, _authToken, _ProRef, _LegNumber, _ModeID, _DataJson, _LegID, _DeleteLeg,) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_LEGS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        LegNumber: _LegNumber,
        ModeID: _ModeID,
        DataJson: _DataJson,
        LegID: _LegID,
        DeleteLeg: _DeleteLeg,

    }
});

export const ctrlTwrShipmentLegsDataGet = (_email, _authToken, _ProRef) =>
({
    type: types.CTRLTWR_SHIPMENT_LEGS_DATA_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,

    }
});

export const ctrlTwrShipmentLegsDataPost = (_email, _authToken, _ProRef, _LegNumber, _ModeID, _LegID, _WarehouseID, _BOL, _MBOL, _HBOL, _PO, _Container, _Carrier, _Seal, _PlannedDepartureDate, _EstimatedDeliveryDate, _PickupAddress, _DeliveryAddress, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _CustomerRef, _Shipper_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode, _PickupAppointmentTime, _DeliveryAppointmentTime) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_LEGS_DATA_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        LegNumber: _LegNumber,
        ModeID: _ModeID,
        LegID: _LegID,
        WarehouseID: _WarehouseID,
        BOL: _BOL,
        MBOL: _MBOL,
        HBOL: _HBOL,
        PO: _PO,
        Container: _Container,
        Carrier: _Carrier,
        Seal: _Seal,

        PlannedDepartureDate: _PlannedDepartureDate,
        EstimatedDeliveryDate: _EstimatedDeliveryDate,

        // EstimatedPickupDate: _EstimatedPickupDate,
        PickupAddress: _PickupAddress,
        DeliveryAddress: _DeliveryAddress,
        Trailer: _Trailer,
        Vessel: _Vessel,
        // SailingDate: _SailingDate,
        // SailingEstimatedArrivalDate: _SailingEstimatedArrivalDate,
        SeaportOriginID: _SeaportOriginID,
        SeaportDestID: _SeaportDestID,
        Flight: _Flight,
        // FlightETD: _FlightETD,
        // FlightETA: _FlightETA,
        AirportOriginID: _AirportOriginID,
        AirportDestID: _AirportDestID,
        CustomerRef: _CustomerRef,

        Shipper_Name: _Shipper_Name,
        Origin_AddressLine: _Origin_AddressLine,
        Origin_City: _Origin_City,
        Origin_State: _Origin_State,
        Origin_PostalCode: _Origin_PostalCode,
        Origin_CountryCode: _Origin_CountryCode,
        Consignee_Name: _Consignee_Name,
        Destination_AddressLine: _Destination_AddressLine,
        Destination_City: _Destination_City,
        Destination_State: _Destination_State,
        Destination_PostalCode: _Destination_PostalCode,
        Destination_CountryCode: _Destination_CountryCode,

        PickupAppointmentTime: _PickupAppointmentTime,
        DeliveryAppointmentTime: _DeliveryAppointmentTime
    }
});

export const ctrlTwrShipmentLegsMilestonesGet = (_email, _authToken, _ProRef, _LegID) =>
({
    type: types.CTRLTWR_SHIPMENT_LEGS_MILESTONES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        LegID: _LegID

    }
});


export const ctrlTwrShipmentLegsMilestonesPost = (_email, _authToken, _ShipmentLegMilestoneID, _ProRef, _LegID, _ModeID, _MilestoneID, _MilestoneDT, _MilestoneTimezone, _CountryCodeID, _City, _State, _Zip, _SeaportCodeID, _AirportCodeID, _WarehouseID, _DeleteMilestone, _StateCodeID) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentLegMilestoneID: _ShipmentLegMilestoneID,
        ProRef: _ProRef,
        LegID: _LegID,
        ModeID: _ModeID,
        MilestoneID: _MilestoneID,
        MilestoneDT: _MilestoneDT,
        MilestoneTimezone: _MilestoneTimezone,
        CountryCodeID: _CountryCodeID,
        City: _City,
        State: _State,
        Zip: _Zip,
        SeaportCodeID: _SeaportCodeID,
        AirportCodeID: _AirportCodeID,
        WarehouseID: _WarehouseID,
        DeleteMilestone: _DeleteMilestone,
        StateCodeID: _StateCodeID

    }
});

export const ctrlTwrShipmentLegsMilestonesMultiPost = (_email, _authToken, _ShipmentLegMilestoneID, _ProRef, _LegIDs, _ModeID, _MilestoneID, _MilestoneDT, _MilestoneTimezone, _CountryCodeID, _City, _State, _Zip, _SeaportCodeID, _AirportCodeID, _WarehouseID, _DeleteMilestone, _StateCodeID) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_LEGS_MILESTONES_MULTI_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ShipmentLegMilestoneID: _ShipmentLegMilestoneID,
        ProRef: _ProRef,
        LegIDs: _LegIDs,
        ModeID: _ModeID,
        MilestoneID: _MilestoneID,
        MilestoneDT: _MilestoneDT,
        MilestoneTimezone: _MilestoneTimezone,
        CountryCodeID: _CountryCodeID,
        City: _City,
        State: _State,
        Zip: _Zip,
        SeaportCodeID: _SeaportCodeID,
        AirportCodeID: _AirportCodeID,
        WarehouseID: _WarehouseID,
        DeleteMilestone: _DeleteMilestone,
        StateCodeID: _StateCodeID

    }
});


export const ctrlTwrShipmentLegsMilestonesMultiGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _ShipmentClosed, _ModeID, _LegID, _Container, _Carrier, _Seal, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _OriginCountryID,) =>
({
    type: types.CTRLTWR_SHIPMENT_LEGS_MILESTONES_MULTI_DATA_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        PO: _PO,
        ShipmentClosed: _ShipmentClosed,
        ModeID: _ModeID,
        LegID: _LegID,
        Container: _Container,
        Carrier: _Carrier,
        Seal: _Seal,
        Trailer: _Trailer,
        Vessel: _Vessel,
        SeaportOriginID: _SeaportOriginID,
        SeaportDestID: _SeaportDestID,
        Flight: _Flight,
        AirportOriginID: _AirportOriginID,
        AirportDestID: _AirportDestID,
        OriginCountryID: _OriginCountryID,

    }
});



export const ctrlTwrShipmentLegsCustomerRefGet = (_email, _authToken, _ProRef, _LegID, _HbolID) =>
({
    type: types.CTRLTWR_SHIPMENT_LEGS_CUSTOMERREF_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        LegID: _LegID,
        HbolID: _HbolID,

    }
});



export const ctrlTwrShipmentLegsPoGet = (_email, _authToken, _ProRef, _LegID, _HbolID) =>
({
    type: types.CTRLTWR_SHIPMENT_LEGS_PO_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        LegID: _LegID,
        HbolID: _HbolID,

    }
});


export const ctrlTwrShipmentLegsHbolGet = (_email, _authToken, _ProRef, _LegID) =>
({
    type: types.CTRLTWR_SHIPMENT_LEGS_HBOL_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        LegID: _LegID

    }
});


export const ctrlTwrShipmentLegsCustomerRefPost = (_email, _authToken, _CustomerRefID, _LegID, _HbolID, _CustomerRef, _Delete) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_LEGS_CUSTOMERREF_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerRefID: _CustomerRefID,
        LegID: _LegID,
        HbolID: _HbolID,
        CustomerRef: _CustomerRef,
        Delete: _Delete,

    }
});


export const ctrlTwrShipmentLegsHbolPost = (_email, _authToken, _HbolID, _LegID, _HBOL, _Delete) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_LEGS_HBOL_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HbolID: _HbolID,
        LegID: _LegID,
        HBOL: _HBOL,
        Delete: _Delete,

    }
});


export const ctrlTwrShipmentLegsPoPost = (_email, _authToken, _HbolID, _LegID, _PoID, _PO, _Delete) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_LEGS_PO_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HbolID: _HbolID,
        LegID: _LegID,
        PoID: _PoID,
        PO: _PO,
        Delete: _Delete,

    }
});


export const ctrlTwrTimezoneGet = (_email, _authToken) =>
({
    type: types.CTRLTWR_TIMEZONE_GET,
    payload: {
        email: _email,
        authToken: _authToken,
    }
});

export const ctrlTwrAlertsShipmentDepartureGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed,) =>
({
    type: types.CTRLTWR_ALERTS_SHIPMENT_DEPARTURE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        OriginCountryID: _OriginCountryID,
        PO: _PO,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        ShipmentClosed: _ShipmentClosed,

    }
});

export const ctrlTwrAlertsShipmentDeliveryGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed,) =>
({
    type: types.CTRLTWR_ALERTS_SHIPMENT_DELIVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        OriginCountryID: _OriginCountryID,
        PO: _PO,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        ShipmentClosed: _ShipmentClosed,

    }
});

export const ctrlTwrAlertsShipmentSummaryGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed,) =>
({
    type: types.CTRLTWR_ALERTS_SHIPMENT_SUMMARY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        OriginCountryID: _OriginCountryID,
        PO: _PO,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        ShipmentClosed: _ShipmentClosed,

    }
});


export const ctrlTwrAlertsStatsGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _ModeID, _Carrier, _OriginCountryID, _DateStart, _DateEnd, _ShipmentClosed, _Filter,) =>
({
    type: types.CTRLTWR_ALERTS_STATS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        PO: _PO,
        ModeID: _ModeID,
        Carrier: _Carrier,
        OriginCountryID: _OriginCountryID,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        ShipmentClosed: _ShipmentClosed,
        Filter: _Filter,
    }
});




export const ctrlTwrHotShipmentPost = (_email, _authToken, _ProRef, _HotShipment) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_HOTSHIPMENT_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        HotShipment: _HotShipment,

    }
});

export const ctrlTwrShipmentUserSubscriptionPost = (_email, _authToken, _ProRef, _Active) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_USER_SUBSCRIPTION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ProRef: _ProRef,
        Active: _Active,

    }
});

export const ctrlTwrChartShipmentDeliveryGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _ShipmentClosed, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData,) =>
({
    type: types.CTRLTWR_CHART_SHIPMENT_DELIVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        PO: _PO,
        OriginCountryID: _OriginCountryID,
        ModeID: _ModeID,
        Carrier: _Carrier,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        ShipmentClosed: _ShipmentClosed,
        HotShipment: _HotShipment,
        Subscription: _Subscription,
        LateShipment: _LateShipment,
        ShipmentAlert: _ShipmentAlert,
        ChartDetailData: _ChartDetailData,

    }
});

export const ctrlTwrChartShipmentDeliveryDetailsGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _ShipmentClosed, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData,) =>
({
    type: types.CTRLTWR_CHART_SHIPMENT_DELIVERY_DETAILS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        PO: _PO,
        OriginCountryID: _OriginCountryID,
        ModeID: _ModeID,
        Carrier: _Carrier,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        ShipmentClosed: _ShipmentClosed,
        HotShipment: _HotShipment,
        Subscription: _Subscription,
        LateShipment: _LateShipment,
        ShipmentAlert: _ShipmentAlert,
        ChartDetailData: _ChartDetailData,

    }
});





export const ctrlTwrChartShipmentDeliveryHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData,) =>
({
    type: types.CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        PO: _PO,
        OriginCountryID: _OriginCountryID,
        ModeID: _ModeID,
        Carrier: _Carrier,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        HotShipment: _HotShipment,
        Subscription: _Subscription,
        LateShipment: _LateShipment,
        ShipmentAlert: _ShipmentAlert,
        ChartDetailData: _ChartDetailData,
    }
});

export const ctrlTwrChartShipmentDeliveryHistoryDetailsGet = (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData,) =>
({
    type: types.CTRLTWR_CHART_SHIPMENT_DELIVERY_HISTORY_DETAILS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ProRef: _ProRef,
        PO: _PO,
        OriginCountryID: _OriginCountryID,
        ModeID: _ModeID,
        Carrier: _Carrier,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        HotShipment: _HotShipment,
        Subscription: _Subscription,
        LateShipment: _LateShipment,
        ShipmentAlert: _ShipmentAlert,
        ChartDetailData: _ChartDetailData,
    }
});



export const ctrlTwrShipmentFileUploadGet = (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.CTRLTWR_SHIPMENT_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        ProRef: _ProRef,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});


export const ctrlTwrShipmentFileUploadPost = (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.CTRLTWR_POST_SHIPMENT_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        ProRef: _ProRef,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});







export const downloadFileFromS3 = (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) =>
({
    type: types.DOWNLOAD_FILE_FROM_S3_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ObjectName: _ObjectName,
        FilenameToSave: _FilenameToSave,
        S3Bucket: _S3Bucket

    }
});





export const edi990QueueGet = (_email, _authToken, _QueueStatus, _CustomerID,) =>
({
    type: types.EDI_990_QUEUE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        QueueStatus: _QueueStatus,
        CustomerID: _CustomerID,

    }
});

export const edi990QueuePost = (_email, _authToken, _CustomerID, _AcceptDecline, _ID, _HdrId204, _B101_SCAC, _N902_ProRef, _SE02_ControlNumber, _QueueStatus,) =>
({
    type: types.EDI_POST_990_QUEUE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        AcceptDecline: _AcceptDecline,
        ID: _ID,
        HdrId204: _HdrId204,
        B101_SCAC: _B101_SCAC,
        N902_ProRef: _N902_ProRef,
        SE02_ControlNumber: _SE02_ControlNumber,
        QueueStatus: _QueueStatus,

    }
});


export const edi204Get = (_email, _authToken, _HdrID, _LoadTenderNo, _CustomerID, _QueueStatus, _LoadDtStart, _LoadDtEnd,) =>
({
    type: types.EDI_204_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HdrID: _HdrID,
        LoadTenderNo: _LoadTenderNo,
        CustomerID: _CustomerID,
        QueueStatus: _QueueStatus,
        LoadDtStart: _LoadDtStart,
        LoadDtEnd: _LoadDtEnd,

    }
});

export const edi204OIDGet = (_email, _authToken, _HdrId, _LoadTenderNo, _ProRef,) =>
({
    type: types.EDI_204_OID_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HdrId: _HdrId,
        LoadTenderNo: _LoadTenderNo,
        ProRef: _ProRef,

    }
});

export const edi204L5Get = (_email, _authToken, _HdrId, _LoadTenderNo, _ProRef,) =>
({
    type: types.EDI_204_L5_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HdrId: _HdrId,
        LoadTenderNo: _LoadTenderNo,
        ProRef: _ProRef,

    }
});

