
export default function RTable_HeightFunction(tableLength) {
    var tableHeight = 200
    if (tableLength < 1) {
      tableHeight = 90
    }
    else if (tableLength < 2) {
      tableHeight = 100
    }
    else if (tableLength < 3) {
      tableHeight = 200
    }
    else if (tableLength < 5) {
      tableHeight = 260
    }
    else if (tableLength < 6) {
      tableHeight = 300
    }
    else if (tableLength < 7) {
      tableHeight = 360
    }
    else if (tableLength < 10) {
      tableHeight = 600
    }
    else if (tableLength < 15) {
      tableHeight = 600
    }
    else {
      tableHeight = 600
    }

    return tableHeight
}
