import React, {
  PureComponent
} from 'react';
import { Table, Checkbox, Button } from 'rsuite';
import { sortData } from '../../../../resources/logic/functions';
import { appSettings } from '../../../../app/appSettings';
import RTable_HeightFunction from './RTable_HeightFunction';

const { Column, HeaderCell, Cell, Loader } = Table;

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    {/* <div style={{ lineHeight: '46px' }}> */}
    <div style={{ marginTop: '-7px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);



function RTableGeneralThreeButton(
  keyColumn,
  tableData,
  DataElements,
  loading,

  isMobile,
  history,

  sortColumn,
  sortType,
  handleSortColumn,

  checkColEntry,
  checkedKeys,
  handleCheckAll,
  handleCheck,

  boolSelectButton,
  onClickSelect,
  selectButtonText,
  selectHighlightRowKey,

  link1bool,
  link1text,
  link1onClick,

  link2bool,
  link2text,
  link2onClick,

  link3bool,
  link3text,
  link3onClick
) {

  let rTable;

  if (loading) {
    rTable = <div align="center">
      <section className="panel">
      </section>
      <Loader
        type="TailSpin"
        color="#3396E5"
        height="50"
        width="50"
      />
    </div>
  }



  else if (typeof (tableData) !== 'undefined' && tableData != null) {
    try {
      if (sortColumn && sortType) {
        sortData(tableData, sortColumn, sortType)
      }
    }
    catch (e) { }

    let selectButtonCol;
    if (boolSelectButton) {
      try {
        selectButtonCol =
          <Column width={(isMobile ? 80 : 100)} fixed="left">
            <HeaderCell></HeaderCell>
            <Cell>
              {rowData => {
                return (
                  <span >
                    <Button appearance={rowData[keyColumn] == selectHighlightRowKey ? "selected" : "primary"} size={isMobile ? 'xs' : 'sm'} style={{ marginTop: '-6px' }} onClick={(e) => onClickSelect(e, rowData, history)}>{selectButtonText}</Button>
                  </span>
                );
              }}
            </Cell>
          </Column>
      }
      catch (e) { }
    }
    else { }

    let checkColumn;
    try {
      if (checkColEntry && tableData.length > 0) {

        let checked = false;
        let indeterminate = false;
        if (checkedKeys.length === tableData.length) {
          checked = true;
        } else if (checkedKeys.length === 0) {
          checked = false;
        } else if (checkedKeys.length > 0 && checkedKeys.length < tableData.length) {
          indeterminate = true;
        }

        checkColumn =
          <Column width={50} align="center" fixed="left">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ marginTop: '-9px' }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={(e, value) => handleCheckAll(value, checked, tableData)}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey={keyColumn}
              checkedKeys={checkedKeys}
              onChange={handleCheck}
            />
          </Column>


      }
      else { }

    }
    catch (e) { }






    let tableColumns;
    tableColumns = Object.keys(DataElements).map(function (key) {

      var primaryCol = false
      var sortableCol = false
      try {
        if (DataElements[key]["primaryCol"]) {
          primaryCol = true
        }
      }
      catch (e) { }
      try {
        if (DataElements[key]["sortable"]) {
          sortableCol = true
        }
      }
      catch (e) { }

      if (!DataElements[key]["mobileDisplay"] && isMobile) {

      }
      else {
        return (
          <Column width={DataElements[key]["colWidth"]} fixed={primaryCol} sortable={sortableCol}>
            <HeaderCell>{DataElements[key]["displayName"]}</HeaderCell>
            <Cell dataKey={DataElements[key]["dBKey"]} >

              {rowData => (rowData[DataElements[key]["dBKey"]] === true ? "True" : (rowData[DataElements[key]["dBKey"]] === false ? "False" : rowData[DataElements[key]["dBKey"]]))}

            </Cell>
          </Column>
        )

      }
    })

    var tableLength = tableData.length
    var tableHeight = RTable_HeightFunction(tableLength)



    rTable = <div>

      <Table
        wordWrap
        height={tableHeight}
        data={tableData}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        
        virtualized
      >

        {selectButtonCol}

        {checkColumn}

        {tableColumns}

        {link1bool === true ?

          <Column width={(isMobile ? 60 : (link2bool ? 200 : 100))} fixed="right">
            <HeaderCell></HeaderCell>
            <Cell>
              {rowData => {

                return (
                  <span>
                    <a style={{ cursor: 'pointer' }} onClick={(e) => link1onClick(e, rowData, history)}> {link1text} </a>
                    {link2bool ? '|' : ''}
                    {link2bool ? <a style={{ cursor: 'pointer' }} onClick={(e) => { link2onClick(e, rowData, keyColumn); }}> {link2text} </a> : ''}

                    {link3bool ? '|' : ''}
                    {link3bool ? <a style={{ cursor: 'pointer' }} onClick={(e) => { link3onClick(e, rowData, keyColumn); }}> {link3text} </a> : ''}
                  </span>
                );
              }}
            </Cell>
          </Column>
          : ''
        }
      </Table>
    </div>

    try {
      if (tableData[0]['MSG']) {
        rTable = <div></div>
      }
    }
    catch (e) { }


  }
  else {
    rTable = <div></div>
  }


  return (

    rTable

  )
}


export default RTableGeneralThreeButton;
