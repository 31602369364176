import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../../components/componentsGeneral/AnimatedView/AnimatedView';
import WarehouseLocationDetailsContainer from '../../../../components/componentsInventoryManagement/_Non_Lot_Tracking_Components/WarehouseLocationDetails/WarehouseLocationDetailsContainer';
import { Message } from 'rsuite';

class invManWarehouseLocationDetailsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    componentDidMount() {

    }

    render() {

        if (String(this.props.warehouseSelection).includes('1')) {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="This view cannot be used with warehouses that have migrated to Lot Tracking" /></AnimatedView>;
        }

        return (
            <AnimatedView>
                <WarehouseLocationDetailsContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default invManWarehouseLocationDetailsView;
