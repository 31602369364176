import * as types from '../constants/transActionTypes';





/* ################################### TRANS ACTIONS ################################### */


export const transConsigneeGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.TRANS_CONSIGNEE_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const transCustomerRefGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.TRANS_CUSTOMERREF_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const transProjectGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.TRANS_PROJECT_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});

export const transDestinationGet = (_email, _authToken, _WarehouseID, _CustomerID) =>
({
    type: types.TRANS_DESTINATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID
    }
});


export const transContainerGet = (_email, _authToken, _ContainerID, _Container, _ContainerStatusID, _LCLFCL, _DeliveryAppointmentStart, _DeliveryAppointmentEnd, _ReceivedDTStart, _ReceivedDTEnd, _EtdStart, _EtdEnd, _EtaStart, _EtaEnd, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) =>
({
    type: types.CONTAINER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        ContainerStatusID: _ContainerStatusID,
        LCLFCL: _LCLFCL,
        DeliveryAppointmentStart: _DeliveryAppointmentStart,
        DeliveryAppointmentEnd: _DeliveryAppointmentEnd,
        ReceivedDTStart: _ReceivedDTStart,
        ReceivedDTEnd: _ReceivedDTEnd,
        EtdStart: _EtdStart,
        EtdEnd: _EtdEnd,
        EtaStart: _EtaStart,
        EtaEnd: _EtaEnd,

        MbolID: _MbolID,
        MBOL: _MBOL,
        HbolID: _HbolID,
        HBOL: _HBOL,
        PoID: _PoID,
        PO: _PO,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,


    }
});



export const transContainerPost = (_email, _authToken, _ContainerID, _Container, _LCLFCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID) =>
({
    type: types.POST_CONTAINER_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        LCLFCL: _LCLFCL,
        DeliveryAppointment: _DeliveryAppointment,
        ETD: _ETD,
        ETA: _ETA,
        CurrentContainerStatusID: _CurrentContainerStatusID,
        Notes: _Notes,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,


    }
});


export const transContainerJsonTreeGet = (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) =>
({
    type: types.CONTAINER_JSON_TREE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        MbolID: _MbolID,
        MBOL: _MBOL,
        HbolID: _HbolID,
        HBOL: _HBOL,
        PoID: _PoID,
        PO: _PO,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,

    }
});


export const transContainerHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container) =>
({
    type: types.TRANS_CONTAINER_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        ContainerID: _ContainerID,
        Container: _Container


    }
});



export const transShipmentReceiveGet = (_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _WarehouseID, _CustomerID,) =>
({
    type: types.SHIPMENT_RECEIVE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        MbolID: _MbolID,
        HbolID: _HbolID,
        PoID: _PoID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,


    }
});


export const transShipmentReceivePost = (_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _CaseCountExpected, _CasesReceived, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID,) =>
({
    type: types.POST_SHIPMENT_RECEIVE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        MbolID: _MbolID,
        HbolID: _HbolID,
        PoID: _PoID,
        CaseCountExpected: _CaseCountExpected,
        CasesReceived: _CasesReceived,
        Pallets: _Pallets,
        Notes: _Notes,
        CustomsCleared: _CustomsCleared,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,


    }
});



export const transMbolPost = (_email, _authToken, _MbolID, _MBOL, _ContainerID, _Delete, _WarehouseID, _CustomerID,) =>
({
    type: types.POST_MBOL_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        MbolID: _MbolID,
        MBOL: _MBOL,
        ContainerID: _ContainerID,
        Delete: _Delete,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,



    }
});

export const transHbolPost = (_email, _authToken, _HbolID, _HBOL, _MbolID, _Delete, _WarehouseID, _CustomerID,) =>
({
    type: types.POST_HBOL_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        HbolID: _HbolID,
        HBOL: _HBOL,
        MbolID: _MbolID,
        Delete: _Delete,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID



    }
});


export const transLoadGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _MbolID, _HbolID, _PoID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal) =>
({
    type: types.TRANS_LOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        LoadStatusID: _LoadStatusID,
        ContainerID: _ContainerID,
        MbolID: _MbolID,
        HbolID: _HbolID,
        PoID: _PoID,
        CustomerRefID: _CustomerRefID,
        PickupDateStart: _PickupDateStart,
        PickupDateEnd: _PickupDateEnd,
        DeliveryDateStart: _DeliveryDateStart,
        DeliveryDateEnd: _DeliveryDateEnd,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,

    }
});



export const transLoadPost = (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _Destination, _LoadStatusID, _LoadPoData, _LocationPoData, _PoID, _PCS, _Pallets, _WarehouseID, _CustomerID,) =>
({
    type: types.TRANS_POST_LOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        PickupDate: _PickupDate,
        DeliveryDate: _DeliveryDate,
        Carrier: _Carrier,
        BOL: _BOL,
        Trailer: _Trailer,
        Seal: _Seal,
        TotalPCS: _TotalPCS,
        TotalPallets: _TotalPallets,
        Destination: _Destination,
        LoadStatusID: _LoadStatusID,
        LoadPoData: _LoadPoData,
        LocationPoData: _LocationPoData,
        PoID: _PoID,
        PCS: _PCS,
        Pallets: _Pallets,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});
export const transLoadRemovePost = (_email, _authToken, _LoadID, _PoID, _PCS, _Pallets, _WarehouseLocations, _WarehouseID, _CustomerID) =>
({
    type: types.TRANS_POST_LOAD_REMOVE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LoadID: _LoadID,
        PoID: _PoID,
        PCS: _PCS,
        Pallets: _Pallets,
        WarehouseLocations: _WarehouseLocations,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});


export const transLoadPoGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID) =>
({
    type: types.TRANS_LOAD_PO_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,



    }
});


export const transLoadPoHistoryGet = (_email, _authToken, _PoID, _LoadID, _WarehouseID, _CustomerID) =>
({
    type: types.TRANS_LOAD_PO_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        PoID: _PoID,
        LoadID: _LoadID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
    }
});


export const transOutboundGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _Seal, _Carrier, _Trailer, _BOL,) =>
({
    type: types.TRANS_OUTBOUND_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        ContainerID: _ContainerID,
        Container: _Container,
        MbolID: _MbolID,
        MBOL: _MBOL,
        HbolID: _HbolID,
        HBOL: _HBOL,
        PoID: _PoID,
        PO: _PO,
        DepartDateStart: _DepartDateStart,
        DepartDateEnd: _DepartDateEnd,
        DestinationID: _DestinationID,
        DestinationName: _DestinationName,
        Seal: _Seal,
        Carrier: _Carrier,
        Trailer: _Trailer,
        BOL: _BOL,
    }
});



export const transPoGet = (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID, _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd) =>
({
    type: types.PO_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,

        ContainerID: _ContainerID,
        Container: _Container,
        MbolID: _MbolID,
        MBOL: _MBOL,
        HbolID: _HbolID,
        HBOL: _HBOL,
        PoID: _PoID,
        PO: _PO,
        ContainerStatusID: _ContainerStatusID,
        PoStatusID: _PoStatusID,

        CustomerRefID: _CustomerRefID,
        CustomerRef: _CustomerRef,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,

    }
});



export const transPoPost = (_email, _authToken, _PoID, _PO, _HbolID, _CustomerRef, _CaseCount, _PalletCount, _Notes, _Project, _Delete, _WarehouseID, _CustomerID) =>
({
    type: types.POST_PO_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        PoID: _PoID,
        PO: _PO,
        HbolID: _HbolID,

        CustomerRef: _CustomerRef,
        CaseCount: _CaseCount,
        PalletCount: _PalletCount,
        Notes: _Notes,
        Project: _Project,
        
        Delete: _Delete,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,


    }
});


export const transPoHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _PoID, _PO) =>
({
    type: types.TRANS_PO_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PoID: _PoID,
        PO: _PO,


    }
});


export const transPoLocationGet = (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID,) =>
({
    type: types.TRANS_PO_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        MbolID: _MbolID,
        MBOL: _MBOL,
        HbolID: _HbolID,
        HBOL: _HBOL,
        PoID: _PoID,
        PO: _PO,

        PoStatusID: _PoStatusID,
        CustomerRefID: _CustomerRefID,
        RecoveryDateStart: _RecoveryDateStart,
        RecoveryDateEnd: _RecoveryDateEnd,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,

    }
});

export const transPoLocationPost = (_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize) =>
({
    type: types.TRANS_POST_PO_LOCATION_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        PoID: _PoID,
        WarehouseLocationID: _WarehouseLocationID,
        Cases: _Cases,
        Pallets: _Pallets,
        PreviousWarehouseLocationID: _PreviousWarehouseLocationID,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        Oversize: _Oversize


    }
});

export const transPoLoadLocationHistoryGet = (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PoID, _PO,) =>
({
    type: types.TRANS_PO_LOAD_LOCATION_HISTORY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        LoadID: _LoadID,
        PoID: _PoID,
        PO: _PO,


    }
});



export const transPoRecoveryGet = (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) =>
({
    type: types.PO_RECOVERY_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        ContainerID: _ContainerID,
        Container: _Container,
        MbolID: _MbolID,
        MBOL: _MBOL,
        HbolID: _HbolID,
        HBOL: _HBOL,
        PoID: _PoID,
        PO: _PO,
        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,

    }
});




export const transContainerFileUploadGet = (_email, _authToken, _CustomerID, _FileUploadID, _ContainerID, _FileName, _S3FileName, _FileType, _FileDelete,) =>
({
    type: types.TRANS_CONTAINER_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        ContainerID: _ContainerID,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});


export const transContainerFileUploadPost = (_email, _authToken, _CustomerID, _FileUploadID, _ContainerID, _FileName, _S3FileName,  _FileType, _FileDelete,) =>
({
    type: types.TRANS_POST_CONTAINER_FILEUPLOAD_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        FileUploadID: _FileUploadID,
        ContainerID: _ContainerID,
        FileName: _FileName,
        S3FileName: _S3FileName,
        FileType: _FileType,
        FileDelete: _FileDelete,

    }
});

