import { connect } from 'react-redux';
import React, { Component } from 'react';
import QuoteShipmentList from './QuoteShipmentList';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import * as userActions from '../../../redux/actions/actionsUser';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI, queryStringParseWithZero } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isCtrlTwrCountryLoading:                                store.ctrlTwrReducer.isCtrlTwrCountryLoading,
    CtrlTwrCountryData:                                     store.ctrlTwrReducer.CtrlTwrCountryData,

    isCtrlTwrModeRefLoading: store.ctrlTwrReducer.isCtrlTwrModeRefLoading,
    CtrlTwrModeRefData: store.ctrlTwrReducer.CtrlTwrModeRefData,

    isCtrlTwrAirportLoading: store.ctrlTwrReducer.isCtrlTwrAirportLoading,
    CtrlTwrAirportData: store.ctrlTwrReducer.CtrlTwrAirportData,

    isCtrlTwrSeaportLoading: store.ctrlTwrReducer.isCtrlTwrSeaportLoading,
    CtrlTwrSeaportData: store.ctrlTwrReducer.CtrlTwrSeaportData,

    CtrlTwrShipmentLegsDataPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsDataPostSuccessful,

    isCtrlTwrQuotingShipmentLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentLoading,
    CtrlTwrQuotingShipmentData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentData,

    isCtrlTwrShipmentLegsDataLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsDataLoading,
    CtrlTwrShipmentLegsData: store.ctrlTwrReducer.CtrlTwrShipmentLegsData,

    CtrlTwrShipmentLegsPostSuccessful:                      store.ctrlTwrReducer.CtrlTwrShipmentLegsPostSuccessful,

    CtrlTwrQuotingShipmentStatusActionPostSuccessful: store.ctrlTwrReducer.CtrlTwrQuotingShipmentStatusActionPostSuccessful,
});

const mapDispatchToProps = dispatch =>
({
    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrCountryGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrCountryGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrAirportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrAirportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrSeaportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrSeaportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrShipmentLegsDataPost: (_email, _authToken, _ProRef, _LegNumber, _ModeID, _LegID, _WarehouseID, _BOL, _MBOL, _HBOL, _PO, _Container, _Carrier, _Seal, _PlannedDepartureDate, _EstimatedDeliveryDate, _PickupAddress, _DeliveryAddress, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _CustomerRef, _Shipper_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode, _PickupAppointmentTime, _DeliveryAppointmentTime) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsDataPost(_email, _authToken, _ProRef, _LegNumber, _ModeID, _LegID, _WarehouseID, _BOL, _MBOL, _HBOL, _PO, _Container, _Carrier, _Seal, _PlannedDepartureDate, _EstimatedDeliveryDate, _PickupAddress, _DeliveryAddress, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _CustomerRef, _Shipper_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode, _PickupAppointmentTime, _DeliveryAppointmentTime))
    },

    ctrlTwrQuotingShipmentGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _QuotingStatusID) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _QuotingStatusID))
    },

    ctrlTwrQuotingShipmentStatusActionGet: (_email, _authToken, _ProRef, _ShipmentQuoteActive, _QuotingStatusID,) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentStatusActionGet(_email, _authToken, _ProRef, _ShipmentQuoteActive, _QuotingStatusID))
    },
    
});

class QuoteShipmentListContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.handleUrlParam();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
       

        if (prevProps.CtrlTwrQuotingShipmentStatusActionPostSuccessful !== this.props.CtrlTwrQuotingShipmentStatusActionPostSuccessful && this.props.CtrlTwrQuotingShipmentStatusActionPostSuccessful) {
            this.handleUrlParam()
        }
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = formatStringForAPI(values.pro)
        }
        catch (e) {
            var paramPro = null
        }

        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }

        try {
            var paramCustomer = queryStringParse(values.customer)
        }
        catch (e) {
            var paramCustomer = null
        }

        try {
            var paramCountry = queryStringParse(values.country)
        }
        catch (e) {
            var paramCountry = null
        }

        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }

        this.props.ctrlTwrModeRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrAirportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrSeaportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.ctrlTwrCountryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.ctrlTwrQuotingShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, paramPo, null, null, null, paramStatus)

        
    }



    render() {

        return (

            <QuoteShipmentList
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isCtrlTwrModeRefLoading={this.props.isCtrlTwrModeRefLoading}
                CtrlTwrModeRefData={this.props.CtrlTwrModeRefData}

                isCtrlTwrAirportLoading={this.props.isCtrlTwrAirportLoading}
                CtrlTwrAirportData={this.props.CtrlTwrAirportData}
            
                isCtrlTwrSeaportLoading={this.props.isCtrlTwrSeaportLoading}
                CtrlTwrSeaportData={this.props.CtrlTwrSeaportData}

                CtrlTwrShipmentLegsDataPostSuccessful={this.props.CtrlTwrShipmentLegsDataPostSuccessful}
                ctrlTwrShipmentLegsDataPost={this.props.ctrlTwrShipmentLegsDataPost}


                isCtrlTwrCountryLoading={this.props.isCtrlTwrCountryLoading}
                CtrlTwrCountryData={this.props.CtrlTwrCountryData}
                

                isCtrlTwrQuotingShipmentLoading={this.props.isCtrlTwrQuotingShipmentLoading}
                CtrlTwrQuotingShipmentData={this.props.CtrlTwrQuotingShipmentData}


                ctrlTwrShipmentLegsDataGet={this.props.ctrlTwrShipmentLegsDataGet}
                isCtrlTwrShipmentLegsDataLoading={this.props.isCtrlTwrShipmentLegsDataLoading}
                CtrlTwrShipmentLegsData={this.props.CtrlTwrShipmentLegsData}

                CtrlTwrShipmentLegsPostSuccessful={this.props.CtrlTwrShipmentLegsPostSuccessful}
                ctrlTwrShipmentLegsPost={this.props.ctrlTwrShipmentLegsPost}

                ctrlTwrQuotingShipmentStatusActionGet={this.props.ctrlTwrQuotingShipmentStatusActionGet}
                CtrlTwrQuotingShipmentStatusActionPostSuccessful={this.props.CtrlTwrQuotingShipmentStatusActionPostSuccessful}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteShipmentListContainer));



