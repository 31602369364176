
export const UserAccountModuleDataElements = [
    {
        dBKey: 'ModuleName',
        displayName: 'Module Name',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    // {
    //     dBKey: 'ModuleCode',
    //     displayName: 'Module Code',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    {
        dBKey: 'Admin',
        displayName: 'Admin',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'ReadWrite',
        displayName: 'Read/Write',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },

    
]

export default UserAccountModuleDataElements

