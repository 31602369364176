import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
// import PendingUserAccountsContainer from '../../../components/PendingUserAccounts/PendingUserAccountsContainer';

import PendingUsersContainer  from '../../../components/componentsUser/PendingUsers/PendingUserContainer';
import UserManagementContainer from '../../../components/componentsUser/UserManagement/UserManagementContainer';
// import AdminUserManagementContainer from '../../../components/ComponentsUser/UserManagement/AdminUserManagementContainer';


class userManagementView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {



          };
      }



    render() {

        return(
            <AnimatedView>

                <PendingUsersContainer isMobile={this.props.isMobile}/>

                <UserManagementContainer isMobile={this.props.isMobile}/>
                
                {/* <AdminUserManagementContainer isMobile={this.props.isMobile}/> */}

            </AnimatedView>

        );
    }
}

export default userManagementView;
