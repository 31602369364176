import React from 'react';
import { Row, Col, Panel, Timeline } from 'rsuite'; // Assuming you're using rsuite
import Loader from 'react-loader-spinner'; // Assuming you're using react-loader-spinner

function LoadPalletHistoryDiv({ isInventoryLoadPalletsHistoryLoading, InventoryLoadPalletsHistoryData }) {
    if (isInventoryLoadPalletsHistoryLoading) {
        return (
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        );
    }

    if (typeof (InventoryLoadPalletsHistoryData[0]) !== 'undefined' && InventoryLoadPalletsHistoryData != null) {
        return (
            <div>
                <Row>
                    <Col md={24} sm={24}>
                        <Panel bordered header="Load Pallet History" style={{ marginBottom: 10 }}>
                            <Timeline>
                                {InventoryLoadPalletsHistoryData.map(item => (
                                    <Timeline.Item key={item.LoadPalletHistoryID}>
                                        {item.CreatedDT}  &nbsp;
                                        {item.EmailAddr}
                                        <l></l>
                                        {item.HistoryNotes != null ? <l><br /> {item.HistoryNotes} </l> : ' '}                                       
                                        {item.PalletBarcode != null ? <l><br /> Pallet Barcode:  {item.PalletBarcode} </l> : ' '}                            
                                        {item.WarehouseLocationName != null ? <l><br /> Warehouse Location:  {item.WarehouseLocationName} </l> : ' '}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }

    return <div></div>;
}

export default LoadPalletHistoryDiv;
