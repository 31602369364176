import { connect } from 'react-redux';
import React, { Component } from 'react';
import Dashboard from './Dashboard'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/ctrlTwrActions';
import { formatStringForAPI, queryStringParse, queryStringParseWithZero } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isCtrlTwrGenerateProLoading: store.ctrlTwrReducer.isCtrlTwrGenerateProLoading,
    CtrlTwrGenerateProData: store.ctrlTwrReducer.CtrlTwrGenerateProData,


    isCtrlTwrAlertsShipmentDepartureLoading: store.ctrlTwrReducer.isCtrlTwrAlertsShipmentDepartureLoading,
    CtrlTwrAlertsShipmentDepartureData: store.ctrlTwrReducer.CtrlTwrAlertsShipmentDepartureData,

    isCtrlTwrAlertsShipmentDeliveryLoading: store.ctrlTwrReducer.isCtrlTwrAlertsShipmentDeliveryLoading,
    CtrlTwrAlertsShipmentDeliveryData: store.ctrlTwrReducer.CtrlTwrAlertsShipmentDeliveryData,

    isCtrlTwrAlertsShipmentSummaryLoading: store.ctrlTwrReducer.isCtrlTwrAlertsShipmentSummaryLoading,
    CtrlTwrAlertsShipmentSummaryData: store.ctrlTwrReducer.CtrlTwrAlertsShipmentSummaryData,

    isCtrlTwrAlertsStatsLoading: store.ctrlTwrReducer.isCtrlTwrAlertsStatsLoading,
    CtrlTwrAlertsStatsData: store.ctrlTwrReducer.CtrlTwrAlertsStatsData,


    isCtrlTwrShipmentLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLoading,
    CtrlTwrShipmentData: store.ctrlTwrReducer.CtrlTwrShipmentData,

    isCtrlTwrChartShipmentDeliveryLoading: store.ctrlTwrReducer.isCtrlTwrChartShipmentDeliveryLoading,
    CtrlTwrChartShipmentDeliveryData: store.ctrlTwrReducer.CtrlTwrChartShipmentDeliveryData,

    isCtrlTwrChartShipmentDeliveryDetailsLoading: store.ctrlTwrReducer.isCtrlTwrChartShipmentDeliveryDetailsLoading,
    CtrlTwrChartShipmentDeliveryDetailsData: store.ctrlTwrReducer.CtrlTwrChartShipmentDeliveryDetailsData,


    isCtrlTwrChartShipmentDeliveryHistoryDetailsLoading: store.ctrlTwrReducer.isCtrlTwrChartShipmentDeliveryHistoryDetailsLoading,
    CtrlTwrChartShipmentDeliveryHistoryDetailsData: store.ctrlTwrReducer.CtrlTwrChartShipmentDeliveryHistoryDetailsData,


});

const mapDispatchToProps = dispatch =>
({
    ctrlTwrShipmentGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert) => {
        dispatch(actions.ctrlTwrShipmentGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert))
    },
    ctrlTwrGenerateProGet: (_email, _authToken) => {
        dispatch(actions.ctrlTwrGenerateProGet(_email, _authToken))
    },

    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    ctrlTwrAlertsShipmentDepartureGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed) => {
        dispatch(actions.ctrlTwrAlertsShipmentDepartureGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed))
    },

    ctrlTwrAlertsShipmentDeliveryGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed) => {
        dispatch(actions.ctrlTwrAlertsShipmentDeliveryGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed))
    },

    ctrlTwrAlertsShipmentSummaryGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed) => {
        dispatch(actions.ctrlTwrAlertsShipmentSummaryGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _DateStart, _DateEnd, _ShipmentClosed))
    },

    ctrlTwrAlertsStatsGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _ModeID, _Carrier, _OriginCountryID, _DateStart, _DateEnd, _ShipmentClosed, _Filter) => {
        dispatch(actions.ctrlTwrAlertsStatsGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _ModeID, _Carrier, _OriginCountryID, _DateStart, _DateEnd, _ShipmentClosed, _Filter))
    },

    ctrlTwrChartShipmentDeliveryDetailsGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _ShipmentClosed, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData) => {
        dispatch(actions.ctrlTwrChartShipmentDeliveryDetailsGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _PO, _OriginCountryID, _ModeID, _Carrier, _DateStart, _DateEnd, _ShipmentClosed, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert, _ChartDetailData))
    },

});

class DashboardContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

    }

    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)



        try {
            var paramPro = formatStringForAPI(values.pro)
        }
        catch (e) {
            var paramPro = null
        }

        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }

        try {
            var paramCountry = queryStringParse(values.country)
        }
        catch (e) {
            var paramCountry = null
        }

        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }

        this.props.ctrlTwrAlertsShipmentDepartureGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPro, paramCountry, paramPo, null, null, null)
        this.props.ctrlTwrAlertsShipmentDeliveryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPro, paramCountry, paramPo, null, null, null)

        this.props.ctrlTwrAlertsShipmentSummaryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPro, paramCountry, paramPo, null, null, null)

        this.props.ctrlTwrAlertsStatsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPro, paramPo, null, null, paramCountry, null, null, null, null)
    }



    render() {

        return (

            <Dashboard
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}


                isCtrlTwrAlertsShipmentDepartureLoading={this.props.isCtrlTwrAlertsShipmentDepartureLoading}
                CtrlTwrAlertsShipmentDepartureData={this.props.CtrlTwrAlertsShipmentDepartureData}

                isCtrlTwrAlertsShipmentDeliveryLoading={this.props.isCtrlTwrAlertsShipmentDeliveryLoading}
                CtrlTwrAlertsShipmentDeliveryData={this.props.CtrlTwrAlertsShipmentDeliveryData}

                isCtrlTwrAlertsShipmentSummaryLoading={this.props.isCtrlTwrAlertsShipmentSummaryLoading}
                CtrlTwrAlertsShipmentSummaryData={this.props.CtrlTwrAlertsShipmentSummaryData}

                isCtrlTwrAlertsStatsLoading={this.props.isCtrlTwrAlertsStatsLoading}
                CtrlTwrAlertsStatsData={this.props.CtrlTwrAlertsStatsData}

                ctrlTwrShipmentGet={this.props.ctrlTwrShipmentGet}
                isCtrlTwrShipmentLoading={this.props.isCtrlTwrShipmentLoading}
                CtrlTwrShipmentData={this.props.CtrlTwrShipmentData}


                isCtrlTwrChartShipmentDeliveryLoading={this.props.isCtrlTwrChartShipmentDeliveryLoading}
                CtrlTwrChartShipmentDeliveryData={this.props.CtrlTwrChartShipmentDeliveryData}

                isCtrlTwrChartShipmentDeliveryDetailsLoading={this.props.isCtrlTwrChartShipmentDeliveryDetailsLoading}
                CtrlTwrChartShipmentDeliveryDetailsData={this.props.CtrlTwrChartShipmentDeliveryDetailsData}




                isCtrlTwrChartShipmentDeliveryHistoryDetailsLoading={this.props.isCtrlTwrChartShipmentDeliveryHistoryDetailsLoading}
                CtrlTwrChartShipmentDeliveryHistoryDetailsData={this.props.CtrlTwrChartShipmentDeliveryHistoryDetailsData}

            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardContainer));



