import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import _ from "lodash";
import React from 'react';
import {
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  Row,
  Col,
  DatePicker
} from 'rsuite';
import { emptyOrdersOutboundSkuDict } from '../../_References/DataStructures';
import { textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';
import { findLowestMissingNumber } from '../../../../resources/logic/functions';

const mountRef = React.createRef();


const uomData = [
  // { label: 'Pallet', value: 'pallet' },
  { label: 'Case', value: 'case' },
  { label: 'Each', value: 'each' }
];

const orderTypeData = [
  { label: 'Sales Order', value: 'sales' },
  { label: 'Purchase Order', value: 'purchase' },
  { label: 'Transfer Order', value: 'transfer' }
];

const orderPriorityData = [
  { label: 'Standard', value: 'standard' },
  { label: 'Rush', value: 'rush' }
];

const orderInboundOutboundData = [
  { label: 'Inbound', value: 'Inbound' },
  { label: 'Outbound', value: 'Outbound' }
];

class OrderEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderType: '',
      orderPriority: '',
      uom: '',

      orderSkuDict: {},

      OrderInboundOutbound: null,

      OrderID: '',
      OrderType: 'sales',
      OrderDate: null,
      ShipByDate: null,
      DeliverByDate: null,
      ShipToName: '',
      ShipToAddress: '',
      OrderPriority: null,
      Notes: '',



    };
  }


  componentDidMount() {
    var newSkuDict = _.cloneDeep(emptyOrdersOutboundSkuDict);
    this.setState({
      orderSkuDict: [newSkuDict]
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };



  //Change State
  onChangeState(e, statename) {
    this.setState({
      [statename]: e
    });
  }


  onChangeSku(e, sku, label) {
    var skuSeq = sku['SkuSeq']
    var currentSkuDict = this.state.orderSkuDict
    var skuKeyToUpdate = currentSkuDict.findIndex(item => item.SkuSeq === skuSeq)
    currentSkuDict[skuKeyToUpdate][label] = e

    this.handleSetStateSku(currentSkuDict)
  }




  handleSetStateSku = (newSkuDict) => {
    this.setState({
      orderSkuDict: newSkuDict
    }, () => {
      this.forceUpdate()
    });
  }



  onClickAddSku(e) {
    var currentSkuDict = this.state.orderSkuDict
    const skuSeqArray = currentSkuDict.filter(x => typeof (x.SkuSeq) !== 'undefined').map(item => item.SkuSeq);
    var newID = findLowestMissingNumber(skuSeqArray)
    var newSku = _.cloneDeep(emptyOrdersOutboundSkuDict);
    newSku['SkuSeq'] = newID
    currentSkuDict.push(newSku)
    this.handleSetStateSku(currentSkuDict)
  }


  onClickDeleteSku(e, sku) {
    var currentSkuDict = this.state.orderSkuDict
    var skuSeq = sku['SkuSeq']
    var skuKeyToDelete = currentSkuDict.findIndex(item => item.SkuSeq === skuSeq)
    currentSkuDict.splice(skuKeyToDelete, 1)
    this.handleSetStateSku(currentSkuDict)
  }



  render() {

    let skuAdd;
    if (this.state.orderSkuDict.length > 0) {

      skuAdd =
        this.state.orderSkuDict.map(sku => (
          <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>

            <Row >
              <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                <InputGroup.Addon>SKU:</InputGroup.Addon>
                <InputPicker preventOverflow
                  container={() => mountRef.current}
                  placeholder={'SKU...'}
                  // value={sku['SkuID']}
                  onChange={(e) => { this.onChangeSku(e, sku, 'SkuID') }}

                  style={{ width: '100%' }}
                  block
                  cleanable={true}
                />
              </InputGroup>
            </Row>

            <Row >
              <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                <InputGroup.Addon>UOM:</InputGroup.Addon>
                <InputPicker preventOverflow
                  container={() => mountRef.current}
                  placeholder={'UOM...'}
                  value={sku['UOM']}
                  onChange={(e) => { this.onChangeSku(e, sku, 'UOM') }}
                  data={uomData}
                  style={{ width: '100%' }}
                  block
                  cleanable={true}
                />
              </InputGroup>
            </Row>

            <Row>
              <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                <Input value={sku.LotCode} className={'custom-input-number'} onChange={(e) => this.onChangeSku(e, sku, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
              </InputGroup>
            </Row>

            <Row>
              <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                <Input value={sku.SerialNum} className={'custom-input-number'} onChange={(e) => this.onChangeSku(e, sku, 'SerialNum')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
              </InputGroup>
            </Row>

            <Row>
              <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                <InputGroup.Addon>Qty:</InputGroup.Addon>
                <InputNumber min={1} value={sku.Qty} className={'custom-input-number'} onChange={(e) => this.onChangeSku(e, sku, 'Qty')} scrollable={false} />
              </InputGroup>
            </Row>

            <Row>
              <Col style={{ textAlign: 'right' }}>
                <Button variant="contained" color="default" onClick={(e) => this.onClickDeleteSku(e, sku)}>
                  Delete SKU
                </Button>
              </Col>
            </Row>


          </Paper>

        ))

    }
    else {
      skuAdd = <div></div>
    }



    return (


      <Container maxWidth={false} style={{ width: '100%' }}>

        <h3>
          Inventory Management - Order Entry
        </h3>

        <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>

          <Row >
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Inbound/Outbound:</InputGroup.Addon>
              <InputPicker preventOverflow
                container={() => mountRef.current}
                placeholder={'Inbound/Outbound...'}
                value={this.state.OrderInboundOutbound}
                onChange={(e) => { this.onChangeState(e, 'OrderInboundOutbound') }}
                data={orderInboundOutboundData}
                style={{ width: '100%' }}
                block
                cleanable={true}
              />
            </InputGroup>
          </Row>

        </Paper>


        <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>


          <Row >
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Order Type:</InputGroup.Addon>
              <InputPicker preventOverflow
                container={() => mountRef.current}
                placeholder={'Order Type...'}
                value={this.state.OrderType}
                onChange={(e) => { this.onChangeState(e, 'OrderType') }}
                data={orderTypeData}
                style={{ width: '100%' }}
                block
                cleanable={true}
              />
            </InputGroup>
          </Row>

          <Row >
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Order Date:</InputGroup.Addon>
              <DatePicker
                container={() => mountRef.current}
                placeholder="Order Date"
                onChange={(e) => { this.onChangeState(e, 'OrderDate') }}
                value={this.state.OrderDate}
                format="YYYY-MM-DD"
                // format="YYYY-MM-DD hh:mm aa"
                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                showMeridian
                size="sm"
                ranges={[
                  {
                    label: 'Now',
                    value: new Date()
                  }
                ]}
                oneTap
                style={{ width: '100%' }}
              />
            </InputGroup>
          </Row>

          <Row >
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Ship By Date (Optional):</InputGroup.Addon>
              <DatePicker
                container={() => mountRef.current}
                placeholder="Ship By Date"
                onChange={(e) => { this.onChangeState(e, 'ShipByDate') }}
                value={this.state.ShipByDate}
                format="YYYY-MM-DD"
                // format="YYYY-MM-DD hh:mm aa"
                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                showMeridian
                size="sm"
                ranges={[
                  {
                    label: 'Now',
                    value: new Date()
                  }
                ]}
                oneTap
                style={{ width: '100%' }}
              />
            </InputGroup>
          </Row>


          <Row >
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Deliver By Date (Optional):</InputGroup.Addon>
              <DatePicker
                container={() => mountRef.current}
                placeholder="Deliver By Date"
                onChange={(e) => { this.onChangeState(e, 'DeliverByDate') }}
                value={this.state.DeliverByDate}
                format="YYYY-MM-DD"
                // format="YYYY-MM-DD hh:mm aa"
                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                showMeridian
                size="sm"
                ranges={[
                  {
                    label: 'Now',
                    value: new Date()
                  }
                ]}
                oneTap
                style={{ width: '100%' }}
              />
            </InputGroup>
          </Row>


          <Row>
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Ship To Name:</InputGroup.Addon>
              <Input value={this.state.ShipToName} className={'custom-input-number'} onChange={(e) => this.onChangeState(e, 'ShipToName')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
            </InputGroup>
          </Row>


          <Row>
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Ship To Address:</InputGroup.Addon>
              <Input value={this.state.ShipToAddress} className={'custom-input-number'} onChange={(e) => this.onChangeState(e, 'ShipToAddress')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
            </InputGroup>
          </Row>


          <Row >
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Order Priority:</InputGroup.Addon>
              <InputPicker preventOverflow
                container={() => mountRef.current}
                placeholder={'Order Priority...'}
                value={this.state.OrderPriority}
                onChange={(e) => { this.onChangeState(e, 'OrderPriority') }}
                data={orderPriorityData}
                style={{ width: '100%' }}
                block
                cleanable={true}
              />
            </InputGroup>
          </Row>


          <Row>
            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
              <InputGroup.Addon>Notes (Optional):</InputGroup.Addon>
              <Input value={this.state.Notes} className={'custom-input-number'} onChange={(e) => this.onChangeState(e, 'Notes')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
            </InputGroup>
          </Row>


        </Paper>


        {skuAdd}



        <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>
          {/* <Grid item xs={12} sm={6}> */}
          <Button variant="contained" color="primary" fullWidth onClick={(e) => this.onClickAddSku(e)}>
            Add SKU
          </Button>
          {/* </Grid> */}
        </Paper>


      </Container>


    );



  }
}

export default OrderEntry;