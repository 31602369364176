import * as types from '../constants/labelGenActionTypes';





/* ################################### LABEL GEN ACTIONS ################################### */


// export const labelGenUpsLabelCreatePost = (_email, _authToken, _WarehouseID, _CustomerID, _labelParam) =>
// ({
//     type: types.LABELGEN_POST_UPS_LABEL_CREATE_GET,
//     payload: {
//         email: _email,
//         authToken: _authToken,

//         WarehouseID: _WarehouseID,
//         CustomerID: _CustomerID,
//         labelParam: _labelParam
//     }
// });


export const labelGenApiRequestMainGet = (_email, _authToken, _param) =>
({
    type: types.LABELGEN_API_REQUEST_MAIN_GET,
    payload: {
        email: _email,
        authToken: _authToken,
        param: _param,

        // WarehouseID: _WarehouseID,
        // CustomerID: _CustomerID,
        // CarrierID: _CarrierID,
        // LabelRequestID: _LabelRequestID,
        // ResponseSuccess: _ResponseSuccess,
        // RequestDtStart: _RequestDtStart,
        // RequestDtEnd: _RequestDtEnd,
        // ProdEnvironment: _ProdEnvironment,
    }
});




export const labelGenRequestRefGet = (_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID) =>
({
    type: types.LABELGEN_REQUEST_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        CarrierID: _CarrierID,
        LabelRequestID: _LabelRequestID,
        ResponseSuccess: _ResponseSuccess,
        RequestDtStart: _RequestDtStart,
        RequestDtEnd: _RequestDtEnd,
        ProdEnvironment: _ProdEnvironment,
        LabelPortID: _LabelPortID
    }
});

export const labelGenResponsePackageLabelsGet = (_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID, _TrackingNumber, _ShipmentIdentificationNumber) =>
({
    type: types.LABELGEN_RESPONSE_PACKAGE_LABELS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        CarrierID: _CarrierID,
        LabelRequestID: _LabelRequestID,
        ResponseSuccess: _ResponseSuccess,
        RequestDtStart: _RequestDtStart,
        RequestDtEnd: _RequestDtEnd,
        ProdEnvironment: _ProdEnvironment,
        LabelPortID: _LabelPortID,
        TrackingNumber: _TrackingNumber,
        ShipmentIdentificationNumber: _ShipmentIdentificationNumber,
    }
});


export const labelGenRequestRefInWorkGet = (_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID) =>
({
    type: types.LABELGEN_REQUEST_INWORK_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        CarrierID: _CarrierID,
        LabelRequestID: _LabelRequestID,
        ResponseSuccess: _ResponseSuccess,
        RequestDtStart: _RequestDtStart,
        RequestDtEnd: _RequestDtEnd,
        ProdEnvironment: _ProdEnvironment,
        LabelPortID: _LabelPortID
    }
});




export const labelGenRequestPackageGet = (_email, _authToken, _RequestPackageID, _LabelRequestID,) =>
({
    type: types.LABELGEN_REQUEST_PACKAGE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        RequestPackageID: _RequestPackageID,
        LabelRequestID: _LabelRequestID,
    }
});


export const labelGenRequestPackagePost = (_email, _authToken, _RequestPackageID, _LabelRequestID, _PackageIndex, _Package_Description, _Package_Packaging_Code, _Package_Dimensions_UOM_Code, _Package_Dimensions_Length, _Package_Dimensions_Width, _Package_Dimensions_Height, _Package_PackageWeight_UOM_Code, _Package_PackageWeight_UOM_Description, _Package_PackageWeight_Weight, _Package_PkgSO_DeliveryConfirmation_DCISType, _Package_PkgSO_DeclaredValue_CurrencyCode, _Package_PkgSO_DeclaredValue_MonetaryValue, _Delete,) =>
({
    type: types.LABELGEN_POST_REQUEST_PACKAGE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        RequestPackageID: _RequestPackageID,
        LabelRequestID: _LabelRequestID,
        PackageIndex: _PackageIndex,
        Package_Description: _Package_Description,
        Package_Packaging_Code: _Package_Packaging_Code,
        Package_Dimensions_UOM_Code: _Package_Dimensions_UOM_Code,
        Package_Dimensions_Length: _Package_Dimensions_Length,
        Package_Dimensions_Width: _Package_Dimensions_Width,
        Package_Dimensions_Height: _Package_Dimensions_Height,
        Package_PackageWeight_UOM_Code: _Package_PackageWeight_UOM_Code,
        Package_PackageWeight_UOM_Description: _Package_PackageWeight_UOM_Description,
        Package_PackageWeight_Weight: _Package_PackageWeight_Weight,
        Package_PkgSO_DeliveryConfirmation_DCISType: _Package_PkgSO_DeliveryConfirmation_DCISType,
        Package_PkgSO_DeclaredValue_CurrencyCode: _Package_PkgSO_DeclaredValue_CurrencyCode,
        Package_PkgSO_DeclaredValue_MonetaryValue: _Package_PkgSO_DeclaredValue_MonetaryValue,
        Delete: _Delete,
    }
});


export const labelGenRequestPackageProductInfoGet = (_email, _authToken, _RequestProductInfoID, _RequestPackageID, _LabelRequestID,) =>
({
    type: types.LABELGEN_REQUEST_PACKAGE_PRODUCTINFO_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        RequestProductInfoID: _RequestProductInfoID,
        RequestPackageID: _RequestPackageID,
        LabelRequestID: _LabelRequestID,
    }
});

export const labelGenRequestPackageProductInfoPost = (_email, _authToken, _RequestProductInfoID, _RequestPackageID, _PackageIndex, _LabelRequestID, _ProductInfoIndex, _ProductCode, _ProductDescription, _ProductUnitValue, _CurrencyCode, _ProductItemOrigin, _ProductHarmonizedCode, _ProductUnitWeight, _ProductQuantity, _ProductFabricContent, _Hazmat, _HazmatCode, _FDAFlag, _FDACode, _Delete) =>
({
    type: types.LABELGEN_POST_REQUEST_PACKAGE_PRODUCTINFO_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        RequestProductInfoID: _RequestProductInfoID,
        RequestPackageID: _RequestPackageID,
        PackageIndex: _PackageIndex,
        LabelRequestID: _LabelRequestID,
        ProductInfoIndex: _ProductInfoIndex,
        ProductCode: _ProductCode,
        ProductDescription: _ProductDescription,
        ProductUnitValue: _ProductUnitValue,
        CurrencyCode: _CurrencyCode,
        ProductItemOrigin: _ProductItemOrigin,
        ProductHarmonizedCode: _ProductHarmonizedCode,
        ProductUnitWeight: _ProductUnitWeight,
        ProductQuantity: _ProductQuantity,
        ProductFabricContent: _ProductFabricContent,
        Hazmat: _Hazmat,
        HazmatCode: _HazmatCode,
        FDAFlag: _FDAFlag,
        FDACode: _FDACode,
        Delete: _Delete,
    }
});


export const labelGenRequestPost = (_email, _authToken, _LabelRequestID, _CustomerID, _WarehouseID, _CarrierID, _ProdEnvironment, _RequestDT, _ResponseSuccess, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipTo_Name, _ShipTo_AttentionName, _ShipTo_CompanyDisplayableName, _ShipTo_TaxIdentificationNumber, _ShipTo_Phone_Number, _ShipTo_Phone_Extension, _ShipTo_FaxNumber, _ShipTo_EMailAddress, _ShipTo_AddressLine1, _ShipTo_AddressLine2, _ShipTo_AddressLine3, _ShipTo_Address_City, _ShipTo_Address_StateProvinceCode, _ShipTo_Address_PostalCode, _ShipTo_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _CarrierServiceID, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _Delete, _LabelPortID) =>
({
    type: types.LABELGEN_POST_REQUEST_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LabelRequestID: _LabelRequestID,
        CustomerID: _CustomerID,
        WarehouseID: _WarehouseID,
        CarrierID: _CarrierID,
        ProdEnvironment: _ProdEnvironment,
        RequestDT: _RequestDT,
        ResponseSuccess: _ResponseSuccess,
        Shipper_Name: _Shipper_Name,
        Shipper_AttentionName: _Shipper_AttentionName,
        Shipper_CompanyDisplayableName: _Shipper_CompanyDisplayableName,
        Shipper_TaxIdentificationNumber: _Shipper_TaxIdentificationNumber,
        Shipper_Phone_Number: _Shipper_Phone_Number,
        Shipper_Phone_Extension: _Shipper_Phone_Extension,
        Shipper_ShipperNumber: _Shipper_ShipperNumber,
        Shipper_FaxNumber: _Shipper_FaxNumber,
        Shipper_EMailAddress: _Shipper_EMailAddress,
        Shipper_Address_AddressLine1: _Shipper_Address_AddressLine1,
        Shipper_Address_AddressLine2: _Shipper_Address_AddressLine2,
        Shipper_Address_AddressLine3: _Shipper_Address_AddressLine3,
        Shipper_Address_City: _Shipper_Address_City,
        Shipper_Address_StateProvinceCode: _Shipper_Address_StateProvinceCode,
        Shipper_Address_PostalCode: _Shipper_Address_PostalCode,
        Shipper_Address_CountryCode: _Shipper_Address_CountryCode,
        ShipTo_Name: _ShipTo_Name,
        ShipTo_AttentionName: _ShipTo_AttentionName,
        ShipTo_CompanyDisplayableName: _ShipTo_CompanyDisplayableName,
        ShipTo_TaxIdentificationNumber: _ShipTo_TaxIdentificationNumber,
        ShipTo_Phone_Number: _ShipTo_Phone_Number,
        ShipTo_Phone_Extension: _ShipTo_Phone_Extension,
        ShipTo_FaxNumber: _ShipTo_FaxNumber,
        ShipTo_EMailAddress: _ShipTo_EMailAddress,
        ShipTo_AddressLine1: _ShipTo_AddressLine1,
        ShipTo_AddressLine2: _ShipTo_AddressLine2,
        ShipTo_AddressLine3: _ShipTo_AddressLine3,
        ShipTo_Address_City: _ShipTo_Address_City,
        ShipTo_Address_StateProvinceCode: _ShipTo_Address_StateProvinceCode,
        ShipTo_Address_PostalCode: _ShipTo_Address_PostalCode,
        ShipTo_Address_CountryCode: _ShipTo_Address_CountryCode,
        ShipFrom_Name: _ShipFrom_Name,
        ShipFrom_AttentionName: _ShipFrom_AttentionName,
        ShipFrom_CompanyDisplayableName: _ShipFrom_CompanyDisplayableName,
        ShipFrom_TaxIdentificationNumber: _ShipFrom_TaxIdentificationNumber,
        ShipFrom_Phone_Number: _ShipFrom_Phone_Number,
        ShipFrom_Phone_Extension: _ShipFrom_Phone_Extension,
        ShipFrom_FaxNumber: _ShipFrom_FaxNumber,
        ShipFrom_Address_AddressLine1: _ShipFrom_Address_AddressLine1,
        ShipFrom_Address_AddressLine2: _ShipFrom_Address_AddressLine2,
        ShipFrom_Address_AddressLine3: _ShipFrom_Address_AddressLine3,
        ShipFrom_Address_City: _ShipFrom_Address_City,
        ShipFrom_Address_StateProvinceCode: _ShipFrom_Address_StateProvinceCode,
        ShipFrom_Address_PostalCode: _ShipFrom_Address_PostalCode,
        ShipFrom_Address_CountryCode: _ShipFrom_Address_CountryCode,
        CarrierServiceID: _CarrierServiceID,
        LabelSpec_LabelImageFormat_Code: _LabelSpec_LabelImageFormat_Code,
        LabelSpec_LabelStockSize_Height: _LabelSpec_LabelStockSize_Height,
        LabelSpec_LabelStockSize_Width: _LabelSpec_LabelStockSize_Width,
        Delete: _Delete,
        LabelPortID: _LabelPortID
    }
});

export const labelGenPortRefGet = (_email, _authToken, _LabelPortID, _CustomerID,) =>
({
    type: types.LABELGEN_PORT_REF_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        LabelPortID: _LabelPortID,
        CustomerID: _CustomerID,
    }
});



export const labelGenRequestCustomerWarehouseDefaultValuesGet = (_email, _authToken, _CustomerID, _WarehouseID, _DefaultValuesID, _LabelPortID) =>
({
    type: types.LABELGEN_CUSTOMERWAREHOUSE_DEFAULTVALUES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        WarehouseID: _WarehouseID,
        DefaultValuesID: _DefaultValuesID,
        LabelPortID: _LabelPortID
    }
});


export const labelGenRequestCustomerWarehouseUserSavedValuesGet = (_email, _authToken, _CustomerID, _WarehouseID, _CarrierID, _UserSavedValuesID, _ProdEnvironment,) =>
({
    type: types.LABELGEN_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        CustomerID: _CustomerID,
        WarehouseID: _WarehouseID,
        CarrierID: _CarrierID,
        UserSavedValuesID: _UserSavedValuesID,
        ProdEnvironment: _ProdEnvironment,
    }
});


export const labelGenRequestCustomerWarehouseUserSavedValuesPost = (_email, _authToken, _UserSavedValuesID, _CustomerID, _WarehouseID, _CarrierID, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _ProdEnvironment, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _PrinterIP, _PrinterPort, _CarrierServiceID, _Delete,) =>
({
    type: types.LABELGEN_POST_CUSTOMERWAREHOUSE_USERSAVEDVALUES_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        UserSavedValuesID: _UserSavedValuesID,
        CustomerID: _CustomerID,
        WarehouseID: _WarehouseID,
        CarrierID: _CarrierID,
        Shipper_Name: _Shipper_Name,
        Shipper_AttentionName: _Shipper_AttentionName,
        Shipper_CompanyDisplayableName: _Shipper_CompanyDisplayableName,
        Shipper_TaxIdentificationNumber: _Shipper_TaxIdentificationNumber,
        Shipper_Phone_Number: _Shipper_Phone_Number,
        Shipper_Phone_Extension: _Shipper_Phone_Extension,
        Shipper_ShipperNumber: _Shipper_ShipperNumber,
        Shipper_FaxNumber: _Shipper_FaxNumber,
        Shipper_EMailAddress: _Shipper_EMailAddress,
        Shipper_Address_AddressLine1: _Shipper_Address_AddressLine1,
        Shipper_Address_AddressLine2: _Shipper_Address_AddressLine2,
        Shipper_Address_AddressLine3: _Shipper_Address_AddressLine3,
        Shipper_Address_City: _Shipper_Address_City,
        Shipper_Address_StateProvinceCode: _Shipper_Address_StateProvinceCode,
        Shipper_Address_PostalCode: _Shipper_Address_PostalCode,
        Shipper_Address_CountryCode: _Shipper_Address_CountryCode,
        ShipFrom_Name: _ShipFrom_Name,
        ShipFrom_AttentionName: _ShipFrom_AttentionName,
        ShipFrom_CompanyDisplayableName: _ShipFrom_CompanyDisplayableName,
        ShipFrom_TaxIdentificationNumber: _ShipFrom_TaxIdentificationNumber,
        ShipFrom_Phone_Number: _ShipFrom_Phone_Number,
        ShipFrom_Phone_Extension: _ShipFrom_Phone_Extension,
        ShipFrom_FaxNumber: _ShipFrom_FaxNumber,
        ShipFrom_Address_AddressLine1: _ShipFrom_Address_AddressLine1,
        ShipFrom_Address_AddressLine2: _ShipFrom_Address_AddressLine2,
        ShipFrom_Address_AddressLine3: _ShipFrom_Address_AddressLine3,
        ShipFrom_Address_City: _ShipFrom_Address_City,
        ShipFrom_Address_StateProvinceCode: _ShipFrom_Address_StateProvinceCode,
        ShipFrom_Address_PostalCode: _ShipFrom_Address_PostalCode,
        ShipFrom_Address_CountryCode: _ShipFrom_Address_CountryCode,
        ProdEnvironment: _ProdEnvironment,
        LabelSpec_LabelImageFormat_Code: _LabelSpec_LabelImageFormat_Code,
        LabelSpec_LabelStockSize_Height: _LabelSpec_LabelStockSize_Height,
        LabelSpec_LabelStockSize_Width: _LabelSpec_LabelStockSize_Width,
        PrinterIP: _PrinterIP,
        PrinterPort: _PrinterPort,
        CarrierServiceID: _CarrierServiceID,
        Delete: _Delete,
    }
});


export const labelGenExternalUpsTrackingGet = (_email, _authToken, _ExternalUserID, _TrackingNoList, _SINList, _USPSPICList, _InTransit,) =>
    ({
        type: types.LABELGEN_EXTERNAL_UPS_TRACKING_GET,
        payload: {
            email: _email,
            authToken: _authToken,
    
            ExternalUserID: _ExternalUserID,
            TrackingNoList: _TrackingNoList,
            SINList: _SINList,
            USPSPICList: _USPSPICList,
            InTransit: _InTransit,
        }
    });








    export const labelGenExternalUpsTrackingDownloadFileFromS3 = (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) =>
        ({
            type: types.LABELGEN_EXTERNAL_DOWNLOAD_TRACKING_FILE_S3_GET,
            payload: {
                email: _email,
                authToken: _authToken,
        
                ObjectName: _ObjectName,
                FilenameToSave: _FilenameToSave,
                S3Bucket: _S3Bucket
        
            }
        });
        
        









































export const UpsLabelGenerationAction = (_authToken, _param, _prodEnv) =>
({
    type: types.SHIP_REQUEST_GET,
    payload: {
        authToken: _authToken,
        param: _param,
        prodEnv: _prodEnv
    }
});

export const BulkUpsLabelGenerationAction = (_authToken, _param, _prodEnv) =>
({
    type: types.BULK_SHIP_REQUEST_GET,
    payload: {
        authToken: _authToken,
        param: _param,
        prodEnv: _prodEnv
    }
});

export const TMSGetLabelCreateHistoryAction = (_user, _RequestID, _RequestUserID, _Environment, _StatusCode, _UniqueKey_ParcelID, _MasterCartonID_ContainerID, _OrderInfo_OrderNo, _OrderInfo_OrderDate, _EvolveCustomerInfo_CustomerName, _ShipToInfo_State, _ShipToInfo_Postcode, _ShipToInfo_CountryCode, _API, _Carrier, _Service, _TrackingNumber, _LabelCreateStartDT, _LabelCreateEndDT, _FlatsFlag, _PrintUserID, _PrintStartDT, _PrintEndDT, _Printed, _filter) =>
({
    type: types.GET_LABEL_CREATE_HISTORY,
    payload: {
        user: _user,
        RequestID: _RequestID,
        RequestUserID: _RequestUserID,
        Environment: _Environment,
        StatusCode: _StatusCode,
        UniqueKey_ParcelID: _UniqueKey_ParcelID,
        MasterCartonID_ContainerID: _MasterCartonID_ContainerID,
        OrderInfo_OrderNo: _OrderInfo_OrderNo,
        OrderInfo_OrderDate: _OrderInfo_OrderDate,
        EvolveCustomerInfo_CustomerName: _EvolveCustomerInfo_CustomerName,
        ShipToInfo_State: _ShipToInfo_State,
        ShipToInfo_Postcode: _ShipToInfo_Postcode,
        ShipToInfo_CountryCode: _ShipToInfo_CountryCode,
        API: _API,
        Carrier: _Carrier,
        Service: _Service,
        TrackingNumber: _TrackingNumber,
        LabelCreateStartDT: _LabelCreateStartDT,
        LabelCreateEndDT: _LabelCreateEndDT,
        FlatsFlag: _FlatsFlag,
        PrintUserID: _PrintUserID,
        PrintStartDT: _PrintStartDT,
        PrintEndDT: _PrintEndDT,
        Printed: _Printed,
        filter: _filter
    }
});


