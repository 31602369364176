import React, {
  PureComponent
} from 'react';
import { Table, Checkbox, Button } from 'rsuite';
import { sortData } from '../../../../resources/logic/functions';
import { appSettings } from '../../../../app/appSettings';
import RTable_HeightFunction from './RTable_HeightFunction';

const { Column, HeaderCell, Cell, Loader } = Table;

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    {/* <div style={{ lineHeight: '46px' }}> */}
    <div style={{ marginTop: '-7px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

function formatCustomClearanceRow(rowData) {
  if (rowData["CustomsCleared"] === true) {
    return;
  }
  else {
    return <i className="fa fa-flag" style={{ color: `${appSettings.colorPalatte.FireOpal}` }} title="Customs Not Cleared"></i>
  }
  // return rowData
}

function RTable(
  tableData,
  DataElements,
  sortColumn,
  sortType,
  loading,
  handleSortColumn,
  onClickDetails,
  checkColEntry,
  keyColumn,
  checkedKeys,
  handleCheckAll,
  handleCheck,
  boolSelectButton,
  onClickSelect,
  selectButtonText,
  isMobile,
  history,
  highlightRowKey,
  editLink,
  editLinkText,
  editLinkOnClick
) {

  let rTable;

  if (loading) {
    rTable = <div align="center">
      <section className="panel">
      </section>
      <Loader
        type="TailSpin"
        color="#3396E5"
        height="50"
        width="50"
      />
    </div>
  }



  else if (typeof (tableData) !== 'undefined' && tableData != null) {
    if (sortColumn && sortType) {
      sortData(tableData, sortColumn, sortType)
    }

    let selectButtonCol;
    if (boolSelectButton) {
      try {
        selectButtonCol =
          <Column width={(isMobile ? 70 : 100)} fixed="left">
            <HeaderCell></HeaderCell>
            <Cell>
              {rowData => {
                return (
                  <span >
                    <Button appearance={rowData[keyColumn] == highlightRowKey ? "selected" : "primary"} size={isMobile ? 'xs' : 'sm'} style={{ marginTop: '-6px' }} onClick={(e) => onClickSelect(e, rowData, history)}>{selectButtonText}</Button>
                  </span>
                );
              }}
            </Cell>
          </Column>
      }
      catch (e) { }
    }
    else { }

    let checkColumn;
    if (checkColEntry) {
      try {
        let checked = false;
        let indeterminate = false;
        if (checkedKeys.length === tableData.length) {
          checked = true;
        } else if (checkedKeys.length === 0) {
          checked = false;
        } else if (checkedKeys.length > 0 && checkedKeys.length < tableData.length) {
          indeterminate = true;
        }

        checkColumn =
          <Column width={50} align="center" fixed="left">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ marginTop: '-9px' }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={(e, value) => handleCheckAll(value, checked, tableData)}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey={keyColumn}
              checkedKeys={checkedKeys}
              onChange={handleCheck}
            // onChange={(e) => handleCheck()}
            // onChange={(e) => handleCheck()}
            />
          </Column>
      }
      catch (e) { }

    }
    else { }


    let flagColumns;
    if (tableData.some(item => item.hasOwnProperty('CustomsCleared'))) {
      if (tableData.some(o => o.CustomsCleared !== true)) {
        flagColumns = <Column width={50} align="center" fixed="left">
          <HeaderCell style={{ padding: 0 }}>
            <div style={{ marginTop: '-9px' }}>
            </div>
          </HeaderCell>
          <Cell>
            {rowData => (formatCustomClearanceRow(rowData))}
          </Cell>
        </Column>
      }
      else {
        flagColumns = ''
      }
    }
    else {
      flagColumns = ''
    }




    let tableColumns;
    tableColumns = Object.keys(DataElements).map(function (key) {

      var primaryCol = false
      var sortableCol = false
      try {
        if (DataElements[key]["primaryCol"]) {
          primaryCol = true
        }
      }
      catch (e) { }
      try {
        if (DataElements[key]["sortable"]) {
          sortableCol = true
        }
      }
      catch (e) { }

      if (!DataElements[key]["mobileDisplay"] && isMobile) {

      }
      else {
        return (
          <Column width={DataElements[key]["colWidth"]} fixed={primaryCol} sortable={sortableCol}>
            <HeaderCell>{DataElements[key]["displayName"]}</HeaderCell>
            <Cell dataKey={DataElements[key]["dBKey"]} >

              {rowData => (rowData[DataElements[key]["dBKey"]] === true ? "True" : (rowData[DataElements[key]["dBKey"]] === false ? "False" : rowData[DataElements[key]["dBKey"]]))}

              {/* {rowData =>(formatCustomClearanceRow(rowData))} */}
            </Cell>
          </Column>
        )

      }
    })

    var tableLength = tableData.length
    var tableHeight = RTable_HeightFunction(tableLength)

    rTable = <div>

      <Table
        wordWrap
        height={tableHeight}

        data={tableData}

        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        // onSortColumn={()=> handleSortColumn()}
        // onSortColumn={() => { handleSortColumn(); }}
        loading={loading}

        virtualized
      >

        {selectButtonCol}

        {checkColumn}

        {flagColumns}

        {tableColumns}

        {onClickDetails == null ? '' :

          <Column width={(isMobile ? 60 : (editLink ? 250 : 100))} fixed="right">
            <HeaderCell></HeaderCell>
            <Cell>
              {rowData => {
                var newEditLink = editLink
                if (editLinkText == 'Clear Customs') {
                  if (rowData.CustomsCleared == true) {
                    newEditLink = false
                  }
                }

                return (
                  <span>
                    <a style={{ cursor: 'pointer' }} onClick={(e) => onClickDetails(e, rowData, history)}> Details </a>
                    {newEditLink ? '|' : ''}
                    {newEditLink ? <a style={{ cursor: 'pointer' }} onClick={() => { editLinkOnClick(rowData, keyColumn); }}> {editLinkText} </a> : ''}
                  </span>
                );
              }}
            </Cell>
          </Column>
        }
      </Table>
    </div>

    try {
      if (tableData[0]['MSG']) {
        rTable = <div></div>
      }
    }
    catch (e) { }



  }
  else {
    rTable = <div></div>
  }


  return (

    rTable

  )
}


export default RTable;
