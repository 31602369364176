import React from 'react';
import {
    Button,
    Col,
    DatePicker,
    Drawer,
    Input,
    InputGroup,
    InputNumber,
    InputPicker,
    Row
} from 'rsuite';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { textNumbersSymbolsInputmask, limitCharacterLength } from '../../../../resources/logic/inputMasks';
import { uomData, orderTypeData, orderPriorityData, orderInboundOutboundData } from '../../inventoryValues';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';


const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};


const mountRef = React.createRef();

const AddNewOrderOutboundDrawer = ({ onChangeState, onClickDeleteSku, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showAddNewOrderOutboundDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Add New Outbound Order</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>

            <Container maxWidth={false} style={{ width: '100%' }}>

                <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%' }}>


                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Order Ref:</InputGroup.Addon>
                            <Input value={state.OrderRef} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'OrderRef')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 50)} />
                        </InputGroup>
                    </Row>



                    <Row >
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Order Type:</InputGroup.Addon>
                            <InputPicker preventOverflow
                                container={() => mountRef.current}
                                placeholder={'Order Type...'}
                                value={state.OrderTypeID}
                                onChange={(e) => { onChangeState(e, 'OrderTypeID') }}
                                data={orderTypeData}
                                style={{ width: '100%' }}
                                block
                                cleanable={true}
                            />
                        </InputGroup>
                    </Row>


                    <Row >
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Order Priority:</InputGroup.Addon>
                            <InputPicker preventOverflow
                                container={() => mountRef.current}
                                placeholder={'Order Priority...'}
                                value={state.OrderPriorityID}
                                onChange={(e) => { onChangeState(e, 'OrderPriorityID') }}
                                data={orderPriorityData}
                                style={{ width: '100%' }}
                                block
                                cleanable={true}
                            />
                        </InputGroup>
                    </Row>




                    <Row >
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Order Date:</InputGroup.Addon>
                            <DatePicker
                                container={() => mountRef.current}
                                placeholder="Order Date"
                                onChange={(e) => { onChangeState(e, 'OrderDate') }}
                                value={state.OrderDate}
                                format="YYYY-MM-DD"
                                // format="YYYY-MM-DD hh:mm aa"
                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                showMeridian
                                size="sm"
                                ranges={[
                                    {
                                        label: 'Now',
                                        value: new Date()
                                    }
                                ]}
                                oneTap
                                style={{ width: '100%' }}
                            />
                        </InputGroup>
                    </Row>

                    <Row >
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship By Date (Optional):</InputGroup.Addon>
                            <DatePicker
                                container={() => mountRef.current}
                                placeholder="Ship By Date"
                                onChange={(e) => { onChangeState(e, 'ShipByDate') }}
                                value={state.ShipByDate}
                                format="YYYY-MM-DD"
                                // format="YYYY-MM-DD hh:mm aa"
                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                showMeridian
                                size="sm"
                                ranges={[
                                    {
                                        label: 'Now',
                                        value: new Date()
                                    }
                                ]}
                                oneTap
                                style={{ width: '100%' }}
                            />
                        </InputGroup>
                    </Row>


                    <Row >
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Deliver By Date (Optional):</InputGroup.Addon>
                            <DatePicker
                                container={() => mountRef.current}
                                placeholder="Deliver By Date"
                                onChange={(e) => { onChangeState(e, 'DeliverByDate') }}
                                value={state.DeliverByDate}
                                format="YYYY-MM-DD"
                                // format="YYYY-MM-DD hh:mm aa"
                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                showMeridian
                                size="sm"
                                ranges={[
                                    {
                                        label: 'Now',
                                        value: new Date()
                                    }
                                ]}
                                oneTap
                                style={{ width: '100%' }}
                            />
                        </InputGroup>
                    </Row>


                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship To Name (Optional):</InputGroup.Addon>
                            <Input value={state.ShipToName} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'ShipToName')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 50)} />
                        </InputGroup>
                    </Row>


                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship To Address Line 1 (Optional):</InputGroup.Addon>
                            <Input value={state.ShipToAddressLine1} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'ShipToAddressLine1')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 100)} />
                        </InputGroup>
                    </Row>

                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship To Address Line 2 (Optional):</InputGroup.Addon>
                            <Input value={state.ShipToAddressLine2} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'ShipToAddressLine2')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 100)} />
                        </InputGroup>
                    </Row>
                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship To City (Optional):</InputGroup.Addon>
                            <Input value={state.ShipToCity} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'ShipToCity')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 100)} />
                        </InputGroup>
                    </Row>
                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship To State (Optional):</InputGroup.Addon>
                            <Input value={state.ShipToState} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'ShipToState')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2)} />
                        </InputGroup>
                    </Row>
                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship To Zip (Optional):</InputGroup.Addon>
                            <Input value={state.ShipToZip} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'ShipToZip')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 9)} />
                        </InputGroup>
                    </Row>
                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Ship To Country (Optional):</InputGroup.Addon>
                            <Input value={state.ShipToCountry} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'ShipToCountry')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 2)} />
                        </InputGroup>
                    </Row>





                    <Row>
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <InputGroup.Addon>Notes (Optional):</InputGroup.Addon>
                            <Input value={state.Notes} className={'custom-input-number'} onChange={(e) => onChangeState(e, 'Notes')} scrollable={false} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 300)} />
                        </InputGroup>
                    </Row>

                </Paper>



                <Paper elevation={3} style={{ padding: '15px', marginTop: '15px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <DebouncedButton onClick={confirm} buttonText="Save" />

                        <Button variant="contained" color="primary" onClick={(e) => close(e)} style={{ marginLeft: '10px' }}>
                            Cancel
                        </Button>
                    </div>
                </Paper>





                <br />
                <br />


            </Container>


        </Drawer.Body>
        <Drawer.Footer>
            <br />
        </Drawer.Footer>

    </Drawer>

);



AddNewOrderOutboundDrawer.defaultProps = {
    show: false
};

export default AddNewOrderOutboundDrawer;


