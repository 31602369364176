import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as actions from '../../../redux/actions/labelGenActions';
import { appSettings } from '../../../app/appSettings'
import { withRouter } from 'react-router-dom';
import LabelGeneration from './LabelGeneration';
import queryString from 'query-string';
import { customerAccountInfo } from '../../../app/appConfig';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { appAPI } from '../../../app/appConfig';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    LabelGenApiRequestSuccessful: store.labelGenReducer.LabelGenApiRequestSuccessful,
    isLabelGenApiRequestLoading: store.labelGenReducer.isLabelGenApiRequestLoading,
    LabelGenApiRequestErrorCode: store.labelGenReducer.LabelGenApiRequestErrorCode,
    LabelGenApiRequestErrorMessage: store.labelGenReducer.LabelGenApiRequestErrorMessage,
    LabelGenApiRequestJson: store.labelGenReducer.LabelGenApiRequestJson,


    isBulkShipRequestLoading: store.labelGenReducer.isBulkShipRequestLoading,
    BulkLabelGenApiRequestJson: store.labelGenReducer.BulkLabelGenApiRequestJson,
    BulkShipRequestResponseCounter: store.labelGenReducer.BulkShipRequestResponseCounter,


    isLabelCreateHistoryLoading: store.labelGenReducer.isLabelCreateHistoryLoading,
    LabelCreateHistoryData: store.labelGenReducer.LabelCreateHistoryData,


    isLabelGenRequestRefLoading: store.labelGenReducer.isLabelGenRequestRefLoading,
    LabelGenRequestRefData: store.labelGenReducer.LabelGenRequestRefData,

    isLabelGenRequestRefInWorkLoading: store.labelGenReducer.isLabelGenRequestRefInWorkLoading,
    LabelGenRequestRefInWorkData: store.labelGenReducer.LabelGenRequestRefInWorkData,

    isLabelGenRequestPackageLoading: store.labelGenReducer.isLabelGenRequestPackageLoading,
    LabelGenRequestPackageData: store.labelGenReducer.LabelGenRequestPackageData,

    LabelGenRequestPackagePostSuccessful: store.labelGenReducer.LabelGenRequestPackagePostSuccessful,
    RequestPackageID: store.labelGenReducer.RequestPackageID,

    isLabelGenRequestPackageProductInfoLoading: store.labelGenReducer.isLabelGenRequestPackageProductInfoLoading,
    LabelGenRequestPackageProductInfoData: store.labelGenReducer.LabelGenRequestPackageProductInfoData,

    LabelGenRequestPackageProductInfoPostSuccessful: store.labelGenReducer.LabelGenRequestPackageProductInfoPostSuccessful,
    RequestProductInfoID: store.labelGenReducer.RequestProductInfoID,

    LabelGenRequestPostSuccessful: store.labelGenReducer.LabelGenRequestPostSuccessful,
    LabelRequestID: store.labelGenReducer.LabelRequestID,

    isLabelGenRequestCustomerWarehouseDefaultValuesLoading: store.labelGenReducer.isLabelGenRequestCustomerWarehouseDefaultValuesLoading,
    LabelGenRequestCustomerWarehouseDefaultValuesData: store.labelGenReducer.LabelGenRequestCustomerWarehouseDefaultValuesData,

    isLabelGenRequestCustomerWarehouseUserSavedValuesLoading: store.labelGenReducer.isLabelGenRequestCustomerWarehouseUserSavedValuesLoading,
    LabelGenRequestCustomerWarehouseUserSavedValuesData: store.labelGenReducer.LabelGenRequestCustomerWarehouseUserSavedValuesData,

    LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful: store.labelGenReducer.LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful,
    UserSavedValuesID: store.labelGenReducer.UserSavedValuesID,


    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,

    isLabelGenPortRefLoading: store.labelGenReducer.isLabelGenPortRefLoading,
    LabelGenPortRefData: store.labelGenReducer.LabelGenPortRefData,
});

const mapDispatchToProps = dispatch =>
({

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },

    labelGenApiRequestMainGet: (_email, _authToken, _param) => {
        dispatch(actions.labelGenApiRequestMainGet(_email, _authToken, _param))
    },

    labelGenRequestRefGet: (_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID) => {
        dispatch(actions.labelGenRequestRefGet(_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID))
    },

    labelGenRequestRefInWorkGet: (_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID) => {
        dispatch(actions.labelGenRequestRefInWorkGet(_email, _authToken, _WarehouseID, _CustomerID, _CarrierID, _LabelRequestID, _ResponseSuccess, _RequestDtStart, _RequestDtEnd, _ProdEnvironment, _LabelPortID))
    },
    labelGenRequestPost: (_email, _authToken, _LabelRequestID, _CustomerID, _WarehouseID, _CarrierID, _ProdEnvironment, _RequestDT, _ResponseSuccess, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipTo_Name, _ShipTo_AttentionName, _ShipTo_CompanyDisplayableName, _ShipTo_TaxIdentificationNumber, _ShipTo_Phone_Number, _ShipTo_Phone_Extension, _ShipTo_FaxNumber, _ShipTo_EMailAddress, _ShipTo_AddressLine1, _ShipTo_AddressLine2, _ShipTo_AddressLine3, _ShipTo_Address_City, _ShipTo_Address_StateProvinceCode, _ShipTo_Address_PostalCode, _ShipTo_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _CarrierServiceID, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _Delete, _LabelPortID) => {
        dispatch(actions.labelGenRequestPost(_email, _authToken, _LabelRequestID, _CustomerID, _WarehouseID, _CarrierID, _ProdEnvironment, _RequestDT, _ResponseSuccess, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipTo_Name, _ShipTo_AttentionName, _ShipTo_CompanyDisplayableName, _ShipTo_TaxIdentificationNumber, _ShipTo_Phone_Number, _ShipTo_Phone_Extension, _ShipTo_FaxNumber, _ShipTo_EMailAddress, _ShipTo_AddressLine1, _ShipTo_AddressLine2, _ShipTo_AddressLine3, _ShipTo_Address_City, _ShipTo_Address_StateProvinceCode, _ShipTo_Address_PostalCode, _ShipTo_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _CarrierServiceID, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _Delete, _LabelPortID))
    },

    labelGenRequestPackageGet: (_email, _authToken, _RequestPackageID, _LabelRequestID) => {
        dispatch(actions.labelGenRequestPackageGet(_email, _authToken, _RequestPackageID, _LabelRequestID))
    },

    labelGenRequestPackagePost: (_email, _authToken, _RequestPackageID, _LabelRequestID, _PackageIndex, _Package_Description, _Package_Packaging_Code, _Package_Dimensions_UOM_Code, _Package_Dimensions_Length, _Package_Dimensions_Width, _Package_Dimensions_Height, _Package_PackageWeight_UOM_Code, _Package_PackageWeight_UOM_Description, _Package_PackageWeight_Weight, _Package_PkgSO_DeliveryConfirmation_DCISType, _Package_PkgSO_DeclaredValue_CurrencyCode, _Package_PkgSO_DeclaredValue_MonetaryValue, _Delete,) => {
        dispatch(actions.labelGenRequestPackagePost(_email, _authToken, _RequestPackageID, _LabelRequestID, _PackageIndex, _Package_Description, _Package_Packaging_Code, _Package_Dimensions_UOM_Code, _Package_Dimensions_Length, _Package_Dimensions_Width, _Package_Dimensions_Height, _Package_PackageWeight_UOM_Code, _Package_PackageWeight_UOM_Description, _Package_PackageWeight_Weight, _Package_PkgSO_DeliveryConfirmation_DCISType, _Package_PkgSO_DeclaredValue_CurrencyCode, _Package_PkgSO_DeclaredValue_MonetaryValue, _Delete,))
    },

    labelGenRequestPackageProductInfoGet: (_email, _authToken, _RequestProductInfoID, _RequestPackageID, _LabelRequestID,) => {
        dispatch(actions.labelGenRequestPackageProductInfoGet(_email, _authToken, _RequestProductInfoID, _RequestPackageID, _LabelRequestID,))
    },

    labelGenRequestPackageProductInfoPost: (_email, _authToken, _RequestProductInfoID, _RequestPackageID, _PackageIndex, _LabelRequestID, _ProductInfoIndex, _ProductCode, _ProductDescription, _ProductUnitValue, _CurrencyCode, _ProductItemOrigin, _ProductHarmonizedCode, _ProductUnitWeight, _ProductQuantity, _ProductFabricContent, _Hazmat, _HazmatCode, _FDAFlag, _FDACode, _Delete) => {
        dispatch(actions.labelGenRequestPackageProductInfoPost(_email, _authToken, _RequestProductInfoID, _RequestPackageID, _PackageIndex, _LabelRequestID, _ProductInfoIndex, _ProductCode, _ProductDescription, _ProductUnitValue, _CurrencyCode, _ProductItemOrigin, _ProductHarmonizedCode, _ProductUnitWeight, _ProductQuantity, _ProductFabricContent, _Hazmat, _HazmatCode, _FDAFlag, _FDACode, _Delete))
    },

    

    labelGenRequestCustomerWarehouseDefaultValuesGet: (_email, _authToken, _CustomerID, _WarehouseID, _DefaultValuesID, _LabelPortID) => {
        dispatch(actions.labelGenRequestCustomerWarehouseDefaultValuesGet(_email, _authToken, _CustomerID, _WarehouseID, _DefaultValuesID, _LabelPortID))
    },
    labelGenRequestCustomerWarehouseUserSavedValuesPost: (_email, _authToken, _UserSavedValuesID, _CustomerID, _WarehouseID, _CarrierID, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _ProdEnvironment, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _PrinterIP, _PrinterPort, _CarrierServiceID, _Delete) => {
        dispatch(actions.labelGenRequestCustomerWarehouseUserSavedValuesPost(_email, _authToken, _UserSavedValuesID, _CustomerID, _WarehouseID, _CarrierID, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _ProdEnvironment, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _PrinterIP, _PrinterPort, _CarrierServiceID, _Delete))
    },

    labelGenPortRefGet: (_email, _authToken, _LabelPortID, _CustomerID) => {
        dispatch(actions.labelGenPortRefGet(_email, _authToken, _LabelPortID, _CustomerID))
    },
});





class LabelGenerationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

        this.props.labelGenPortRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null)

    }

    componentDidUpdate(prevProps, prevState) {

        // if (prevProps.LabelGenApiRequestSuccessful !== this.props.LabelGenApiRequestSuccessful && this.props.LabelGenApiRequestSuccessful) {
        //     try {
        //         var fileNameToDownload = this.props.LabelGenApiRequestJson.ShipmentIdentificationNumber + '.' + this.props.LabelGenApiRequestJson.LabelFormat
        //         this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.LabelGenApiRequestJson.labelUploadedFilename, fileNameToDownload, appAPI.API.data.LabelGenDownloadS3)


        //     }
        //     catch (e) { }
        // }

        // if (prevProps.LabelGenApiRequestErrorMessage !== this.props.LabelGenApiRequestErrorMessage && this.props.LabelGenApiRequestErrorMessage != null) {
        //     try {
        //         window.alert(this.props.LabelGenApiRequestErrorMessage)
        //     }
        //     catch (e) { }
        // }


    }




    handleUrlParam = () => {





    }


    render() {

        return (

            <LabelGeneration

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}



                LabelGenApiRequestSuccessful={this.props.LabelGenApiRequestSuccessful}
                isLabelGenApiRequestLoading={this.props.isLabelGenApiRequestLoading}
                LabelGenApiRequestErrorCode={this.props.LabelGenApiRequestErrorCode}
                LabelGenApiRequestErrorMessage={this.props.LabelGenApiRequestErrorMessage}
                LabelGenApiRequestJson={this.props.LabelGenApiRequestJson}


                isBulkShipRequestLoading={this.props.isBulkShipRequestLoading}
                BulkLabelGenApiRequestJson={this.props.BulkLabelGenApiRequestJson}
                BulkShipRequestResponseCounter={this.props.BulkShipRequestResponseCounter}

                CustomerName={this.state.CustomerName}
                EvolveClientID={this.state.EvolveClientID}
                CustomerNumber={this.state.CustomerNumber}

                ReturnName={this.state.ReturnName}
                ReturnAddressLine1={this.state.ReturnAddressLine1}
                ReturnAddressLine2={this.state.ReturnAddressLine2}
                ReturnCity={this.state.ReturnCity}
                ReturnState={this.state.ReturnState}
                ReturnPostcode={this.state.ReturnPostcode}
                ReturnCountryCode={this.state.ReturnCountryCode}





                labelGenApiRequestMainGet={this.props.labelGenApiRequestMainGet}





                isLabelGenRequestRefLoading={this.props.isLabelGenRequestRefLoading}
                LabelGenRequestRefData={this.props.LabelGenRequestRefData}

                isLabelGenRequestRefInWorkLoading={this.props.isLabelGenRequestRefInWorkLoading}
                LabelGenRequestRefInWorkData={this.props.LabelGenRequestRefInWorkData}

                isLabelGenRequestPackageLoading={this.props.isLabelGenRequestPackageLoading}
                LabelGenRequestPackageData={this.props.LabelGenRequestPackageData}

                LabelGenRequestPackagePostSuccessful={this.props.LabelGenRequestPackagePostSuccessful}
                RequestPackageID={this.props.RequestPackageID}

                isLabelGenRequestPackageProductInfoLoading={this.props.isLabelGenRequestPackageProductInfoLoading}
                LabelGenRequestPackageProductInfoData={this.props.LabelGenRequestPackageProductInfoData}

                LabelGenRequestPackageProductInfoPostSuccessful={this.props.LabelGenRequestPackageProductInfoPostSuccessful}
                RequestProductInfoID={this.props.RequestProductInfoID}

                LabelGenRequestPostSuccessful={this.props.LabelGenRequestPostSuccessful}
                LabelRequestID={this.props.LabelRequestID}


                isLabelGenRequestCustomerWarehouseUserSavedValuesLoading={this.props.isLabelGenRequestCustomerWarehouseUserSavedValuesLoading}
                LabelGenRequestCustomerWarehouseUserSavedValuesData={this.props.LabelGenRequestCustomerWarehouseUserSavedValuesData}

                LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful={this.props.LabelGenRequestCustomerWarehouseUserSavedValuesPostSuccessful}
                UserSavedValuesID={this.props.UserSavedValuesID}


                isLabelGenRequestCustomerWarehouseDefaultValuesLoading={this.props.isLabelGenRequestCustomerWarehouseDefaultValuesLoading}
                LabelGenRequestCustomerWarehouseDefaultValuesData={this.props.LabelGenRequestCustomerWarehouseDefaultValuesData}

                labelGenRequestCustomerWarehouseDefaultValuesGet={this.props.labelGenRequestCustomerWarehouseDefaultValuesGet}

                labelGenRequestCustomerWarehouseUserSavedValuesPost={this.props.labelGenRequestCustomerWarehouseUserSavedValuesPost}




                labelGenRequestRefInWorkGet={this.props.labelGenRequestRefInWorkGet}
                labelGenRequestRefGet={this.props.labelGenRequestRefGet}
                labelGenRequestPackageGet={this.props.labelGenRequestPackageGet}
                labelGenRequestPackagePost={this.props.labelGenRequestPackagePost}
                labelGenRequestPackageProductInfoGet={this.props.labelGenRequestPackageProductInfoGet}
                labelGenRequestPackageProductInfoPost={this.props.labelGenRequestPackageProductInfoPost}
                labelGenRequestPost={this.props.labelGenRequestPost}



                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}


                labelGenPortRefGet={this.props.labelGenPortRefGet}
                isLabelGenPortRefLoading={this.props.isLabelGenPortRefLoading}
                LabelGenPortRefData={this.props.LabelGenPortRefData}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LabelGenerationContainer));



