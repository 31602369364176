import * as types from '../constants/transActionTypes';
import { appSettings } from '../../app/appSettings'

const initialState = {

    isTransConsigneeLoading: false,
    TransConsigneeData: [],

    isTransCustomerRefLoading: false,
    TransCustomerRefData: [],

    isContainerLoading: false,
    ContainerData: [],

    isContainerJsonTreeLoading: false,
    ContainerJsonTreeData: [],

    ContainerPostSuccessful: false,
    NewContainerId: null,

    isContainerHistoryLoading: false,
    ContainerHistoryData: [],

    ShipmentReceivePostSuccessful: false,

    isShipmentReceiveLoading: false,
    ShipmentReceiveData: [],

    MbolPostSuccessful: false,
    NewMbolId: null,

    HbolPostSuccessful: false,
    NewHbolId: null,

    PoPostSuccessful: false,
    NewPoId: null,

    isLoadLoading: false,
    LoadData: [],

    isLoadPoLoading: false,
    LoadPoData: [],

    isLoadPoHistoryLoading: false,
    LoadPoHistoryData: [],

    isOutboundLoading: false,
    OutboundData: [],

    isPoLoading: false,
    PoData: [],


    isPoLocationLoading: false,
    PoLocationData: [],
    PoLocationDataGrouped: [],

    PoLocationPostSuccessful: false,

    isPoLoadLocationHistoryLoading: false,
    PoLoadLocationHistoryData: [],

    isPoRecoveryLoading: false,
    PoRecoveryData: [],

    LoadPostSuccessful: false,
    LoadRemovePostSuccessful: false,

    isPoHistoryLoading: false,
    PoHistoryData: [],

    isTransProjectLoading: false,
    TransProjectData: [],

    isTransDestinationLoading: false,
    TransDestinationData: [],

    isTransContainerFileUploadLoading: false,
    TransContainerFileUploadData: [],

    TransContainerFileUploadPostSuccessful: false


};

function checkStatusCode(payload) {
    var errorType = 'None'
    try {
        errorType = payload['errorType']
    }
    catch (e) {
    }
    if (typeof (errorType) !== 'undefined') {
        return false
    }
    else {
        var res = payload['statusCode']
        try {
            if (res === "200" || res == 200 || res == 600 || res === "600") { return true }
            else { return false }
        }
        catch (e) {
            return false
        }
    }
}

function actionOnError(proc) {
    // sessionStorage.clear();
    // window.location.reload();
    // // window.alert('Session expired, please log in again.')

    return initialState


}



export const TransReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state


        case types.ON_ERROR:
            var errorstate = actionOnError(state)
            return errorstate;






        /* ################################### TRANS ACTIONS ################################### */


        case types.TRANS_CONSIGNEE_GET:
            return Object.assign({}, state, {
                isTransConsigneeLoading: true
            });
        case types.TRANS_CONSIGNEE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isTransConsigneeLoading: false,
                TransConsigneeData: newData.data
            });


        case types.TRANS_CUSTOMERREF_GET:
            return Object.assign({}, state, {
                isTransCustomerRefLoading: true
            });
        case types.TRANS_CUSTOMERREF_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isTransCustomerRefLoading: false,
                TransCustomerRefData: newData.data
            });



        case types.TRANS_PROJECT_GET:
            return Object.assign({}, state, {
                isTransProjectLoading: true
            });
        case types.TRANS_PROJECT_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isTransProjectLoading: false,
                TransProjectData: newData.data
            });


        case types.TRANS_DESTINATION_GET:
            return Object.assign({}, state, {
                isTransDestinationLoading: true
            });
        case types.TRANS_DESTINATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isTransDestinationLoading: false,
                TransDestinationData: newData.data
            });





        case types.CONTAINER_GET:
            return Object.assign({}, state, {
                isContainerLoading: true
            });
        case types.CONTAINER_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            try {
                if (msgCode === 400) {
                    return Object.assign({}, state, {
                        isContainerLoading: false,
                        ContainerData: []
                    });

                }
                else {
                    return Object.assign({}, state, {
                        isContainerLoading: false,
                        ContainerData: newData.data
                    });
                }
            }
            catch (e) {
                return Object.assign({}, state, {
                    isContainerLoading: false,
                    ContainerData: newData.data
                });
            }




        case types.POST_CONTAINER_GET:
            return Object.assign({}, state, {
                ContainerPostSuccessful: false,
                NewContainerId: null
            });
        case types.POST_CONTAINER_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    ContainerPostSuccessful: true,
                    NewContainerId: newData.data[0]['NewContainerId']

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    ContainerPostSuccessful: false
                });
            }



        case types.CONTAINER_JSON_TREE_GET:
            return Object.assign({}, state, {
                isContainerJsonTreeLoading: true
            });
        case types.CONTAINER_JSON_TREE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            try {
                var newData = JSON.parse(action.payload.body);
                var newStringData = newData.data[0]['ContainerJson']

                // newStringData = newStringData.replaceAll(',"mr":[{"hr":[{"pr":[{"custref":[{}]}]}]}]', ',"children":[]')
                // newStringData = newStringData.replaceAll(',"hr":[{"pr":[{"custref":[{}]}]}]', ',"children":[]')
                // newStringData = newStringData.replaceAll(',"pr":[{"custref":[{}]}]', ',"children":[]')

                newStringData = newStringData.replaceAll(',"mr":[{"hr":[{"pr":[{"custref":[{"proref":[{}]}]}]}]}]', ',"children":[]')
                newStringData = newStringData.replaceAll(',"hr":[{"pr":[{"custref":[{"proref":[{}]}]}]}]', ',"children":[]')
                newStringData = newStringData.replaceAll(',"pr":[{"custref":[{"proref":[{}]}]}]', ',"children":[]')


                // "custref": [{}]
                // {"data": [{"ContainerJson": "[{"ContainerID":22,"Container":"ContainerTest2","mr":[{"MbolID":14,"MBOL":"Mbol2","hr":[{"pr":[{"custref":[{}]}]}]}]}]"}]}

                newStringData = newStringData.replaceAll('"ContainerID":', '"value":"C')
                // newStringData = newStringData.replaceAll('ContainerID', 'value')
                // newStringData = newStringData.replaceAll(',"Container', '","label')
                newStringData = newStringData.replaceAll(',"Container":"', '","label":"Container: ')


                newStringData = newStringData.replaceAll('"MbolID":', '"value":"M')
                // newStringData = newStringData.replaceAll('MbolID', 'value')
                // newStringData = newStringData.replaceAll('"MbolID":"', '"value":"M')
                // newStringData = newStringData.replaceAll(',"MBOL', '","label')
                newStringData = newStringData.replaceAll(',"MBOL":"', '","label":"MBOL: ')


                newStringData = newStringData.replaceAll('"HbolID":', '"value":"H')
                // newStringData = newStringData.replaceAll('HbolID', 'value')
                // newStringData = newStringData.replaceAll('"HbolID":"', '"value":"H')
                // newStringData = newStringData.replaceAll(',"HBOL', '","label')
                newStringData = newStringData.replaceAll(',"HBOL":"', '","label":"HBOL: ')

                newStringData = newStringData.replaceAll('"PoID":', '"value":"P')
                // newStringData = newStringData.replaceAll('PoID', 'value')
                // newStringData = newStringData.replaceAll('"PoID":"', '"value":"P')
                // newStringData = newStringData.replaceAll(',"PO', '","label')
                newStringData = newStringData.replaceAll(',"PO":"', '","label":"PO: ')

                newStringData = newStringData.replaceAll('mr', 'children')
                newStringData = newStringData.replaceAll('hr', 'children')
                newStringData = newStringData.replaceAll('pr', 'children')

                var newJsonData = JSON.parse(newStringData)

                return Object.assign({}, state, {
                    isContainerJsonTreeLoading: false,
                    ContainerJsonTreeData: newJsonData
                });
            }
            catch (e) { }



        case types.TRANS_CONTAINER_HISTORY_GET:
            return Object.assign({}, state, {
                isContainerHistoryLoading: true
            });
        case types.TRANS_CONTAINER_HISTORY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isContainerHistoryLoading: false,
                ContainerHistoryData: newData.data
            });






        case types.SHIPMENT_RECEIVE_GET:
            return Object.assign({}, state, {
                isShipmentReceiveLoading: true
            });
        case types.SHIPMENT_RECEIVE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isShipmentReceiveLoading: false,
                ShipmentReceiveData: newData.data
            });


        case types.POST_SHIPMENT_RECEIVE_GET:
            return Object.assign({}, state, {
                ShipmentReceivePostSuccessful: false
            });
        case types.POST_SHIPMENT_RECEIVE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    ShipmentReceivePostSuccessful: true
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    ShipmentReceivePostSuccessful: false
                });
            }


        case types.POST_MBOL_GET:
            return Object.assign({}, state, {
                MbolPostSuccessful: false,
                NewMbolId: null
            });
        case types.POST_MBOL_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    NewMbolId: newData.data[0]['NewMbolId'],
                    MbolPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    MbolPostSuccessful: false
                });
            }



        case types.POST_HBOL_GET:
            return Object.assign({}, state, {
                HbolPostSuccessful: false,
                NewHbolId: null
            });
        case types.POST_HBOL_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    HbolPostSuccessful: true,
                    NewHbolId: newData.data[0]['NewHbolId']

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    HbolPostSuccessful: false
                });
            }




        case types.TRANS_LOAD_GET:
            return Object.assign({}, state, {
                isLoadLoading: true
            });
        case types.TRANS_LOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLoadLoading: false,
                LoadData: newData.data
            });

        case types.TRANS_LOAD_PO_GET:
            return Object.assign({}, state, {
                isLoadPoLoading: true
            });
        case types.TRANS_LOAD_PO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLoadPoLoading: false,
                LoadPoData: newData.data
            });



        case types.TRANS_POST_LOAD_GET:
            return Object.assign({}, state, {
                LoadPostSuccessful: false
            });
        case types.TRANS_POST_LOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    LoadPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    LoadPostSuccessful: false
                });
            }





        case types.TRANS_POST_LOAD_REMOVE_GET:
            return Object.assign({}, state, {
                LoadRemovePostSuccessful: false
            });
        case types.TRANS_POST_LOAD_REMOVE_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    LoadRemovePostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    LoadRemovePostSuccessful: false
                });
            }



        case types.TRANS_LOAD_PO_HISTORY_GET:
            return Object.assign({}, state, {
                isLoadPoHistoryLoading: true
            });
        case types.TRANS_LOAD_PO_HISTORY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isLoadPoHistoryLoading: false,
                LoadPoHistoryData: newData.data
            });

        case types.TRANS_OUTBOUND_GET:
            return Object.assign({}, state, {
                isOutboundLoading: true
            });
        case types.TRANS_OUTBOUND_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isOutboundLoading: false,
                OutboundData: newData.data
            });

        case types.PO_GET:
            return Object.assign({}, state, {
                isPoLoading: true
            });
        case types.PO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isPoLoading: false,
                PoData: newData.data
            });


        case types.POST_PO_GET:
            return Object.assign({}, state, {
                PoPostSuccessful: false,
                NewPoId: null
            });
        case types.POST_PO_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    PoPostSuccessful: true,
                    NewPoId: newData.data[0]['NewPoId']
                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    PoPostSuccessful: false
                });
            }

        case types.TRANS_PO_LOCATION_GET:
            return Object.assign({}, state, {
                isPoLocationLoading: true
            });
        case types.TRANS_PO_LOCATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);

            var newLocationDict = newData.data.reduce((h, poItem) => Object.assign(h, { [poItem.PoID]: (h[poItem.PoID] || []).concat({ WarehouseLocationID: poItem.WarehouseLocationID, WarehouseLocationName: poItem.WarehouseLocationName, Cases: poItem.Cases, Pallets: poItem.Pallets }) }), {})

            return Object.assign({}, state, {
                isPoLocationLoading: false,
                PoLocationData: newData.data,
                PoLocationDataGrouped: newLocationDict
            });


        case types.TRANS_POST_PO_LOCATION_GET:
            return Object.assign({}, state, {
                PoLocationPostSuccessful: false
            });
        case types.TRANS_POST_PO_LOCATION_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    PoLocationPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    PoLocationPostSuccessful: false
                });
            }

        case types.TRANS_PO_LOAD_LOCATION_HISTORY_GET:
            return Object.assign({}, state, {
                isPoLoadLocationHistoryLoading: true
            });
        case types.TRANS_PO_LOAD_LOCATION_HISTORY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isPoLoadLocationHistoryLoading: false,
                PoLoadLocationHistoryData: newData.data
            });

        case types.PO_RECOVERY_GET:
            return Object.assign({}, state, {
                isPoRecoveryLoading: true
            });
        case types.PO_RECOVERY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isPoRecoveryLoading: false,
                PoRecoveryData: newData.data
            });


        case types.TRANS_PO_HISTORY_GET:
            return Object.assign({}, state, {
                isPoHistoryLoading: true
            });
        case types.TRANS_PO_HISTORY_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                return actionOnError()
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isPoHistoryLoading: false,
                PoHistoryData: newData.data
            });





        case types.TRANS_CONTAINER_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                isTransContainerFileUploadLoading: true
            });
        case types.TRANS_CONTAINER_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isTransContainerFileUploadLoading: false,
                TransContainerFileUploadData: newData.data
            });


        case types.TRANS_POST_CONTAINER_FILEUPLOAD_GET:
            return Object.assign({}, state, {
                TransContainerFileUploadPostSuccessful: false
            });
        case types.TRANS_POST_CONTAINER_FILEUPLOAD_COMPLETE:
            if (!checkStatusCode(action.payload)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);
            var msgCode;
            try {
                msgCode = newData.data[0].MsgCode
            }
            catch (e) {
                msgCode = 200
            }
            if (msgCode === 200) {
                // window.alert('Save Successful')
                return Object.assign({}, state, {
                    TransContainerFileUploadPostSuccessful: true

                });
            }
            else {
                try {
                    window.alert(newData.data[0].MSG)
                }
                catch (e) {
                    window.alert('Error, please try again.')
                }
                return Object.assign({}, state, {
                    TransContainerFileUploadPostSuccessful: false
                });
            }






        default:
            return state;
    }
};





export default TransReducer;
