export const InventoryLoadUnitDataElements = [
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'SkuLotCode',
        displayName: 'Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'SkuSerialNumber',
        displayName: 'Serial Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'SkuUnits',
        displayName: 'Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'SkuCaseBarcode',
        displayName: 'Case Barcode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 220
    },
    {
        dBKey: 'SkuCaseLotCode',
        displayName: 'Case Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'SkuCaseSerialNumber',
        displayName: 'Case Serial Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    // {
    //     dBKey: 'SkuPalletBarcode',
    //     displayName: 'Pallet Barcode',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 220
    // },





]


export default InventoryLoadUnitDataElements

