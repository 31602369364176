import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Button,
    ButtonToolbar,
    Form,
    Checkbox,
    FormGroup,
    InputNumber,
    DatePicker,
    InputPicker,
    InputGroup,
    Row,
    Input

} from 'rsuite';
import { textNumbersSymbolsInputmask, limitCharacterLength, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../../resources/logic/inputMasks';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const styles = {
    marginBottom: 10,
    width: '100%'
};

const mountRef = React.createRef();

const EditPalletDrawer = ({ show, onChangeEditPallet, confirm, close, state, ...props }) => (

    show === false ?
        <div></div> :

        <Drawer show={show} onHide={close} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
            <Drawer.Header>
                <Drawer.Title>Edit Pallet</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body> <div ref={mountRef}>

                <Form fluid>




                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <Checkbox checked={state.Oversize} onChange={(e, checked) => { onChangeEditPallet(checked, 'Oversize') }} >Oversize</Checkbox>
                    </InputGroup>



                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <InputGroup.Addon>Dims (LxW):</InputGroup.Addon>
                        <InputNumber min={1} value={state.Length} className={'custom-input-number'} onChange={(e) => onChangeEditPallet(e, 'Length')} scrollable={false} />

                        <InputGroup.Addon>x</InputGroup.Addon>

                        <InputNumber min={1} value={state.Width} className={'custom-input-number'} onChange={(e) => onChangeEditPallet(e, 'Width')} scrollable={false} />
                    </InputGroup>



                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <InputGroup.Addon>Notes:</InputGroup.Addon>
                        <Input value={state.Notes} onChange={(e) => { onChangeEditPallet(e, 'Notes') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 400)} />
                    </InputGroup>





                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                        <InputGroup.Addon>Edit Recovery Date:</InputGroup.Addon>
                        <DatePicker
                            container={() => mountRef.current}
                            placeholder="Edit Recovery Date"
                            onChange={(e) => { onChangeEditPallet(e, 'RecoveryDateEdit') }}
                            value={state.RecoveryDateEdit || state.CreateDT}
                            format="YYYY-MM-DD hh:mm aa"
                            // defaultValue={state.CreateDT}
                            showMeridian
                            ranges={[
                                {
                                    label: 'Now',
                                    value: new Date()
                                }
                            ]}
                            style={{ width: '100%' }}
                            // oneTap
                        />
                    </InputGroup>

                    <FormGroup>
                        <ButtonToolbar>
                            <DebouncedButton onClick={confirm} buttonText="Submit" appearance="primary" />
                            <Button onClick={close} appearance="default">Cancel</Button>
                        </ButtonToolbar>
                    </FormGroup>
                </Form>

            </div> </Drawer.Body>
            <Drawer.Footer>
                <br />
            </Drawer.Footer>

        </Drawer>

);


EditPalletDrawer.defaultProps = {
    show: false
};



export default EditPalletDrawer;


