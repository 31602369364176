import React, {
    PureComponent
} from 'react';
import { Col, InputGroup, FormGroup, Input, Panel, Row, Checkbox } from 'rsuite';
import { textStringInputMask, phoneInputMask, limitNumberLengthMask, lettersNumbersDashesInputMask, zipCodeInputMask, limitUpperCaseLengthMask, emailInputMask, } from '../../../resources/logic/inputMasks';


const styles = {
    marginBottom: '10px',
    width: '100%'
};
const stylesRequiredInput = {
    backgroundColor: '#FFE4E1'
};
const stylesInputPicker = {
    width: '100%'
};
const stylesRequiredAddOn = {
    backgroundColor: "rgb(255,228,225)"
};

const mountRef = React.createRef();

function StepAddress({
    handleChangeFunc,

    state, ...props
}) {
    return (

        <div ref={mountRef}>

            <Panel header={'Ship To'} bordered style={{ marginBottom: 10 }} >

                <Row>
                    <Col md={24} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>Name (Required):</InputGroup.Addon>
                            <Input value={state.ShipTo_Name}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_Name') }}
                                onInput={(e) => e.target.value = textStringInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>Address Line 1 (Required):</InputGroup.Addon>
                            <Input value={state.ShipTo_AddressLine1}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_AddressLine1') }}
                                onInput={(e) => e.target.value = textStringInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>Address Line 2:</InputGroup.Addon>
                            <Input value={state.ShipTo_AddressLine2}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_AddressLine2') }}
                                onInput={(e) => e.target.value = textStringInputMask(e)}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>City (Required):</InputGroup.Addon>
                            <Input value={state.ShipTo_Address_City}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_Address_City') }}
                                onInput={(e) => e.target.value = textStringInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>State (Required):</InputGroup.Addon>
                            <Input value={state.ShipTo_Address_StateProvinceCode}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_Address_StateProvinceCode') }}
                                onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>Postal Code (Required):</InputGroup.Addon>
                            <Input value={state.ShipTo_Address_PostalCode}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_Address_PostalCode') }}
                                onInput={(e) => e.target.value = zipCodeInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>Country Code (Required):</InputGroup.Addon>
                            <Input value={state.ShipTo_Address_CountryCode}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_Address_CountryCode') }}
                                onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>Phone Number (Required):</InputGroup.Addon>
                            <Input value={state.ShipTo_Phone_Number}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_Phone_Number') }}
                                onInput={(e) => e.target.value = phoneInputMask(e)}
                                style={stylesRequiredInput}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <InputGroup.Addon>Email Address:</InputGroup.Addon>
                            <Input value={state.ShipTo_EMailAddress}
                                onChange={(e) => { handleChangeFunc(e, 'ShipTo_EMailAddress') }}
                                onInput={(e) => e.target.value = emailInputMask(e)}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={24} sm={24} >
                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                            <Checkbox checked={state.ResidentialAddressIndicator} onChange={(e, checked) => handleChangeFunc(checked, 'ResidentialAddressIndicator')} >Residential Address</Checkbox>
                        </InputGroup>
                    </Col>
                </Row>


            </Panel>


            {state.AllowEditShipperAddress === true ?

                <div>

                    <Panel header={'Shipper'} bordered style={{ marginBottom: 10 }} >

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Shipper Name (Required):</InputGroup.Addon>
                                    <Input value={state.Shipper_Name}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Name') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>

                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Attention Name:</InputGroup.Addon>
                                    <Input value={state.Shipper_AttentionName}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_AttentionName') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Company Displayable Name:</InputGroup.Addon>
                                    <Input value={state.Shipper_CompanyDisplayableName}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_CompanyDisplayableName') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>

                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Tax Identification Number:</InputGroup.Addon>
                                    <Input value={state.Shipper_TaxIdentificationNumber}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_TaxIdentificationNumber') }}
                                        onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Phone Number (Required):</InputGroup.Addon>
                                    <Input value={state.Shipper_Phone_Number}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Phone_Number') }}
                                        onInput={(e) => e.target.value = phoneInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Phone Extension:</InputGroup.Addon>
                                    <Input value={state.Shipper_Phone_Extension}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Phone_Extension') }}
                                        onInput={(e) => e.target.value = limitNumberLengthMask(e, 4)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Shipper Number:</InputGroup.Addon>
                                    <Input value={state.Shipper_ShipperNumber}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_ShipperNumber') }}
                                        onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={8} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Fax Number:</InputGroup.Addon>
                                    <Input value={state.Shipper_FaxNumber}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_FaxNumber') }}
                                        onInput={(e) => e.target.value = phoneInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={8} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Email Address:</InputGroup.Addon>
                                    <Input value={state.Shipper_EMailAddress}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_EMailAddress') }}
                                        onInput={(e) => e.target.value = emailInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Address Line 1 (Required):</InputGroup.Addon>
                                    <Input value={state.Shipper_Address_AddressLine1}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Address_AddressLine1') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Address Line 2:</InputGroup.Addon>
                                    <Input value={state.Shipper_Address_AddressLine2}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Address_AddressLine2') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>City (Required):</InputGroup.Addon>
                                    <Input value={state.Shipper_Address_City}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Address_City') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>State Province Code (Required):</InputGroup.Addon>
                                    <Input value={state.Shipper_Address_StateProvinceCode}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Address_StateProvinceCode') }}
                                        onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Postal Code (Required):</InputGroup.Addon>
                                    <Input value={state.Shipper_Address_PostalCode}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Address_PostalCode') }}
                                        onInput={(e) => e.target.value = zipCodeInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Country Code (Required):</InputGroup.Addon>
                                    <Input value={state.Shipper_Address_CountryCode}
                                        onChange={(e) => { handleChangeFunc(e, 'Shipper_Address_CountryCode') }}
                                        onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>


                    </Panel>



                    <Panel header={'Ship From'} bordered style={{ marginBottom: 10 }} >

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Ship From Name (Required):</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Name}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Name') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Attention Name:</InputGroup.Addon>
                                    <Input value={state.ShipFrom_AttentionName}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_AttentionName') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Company Displayable Name:</InputGroup.Addon>
                                    <Input value={state.ShipFrom_CompanyDisplayableName}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_CompanyDisplayableName') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Tax Identification Number:</InputGroup.Addon>
                                    <Input value={state.ShipFrom_TaxIdentificationNumber}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_TaxIdentificationNumber') }}
                                        onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Phone Number:</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Phone_Number}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Phone_Number') }}
                                        onInput={(e) => e.target.value = phoneInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={8} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Phone Extension:</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Phone_Extension}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Phone_Extension') }}
                                        onInput={(e) => e.target.value = limitNumberLengthMask(e, 4)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={8} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Fax Number:</InputGroup.Addon>
                                    <Input value={state.ShipFrom_FaxNumber}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_FaxNumber') }}
                                        onInput={(e) => e.target.value = phoneInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Address Line 1 (Required):</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Address_AddressLine1}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Address_AddressLine1') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Address Line 2:</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Address_AddressLine2}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Address_AddressLine2') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>City (Required):</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Address_City}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Address_City') }}
                                        onInput={(e) => e.target.value = textStringInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>State Province Code (Required):</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Address_StateProvinceCode}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Address_StateProvinceCode') }}
                                        onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Postal Code (Required):</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Address_PostalCode}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Address_PostalCode') }}
                                        onInput={(e) => e.target.value = zipCodeInputMask(e)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={12} sm={24} style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <InputGroup.Addon>Country Code (Required):</InputGroup.Addon>
                                    <Input value={state.ShipFrom_Address_CountryCode}
                                        onChange={(e) => { handleChangeFunc(e, 'ShipFrom_Address_CountryCode') }}
                                        onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)}
                                        style={stylesRequiredInput}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>



                    </Panel>
                </div>

                :

                <div></div>

            }






        </div >


    );
}


export default StepAddress;