export const InventoryLotOrderOutboundSkuEachLocationsDataElements = [
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 220
    },

    {
        dBKey: 'UnitsInWhse',
        displayName: 'Units In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },


    

    {
        dBKey: 'StatusName',
        displayName: 'Case Status',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'CaseBarcode',
        displayName: 'Case Barcode',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 220
    },
    {
        dBKey: 'PalletBarcode',
        displayName: 'Pallet Barcode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 220
    },




    {
        dBKey: 'WarehouseLocationName',
        displayName: 'Warehouse Location',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
 
    {
        dBKey: 'LotCode',
        displayName: 'Lot Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'SerialNumber',
        displayName: 'Serial Number',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
]


export default InventoryLotOrderOutboundSkuEachLocationsDataElements