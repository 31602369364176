import React, {
    PureComponent
} from 'react';
import { Modal, Button, Row, Col, InputGroup, InputNumber, Panel, Form, InputPicker, Input } from 'rsuite';
import { lettersNumbersDashesSpacesCommasInputMask } from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

function QuoteShipmentStatusModal({
    onClickApproveRejectQuote,
    quoteActionModalShown,
    handleCloseActionModal,
    state, ...props

}) {
    return (

        <div className="modal-container">
            <Modal backdrop={'static'} keyboard={false} show={quoteActionModalShown} onHide={handleCloseActionModal}>
                <Modal.Header>
                    <Modal.Title>Accept/Reject Quote:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button onClick={(e) => { onClickApproveRejectQuote(e, true) }} appearance="primary">
                        Accept
                    </Button>
                    <Button onClick={(e) => { onClickApproveRejectQuote(e, false) }} appearance="subtle">
                        Reject
                    </Button>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default QuoteShipmentStatusModal;