import * as types from '../constants/visibilityActionTypes';

/* ################################### VISIBILITY ACTIONS ################################### */

export const visibilityDashboardStatsGet = (_email, _authToken, _WarehouseID, _CustomerID, _PackageID, _DateStart, _DateEnd,) =>
({
    type: types.VISIBILITY_DASHBOARD_STATS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PackageID: _PackageID,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
    }
});

export const visibilityDashboardPackageListGet = (_email, _authToken, _WarehouseID, _CustomerID, _PackageID, _TrackingNo, _DateStart, _DateEnd, _AddressUpdate, _TransitException, _DeliveryException, _Delivered, _Damaged, _InsufficientAdd, _RTS) =>
({
    type: types.VISIBILITY_DASHBOARD_PACKAGE_LIST_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        WarehouseID: _WarehouseID,
        CustomerID: _CustomerID,
        PackageID: _PackageID,
        TrackingNo: _TrackingNo,
        DateStart: _DateStart,
        DateEnd: _DateEnd,
        AddressUpdate: _AddressUpdate,
        TransitException: _TransitException,
        DeliveryException: _DeliveryException,
        Delivered: _Delivered,

        Damaged: _Damaged,
        InsufficientAdd: _InsufficientAdd,
        RTS: _RTS 
    }
});

export const visibilityPackageGet = (_email, _authToken, _PackageID,) =>
({
    type: types.VISIBILITY_PACKAGE_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        PackageID: _PackageID,
    }
});

export const visibilityPackageUpsEventsGet = (_email, _authToken, _PackageID,) =>
({
    type: types.VISIBILITY_PACKAGE_UPS_EVENTS_GET,
    payload: {
        email: _email,
        authToken: _authToken,

        PackageID: _PackageID,
    }
});



































