import React, {
    PureComponent
  }                         from 'react';
import { Toggle } from 'rsuite';



function RToggle(
    isMobile,
    label,
    onChange
    
  ) {

    let rToggle;

    try{
        rToggle =    
        <div style={{marginBottom: '5px'}}>
            <Toggle appearance="primary" checkedChildren="On" block onChange={(e) => onChange(e)} size={isMobile ? 'sm' : 'md' } style={ isMobile ? {marginBottom:'5px'} : {fontWeight: 'bold'} } /> {label}
        </div>
    }
    catch(e){}

    return (

        rToggle

    )
    }


export default RToggle;


