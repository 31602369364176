import { connect } from 'react-redux';
import React, { Component } from 'react';
import ContainerDetails from './ContainerDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import * as userActions from '../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isContainerLoading: store.transReducer.isContainerLoading,
    ContainerData: store.transReducer.ContainerData,

    isContainerJsonTreeLoading: store.transReducer.isContainerJsonTreeLoading,
    ContainerJsonTreeData: store.transReducer.ContainerJsonTreeData,

    isPoRecoveryLoading: store.transReducer.isPoRecoveryLoading,
    PoRecoveryData: store.transReducer.PoRecoveryData,

    isPoLoading: store.transReducer.isPoLoading,
    PoData: store.transReducer.PoData,

    ShipmentReceivePostSuccessful: store.transReducer.ShipmentReceivePostSuccessful,


    isShipmentReceiveLoading: store.transReducer.isShipmentReceiveLoading,
    ShipmentReceiveData: store.transReducer.ShipmentReceiveData,


    ContainerPostSuccessful: store.transReducer.ContainerPostSuccessful,


    isContainerHistoryLoading: store.transReducer.isContainerHistoryLoading,
    ContainerHistoryData: store.transReducer.ContainerHistoryData,

    isTransCustomerRefLoading: store.transReducer.isTransCustomerRefLoading,
    TransCustomerRefData: store.transReducer.TransCustomerRefData,

    PoPostSuccessful: store.transReducer.PoPostSuccessful,


    isPoLocationLoading: store.transReducer.isPoLocationLoading,
    PoLocationData: store.transReducer.PoLocationData,

    PoLocationPostSuccessful: store.transReducer.PoLocationPostSuccessful,

    isTransContainerFileUploadLoading: store.transReducer.isTransContainerFileUploadLoading,
    TransContainerFileUploadData: store.transReducer.TransContainerFileUploadData,

    TransContainerFileUploadPostSuccessful: store.transReducer.TransContainerFileUploadPostSuccessful,

});

const mapDispatchToProps = dispatch =>
({

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },



    transContainerGet: (_email, _authToken, _ContainerID, _Container, _ContainerStatusID, _LCLFCL, _DeliveryAppointmentStart, _DeliveryAppointmentEnd, _ReceivedDTStart, _ReceivedDTEnd, _EtdStart, _EtdEnd, _EtaStart, _EtaEnd, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) => {
        dispatch(actions.transContainerGet(_email, _authToken, _ContainerID, _Container, _ContainerStatusID, _LCLFCL, _DeliveryAppointmentStart, _DeliveryAppointmentEnd, _ReceivedDTStart, _ReceivedDTEnd, _EtdStart, _EtdEnd, _EtaStart, _EtaEnd, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID))
    },

    transContainerJsonTreeGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) => {
        dispatch(actions.transContainerJsonTreeGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID))
    },

    transPoRecoveryGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoRecoveryGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID))
    },

    transPoGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID, _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(actions.transPoGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID, _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd))
    },

    transShipmentReceiveGet: (_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _WarehouseID, _CustomerID) => {
        dispatch(actions.transShipmentReceiveGet(_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _WarehouseID, _CustomerID))
    },
    transCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    transShipmentReceivePost: (_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _CaseCountExpected, _CasesReceived, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID) => {
        dispatch(actions.transShipmentReceivePost(_email, _authToken, _ContainerID, _MbolID, _HbolID, _PoID, _CaseCountExpected, _CasesReceived, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID))
    },

    transContainerPost: (_email, _authToken, _ContainerID, _Container, _LCLFCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID) => {
        dispatch(actions.transContainerPost(_email, _authToken, _ContainerID, _Container, _LCLFCL, _DeliveryAppointment, _ETD, _ETA, _CurrentContainerStatusID, _Notes, _WarehouseID, _CustomerID))
    },

    transContainerHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container) => {
        dispatch(actions.transContainerHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container))
    },

    transPoPost: (_email, _authToken, _PoID, _PO, _HbolID, _CustomerRef, _CaseCount, _PalletCount, _Notes, _Project, _Delete, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoPost(_email, _authToken, _PoID, _PO, _HbolID, _CustomerRef, _CaseCount, _PalletCount, _Notes, _Project, _Delete, _WarehouseID, _CustomerID))
    },

    transPoLocationGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoLocationGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID))
    },
    transPoLocationPost: (_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize) => {
        dispatch(actions.transPoLocationPost(_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize))
    },

    transContainerFileUploadGet: (_email, _authToken, _CustomerID, _FileUploadID, _ContainerID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(actions.transContainerFileUploadGet(_email, _authToken, _CustomerID, _FileUploadID, _ContainerID, _FileName, _S3FileName, _FileType, _FileDelete))
    },
    transContainerFileUploadPost: (_email, _authToken, _CustomerID, _FileUploadID, _ContainerID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(actions.transContainerFileUploadPost(_email, _authToken, _CustomerID, _FileUploadID, _ContainerID, _FileName, _S3FileName, _FileType, _FileDelete))
    },
});

class ContainerDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.ShipmentReceivePostSuccessful !== this.props.ShipmentReceivePostSuccessful && this.props.ShipmentReceivePostSuccessful) {
            this.handleUrlParam();
        }

        if (prevProps.ContainerPostSuccessful !== this.props.ContainerPostSuccessful && this.props.ContainerPostSuccessful) {
            this.handleUrlParam();
        }


        if (prevProps.PoPostSuccessful !== this.props.PoPostSuccessful && this.props.PoPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }


        if (prevProps.PoLocationPostSuccessful !== this.props.PoLocationPostSuccessful && this.props.PoLocationPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }

        if (prevProps.TransContainerFileUploadPostSuccessful !== this.props.TransContainerFileUploadPostSuccessful && this.props.TransContainerFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.transContainerFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
        }
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        try {
            var paramMbol = formatStringForAPI(values.mbol)
        }
        catch (e) {
            var paramMbol = null
        }
        try {
            var paramHbol = formatStringForAPI(values.hbol)
        }
        catch (e) {
            var paramHbol = null
        }
        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);

        this.props.transContainerGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramContainerID, paramContainer, paramStatus, null, null, null, null, null, null, null, null, null, null, paramMbol, null, paramHbol, null, paramPo, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramContainerID, paramContainer, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transPoRecoveryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramContainerID, paramContainer, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, paramContainer, null, null, null, null, null, null, null, null, null, null, null, null);
        this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transContainerHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramContainerID, null);
        this.props.transPoLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramContainerID, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)


        this.props.transContainerFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramContainerID, null, null, null, 0)
    }



    render() {

        return (

            <ContainerDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}


                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}


                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}


                isContainerLoading={this.props.isContainerLoading}
                ContainerData={this.props.ContainerData}

                isContainerJsonTreeLoading={this.props.isContainerJsonTreeLoading}
                ContainerJsonTreeData={this.props.ContainerJsonTreeData}

                isPoRecoveryLoading={this.props.isPoRecoveryLoading}
                PoRecoveryData={this.props.PoRecoveryData}

                isPoLoading={this.props.isPoLoading}
                PoData={this.props.PoData}

                transShipmentReceiveGet={this.props.transShipmentReceiveGet}
                isShipmentReceiveLoading={this.props.isShipmentReceiveLoading}
                ShipmentReceiveData={this.props.ShipmentReceiveData}

                ShipmentReceivePostSuccessful={this.props.ShipmentReceivePostSuccessful}

                transShipmentReceivePost={this.props.transShipmentReceivePost}

                transContainerPost={this.props.transContainerPost}
                ContainerPostSuccessful={this.props.ContainerPostSuccessful}


                isContainerHistoryLoading={this.props.isContainerHistoryLoading}
                ContainerHistoryData={this.props.ContainerHistoryData}

                isTransCustomerRefLoading={this.props.isTransCustomerRefLoading}
                TransCustomerRefData={this.props.TransCustomerRefData}


                transPoPost={this.props.transPoPost}
                PoPostSuccessful={this.props.PoPostSuccessful}

                transPoLocationGet={this.props.transPoLocationGet}
                isPoLocationLoading={this.props.isPoLocationLoading}
                PoLocationData={this.props.PoLocationData}

                transPoLocationPost={this.props.transPoLocationPost}


                transContainerFileUploadGet={this.props.transContainerFileUploadGet}
                transContainerFileUploadPost={this.props.transContainerFileUploadPost}
                isTransContainerFileUploadLoading={this.props.isTransContainerFileUploadLoading}
                TransContainerFileUploadData={this.props.TransContainerFileUploadData}
                TransContainerFileUploadPostSuccessful={this.props.TransContainerFileUploadPostSuccessful}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContainerDetailsContainer));



