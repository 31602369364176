import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoadDetails from './LoadDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import { queryStringParse, formatStringForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail:                           store.userServicesReducer.currentUserEmail,
    userSignInData:                             store.userServicesReducer.userSignInData,

    isLoadLoading:                              store.transReducer.isLoadLoading,
    LoadData:                                   store.transReducer.LoadData,

    isLoadPoLoading:                            store.transReducer.isLoadPoLoading,
    LoadPoData:                                 store.transReducer.LoadPoData,

    LoadPostSuccessful:                         store.transReducer.LoadPostSuccessful,

    LoadRemovePostSuccessful:                   store.transReducer.LoadRemovePostSuccessful,

    isLoadPoHistoryLoading:                     store.transReducer.isLoadPoHistoryLoading,
    LoadPoHistoryData:                          store.transReducer.LoadPoHistoryData,

    isPoLoading:                                store.transReducer.isPoLoading,
    PoData:                                     store.transReducer.PoData,

    isPoLocationLoading: store.transReducer.isPoLocationLoading,
    PoLocationData: store.transReducer.PoLocationData,
    PoLocationDataGrouped: store.transReducer.PoLocationDataGrouped,

    PoLocationPostSuccessful: store.transReducer.PoLocationPostSuccessful,

    isPoLoadLocationHistoryLoading: store.transReducer.isPoLoadLocationHistoryLoading,
    PoLoadLocationHistoryData: store.transReducer.PoLoadLocationHistoryData,

    isTransDestinationLoading: store.transReducer.isTransDestinationLoading,
    TransDestinationData: store.transReducer.TransDestinationData,
});

const mapDispatchToProps = dispatch =>
({

    transLoadGet:( _email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _MbolID, _HbolID, _PoID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal ) =>
    {
        dispatch( actions.transLoadGet( _email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _MbolID, _HbolID, _PoID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal ) )
    },
    transLoadPoGet:( _email, _authToken, _WarehouseID, _CustomerID, _LoadID ) =>
    {
        dispatch( actions.transLoadPoGet( _email, _authToken, _WarehouseID, _CustomerID, _LoadID ) )
    },

    transLoadPost:( _email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _Destination, _LoadStatusID, _LoadPoData, _LocationPoData, _PoID, _PCS, _Pallets, _WarehouseID, _CustomerID  ) =>
    {
        dispatch( actions.transLoadPost( _email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _Destination, _LoadStatusID, _LoadPoData, _LocationPoData, _PoID, _PCS, _Pallets, _WarehouseID, _CustomerID  ) )
    },

    transLoadRemovePost:( _email, _authToken, _LoadID, _PoID, _PCS, _Pallets, _WarehouseLocations, _WarehouseID, _CustomerID) =>
    {
        dispatch( actions.transLoadRemovePost( _email, _authToken, _LoadID, _PoID, _PCS, _Pallets, _WarehouseLocations, _WarehouseID, _CustomerID) )
    },
    transLoadPoHistoryGet:( _email, _authToken, _PoID, _LoadID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.transLoadPoHistoryGet( _email, _authToken, _PoID, _LoadID, _WarehouseID, _CustomerID ) )
    },

    transPoGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID, _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(actions.transPoGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID, _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd))
    },
    transPoLocationGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoLocationGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID))
    },

    transPoLocationPost: (_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize) => {
        dispatch(actions.transPoLocationPost(_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize))
    },

    transPoLoadLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PoID, _PO) => {
        dispatch(actions.transPoLoadLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PoID, _PO))
    },
    transDestinationGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transDestinationGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
});

class LoadDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.LoadRemovePostSuccessful !== this.props.LoadRemovePostSuccessful && this.props.LoadRemovePostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{}
        if (prevProps.LoadPostSuccessful !== this.props.LoadPostSuccessful && this.props.LoadPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else{}


        
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try{
            var paramLoadID = queryStringParse( values.loadid )
        }
        catch(e){
            var paramLoadID = null
        }

        this.props.transLoadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        this.props.transLoadPoGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken,this.props.warehouseSelection, this.props.customerSelection, paramLoadID )
        this.props.transLoadPoHistoryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken,null, paramLoadID, this.props.warehouseSelection, this.props.customerSelection)

        this.props.transPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null, null, null, 1, null, null, null, null)
        this.props.transPoLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, null, null, 1, null, null, null, this.props.warehouseSelection, this.props.customerSelection  )
        this.props.transDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
   
    }



    render() {

        return (

            <LoadDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}

                isLoadPoLoading={this.props.isLoadPoLoading}
                LoadPoData={this.props.LoadPoData}

                transLoadPost={this.props.transLoadPost}
                LoadPostSuccessful={this.props.LoadPostSuccessful}

                transLoadRemovePost={this.props.transLoadRemovePost}
                LoadRemovePostSuccessful={this.props.LoadRemovePostSuccessful}

                isLoadPoHistoryLoading={this.props.isLoadPoHistoryLoading}
                LoadPoHistoryData={this.props.LoadPoHistoryData}

                isPoLoading={this.props.isPoLoading}
                PoData={this.props.PoData}
                transPoGet={this.props.transPoGet}

                transPoLocationGet={this.props.transPoLocationGet}
                isPoLocationLoading={this.props.isPoLocationLoading}
                PoLocationData={this.props.PoLocationData}
                PoLocationDataGrouped={this.props.PoLocationDataGrouped}

                transPoLocationPost={this.props.transPoLocationPost}

                transPoLoadLocationHistoryGet={this.props.transPoLoadLocationHistoryGet}
                isPoLoadLocationHistoryLoading={this.props.isPoLoadLocationHistoryLoading}
                PoLoadLocationHistoryData={this.props.PoLoadLocationHistoryData}

                isTransDestinationLoading={this.props.isTransDestinationLoading}
                TransDestinationData={this.props.TransDestinationData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadDetailsContainer));




