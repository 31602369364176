export const InventoryUnitWarehouseGroupedDataElements = [
    {
        dBKey: 'WarehouseLocationName',
        displayName: 'Warehouse Location',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'SkuStatusName',
        displayName: 'Unit Status',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'UnitsRecovered',
        displayName: 'Units Recovered',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'UnitsInWhse',
        displayName: 'Units In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'UnitsLoaded',
        displayName: 'Units Loaded',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },

    
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },



    {
        dBKey: 'LotCode',
        displayName: 'Lot Code',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'SerialNumber',
        displayName: 'Serial Number',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },




    // {
    //     dBKey: 'CaseCount',
    //     displayName: 'Case Count',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: true,
    //     colWidth: 200
    // },










]


export default InventoryUnitWarehouseGroupedDataElements

