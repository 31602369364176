import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, Loader, Button } from 'rsuite';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, queryStringParseWithZero, formatStringForAPI, formatDateForUrlSearch } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { ctrlTwrQuoteStatus, ctrlTwrShipmentStatus } from '../../../app/appConfig';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import CtrlTwrQuoteReportDataElements from '../../dataComponentsControlTower/CtrlTwrQuoteReportDataElements';
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "LegLineItemID"

const mountRef = React.createRef();

class QuoteReport extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchStatus: null,
            searchShipmentClosed: null,
            isFilterShown: true,
            searchDateStart: null,
            searchDateEnd: null,



        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);
        this.onChangeSearchShipmentStatus = this.onChangeSearchShipmentStatus.bind(this);
        this.onChangeSearchDateStart = this.onChangeSearchDateStart.bind(this);
        this.onChangeSearchDateEnd = this.onChangeSearchDateEnd.bind(this);

    }



    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection

        this.loadSortFromStorage()
        this.updateParamsFromURL()
    }

    getsessionStorageWarehouse() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }

    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

        try {
            var paramShipmentStatus = queryStringParseWithZero(values.shipmentstatus)
        }
        catch (e) {
            var paramShipmentStatus = null
        }
        if (paramShipmentStatus != null) {
            this.onChangeSearchShipmentStatus(paramShipmentStatus)
        }

        try {
            var paramDateStart = queryStringParse(values.datestart)
        }
        catch (e) {
            var paramDateStart = null
        }
        this.onChangeSearchDateStart(paramDateStart)
        try {
            var paramDateEnd = queryStringParse(values.dateend)
        }
        catch (e) {
            var paramDateEnd = null
        }
        this.onChangeSearchDateEnd(paramDateEnd)

    }




    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchStatus: null,
            searchShipmentClosed: null,
            searchDateStart: null,
            searchDateEnd: null

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        try {
            var searchStatus = this.state.searchStatus
            var searchShipmentClosed = this.state.searchShipmentClosed
            var searchDateStart = this.state.searchDateStart
            var searchDateEnd = this.state.searchDateEnd

            if (searchDateStart == null && searchStatus != 1){
                window.alert('Please select Start Date.')
            }
            else{
            this.props.history.push({
                search:
                    'status=' + searchStatus + '&' +
                    'shipmentstatus=' + searchShipmentClosed + '&' +
                    'datestart=' + formatDateForUrlSearch(searchDateStart) + '&' +
                    'dateend=' + formatDateForUrlSearch(searchDateEnd)
            });
        }
        }
        catch (e) { }

    }

    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchShipmentClosed: null,
            searchDateStart: null,
            searchDateEnd: null

        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }
    onChangeSearchShipmentStatus(e){
        this.setState({
            searchShipmentClosed: e
        })
    }
    onChangeSearchDateStart(e) {
        this.setState({
            searchDateStart: e
        });
    }
    onChangeSearchDateEnd(e) {
        this.setState({
            searchDateEnd: e
        });
    }

    //Table on click details
    onClickShipDetails(e, rowData, history) {
        try {
            var paramID = rowData['ProRef']
            history.push({
                pathname: 'quoteshipmentdetails',
                search: 'pro=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }


    render() {

        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >

                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                ctrlTwrQuoteStatus,
                                this.state.searchStatus,
                                'Quote Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                ctrlTwrShipmentStatus,
                                this.state.searchShipmentClosed,
                                'Shipment Status',
                                this.onChangeSearchShipmentStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Date',
                                this.onChangeSearchDateStart,
                                this.onChangeSearchDateEnd,
                                this.state.searchDateStart,
                                this.state.searchDateEnd
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isCtrlTwrQuotingReportLoading) {
            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.CtrlTwrQuotingReportData) !== 'undefined' && this.props.CtrlTwrQuotingReportData != null) {
            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.CtrlTwrQuotingReportData,     //   tableData,
                CtrlTwrQuoteReportDataElements,       //   DataElements,
                this.props.isCtrlTwrQuotingReportLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                false,      //   onClickAddMilestone,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickShipDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick


            )


            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'QuoteReport_' + currentDateTime}>
                                <ExcelSheet data={this.props.CtrlTwrQuotingReportData} name="QuoteReport">
                                    {
                                        CtrlTwrQuoteReportDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }




        return (
            <div>
                <h3>
                    Control Tower - Quote Report
                </h3>
                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}

            </div>
        );

    }
}

export default withRouter(QuoteReport);