import React from 'react';
import { Row, Col, Panel, Timeline } from 'rsuite'; // Assuming you're using rsuite
import Loader from 'react-loader-spinner'; // Assuming you're using react-loader-spinner

function PalletWarehouseLocationHistoryDiv({ isInventoryPalletWarehouseLocationHistoryLoading, InventoryPalletWarehouseLocationHistoryData }) {
    if (isInventoryPalletWarehouseLocationHistoryLoading) {
        return (
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        );
    }

    if (typeof (InventoryPalletWarehouseLocationHistoryData[0]) !== 'undefined' && InventoryPalletWarehouseLocationHistoryData != null) {
        return (
            <div>
                <Row>
                    <Col md={24} sm={24}>
                        <Panel bordered header="Pallet History" style={{ marginBottom: 10 }}>
                            <Timeline>
                                {InventoryPalletWarehouseLocationHistoryData.map(item => (
                                    <Timeline.Item key={item.PalletWarehouseLocationHistoryID}>
                                        {item.CreatedDT}  &nbsp;
                                        {item.EmailAddr}
                                        <l></l>
                                        {item.HistoryType != null ? <l><br /> {item.HistoryType} </l> : ' '}
                                        {item.StatusName != null ? <l><br /> Pallet Status:  {item.StatusName} </l> : ' '}
                                        {item.Container != null  && item.HistoryTypeID == 1 ? <l><br /> Received From Container:  {item.Container} </l> : ' '}
                                        {item.RecoveryDTEditDT != null ? <l><br /> Recovery Edit Date:  {item.RecoveryDTEditDT} </l> : ' '}
                                        {item.Oversize != null ? <l><br /> Oversize:  {item.Oversize === true ? "True" : "False"} </l> : ' '}
                                        {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}
                                        {item.PalletDescription != null ? <l><br /> Description:  {item.PalletDescription} </l> : ' '}
                                        
                                        
                                        {item.WarehouseLocationName != null ? <l><br /> Warehouse Location:  {item.WarehouseLocationName} </l> : ' '}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }

    return <div></div>;
}

export default PalletWarehouseLocationHistoryDiv;

