export const AddToLoadSkuDataElements = [
    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'SkuStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    // {
    //     dBKey: 'CustomerRef',
    //     displayName: 'Customer Ref',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    // {
    //     dBKey: 'Project',
    //     displayName: 'Project',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    // {
    //     dBKey: 'Weight',
    //     displayName: 'Weight',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    {
        dBKey: 'Units',
        displayName: 'Units In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Cases',
        displayName: 'Cases In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'Pallets',
        displayName: 'Pallets In Whse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },



    // {
    //     dBKey: 'CustomsCleared',
    //     displayName: 'Customs Cleared',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },

    // {
    //     dBKey: 'Orders',
    //     displayName: 'Orders',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },

    // {
    //     dBKey: 'DeliveryAddress',
    //     displayName: 'Destination',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },
    // {
    //     dBKey: 'DCCode',
    //     displayName: 'DC Code',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },


]


export default AddToLoadSkuDataElements

