import { connect } from 'react-redux';
import React, { Component } from 'react';
import ShipmentDetailsManagement from './ShipmentDetailsManagement';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../redux/actions/actionsUser';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';



const mapStateToProps = store =>
({

    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isCtrlTwrCountryLoading: store.ctrlTwrReducer.isCtrlTwrCountryLoading,
    CtrlTwrCountryData: store.ctrlTwrReducer.CtrlTwrCountryData,

    isCtrlTwrStateLoading:                                store.ctrlTwrReducer.isCtrlTwrStateLoading,
    CtrlTwrStateData:                                store.ctrlTwrReducer.CtrlTwrStateData,

    isCtrlTwrModeRefLoading: store.ctrlTwrReducer.isCtrlTwrModeRefLoading,
    CtrlTwrModeRefData: store.ctrlTwrReducer.CtrlTwrModeRefData,

    isCtrlTwrAirportLoading: store.ctrlTwrReducer.isCtrlTwrAirportLoading,
    CtrlTwrAirportData: store.ctrlTwrReducer.CtrlTwrAirportData,

    isCtrlTwrSeaportLoading: store.ctrlTwrReducer.isCtrlTwrSeaportLoading,
    CtrlTwrSeaportData: store.ctrlTwrReducer.CtrlTwrSeaportData,

    CtrlTwrShipmentLegsPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsPostSuccessful,

    isCtrlTwrShipmentLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLoading,
    CtrlTwrShipmentData: store.ctrlTwrReducer.CtrlTwrShipmentData,

    isCtrlTwrShipmentLegsDataLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsDataLoading,
    CtrlTwrShipmentLegsData: store.ctrlTwrReducer.CtrlTwrShipmentLegsData,

    CtrlTwrShipmentPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentPostSuccessful,

    CtrlTwrShipmentLegsDataPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsDataPostSuccessful,

    isCtrlTwrShipmentLegsMilestonesLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsMilestonesLoading,
    CtrlTwrShipmentLegsMilestonesData: store.ctrlTwrReducer.CtrlTwrShipmentLegsMilestonesData,

    CtrlTwrShipmentLegsMilestonesPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsMilestonesPostSuccessful,


    CtrlTwrShipmentLegsCustomerRefPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsCustomerRefPostSuccessful,

    isCtrlTwrShipmentLegsCustomerRefLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsCustomerRefLoading,
    CtrlTwrShipmentLegsCustomerRefData: store.ctrlTwrReducer.CtrlTwrShipmentLegsCustomerRefData,

    CtrlTwrShipmentLegsPoPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsPoPostSuccessful,

    isCtrlTwrShipmentLegsPoLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsPoLoading,
    CtrlTwrShipmentLegsPoData: store.ctrlTwrReducer.CtrlTwrShipmentLegsPoData,

    CtrlTwrShipmentLegsHbolPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentLegsHbolPostSuccessful,

    isCtrlTwrShipmentLegsHbolLoading: store.ctrlTwrReducer.isCtrlTwrShipmentLegsHbolLoading,
    CtrlTwrShipmentLegsHbolData: store.ctrlTwrReducer.CtrlTwrShipmentLegsHbolData,

    isCtrlTwrMilestoneRefLoading: store.ctrlTwrReducer.isCtrlTwrMilestoneRefLoading,
    CtrlTwrMilestoneRefData: store.ctrlTwrReducer.CtrlTwrMilestoneRefData,

    isCtrlTwrTimezoneLoading: store.ctrlTwrReducer.isCtrlTwrTimezoneLoading,
    CtrlTwrTimezoneData: store.ctrlTwrReducer.CtrlTwrTimezoneData,

    CtrlTwrHotShipmentPostSuccessful: store.ctrlTwrReducer.CtrlTwrHotShipmentPostSuccessful,

    CtrlTwrShipmentUserSubscriptionPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentUserSubscriptionPostSuccessful,

    isCtrlTwrShipmentFileUploadLoading: store.ctrlTwrReducer.isCtrlTwrShipmentFileUploadLoading,
    CtrlTwrShipmentFileUploadData: store.ctrlTwrReducer.CtrlTwrShipmentFileUploadData,

    CtrlTwrShipmentFileUploadPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentFileUploadPostSuccessful,


    isCtrlTwrShipmentNotesPrivateLoading: store.ctrlTwrReducer.isCtrlTwrShipmentNotesPrivateLoading,
    CtrlTwrShipmentNotesPrivateData: store.ctrlTwrReducer.CtrlTwrShipmentNotesPrivateData,

    CtrlTwrShipmentNotesPrivatePostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPrivatePostSuccessful,

    isCtrlTwrShipmentNotesPublicLoading: store.ctrlTwrReducer.isCtrlTwrShipmentNotesPublicLoading,
    CtrlTwrShipmentNotesPublicData: store.ctrlTwrReducer.CtrlTwrShipmentNotesPublicData,

    CtrlTwrShipmentNotesPublicPostSuccessful: store.ctrlTwrReducer.CtrlTwrShipmentNotesPublicPostSuccessful,


    isCtrlTwrQuotingShipmentFileUploadLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentFileUploadLoading,
    CtrlTwrQuotingShipmentFileUploadData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentFileUploadData,

    isCtrlTwrQuotingShipmentFileUploadLoading: store.ctrlTwrReducer.isCtrlTwrQuotingShipmentFileUploadLoading,
    CtrlTwrQuotingShipmentFileUploadData: store.ctrlTwrReducer.CtrlTwrQuotingShipmentFileUploadData,

    CtrlTwrQuotingShipmentFileUploadPostSuccessful: store.ctrlTwrReducer.CtrlTwrQuotingShipmentFileUploadPostSuccessful,

});

const mapDispatchToProps = dispatch =>
({

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },

    ctrlTwrModeRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrModeRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrCountryGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrCountryGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrStateGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrStateGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrAirportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrAirportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    ctrlTwrSeaportGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrSeaportGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    ctrlTwrMilestoneRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(ctrlTwrActions.ctrlTwrMilestoneRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    ctrlTwrShipmentGet: (_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentGet(_email, _authToken, _WarehouseID, _CustomerID, _ProRef, _OriginCountryID, _PO, _ShipmentClosed, _DateStart, _DateEnd, _HotShipment, _Subscription, _LateShipment, _ShipmentAlert))
    },

    ctrlTwrShipmentLegsDataPost: (_email, _authToken, _ProRef, _LegNumber, _ModeID, _LegID, _WarehouseID, _BOL, _MBOL, _HBOL, _PO, _Container, _Carrier, _Seal, _PlannedDepartureDate, _EstimatedDeliveryDate, _PickupAddress, _DeliveryAddress, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _CustomerRef, _Shipper_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode, _PickupAppointmentTime, _DeliveryAppointmentTime) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsDataPost(_email, _authToken, _ProRef, _LegNumber, _ModeID, _LegID, _WarehouseID, _BOL, _MBOL, _HBOL, _PO, _Container, _Carrier, _Seal, _PlannedDepartureDate, _EstimatedDeliveryDate, _PickupAddress, _DeliveryAddress, _Trailer, _Vessel, _SeaportOriginID, _SeaportDestID, _Flight, _AirportOriginID, _AirportDestID, _CustomerRef, _Shipper_Name, _Origin_AddressLine, _Origin_City, _Origin_State, _Origin_PostalCode, _Origin_CountryCode, _Consignee_Name, _Destination_AddressLine, _Destination_City, _Destination_State, _Destination_PostalCode, _Destination_CountryCode, _PickupAppointmentTime, _DeliveryAppointmentTime))
    },

    ctrlTwrShipmentLegsDataGet: (_email, _authToken, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsDataGet(_email, _authToken, _ProRef))
    },
    ctrlTwrShipmentLegsMilestonesGet: (_email, _authToken, _ProRef, _LegID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsMilestonesGet(_email, _authToken, _ProRef, _LegID))
    },
    ctrlTwrShipmentLegsCustomerRefGet: (_email, _authToken, _ProRef, _LegID, _HbolID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsCustomerRefGet(_email, _authToken, _ProRef, _LegID, _HbolID))
    },
    ctrlTwrShipmentLegsPoGet: (_email, _authToken, _ProRef, _LegID, _HbolID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsPoGet(_email, _authToken, _ProRef, _LegID, _HbolID))
    },
    ctrlTwrShipmentLegsHbolGet: (_email, _authToken, _ProRef, _LegID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsHbolGet(_email, _authToken, _ProRef, _LegID))
    },
    // xxxx: (xxxxxxxxxxxxxxx) => {
    //     dispatch(ctrlTwrActions.xxxx(xxxxxxxxxxxxxxx))
    // },
    // xxxx: (xxxxxxxxxxxxxxx) => {
    //     dispatch(ctrlTwrActions.xxxx(xxxxxxxxxxxxxxx))
    // },

    ctrlTwrShipmentLegsMilestonesPost: (_email, _authToken, _ShipmentLegMilestoneID, _ProRef, _LegID, _ModeID, _MilestoneID, _MilestoneDT, _MilestoneTimezone, _CountryCodeID, _City, _State, _Zip, _SeaportCodeID, _AirportCodeID, _WarehouseID, _DeleteMilestone, _StateCodeID) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsMilestonesPost(_email, _authToken, _ShipmentLegMilestoneID, _ProRef, _LegID, _ModeID, _MilestoneID, _MilestoneDT, _MilestoneTimezone, _CountryCodeID, _City, _State, _Zip, _SeaportCodeID, _AirportCodeID, _WarehouseID, _DeleteMilestone, _StateCodeID))
    },


    ctrlTwrShipmentLegsPost: (_email, _authToken, _ProRef, _LegNumber, _ModeID, _DataJson, _LegID, _DeleteLeg, ) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsPost(_email, _authToken, _ProRef, _LegNumber, _ModeID, _DataJson, _LegID, _DeleteLeg, ))
    },

    ctrlTwrShipmentLegsCustomerRefPost: (_email, _authToken, _CustomerRefID, _LegID, _HbolID, _CustomerRef, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsCustomerRefPost(_email, _authToken, _CustomerRefID, _LegID, _HbolID, _CustomerRef, _Delete))
    },
    ctrlTwrShipmentLegsHbolPost: (_email, _authToken, _HbolID, _LegID, _HBOL, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsHbolPost(_email, _authToken, _HbolID, _LegID, _HBOL, _Delete))
    },
    ctrlTwrShipmentLegsPoPost: (_email, _authToken, _HbolID, _LegID, _PoID, _PO, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentLegsPoPost(_email, _authToken, _HbolID, _LegID, _PoID, _PO, _Delete))
    },

    ctrlTwrShipmentPost: (_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPost(_email, _authToken, _ProRef, _CustomerID, _OriginCountryID, _PO, _ETD, _HotShipment, _Pallets, _Cases, _UnitCount, _WeightUnits, _DestinationCountryID, _SpecialRequirements, _GrossWeight, _ChargeableWeight, _Length, _Width, _Height, _DimUnits, _Commodity))
    },
    ctrlTwrTimezoneGet: (_email, _authToken,) => {
        dispatch(ctrlTwrActions.ctrlTwrTimezoneGet(_email, _authToken,))
    },


    ctrlTwrHotShipmentPost: (_email, _authToken, _ProRef, _HotShipment) => {
        dispatch(ctrlTwrActions.ctrlTwrHotShipmentPost(_email, _authToken, _ProRef, _HotShipment))
    },

    ctrlTwrShipmentUserSubscriptionPost: (_email, _authToken, _ProRef, _Active) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentUserSubscriptionPost(_email, _authToken, _ProRef, _Active))
    },


    ctrlTwrShipmentFileUploadGet: (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentFileUploadGet(_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete))
    },
    ctrlTwrShipmentFileUploadPost: (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentFileUploadPost(_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete))
    },

    ctrlTwrShipmentPrivateNotesGet: (_email, _authToken, _ShipmentNoteID, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPrivateNotesGet(_email, _authToken, _ShipmentNoteID, _ProRef))
    },
    ctrlTwrShipmentPrivateNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPrivateNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PrivateNote, _Delete))
    },
    ctrlTwrShipmentPublicNotesGet: (_email, _authToken, _ShipmentNoteID, _ProRef) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPublicNotesGet(_email, _authToken, _ShipmentNoteID, _ProRef))
    },
    ctrlTwrShipmentPublicNotesPost: (_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete) => {
        dispatch(ctrlTwrActions.ctrlTwrShipmentPublicNotesPost(_email, _authToken, _ShipmentNoteID, _ProRef, _PublicNote, _Delete))
    },

    ctrlTwrQuotingShipmentFileUploadGet: (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentFileUploadGet(_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete))
    },
    ctrlTwrQuotingShipmentFileUploadPost: (_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete, MoveFileToCtrlTwr) => {
        dispatch(ctrlTwrActions.ctrlTwrQuotingShipmentFileUploadPost(_email, _authToken, _CustomerID, _FileUploadID, _ProRef, _FileName, _S3FileName, _FileType, _FileDelete, MoveFileToCtrlTwr))
    },


});

class ShipmentDetailsManagementContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.CtrlTwrShipmentLegsPostSuccessful !== this.props.CtrlTwrShipmentLegsPostSuccessful && this.props.CtrlTwrShipmentLegsPostSuccessful) {
            setTimeout(() => {
                try {
                    this.handleUrlParam();
                }
                catch (e) { }
            }, 100);
        }

        if (prevProps.CtrlTwrShipmentPostSuccessful !== this.props.CtrlTwrShipmentPostSuccessful && this.props.CtrlTwrShipmentPostSuccessful) {
            this.handleUrlParam()
        }


        if (prevProps.CtrlTwrShipmentLegsMilestonesPostSuccessful !== this.props.CtrlTwrShipmentLegsMilestonesPostSuccessful && this.props.CtrlTwrShipmentLegsMilestonesPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.CtrlTwrHotShipmentPostSuccessful !== this.props.CtrlTwrHotShipmentPostSuccessful && this.props.CtrlTwrHotShipmentPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, null, null, null, null, null, null, null, null)
        }

        if (prevProps.CtrlTwrShipmentUserSubscriptionPostSuccessful !== this.props.CtrlTwrShipmentUserSubscriptionPostSuccessful && this.props.CtrlTwrShipmentUserSubscriptionPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, null, null, null, null, null, null, null, null)
        }

        if (prevProps.CtrlTwrShipmentFileUploadPostSuccessful !== this.props.CtrlTwrShipmentFileUploadPostSuccessful && this.props.CtrlTwrShipmentFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
        }
        

        if (prevProps.CtrlTwrQuotingShipmentFileUploadPostSuccessful !== this.props.CtrlTwrQuotingShipmentFileUploadPostSuccessful && this.props.CtrlTwrQuotingShipmentFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPro = formatStringForAPI(values.pro)
            }
            catch (e) {
                var paramPro = null
            }
            this.props.ctrlTwrQuotingShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
            this.props.ctrlTwrShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
        }


          
        
    
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = formatStringForAPI(values.pro)
        }
        catch (e) {
            var paramPro = null
        }

        // try {
        //     var paramPo = formatStringForAPI(values.po)
        // }
        // catch (e) {
        //     var paramPo = null
        // }

        // try {
        //     var paramCustomer = queryStringParse(values.customer)
        // }
        // catch (e) {
        //     var paramCustomer = null
        // }

        // try {
        //     var paramCountry = queryStringParse(values.country)
        // }
        // catch (e) {
        //     var paramCountry = null
        // }

        // try {
        //     var paramStatus = queryStringParse(values.status)
        // }
        // catch (e) {
        //     var paramStatus = null
        // }
        this.props.ctrlTwrModeRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrAirportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrSeaportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrMilestoneRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.ctrlTwrCountryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrStateGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)


        this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, paramPro, null, null, null, null, null, null, null, null, null)

        this.props.ctrlTwrShipmentLegsDataGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro)
        this.props.ctrlTwrShipmentLegsMilestonesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null)
        this.props.ctrlTwrShipmentLegsCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        this.props.ctrlTwrShipmentLegsPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null, null)
        this.props.ctrlTwrShipmentLegsHbolGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPro, null)
        this.props.ctrlTwrTimezoneGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken)

        this.props.ctrlTwrShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)


        this.props.ctrlTwrShipmentPrivateNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)    
        this.props.ctrlTwrShipmentPublicNotesGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPro)
    
        this.props.ctrlTwrQuotingShipmentFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramPro, null, null, null, 0)
    }



    render() {

        return (

            <ShipmentDetailsManagement
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}
                organizationSelection={this.props.organizationSelection}

                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}

                isCtrlTwrCountryLoading={this.props.isCtrlTwrCountryLoading}
                CtrlTwrCountryData={this.props.CtrlTwrCountryData}

                isCtrlTwrStateLoading={this.props.isCtrlTwrStateLoading}
                CtrlTwrStateData={this.props.CtrlTwrStateData}
                
                isCtrlTwrModeRefLoading={this.props.isCtrlTwrModeRefLoading}
                CtrlTwrModeRefData={this.props.CtrlTwrModeRefData}

                isCtrlTwrAirportLoading={this.props.isCtrlTwrAirportLoading}
                CtrlTwrAirportData={this.props.CtrlTwrAirportData}

                isCtrlTwrSeaportLoading={this.props.isCtrlTwrSeaportLoading}
                CtrlTwrSeaportData={this.props.CtrlTwrSeaportData}

                isCtrlTwrShipmentLoading={this.props.isCtrlTwrShipmentLoading}
                CtrlTwrShipmentData={this.props.CtrlTwrShipmentData}

                isCtrlTwrMilestoneRefLoading={this.props.isCtrlTwrMilestoneRefLoading}
                CtrlTwrMilestoneRefData={this.props.CtrlTwrMilestoneRefData}


                ctrlTwrShipmentLegsDataPost={this.props.ctrlTwrShipmentLegsDataPost}

                ctrlTwrShipmentLegsDataGet={this.props.ctrlTwrShipmentLegsDataGet}
                isCtrlTwrShipmentLegsDataLoading={this.props.isCtrlTwrShipmentLegsDataLoading}
                CtrlTwrShipmentLegsData={this.props.CtrlTwrShipmentLegsData}


                CtrlTwrShipmentLegsDataPostSuccessful={this.props.CtrlTwrShipmentLegsDataPostSuccessful}

                ctrlTwrShipmentLegsMilestonesGet={this.props.ctrlTwrShipmentLegsMilestonesGet}

                isCtrlTwrShipmentLegsMilestonesLoading={this.props.isCtrlTwrShipmentLegsMilestonesLoading}
                CtrlTwrShipmentLegsMilestonesData={this.props.CtrlTwrShipmentLegsMilestonesData}

                CtrlTwrShipmentLegsMilestonesPostSuccessful={this.props.CtrlTwrShipmentLegsMilestonesPostSuccessful}


                CtrlTwrShipmentLegsCustomerRefPostSuccessful={this.props.CtrlTwrShipmentLegsCustomerRefPostSuccessful}

                isCtrlTwrShipmentLegsCustomerRefLoading={this.props.isCtrlTwrShipmentLegsCustomerRefLoading}
                CtrlTwrShipmentLegsCustomerRefData={this.props.CtrlTwrShipmentLegsCustomerRefData}

                CtrlTwrShipmentLegsPoPostSuccessful={this.props.CtrlTwrShipmentLegsPoPostSuccessful}

                isCtrlTwrShipmentLegsPoLoading={this.props.isCtrlTwrShipmentLegsPoLoading}
                CtrlTwrShipmentLegsPoData={this.props.CtrlTwrShipmentLegsPoData}

                CtrlTwrShipmentLegsHbolPostSuccessful={this.props.CtrlTwrShipmentLegsHbolPostSuccessful}

                isCtrlTwrShipmentLegsHbolLoading={this.props.isCtrlTwrShipmentLegsHbolLoading}
                CtrlTwrShipmentLegsHbolData={this.props.CtrlTwrShipmentLegsHbolData}

                CtrlTwrShipmentLegsPostSuccessful={this.props.CtrlTwrShipmentLegsPostSuccessful}
                ctrlTwrShipmentLegsPost={this.props.ctrlTwrShipmentLegsPost}

                ctrlTwrShipmentLegsCustomerRefGet={this.props.ctrlTwrShipmentLegsCustomerRefGet}
                ctrlTwrShipmentLegsPoGet={this.props.ctrlTwrShipmentLegsPoGet}
                ctrlTwrShipmentLegsHbolGet={this.props.ctrlTwrShipmentLegsHbolGet}

                ctrlTwrShipmentLegsCustomerRefPost={this.props.ctrlTwrShipmentLegsCustomerRefPost}
                ctrlTwrShipmentLegsHbolPost={this.props.ctrlTwrShipmentLegsHbolPost}
                ctrlTwrShipmentLegsPoPost={this.props.ctrlTwrShipmentLegsPoPost}


                CtrlTwrShipmentPostSuccessful={this.props.CtrlTwrShipmentPostSuccessful}
                ctrlTwrShipmentPost={this.props.ctrlTwrShipmentPost}

                ctrlTwrShipmentLegsMilestonesPost={this.props.ctrlTwrShipmentLegsMilestonesPost}

                isCtrlTwrTimezoneLoading={this.props.isCtrlTwrTimezoneLoading}
                CtrlTwrTimezoneData={this.props.CtrlTwrTimezoneData}

                CtrlTwrHotShipmentPostSuccessful={this.props.CtrlTwrHotShipmentPostSuccessful}
                ctrlTwrHotShipmentPost={this.props.ctrlTwrHotShipmentPost}

                CtrlTwrShipmentUserSubscriptionPostSuccessful={this.props.CtrlTwrShipmentUserSubscriptionPostSuccessful}
                ctrlTwrShipmentUserSubscriptionPost={this.props.ctrlTwrShipmentUserSubscriptionPost}


                ctrlTwrShipmentFileUploadGet={this.props.ctrlTwrShipmentFileUploadGet}
                ctrlTwrShipmentFileUploadPost={this.props.ctrlTwrShipmentFileUploadPost}
                isCtrlTwrShipmentFileUploadLoading={this.props.isCtrlTwrShipmentFileUploadLoading}
                CtrlTwrShipmentFileUploadData={this.props.CtrlTwrShipmentFileUploadData}
                CtrlTwrShipmentFileUploadPostSuccessful={this.props.CtrlTwrShipmentFileUploadPostSuccessful}


                ctrlTwrQuotingShipmentFileUploadGet={this.props.ctrlTwrQuotingShipmentFileUploadGet}
                ctrlTwrQuotingShipmentFileUploadPost={this.props.ctrlTwrQuotingShipmentFileUploadPost}
                isCtrlTwrQuotingShipmentFileUploadLoading={this.props.isCtrlTwrQuotingShipmentFileUploadLoading}
                CtrlTwrQuotingShipmentFileUploadData={this.props.CtrlTwrQuotingShipmentFileUploadData}
                CtrlTwrQuotingShipmentFileUploadPostSuccessful={this.props.CtrlTwrQuotingShipmentFileUploadPostSuccessful}


                isCtrlTwrShipmentNotesPrivateLoading={this.props.isCtrlTwrShipmentNotesPrivateLoading}
                CtrlTwrShipmentNotesPrivateData={this.props.CtrlTwrShipmentNotesPrivateData}
            
                CtrlTwrShipmentNotesPrivatePostSuccessful={this.props.CtrlTwrShipmentNotesPrivatePostSuccessful}
            
                isCtrlTwrShipmentNotesPublicLoading={this.props.isCtrlTwrShipmentNotesPublicLoading}
                CtrlTwrShipmentNotesPublicData={this.props.CtrlTwrShipmentNotesPublicData}
            
                CtrlTwrShipmentNotesPublicPostSuccessful={this.props.CtrlTwrShipmentNotesPublicPostSuccessful}

                ctrlTwrShipmentPrivateNotesGet={this.props.ctrlTwrShipmentPrivateNotesGet}
                ctrlTwrShipmentPrivateNotesPost={this.props.ctrlTwrShipmentPrivateNotesPost}
                ctrlTwrShipmentPublicNotesGet={this.props.ctrlTwrShipmentPublicNotesGet}
                ctrlTwrShipmentPublicNotesPost={this.props.ctrlTwrShipmentPublicNotesPost}
            
            />



        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentDetailsManagementContainer));



