import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, Tree } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventorySkuDataElements from '../../../dataComponentsInventory/InventorySkuDataElements';
import RButtonHideShowFilter from '../../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import { hawbStatus } from '../../../../app/appConfig';
import RSearchInputNumberAsString from '../../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RSelectPicker from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import RDatePickerRange from '../../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import queryString from 'query-string';
import { queryStringParse, replaceNegativeWithZero, isNumeric, formatStringForAPI, uniqueArray, convertNanToZero, formatTrueFalseToBit } from '../../../../resources/logic/functions';
import BuildLoadDrawer from './BuildLoadDrawer';
import RSelectPickerCreatable from '../../../componentsGeneral/Rsuite/InputPicker/RSelectPickerCreatable';

const tableIdCol = "SkuID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const mountRef = React.createRef();

class Inventory extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            //Search Values
            isFilterShown: true,
            searchContainer: '',
            searchSKU: '',
            searchStatus: 1,
            searchCustomerRef: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,

            // searchCarrier: null,
            // searchMinETADischargeStart: null,
            // searchMinETADischargeEnd: null,


            // serachAssignedDriver: '',
            // searchMawbColorCode: null,


            //Build Load Drawer
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false,
            existingDataChangeLoading: false


        };

        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeSearchContainer = this.onChangeSearchContainer.bind(this);
        this.onChangeSearchSKU = this.onChangeSearchSKU.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);
        this.onChangeSearchCustomerRef = this.onChangeSearchCustomerRef.bind(this)
        this.onChangeSearchRecoveryDateStart = this.onChangeSearchRecoveryDateStart.bind(this);
        this.onChangeSearchRecoveryDateEnd = this.onChangeSearchRecoveryDateEnd.bind(this);



        //Table buttons
        this.onClickDetails = this.onClickDetails.bind(this);


        //Build Load Drawer
        this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onChangeExistingBuildLoadDrawer = this.onChangeExistingBuildLoadDrawer.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onClickBuildLoad = this.onClickBuildLoad.bind(this);
        this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickBuildLoadDrawerSave = this.onClickBuildLoadDrawerSave.bind(this);
        this.onClickRemoveFromBuild = this.onClickRemoveFromBuild.bind(this);

        this.onClickRemoveFromAdd = this.onClickRemoveFromAdd.bind(this);
    }




    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainer = queryStringParse(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        if (paramContainer != null) {
            this.onChangeSearchContainer(paramContainer)
        }
        try {
            var paramSKU = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSKU = null
        }
        if (paramSKU != null) {
            this.onChangeSearchSKU(paramSKU)
        }

        try {
            var paramStatus = queryStringParse(values.skustatusid)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }


        try {
            var paramCustomerRefID = queryStringParse(values.customerrefid)
        }
        catch (e) {
            var paramCustomerRefID = null
        }
        if (paramCustomerRefID != null) {
            this.onChangeSearchCustomerRef(paramCustomerRefID)
        }

        try {
            var date = queryStringParse(values.recoverydatestart)
            if (date === null) {
                var paramRecoveryDateStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateStart = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateStart = null
        }
        if (paramRecoveryDateStart != null) {
            this.onChangeSearchRecoveryDateStart(paramRecoveryDateStart)
        }

        try {
            var date = queryStringParse(values.recoverydateend)
            if (date === null) {
                var paramRecoveryDateEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramRecoveryDateEnd = dayAdd
            }
        }
        catch (e) {
            var paramRecoveryDateEnd = null
        }
        this.onChangeSearchRecoveryDateEnd(paramRecoveryDateEnd)

    }


    //Table on click details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'skudetails',
                search: 'skuid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }



    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const currentCheckedRow = this.props.InventorySkuData.filter(x => value === x[tableIdCol])

        // const remainingToLoad = currentCheckedRow[0]['CasesReceived'] - currentCheckedRow[0]['CasesLoaded']

        // const remainingToLoad = currentCheckedRow[0]['InWarehouseUnits']
        // const remainingToLoad = currentCheckedRow[0]['InWarehouseCases']
        // const remainingToLoad = currentCheckedRow[0]['Cases']

        const currentStatusID = currentCheckedRow[0]['CurrentSkuStatusID']

        if (currentStatusID === 1) {
            const { checkedKeys } = this.state;
            const nextCheckedKeys = checked
                ? [...checkedKeys, value]
                : checkedKeys.filter(item => item !== value);
            this.setState({
                checkedKeys: nextCheckedKeys
            });
        }
        else {
            window.alert('Nothing remaining to load.')
        }

        // if (remainingToLoad < 1) {
        //     window.alert('Nothing remaining to load.')
        // }
        // else {

        //     const { checkedKeys } = this.state;
        //     const nextCheckedKeys = checked
        //         ? [...checkedKeys, value]
        //         : checkedKeys.filter(item => item !== value);
        //     this.setState({
        //         checkedKeys: nextCheckedKeys
        //     });
        // }
    }


    //Filter
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }
    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchContainer: '',
            searchSKU: '',
            searchStatus: null,
            searchCustomerRef: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchContainer: '',
            searchSKU: '',
        });

        var sDT = null
        var eDT = null


        if (this.state.searchRecoveryDateStart === null) {
            sDT = null
        }
        else {
            sDT = "'" + moment(this.state.searchRecoveryDateStart).format('YYYY-MM-DD') + "'"
        }
        if (this.state.searchRecoveryDateEnd === null) {
            eDT = null
        }
        else {
            eDT = "'" + moment(this.state.searchRecoveryDateEnd).format('YYYY-MM-DD') + "'"
        }

        if (this.props.warehouseSelection == null) {
            window.alert('Please select a warehouse and customer.')
        }
        else if (this.props.customerSelection == null) {
            window.alert('Please select a customer.')
        }
        else {
            try {
                var searchStatus = this.state.searchStatus
                var searchCustomerRef = this.state.searchCustomerRef
                var searchCarrier = this.state.searchCarrier
                var serachAssignedDriver = this.state.serachAssignedDriver
                var searchMawbColorCode = this.state.searchMawbColorCode
                this.props.history.push({
                    search:
                        'skustatusid=' + searchStatus + '&' +
                        'customerrefid=' + searchCustomerRef + '&' +
                        // 'carrier=' + searchCarrier + '&' +
                        // 'assigneddriver=' + serachAssignedDriver + '&' +
                        // 'mawbcolorcode=' + searchMawbColorCode + '&' +
                        'recoverydatestart=' + sDT + '&' +
                        'recoverydateend=' + eDT
                });
            }
            catch (e) { }
        }
    }


    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            searchCustomerRef: null,
            searchCarrier: null,
            searchMinETADischargeStart: null,
            searchMinETADischargeEnd: null,
            searchRecoveryDateStart: null,
            searchRecoveryDateEnd: null,
            serachAssignedDriver: '',
            searchMawbColorCode: null
        });

    }

    onChangeSearchContainer(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchContainer: e,
            searchSKU: '',
        });
    }

    onChangeSearchSKU(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchSKU: e,
            searchContainer: '',
        });
    }

    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }

    onChangeSearchCustomerRef(e) {
        this.setState({
            searchCustomerRef: e
        });
    }


    onChangeSearchCarrier(e) {
        this.setState({
            searchCarrier: e
        });
    }

    onChangeSearchMinETADischargeStart(e) {
        this.setState({
            searchMinETADischargeStart: e
        });
    }
    onChangeSearchMinETADischargeEnd(e) {
        this.setState({
            searchMinETADischargeEnd: e
        });
    }

    onChangeSearchRecoveryDateStart(e) {
        this.setState({
            searchRecoveryDateStart: e
        });
    }
    onChangeSearchRecoveryDateEnd(e) {
        this.setState({
            searchRecoveryDateEnd: e
        });
    }
    onChangeSearchAssigedDriver(e) {
        this.setState({
            serachAssignedDriver: e
        });
    }
    onChangeSearchMawbColorCode(e) {
        this.setState({
            searchMawbColorCode: e
        });
    }


    // //Table buttons
    // onClickDetails(e, rowData, history) {
    //     try {
    //         var paramID = rowData[tableIdCol]
    //         history.push({
    //             pathname: 'skudetails',
    //             search: 'skuid=\'' + paramID + '\''
    //         });
    //     }
    //     catch (e) {
    //     }
    // }


    //Build Load Drawer
    onClickBuildLoad(e) {

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {





            if (this.state.checkedKeys.length > 0) {
                var buildLoadArray = {};
                buildLoadArray = this.props.InventorySkuData.filter(x => this.state.checkedKeys.includes(x.SkuID))

                var SkuLocationDataGrouped = JSON.parse(JSON.stringify(this.props.SkuLocationDataGrouped));

                var newLocationDict = Object.keys(SkuLocationDataGrouped).
                    filter((key) => this.state.checkedKeys.includes(Number(key))).
                    reduce((cur, key) => { return Object.assign(cur, { [key]: SkuLocationDataGrouped[key] }) }, {});

                var newLoadDict = {}
                newLoadDict['PickupDate'] = null
                newLoadDict['DeliveryDate'] = null
                newLoadDict['Carrier'] = null
                newLoadDict['BOL'] = null
                newLoadDict['Trailer'] = null
                newLoadDict['Seal'] = null

                for (let k in buildLoadArray) {
                    try {
                        buildLoadArray[k]['NewUnitsLoaded'] = replaceNegativeWithZero((newLocationDict[buildLoadArray[k]['SkuID']].reduce((a, v) => a = a + v.Units, 0)))

                        buildLoadArray[k]['NewCasesLoaded'] = replaceNegativeWithZero((newLocationDict[buildLoadArray[k]['SkuID']].reduce((a, v) => a = a + v.Cases, 0)))
                        buildLoadArray[k]['NewPallets'] = replaceNegativeWithZero((newLocationDict[buildLoadArray[k]['SkuID']].reduce((a, v) => a = a + v.Pallets, 0)))
                    }
                    catch (e) {
                        buildLoadArray[k]['NewUnitsLoaded'] = replaceNegativeWithZero(buildLoadArray[k]['Units'])
                        buildLoadArray[k]['NewCasesLoaded'] = replaceNegativeWithZero(buildLoadArray[k]['Cases'])
                        buildLoadArray[k]['NewPallets'] = replaceNegativeWithZero(buildLoadArray[k]['Pallets'])
                    }
                }

                this.setState({
                    // unsavedBuildLoadChanges: true,
                    BuildLoadDict: buildLoadArray,
                    LocationDict: newLocationDict,
                    NewLoadData: newLoadDict,
                    showBuildLoadDrawer: true,
                });
            }
            else {
                window.alert('Please select rows.')
            }
        }
        else {
            window.alert('Please select one warehouse and one customer to build a load.')
        }
    }
    onChangeBuildLoadDrawer = (event, label) => {
        var currentLoadData = this.state.NewLoadData
        currentLoadData[label] = event
        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: currentLoadData
        });

    };
    onChangeExistingBuildLoadDrawer = (event, item, label) => {
        setTimeout(() => {
            this.setState({
                existingDataChangeLoading: true
            });
        }, 50);


        var skuId = item.SkuID;
        var itemIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        var currentBuildLoadDict = this.state.BuildLoadDict

        currentBuildLoadDict[itemIndex][label] = isNumeric(event)

        this.setState({
            unsavedBuildLoadChanges: true,
            BuildLoadDict: currentBuildLoadDict,

            existingDataChangeLoading: false
        });
    };
    onChangeLocation = (e, item, locationitem, label, skuWarehouseLocationID) => {
        setTimeout(() => {
            this.setState({
                existingDataChangeLoading: true
            });
        }, 50);

        var skuId = item.SkuID;
        var itemIndex = this.state.LocationDict[skuId].findIndex(({ SkuWarehouseLocationID }) => SkuWarehouseLocationID === skuWarehouseLocationID);
        var newLocationBuildDict = this.state.LocationDict

        newLocationBuildDict[skuId][itemIndex][label] = isNumeric(e)

        var currentBuildLoadDictIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        var currentBuildLoadDict = this.state.BuildLoadDict

        try {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Units, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Cases, 0)))
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero((newLocationBuildDict[currentBuildLoadDict[currentBuildLoadDictIndex]['SkuID']].reduce((a, v) => a = a + v.Pallets, 0)))
        }
        catch (e) {
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewUnitsLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['UnitsLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewCasesLoaded'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['CasesReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['CasesLoaded'])
            currentBuildLoadDict[currentBuildLoadDictIndex]['NewPallets'] = replaceNegativeWithZero(currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsReceived'] - currentBuildLoadDict[currentBuildLoadDictIndex]['PalletsLoaded'])
        }

        this.setState({
            unsavedBuildLoadChanges: true,
            BuildLoadDict: currentBuildLoadDict,
            LocationDict: newLocationBuildDict,
            existingDataChangeLoading: false
        });
    }

    onClickRemoveFromAdd = (e, item, locationitem, skuWarehouseLocationID) => {

        this.onChangeLocation(0, item, locationitem, 'Units', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Cases', skuWarehouseLocationID)
        this.onChangeLocation(0, item, locationitem, 'Pallets', skuWarehouseLocationID)

        this.forceUpdate()
    }

    onClickRemoveFromBuild(e, item) {

        // setTimeout(() => {
        //     this.setState({
        //         showBuildLoadDrawer: false
        //     });
        // }, 50);

        var prevBuild = {}
        prevBuild = this.state.BuildLoadDict

        var skuId = item.SkuID;
        var itemIndex = this.state.BuildLoadDict.findIndex(({ SkuID }) => SkuID === skuId);
        prevBuild.splice(itemIndex, 1);

        setTimeout(() => {
            this.setState({
                BuildLoadDict: prevBuild
            });
        }, 50);

        // setTimeout(() => {
        //     this.setState({
        //         showBuildLoadDrawer: true
        //     });
        // }, 50);


        this.forceUpdate()

    }
    onClickBuildLoadDrawerSave(e, totalUnitsNumber, totalRecoveredNum, totalPalleteNum) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            // if (this.state.NewLoadData.Carrier == null || this.state.NewLoadData.Carrier == '') {
            //     window.alert('Please enter Carrier')
            // }
            // else if (this.state.NewLoadData.Trailer == null || this.state.NewLoadData.Trailer == '') {
            //     window.alert('Please enter Trailer')
            // }

            // if (window.confirm('Save Data?')) {

            const currentListPo = this.state.BuildLoadDict
            const newPoToPost = currentListPo.map(({ SkuID, NewUnitsLoaded, NewCasesLoaded, NewPallets }) => ({ SkuID, NewUnitsLoaded, NewCasesLoaded, NewPallets }));
            var poString = JSON.stringify(newPoToPost)

            const currentListLocation = this.state.LocationDict
            var newLocationToPost = []
            for (let x in currentListLocation) {
                for (let i in currentListLocation[x]) {
                    try {

                        if ((currentListLocation[x][i]['Units'] === null || currentListLocation[x][i]['Units'] == 0) && (currentListLocation[x][i]['Cases'] === null || currentListLocation[x][i]['Cases'] == 0) && (currentListLocation[x][i]['Pallets'] === null || currentListLocation[x][i]['Pallets'] == 0)) {

                        }
                        else {
                            newLocationToPost.push({ 'SkuID': x, 'WarehouseLocationID': currentListLocation[x][i]['WarehouseLocationID'], 'SkuWarehouseLocationID': currentListLocation[x][i]['SkuWarehouseLocationID'], 'Units': currentListLocation[x][i]['Units'], 'Cases': currentListLocation[x][i]['Cases'], 'Pallets': currentListLocation[x][i]['Pallets'], 'Oversize': formatTrueFalseToBit(currentListLocation[x][i]['Oversize']) })
                        }
                    }
                    catch (e) { }
                }
            }
            var locationString = JSON.stringify(newLocationToPost)

            try {
                if (this.state.NewLoadData.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.NewLoadData.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.NewLoadData.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.NewLoadData.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                // var paramPostCarrier = "'" + this.state.NewLoadData.Carrier + "'"
                var paramPostCarrier = formatStringForAPI(this.state.NewLoadData.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                // var paramPostBOL = "'" + this.state.NewLoadData.BOL + "'"
                var paramPostBOL = formatStringForAPI(this.state.NewLoadData.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                // var paramPostTrailer = "'" + this.state.NewLoadData.Trailer + "'"
                var paramPostTrailer = formatStringForAPI(this.state.NewLoadData.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                // var paramPostSeal = "'" + this.state.NewLoadData.Seal + "'"
                var paramPostSeal = formatStringForAPI(this.state.NewLoadData.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }


            try {
                var paramPostTotalUnits = totalUnitsNumber
            }
            catch (e) {
                var paramPostTotalUnits = null
            }

            try {
                var paramPostTotalCases = totalRecoveredNum
            }
            catch (e) {
                var paramPostTotalCases = null
            }
            try {
                var paramPostTotalPallets = totalPalleteNum
            }
            catch (e) {
                var paramPostTotalPallets = null
            }
            try {
                var paramPostDesintation = formatStringForAPI(this.state.NewLoadData.Destination)
            }
            catch (e) {
                var paramPostDesintation = null
            }
            try {
                var paramPostLoadStatusID = 1
            }
            catch (e) {
                var paramPostLoadStatusID = 1
            }
            try {
                var paramPostLoadInventorySkuData = "'" + poString + "'"
            }
            catch (e) {
                var paramPostLoadInventorySkuData = null
            }
            try {
                var paramPostLocationInventorySkuData = "'" + locationString + "'"
            }
            catch (e) {
                var paramPostLocationInventorySkuData = null
            }

            this.props.inventoryLoadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, paramPostTotalUnits, paramPostTotalCases, paramPostTotalPallets, paramPostDesintation, null, paramPostLoadStatusID, paramPostLoadInventorySkuData, paramPostLocationInventorySkuData, null, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)

            this.setState({
                unsavedBuildLoadChanges: false,
                showBuildLoadDrawer: false
            });
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeBuildLoadDrawer() {
        if (this.state.unsavedBuildLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setBuildLoadDrawerToClose()
            }
        }
        else {
            this.setBuildLoadDrawerToClose()
        }
    }

    setBuildLoadDrawerToClose() {
        this.setState({
            BuildLoadDict: {},
            LocationDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false
        });
    }



    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;

        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            // && !this.props.isTransConsigneeLoading) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Container',
                                this.onChangeSearchContainer,
                                this.props.history,
                                'container',
                                this.state.searchContainer
                            )}
                        </Row>

                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'SKU',
                                this.onChangeSearchSKU,
                                this.props.history,
                                'sku',
                                this.state.searchSKU
                            )}
                        </Row>
                    </Panel>
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                hawbStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>


                        {/* <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.TransCustomerRefData,
                                this.state.searchCustomerRef,
                                'Customer Ref',
                                this.onChangeSearchCustomerRef,
                                this.props.history,
                                mountRef
                            )}
                        </Row> */}

                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Recovery Date',
                                this.onChangeSearchRecoveryDateStart,
                                this.onChangeSearchRecoveryDateEnd,
                                this.state.searchRecoveryDateStart,
                                this.state.searchRecoveryDateEnd
                            )}
                        </Row>

                        {/* <Row>
                            <div >
                                <ControlLabel>Assigned Driver</ControlLabel>
                                <InputGroup style={{ marginBottom: 10 }}>
                                    <Input value={this.state.serachAssignedDriver} onChange={(e) => { this.onChangeSearchAssigedDriver(e) }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                </InputGroup>
                            </div>
                        </Row> */}

                        {/* <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                colorColumnsValuesDropDownFilter,
                                this.state.searchMawbColorCode,
                                'Color',
                                this.onChangeSearchMawbColorCode,
                                this.props.history
                            )}
                        </Row> */}

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }




        let shipmentPoTable;
        let tableDataDiv;
        if (this.props.isInventorySkuLoading) {
            shipmentPoTable = <div></div>
            tableDataDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventorySkuData) !== 'undefined' && this.props.InventorySkuData != null) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramPoStatusID = queryStringParse(values.skustatusid)
            }
            catch (e) {
                var paramPoStatusID = null
            }

            shipmentPoTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.InventorySkuData,     //   tableData,
                InventorySkuDataElements,       //   DataElements,
                this.props.isInventorySkuLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                (paramPoStatusID == 1 || paramPoStatusID == null ? true : false), // checkColEntry,
                checkedKeys,       //   checkedKeys,
                this.handleCheckAll,       //   handleCheckAll,
                this.handleCheck,       //   handleCheck,

                false,      //   boolSelectButton,
                null,       //   onClickSelect,
                null,       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick

            )



            tableDataDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :

                    <div>

                        <Row>

                            {paramPoStatusID == 1 || paramPoStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>

                                <ExcelSheet data={this.props.InventorySkuData} name="Warehouse">

                                    {
                                        InventorySkuDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>

                        </Row>
                    </div>
                }

            </div>


        }
        else {
            shipmentPoTable = <div></div>
            tableDataDiv = <div></div>
        }



        let totalUnitsNumber;
        let totalRecoveredNumber;
        let totalPalletNumber;
        if (!this.state.existingDataChangeLoading) {
            try {
                totalUnitsNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewUnitsLoaded, 0)
            }
            catch (e) {
                totalUnitsNumber = 0
            }

            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewCasesLoaded, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }

            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }
        else {
            try {
                totalUnitsNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewUnitsLoaded, 0)
            }
            catch (e) {
                totalUnitsNumber = 0
            }
            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewCasesLoaded, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }
            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }




        let buildLoadDrawer;
        if (this.props.isInventorySkuLoading) {
            totalRecoveredNumber = 0
            totalUnitsNumber = 0
            buildLoadDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (this.props.isInventorySkuWarehouseLocationLoading) {
            totalRecoveredNumber = 0
            totalUnitsNumber = 0
            buildLoadDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (typeof (this.state.BuildLoadDict) !== 'undefined' && this.state.BuildLoadDict != null && this.state.BuildLoadDict.length > 0 && this.state.showBuildLoadDrawer) {
            // totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.CasesReceived, 0)

            buildLoadDrawer = <BuildLoadDrawer {...this.props} state={this.state} totalUnitsNumber={totalUnitsNumber} totalRecoveredNum={totalRecoveredNumber} totalPalleteNum={totalPalletNumber} onChange={this.onChangeBuildLoadDrawer} onChangeExisting={this.onChangeExistingBuildLoadDrawer} onChangeLocation={this.onChangeLocation} onClickRemoveFromAdd={this.onClickRemoveFromAdd} confirm={this.onClickBuildLoadDrawerSave} close={this.closeBuildLoadDrawer} remove={this.onClickRemoveFromBuild} />
        }


        return (
            <div>
                <h3>
                    Inventory Management - Inventory
                </h3>

                {hideShowSearch}
                {searchDiv}

                <PanelGroup>

                </PanelGroup>

                {tableDataDiv}
                {shipmentPoTable}

                {buildLoadDrawer}

            </div>
        );

    }
}

export default withRouter(Inventory);
