import { connect } from 'react-redux';
import React, { Component } from 'react';
import Outbound from './Outbound';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { formatStringForAPI, formatDateForAPI } from '../../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventoryLotOutboundLoading: store.inventoryReducer.isInventoryLotOutboundLoading,
    InventoryLotOutboundData: store.inventoryReducer.InventoryLotOutboundData,

});

const mapDispatchToProps = dispatch =>
({

    inventoryLotOutboundGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _Seal, _Carrier, _Trailer, _BOL, _CaseSku, _UnitSKU, _CaseOrUnitSKU, _CaseOrUnitSkuID) => {
        dispatch(inventoryActions.inventoryLotOutboundGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _DepartDateStart, _DepartDateEnd, _DestinationID, _DestinationName, _Seal, _Carrier, _Trailer, _BOL, _CaseSku, _UnitSKU, _CaseOrUnitSKU, _CaseOrUnitSkuID))
    },



});

class OutboundContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        // if (prevProps.InventoryLoadPostSuccessful !== this.props.InventoryLoadPostSuccessful && this.props.InventoryLoadPostSuccessful) {
        //     setTimeout(() => {
        //         this.handleUrlParam()
        //     }, 1000);
        // }
        // else{
        // }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search);
    
        const formatValue = (value, formatter) => {
            try {
                return formatter(value);
            } catch (e) {
                return null;
            }
        };
    
        const paramSKU = formatValue(values.sku, formatStringForAPI);
        const paramSealNo = formatValue(values.sealno, formatStringForAPI);
        const paramDepartDateStart = formatValue(values.departdatestart, formatDateForAPI);
        const paramDepartDateEnd = formatValue(values.departdateend, formatDateForAPI);
    
        if (paramSKU || paramSealNo || paramDepartDateStart || paramDepartDateEnd) {
            this.props.inventoryLotOutboundGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, paramDepartDateStart, paramDepartDateEnd, null, null, paramSealNo, null, null, null, null, null, paramSKU, null)
        }
    };


    render() {

        return (

            <Outbound
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isInventoryLotOutboundLoading={this.props.isInventoryLotOutboundLoading}
                InventoryLotOutboundData={this.props.InventoryLotOutboundData}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundContainer));



