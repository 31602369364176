export const TransContainerWarehouseLocationDataElements = [
    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseLocationName',
        displayName: 'Warehouse Location',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'Cases',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'Oversize',
        displayName: 'Oversize',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },

]


export default TransContainerWarehouseLocationDataElements

