import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Checkbox,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,

    Row,
    Col,
    Panel,
    InputGroup,
    InputNumber,
    Input,
    Loader,
    InputPicker,

    Divider,
    IconButton,
    Tooltip,
    Whisper,
    Grid

} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, positiveIntegerInputMask, textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import RButtonSmall from '../../../componentsGeneral/Rsuite/Button/RButtonSmall';
import PlusIcon from '@rsuite/icons/Plus';
import { convertNanToZero, isNumericWithZero } from '../../../../resources/logic/functions';
import CloseIcon from '@rsuite/icons/Close';
import * as moment from 'moment'
// import { debounceClick } from '../../../resources/logic/useability';
import debounceClick from '../../../../resources/logic/useability';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';

const tooltipReceiveDate = (
    <Tooltip>
        Enter Receive Date only if different than current datetime.
    </Tooltip>
);

const styles = {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
};




const mountRef = React.createRef();


const ReceiveContainerDrawer = ({ onChange, onClickAddPallet, onChangePallet, onClickAddCase, onClickAddSku, onChangeCase, onChangeCaseSKU, onChangeSku, onClickDuplicatePallet, onClickDuplicateCase, onClickDeletePallet, onClickDeleteCase, onClickDeleteSku, onChangeLocation, onChangeReceiveDate, onClickAddLocation, onClickRemoveFromAdd, show, placement, confirm, close, onClickAddMissing, onChangePalletized, masterLabel, caseCounts, unitCounts, palletCount, state, ...props }) => {
    // Prepare data for InputPicker
    const palletdata = props.InventoryPalletWarehouseData2.map(item => ({
        label: item.PalletBarcode,
        value: item.PalletID
    }));


    return (
        // Your component JSX here
        // <div>
        state.showReceiveDrawer === false ?
            <div align="center">
            </div>
            :
            props.InventoryRecoveryPostLoading ?
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
                :
                state.receiveDrawerLoading === true ?
                    <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '30px' } : { width: '800px' }}    >
                        <Drawer.Header>
                            <Drawer.Title style={{ marginBottom: '15px' }}>Container: {masterLabel}</Drawer.Title>
                        </Drawer.Header>

                        <Drawer.Body> <div ref={mountRef} >

                            <div align="center">
                                <section className="panel">
                                </section>
                                <Loader
                                    type="TailSpin"
                                    color="#3396E5"
                                    height="50"
                                    width="50"
                                />
                            </div>

                        </div> </Drawer.Body>

                        <Drawer.Footer>
                        </Drawer.Footer>

                    </Drawer>

                    :

                    <Drawer show={state.showReceiveDrawer} onHide={close} placement={placement} size={"full"} style={{ zIndex: '9999999999999999999', display: 'flex', flexDirection: 'column', height: '100%' }}  >
                        <Drawer.Header>
                            <Drawer.Title style={{ marginBottom: '15px' }}>Container: {masterLabel}</Drawer.Title>

                            <FormGroup>
                                <ButtonToolbar >
                                    <DebouncedButton onClick={confirm} buttonText="Save" />
                                    <Button onClick={close} appearance="default">Cancel</Button>
                                </ButtonToolbar>

                            </FormGroup>
                        </Drawer.Header>

                        <Drawer.Body style={{ flex: '1 1 auto', overflow: 'hidden', height: '100%' }}>
                            <div ref={mountRef} >
                                <Grid fluid style={{ maxHeight: '100%', overflow: 'hidden' }}>
                                    <Row gutter={16}>
                                        <Col xs={8} style={{ overflow: 'auto', maxHeight: 'calc(100vh - 60px)' }}>

                                            <Whisper placement="top" trigger="hover" speaker={tooltipReceiveDate}>
                                                <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                    <InputGroup.Addon>Receive Date (Optional):</InputGroup.Addon>
                                                    <DatePicker
                                                        container={() => mountRef.current}
                                                        placeholder="Edit Receive Date"
                                                        onChange={(e) => { onChangeReceiveDate(e) }}
                                                        value={state.ReceiveDate}
                                                        format="YYYY-MM-DD hh:mm aa"
                                                        defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                        showMeridian
                                                        ranges={[
                                                            {
                                                                label: 'Now',
                                                                value: new Date()
                                                            }
                                                        ]}
                                                        style={{ width: '100%' }}
                                                    />
                                                </InputGroup>
                                            </Whisper>

                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                <Checkbox checked={state.receiveDataRowData['Palletized']} onChange={(e, checked) => onChangePalletized(checked)} >Palletized</Checkbox>
                                            </InputGroup>

                                            <Row >
                                                <Col md={24} sm={24}>
                                                    <Panel bordered style={{ marginBottom: '10px' }}>

                                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                            <InputGroup.Addon>Uncategorized Units:</InputGroup.Addon>
                                                            <InputNumber readOnly value={typeof (unitCounts['null']) !== 'undefined' ? unitCounts['null'].Units : 0} className={'custom-input-number'} scrollable={false} />
                                                        </InputGroup>

                                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                            <InputGroup.Addon>Uncategorized Cases:</InputGroup.Addon>
                                                            <InputNumber readOnly value={typeof (caseCounts['null']) !== 'undefined' ? caseCounts['null'].Qty : 0} className={'custom-input-number'} scrollable={false} />
                                                        </InputGroup>

                                                    </Panel>
                                                </Col>
                                            </Row>

                                            <Row >
                                                <Col md={24} sm={24}>
                                                    <Panel bordered style={{ marginBottom: '10px' }}>
                                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                            <InputGroup.Addon>Pallet Count:</InputGroup.Addon>
                                                            <InputNumber readOnly value={palletCount} className={'custom-input-number'} scrollable={false} />
                                                        </InputGroup>
                                                    </Panel>
                                                </Col>
                                            </Row>

                                            {props.InventoryLotSkuData.map(item => (
                                                <Row >
                                                    <Col md={24} sm={24}>
                                                        <Panel bordered style={{ marginBottom: '10px' }}>

                                                            <Row>
                                                                <Col md={12} sm={24}>
                                                                    SKU: <strong>{item.SKU}</strong>
                                                                    <br />
                                                                    Remaining Units: <strong>{convertNanToZero(item.UnitsExpected) - convertNanToZero(item.UnitsReceived) - convertNanToZero(typeof (unitCounts[item.SkuID]) !== 'undefined' ? unitCounts[item.SkuID].Units : 0)}</strong>
                                                                    <br />
                                                                    Remaining Cases: <strong>{convertNanToZero(item.CasesExpected) - convertNanToZero(item.CasesReceived) - convertNanToZero(typeof (caseCounts[item.SkuID]) !== 'undefined' ? caseCounts[item.SkuID].Qty : 0)}</strong>
                                                                    <br />
                                                                    {/* <Checkbox onChange={(e, value) => { onChange(value, item, 'Customs') }}> Customs Cleared</Checkbox> */}
                                                                </Col>

                                                                <Col md={12} sm={24}>
                                                                    <FormGroup >
                                                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                                            <InputGroup.Addon>Rec'd Units:</InputGroup.Addon>
                                                                            {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                                                            <InputNumber readOnly value={typeof (unitCounts[item.SkuID]) !== 'undefined' ? unitCounts[item.SkuID].Units : 0} className={'custom-input-number'} scrollable={false} />
                                                                        </InputGroup>
                                                                        <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                                                            <InputGroup.Addon>Rec'd Cases:</InputGroup.Addon>
                                                                            {/* <InputNumber defaultValue={item.Cartons - item.RecoveredPCS } className={'custom-input-number'} onChange={(e) => onChange(e, item, 'Recovered')} scrollable={false} /> */}
                                                                            <InputNumber readOnly value={typeof (caseCounts[item.SkuID]) !== 'undefined' ? caseCounts[item.SkuID].Qty : 0} className={'custom-input-number'} scrollable={false} />
                                                                        </InputGroup>

                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Panel>
                                                    </Col>
                                                </Row>
                                            ))}

                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                        </Col>


                                        <Col xs={16} style={{ overflow: 'auto', maxHeight: 'calc(100vh - 60px)' }}>

                                            <Form fluid>

                                                <Panel bordered style={{ marginBottom: '10px' }}>

                                                    <Row >

                                                        {state.ReceivePalletDict.map(pallet => (
                                                            // Pallet
                                                            <Panel bordered style={{ marginBottom: '10px' }}>

                                                                <Row>
                                                                    <Col md={7} sm={24} >

                                                                        <Row style={{ paddingRight: '10px' }}>
                                                                            <Button block active style={{ marginBottom: '10px', width: '100%', cursor: 'default' }} >Pallet Type {pallet.PalletSeq}</Button>
                                                                        </Row>



                                                                        <Row style={{ paddingRight: '10px' }}>
                                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>

                                                                                <InputPicker preventOverflow
                                                                                    style={{ width: '100%' }}
                                                                                    container={() => mountRef.current}
                                                                                    // placeholder={label}
                                                                                    placeholder={'Existing Pallet...'}
                                                                                    value={pallet['PalletID']}
                                                                                    onChange={(e) => { onChangePallet(e, pallet, 'PalletID') }}
                                                                                    data={palletdata}
                                                                                    block
                                                                                    cleanable={true}
                                                                                />
                                                                            </InputGroup>
                                                                        </Row>





                                                                        <Row style={{ paddingRight: '10px' }}>
                                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                                {pallet['PalletID'] === null ? (
                                                                                    <InputPicker preventOverflow
                                                                                        style={{ width: '100%' }}
                                                                                        container={() => mountRef.current}
                                                                                        // placeholder={label}
                                                                                        placeholder={'Location...'}
                                                                                        value={pallet['WarehouseLocationID']}
                                                                                        onChange={(e) => { onChangePallet(e, pallet, 'WarehouseLocationID') }}
                                                                                        data={props.WarehouseLocationsData}
                                                                                        block
                                                                                        cleanable={true}
                                                                                    />
                                                                                ) : (
                                                                                    <InputPicker preventOverflow
                                                                                        style={{ width: '100%' }}
                                                                                        container={() => mountRef.current}
                                                                                        placeholder={'Location...'}
                                                                                        value={pallet['WarehouseLocationID']}
                                                                                        data={props.WarehouseLocationsData}
                                                                                        block
                                                                                        // readOnly
                                                                                        disabled
                                                                                        cleanable={false}
                                                                                    />
                                                                                )}
                                                                            </InputGroup>
                                                                        </Row>

                                                                        <Row style={{ paddingRight: '10px' }}>
                                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                <InputGroup.Addon>Qty:</InputGroup.Addon>

                                                                                {pallet['PalletID'] === null ? (
                                                                                    <InputNumber min={1} value={pallet.Qty} className={'custom-input-number'} onChange={(e) => onChangePallet(e, pallet, 'Qty')} scrollable={false} />
                                                                                ) : (
                                                                                    <InputNumber disabled value={pallet.Qty} className={'custom-input-number'} scrollable={false} />
                                                                                )}

                                                                            </InputGroup>
                                                                        </Row>




                                                                        <Row style={{ paddingRight: '10px' }}>
                                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                {pallet['PalletID'] === null ? (
                                                                                    <Checkbox checked={pallet['Oversize']} onChange={(e, checked) => { onChangePallet(checked, pallet, 'Oversize') }} >Oversize</Checkbox>
                                                                                ) : (
                                                                                    <Checkbox disabled checked={pallet['Oversize']}  >Oversize</Checkbox>
                                                                                )}


                                                                            </InputGroup>
                                                                        </Row>


                                                                        <Row style={{ paddingRight: '10px' }}>
                                                                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                {pallet['PalletID'] === null ? (
                                                                                    // <Checkbox checked={pallet['Oversize']} onChange={(e, checked) => { onChangePallet(checked, pallet, 'Oversize') }} >Oversize</Checkbox>

                                                                                    <InputGroup style={{ width: '100%' }}>
                                                                                        <InputGroup.Addon>Dims:</InputGroup.Addon>
                                                                                        <InputNumber min={1} value={pallet.Length} className={'custom-input-number'} onChange={(e) => onChangePallet(e, pallet, 'Length')} scrollable={false} />

                                                                                        <InputGroup.Addon>x</InputGroup.Addon>

                                                                                        <InputNumber min={1} value={pallet.Width} className={'custom-input-number'} onChange={(e) => onChangePallet(e, pallet, 'Width')} scrollable={false} />
                                                                                    </InputGroup>
                                                                                ) : (
                                                                                    <InputGroup style={{  width: '100%' }}>
                                                                                        <InputGroup.Addon>Dims:</InputGroup.Addon>
                                                                                        <InputNumber disabled min={1} value={pallet.Length} className={'custom-input-number'} scrollable={false} />
                                                                                        <InputGroup.Addon>x</InputGroup.Addon>
                                                                                        <InputNumber disabled min={1} value={pallet.Width} className={'custom-input-number'} scrollable={false} />
                                                                                    </InputGroup>
                                                                                )}


                                                                            </InputGroup>
                                                                        </Row>



                                                                        {pallet['PalletID'] === null && (
                                                                            <Row style={{ paddingRight: '10px' }}>
                                                                                <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDuplicatePallet(e, pallet)} >Duplicate Pallet</Button>
                                                                            </Row>
                                                                        )}

                                                                        <Row style={{ paddingRight: '10px' }}>
                                                                            <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDeletePallet(e, pallet)} >Delete Pallet</Button>
                                                                        </Row>


                                                                    </Col>

                                                                    <Col md={17} sm={24}>

                                                                        {pallet.Cases.map(cases => (
                                                                            <Row >

                                                                                <Panel bordered style={{ marginBottom: '10px' }}>

                                                                                    <Row >
                                                                                        <Button block active style={{ marginBottom: '10px', width: '100%', cursor: 'default' }} >Case Type {cases.CaseSeq}</Button>
                                                                                    </Row>



                                                                                    <Row >
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                                            <InputPicker preventOverflow
                                                                                                container={() => mountRef.current}
                                                                                                // placeholder={label}
                                                                                                placeholder={'SKU...'}
                                                                                                value={cases['SkuID']}
                                                                                                onChange={(e) => { onChangeCase(e, pallet, cases, 'SkuID') }}
                                                                                                data={props.InventoryLotSkuData}
                                                                                                style={{ width: '100%' }}
                                                                                                block
                                                                                                cleanable={true}

                                                                                            />
                                                                                        </InputGroup>
                                                                                    </Row>

                                                                                    <Row>
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            <InputGroup.Addon>Case Qty:</InputGroup.Addon>
                                                                                            <InputNumber min={1} value={cases.Qty} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'Qty')} scrollable={false} />
                                                                                        </InputGroup>
                                                                                    </Row>

                                                                                    <Row>
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                                                                                            <Input value={cases.LotCode} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                                        </InputGroup>
                                                                                    </Row>

                                                                                    <Row>
                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                            <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                                                                                            <Input value={cases.SerialNum} className={'custom-input-number'} onChange={(e) => onChangeCase(e, pallet, cases, 'SerialNum')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                                        </InputGroup>
                                                                                    </Row>


                                                                                    {cases.Skus.filter(x => x.SkuSeq !== 0).length > 0 ?
                                                                                        <div>
                                                                                            <Divider>Units</Divider>

                                                                                            {cases.Skus.map(skus => (

                                                                                                // cases.SkuSeq
                                                                                                <Panel bordered style={{ backgroundColor: '#D8D8D8', marginBottom: '10px' }}>


                                                                                                    <Row style={{ paddingRight: '10px' }}>
                                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                                            {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                                                                                            <InputPicker preventOverflow
                                                                                                                container={() => mountRef.current}
                                                                                                                // placeholder={label}
                                                                                                                placeholder={'SKU...'}
                                                                                                                value={skus['SkuID']}
                                                                                                                onChange={(e) => { onChangeSku(e, pallet, cases, skus, 'SkuID') }}
                                                                                                                data={props.InventoryLotSkuData}
                                                                                                                style={{ width: '100%' }}
                                                                                                                block
                                                                                                                cleanable={true}
                                                                                                            />
                                                                                                        </InputGroup>
                                                                                                    </Row>




                                                                                                    <Row>
                                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                                            <InputGroup.Addon>Units:</InputGroup.Addon>
                                                                                                            <InputNumber min={1} value={skus.Units} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'Units')} scrollable={false}
                                                                                                                onBlur={(e) => {
                                                                                                                    if (e.target.value === null || e.target.value === '' || e.target.value === '0') {
                                                                                                                        onChangeSku(1, pallet, cases, skus, 'Units');
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </InputGroup>
                                                                                                    </Row>


                                                                                                    <Row>
                                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                                            <InputGroup.Addon>Lot Code:</InputGroup.Addon>
                                                                                                            <Input value={skus.LotCode} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'LotCode')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                                                        </InputGroup>
                                                                                                    </Row>

                                                                                                    <Row>
                                                                                                        <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                                                                                            <InputGroup.Addon>Serial Number:</InputGroup.Addon>
                                                                                                            <Input value={skus.SerialNum} className={'custom-input-number'} onChange={(e) => onChangeSku(e, pallet, cases, skus, 'SerialNum')} scrollable={false} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                                                        </InputGroup>
                                                                                                    </Row>

                                                                                                    <Row >
                                                                                                        <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDeleteSku(e, pallet, cases, skus)} >Delete Unit</Button>
                                                                                                    </Row>


                                                                                                </Panel>

                                                                                            ))}
                                                                                        </div>
                                                                                        :
                                                                                        <div> </div>
                                                                                    }

                                                                                    <Row>
                                                                                        <Button style={{ float: 'right', marginBottom: '10px', }} onClick={(e) => onClickAddSku(e, pallet, cases)} appearance="default">+  Add Unit</Button>
                                                                                    </Row>

                                                                                    <Row >
                                                                                        <Button block style={{ marginBottom: '10px', width: '100%' }} onClick={(e) => onClickDuplicateCase(e, pallet, cases)} >Duplicate Case</Button>
                                                                                    </Row>

                                                                                    <Row >
                                                                                        <Button block style={{ width: '100%' }} onClick={(e) => onClickDeleteCase(e, pallet, cases)} >Delete Case</Button>
                                                                                    </Row>




                                                                                </Panel>

                                                                            </Row>

                                                                        ))}

                                                                        <Row>
                                                                            <Button style={{ float: 'right', marginBottom: '10px', }} onClick={(e) => onClickAddCase(e, pallet)} appearance="default">+  Add Case</Button>
                                                                        </Row>

                                                                    </Col>
                                                                </Row>



                                                            </Panel>



                                                        ))}
                                                    </Row>


                                                    <Row>
                                                        <Button style={{ float: 'right' }} onClick={(e) => onClickAddPallet(e)} appearance="default">+  Add Pallet</Button>
                                                    </Row>

                                                </Panel>

                                            </Form>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </Col>

                                    </Row>

                                </Grid>

                            </div>

                        </Drawer.Body>

                    </Drawer>

    );
}





ReceiveContainerDrawer.defaultProps = {
    show: false
};

export default ReceiveContainerDrawer;






