import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import * as moment from 'moment';
import ReactExport from "react-export-excel";
import { Col, FormGroup, Icon, Input, InputGroup, Loader, Panel, Row, List, Button, DatePicker, InputPicker, ButtonToolbar, Divider } from 'rsuite';
import RTableGeneral from '../../../componentsGeneral/Rsuite/Table/RTableGeneral';
import InventoryLotOrderOutboundSkuDataElements from '../../../dataComponentsInventory/InventoryLotOrderOutboundSkuDataElements';
import InventoryLotOrderOutboundSkuCaseLocationsDataElements from '../../../dataComponentsInventory/InventoryLotOrderOutboundSkuCaseLocationsDataElements';
import InventoryLotOrderOutboundSkuEachLocationsDataElements from '../../../dataComponentsInventory/InventoryLotOrderOutboundSkuEachLocationsDataElements';
import { formatNumberForAPI, formatStringForAPI, isNumeric, queryStringParse, formatDateForUrlSearch, formatAllTextNumbersSymbolsForAPI } from '../../../../resources/logic/functions';
import InventoryLoadDataElements from '../../../dataComponentsInventory/InventoryLoadDataElements';
import queryString from 'query-string';
import RTable from '../../../componentsGeneral/Rsuite/Table/RTable';
import { lettersNumbersDashesSpacesInputMask, limitCharacterLength, textNumbersSymbolsInputmask } from '../../../../resources/logic/inputMasks';
import DebouncedButton from '../../../componentsGeneral/Rsuite/Button/DebouncedButton';
import RTableOrderLoadsSelect from '../../../componentsGeneral/Rsuite/Table/RTableOrderLoadsSelect';
import EditLoadDrawer from '../LoadDetails/EditLoadDrawer';
import InventoryLotOrderOutboundSkuPalletLocationsDataElements from '../../../dataComponentsInventory/InventoryLotOrderOutboundSkuPalletLocationsDataElements';
import InventoryLoadCaseDataElements from '../../../dataComponentsInventory/InventoryLoadCaseDataElements';
import InventoryLoadPalletDataElements from '../../../dataComponentsInventory/InventoryLoadPalletDataElements';
import InventoryLoadUnitDataElements from '../../../dataComponentsInventory/InventoryLoadUnitDataElements';
import EditOrderOutboundDrawer from './EditOrderOutboundDrawer';
import EditOrderOutboundSkusDrawer from '../Orders/EditOrderOutboundSkusDrawer';
import OrderOutboundHistoryDiv from '../../_Divs_Lot_History/OrderOutboundHistoryDiv';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


//need to make this outbound vs inbound
const tableIdCol = "OrderOutboundID"


const styles = {
    marginBottom: 15
};

const containerstyles = {
    width: '100%'
};

// function formatAddress(name, line1, line2, city, state, zip, country) {
//     let address = `${name}<br />`;
//     if (line1) address += `${line1}<br />`;
//     if (line2) address += `${line2}<br />`;
//     address += `${city}, ${state} ${zip}, ${country}`;
//     return address;
// }

function formatAddress(name, line1, line2, city, state, zip, country) {
    // Initialize an array to hold the address parts
    const parts = [];

    // Add name and address lines if they are not null or empty
    if (name) parts.push(`${name}<br />`);
    if (line1) parts.push(`${line1}<br />`);
    if (line2) parts.push(`${line2}<br />`);

    // Collect city, state, zip, and country parts, excluding null or empty values
    const locationParts = [city, state, zip, country].filter(part => part).join(", ");

    // Add the location string to the parts array if it's not empty
    if (locationParts) parts.push(locationParts);

    // Join all parts with a separator and return the result
    return parts.join('');
}

const mountRef = React.createRef();

class OrderDetails extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},

            // Table Check Box
            checkedKeys: [],

            //Barcode Scanning
            barcodeInput: '',
            barcodeScannedList: [],

            loadedPalletBarcodes: [],
            loadedPalletIdList: [],
            loadedCaseBarcodes: [],
            loadedCaseIdList: [],

            loadedEachCaseSkuList: [],  //no data added to this
            skuContent: [],  //no data added to this


            //SKU counts:
            loadedEachCounts: [],
            loadedCaseCounts: [],
            //when a case is added to the load then add the case id along with sku and qty 
            //when a pallet is added to the load then add case id's along with sku and qty 
            //when an each is added to the load then add case id along with sku and qty 

            //if a higher level is added check if case or each is contained and remove the lower level



            //sku data
            inventoryLotCaseSkuUnitsData: [], //cumulative inventoryLotCaseSkuUnitsData data
            inventoryLotPalletSkuCasesData: [], //cumulative inventoryLotPalletSkuCasesData data
            inventoryLotPalletSkuUnitsData: [], //cumulative inventoryLotPalletSkuUnitsData data



            //Load Details
            createNewLoadBit: false,

            PickupDate: null,
            DeliveryDate: null,
            Destination: null,
            Carrier: '',
            Trailer: '',
            BOL: '',
            Seal: '',

            MC: '',
            DOT: '',
            Driver: '',
            Truck: '',
            TruckPlate: '',
            TrailerPlate: '',
            Notes: '',


            //Edit Load Drawer
            showEditLoadDrawer: false,
            editLoadItem: {},
            unsavedEditLoadChanges: false,






            //ORDER:
            //Add new outbound order drawer:
            showEditOrderOutboundDrawer: false,
            unsavedNewOrderChanges: false,

            //Add new order state values (inbound or outbound):
            OrderID: '',
            OrderTypeID: 1,
            OrderRef: '',
            OrderDate: null,
            ShipByDate: null,
            DeliverByDate: null,
            ShipToName: '',
            ShipToAddressLine1: '',
            ShipToAddressLine2: '',
            ShipToCity: '',
            ShipToState: '',
            ShipToZip: '',
            ShipToCountry: '',
            OrderPriorityID: 1,
            Notes: '',



            //SKU:

            //Outbound Order SKUs
            showOutboundSkuEdit: false, //edits existing sku
            showEditOrderOutboundSkusDrawer: false,
            OrderOutboundSkuID: '',

            //Add or Edit SKU state values (inbound or outbound)
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //State change
        this.onChangeState = this.onChangeState.bind(this);

        //Detect barcode scanning
        this.scannedInput = '';
        this.handleInputSubmit = this.handleInputSubmit.bind(this);

        //Pick
        this.submitBarcode = this.submitBarcode.bind(this);
        this.onClickPickEach = this.onClickPickEach.bind(this);
        this.onClickPickCase = this.onClickPickCase.bind(this);
        this.onClickPickPallet = this.onClickPickPallet.bind(this);

        //Load
        this.onClickSaveLoadRef = this.onClickSaveLoadRef.bind(this);
        this.onClickCreateNewLoad = this.onClickCreateNewLoad.bind(this);
        this.onClickSelectLoad = this.onClickSelectLoad.bind(this);
        this.tableOnClickLoadDetails = this.tableOnClickLoadDetails.bind(this);
        this.onClickLoadDetails = this.onClickLoadDetails.bind(this);
        this.onClickDeSelectLoad = this.onClickDeSelectLoad.bind(this);


        //Edit Load Drawer
        this.onChangeEditLoadDrawer = this.onChangeEditLoadDrawer.bind(this);
        this.onClickSaveEditLoadDrawer = this.onClickSaveEditLoadDrawer.bind(this);
        this.openEditLoadDrawer = this.openEditLoadDrawer.bind(this);
        this.closeEditLoadDrawer = this.closeEditLoadDrawer.bind(this);

        this.onClickCloseOrder = this.onClickCloseOrder.bind(this);


        // LoadDetails tables 
        this.onClickPallet = this.onClickPallet.bind(this);
        this.onClickCase = this.onClickCase.bind(this);
        this.onClickUnit = this.onClickUnit.bind(this);

        this.onClickDeletePalletFromLoad = this.onClickDeletePalletFromLoad.bind(this);
        this.onClickDeleteCaseFromLoad = this.onClickDeleteCaseFromLoad.bind(this);
        this.onClickDeleteUnitFromLoad = this.onClickDeleteUnitFromLoad.bind(this);



        this.onClickDeleteSku = this.onClickDeleteSku.bind(this);
        this.onClickConfirmAddNewOrderOutbound = this.onClickConfirmAddNewOrderOutbound.bind(this);
        this.closeEditOrderDrawer = this.closeEditOrderDrawer.bind(this);



        //Add To Order Drawer
        this.onEditOrder = this.onEditOrder.bind(this);
        this.resetAddToOrderState = this.resetAddToOrderState.bind(this);
        this.closeAddToOrderDrawer = this.closeAddToOrderDrawer.bind(this);
        this.onClickConfirmAddToOrder = this.onClickConfirmAddToOrder.bind(this);




        //Outbound Sku Drawer
        this.onClickTableLinkEditOrderOutboundSku = this.onClickTableLinkEditOrderOutboundSku.bind(this);
        this.onClickConfirmOrderSkuOutbound = this.onClickConfirmOrderSkuOutbound.bind(this);
        // this.setStateToOutboundOrderSkus = this.setStateToOutboundOrderSkus.bind(this);
        this.closeOrderSkuOutboundDrawer = this.closeOrderSkuOutboundDrawer.bind(this);
        this.onClickEditSku = this.onClickEditSku.bind(this);
        this.onCloseEditSku = this.onCloseEditSku.bind(this);

        this.resetStateOutboundSkus = this.resetStateOutboundSkus.bind(this);

    }




    resetStateOutboundSkus() {
        this.setState({
            //Outbound Order SKUs
            EditOrderID: '',
            showOutboundSkuEdit: false, //edits existing sku
            showEditOrderOutboundSkusDrawer: false,
            OrderOutboundSkuID: '',

            //Add or Edit SKU state values (inbound or outbound)
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });
    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[this.tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {

            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }












    componentDidMount() {
        this.loadSortFromStorage()
        this.updateParamsFromURL()
        this.getsessionStorageData() //this will preserve menu selection
        this.loadWarehouseToState()
        this.loadCustomerToState()


        window.addEventListener('keypress', this.handleGlobalKeyPress);


        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            })
        }

        if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            })
        }


    }


    componentWillUnmount() {
        window.removeEventListener('keypress', this.handleGlobalKeyPress);
    }

    //Detect barcode scanning
    handleGlobalKeyPress = (e) => {
        // Ignore paste events and events when the input is focused
        if (e.ctrlKey || e.metaKey || document.activeElement.id === "barcodeinput") {
            return;
        }

        if (e.key === 'Enter') {
            this.setState({ barcodeInput: this.scannedInput }, () => {
                this.handleInputSubmit();
            });
            this.scannedInput = '';
        } else {
            this.scannedInput += e.key;
        }
    };

    updateParamsFromURL = () => {
        // const values = queryString.parse(this.props.location.search)

    }


    //State Change
    onChangeState(e, statename) {
        this.setState({
            [statename]: e,
            unsavedNewOrderChanges: true,
        });
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.state.WarehouseID) !== 'number') {
                this.setState({
                    WarehouseID: this.props.warehouseSelection
                })
            }
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            if (typeof (this.props.customerSelection) === 'number' && typeof (this.state.CustomerID) !== 'number') {
                this.setState({
                    CustomerID: this.props.customerSelection
                })
            }
        }

        if (prevState.CustomerID !== this.state.CustomerID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                // this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                // this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)

            }
        }

        if (prevState.WarehouseID !== this.state.WarehouseID) {
            if (typeof (this.state.CustomerID) === 'number' && typeof (this.state.WarehouseID) === 'number') {
                // this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)
                // this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID)

            }
        }


        if (this.props.InventoryLotBarcodeScanValidateData != null && this.props.InventoryLotBarcodeScanValidateData.length > 0 && !_.isEqual(prevProps.InventoryLotBarcodeScanValidateData, this.props.InventoryLotBarcodeScanValidateData)) {
            this.addScannedBarcode();
        }

        if (prevProps.InventoryLoadBarcodeScanPostSuccessful !== this.props.InventoryLoadBarcodeScanPostSuccessful && this.props.InventoryLoadBarcodeScanPostSuccessful) {
            this.resetState()
            window.alert('Save successful.')
        }

        if (prevProps.InventoryLoadPalletCaseSkuPostSuccessful !== this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.props.InventoryLoadPalletCaseSkuPostSuccessful && this.state.createNewLoadBit) {
            const values = queryString.parse(this.props.location.search)

            try {
                var paramOrderID = queryStringParse(values.orderid)
            }
            catch (e) {
                var paramOrderID = null
            }

            try {
                var paramLoadID = formatNumberForAPI(this.props.InventoryLoadPalletCaseSkuPostNewLoadID)
            }
            catch (e) {
                var paramLoadID = null
            }

            this.props.history.push({
                search:
                    'orderid=' + paramOrderID + '&' +
                    'loadid=' + paramLoadID
            });

            this.closeCreateNewLoad()
        }



        // Pallet barcode added to load, this gets the SKU data at the unit level
        if (this.props.InventoryLotPalletSkuUnitsData != null && this.props.InventoryLotPalletSkuUnitsData.length > 0 && !_.isEqual(prevProps.InventoryLotPalletSkuUnitsData, this.props.InventoryLotPalletSkuUnitsData)) {

            // Get the current state data
            const currentData = this.state.inventoryLotPalletSkuUnitsData || [];

            // Filter out new data that already exists in the state based on CaseID
            const newData = this.props.InventoryLotPalletSkuUnitsData.filter(newItem =>
                !currentData.some(existingItem => existingItem.PalletID === newItem.PalletID)
            );

            // Append the new data to the current state data
            const updatedData = [...currentData, ...newData];

            // Update the state with the new data
            this.setState({ inventoryLotPalletSkuUnitsData: updatedData });

        }

        //Pallet Barcode added to load, this gets the SKU data at the case level
        if (this.props.InventoryLotPalletSkuCasesData != null && this.props.InventoryLotPalletSkuCasesData.length > 0 && !_.isEqual(prevProps.InventoryLotPalletSkuCasesData, this.props.InventoryLotPalletSkuCasesData)) {

            // Get the current state data
            const currentData = this.state.inventoryLotPalletSkuCasesData || [];

            // Filter out new data that already exists in the state based on CaseID
            const newData = this.props.InventoryLotPalletSkuCasesData.filter(newItem =>
                !currentData.some(existingItem => existingItem.PalletID === newItem.PalletID)
            );

            // Append the new data to the current state data
            const updatedData = [...currentData, ...newData];

            // Update the state with the new data
            this.setState({ inventoryLotPalletSkuCasesData: updatedData });

        }

        //Case Barcode added to load, this gets the SKU data at the unit level
        if (this.props.InventoryLotCaseSkuUnitsData != null && this.props.InventoryLotCaseSkuUnitsData.length > 0 && !_.isEqual(prevProps.InventoryLotCaseSkuUnitsData, this.props.InventoryLotCaseSkuUnitsData)) {

            // Get the current state data
            const currentData = this.state.inventoryLotCaseSkuUnitsData || [];

            // Filter out new data that already exists in the state based on CaseID
            const newData = this.props.InventoryLotCaseSkuUnitsData.filter(newItem =>
                !currentData.some(existingItem => existingItem.CaseID === newItem.CaseID)
            );

            // Append the new data to the current state data
            const updatedData = [...currentData, ...newData];

            // Update the state with the new data
            this.setState({ inventoryLotCaseSkuUnitsData: updatedData });

        }



        if (prevProps.InventoryLotOrderOutboundSkuPostSuccessful !== this.props.InventoryLotOrderOutboundSkuPostSuccessful && this.props.InventoryLotOrderOutboundSkuPostSuccessful) {
            this.onCloseEditSku()
            try {
                this.props.inventoryLotOrderOutboundSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, this.state.EditOrderID, null, null, null, null, null)
            }
            catch (e) { }


        }

        if (prevProps.InventoryLotOrderOutboundPostSuccessful !== this.props.InventoryLotOrderOutboundPostSuccessful && this.props.InventoryLotOrderOutboundPostSuccessful) {
            this.closeOrderSkuOutboundDrawer()
        }









        if (prevProps.InventoryLoadLotData !== this.props.InventoryLoadLotData) {
            // Check if InventoryLoadLotData is not null and its length is 0
            if (this.props.InventoryLoadLotData != null && this.props.InventoryLoadLotData.length === 0) {
                this.onClickCreateNewLoad();
            }
        }



        // if (this.props.InventoryLoadLotData != null && this.props.InventoryLoadLotData.length == 0  ) {
        //     this.onClickCreateNewLoad()
        // }


    }



    loadWarehouseToState() {
        if (typeof (this.props.warehouseSelection) === 'number') {
            this.setState({
                WarehouseID: this.props.warehouseSelection
            });
        }
    }

    loadCustomerToState() {
        if (typeof (this.props.customerSelection) === 'number') {
            this.setState({
                CustomerID: this.props.customerSelection
            });
        }
    }

    getsessionStorageData() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }
        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierArray'));
            if (carrierMenuList != null && carrierMenuList != undefined) {
                this.setState({
                    carrierArray: carrierMenuList
                })
            }
        }
        catch (e) { }
        try {
            let carrierServiceMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CarrierServiceArray'));
            if (carrierServiceMenuList != null && carrierServiceMenuList != undefined) {
                this.setState({
                    carrierServiceArray: carrierServiceMenuList
                })
            }
        }
        catch (e) { }
    }




    //Barcode Scanning
    isPallet = (barcode) => {
        const parts = barcode.split(' ');
        const thirdPosition = parts.length > 2 ? parts[2].toUpperCase() : null;
        return thirdPosition === 'P';
    };

    isCase = (barcode) => {
        const parts = barcode.split(' ');
        const thirdPosition = parts.length > 2 ? parts[2].toUpperCase() : null;
        return thirdPosition === 'C';
    };

    handleInputChange = (value) => {
        this.setState({ barcodeInput: value });
    };

    handleInputSubmit(e, inputDisabled) {


        if (inputDisabled === true) {
            window.alert('Please wait before scanning next barcode.')
        }
        else if (this.props.isInventoryLotBarcodeScanValidateLoading) {
            window.alert('Please wait before scanning next barcode.')
        }
        // else if ((this.props.isInventoryPalletWarehouseLoading || this.props.isInventoryCaseWarehouseLoading || this.props.isInventorySkuWarehouseLoading)) {
        //     window.alert('Please wait before scanning next barcode.')
        // }
        else {
            if (this.state.barcodeInput) {


                this.submitBarcode(this.state.barcodeInput, null, null, null)

                // if (this.isPallet(this.state.barcodeInput) || this.isCase(this.state.barcodeInput)) {
                //     this.props.inventoryLotBarcodeScanValidateGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, formatStringForAPI(this.state.barcodeInput))
                // }


                // // if (this.isPallet(this.state.barcodeInput)) {
                // //     // this.getPallets(barcode);
                // //     this.props.inventoryPalletWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, formatStringForAPI(paramStatusID), null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null, null)
                // //     this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, formatStringForAPI(paramStatusID), null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null)
                // //     this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, formatStringForAPI(paramStatusID), null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
                // // } else if (this.isCase(this.state.barcodeInput)) {
                // //     // getCases function...
                // //     this.props.inventoryCaseWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, formatStringForAPI(paramStatusID), null, null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null)
                // //     this.props.inventoryLotSkuWarehouseLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.warehouseSelection, this.props.customerSelection, null, null, formatStringForAPI(paramStatusID), null, null, null, formatStringForAPI(this.state.barcodeInput), null, null, null, null, null, null, null, null, null, null, null, null, null)
                // // }
                // else {
                //     window.alert('Barcode not recognized as pallet or case.');
                // }

                // this.setState(prevState => ({
                //     barcodeScannedList: [...prevState.barcodeScannedList, this.state.barcodeInput],
                //     barcodeInput: ''
                // }));
            }
        }

    };

    submitBarcode(barcode, caseID, skuID, inputQty) {


        if (this.isPallet(barcode) || this.isCase(barcode)) {
            this.props.inventoryLotBarcodeScanValidateGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, formatStringForAPI(barcode))
        }

        else {
            window.alert('Barcode not recognized as pallet or case.');
        }

        //An each was added:
        if (this.isCase(barcode) && !isNaN(inputQty)) {

            var qty = isNumeric(inputQty) ? parseInt(inputQty, 10) : 0; // Ensure qty is a number

            this.setState(prevState => {
                // Check if the caseID already exists
                const existingIndex = prevState.loadedEachCounts.findIndex(item => item.caseID === caseID);

                if (existingIndex !== -1) {
                    // If exists, create a new array with the updated qty for the existing caseID
                    return {
                        loadedEachCounts: prevState.loadedEachCounts.map((item, index) => {
                            if (index === existingIndex) {
                                return { ...item, qty: item.qty + qty }; // Sum the quantities
                            }
                            return item;
                        })
                    };
                } else {
                    // If not exists, add the new entry
                    const newBarcodeWithQty = { caseID, barcode, skuID, qty };
                    return {
                        loadedEachCounts: [...prevState.loadedEachCounts, newBarcodeWithQty]
                    };
                }
            });



        }
    }





    addScannedBarcode() {
        const { InventoryLotBarcodeScanValidateData } = this.props;
        const { loadedPalletBarcodes, loadedCaseBarcodes, loadedPalletIdList, loadedCaseIdList } = this.state;

        var barcodeType = InventoryLotBarcodeScanValidateData[0].BarcodeType;
        var barcodeStatusID = InventoryLotBarcodeScanValidateData[0].StatusID;
        var palletID = InventoryLotBarcodeScanValidateData[0].PalletID;
        var caseID = InventoryLotBarcodeScanValidateData[0].CaseID;
        var barcode = InventoryLotBarcodeScanValidateData[0].Barcode;
        var valid = InventoryLotBarcodeScanValidateData[0].Valid;

        if (barcodeType === 'P' && valid == true && (barcodeStatusID === 1 || barcodeStatusID === 2)) {


            var matchedItem = this.props.InventoryLotOrderOutboundSkuPalletLocationsData.find(item => item.PalletBarcode === barcode);
            var barcodeExists = !!matchedItem;

            if (barcodeExists) {
                this.onClickPickPallet(null, matchedItem, null)
            }
            else {
                window.alert('Pallet not valid for order.')
            }

            // if (!loadedPalletBarcodes.includes(barcode) && barcodeExists) {

            //     this.props.inventoryLotPalletCaseUnitsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, palletID);
            //     this.props.inventoryLotPalletSkuUnitsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, palletID);

            //     this.setState({
            //         loadedPalletBarcodes: [...loadedPalletBarcodes, barcode],
            //         loadedPalletIdList: [...loadedPalletIdList, palletID]
            //     });



            // }


        }
        else if (barcodeType === 'C' && valid == true && (barcodeStatusID === 1)) { // || barcodeStatusID === 2)) {

            var matchedItem = this.props.InventoryLotOrderOutboundSkuCaseLocationsData.find(item => item.CaseBarcode === barcode);
            var barcodeExists = !!matchedItem;

            if (barcodeExists) {
                this.onClickPickCase(null, matchedItem, null)
            }
            else {
                window.alert('Case not valid for order.')
            }

            // if (!loadedCaseBarcodes.includes(barcode)) {

            //     this.props.inventoryLotCaseSkuUnitsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, caseID, null)

            //     this.setState({
            //         loadedCaseBarcodes: [...loadedCaseBarcodes, barcode],
            //         loadedCaseIdList: [...loadedCaseIdList, caseID]
            //     });
            // }
        }
        else if (valid == false) {
            window.alert('Barcode not recognized.')
        }
        else if (barcodeStatusID === 3) {
            window.alert('Barcode is already picked.')
        }
        else if (barcodeStatusID === 4) {
            window.alert('Barcode is already shipped.')
        }
    }


    // onClickPickEach(e, rowData, history){

    //     // need to select count to load








    //     this.submitBarcode(rowData.CaseBarcode)



    // }


    onClickPickEach(e, rowData, history) {

        var skuQty = rowData.Qty
        var skuLoadedQty = rowData.LoadedEachSkuCount
        var unitsInWhse = rowData.UnitsInWhse;

        var remainingQty = skuQty - skuLoadedQty;

        var defaultEachCount = 0;

        if (unitsInWhse > remainingQty) {
            defaultEachCount = remainingQty
        }
        else {
            defaultEachCount = unitsInWhse
        }

        // try {
        //     var defaultEachCount = rowData.UnitsInWhse;
        // } catch (e) {
        //     var defaultEachCount = 0;
        // }
        // Prompt the user to enter a number, with a default value pre-filled
        const eachCount = window.prompt("Enter the pick eachCount:", defaultEachCount);

        // First, check if eachCount is null or an empty string
        if (eachCount === null || eachCount.trim() === '') {
            // window.alert("No eachCount entered.");
        } else if (isNaN(eachCount)) { // Then, check if eachCount is not a numeric value
            window.alert("Invalid eachCount entered. Please enter a numeric value.");
        } else if (+eachCount > defaultEachCount) {
            // If eachCount is empty, not a number, less than 1, or greater than 100, show an alert
            window.alert("Cannot enter value greater than: " + defaultEachCount);
        } else {
            // If eachCount is not null, not empty, and is a numeric value, proceed with submitting the barcode
            // this.submitBarcode(rowData.CaseBarcode, rowData.CaseID, rowData.SkuID, eachCount);

            //need to add units to data
            rowData['UnitsInWhse'] = formatNumberForAPI(eachCount)

            const values = queryString.parse(this.props.location.search)

            try {
                var paramOrderID = queryStringParse(values.orderid)
            }
            catch (e) {
                var paramOrderID = null
            }

            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }

            var loadCaseDict = rowData
            var LoadCaseDictString = JSON.stringify(loadCaseDict)

            try {
                var paramPostLoadCaseDict = "'" + LoadCaseDictString + "'"
            }
            catch (e) {
                var paramPostLoadCaseDict = null
            }

            var paramPostLoadStatusID = 1

            var paramReportNav = formatStringForAPI('sku')


            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramPostLoadCaseDict, paramReportNav, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, paramPostLoadStatusID, paramOrderID, null, null, null, null, null, null, null)


        }










    }


    onClickPickCase(e, rowData, history) {
        // this.submitBarcode(rowData.CaseBarcode, rowData.CaseID, null, null)



        const values = queryString.parse(this.props.location.search)

        try {
            var paramOrderID = queryStringParse(values.orderid)
        }
        catch (e) {
            var paramOrderID = null
        }

        try {
            var paramLoadID = queryStringParse(values.loadid)
        }
        catch (e) {
            var paramLoadID = null
        }


        var loadCaseDict = rowData
        var LoadCaseDictString = JSON.stringify(loadCaseDict)

        try {
            var paramPostLoadCaseDict = "'" + LoadCaseDictString + "'"
        }
        catch (e) {
            var paramPostLoadCaseDict = null
        }


        var paramPostLoadStatusID = 1


        var paramReportNav = formatStringForAPI('case')


        this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramPostLoadCaseDict, paramReportNav, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, paramPostLoadStatusID, paramOrderID, null, null, null, null, null, null, null)

    }



    onClickPickPallet(e, rowData, history) {

        const values = queryString.parse(this.props.location.search)

        try {
            var paramOrderID = queryStringParse(values.orderid)
        }
        catch (e) {
            var paramOrderID = null
        }

        try {
            var paramLoadID = queryStringParse(values.loadid)
        }
        catch (e) {
            var paramLoadID = null
        }


        var loadCaseDict = rowData
        var LoadCaseDictString = JSON.stringify(loadCaseDict)

        try {
            var paramPostLoadCaseDict = "'" + LoadCaseDictString + "'"
        }
        catch (e) {
            var paramPostLoadCaseDict = null
        }


        var paramPostLoadStatusID = 1


        var paramReportNav = formatStringForAPI('pallet')


        this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramPostLoadCaseDict, paramReportNav, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, paramPostLoadStatusID, paramOrderID, null, null, null, null, null, null, null)

    }



    //Load
    onClickCreateNewLoad(e) {
        this.setState({
            createNewLoadBit: true
        })
    }
    closeCreateNewLoad(e) {
        this.setState({
            createNewLoadBit: false
        })
    }

    onClickSaveLoadRef(e) {

        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            var paramPostLoadStatusID = 1

            const values = queryString.parse(this.props.location.search)

            try {
                var paramOrderID = queryStringParse(values.orderid)
            }
            catch (e) {
                var paramOrderID = null
            }


            try {
                if (this.state.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                var paramPostCarrier = formatStringForAPI(this.state.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                var paramPostBOL = formatStringForAPI(this.state.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                var paramPostTrailer = formatStringForAPI(this.state.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                var paramPostSeal = formatStringForAPI(this.state.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }


            try {
                var paramPostDesintation = formatStringForAPI(this.state.Destination)
            }
            catch (e) {
                var paramPostDesintation = null
            }




            try {
                var paramPostMC = formatStringForAPI(this.state.MC)
            }
            catch (e) {
                var paramPostMC = null
            }
            try {
                var paramPostDOT = formatStringForAPI(this.state.DOT)
            }
            catch (e) {
                var paramPostDOT = null
            }
            try {
                var paramPostDriver = formatStringForAPI(this.state.Driver)
            }
            catch (e) {
                var paramPostDriver = null
            }
            try {
                var paramPostTruck = formatStringForAPI(this.state.Truck)
            }
            catch (e) {
                var paramPostTruck = null
            }
            try {
                var paramPostTruckPlate = formatStringForAPI(this.state.TruckPlate)
            }
            catch (e) {
                var paramPostTruckPlate = null
            }
            try {
                var paramPostTrailerPlate = formatStringForAPI(this.state.TrailerPlate)
            }
            catch (e) {
                var paramPostTrailerPlate = null
            }
            try {
                var paramPostNotes = formatStringForAPI(this.state.Notes)
            }
            catch (e) {
                var paramPostNotes = null
            }


            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, paramPostLoadStatusID, paramOrderID, paramPostMC, paramPostDOT, paramPostDriver, paramPostTruck, paramPostTruckPlate, paramPostTrailerPlate, paramPostNotes)


        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }


    }

    onClickSelectLoad(e, rowData, history) {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramOrderID = queryStringParse(values.orderid)
        }
        catch (e) {
            var paramOrderID = null
        }


        try {
            var paramLoadID = formatNumberForAPI(rowData.LoadID)
        }
        catch (e) {
            var paramLoadID = null
        }


        this.props.history.push({
            search:
                'orderid=' + paramOrderID + '&' +
                'loadid=' + paramLoadID
        });


    }

    tableOnClickLoadDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            var paramWarehouseID = rowData["WarehouseID"]
            var paramCustomerID = rowData["CustomerID"]
            history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }
    }

    onClickDeSelectLoad(e) {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramOrderID = queryStringParse(values.orderid)
        }
        catch (e) {
            var paramOrderID = null
        }

        this.props.history.push({
            search:
                'orderid=' + paramOrderID
        });


    }


    //Edit Load Drawer
    openEditLoadDrawer(e) {
        const values = queryString.parse(this.props.location.search)

        var paramLoadID = queryStringParse(values.loadid)



        var editInventoryLoadLotData = {}
        // editInventoryLoadLotData = this.props.InventoryLoadLotData[0]

        editInventoryLoadLotData['LoadID'] = paramLoadID
        editInventoryLoadLotData['PickupDate'] = this.props.InventoryLoadLotData[0].PickupDate
        editInventoryLoadLotData['DeliveryDate'] = this.props.InventoryLoadLotData[0].DeliveryDate
        editInventoryLoadLotData['Carrier'] = this.props.InventoryLoadLotData[0].Carrier
        editInventoryLoadLotData['BOL'] = this.props.InventoryLoadLotData[0].BOL
        editInventoryLoadLotData['Trailer'] = this.props.InventoryLoadLotData[0].Trailer
        editInventoryLoadLotData['Seal'] = this.props.InventoryLoadLotData[0].Seal
        editInventoryLoadLotData['Destination'] = this.props.InventoryLoadLotData[0].DestinationID


        editInventoryLoadLotData['MC'] = this.props.InventoryLoadLotData[0].MC
        editInventoryLoadLotData['DOT'] = this.props.InventoryLoadLotData[0].DOT
        editInventoryLoadLotData['Driver'] = this.props.InventoryLoadLotData[0].Driver
        editInventoryLoadLotData['Truck'] = this.props.InventoryLoadLotData[0].Truck
        editInventoryLoadLotData['TruckPlate'] = this.props.InventoryLoadLotData[0].TruckPlate
        editInventoryLoadLotData['TrailerPlate'] = this.props.InventoryLoadLotData[0].TrailerPlate
        editInventoryLoadLotData['Notes'] = this.props.InventoryLoadLotData[0].Notes

        this.setState({
            unsavedEditLoadChanges: false,
            editLoadItem: editInventoryLoadLotData,
            showEditLoadDrawer: true
        });
    }

    onChangeEditLoadDrawer(event, label) {
        var currentInventoryLoadLotData = {}
        currentInventoryLoadLotData = this.state.editLoadItem
        currentInventoryLoadLotData[label] = event
        this.setState({
            unsavedEditLoadChanges: true,
            editLoadItem: currentInventoryLoadLotData
        });
        this.forceUpdate()
    }

    onClickSaveEditLoadDrawer(e) {
        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
        const values = queryString.parse(this.props.location.search)

        var paramPostLoadID = queryStringParse(values.loadid)



        // try {
        //     var paramPostLoadID = this.state.editLoadItem.LoadID
        // }
        // catch (e) {
        //     var paramPostLoadID = null
        // }
        try {
            if (this.state.editLoadItem.PickupDate != null) {
                var paramPostPickupDate = "'" + moment(this.state.editLoadItem.PickupDate).format('YYYY-MM-DD') + "'"
            }
            else {
                var paramPostPickupDate = null
            }
        }
        catch (e) {
            var paramPostPickupDate = null
        }
        try {
            if (this.state.editLoadItem.DeliveryDate != null) {
                var paramPostDeliveryDate = "'" + moment(this.state.editLoadItem.DeliveryDate).format('YYYY-MM-DD') + "'"
            }
            else {
                var paramPostDeliveryDate = null
            }
        }
        catch (e) {
            var paramPostDeliveryDate = null
        }
        try {
            var paramPostCarrier = formatStringForAPI(this.state.editLoadItem.Carrier)
        }
        catch (e) {
            var paramPostCarrier = null
        }
        try {
            var paramPostBOL = formatStringForAPI(this.state.editLoadItem.BOL)
        }
        catch (e) {
            var paramPostBOL = null
        }
        try {
            var paramPostTrailer = formatStringForAPI(this.state.editLoadItem.Trailer)
        }
        catch (e) {
            var paramPostTrailer = null
        }
        try {
            var paramPostSeal = formatStringForAPI(this.state.editLoadItem.Seal)
        }
        catch (e) {
            var paramPostSeal = null
        }
        try {
            var paramPostDesintation = formatStringForAPI(this.state.editLoadItem.Destination)
        }
        catch (e) {
            var paramPostDesintation = null
        }



        try {
            var paramPostMC = formatStringForAPI(this.state.editLoadItem.MC)
        }
        catch (e) {
            var paramPostMC = null
        }
        try {
            var paramPostDOT = formatStringForAPI(this.state.editLoadItem.DOT)
        }
        catch (e) {
            var paramPostDOT = null
        }
        try {
            var paramPostDriver = formatStringForAPI(this.state.editLoadItem.Driver)
        }
        catch (e) {
            var paramPostDriver = null
        }
        try {
            var paramPostTruck = formatStringForAPI(this.state.editLoadItem.Truck)
        }
        catch (e) {
            var paramPostTruck = null
        }
        try {
            var paramPostTruckPlate = formatStringForAPI(this.state.editLoadItem.TruckPlate)
        }
        catch (e) {
            var paramPostTruckPlate = null
        }
        try {
            var paramPostTrailerPlate = formatStringForAPI(this.state.editLoadItem.TrailerPlate)
        }
        catch (e) {
            var paramPostTrailerPlate = null
        }
        try {
            var paramPostNotes = formatStringForAPI(this.state.editLoadItem.Notes)
        }
        catch (e) {
            var paramPostNotes = null
        }



        this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, paramPostLoadID, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, paramPostDesintation, null, null, null, paramPostMC, paramPostDOT, paramPostDriver, paramPostTruck, paramPostTruckPlate, paramPostTrailerPlate, paramPostNotes)

        this.setState({
            showEditLoadDrawer: false,
            unsavedEditLoadChanges: false,
            editLoadItem: {}
        });
        // }
        // else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    closeEditLoadDrawer(e) {
        if (this.state.unsavedEditLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setState({
                    showEditLoadDrawer: false,
                    unsavedEditLoadChanges: false,
                    editLoadItem: {}
                });
            }
        }
        else {
            this.setState({
                showEditLoadDrawer: false,
                unsavedEditLoadChanges: false,
                editLoadItem: {}
            });
        }
    }



    onClickCloseOrder(e) {

        if (window.confirm('Are you sure you want to close this order?')) {

            const values = queryString.parse(this.props.location.search)

            var paramOrderID = queryStringParse(values.orderid)

            if (paramOrderID != null) {

                this.props.inventoryLotOrderOutboundPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(paramOrderID),
                    null,
                    null,
                    null,
                    5,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                )
            }

        }



    }


    //Delete Load
    onClickDeleteLoad(e) {
        const values = queryString.parse(this.props.location.search)

        var paramLoadID = queryStringParse(values.loadid)


        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
        if (this.props.InventoryLoadSkuData !== undefined && this.props.InventoryLoadSkuData.length > 0) {
            window.alert('Please first remove all SKUs from Load')
        }
        else if (this.props.InventoryLoadPalletCaseUnitData !== undefined && this.props.InventoryLoadPalletCaseUnitData.length > 0) {
            this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, null, null, null, null, null, null, 1)
        }
        else if (window.confirm('Are you sure you want to delete this load?')) {
            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, 4, null, null, null, null, null, null, null, null)

            const values = queryString.parse(this.props.location.search)

            try {
                var paramOrderID = queryStringParse(values.orderid)
            }
            catch (e) {
                var paramOrderID = null
            }

            this.props.history.push({
                search:
                    'orderid=' + paramOrderID
            });

        }

    }

    //Depart Load
    onClickDepartLoad(e) {
        const values = queryString.parse(this.props.location.search)

        var paramLoadID = queryStringParse(values.loadid)

        // if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

        if (this.props.InventoryLoadLotData[0].Trailer === null || this.props.InventoryLoadLotData[0].Trailer == 'null') {
            window.alert('Please enter Trailer.')
        }
        else if (this.props.InventoryLoadLotData[0].Carrier === null || this.props.InventoryLoadLotData[0].Carrier == 'null') {
            window.alert('Please enter Carrier.')
        }
        else if (this.props.InventoryLoadLotData[0].BOL === null || this.props.InventoryLoadLotData[0].BOL == 'null') {
            window.alert('Please enter BOL.')
        }
        else if (this.props.InventoryLoadLotData[0].Seal === null || this.props.InventoryLoadLotData[0].Seal == 'Seal') {
            window.alert('Please enter Seal.')
        }
        else if (window.confirm('Depart Load?')) {
            this.props.inventoryLoadPalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, 2, null, null, null, null, null, null, null, null, null)

        }
        // }
        // else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    onClickLoadDetails(e) {
        const values = queryString.parse(this.props.location.search)

        var paramID = queryStringParse(values.loadid)

        try {
            var paramWarehouseID = this.props.InventoryLoadLotData[0].WarehouseID
            var paramCustomerID = this.props.InventoryLoadLotData[0].CustomerID
            this.props.history.push({
                pathname: 'lotloaddetails',
                search: 'loadid=\'' + paramID + '\'' + '&' +
                    'warehouseid=' + paramWarehouseID + '&' +
                    'customerid=' + paramCustomerID
            });
        }
        catch (e) {
        }


    }



    // Details tables 

    onClickPallet(e, rowData, history) {
        try {
            var paramID = rowData["PalletID"]
            history.push({
                pathname: 'palletdetails',
                search: 'palletid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }




    onClickCase(e, rowData, history) {
        try {
            var paramID = rowData["CaseID"]
            history.push({
                pathname: 'casedetails',
                search: 'caseid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }



    onClickUnit(e, rowData, history) {
        try {
            var paramSkuID = rowData['SkuID']
            var paramPalletID = rowData['SkuPalletID']
            var paramCaseID = rowData['SkuCaseID']
            var paramUnitID = rowData['UnitCaseSkuID']

            history.push({
                pathname: 'unitdetails',
                search: 'skuid=' + paramSkuID + '&' +
                    'palletid=' + paramPalletID + '&' +
                    'unitid=' + paramUnitID + '&' +
                    'caseid=' + paramCaseID
            });
        }
        catch (e) {
        }
    }

    onClickDeletePalletFromLoad(e, rowData) {
        if (window.confirm('Remove from load?')) {

            const values = queryString.parse(this.props.location.search)

            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }

            this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, rowData['PalletID'], null, null, null, null, null, null, null, null)
        }
    }

    onClickDeleteCaseFromLoad(e, rowData) {
        if (window.confirm('Remove from load?')) {

            const values = queryString.parse(this.props.location.search)

            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }

            this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, rowData['CaseID'], null, null, null, null, null, null)
        }
    }

    onClickDeleteUnitFromLoad(e, rowData) {
        if (window.confirm('Remove from load?')) {

            const values = queryString.parse(this.props.location.search)

            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }

            this.props.inventoryLoadRemovePalletCaseSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramLoadID, null, null, null, null, null, rowData['UnitCaseSkuID'], rowData['SkuUnits'], null, null)
        }
    }











    //Add new Order drawer

    onEditOrder(e) {

        var _OrderID = this.props.InventoryLotOrderOutboundData[0].OrderOutboundID
        var _OrderTypeID = this.props.InventoryLotOrderOutboundData[0].OrderTypeID
        var _OrderRef = this.props.InventoryLotOrderOutboundData[0].OrderRef
        var _OrderDate = this.props.InventoryLotOrderOutboundData[0].OrderDate
        var _ShipByDate = this.props.InventoryLotOrderOutboundData[0].ShipByDate
        var _DeliverByDate = this.props.InventoryLotOrderOutboundData[0].DeliverByDate
        var _ShipToName = this.props.InventoryLotOrderOutboundData[0].ShipToName
        var _ShipToAddressLine1 = this.props.InventoryLotOrderOutboundData[0].ShipToAddressLine1
        var _ShipToAddressLine2 = this.props.InventoryLotOrderOutboundData[0].ShipToAddressLine2
        var _ShipToCity = this.props.InventoryLotOrderOutboundData[0].ShipToCity
        var _ShipToState = this.props.InventoryLotOrderOutboundData[0].ShipToState
        var _ShipToZip = this.props.InventoryLotOrderOutboundData[0].ShipToZip
        var _ShipToCountry = this.props.InventoryLotOrderOutboundData[0].ShipToCountry
        var _OrderPriorityID = this.props.InventoryLotOrderOutboundData[0].OrderPriorityID
        var _Notes = this.props.InventoryLotOrderOutboundData[0].Notes

        this.setState({
            OrderID: _OrderID,
            OrderTypeID: _OrderTypeID,
            OrderRef: _OrderRef,
            OrderDate: _OrderDate,
            ShipByDate: _ShipByDate,
            DeliverByDate: _DeliverByDate,
            ShipToName: _ShipToName,
            ShipToAddressLine1: _ShipToAddressLine1,
            ShipToAddressLine2: _ShipToAddressLine2,
            ShipToCity: _ShipToCity,
            ShipToState: _ShipToState,
            ShipToZip: _ShipToZip,
            ShipToCountry: _ShipToCountry,
            OrderPriorityID: _OrderPriorityID,
            Notes: _Notes,


            showEditOrderOutboundDrawer: true,
        });
        this.forceUpdate()

    }





    onClickConfirmAddNewOrderOutbound(e) {

        if (typeof (this.state.WarehouseID) === 'number' && typeof (this.state.CustomerID) === 'number') {
            if (this.state.OrderRef == null || this.state.OrderRef == '') {
                window.alert('Please enter new Order.')
            }
            else if (this.state.OrderTypeID == null || this.state.OrderTypeID == '') {
                window.alert('Please select Order Type.')
            }
            else if (this.state.OrderPriorityID == null || this.state.OrderPriorityID == '') {
                window.alert('Please select Order Priority.')
            }
            else if (this.state.OrderDate == null || this.state.OrderDate == '') {
                window.alert('Please select Order Date.')
            }
            else {
                this.props.inventoryLotOrderOutboundPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    formatNumberForAPI(this.state.OrderID),
                    formatStringForAPI(this.state.OrderRef),
                    null,
                    null,
                    null,
                    formatNumberForAPI(this.state.OrderTypeID),
                    formatDateForUrlSearch(this.state.OrderDate),
                    formatDateForUrlSearch(this.state.ShipByDate),
                    formatDateForUrlSearch(this.state.DeliverByDate),
                    formatStringForAPI(this.state.ShipToName),
                    formatStringForAPI(this.state.ShipToAddressLine1),
                    formatStringForAPI(this.state.ShipToAddressLine2),
                    formatStringForAPI(this.state.ShipToCity),
                    formatStringForAPI(this.state.ShipToState),
                    formatStringForAPI(this.state.ShipToZip),
                    formatStringForAPI(this.state.ShipToCountry),
                    formatNumberForAPI(this.state.OrderPriorityID),
                    formatStringForAPI(this.state.Notes),
                    null
                )
            }
        }
        else { window.alert('Please select one Warehouse and one Customer to save data.') }
    }
    closeEditOrderDrawer() {
        if (this.state.unsavedNewOrderChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setStateCloseAddNewDrawer()
            }
        }
        else {
            this.setStateCloseAddNewDrawer()
        }
    }
    setStateCloseAddNewDrawer() {

        this.resetStateOutboundSkus()

        this.closeOrderSkuOutboundDrawer()

    }

    setInitialTreeSelection(NewOutboundOrderId, NewOrderLabel) {
        this.setState({
            // OrderTypeID: 1,
            selectedLabel: NewOrderLabel,
            EditOrderID: NewOutboundOrderId,
            addNewText: null,
        });
    }


    //Edit Order Outbound Skus Drawer
    onClickAddSkuToOrderOutbound(placement) {

        this.setState({

            showEditOrderOutboundSkusDrawer: true,

            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });
    }


    onClickConfirmAddToOrder(e) {

        if (this.state.addNewText == null || this.state.addNewText == '') {
            window.alert('Please enter data.')
        }
        // else if (window.confirm('Confirm Save?')) {
        else {
            // save child of selected type
            // if (this.state.selectedType === 'order') {

            this.props.inventoryOrderSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.state.WarehouseID, this.state.CustomerID, this.state.EditOrderID, null, formatStringForAPI(this.state.addNewText), this.state.newSkuUnits, this.state.newSkuCases, this.state.newSkuPallets, null, null, formatStringForAPI(this.state.newSkuCustomerRef), null, formatStringForAPI(this.state.newSkuProject), formatAllTextNumbersSymbolsForAPI(this.state.newSkuNotes))

            this.resetAddToOrderState()

        }
        // }

    }

    onClickEditSku(e, rowData, history) {

        this.setState({
            showOutboundSkuEdit: true,

            OrderOutboundSkuID: rowData['OrderOutboundSkuID'],
            SkuID: rowData['SkuID'],
            SKU: rowData['SKU'],
            UomID: rowData['UomID'],
            Qty: rowData['Qty'],
            LotCode: rowData['LotCode'],
            SerialNumber: rowData['SerialNumber'],
            CustomerRefID: rowData['CustomerRefID'],
            CustomerRef: rowData['CustomerRef'],
            ProjectID: rowData['ProjectID'],
            Project: rowData['Project'],
            SkuNotes: rowData['SkuNotes'],



        });

        // need to find differnce from exsiting  since proc adds new number to existing
    }


    onCloseEditSku() {
        this.setState({
            //Outbound Order SKUs
            showOutboundSkuEdit: false, //edits existing sku
            OrderOutboundSkuID: '',

            //Add or Edit SKU state values (inbound or outbound)
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });

    }

    onClickDeleteSku(e, rowData, history) {

        if (window.confirm('Confirm Delete?')) {
            this.props.inventoryLotOrderOutboundSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, formatNumberForAPI(rowData['OrderOutboundID']), formatNumberForAPI(rowData['OrderOutboundSkuID']), formatNumberForAPI(rowData['SkuID']), null, null, null, null, null, null, null, null, null, null, 1)
        }
    }


    resetAddToOrderState() {
        this.setState({
            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',
        });
    }

    closeAddToOrderDrawer() {

        this.resetStateOutboundSkus()

    }



    //Add New Order Drawer
    onClickTableLinkEditOrderOutboundSku(e) {


        var outboundOrderID = null
        var newOrder = null
        var newWarehouse = null
        var newCustomer = null
        try {
            outboundOrderID = this.props.InventoryLotOrderOutboundData[0].OrderOutboundID
            newOrder = this.props.InventoryLotOrderOutboundData[0].OrderRef
            newWarehouse = this.props.InventoryLotOrderOutboundData[0].WarehouseID
            newCustomer = this.props.InventoryLotOrderOutboundData[0].CustomerID

            this.props.inventoryLotOrderOutboundSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, outboundOrderID, null, null, null, null, null)
        }
        catch (e) { }

        this.setState({
            EditOrderID: outboundOrderID,
            showEditOrderOutboundSkusDrawer: true,

            WarehouseID: newWarehouse,
            CustomerID: newCustomer,

            selectedLabel: newOrder,

            SkuID: '',
            SKU: '',
            UomID: null,
            Qty: null,
            LotCode: '',
            SerialNumber: '',
            CustomerRefID: null,
            CustomerRef: '',
            ProjectID: null,
            Project: '',
            SkuNotes: '',

        });

    }

    onClickConfirmOrderSkuOutbound() {
        if (!this.state.SKU) {
            window.alert('Please enter SKU.')
        }
        else if (!this.state.UomID) {
            window.alert('Please select UOM.')
        }
        else if (!this.state.Qty) {
            window.alert('Please enter Qty.')
        }
        else {
            this.props.inventoryLotOrderOutboundSkuPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, formatNumberForAPI(this.state.EditOrderID), formatNumberForAPI(this.state.OrderOutboundSkuID), formatNumberForAPI(this.state.SkuID), formatStringForAPI(this.state.SKU), formatNumberForAPI(this.state.UomID), formatNumberForAPI(this.state.Qty), formatStringForAPI(this.state.LotCode), formatStringForAPI(this.state.SerialNumber), formatNumberForAPI(this.state.CustomerRefID), formatStringForAPI(this.state.CustomerRef), formatNumberForAPI(this.state.ProjectID), formatStringForAPI(this.state.Project), formatStringForAPI(this.state.SkuNotes), null)
        }
    }

    closeOrderSkuOutboundDrawer() {
        this.resetStateOutboundSkus()
        this.setState({
            showEditOrderOutboundDrawer: false,
            unsavedNewOrderChanges: false,
        });
    }





    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;

        const values = queryString.parse(this.props.location.search)

        var paramLoadID = null
        try {
            paramLoadID = queryStringParse(values.loadid)
        }
        catch (e) {
            paramLoadID = null
        }

        if (this.props.isInventoryLotOrderOutboundGetLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, loading...</p>
                </div>
            );
        }


        if (this.props.isInventoryLotOrderOutboundSkuLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, loading...</p>
                </div>
            );
        }





        let destinationDiv;
        if (this.props.isInventoryDestinationLoading) {
            destinationDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryDestinationData) !== 'undefined' && this.props.InventoryDestinationData != null) {
            destinationDiv = <div style={{ marginBottom: '5px', width: '100%' }}>
                <InputPicker preventOverflow
                    container={() => mountRef.current}
                    block
                    creatable
                    placeholder={'Destination...'}
                    value={this.state.Destination}
                    // onChange={(e, value, label) => { onChangeDestination(e, value, label) }}
                    onChange={(e) => { this.onChangeState(e, 'Destination') }}
                    data={this.props.InventoryDestinationData}
                    cleanable={true}
                />
            </div>
        }
        else {
            destinationDiv = <div></div>
        }




        let editLoadDrawerDiv;
        let loadDiv;
        let rTable;
        let closedLoadTable;
        let closedLoadDiv;
        if (this.props.isInventoryLoadLotLoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait, loading...</p>
                </div>
            );
        }
        else if (paramLoadID != null && typeof (this.props.InventoryLoadLotData[0]) !== 'undefined' && this.props.InventoryLoadLotData != null) {

            closedLoadDiv = <div></div>

            //load selected to build, show load details and option to cancel or create new load
            var currentLoadStatus = this.props.InventoryLoadLotData[0].CurrentLoadStatusID


            // houseButtonDiv = currentLoadStatus != 4 ? <Row>
            //     SKU List
            //     <Button onClick={(e) => { this.onClickAddToLoad(e) }} style={{ float: 'right' }}> Add to Load </Button>
            // </Row> : <div></div>

            editLoadDrawerDiv = <EditLoadDrawer {...this.props} state={this.state}
                onChange={this.onChangeEditLoadDrawer}
                onClickSave={this.onClickSaveEditLoadDrawer}
                close={this.closeEditLoadDrawer}
            />


            loadDiv = <Panel bordered style={{ marginBottom: '10px' }} header={
                <div >
                    <h4>Load Details</h4>
                </div>
            }>

                <div>


                    <Row style={{ marginBottom: 10 }} >
                        <Col md={24} sm={24}>
                            {currentLoadStatus != 4 ? <ButtonToolbar style={{ float: 'right' }}>  {this.props.InventoryLoadLotData[0].CurrentLoadStatusID == 1 ? <Button onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Load</Button> : ''}  <Button onClick={(e) => { this.onClickLoadDetails(e) }} > Load Details </Button>   <Button onClick={(e) => { this.openEditLoadDrawer(e) }} > Edit Load </Button> <Button onClick={(e) => { this.onClickDeSelectLoad(e) }} > Deselect Load </Button><Button onClick={(e) => { this.onClickDeleteLoad(e) }} > Delete Load </Button></ButtonToolbar> : ''}
                        </Col>
                    </Row>




                    <Row >

                        <Col md={12} sm={12}>
                            <Panel bordered style={{ marginBottom: 10 }} >
                                Warehouse: <strong>{this.props.InventoryLoadLotData[0].WarehouseName}</strong>
                                <br />
                                Customer: <strong>{this.props.InventoryLoadLotData[0].CustomerName}</strong>
                                <br />
                                Carrier: <strong>{this.props.InventoryLoadLotData[0].Carrier}</strong>
                                <br />
                                Trailer: <strong>{this.props.InventoryLoadLotData[0].Trailer}</strong>
                                <br />
                                BOL: <strong>{this.props.InventoryLoadLotData[0].BOL}</strong>
                                <br />
                                Seal: <strong>{this.props.InventoryLoadLotData[0].Seal}</strong>
                                <br />
                                {/* Consignee: <strong>{this.props.InventoryLoadLotData[0].ConsigneeName}</strong> */}
                                {/* <br /> */}
                                Destination: <strong>{this.props.InventoryLoadLotData[0].Destination}</strong>
                                <br />
                                Load Status: <strong>{this.props.InventoryLoadLotData[0].LoadStatusName}</strong>
                                <br />


                            </Panel>
                        </Col>


                        <Col md={12} sm={12}>
                            <Panel bordered style={{ marginBottom: 10 }}  >
                                MC #: <strong>{this.props.InventoryLoadLotData[0].MC}</strong>
                                <br />
                                DOT #: <strong>{this.props.InventoryLoadLotData[0].DOT}</strong>
                                <br />
                                Driver: <strong>{this.props.InventoryLoadLotData[0].Driver}</strong>
                                <br />
                                Truck #: <strong>{this.props.InventoryLoadLotData[0].Truck}</strong>
                                <br />
                                Truck Plate: <strong>{this.props.InventoryLoadLotData[0].TruckPlate}</strong>
                                <br />
                                Trailer Plate: <strong>{this.props.InventoryLoadLotData[0].TrailerPlate}</strong>
                                <br />
                                Notes: <strong>{this.props.InventoryLoadLotData[0].Notes}</strong>
                                <br />
                                <br />


                            </Panel>
                        </Col>



                    </Row>


                    {
                        (this.props.InventoryLoadLotData[0].TotalUnits == null && this.props.InventoryLoadLotData[0].TotalCases == null && this.props.InventoryLoadLotData[0].TotalPallets == null) ||
                            (this.props.InventoryLoadLotData[0].TotalUnits == 0 && this.props.InventoryLoadLotData[0].TotalCases == 0 && this.props.InventoryLoadLotData[0].TotalPallets == 0)

                            ? <div></div> :

                            <Row >
                                <Col md={12} sm={24}>
                                    <Panel bordered style={{ marginBottom: 10 }}>
                                        Total Units: <strong>{this.props.InventoryLoadLotData[0].TotalUnits}</strong>
                                        <br />
                                        Total Cases: <strong>{this.props.InventoryLoadLotData[0].TotalCases}</strong>
                                        <br />
                                        Total Pallets: <strong>{this.props.InventoryLoadLotData[0].TotalPallets}</strong>
                                        <br />

                                    </Panel>
                                </Col>
                                <Col md={12} sm={24}>
                                    <Panel bordered style={{ marginBottom: 10 }}>

                                        Pickup Date: <strong>{this.props.InventoryLoadLotData[0].PickupDate}</strong>
                                        <br />

                                        Delivery Date: <strong>{this.props.InventoryLoadLotData[0].DeliveryDate}</strong>
                                        <br />
                                        <br />


                                    </Panel>
                                </Col>
                            </Row>
                    }
                </div>

            </Panel>









            closedLoadTable = RTableOrderLoadsSelect(
                "LoadID", // keyColumn,
                this.props.InventoryLoadLotData, // tableData,
                InventoryLoadDataElements, // DataElements,
                this.props.isInventoryLoadLotLoading, // loading,

                this.props.isMobile, // isMobile,
                this.props.history, // history,

                sortColumn, // sortColumn,
                sortType, // sortType,
                this.handleSortColumn, // handleSortColumn,

                null, // checkColEntry,
                null, // checkedKeys,
                null, // handleCheckAll,
                null, // handleCheck,

                false, // boolSelectButton,
                null, // onClickSelect,
                null, // selectButtonText,
                null, // selectHighlightRowKey,

                true, // link1bool,
                'Details', // link1text,
                this.tableOnClickLoadDetails, // link1onClick,

                false, // link2bool,
                null, // link2text,
                null // link2onClick
            )



            closedLoadDiv = <Panel bordered style={{ marginBottom: '10px' }} header={
                <div >
                    <h4>Loads</h4>
                </div>
            }>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>

                            {/* <Button style={{ float: 'left', marginRight: '10px' }} onClick={(e) => { this.onClickCreateNewLoad(e) }}>Create New Load</Button> */}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                    {
                                        InventoryLoadDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

                {closedLoadTable}

            </Panel>

        }
        else if (typeof (this.props.InventoryLoadLotData[0]) !== 'undefined' && this.props.InventoryLoadLotData.length > 0 && this.props.InventoryLoadLotData != null && this.state.createNewLoadBit == false) {
            editLoadDrawerDiv = <div></div>;

            closedLoadTable = RTableOrderLoadsSelect(
                "LoadID", // keyColumn,
                this.props.InventoryLoadLotData, // tableData,
                InventoryLoadDataElements, // DataElements,
                this.props.isInventoryLoadLotLoading, // loading,

                this.props.isMobile, // isMobile,
                this.props.history, // history,

                sortColumn, // sortColumn,
                sortType, // sortType,
                this.handleSortColumn, // handleSortColumn,

                null, // checkColEntry,
                null, // checkedKeys,
                null, // handleCheckAll,
                null, // handleCheck,

                false, // boolSelectButton,
                null, // onClickSelect,
                null, // selectButtonText,
                null, // selectHighlightRowKey,

                true, // link1bool,
                'Details', // link1text,
                this.tableOnClickLoadDetails, // link1onClick,

                false, // link2bool,
                null, // link2text,
                null // link2onClick
            )


            rTable = RTableOrderLoadsSelect(
                "LoadID", // keyColumn,
                this.props.InventoryLoadLotData, // tableData,
                InventoryLoadDataElements, // DataElements,
                this.props.isInventoryLoadLotLoading, // loading,

                this.props.isMobile, // isMobile,
                this.props.history, // history,

                sortColumn, // sortColumn,
                sortType, // sortType,
                this.handleSortColumn, // handleSortColumn,

                null, // checkColEntry,
                null, // checkedKeys,
                null, // handleCheckAll,
                null, // handleCheck,

                true, // boolSelectButton,
                this.onClickSelectLoad, // onClickSelect,
                'Select', // selectButtonText,
                null, // selectHighlightRowKey,

                true, // link1bool,
                'Details', // link1text,
                this.tableOnClickLoadDetails, // link1onClick,

                false, // link2bool,
                null, // link2text,
                null // link2onClick
            )





            loadDiv = <Panel bordered style={{ marginBottom: '10px' }} header={
                <div >
                    <h4>Loads</h4>
                </div>
            }>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>

                            <Button style={{ float: 'left', marginRight: '10px' }} onClick={(e) => { this.onClickCreateNewLoad(e) }}>Create New Load</Button>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                    {
                                        InventoryLoadDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

                {rTable}

            </Panel>





            closedLoadDiv = <Panel bordered style={{ marginBottom: '10px' }} header={
                <div >
                    <h4>Loads</h4>
                </div>
            }>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>

                            {/* <Button style={{ float: 'left', marginRight: '10px' }} onClick={(e) => { this.onClickCreateNewLoad(e) }}>Create New Load</Button> */}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.InventoryLoadLotData} name="Load">

                                    {
                                        InventoryLoadDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

                {closedLoadTable}

            </Panel>



        }
        else if (this.state.createNewLoadBit || this.props.InventoryLoadLotData.length == 0) {
            editLoadDrawerDiv = <div></div>;
            closedLoadDiv = <div></div>

            loadDiv = <Panel bordered style={{ marginBottom: '10px' }} header={
                <div >
                    <h4>Create New Load:</h4>
                </div>
            }>
                <Row>
                    <Col md={24} sm={24}>
                        <FormGroup >
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Pickup Date:</InputGroup.Addon>
                                <DatePicker
                                    container={() => mountRef.current} oneTap style={containerstyles} value={(this.state.PickupDate === null ? null : moment(this.state.PickupDate).format('YYYY-MM-DD'))} onChange={(e) => this.onChangeState(e, 'PickupDate')} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Delivery Date:</InputGroup.Addon>
                                <DatePicker
                                    container={() => mountRef.current} oneTap style={containerstyles} value={(this.state.DeliveryDate === null ? null : moment(this.state.DeliveryDate).format('YYYY-MM-DD'))} onChange={(e) => this.onChangeState(e, 'DeliveryDate')} />
                            </InputGroup>

                            {destinationDiv}

                        </FormGroup>
                    </Col>
                    <Col md={12} sm={24}>
                        <FormGroup >

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                <Input value={this.state.Carrier} onChange={(e) => { this.onChangeState(e, 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Trailer #:</InputGroup.Addon>
                                <Input value={this.state.Trailer} onChange={(e) => { this.onChangeState(e, 'Trailer') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>


                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>MC #:</InputGroup.Addon>
                                <Input defaultValue={this.state.MC} onChange={(e) => { this.onChangeState(e, 'MC') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>DOT #:</InputGroup.Addon>
                                <Input defaultValue={this.state.DOT} onChange={(e) => { this.onChangeState(e, 'DOT') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Driver:</InputGroup.Addon>
                                <Input defaultValue={this.state.Driver} onChange={(e) => { this.onChangeState(e, 'Driver') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>




                        </FormGroup>
                    </Col>
                    <Col md={12} sm={24}>
                        <FormGroup >


                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>BOL #:</InputGroup.Addon>
                                <Input value={this.state.BOL} onChange={(e) => { this.onChangeState(e, 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Seal #:</InputGroup.Addon>
                                <Input value={this.state.Seal} onChange={(e) => { this.onChangeState(e, 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Truck #:</InputGroup.Addon>
                                <Input defaultValue={this.state.Truck} onChange={(e) => { this.onChangeState(e, 'Truck') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Truck Plate:</InputGroup.Addon>
                                <Input defaultValue={this.state.TruckPlate} onChange={(e) => { this.onChangeState(e, 'TruckPlate') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Trailer Plate:</InputGroup.Addon>
                                <Input defaultValue={this.state.TrailerPlate} onChange={(e) => { this.onChangeState(e, 'TrailerPlate') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>


                <Row>

                    <Col md={24} sm={24}>

                        <FormGroup >

                            <InputGroup style={{ marginBottom: '15px', width: '100%' }}>
                                <InputGroup.Addon>Notes:</InputGroup.Addon>
                                <Input defaultValue={this.state.Notes} onChange={(e) => { this.onChangeState(e, 'Notes') }} onInput={(e) => e.target.value = limitCharacterLength(textNumbersSymbolsInputmask(e), 300)} />
                            </InputGroup>

                        </FormGroup>
                    </Col>
                </Row>




                {this.props.InventoryLoadLotData.length == 0 ?
                    <Row >
                        <Col md={24} sm={24}>
                            <ButtonToolbar >
                                <DebouncedButton onClick={(e) => { this.onClickSaveLoadRef(e) }} buttonText="Save" block={true} />
                            </ButtonToolbar>
                        </Col>
                    </Row>
                    :
                    <Row >
                        <Col md={12} sm={12}>
                            <ButtonToolbar >
                                <DebouncedButton onClick={(e) => { this.onClickSaveLoadRef(e) }} buttonText="Save" block={true} />
                            </ButtonToolbar>
                        </Col>
                        <Col md={12} sm={12}>
                            <ButtonToolbar >
                                <Button block onClick={(e) => { this.closeCreateNewLoad(e) }} appearance="default">Cancel</Button>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                }



            </Panel>


        }
        else {
            closedLoadDiv = <div></div>
            closedLoadTable = <div></div>
            rTable = <div></div>
            loadDiv = <div></div>
            editLoadDrawerDiv = <div></div>;
        }





        let inputDisabled = false;
        if (this.props.isInventoryLotBarcodeScanValidateLoading) {
            inputDisabled = true;
        }
        else {
            inputDisabled = false;
        }

        let orderDetailsDiv;
        let orderStatusID = 0
        if (this.props.isInventoryLotOrderOutboundGetLoading) {
            orderStatusID = 0
            orderDetailsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotOrderOutboundData[0]) !== 'undefined' && this.props.InventoryLotOrderOutboundData != null) {
            orderStatusID = this.props.InventoryLotOrderOutboundData[0].OrderStatusID

            orderDetailsDiv = <div>

                <Row>
                    <Button style={{ float: 'right', marginBottom: '10px', marginRight: '5px' }} onClick={(e) => { this.onClickCloseOrder(e) }}>Close Order</Button>
                    <Button style={{ float: 'right', marginBottom: '10px', marginRight: '10px' }} onClick={(e) => { this.onEditOrder(e) }}>Edit Details</Button>

                </Row>

                <Row >
                    <Col md={12} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }}>
                            Order Ref: <strong>{this.props.InventoryLotOrderOutboundData[0].OrderRef}</strong>
                            <br />
                            Status: <strong>{this.props.InventoryLotOrderOutboundData[0].OrderStatus}</strong>
                            <br />
                            Priority: <strong>{this.props.InventoryLotOrderOutboundData[0].OrderPriority}</strong>
                            <br />
                            Type: <strong>{this.props.InventoryLotOrderOutboundData[0].OrderType}</strong>
                            <br />
                            Notes: <strong>{this.props.InventoryLotOrderOutboundData[0].Notes}</strong>
                            <br />
                        </Panel>
                    </Col>

                    <Col md={12} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }}>
                            Warehouse: <strong>{this.props.InventoryLotOrderOutboundData[0].WarehouseName}</strong>
                            <br />
                            Customer: <strong>{this.props.InventoryLotOrderOutboundData[0].CustomerName}</strong>
                            <br />
                            Order Date: <strong>{this.props.InventoryLotOrderOutboundData[0].OrderDate}</strong>
                            <br />
                            Ship By Date: <strong>{this.props.InventoryLotOrderOutboundData[0].ShipByDate}</strong>
                            <br />
                            Deliver By Date: <strong>{this.props.InventoryLotOrderOutboundData[0].DeliverByDate}</strong>
                            <br />

                        </Panel>
                    </Col>

                </Row>

                <Row>

                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }}>

                            Ship To:
                            <strong>
                                <div dangerouslySetInnerHTML={{
                                    __html: formatAddress(
                                        this.props.InventoryLotOrderOutboundData[0].ShipToName,
                                        this.props.InventoryLotOrderOutboundData[0].ShipToAddressLine1,
                                        this.props.InventoryLotOrderOutboundData[0].ShipToAddressLine2,
                                        this.props.InventoryLotOrderOutboundData[0].ShipToCity,
                                        this.props.InventoryLotOrderOutboundData[0].ShipToState,
                                        this.props.InventoryLotOrderOutboundData[0].ShipToZip,
                                        this.props.InventoryLotOrderOutboundData[0].ShipToCountry
                                    )
                                }} style={{ marginBottom: 5 }} />
                            </strong>


                        </Panel>

                    </Col>
                </Row>

            </div>
        }
        else {
            orderDetailsDiv = <div></div>
            orderStatusID = 0
        }



        let orderSkuDiv;
        if (this.props.isInventoryLotOrderOutboundSkuGetLoading) {
            orderSkuDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotOrderOutboundSkuData[0]) !== 'undefined' && this.props.InventoryLotOrderOutboundSkuData != null) {


            orderSkuDiv = <div>
                <FormGroup>
                    <Panel header={
                        <div>
                            <h4>Order Contents</h4>

                            {
                                orderStatusID == 1 || orderStatusID == 2 || orderStatusID == 3 ?
                                    <Button style={{ float: 'right', marginBottom: '10px' }} onClick={(e) => { this.onClickTableLinkEditOrderOutboundSku(e) }}>Edit SKUs</Button>
                                    : <div></div>
                            }
                        </div>
                    } bordered style={{ marginBottom: 10 }}>
                        <Row>
                            {RTableGeneral(
                                tableIdCol, // keyColumn,
                                this.props.InventoryLotOrderOutboundSkuData, // tableData,
                                InventoryLotOrderOutboundSkuDataElements, // DataElements,
                                this.props.isInventoryLotOrderOutboundSkuGetLoading, // loading,

                                this.props.isMobile, // isMobile,
                                this.props.history, // history,

                                null, // sortColumn,
                                null, // sortType,
                                null, // handleSortColumn,

                                null, // checkColEntry,
                                null, // checkedKeys,
                                null, // handleCheckAll,
                                null, // handleCheck,

                                false, // boolSelectButton,
                                null, // onClickSelect,
                                null, // selectButtonText,
                                null, // selectHighlightRowKey,

                                false, // link1bool,
                                null, // link1text,
                                null, // link1onClick,

                                false, // link2bool,
                                null, // link2text,
                                null // link2onClick
                            )}
                        </Row>


                    </Panel>
                </FormGroup>
            </div>
        }
        else {
            orderSkuDiv = <div></div>
        }


        let eachLocationDiv;
        if (this.props.isInventoryLotOrderOutboundSkuEachLocationsGetLoading) {
            eachLocationDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotOrderOutboundSkuEachLocationsData[0]) !== 'undefined' && this.props.InventoryLotOrderOutboundSkuEachLocationsData != null) {

            // const filteredSkuData = this.props.InventoryLotOrderOutboundSkuEachLocationsData.filter(sku =>
            //     !this.state.loadedCaseIdList.includes(sku.CaseID)
            // );


            const filteredSkuData = this.props.InventoryLotOrderOutboundSkuEachLocationsData.filter(sku => {

                // Check if sku.CaseID is not in the loadedCaseIdList
                const isNotInCaseIDList = !this.state.loadedCaseIdList.includes(sku.CaseID);

                // Find the corresponding loadedEachCount entry
                const loadedEachCountEntry = this.state.loadedEachCounts.find(entry => entry.caseID === sku.CaseID);

                // If there's no corresponding loadedEachCount entry, consider it as not exceeding UnitsInWhse
                const doesNotExceedUnitsInWhse = !loadedEachCountEntry || sku.UnitsInWhse > loadedEachCountEntry.qty;

                // Return true if both conditions are met
                // return isNotInCaseIDList && doesNotExceedUnitsInWhse;

                return doesNotExceedUnitsInWhse;
            });

            eachLocationDiv = <div>
                <FormGroup>
                    <Panel bordered header={<h4>Inventory Locations - Each</h4>} style={{ marginBottom: 10 }}>
                        <Row>
                            {RTableGeneral(
                                tableIdCol, // keyColumn,
                                filteredSkuData, // tableData,
                                InventoryLotOrderOutboundSkuEachLocationsDataElements, // DataElements,
                                this.props.isInventoryLotOrderOutboundSkuEachLocationsGetLoading, // loading,

                                this.props.isMobile, // isMobile,
                                this.props.history, // history,

                                null, // sortColumn,
                                null, // sortType,
                                null, // handleSortColumn,

                                null, // checkColEntry,
                                null, // checkedKeys,
                                null, // handleCheckAll,
                                null, // handleCheck,

                                true, // boolSelectButton,
                                this.onClickPickEach, // onClickSelect,
                                'Pick', // selectButtonText,
                                null, // selectHighlightRowKey,

                                false, // link1bool,
                                null, // link1text,
                                null, // link1onClick,

                                false, // link2bool,
                                null, // link2text,
                                null // link2onClick
                            )}
                        </Row>
                    </Panel>
                </FormGroup>
            </div>
        }
        else {
            eachLocationDiv = <div></div>
        }



        let caseLocationDiv;
        if (this.props.isInventoryLotOrderOutboundSkuCaseLocationsGetLoading) {
            caseLocationDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotOrderOutboundSkuCaseLocationsData[0]) !== 'undefined' && this.props.InventoryLotOrderOutboundSkuCaseLocationsData != null) {

            caseLocationDiv = <div>
                <FormGroup>
                    <Panel bordered header={<h4>Inventory Locations - Cases</h4>} style={{ marginBottom: 10 }}>
                        <Row>
                            {RTableGeneral(
                                tableIdCol, // keyColumn,
                                this.props.InventoryLotOrderOutboundSkuCaseLocationsData, // tableData,
                                InventoryLotOrderOutboundSkuCaseLocationsDataElements, // DataElements,
                                this.props.isInventoryLotOrderOutboundSkuCaseLocationsGetLoading, // loading,

                                this.props.isMobile, // isMobile,
                                this.props.history, // history,

                                null, // sortColumn,
                                null, // sortType,
                                null, // handleSortColumn,

                                null, // checkColEntry,
                                null, // checkedKeys,
                                null, // handleCheckAll,
                                null, // handleCheck,

                                true, // boolSelectButton,
                                this.onClickPickCase, // onClickSelect,
                                'Pick', // selectButtonText,
                                null, // selectHighlightRowKey,

                                false, // link1bool,
                                null, // link1text,
                                null, // link1onClick,

                                false, // link2bool,
                                null, // link2text,
                                null // link2onClick
                            )}
                        </Row>
                    </Panel>
                </FormGroup>
            </div>
        }
        else {
            caseLocationDiv = <div></div>
        }





        let palletLocationDiv;
        if (this.props.isInventoryLotOrderOutboundSkuPalletLocationsGetLoading) {
            palletLocationDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLotOrderOutboundSkuPalletLocationsData[0]) !== 'undefined' && this.props.InventoryLotOrderOutboundSkuPalletLocationsData != null) {
            //Need to group by pallet id
            palletLocationDiv = <div>
                <FormGroup>
                    <Panel bordered header={<h4>Inventory Locations - Pallets</h4>} style={{ marginBottom: 10 }}>
                        <Row>
                            {RTableGeneral(
                                'PalletID', // keyColumn,
                                this.props.InventoryLotOrderOutboundSkuPalletLocationsData, // tableData,
                                InventoryLotOrderOutboundSkuPalletLocationsDataElements, // DataElements,
                                this.props.isInventoryLotOrderOutboundSkuPalletLocationsGetLoading, // loading,

                                this.props.isMobile, // isMobile,
                                this.props.history, // history,

                                null, // sortColumn,
                                null, // sortType,
                                null, // handleSortColumn,

                                null, // checkColEntry,
                                null, // checkedKeys,
                                null, // handleCheckAll,
                                null, // handleCheck,

                                true, // boolSelectButton,
                                this.onClickPickPallet, // onClickSelect,
                                'Pick', // selectButtonText,
                                null, // selectHighlightRowKey,

                                false, // link1bool,
                                null, // link1text,
                                null, // link1onClick,

                                false, // link2bool,
                                null, // link2text,
                                null // link2onClick
                            )}
                        </Row>
                    </Panel>
                </FormGroup>
            </div>
        }
        else {
            palletLocationDiv = <div></div>
        }



        let palletCaseUnitdiv;
        if (this.props.isInventoryLoadPalletCaseUnitLoading) {
            palletCaseUnitdiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.InventoryLoadPalletCaseUnitData[0]) !== 'undefined' && this.props.InventoryLoadPalletCaseUnitData != null) {

            let palletTable;
            let caseTable;
            let unitTable;

            try {
                var palletTableData = this.props.InventoryLoadPalletCaseUnitData.filter(x => x.LoadPalletID != null)
                if (palletTableData.length > 0) {


                    palletTable = <Panel header={'Loaded Pallets'} bordered style={{ marginBottom: 10 }}>
                        <div>

                            {(this.props.isMobile == true) ? <div></div> :
                                <div>
                                    <Row>
                                        <ExcelFile element={<Button >Export Excel</Button>} filename={'Pallet_' + currentDateTime}>
                                            <ExcelSheet data={palletTableData} name="Pallet">

                                                {
                                                    InventoryLoadPalletDataElements.map(
                                                        ({ dBKey, displayName }, idx) => {
                                                            return (
                                                                <ExcelColumn label={displayName} value={dBKey} />
                                                            );
                                                        }
                                                    )
                                                }

                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Row>
                                </div>
                            }

                        </div>

                        {RTableGeneral(
                            'LoadPalletID',     //   keyColumn,
                            palletTableData,     //   tableData,
                            InventoryLoadPalletDataElements,       //   DataElements,
                            this.props.isInventoryLoadPalletCaseUnitLoading,        //   loading,

                            this.props.isMobile,        //   isMobile,
                            this.props.history,     //   history,

                            null,     //   sortColumn,
                            null,       //   sortType,
                            null,      //   handleSortColumn,

                            null, // checkColEntry,
                            null,       //   checkedKeys,
                            null,       //   handleCheckAll,
                            null,       //   handleCheck,

                            null,      //   onClickAddMilestone,
                            null,       //   onClickSelect,
                            null,       //   selectButtonText,
                            null,       //   selectHighlightRowKey,

                            true,       //   link1bool,
                            'Details',     //   link1text,
                            this.onClickPallet,     //   link1onClick,

                            true,       //   link2bool,
                            'Delete',       //   link2text,
                            this.onClickDeletePalletFromLoad  //   link2onClick
                        )
                        }
                    </Panel>


                }
                else {
                    palletTable = <div></div>
                }

            }
            catch (e) {
                palletTable = <div></div>
            }

            try {
                var caseTableData = this.props.InventoryLoadPalletCaseUnitData.filter(x => x.LoadCaseID != null)
                if (caseTableData.length > 0) {
                    caseTable = <Panel header={'Loaded Cases'} bordered style={{ marginBottom: 10 }}>
                        <div>
                            {(this.props.isMobile == true) ? <div></div> :
                                <div>
                                    <Row>
                                        <ExcelFile element={<Button >Export Excel</Button>} filename={'Case_' + currentDateTime}>
                                            <ExcelSheet data={caseTableData} name="Case">
                                                {
                                                    InventoryLoadCaseDataElements.map(
                                                        ({ dBKey, displayName }, idx) => {
                                                            return (
                                                                <ExcelColumn label={displayName} value={dBKey} />
                                                            );
                                                        }
                                                    )
                                                }
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Row>
                                </div>
                            }

                        </div>

                        {RTableGeneral(
                            'LoadCaseID',     //   keyColumn,
                            caseTableData,     //   tableData,
                            InventoryLoadCaseDataElements,       //   DataElements,
                            this.props.isInventoryLoadPalletCaseUnitLoading,        //   loading,

                            this.props.isMobile,        //   isMobile,
                            this.props.history,     //   history,

                            null,     //   sortColumn,
                            null,       //   sortType,
                            null,      //   handleSortColumn,

                            null, // checkColEntry,
                            null,       //   checkedKeys,
                            null,       //   handleCheckAll,
                            null,       //   handleCheck,

                            null,      //   onClickAddMilestone,
                            null,       //   onClickSelect,
                            null,       //   selectButtonText,
                            null,       //   selectHighlightRowKey,

                            true,       //   link1bool,
                            'Details',     //   link1text,
                            this.onClickCase,     //   link1onClick,

                            true,       //   link2bool,
                            'Delete',       //   link2text,
                            this.onClickDeleteCaseFromLoad  //   link2onClick
                        )
                        }
                    </Panel>
                }
                else {
                    caseTable = <div></div>
                }
            }
            catch (e) {
                caseTable = <div></div>
            }

            try {
                var unitTableData = this.props.InventoryLoadPalletCaseUnitData.filter(x => x.LoadSkuID != null)
                if (unitTableData.length > 0) {
                    unitTable = <Panel header={'Loaded Units'} bordered style={{ marginBottom: 10 }}>
                        <div>
                            {(this.props.isMobile == true) ? <div></div> :
                                <div>
                                    <Row>
                                        <ExcelFile element={<Button >Export Excel</Button>} filename={'Unit_' + currentDateTime}>
                                            <ExcelSheet data={caseTableData} name="Unit">
                                                {
                                                    InventoryLoadUnitDataElements.map(
                                                        ({ dBKey, displayName }, idx) => {
                                                            return (
                                                                <ExcelColumn label={displayName} value={dBKey} />
                                                            );
                                                        }
                                                    )
                                                }
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Row>
                                </div>
                            }

                        </div>
                        {
                            RTableGeneral(
                                'LoadSkuID',     //   keyColumn,
                                unitTableData,     //   tableData,
                                InventoryLoadUnitDataElements,       //   DataElements,
                                this.props.isInventoryLoadPalletCaseUnitLoading,        //   loading,

                                this.props.isMobile,        //   isMobile,
                                this.props.history,     //   history,

                                null,     //   sortColumn,
                                null,       //   sortType,
                                null,      //   handleSortColumn,

                                null, // checkColEntry,
                                null,       //   checkedKeys,
                                null,       //   handleCheckAll,
                                null,       //   handleCheck,

                                null,      //   onClickAddMilestone,
                                null,       //   onClickSelect,
                                null,       //   selectButtonText,
                                null,       //   selectHighlightRowKey,

                                true,       //   link1bool,
                                'Details',     //   link1text,
                                this.onClickUnit,     //   link1onClick,

                                true,       //   link2bool,
                                'Delete',       //   link2text,
                                this.onClickDeleteUnitFromLoad  //   link2onClick
                            )
                        }
                    </Panel>
                }
                else {
                    unitTable = <div></div>
                }
            }
            catch (e) {
                unitTable = <div></div>
            }

            palletCaseUnitdiv = <div>
                {palletTable}
                {caseTable}
                {unitTable}
            </div>




        }
        else {
            palletCaseUnitdiv = <duv></duv>
        }




        return (
            <div>
                <h3>
                    Inventory Management - Order Details
                </h3>



                {orderDetailsDiv}

                {orderSkuDiv}


                {
                    orderStatusID == 1 || orderStatusID == 2 ?
                        <div>

                        </div>

                        : <div>
                            {closedLoadDiv}
                        </div>
                }










                {paramLoadID != null && (orderStatusID === 1 || orderStatusID === 2) ?
                    <div>

                        <Divider><h3>Build Load</h3></Divider>

                        <Panel header={<h4>Scan or Enter Barcode</h4>} bordered style={styles}>

                            <InputGroup style={{ marginBottom: '10px' }}>
                                <InputGroup.Addon>Barcode:</InputGroup.Addon>
                                <Input
                                    id="barcodeinput"
                                    // innerRef={this.inputRef}
                                    value={this.state.barcodeInput}
                                    onChange={this.handleInputChange}
                                    // onPressEnter={this.handleInputSubmit(inputDisabled)}
                                    onPressEnter={(e) => { this.handleInputSubmit(e, inputDisabled) }}
                                    // disabled={inputDisabled}
                                    autoFocus
                                />
                                <InputGroup.Button onClick={(e) => { this.handleInputSubmit(e, inputDisabled) }} onPressEnter={(e) => { this.handleInputSubmit(e, inputDisabled) }} disabled={inputDisabled}>
                                    {inputDisabled ? <Loader size="md" /> : <Icon icon="barcode" />}
                                </InputGroup.Button>
                            </InputGroup>


                            {this.state.barcodeScannedList.length > 0 && (
                                <Panel header={<h4>Barcodes Scanned</h4>} bordered style={styles}>


                                    <List>
                                        {
                                            (() => {
                                                let encounteredBarcodes = new Set();
                                                return this.state.barcodeScannedList.map((barcode) => {
                                                    const isBarcodeInContent = this.state.loadedPalletBarcodes.indexOf(barcode) !== -1 || this.state.loadedCaseBarcodes.indexOf(barcode) !== -1; // Supports Internet Explorer
                                                    const isRepeat = encounteredBarcodes.has(barcode);
                                                    encounteredBarcodes.add(barcode);
                                                    return (
                                                        <div key={barcode}>
                                                            {isBarcodeInContent ? (isRepeat ? '⚠️' : '✅') : '❌'} {barcode}
                                                        </div>
                                                    )
                                                })
                                            })()
                                        }
                                    </List>


                                </Panel>
                            )}

                            {this.state.loadedPalletBarcodes.length > 0 && (
                                <Panel header={<h4>Pallets Loaded</h4>} bordered style={styles}>
                                    <List>
                                        {this.state.loadedPalletBarcodes.map((item, index) => (
                                            <List.Item key={index} index={index}>
                                                {item}
                                            </List.Item>
                                        ))}
                                    </List>
                                </Panel>
                            )}

                            {(this.state.loadedCaseBarcodes.length > 0 || this.state.loadedEachCaseSkuList.length > 0) && (
                                <Panel bordered style={styles}>
                                    <Col md={12} sm={24}>
                                        {this.state.loadedCaseBarcodes.length > 0 && (
                                            // <Panel header={<h4>Cases Loaded</h4>} style={styles}>
                                            <div>
                                                <h4>Cases Loaded</h4>
                                                <List>
                                                    {this.state.loadedCaseBarcodes.map((item, index) => (
                                                        <List.Item key={index} index={index}>
                                                            {item}
                                                        </List.Item>
                                                    ))}
                                                </List>
                                            </div>
                                            // </Panel>
                                        )}
                                    </Col>
                                    <Col md={12} sm={24}>
                                        {this.state.loadedEachCaseSkuList.length > 0 && (
                                            // <Panel header={<h4>Case SKUs</h4>} style={styles}>
                                            <div>
                                                <h4>Case SKUs</h4>
                                                <List>
                                                    {this.state.loadedEachCaseSkuList.map((item, index) => (
                                                        <List.Item key={index} index={index}>
                                                            {item.CaseSKU} (Qty: {item.quantity})
                                                        </List.Item>
                                                    ))}
                                                </List>
                                            </div>
                                            // </Panel>
                                        )}
                                    </Col>

                                </Panel>

                            )}

                            {this.state.skuContent.length > 0 && (
                                <Panel header={<h4>Units Loaded</h4>} bordered style={styles}>
                                    <List>
                                        {this.state.skuContent.map((item, index) => (
                                            <List.Item key={index} index={index}>
                                                {item.SKU} (Qty: {item.UnitsInWhse})
                                            </List.Item>
                                        ))}
                                    </List>
                                </Panel>
                            )}

                        </Panel>

                        {palletLocationDiv}

                        {caseLocationDiv}

                        {eachLocationDiv}




                    </div>
                    :

                    <div></div>
                }


                {
                    orderStatusID == 1 || orderStatusID == 2 ?
                        <div>
                            {loadDiv}
                            {editLoadDrawerDiv}
                            {palletCaseUnitdiv}
                        </div>

                        : <div>
                        </div>
                }


                <OrderOutboundHistoryDiv
                    isInventoryLotOrderOutboundHistoryGetLoading={this.props.isInventoryLotOrderOutboundHistoryGetLoading}
                    InventoryLotOrderOutboundHistoryData={this.props.InventoryLotOrderOutboundHistoryData}
                />

                <EditOrderOutboundDrawer {...this.props} state={this.state} onChangeState={this.onChangeState} confirm={this.onClickConfirmAddNewOrderOutbound} close={this.closeEditOrderDrawer} />

                <EditOrderOutboundSkusDrawer {...this.props} state={this.state} onChangeState={this.onChangeState} onClickEditSku={this.onClickEditSku} onCloseEditSku={this.onCloseEditSku} onClickDeleteSku={this.onClickDeleteSku} confirm={this.onClickConfirmOrderSkuOutbound} close={this.closeOrderSkuOutboundDrawer} />



            </div>

        );

    }
}

export default withRouter(OrderDetails);