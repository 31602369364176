import React from 'react';
import { Row, Col, Panel, Button } from 'rsuite'; // Assuming you're using rsuite
import Loader from 'react-loader-spinner'; // Assuming you're using react-loader-spinner




function PalletDetailsDiv({ isInventoryPalletWarehouseLoading, InventoryPalletWarehouseData }) {

    // onPrintBarcode(){

    //     this.setState({ showPrintableBarcode: true }, () => {

    //         var container = document.getElementById("div-svg");
    //         var mySVG = document.getElementById("barcode-canvas");
    //         var width = "100%";
    //         var height = "100%";
    //         var printWindow = window.open('', 'PrintMap',
    //             'width=' + width + ',height=' + height);
    //         printWindow.document.writeln(container.innerHTML);
    //         printWindow.document.close();
    //         printWindow.print();
    //         printWindow.close();


    //         this.setState({ showPrintableBarcode: false });


    //     });

    // }


    // onPrintContent(){
    //     this.setState({ showPrintableContent: true }, () => {
    //         // window.print();
    //         var container = document.getElementById("div-svg");
    //         var mySVG = document.getElementById("barcode-canvas");
    //         var width = "100%";
    //         var height = "100%";
    //         var printWindow = window.open('', 'PrintMap',
    //             'width=' + width + ',height=' + height);
    //         printWindow.document.writeln(container.innerHTML);
    //         printWindow.document.close();
    //         printWindow.print();
    //         printWindow.close();

    //         this.setState({ showPrintableContent: false });


    //     });


    //     // var container = document.getElementById("div-svg");
    //     // var mySVG = document.getElementById("barcode-canvas");
    //     // var width = "100%";
    //     // var height = "100%";
    //     // var printWindow = window.open('', 'PrintMap',
    //     //     'width=' + width + ',height=' + height);
    //     // printWindow.document.writeln(container.innerHTML);
    //     // printWindow.document.close();
    //     // printWindow.print();
    //     // printWindow.close();
    // }


    if (isInventoryPalletWarehouseLoading) {
        return (
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        );
    }

    if (typeof (InventoryPalletWarehouseData[0]) !== 'undefined' && InventoryPalletWarehouseData != null) {
        let pageHeader
        let palletLabel;
        let palletContents;
        try {
            var skuName = InventoryPalletWarehouseData[0].PalletBarcode.toString()
            pageHeader = <div style={{ marginBottom: '15px' }}>  {'Pallet: ' + skuName} </div>
        }
        catch (e) {

        }

        // if (isInventoryPalletContentsLoading) {
        //     palletLabel = <div align="center">
        //         <section className="panel">
        //         </section>
        //         <Loader
        //             type="TailSpin"
        //             color="#3396E5"
        //             height="50"
        //             width="50"
        //         />
        //     </div>
        //     palletContents = <div></div>

        // }
        // else {
        //     palletLabel = <BarcodeCard barcode={thiInventoryPalletWarehouseData[0].PalletBarcode} />
        //     palletContents = <PalletContents barcode={InventoryPalletWarehouseData[0].PalletBarcode} content={InventoryPalletContentsData} />
        // }





        return (

            <div>
                <h3>
                    {pageHeader}
                </h3>
                <Row >
                    <Col md={24} sm={24}>
                        <Panel bordered style={{ marginBottom: 10 }} header={
                            <div style={{ float: 'right' }}>
                                {/* <Button style={{ float: 'right' }} onClick={(e) => { this.movePalletModalShown(e) }} >Move Pallet Location</Button> */}
                                {/* {closePalletButton} */}
                            </div>
                        }>
                            Warehouse Location: <strong>{InventoryPalletWarehouseData[0].WarehouseLocationName}</strong>
                            <br />
                            Warehouse: <strong>{InventoryPalletWarehouseData[0].WarehouseName}</strong>
                            <br />
                            Customer: <strong>{InventoryPalletWarehouseData[0].CustomerName}</strong>
                            <br />
                            Pallet Barcode: <strong>{InventoryPalletWarehouseData[0].PalletBarcode}</strong>
                            <br />
                            Pallet Status: <strong>{InventoryPalletWarehouseData[0].PalletStatusName}</strong>
                            <br />
                        </Panel>
                    </Col>
                </Row>




            </div>
        );
    }

    return <div></div>;
}

export default PalletDetailsDiv;
