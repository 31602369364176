import { connect } from 'react-redux';
import React, { Component } from 'react';
import Orders from './Orders'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    isInventoryPostLoading: store.inventoryReducer.isInventoryPostLoading,

    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,


    isInventoryLotOrderOutboundLoading: store.inventoryReducer.isInventoryLotOrderOutboundLoading,
    InventoryLotOrderOutboundPostSuccessful: store.inventoryReducer.InventoryLotOrderOutboundPostSuccessful,
    NewOutboundOrderId: store.inventoryReducer.NewOutboundOrderId,

    isInventoryLotOrderOutboundSkuLoading: store.inventoryReducer.isInventoryLotOrderOutboundSkuLoading,
    InventoryLotOrderOutboundSkuPostSuccessful: store.inventoryReducer.InventoryLotOrderOutboundSkuPostSuccessful,


    isInventoryLotOrderOutboundGetLoading: store.inventoryReducer.isInventoryLotOrderOutboundGetLoading,
    InventoryLotOrderOutboundData: store.inventoryReducer.InventoryLotOrderOutboundData,

    isInventorySkuRefLoading: store.inventoryReducer.isInventorySkuRefLoading,
    InventorySkuRefData: store.inventoryReducer.InventorySkuRefData,


    isInventoryLotOrderOutboundSkuGetLoading: store.inventoryReducer.isInventoryLotOrderOutboundSkuGetLoading,
    InventoryLotOrderOutboundSkuData: store.inventoryReducer.InventoryLotOrderOutboundSkuData,

    isInventorySkuRefLoading: store.inventoryReducer.isInventorySkuRefLoading,
    InventorySkuRefData: store.inventoryReducer.InventorySkuRefData,
});

const mapDispatchToProps = dispatch =>
({

    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryLotOrderOutboundPost: (_email, _authToken, _OrderOutboundID, _OrderRef, _WarehouseID, _CustomerID, _OrderStatusID, _OrderTypeID, _OrderDate, _ShipByDate, _DeliverByDate, _ShipToName, _ShipToAddressLine1, _ShipToAddressLine2, _ShipToCity, _ShipToState, _ShipToZip, _ShipToCountry, _OrderPriorityID, _Notes, _Delete) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundPost(_email, _authToken, _OrderOutboundID, _OrderRef, _WarehouseID, _CustomerID, _OrderStatusID, _OrderTypeID, _OrderDate, _ShipByDate, _DeliverByDate, _ShipToName, _ShipToAddressLine1, _ShipToAddressLine2, _ShipToCity, _ShipToState, _ShipToZip, _ShipToCountry, _OrderPriorityID, _Notes, _Delete))
    },

    inventoryLotOrderOutboundSkuPost: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderOutboundSkuID, _SkuID, _SKU, _UomID, _Qty, _LotCode, _SerialNumber, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes, _Delete) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundSkuPost(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderOutboundSkuID, _SkuID, _SKU, _UomID, _Qty, _LotCode, _SerialNumber, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes, _Delete))
    },

    inventoryLotOrderOutboundGet: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundGet(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },

    inventoryLotOrderOutboundSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventoryLotOrderOutboundSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _OrderOutboundID, _OrderRef, _OrderStatusID, _SkuID, _SKU, _WarehouseLocationID))
    },

    inventorySkuRefGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber) => {
        dispatch(inventoryActions.inventorySkuRefGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _SkuStatusID, _ProjectID, _SkuLotCode, _SkuSerialNumber))
    },



});

class OrdersContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.inventoryCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.inventoryProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.handleUrlParam();
        // this.props.userWarehousesGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        // if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
        //     this.handleUrlParam();
        // }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }





    }



    handleUrlParam = () => {

        const values = queryString.parse(this.props.location.search)

        try {
            var paramOrderNav = queryStringParse(values.ordernav)
        }
        catch (e) {
            var paramOrderNav = null
        }

        try {
            var paramOrderID = queryStringParse(values.orderid)
        }
        catch (e) {
            var paramOrderID = null
        }


        try {
            var paramOrderRef = queryStringParse(values.orderref)
        }
        catch (e) {
            var paramOrderRef = null
        }


        try {
            var paramStatus = queryStringParse(values.orderstatus)
        }
        catch (e) {
            var paramStatus = null
        }

        try {
            var paramSku = queryStringParse(values.sku)
        }
        catch (e) {
            var paramSku = null
        }

        try {
            var paramSkuID = queryStringParse(values.skuid)
        }
        catch (e) {
            var paramSkuID = null
        }

        if (paramOrderNav == 'inbound') {
            // this.props.inventoryLotOrderOutboundGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramOrderID, paramOrderRef, paramStatus, null, null, null)
        }
        else if (paramOrderNav == 'outbound') {
            this.props.inventoryLotOrderOutboundGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramOrderID, paramOrderRef, paramStatus, paramSkuID, paramSku, null)
        }



    }



    render() {

        return (

            <Orders
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}

                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}


                inventoryLotOrderOutboundPost={this.props.inventoryLotOrderOutboundPost}
                isInventoryLotOrderOutboundLoading={this.props.isInventoryLotOrderOutboundLoading}
                InventoryLotOrderOutboundPostSuccessful={this.props.InventoryLotOrderOutboundPostSuccessful}
                NewOutboundOrderId={this.props.NewOutboundOrderId}

                inventoryLotOrderOutboundGet={this.props.inventoryLotOrderOutboundGet}
                isInventoryLotOrderOutboundGetLoading={this.props.isInventoryLotOrderOutboundGetLoading}
                InventoryLotOrderOutboundData={this.props.InventoryLotOrderOutboundData}


                inventorySkuRefGet={this.props.inventorySkuRefGet}
                isInventorySkuRefLoading={this.props.isInventorySkuRefLoading}
                InventorySkuRefData={this.props.InventorySkuRefData}

                isInventoryPostLoading={this.props.isInventoryPostLoading}


                inventoryLotOrderOutboundSkuPost={this.props.inventoryLotOrderOutboundSkuPost}
                isInventoryLotOrderOutboundSkuLoading={this.props.isInventoryLotOrderOutboundSkuLoading}
                InventoryLotOrderOutboundSkuPostSuccessful={this.props.InventoryLotOrderOutboundSkuPostSuccessful}

                inventoryLotOrderOutboundSkuGet={this.props.inventoryLotOrderOutboundSkuGet}
                isInventoryLotOrderOutboundSkuGetLoading={this.props.isInventoryLotOrderOutboundSkuGetLoading}
                InventoryLotOrderOutboundSkuData={this.props.InventoryLotOrderOutboundSkuData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersContainer));



