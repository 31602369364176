import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import {appSettings} from '../../../app/appSettings'
import MyProfileContainer from '../../../components/componentsUser/MyProfile/MyProfileContainer';

class myProfileView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

          };
      }

    render() {

        return(
            <AnimatedView>

                <MyProfileContainer 
                isMobile={this.props.isMobile} 
                />

            </AnimatedView>

        );
    }
}

export default myProfileView;
