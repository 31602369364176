import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { lettersNumbersDashesSpacesInputMask, lettersNumbersDashesInputMask, lettersNumbersDashesSpacesCommasInputMask, textStringInputMask, limitCharacterLength, textNumbersSymbolsInputmask, limitUpperCaseLengthMask, zipCodeInputMask } from '../../../resources/logic/inputMasks';
import { Row, Col, Panel, PanelGroup, Button, Timeline, ButtonToolbar, FormGroup, InputGroup, InputPicker, Input, Whisper, DatePicker, Tooltip, Divider, Form, Checkbox } from 'rsuite';
import { formatNumberForAPI, formatStringForAPI, formatDateForUrlSearch, formatDateTimeForUrlSearch, findLowestMissingNumber, formatTrueFalseToBit, formatTextNumbersSymbolsForAPI, formatTimeForUrlSearch, formatAllTextNumbersSymbolsForAPI } from '../../../resources/logic/functions';
import queryString from 'query-string';
import ReactExport from "react-data-export";
// import ShipmentManagementAddNewLegModal from '../ShipmentManagement/ShipmentManagementAddNewLegModal';
// import ShipmentManagementMultiModal from '../ShipmentManagement/ShipmentManagementMultiModal';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import VisibilityPackageUpsEventsDataElements from '../../dataComponentsVisibility/VisibilityPackageUpsEventsDataElements';
import * as moment from 'moment'
// import MilestoneEventModal from '../MilestoneEventEntry/MilestoneEventModal';
// import PackageDetailsEditDrawer from './PackageDetailsEditDrawer';
import RUploader from '../../componentsGeneral/Rsuite/Uploader/RUploader';
import IconButton from '../../common/IconButton/IconButton';
import { appAPI } from '../../../app/appConfig';

import { dbValues } from '../../../app/appConfig';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "PackageUpsID"




class PackageDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

      //Warehouse & Customer Arrays
      warehouseArray: {},
      customerArray: {},



    };


    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);


  }


  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection

  }


  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }

    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }

  }

  componentDidUpdate(prevProps, prevState) {

  }

  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }




  render() {
    const { sortColumn, sortType } = this.state;

    let pageHeader;
    let detailsDiv;
    if (this.props.isVisibilityPackageLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.VisibilityPackageData[0]) !== 'undefined' && this.props.VisibilityPackageData != null) {



      pageHeader = <div style={{ float: 'left' }}>  {'Visibility - Package: ' + this.props.VisibilityPackageData[0].TrackingNumber} </div>



      detailsDiv =
        <div>
          <Row >
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}

              >
                Tracking Number: <strong>{this.props.VisibilityPackageData[0].TrackingNumber}</strong>
                <br />
                Order No: <strong>{this.props.VisibilityPackageData[0].OrderNo}</strong>
                <br />
                MC: <strong>{this.props.VisibilityPackageData[0].MC}</strong>
                <br />
                Parcel Weight: <strong>{this.props.VisibilityPackageData[0].ParcelWeight}</strong>
                <br />
              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}
              >
                Destination Airport: <strong>{this.props.VisibilityPackageData[0].DestinationAirport}</strong>
                <br />
                State: <strong>{this.props.VisibilityPackageData[0].R_State}</strong>
                <br />
                Postal Code: <strong>{this.props.VisibilityPackageData[0].R_PostCode}</strong>
                <br />
                Country: <strong>{this.props.VisibilityPackageData[0].GrossWeight} {this.props.VisibilityPackageData[0].R_CountryCode}</strong>
                <br />
              </Panel>
            </Col>
          </Row>
        </div>
    }
    else {
      detailsDiv = <div></div>;

    }









    let lateShipmentDiv;
    let shipSubscriptionDiv;


    let AddressUpdateDiv;
    let TransitExceptionDiv;
    let DeliveryExceptionDiv;
    let DeliveredDiv;

    let alertDiv;

    let downloadMilestoneDiv;
    let milestoneTable;
    if (this.props.isVisibilityPackageUpsEventsLoading) {

      downloadMilestoneDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.VisibilityPackageUpsEventsData) !== 'undefined' && this.props.VisibilityPackageUpsEventsData != null) {
      

      var testAddressUpdate = []
      testAddressUpdate = this.props.VisibilityPackageUpsEventsData.filter(x => x.AddressUpdate === true)
      if( testAddressUpdate.length > 0){
        AddressUpdateDiv = <IconButton
          iconLabel={'Address Update'}
          icon={<i className="fa fa-address-card" />}
          iconColor={'yellow'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }
      else {
        AddressUpdateDiv = <IconButton
          iconLabel={'Address Update'}
          icon={<i className="fa fa-address-card" />}
          iconColor={'white'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }



      var testTransitException = []
      testTransitException = this.props.VisibilityPackageUpsEventsData.filter(x => x.TransitException === true)
      if( testTransitException.length > 0){
        TransitExceptionDiv = <IconButton
          iconLabel={'Transit Exception'}
          icon={<i className="fa fa-exclamation" />}
          iconColor={'red'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }
      else {
        TransitExceptionDiv = <IconButton
          iconLabel={'Transit Exception'}
          icon={<i className="fa fa-exclamation" />}
          iconColor={'white'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }

      var testDeliveryException = []
      testDeliveryException = this.props.VisibilityPackageUpsEventsData.filter(x => x.DeliveryException === true)
      if( testDeliveryException.length > 0){
        DeliveryExceptionDiv = <IconButton
          iconLabel={'Delivery Exception'}
          icon={<i className="fa fa-exclamation-triangle" />}
          iconColor={'red'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }
      else {
        DeliveryExceptionDiv = <IconButton
          iconLabel={'Delivery Exception'}
          icon={<i className="fa fa-exclamation-triangle" />}
          iconColor={'white'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }

      var testDelivered = []
      testDelivered = this.props.VisibilityPackageUpsEventsData.filter(x => x.Delivered === true)
      if( testDelivered.length > 0){
        DeliveredDiv = <IconButton
          iconLabel={'Delivered'}
          icon={<i className="fa fa-check-square" />}
          iconColor={'green'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }
      else {
        DeliveredDiv = <IconButton
          iconLabel={'Delivered'}
          icon={<i className="fa fa-check-square" />}
          iconColor={'white'}
          allowClick={false}

          isMobile={this.props.isMobile}
        />
      }


      var milestoneTableComponent = RTableGeneral(
        'PackageUpsID',     //   keyColumn,
        this.props.VisibilityPackageUpsEventsData,     //   tableData,
        VisibilityPackageUpsEventsDataElements,       //   DataElements,
        this.props.isVisibilityPackageUpsEventsLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        false,      //   onClickEditMilestone,
        null,       //   onClickSelect,
        null,       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        false,       //   link1bool,
        null,     //   link1text,
        null,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      downloadMilestoneDiv = <div>
        {(this.props.isMobile == true) ? <div></div> :
          <div>
            <Row>
              <ExcelFile element={<Button style={{ marginTop: '10px', float: 'right' }}>Export Excel</Button>} filename={'UpsEvents_' + currentDateTime}>
                <ExcelSheet data={this.props.VisibilityPackageUpsEventsData} name="UpsEvents">
                  {
                    VisibilityPackageUpsEventsDataElements.map(
                      ({ dBKey, displayName }, idx) => {
                        return (
                          <ExcelColumn label={displayName} value={dBKey} />
                        );
                      }
                    )
                  }
                </ExcelSheet>
              </ExcelFile>
            </Row>
          </div>
        }
      </div>


      milestoneTable = <Panel
        bordered style={{ marginBottom: 10 }}
        header={
          <Row>
            <Col md={16} sm={24}>
              <span>UPS Events</span>
            </Col>
          </Row>
        }
      >
        {milestoneTableComponent}
        {downloadMilestoneDiv}
      </Panel>


    }
    else {
      milestoneTable = <div></div>
      downloadMilestoneDiv = <div></div>

      // hotShipmentDiv = <div></div>;
      lateShipmentDiv = <div></div>
      alertDiv = <div></div>
      shipSubscriptionDiv = <div></div>
    }

    let iconsDiv = <div>

      <Row>
        <Col md={6} sm={24}>
          {AddressUpdateDiv}
        </Col>

        <Col md={6} sm={24}>
          {TransitExceptionDiv}
        </Col>

        <Col md={6} sm={24}>
          {DeliveryExceptionDiv}
        </Col>

        <Col md={6} sm={24}>
          {DeliveredDiv}
        </Col>

      </Row>
    </div>








    return (
      <div>
        <h3>
          <Row>{pageHeader} </Row>
        </h3>

        {iconsDiv}

        <PanelGroup>
          {detailsDiv}
        </PanelGroup>

        {milestoneTable}

      </div>
    );

  }
}

export default withRouter(PackageDetails);



