import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import {
    Form,
    Col,
    Loader,
    Row,
    Button,
    Panel
} from 'rsuite';
import { formatNumberForAPI, formatStringForAPI, findLowestMissingNumber, formatDateForUrlSearch } from '../../../resources/logic/functions';
import StatsCard from '../../common/StatsCard/StatsCard';
import { ResponsivePie } from '@nivo/pie' //npm i @nivo/pie
import { appSettings } from '../../../app/appSettings';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import CtrlTwrShipmentAlertsDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentAlertsDataElements';
import DashboardShipListDrawer from './DashboardShipListDrawer';
import CtrlTwrShipmentDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentDataElements';
import CtrlTwrShipmentDeliveryDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentDeliveryDataElements';
import ShipmentDeliveryDateChartContainer from '../Charts/ShipmentDeliveryDateChart/ShipmentDeliveryDateChartContainer';
import ShipmentDeliveryHistoryChartContainer from '../Charts/ShipmentDeliveryHistoryChart/ShipmentDeliveryHistoryChartContainer';
import GoogleMapTrackerContainer from '../../common/GoogleMap/GoogleMapTrackerContainer';
import CtrlTwrShipmentMilestoneAlertDataElements from '../../dataComponentsControlTower/CtrlTwrShipmentMilestoneAlertDataElements';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "LegID"




class Dashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Shipment List Drawer
            ShipmentListDrawerData: {},
            shipListDrawerDataElements: null,
            showShipListDrawer: false,
            ShipListDrawerType: '',



        };

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);


        this.onClickShipDeliveryChart = this.onClickShipDeliveryChart.bind(this);
        this.onClickShipDeliveryHistoryChart = this.onClickShipDeliveryHistoryChart.bind(this);

        //Shipment List Drawer
        this.onClickShipDetails = this.onClickShipDetails.bind(this);
        this.setShipListDrawerToClose = this.setShipListDrawerToClose.bind(this);
        this.closeShipListDrawer = this.closeShipListDrawer.bind(this);

    }



    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection
        this.loadSortFromStorage()

        this.setState({
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: null
        });
    }

    getsessionStorageWarehouse() {
        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                // checkedKeys: []
            });

            this.setState({
                ShipmentListDrawerData: {},
                ShipListDrawerType: '',
                shipListDrawerDataElements: null
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                // checkedKeys: []
            });

            this.setState({
                ShipmentListDrawerData: {},
                ShipListDrawerType: '',
                shipListDrawerDataElements: null
            });
        }

        // isCtrlTwrShipmentLoading
        if (prevProps.CtrlTwrShipmentData !== this.props.CtrlTwrShipmentData) {
            var newdata = this.props.CtrlTwrShipmentData
            this.setState({
                ShipmentListDrawerData: newdata
            });
            this.forceUpdate()

        }

        if (prevProps.CtrlTwrChartShipmentDeliveryDetailsData !== this.props.CtrlTwrChartShipmentDeliveryDetailsData) {
            var newdata = this.props.CtrlTwrChartShipmentDeliveryDetailsData
            this.setState({
                ShipmentListDrawerData: newdata
            });
            this.forceUpdate()
        }

        if (prevProps.CtrlTwrChartShipmentDeliveryHistoryDetailsData !== this.props.CtrlTwrChartShipmentDeliveryHistoryDetailsData) {
            var newdata = this.props.CtrlTwrChartShipmentDeliveryHistoryDetailsData
            this.setState({
                ShipmentListDrawerData: newdata
            });
            this.forceUpdate()
        }



    }


    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }



    onClickStatCard(e, statCard) {

        this.setState({
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: CtrlTwrShipmentMilestoneAlertDataElements
        });

        if (statCard === 'InTransit' || statCard === 'NetworkHealth') {
            this.setState({
                ShipListDrawerType: 'In Transit'
            });
            this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, null, 0, null, null, null, null, null, null)
        }
        else if (statCard === 'LateShipments') {
            this.setState({
                ShipListDrawerType: 'Late Shipments'
            });
            this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, null, 0, null, null, null, null, 1, null)
        }
        else if (statCard === 'ShipmentWithAlerts') {
            this.setState({
                ShipListDrawerType: 'Shipments With Alerts'
            });
            this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, null, 0, null, null, null, null, null, 1)

        }
        // else if (statCard === 'HotShipments') {
        //     this.setState({
        //         ShipListDrawerType: 'Hot Shipments'
        //     });
        //     this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, null, 0, null, null, 1, null, null, null)
        // }
        else if (statCard === 'ShipmentSubscription') {
            this.setState({
                ShipListDrawerType: 'Shipment Subscriptions'
            });
            this.props.ctrlTwrShipmentGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.customerSelection, null, null, null, 0, null, null, null, 1, null, null)
        }


        this.setState({
            showShipListDrawer: true,
        });

    }



    onClickAlerts = (data, event) => {

        this.setState({
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: CtrlTwrShipmentAlertsDataElements
        });

        var alertType = data.id + 'Hours'

        var newArrayTableData = []

        //this filters customers to be shown according to the app settings customer/product matrix
        for (let key in this.props.CtrlTwrAlertsShipmentDepartureData) {
            if (this.props.CtrlTwrAlertsShipmentDepartureData[key][alertType] > 0 && this.props.CtrlTwrAlertsShipmentDepartureData[key]['ActiveAlert'] === 1  ) {
                newArrayTableData.push({ Alert: "Departure", ProRef: this.props.CtrlTwrAlertsShipmentDepartureData[key]["ProRef"], Leg: this.props.CtrlTwrAlertsShipmentDepartureData[key]["LegNumber"], Mode: this.props.CtrlTwrAlertsShipmentDepartureData[key]["ModeName"], LegID: this.props.CtrlTwrAlertsShipmentDepartureData[key]["LegID"] })
            }
        }

        for (let key in this.props.CtrlTwrAlertsShipmentDeliveryData) {
            if (this.props.CtrlTwrAlertsShipmentDeliveryData[key][alertType] > 0  && this.props.CtrlTwrAlertsShipmentDeliveryData[key]['ActiveAlert'] === 1) {
                newArrayTableData.push({ Alert: "Delivery", ProRef: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["ProRef"], Leg: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["LegNumber"], Mode: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["ModeName"], LegID: this.props.CtrlTwrAlertsShipmentDeliveryData[key]["LegID"] })
            }
        }


        this.setState({
            ShipListDrawerType: data.label,
            ShipmentListDrawerData: newArrayTableData
        });

        this.setState({
            showShipListDrawer: true,
        });

        this.forceUpdate()
    }




    onClickShipDeliveryChart = () => {

        this.setState({
            ShipListDrawerType: 'Shipment Schedule',
            shipListDrawerDataElements: CtrlTwrShipmentDeliveryDataElements
        });

        this.setState({
            showShipListDrawer: true,
        });

    }



    onClickShipDeliveryHistoryChart = () => {

        this.setState({
            ShipListDrawerType: 'Shipment History',
            shipListDrawerDataElements: CtrlTwrShipmentDeliveryDataElements
        });

        this.setState({
            showShipListDrawer: true,
        });

    }

    //Shipment List Drawer


    closeShipListDrawer() {
        this.setShipListDrawerToClose()
    }

    setShipListDrawerToClose() {
        this.setState({
            showShipListDrawer: false,
            ShipListDrawerType: '',
            ShipmentListDrawerData: {},
            ShipListDrawerType: '',
            shipListDrawerDataElements: null
        });
    }




    //Table on click details
    onClickShipDetails(e, rowData, history) {
        if (this.props.userSignInData.Admin) {
            try {
                var paramID = rowData['ProRef']
                history.push({
                    pathname: 'shipmentdetailsmanagement',
                    search: 'pro=\'' + paramID + '\''
                });
            }
            catch (e) {
            }
        }
        else {
            try {
                var paramID = rowData['ProRef']
                history.push({
                    pathname: 'shipmentdetails',
                    search: 'pro=\'' + paramID + '\''
                });
            }
            catch (e) {
            }
        }
    }





    render() {
        const { sortColumn, sortType } = this.state;


        let statCardsDiv;
        if (this.props.isCtrlTwrAlertsStatsLoading) {
            statCardsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.CtrlTwrAlertsStatsData[0]) !== 'undefined' && this.props.CtrlTwrAlertsStatsData != null) {

            try {
                var InTransit = String(this.props.CtrlTwrAlertsStatsData[0].InTransit);
            }
            catch (e) {
                var InTransit = 0
            }

            try {
                var ShipmentWithAlerts = String(this.props.CtrlTwrAlertsStatsData[0].ShipmentWithAlerts);
            }
            catch (e) {
                var ShipmentWithAlerts = 0
            }

            try {
                var LateShipments = String(this.props.CtrlTwrAlertsStatsData[0].LateShipments);
            }
            catch (e) {
                var LateShipments = 0
            }

            // try {
            //     var HotShipment = String(this.props.CtrlTwrAlertsStatsData[0].HotShipment);
            // }
            // catch (e) {
            //     var HotShipment = 0
            // }

            try {
                var ShipmentSubscription = String(this.props.CtrlTwrAlertsStatsData[0].ShipmentSubscription);
            }
            catch (e) {
                var ShipmentSubscription = 0
            }



            try {
                var NetworkHealth = String(this.props.CtrlTwrAlertsStatsData[0].NetworkHealth);
            }
            catch (e) {
                var NetworkHealth = 0
            }

            statCardsDiv = <Form fluid>
                <Row>
                    {/* <Col md={6} sm={24}>
                        <StatsCard
                            statValue={NetworkHealth + '%'}
                            statLabel={'Network Health'}
                            icon={<i className="fa fa-thermometer-full" />}
                            iconColor={'yellow'}
                            allowClick={false}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'NetworkHealth') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col> */}

                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={InTransit}
                            statLabel={'In Transit'}
                            icon={<i className="fa fa-truck" />}
                            iconColor={'green'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'InTransit') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>


                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={LateShipments}
                            statLabel={'Late Shipments'}
                            icon={<i className="fa fa-hourglass" />}
                            iconColor={'red'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'LateShipments') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>
                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={ShipmentWithAlerts}
                            statLabel={'Milestone Alert Shipments'}
                            icon={<i className="fa fa-bell" />}
                            iconColor={'yellow'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'ShipmentWithAlerts') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>

                    <Col md={6} sm={24}>
                        <StatsCard
                            statValue={ShipmentSubscription}
                            statLabel={'Subscriptions'}
                            icon={<i className="fa fa-envelope-open" />}
                            iconColor={'green'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'ShipmentSubscription') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col>


                </Row>

                <Row>

                    {/* <Col md={12} sm={24}>
                        <StatsCard
                            statValue={HotShipment}
                            statLabel={'Hot Shipments'}
                            icon={<i className="fa fa-exclamation-triangle" />}
                            iconColor={'red'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'HotShipments') }}
                            isMobile={this.props.isMobile}
                        />
                    </Col> */}




                    {/* <Col md={6} sm={24}>
                        <StatsCard
                            statValue={0}
                            statLabel={'Carrier Actions'}
                            icon={<i className="fa fa-flag" />}
                            iconColor={'yellow'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CarrierActions')}}
                            isMobile={this.props.isMobile}
                        />
                    </Col> */}
                    {/* <Col md={6} sm={24}>
                        <StatsCard
                            statValue={1}
                            statLabel={'Customer Actions'}
                            icon={<i className="fa fa-flag" />}
                            iconColor={'blue'}
                            allowClick={true}
                            onClickStatCard={(e) => { this.onClickStatCard(e, 'CustomerActions')}}
                            isMobile={this.props.isMobile}
                        />
                    </Col> */}
                </Row>
            </Form>


        }





        let alertPie;
        if (this.props.isCtrlTwrAlertsShipmentSummaryLoading) {
            alertPie =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (this.props.CtrlTwrAlertsShipmentSummaryData.reduce((a, v) => a = a + v.value, 0) > 0) {
            alertPie =
                <Panel bordered>
                    <section className="panel">
                        <header className="panel-heading">
                            {'Milestone Alerts Status'}
                        </header>
                        <div style={{ height: 400, background: "#FFF", cursor: "pointer" }}
                        >
                            <ResponsivePie
                                data={this.props.CtrlTwrAlertsShipmentSummaryData}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={{ scheme: 'nivo' }}
                                borderWidth={0}
                                // borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                borderColor="black"
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor="#333333"
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                // radialLabelsLinkColor={{ from: 'color' }}
                                radialLabelsLinkColor="black"
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#333333"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                onClick={(data, event) => this.onClickAlerts(data, event)}
                                defs={[
                                    {
                                        id: 'carrierred',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#f43e1f',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    },
                                    {
                                        id: 'carrieryellow',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#f1e15b',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    },
                                    {
                                        id: 'customerred',
                                        type: 'patternLines',
                                        background: '#f43e1f',
                                        color: '#f43e1f',
                                        // rotation: -45,
                                        // lineWidth: 30,
                                        // spacing: 1
                                    },
                                    {
                                        id: 'customeryellow',
                                        type: 'patternLines',
                                        background: '#f1e15b',
                                        color: '#f1e15b',
                                        // rotation: -45,
                                        // lineWidth: 30,
                                        // spacing: 1
                                    },
                                    {
                                        id: 'green',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#37ba30',
                                        rotation: -45,
                                        lineWidth: 30,
                                        spacing: 1
                                    },
                                    {
                                        id: 'redstripe',
                                        type: 'patternLines',
                                        background: '#f4a91f',
                                        color: '#f43e1f',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    },
                                    {
                                        id: 'yellowstripe',
                                        type: 'patternLines',
                                        background: '#fbc5bb',
                                        color: '#f1e15b',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    },

                                ]}
                                fill={[
                                    // {
                                    //     match: {
                                    //         id: 'CarrierRed'
                                    //     },
                                    //     id: 'carrierred',
                                    //     // label: 'test'
                                    // },
                                    // {
                                    //     match: {
                                    //         id: 'CarrierYellow'
                                    //     },
                                    //     id: 'carrieryellow'
                                    // },
                                    {
                                        match: {
                                            id: 'CustomerRed'
                                        },
                                        id: 'customerred'
                                    },
                                    {
                                        match: {
                                            id: 'CustomerYellow'
                                        },
                                        id: 'customeryellow'
                                    }
                                ]}
                                legends={[
                                    {
                                        anchor: 'bottom',
                                        direction: 'row',
                                        translateY: 56,
                                        itemWidth: 100,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />


                        </div>
                    </section>
                </Panel>
        }
        else {
            alertPie =
                <Panel bordered>
                    <div align="center">
                        <section className="panel">
                            <header className="panel-heading">
                                {'Milestone Alerts Status'}
                            </header>

                            <div className="panel-body table-responsive"
                                style={{
                                    backgroundColor: '#FFF'
                                    // , height: '400px' 
                                }}>


                                <i className="fa fa-check-square-o" style={{ color: `${appSettings.colorPalatte.CaribbeanGreen}` }} title="No milestone alerts"> &nbsp;</i>
                                No Milestone Alerts


                            </div>
                        </section>
                    </div>
                </Panel>
        }


        let ShipListDrawer;
        if (typeof (this.state.ShipmentListDrawerData.length) === 'undefined' || this.state.ShipmentListDrawerData.length < 1) {
            ShipListDrawer =
                <div align="center"></div>
        }
        else if (typeof (this.state.ShipmentListDrawerData) !== 'undefined' && this.state.ShipmentListDrawerData != null) {
            ShipListDrawer = <DashboardShipListDrawer {...this.props} state={this.state} shipListDrawerDataElements={this.state.shipListDrawerDataElements} onChangeMilestoneEventEntry={this.onChangeMilestoneEventEntry} onChangeWarehouse={this.onChangeWarehouse} onChangeCustomer={this.onChangeCustomer} onChangePO={this.onChangePO} onChangeOriginCountry={this.onChangeOriginCountry} onChangeETD={this.onChangeETD} onClickShipDetails={this.onClickShipDetails} close={this.closeShipListDrawer} />
        }
        else {
            ShipListDrawer = <div></div>
        }

        let ShipDeliveryChart = <ShipmentDeliveryDateChartContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection} onClickShipDeliveryChart={this.onClickShipDeliveryChart} />

        let ShipDeliveryHistoryChart = <ShipmentDeliveryHistoryChartContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection} onClickShipDeliveryHistoryChart={this.onClickShipDeliveryHistoryChart} />




        return (
            <div>
                <h3>
                    Control Tower - Dashboard
                </h3>

                <br />
                <br />

                {statCardsDiv}

                <Form fluid>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={24} sm={24}>
                            {alertPie}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12} sm={24}>
                            {ShipDeliveryChart}
                        </Col>
                        <Col md={12} sm={24}>
                            {ShipDeliveryHistoryChart}
                        </Col>
                    </Row>
                </Form>





                {/* <Panel bordered>
                    <div style={{ height: '600px', width: '100%', position: 'relative', marginRight: '30px', marginBottom: '40px' }} >
                        <GoogleMapTrackerContainer />
                    </div>
                </Panel> */}

                {ShipListDrawer}
            </div >
        );

    }
}

export default withRouter(Dashboard);


