export const CtrlTwrQuoteReportDataElements = [
    {
        dBKey: 'ProRef',
        displayName: 'Quote ID',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    
    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },    




    {
        dBKey: 'ModeName',
        displayName: 'Mode',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Carrier',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'QuoteLineItem',
        displayName: 'Line Item',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 280
    },

    {
        dBKey: 'BuyRate',
        displayName: 'Buy Rate',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },


    {
        dBKey: 'SellRate',
        displayName: 'Sell Rate',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },

    {
        dBKey: 'QuoteLineItemUom',
        displayName: 'Unit of Measure',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'QuoteLineItemRateType',
        displayName: 'Rate Type',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'LineItemNotes',
        displayName: 'Notes',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },

    // {
    //     dBKey: 'QuoteLineItemRateType',
    //     displayName: ' Line Item Rate Type',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 150
    // },







    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'Alpha2Code',
        displayName: 'Origin Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },
    {
        dBKey: 'DestAlpha2Code',
        displayName: 'Destination Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ETD',
        displayName: 'Planned Pickup',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
 

    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Cases',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'UnitCount',
        displayName: 'Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'GrossWeight',
        displayName: 'Gross Wt',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'ChargeableWeight',
        displayName: 'Chargeable Wt',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'WeightUnits',
        displayName: 'Weight Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'QuotingStatusName',
        displayName: 'Quote Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'QuotingStatusDT',
        displayName: 'Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    
    {
        dBKey: 'ShipmentClosed',
        displayName: 'Shipment Closed',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },




    


]


export default CtrlTwrQuoteReportDataElements

