import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    InputNumber,
    InputGroup,
    Input,
    Divider,
    Row,
    Col,
    Checkbox
} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../resources/logic/inputMasks';
import { replaceNegativeWithZero } from '../../../resources/logic/functions';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};



// if (currentBuildLoadDict['Cases'] < 0) {
//     window.alert('May not move more cases than existing at location.')
// }
// else if (currentBuildLoadDict['Pallets'] < 0) {
//     window.alert('May not move more pallets than existing at location.')
// }
// else {
//     this.setState({
//         LocationToEditDict: currentBuildLoadDict
//     });
// }


function limitCaseNumberToExistingValue(e, locationitem, LocationToEditDict, LocationDict) {
    var nonNegativeValue
    nonNegativeValue = numbersInputMask(e)
    return nonNegativeValue

    // var limitValue = LocationToEditDict.OriginalCases

    // // try {
    // //     var limitValue = LocationToEditDict.OriginalCases
    // // }
    // // catch (e) {
    // //     var limitValue = 0
    // // }

    // var sumOfMovedCases = replaceNegativeWithZero((LocationDict[LocationToEditDict.PoID].reduce((a, v) => a = a + v.Cases, 0)))

    // if (sumOfMovedCases + nonNegativeValue >= limitValue) {
    //     window.alert('May not move more than remaining: ' + limitValue.toString())
    //     return limitValue - sumOfMovedCases
    // }
    // else{
    //     return nonNegativeValue
    // }



    // if (LocationToEditDict.Cases < 0 ){
    //     return null
    // }
    // else{
    //     return nonNegativeValue
    // }


    // var itemIndex = originalProps[item.PoID].findIndex(({ WarehouseLocationID }) => WarehouseLocationID === locationitem['WarehouseLocationID']);

    // try {
    //     var limitValue = originalProps[item.PoID][itemIndex]['Cases']
    // }
    // catch (e) {
    //     var limitValue = 0
    // }

    // if (nonNegativeValue <= limitValue) {
    //     return nonNegativeValue
    // }
    // else {
    //     window.alert('Must not be greater than ' + limitValue.toString())
    //     return limitValue
    // }
}

const mountRef = React.createRef();

const EditWarehouseLocationDrawer = ({ onChangeExistingOversize, onChangeLocation, onClickAddLocation, placement, confirm, close, unsavedChanges, state, ...props }) => (

    <Drawer show={state.showEditWarehouseLocationDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '700px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit Warehouse Location</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                <Row>
                    <Col md={24} sm={24}>
                        <FormGroup >

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Existing Location:</InputGroup.Addon>
                                <Input value={state.LocationToEditDict['WarehouseLocationName']} readOnly />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Remaining Cases:</InputGroup.Addon>
                                <InputNumber readOnly value={state.LocationToEditDict['Cases']} className={'custom-input-number'} scrollable={false} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Remaining Pallets:</InputGroup.Addon>
                                <InputNumber readOnly value={state.LocationToEditDict['Pallets']} className={'custom-input-number'} scrollable={false} />
                            </InputGroup>


                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <Checkbox checked={state.LocationToEditDict['Oversize']} onChange={(e, checked) => onChangeExistingOversize(checked, state.LocationToEditDict)} >Oversize</Checkbox>
                            </InputGroup>


                        </FormGroup>
                    </Col>
                </Row>

                <Divider>Move To New Locations:</Divider>


                {state.LocationToEditDict.PoID in state.LocationDict ?
                    state.LocationDict[state.LocationToEditDict.PoID].map(locationitem => (
                        <div>

                            <Row>
                                <Col md={7} sm={7}>


                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                        <InputPicker preventOverflow
                                            container={() => mountRef.current}
                                            // placeholder={label}
                                            placeholder={'Location...'}
                                            value={locationitem['WarehouseLocationID']}
                                            onChange={(e) => { onChangeLocation(e, locationitem, 'WarehouseLocationID', locationitem['WarehouseLocationID']) }}
                                            data={props.WarehouseLocationsData}
                                            block
                                            cleanable={true}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={6} sm={6}>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Cases:</InputGroup.Addon>
                                        <InputNumber value={locationitem['Cases']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, locationitem, 'Cases', locationitem['WarehouseLocationID'])} scrollable={false} onInput={(e) => e.target.value = limitCaseNumberToExistingValue(e, locationitem, state.LocationToEditDict, state.LocationDict)} />
                                    </InputGroup>
                                </Col>
                                <Col md={6} sm={6}>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                        <InputNumber value={locationitem['Pallets']} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, locationitem, 'Pallets', locationitem['WarehouseLocationID'])} scrollable={false} />
                                    </InputGroup>
                                </Col>

                                <Col md={5} sm={5}>
                                    <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                        <Checkbox checked={locationitem['Oversize']} onChange={(e, checked) => onChangeLocation(checked, locationitem, 'Oversize', locationitem['WarehouseLocationID'])} >Oversize</Checkbox>
                                    </InputGroup>
                                </Col>

                            </Row>
                        </div>
                    ))
                    :
                    <div></div>
                }



                <Row>
                    <Col md={8} sm={8}>
                        <Button style={{ marginBottom: '15px' }} onClick={(e) => onClickAddLocation(e)} appearance="default">+  Add Location</Button>
                    </Col>
                </Row>

                <Divider />

                <FormGroup>
                    <ButtonToolbar>
                        {unsavedChanges ? <Button onClick={confirm} appearance="primary">Save</Button> : <div></div>}
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />
        </Drawer.Footer>

    </Drawer>

);



EditWarehouseLocationDrawer.defaultProps = {
    show: false
};

export default EditWarehouseLocationDrawer;


