
export const UserAccountsDataElements = [
    {
        dBKey: 'EmailAddr',
        displayName: 'Email Addr',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'FirstName',
        displayName: 'First Name',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'LastName',
        displayName: 'Last Name',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Admin',
        displayName: 'Admin',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    // {
    //     dBKey: 'RoleName',
    //     displayName: 'User Permissions',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 200
    // },

    
]


export default UserAccountsDataElements

