export const InventoryLoadSkuDetailsDataElements = [
    {
        dBKey: 'LoadStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },


    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 250
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'Cases',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'Units',
        displayName: 'Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },





    {
        dBKey: 'Oversize',
        displayName: 'Oversize',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },

    {
        dBKey: 'Trailer',
        displayName: 'Trailer',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Carrier',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'BOL',
        displayName: 'BOL',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Seal',
        displayName: 'Seal',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },

    {
        dBKey: 'PickupDate',
        displayName: 'Pickup Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
        {
        dBKey: 'DeliveryDate',
        displayName: 'Delivery Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'Destination',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },






]


export default InventoryLoadSkuDetailsDataElements

