import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import LoadTenderContainer from '../../../components/componentsControlTower/LoadTender/LoadTenderContainer';

class ctrlTwrLoadTenderView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

    }

    render() {

        return (
            <AnimatedView>
                <LoadTenderContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default ctrlTwrLoadTenderView;
