import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline, ButtonToolbar, FormGroup, InputGroup, InputPicker, Input, Whisper, DatePicker, Tooltip, Divider } from 'rsuite';
import { formatNumberForAPI, formatStringForAPI, formatDateForUrlSearch, formatDateTimeForUrlSearch, findLowestMissingNumber } from '../../../resources/logic/functions';
import MinusIcon from '@rsuite/icons/Minus';
import queryString from 'query-string';
import ReactExport from "react-data-export";
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import IconButton from '../../common/IconButton/IconButton';
import * as moment from 'moment'
import LoadTenderResponseModal from '../LoadTender/LoadTenderResponseModal';
import Edi204L5DataElements from '../../dataComponentsControlTower/Edi204L5DataElements';
import Edi204OIDDataElements from '../../dataComponentsControlTower/Edi204OIDDataElements';
import { appAPI } from '../../../app/appConfig';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "HdrId"



const styles = {
  marginTop: '5px',
  width: '100%'
};

const inputStyle = {
  width: '100%'
};


const mountRef = React.createRef();



class LoadTender extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {

      //Warehouse & Customer Arrays
      warehouseArray: {},
      customerArray: {},


      //Load Tender Modal
      loadTenderResponseModalShown: false,
      loadTenderModalAcceptDecline: null,
      loadTenderID: null,
      loadTenderNumber: null,
      loadTenderHdrId204: null,
      loadTenderN902Pro: null,

    };

    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);

    //Action Buttons
    this.onClickQueueStatusResponse = this.onClickQueueStatusResponse.bind(this);


    //Load Tender Modal
    this.handleSaveLoadTenderModal = this.handleSaveLoadTenderModal.bind(this);
    this.handleCloseLoadTenderModal = this.handleCloseLoadTenderModal.bind(this);
    this.onChangeLoadTenderResponseModal = this.onChangeLoadTenderResponseModal.bind(this);


  }

  getsessionStorageWarehouse() {

    try {
      let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
      if (warehouseMenuList != null && warehouseMenuList != undefined) {
        this.setState({
          warehouseArray: warehouseMenuList
        })
      }
    }
    catch (e) { }

    try {
      let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
      if (customerMenuList != null && customerMenuList != undefined) {
        this.setState({
          customerArray: customerMenuList
        })
      }
    }
    catch (e) { }

  }


  componentDidMount() {
    this.getsessionStorageWarehouse() //this will preserve menu selection
  }



  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.CtrlTwrShipmentLegsData !== this.props.CtrlTwrShipmentLegsData && this.props.CtrlTwrShipmentLegsData.length > 0) {
    //   this.preloadShipManagement()
    // }

  }






  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }


  //Action Buttons
  onClickQueueStatusResponse(e, queueStatus, loadTenderID, loadTenderNumber, loadTenderHdrId204, loadTenderN902Pro ) {

    this.setState({
      loadTenderResponseModalShown: true,
      loadTenderID: loadTenderID,
      loadTenderNumber: loadTenderNumber,
      loadTenderHdrId204: loadTenderHdrId204,
      loadTenderN902Pro: loadTenderN902Pro
    });


  }







  //Load Tender Modal
  onChangeLoadTenderResponseModal(e, label) {
    if (label === 'loadTenderModalAcceptDecline') {
      this.setState({
        loadTenderModalAcceptDecline: e
      });
    }
  }


  handleCloseLoadTenderModal() {
    this.setState({
      loadTenderResponseModalShown: false,
      loadTenderModalAcceptDecline: null,
      loadTenderID: null,
      loadTenderNumber: null,
      loadTenderHdrId204: null,
      loadTenderN902Pro: null,

    });
  }

  handleSaveLoadTenderModal() {

    if (this.state.loadTenderModalAcceptDecline === null || this.state.loadTenderModalAcceptDecline === '') {
      window.alert('Please select a response.')
    }

    else {
      this.props.edi990QueuePost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        this.props.customerSelection,

        formatNumberForAPI(this.state.loadTenderModalAcceptDecline),
        formatNumberForAPI(this.state.loadTenderID),
        formatNumberForAPI(this.state.loadTenderHdrId204),
        null,
        formatNumberForAPI(this.state.loadTenderN902Pro),
        null,
        'Q'
    )

      this.handleCloseLoadTenderModal()

    }
  }


  render() {
    const { sortColumn, sortType } = this.state;

    let pageHeader;

    let queueStatusDiv;
    let actionsDiv;


    let detailsDiv;
    if (this.props.isEdi204Loading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.Edi204Data[0]) !== 'undefined' && this.props.Edi204Data != null) {

      pageHeader = <div style={{ float: 'left' }}>  {'Control Tower - Load Tender: ' + this.props.Edi204Data[0].LoadTenderNo} </div>


      if (this.props.Edi204Data[0].QueueStatus === 'W') {
        queueStatusDiv = <IconButton
          iconLabel={'Pending Response'}
          icon={<i className="fa fa-envelope" />}
          iconColor={'yellow'}
          allowClick={true}
          onClickIconButton={(e) => { this.onClickQueueStatusResponse(e, this.props.Edi204Data[0].QueueStatus, this.props.Edi204Data[0].ID, this.props.Edi204Data[0].LoadTenderNo, this.props.Edi204Data[0].HdrId204 , this.props.Edi204Data[0].N902) }}
          isMobile={this.props.isMobile}
        />
      }
      else {
        queueStatusDiv = <IconButton
          iconLabel={'Response Complete'}
          icon={<i className="fa fa-envelope-open" />}
          iconColor={'green'}
          allowClick={false}
          // onClickIconButton={(e) => { this.onClickQueueStatusResponse(e, this.props.Edi204OIDData[0].LateShipment, this.props.Edi204OIDData[0].ProRef) }}
          isMobile={this.props.isMobile}
        />
      }


      actionsDiv =
        <div>

          <Row>
            <Col md={6} sm={24}>
              {queueStatusDiv}
            </Col>

          </Row>
        </div>

      detailsDiv =
        <div>
          <Row >
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}
              >
                PRO: <strong>{this.props.Edi204Data[0].N902}</strong>
                <br />
                Customer: <strong>{this.props.Edi204Data[0].CustomerName}</strong>
                <br />
                Load Tender Number: <strong>{this.props.Edi204Data[0].LoadTenderNo}</strong>
                <br />
                Load Tender Date: <strong>{this.props.Edi204Data[0].N904}</strong>
                <br />
                Estimated Pickup Date: <strong>{this.props.Edi204Data[0].B103}</strong>
                <br />
                990 Queue Status: <strong>{this.props.Edi204Data[0].QueueStatusName}</strong>
                <br />
                990 Queue Create DT: <strong>{this.props.Edi204Data[0].QueueCreatDT}</strong>
                <br />

              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}
              >
                Pallets: <strong>{this.props.Edi204Data[0].Data_Pallets}</strong>
                <br />
                Weight: <strong>{this.props.Edi204Data[0].Data_Weight}</strong>
                <br />
                Weight Qualifier: <strong>{this.props.Edi204Data[0].Data_Weight_Qualifier}</strong>
                <br />
                Weight Unit Code: <strong>{this.props.Edi204Data[0].Data_Weight_Unit_Code}</strong>
                <br />
                Declared Value: <strong>{this.props.Edi204Data[0].Data_Declared_Value}</strong>
                <br />
                Rate Qualifier: <strong>{this.props.Edi204Data[0].Data_Rate_Qualifier}</strong>
                <br />
                <br />
              </Panel>
            </Col>
          </Row>

          <Row >
            <Col md={12} sm={24}>
              <Panel bordered header='Ship From' style={{ marginBottom: 10 }}
              >

                Code: <strong>{this.props.Edi204Data[0].SF_Code}</strong>
                <br />
                Name: <strong>{this.props.Edi204Data[0].SF_Name}</strong>
                <br />
                Id Code Qualifier: <strong>{this.props.Edi204Data[0].SF_Id_Code_Qualifier}</strong>
                <br />
                Id Code: <strong>{this.props.Edi204Data[0].SF_Id_Code}</strong>
                <br />
                Address: <strong>{this.props.Edi204Data[0].SF_Address}</strong>
                <br />
                City: <strong>{this.props.Edi204Data[0].SF_City}</strong>
                <br />
                State: <strong>{this.props.Edi204Data[0].SF_State}</strong>
                <br />
                Postal Code: <strong>{this.props.Edi204Data[0].SF_PostalCode}</strong>
                <br />
                Country Code: <strong>{this.props.Edi204Data[0].SF_CountryCode}</strong>
                <br />
                Location Qualifier: <strong>{this.props.Edi204Data[0].SF_Location_Qualifier}</strong>
                <br />
                Location Identifier: <strong>{this.props.Edi204Data[0].SF_Location_Identifier}</strong>
                <br />
                Contact Function Code TE: <strong>{this.props.Edi204Data[0].SF_Contact_Function_Code_TE}</strong>
                <br />
                Contact Name TE: <strong>{this.props.Edi204Data[0].SF_Contact_Name_TE}</strong>
                <br />
                Communication Number Qualifier TE: <strong>{this.props.Edi204Data[0].SF_Communication_Number_Qualifier_TE}</strong>
                <br />
                Communication Number TE: <strong>{this.props.Edi204Data[0].SF_Communication_Number_TE}</strong>
                <br />
                Contact Function Code EM: <strong>{this.props.Edi204Data[0].SF_Contact_Function_Code_EM}</strong>
                <br />
                Contact Name EM: <strong>{this.props.Edi204Data[0].SF_Contact_Name_EM}</strong>
                <br />
                Communication Number Qualifier EM: <strong>{this.props.Edi204Data[0].SF_Communication_Number_Qualifier_EM}</strong>
                <br />
                Communication Number EM: <strong>{this.props.Edi204Data[0].SF_Communication_Number_EM}</strong>
                <br />

              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered header='Ship To' style={{ marginBottom: 10 }}
              >
                Code: <strong>{this.props.Edi204Data[0].ST_Code}</strong>
                <br />
                Name: <strong>{this.props.Edi204Data[0].ST_Name}</strong>
                <br />
                Id Code Qualifier: <strong>{this.props.Edi204Data[0].ST_Id_Code_Qualifier}</strong>
                <br />
                Id Code: <strong>{this.props.Edi204Data[0].ST_Id_Code}</strong>
                <br />
                Address: <strong>{this.props.Edi204Data[0].ST_Address}</strong>
                <br />
                City: <strong>{this.props.Edi204Data[0].ST_City}</strong>
                <br />
                State: <strong>{this.props.Edi204Data[0].ST_State}</strong>
                <br />
                Postal Code: <strong>{this.props.Edi204Data[0].ST_PostalCode}</strong>
                <br />
                Country Code: <strong>{this.props.Edi204Data[0].ST_CountryCode}</strong>
                <br />
                Location Qualifier: <strong>{this.props.Edi204Data[0].ST_Location_Qualifier}</strong>
                <br />
                Location Identifier: <strong>{this.props.Edi204Data[0].ST_Location_Identifier}</strong>
                <br />
                Contact Function Code TE: <strong>{this.props.Edi204Data[0].ST_Contact_Function_Code_TE}</strong>
                <br />
                Contact Name TE: <strong>{this.props.Edi204Data[0].ST_Contact_Name_TE}</strong>
                <br />
                Communication Number Qualifier TE: <strong>{this.props.Edi204Data[0].ST_Communication_Number_Qualifier_TE}</strong>
                <br />
                Communication Number TE: <strong>{this.props.Edi204Data[0].ST_Communication_Number_TE}</strong>
                <br />
                Contact Function Code EM: <strong>{this.props.Edi204Data[0].ST_Contact_Function_Code_EM}</strong>
                <br />
                Contact Name EM: <strong>{this.props.Edi204Data[0].ST_Contact_Name_EM}</strong>
                <br />
                Communication Number Qualifier EM: <strong>{this.props.Edi204Data[0].ST_Communication_Number_Qualifier_EM}</strong>
                <br />
                Communication Number EM: <strong>{this.props.Edi204Data[0].ST_Communication_Number_EM}</strong>
                <br />
              </Panel>
            </Col>
          </Row>


          <Row >
            <Col md={12} sm={24}>
              <Panel bordered header='Stop Off Details' style={{ marginBottom: 10 }}
              >

                Stop Seq Number: <strong>{this.props.Edi204Data[0].SOD_Stop_Seq_Number}</strong>
                <br />
                Stop Reason code: <strong>{this.props.Edi204Data[0].SOD_Stop_Reason_code}</strong>
                <br />
                Reference Id MA: <strong>{this.props.Edi204Data[0].SOD_Reference_Id_MA}</strong>
                <br />
                Reference Id Qualifier MA: <strong>{this.props.Edi204Data[0].SOD_Reference_Id_Qualifier_MA}</strong>
                <br />
                Reference Id EQ: <strong>{this.props.Edi204Data[0].SOD_Reference_Id_EQ}</strong>
                <br />
                Reference Id Qualifier EQ: <strong>{this.props.Edi204Data[0].SOD_Reference_Id_Qualifier_EQ}</strong>
                <br />
                Pick Up Window Start Date Qualifier: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowStart_Date_Qualifier}</strong>
                <br />
                Pick Up Window Start Date: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowStart_Date}</strong>
                <br />
                Pick Up Window Start Time Qualifier: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowStart_Time_Qualifier}</strong>
                <br />
                Pick Up Window Start Time: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowStart_Time}</strong>
                <br />
                Pick Up Window End Date Qualifier: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowEnd_Date_Qualifier}</strong>
                <br />
                Pick Up Window End Date: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowEnd_Date}</strong>
                <br />
                Pick Up Window End Time Qualifier: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowEnd_Time_Qualifier}</strong>
                <br />
                Pick Up Window End Time: <strong>{this.props.Edi204Data[0].SOD_PickUpWindowEnd_Time}</strong>
                <br />
                Weight Qualifier: <strong>{this.props.Edi204Data[0].SOD_Weight_Qualifier}</strong>
                <br />
                Weight Unit Code: <strong>{this.props.Edi204Data[0].SOD_Weight_Unit_Code}</strong>
                <br />
                Weight: <strong>{this.props.Edi204Data[0].SOD_Weight}</strong>
                <br />
                AT804 Not Used: <strong>{this.props.Edi204Data[0].SOD_AT804_NotUsed}</strong>
                <br />
                Lading Quantity: <strong>{this.props.Edi204Data[0].SOD_Lading_Quantity}</strong>
                <br />
                Volume Unit Qualifier: <strong>{this.props.Edi204Data[0].SOD_Volume_Unit_Qualifier}</strong>
                <br />
                Note Reference Code: <strong>{this.props.Edi204Data[0].SOD_Note_Reference_Code}</strong>
                <br />
                Description: <strong>{this.props.Edi204Data[0].SOD_Description}</strong>
                <br />

              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered header='Bill To' style={{ marginBottom: 10 }}
              >
                Code: <strong>{this.props.Edi204Data[0].BT_Code}</strong>
                <br />
                Name: <strong>{this.props.Edi204Data[0].BT_Name}</strong>
                <br />
                Id Code Qualifier: <strong>{this.props.Edi204Data[0].BT_Id_Code_Qualifier}</strong>
                <br />
                Id Code: <strong>{this.props.Edi204Data[0].BT_Id_Code}</strong>
                <br />
                Address: <strong>{this.props.Edi204Data[0].BT_Address}</strong>
                <br />
                City: <strong>{this.props.Edi204Data[0].BT_City}</strong>
                <br />
                State: <strong>{this.props.Edi204Data[0].BT_State}</strong>
                <br />
                Postal Code: <strong>{this.props.Edi204Data[0].BT_PostalCode}</strong>
                <br />
                Country Code: <strong>{this.props.Edi204Data[0].BT_CountryCode}</strong>
                <br />
                Location Qualifier: <strong>{this.props.Edi204Data[0].BT_Location_Qualifier}</strong>
                <br />
                Location Identifier: <strong>{this.props.Edi204Data[0].BT_Location_Identifier}</strong>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

              </Panel>
            </Col>
          </Row>

        </div>


    }
    else {
      detailsDiv = <div></div>;
    }





    // let edi204OIDDiv;
    let oidRTable;
    if (this.props.isEdi204OIDLoading) {

      oidRTable =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.Edi204OIDData) !== 'undefined' && this.props.Edi204OIDData != null) {
      oidRTable = RTableGeneral(
        'Id',     //   keyColumn,
        this.props.Edi204OIDData,     //   tableData,
        Edi204OIDDataElements,       //   DataElements,
        this.props.isEdi204OIDLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        false,      //   onClickAddMilestone,
        null,       //   onClickSelect,
        null,       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        false,       //   link1bool,
        null,     //   link1text,
        null,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick


      )


      // edi204OIDDiv = <div>

      //     {(this.props.isMobile == true) ? <div></div> :
      //         <div>
      //             <Row>
      //                 <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'ShipmentList_' + currentDateTime}>
      //                     <ExcelSheet data={this.props.Edi204OIDData} name="ShipmentList">
      //                         {
      //                             Edi204OIDDataElements.map(
      //                                 ({ dBKey, displayName }, idx) => {
      //                                     return (
      //                                         <ExcelColumn label={displayName} value={dBKey} />
      //                                     );
      //                                 }
      //                             )
      //                         }
      //                     </ExcelSheet>
      //                 </ExcelFile>
      //             </Row>
      //         </div>
      //     }

      // </div>
    }
    else {
      oidRTable = <div></div>
      // edi204OIDDiv = <div></div>
    }




    // let edi204L5Div;
    let l5RTable;
    if (this.props.isEdi204L5Loading) {

      l5RTable =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.Edi204L5Data) !== 'undefined' && this.props.Edi204L5Data != null) {
      l5RTable = RTableGeneral(
        'Id',     //   keyColumn,
        this.props.Edi204L5Data,     //   tableData,
        Edi204L5DataElements,       //   DataElements,
        this.props.isEdi204L5Loading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        false,      //   onClickAddMilestone,
        null,       //   onClickSelect,
        null,       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        false,       //   link1bool,
        null,     //   link1text,
        null,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick


      )


      // edi204L5Div = <div>

      //     {(this.props.isMobile == true) ? <div></div> :
      //         <div>
      //             <Row>
      //                 <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'ShipmentList_' + currentDateTime}>
      //                     <ExcelSheet data={this.props.Edi204L5Data} name="ShipmentList">
      //                         {
      //                             Edi204L5DataElements.map(
      //                                 ({ dBKey, displayName }, idx) => {
      //                                     return (
      //                                         <ExcelColumn label={displayName} value={dBKey} />
      //                                     );
      //                                 }
      //                             )
      //                         }
      //                     </ExcelSheet>
      //                 </ExcelFile>
      //             </Row>
      //         </div>
      //     }

      // </div>
    }
    else {
      l5RTable = <div></div>
      // edi204L5Div = <div></div>
    }

    return (
      <div>
        <h3>
          <Row>{pageHeader} </Row>
        </h3>

        <PanelGroup>
          {actionsDiv}
          {detailsDiv}
        </PanelGroup >


        <Panel bordered header='Order Identification Detail' style={{ marginBottom: 10 }}>
          {oidRTable}
        </Panel >

        <Panel bordered header='Description, Marks and Numbers' style={{ marginBottom: 10 }}>
          {l5RTable}
        </Panel >

        <LoadTenderResponseModal {...this.props} state={this.state} loadTenderResponseModalShown={this.state.loadTenderResponseModalShown} onChangeLoadTenderResponseModal={this.onChangeLoadTenderResponseModal} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseLoadTenderModal} handleSave={this.handleSaveLoadTenderModal} />

      </div>
    );

  }
}

export default withRouter(LoadTender);



