import React from 'react'
import PropTypes from 'prop-types'
import {awsCognitoConfig} from "../../../app/awsCognitoConfig"
import { config } from 'aws-cognito-redux-saga'

class CognitoAuth extends React.Component {
  static propTypes = {
    getUser: PropTypes.func
  }

    componentWillMount() {
      config.config.set(awsCognitoConfig.cognitoLogin.Auth)
      this.props.getUser()
  }


  render() {
    return null
  }
}

export default CognitoAuth
