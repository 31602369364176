import React, {
  PureComponent
} from 'react';
import { Loader, Row, Button } from 'rsuite';
import RTableSetHeight from '../../componentsGeneral/Rsuite/Table/RTableSetHeight';
import ReactExport from "react-export-excel";

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



function ConfigTable(
  isLoading,
  keyColumn,
  tableData,
  tableDataElements,
  title,
  currentDateTime,
  onClickAddNew,

  isMobile,
  history,

  sortColumn,
  sortType,
  handleSortColumn,
  state, 
  ...props

) {



  let tableDiv;
  let table;
  if (isLoading) {

    tableDiv =
      <div align="center">
        <section className="panel">
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
  }
  else if (typeof (tableData) !== 'undefined' && tableData != null) {

    table = RTableSetHeight(
      keyColumn,     //   keyColumn,
      tableData,     //   tableData,
      tableDataElements,       //   DataElements,
      isLoading,        //   loading,

      props.isMobile,        //   isMobile,
      props.history,     //   history,

      sortColumn,     //   sortColumn,
      sortType,       //   sortType,
      handleSortColumn,      //   handleSortColumn,

      false, // checkColEntry,
      null,       //   checkedKeys,
      null,       //   handleCheckAll,
      null,       //   handleCheck,

      false,      //   boolSelectButton,
      null,       //   onClickSelect,
      null,       //   selectButtonText,
      null,       //   selectHighlightRowKey,

      false,       //   link1bool,
      null,     //   link1text,
      null,     //   link1onClick,

      false,       //   link2bool,
      null,       //   link2text,
      null,  //   link2onClick,

      300       //  height

    )
    tableDiv = <div>
      <h4>
        {title}
      </h4>
      {(props.isMobile == true) ? <div></div> :
        <div>
          <Row>


            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={title + '_' + currentDateTime}>
              <ExcelSheet data={tableData} name={title}>
                {
                  tableDataElements.map(
                    ({ dBKey, displayName }, idx) => {
                      return (
                        <ExcelColumn label={displayName} value={dBKey} />
                      );
                    }
                  )
                }
              </ExcelSheet>
            </ExcelFile>

            <Button onClick={(e) => { onClickAddNew(e) }} style={{ float: 'right', marginRight: 10 }}> Add {title} </Button>


          </Row>
        </div>
      }
      {table}
    </div>
  }
  else {
    table = <div></div>
    tableDiv = <div></div>
  }


















  return (

    tableDiv

  )
}


export default ConfigTable;
