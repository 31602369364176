import React from 'react';
import { Row, Col, Panel, Timeline } from 'rsuite'; // Assuming you're using rsuite
import Loader from 'react-loader-spinner'; // Assuming you're using react-loader-spinner

function CaseSKUsHistoryDiv({ isInventoryCaseSkusHistoryLoading, InventoryCaseSkusHistoryData }) {
    if (isInventoryCaseSkusHistoryLoading) {
        return (
            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        );
    }

    if (typeof (InventoryCaseSkusHistoryData[0]) !== 'undefined' && InventoryCaseSkusHistoryData != null) {
        return (
            <div>
                <Row>
                    <Col md={24} sm={24}>
                        <Panel bordered header="Unit History" style={{ marginBottom: 10 }}>
                            <Timeline>
                                {InventoryCaseSkusHistoryData.map(item => (
                                    <Timeline.Item key={item.CaseSkuHistoryID}>
                                        {item.CreatedDT}  &nbsp;
                                        {item.EmailAddr}
                                        <l></l>
                                        {item.HistoryType != null ? <l><br /> {item.HistoryType} </l> : ' '}
                                        {item.StatusName != null ? <l><br /> Unit Status:  {item.StatusName} </l> : ' '}
                                        {item.Container != null && item.HistoryTypeID == 1 ? <l><br /> Received From Container:  {item.Container} </l> : ' '}
                                        {item.SKU != null ? <l><br /> SKU:  {item.SKU} </l> : ' '}
                                        {/* {item.PalletBarcode != null ? <l><br /> Pallet Barcode:  {item.PalletBarcode} </l> : ' '}
                                        {item.CaseBarcode != null ? <l><br /> Case Barcode:  {item.CaseBarcode} </l> : ' '} */}
                                        
                                        {/* {item.SerialNumber != null ? <l><br /> Serial Number:  {item.SerialNumber} </l> : ' '}
                                        {item.LotCode != null ? <l><br /> Lot Code:  {item.LotCode} </l> : ' '}
                                        {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '} */}
                                        {item.UnitsRecovered != null ? <l><br /> Units Recovered:  {item.UnitsRecovered} </l> : ' '}
                                        {item.UnitsLoaded != null ? <l><br /> Units Loaded:  {item.UnitsLoaded} </l> : ' '}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }

    return <div></div>;
}

export default CaseSKUsHistoryDiv;


