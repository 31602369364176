import { connect } from 'react-redux';
import React, { Component } from 'react';
import LabelGenConfiguration from './LabelGenConfiguration'
// import * as actions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'
import * as actions from '../../../redux/actions/labelGenActions';

//add props from reducer here
const mapStateToProps = store =>
({
    isUserAccountsLoading: store.userServicesReducer.isUserAccountsLoading,
    userAccountData: store.userServicesReducer.userAccountData,

    userSignInData: store.userServicesReducer.userSignInData,
    currentUserEmail: store.userServicesReducer.currentUserEmail,

    isCtrlTwrAirportLoading: store.ctrlTwrReducer.isCtrlTwrAirportLoading,
    CtrlTwrAirportData: store.ctrlTwrReducer.CtrlTwrAirportData,

    isCtrlTwrSeaportLoading: store.ctrlTwrReducer.isCtrlTwrSeaportLoading,
    CtrlTwrSeaportData: store.ctrlTwrReducer.CtrlTwrSeaportData,

});

//add actions here
const mapDispatchToProps = dispatch =>
({

    labelGenRequestCustomerWarehouseUserSavedValuesGet: (_email, _authToken, _CustomerID, _WarehouseID, _CarrierID, _UserSavedValuesID, _ProdEnvironment) => {
        dispatch(actions.labelGenRequestCustomerWarehouseUserSavedValuesGet(_email, _authToken, _CustomerID, _WarehouseID, _CarrierID, _UserSavedValuesID, _ProdEnvironment))
    },
    labelGenRequestCustomerWarehouseUserSavedValuesPost: (_email, _authToken, _UserSavedValuesID, _CustomerID, _WarehouseID, _CarrierID, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _ProdEnvironment, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _PrinterIP, _PrinterPort, _CarrierServiceID, _Delete) => {
        dispatch(actions.labelGenRequestCustomerWarehouseUserSavedValuesPost(_email, _authToken, _UserSavedValuesID, _CustomerID, _WarehouseID, _CarrierID, _Shipper_Name, _Shipper_AttentionName, _Shipper_CompanyDisplayableName, _Shipper_TaxIdentificationNumber, _Shipper_Phone_Number, _Shipper_Phone_Extension, _Shipper_ShipperNumber, _Shipper_FaxNumber, _Shipper_EMailAddress, _Shipper_Address_AddressLine1, _Shipper_Address_AddressLine2, _Shipper_Address_AddressLine3, _Shipper_Address_City, _Shipper_Address_StateProvinceCode, _Shipper_Address_PostalCode, _Shipper_Address_CountryCode, _ShipFrom_Name, _ShipFrom_AttentionName, _ShipFrom_CompanyDisplayableName, _ShipFrom_TaxIdentificationNumber, _ShipFrom_Phone_Number, _ShipFrom_Phone_Extension, _ShipFrom_FaxNumber, _ShipFrom_Address_AddressLine1, _ShipFrom_Address_AddressLine2, _ShipFrom_Address_AddressLine3, _ShipFrom_Address_City, _ShipFrom_Address_StateProvinceCode, _ShipFrom_Address_PostalCode, _ShipFrom_Address_CountryCode, _ProdEnvironment, _LabelSpec_LabelImageFormat_Code, _LabelSpec_LabelStockSize_Height, _LabelSpec_LabelStockSize_Width, _PrinterIP, _PrinterPort, _CarrierServiceID, _Delete))
    },





});

class LabelGenConfigurationContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.props.ctrlTwrAirportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.ctrlTwrSeaportGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

    }


    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        return (
            <LabelGenConfiguration
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isCtrlTwrAirportLoading={this.props.isCtrlTwrAirportLoading}
                CtrlTwrAirportData={this.props.CtrlTwrAirportData}

                isCtrlTwrSeaportLoading={this.props.isCtrlTwrSeaportLoading}
                CtrlTwrSeaportData={this.props.CtrlTwrSeaportData}

            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelGenConfigurationContainer);




