import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    InputNumber,
    InputGroup,
    Input,
    Divider,
    Row,
    Col,
    Checkbox
} from 'rsuite';
import RFormControl from '../../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask, textStringInputMask } from '../../../../resources/logic/inputMasks';
import { replaceNegativeWithZero, convertNanToZero } from '../../../../resources/logic/functions';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};





function limitCaseNumberToExistingValue(e, maxValue) {
    try {
        var nonNegativeValue
        nonNegativeValue = numbersInputMask(e)

        if (maxValue < nonNegativeValue) {
            nonNegativeValue = maxValue
        }
        return nonNegativeValue
    }
    catch (e) {
        return 0
    }
}

const mountRef = React.createRef();

const EditSingleWarehouseLocationsDrawer = ({ onChangeLocation, onClickAddLocation, placement, confirm, close, unsavedChanges, state, ...props }) => (

    <Drawer show={state.showEditSingleWarehouseLocationsDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '800px' }}    >
        <Drawer.Header>
            <Drawer.Title>Edit Warehouse Location</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                <Row>
                    <Col md={24} sm={24}>
                        <FormGroup >

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Existing Location:</InputGroup.Addon>
                                <Input value={state.LocationToEditDict['WarehouseLocationName']} readOnly />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Remaining Units:</InputGroup.Addon>
                                <InputNumber readOnly value={convertNanToZero(state.LocationOriginalDict.Units) - convertNanToZero(state.newUnits)} className={'custom-input-number'} scrollable={false} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Remaining Cases:</InputGroup.Addon>
                                <InputNumber readOnly value={convertNanToZero(state.LocationOriginalDict.Cases) - convertNanToZero(state.newCases)} className={'custom-input-number'} scrollable={false} />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '5px', width: '100%' }}>
                                <InputGroup.Addon>Remaining Pallets:</InputGroup.Addon>
                                <InputNumber readOnly value={convertNanToZero(state.LocationOriginalDict.Pallets) - convertNanToZero(state.newPallets)} className={'custom-input-number'} scrollable={false} />
                            </InputGroup>

                        </FormGroup>
                    </Col>
                </Row>

                <Divider>Move To New Location:</Divider>

                <div>

                    <Row>
                        <Col md={5} sm={5}>
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                {/* <InputGroup.Addon>Location:</InputGroup.Addon> */}
                                <InputPicker preventOverflow
                                    container={() => mountRef.current}
                                    // placeholder={label}
                                    placeholder={'Location...'}
                                    value={state.newWarehouseLocationID}
                                    onChange={(e) => { onChangeLocation(e, 'newWarehouseLocationID') }}
                                    data={props.WarehouseLocationsData}
                                    block
                                    cleanable={true}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={5} sm={5}>
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                <InputGroup.Addon>Units:</InputGroup.Addon>
                                <InputNumber value={state.newUnits} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, 'newUnits')} scrollable={false} onInput={(e) => e.target.value = limitCaseNumberToExistingValue(e, state.LocationOriginalDict.Units)} />
                            </InputGroup>
                        </Col>
                        <Col md={5} sm={5}>
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                <InputGroup.Addon>Cases:</InputGroup.Addon>
                                <InputNumber value={state.newCases} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, 'newCases')} scrollable={false} onInput={(e) => e.target.value = limitCaseNumberToExistingValue(e, state.LocationOriginalDict.Cases)} />
                            </InputGroup>
                        </Col>
                        <Col md={5} sm={5}>
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                <InputGroup.Addon>Pallets:</InputGroup.Addon>
                                <InputNumber value={state.newPallets} className={'custom-input-number'} onChange={(e) => onChangeLocation(e, 'newPallets')} scrollable={false} onInput={(e) => e.target.value = limitCaseNumberToExistingValue(e, state.LocationOriginalDict.Pallets)} />
                            </InputGroup>
                        </Col>

                        <Col md={4} sm={4}>
                            <InputGroup style={{ marginBottom: '10px', width: '100%' }}>
                                <Checkbox checked={state.newOversize} onChange={(e, checked) => onChangeLocation(checked, 'newOversize')} >Oversize</Checkbox>
                            </InputGroup>
                        </Col>

                    </Row>
                </div>

                <Divider />

                <FormGroup>
                    <ButtonToolbar>
                        {unsavedChanges ? <Button onClick={confirm} appearance="primary">Save</Button> : <div></div>}
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>

        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />
        </Drawer.Footer>

    </Drawer>

);



EditSingleWarehouseLocationsDrawer.defaultProps = {
    show: false
};

export default EditSingleWarehouseLocationsDrawer;


