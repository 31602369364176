
export const CtrlTwrSeaportDataElements = [
    {
        dBKey: 'SeaportCode',
        displayName: 'Seaport Code',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Port',
        displayName: 'Port',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'Country',
        displayName: 'Country',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    
]


export default CtrlTwrSeaportDataElements

