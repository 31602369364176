import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import HouseDetailsContainer from '../../../components/componentsVas/HouseDetails/HouseDetailsContainer';
import { Message } from 'rsuite';

class houseDetailsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

    }

    render() {

        if (typeof (this.props.warehouseSelection) !== 'number' || typeof (this.props.customerSelection) !== 'number') {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please select only one Warehouse and one Customer to view all data." /></AnimatedView>;
        }

        return (
            <AnimatedView>
                <HouseDetailsContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default houseDetailsView;
