import { connect } from 'react-redux';
import React, { Component } from 'react';
import SkuDetail from './SkuDetail'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as userActions from '../../../../redux/actions/actionsUser';
import * as inventoryActions from '../../../../redux/actions/inventoryActions'
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isInventorySkuWarehouseLocationLoading: store.inventoryReducer.isInventorySkuWarehouseLocationLoading,
    InventorySkuWarehouseLocationData: store.inventoryReducer.InventorySkuWarehouseLocationData,

    isInventorySkuLoading: store.inventoryReducer.isInventorySkuLoading,
    InventorySkuData: store.inventoryReducer.InventorySkuData,

    isInventorySkuRecoveryLoading: store.inventoryReducer.isInventorySkuRecoveryLoading,
    InventorySkuRecoveryData: store.inventoryReducer.InventorySkuRecoveryData,


    isInventorySkuRecoveryHistoryLoading: store.inventoryReducer.isInventorySkuRecoveryHistoryLoading,
    InventorySkuRecoveryHistoryData: store.inventoryReducer.InventorySkuRecoveryHistoryData,

    isInventorySkuWarehouseLocationHistoryLoading: store.inventoryReducer.isInventorySkuWarehouseLocationHistoryLoading,
    InventorySkuWarehouseLocationHistoryData: store.inventoryReducer.InventorySkuWarehouseLocationHistoryData,

    isInventoryLoadSkuLoading: store.inventoryReducer.isInventoryLoadSkuLoading,
    InventoryLoadSkuData: store.inventoryReducer.InventoryLoadSkuData,


    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    InventorySkuWarehouseLocationPostSuccessful: store.inventoryReducer.InventorySkuWarehouseLocationPostSuccessful,

    InventorySkuPostSuccessful: store.inventoryReducer.InventorySkuPostSuccessful,
    SkuID: store.inventoryReducer.SkuID,

    isInventoryCustomerRefLoading: store.inventoryReducer.isInventoryCustomerRefLoading,
    InventoryCustomerRefData: store.inventoryReducer.InventoryCustomerRefData,

    isInventoryProjectLoading: store.inventoryReducer.isInventoryProjectLoading,
    InventoryProjectData: store.inventoryReducer.InventoryProjectData,
});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    inventorySkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuWarehouseLocGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventorySkuRecoverGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventorySkuRecoverHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,) => {
        dispatch(inventoryActions.inventorySkuRecoverHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container,))
    },
    inventorySkuWarehouseLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _SkuID, _SKU, _ContainerID, _Container, _SkuStatusID, _WarehouseLocationID))
    },
    inventoryLoadSkuGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID) => {
        dispatch(inventoryActions.inventoryLoadSkuGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _SkuID))
    },
    inventorySkuWarehouseLocationPost: (_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription) => {
        dispatch(inventoryActions.inventorySkuWarehouseLocationPost(_email, _authToken, _SkuID, _SkuWarehouseLocationID, _WarehouseLocationID, _Units, _Cases, _Pallets, _Oversize, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _SkuRecovery, _RecoveryContainerID, _RecoveryDate, _SkuDescription))
    },

    inventorySkuPost: (_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes) => {
        dispatch(inventoryActions.inventorySkuPost(_email, _authToken, _SkuID, _SKU, _ContainerID, _WarehouseID, _CustomerID, _CurrentSkuStatusID, _CustomerRefID, _CustomerRef, _ProjectID, _Project, _Notes))
    },

    inventoryCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },


    inventoryProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(inventoryActions.inventoryProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
});

class SkuDetailContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();

        // this.props.inventoryCustomerRefGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection )
        // this.props.inventoryProjectGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        if (prevProps.InventorySkuWarehouseLocationPostSuccessful !== this.props.InventorySkuWarehouseLocationPostSuccessful && this.props.InventorySkuWarehouseLocationPostSuccessful) {
            this.handleUrlParam()
        }

        if (prevProps.InventorySkuPostSuccessful !== this.props.InventorySkuPostSuccessful && this.props.InventorySkuPostSuccessful) {
            this.handleUrlParam()
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramSku = formatStringForAPI(values.sku)
        }
        catch (e) {
            var paramSku = null
        }
        try {
            var paramSkuID = formatStringForAPI(values.skuid)
        }
        catch (e) {
            var paramSkuID = null
        }
        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.inventorySkuWarehouseLocGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramSkuID, paramSku, null, null, null, null)
        this.props.inventorySkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramSkuID, paramSku, null, null, null, null)
        this.props.inventorySkuRecoverGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramSkuID, paramSku, null, null)
        this.props.inventorySkuRecoverHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramSkuID, paramSku, null, null)
        this.props.inventorySkuWarehouseLocationHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramSkuID, paramSku, null, null, null, null)
        this.props.inventoryLoadSkuGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, paramSkuID)
    }



    render() {

        return (

            <SkuDetail
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                userWarehouseLocationsGet={this.props.userWarehouseLocationsGet}
                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isInventorySkuWarehouseLocationLoading={this.props.isInventorySkuWarehouseLocationLoading}
                InventorySkuWarehouseLocationData={this.props.InventorySkuWarehouseLocationData}

                isInventorySkuLoading={this.props.isInventorySkuLoading}
                InventorySkuData={this.props.InventorySkuData}

                isInventorySkuRecoveryLoading={this.props.isInventorySkuRecoveryLoading}
                InventorySkuRecoveryData={this.props.InventorySkuRecoveryData}

                isInventorySkuRecoveryHistoryLoading={this.props.isInventorySkuRecoveryHistoryLoading}
                InventorySkuRecoveryHistoryData={this.props.InventorySkuRecoveryHistoryData}

                isInventorySkuWarehouseLocationHistoryLoading={this.props.isInventorySkuWarehouseLocationHistoryLoading}
                InventorySkuWarehouseLocationHistoryData={this.props.InventorySkuWarehouseLocationHistoryData}

                inventoryLoadSkuGet={this.props.inventoryLoadSkuGet}
                isInventoryLoadSkuLoading={this.props.isInventoryLoadSkuLoading}
                InventoryLoadSkuData={this.props.InventoryLoadSkuData}

                InventorySkuWarehouseLocationPostSuccessful={this.props.InventorySkuWarehouseLocationPostSuccessful}
                inventorySkuWarehouseLocationPost={this.props.inventorySkuWarehouseLocationPost}


                inventorySkuPost={this.props.inventorySkuPost}
                InventorySkuPostSuccessful={this.props.InventorySkuPostSuccessful}

                inventoryCustomerRefGet={this.props.inventoryCustomerRefGet}
                isInventoryCustomerRefLoading={this.props.isInventoryCustomerRefLoading}
                InventoryCustomerRefData={this.props.InventoryCustomerRefData}
            
                inventoryProjectGet={this.props.inventoryProjectGet}
                isInventoryProjectLoading={this.props.isInventoryProjectLoading}
                InventoryProjectData={this.props.InventoryProjectData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SkuDetailContainer));



