export const TransOutboundDataElements = [
    {
        dBKey: 'LoadDepartDT',
        displayName: 'Load Depart Date',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'PO',
        displayName: 'PO',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'Container',
        displayName: 'Container',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'PCS',
        displayName: 'PCS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 110
    },
    {
        dBKey: 'Carrier',
        displayName: 'Carrier',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
        {
        dBKey: 'Trailer',
        displayName: 'Trailer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'Seal',
        displayName: 'Seal',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'BOL',
        displayName: 'BOL #',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'InWarehouseHours',
        displayName: 'In Warehouse Hours',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'MinRecoveryDT',
        displayName: 'Rcd Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'PickupDate',
        displayName: 'Pickup Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Project',
        displayName: 'Project',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'Destination',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },

    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },



    
]


export default TransOutboundDataElements

