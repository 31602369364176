import React, { useCallback } from 'react';
import _ from 'lodash';
import { Button } from 'rsuite';

export default function DebouncedButton({ onClick, buttonText, appearance = "primary", block = false }) {
    const debouncedConfirm = useCallback(_.debounce(onClick, 400), [onClick]);

    return (
        <Button onClick={debouncedConfirm} appearance={appearance} block={block}>{buttonText}</Button>
    );
}


// //How to implement
// // Example usage with appearance prop
// <DebouncedButton onClick={yourOnClickFunction} buttonText="Submit" appearance="default" />

// // Example usage without appearance prop
// <DebouncedButton onClick={yourOnClickFunction} buttonText="Submit" />