import { connect } from 'react-redux';
import React, { Component } from 'react';
import PoDetails from './PoDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/transActions';
import * as userActions from '../../../redux/actions/actionsUser';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,


    isPoLoading: store.transReducer.isPoLoading,
    PoData: store.transReducer.PoData,

    isLoadLoading: store.transReducer.isLoadLoading,
    LoadData: store.transReducer.LoadData,

    LoadRemovePostSuccessful: store.transReducer.LoadRemovePostSuccessful,

    isLoadPoHistoryLoading: store.transReducer.isLoadPoHistoryLoading,
    LoadPoHistoryData: store.transReducer.LoadPoHistoryData,

    isContainerJsonTreeLoading: store.transReducer.isContainerJsonTreeLoading,
    ContainerJsonTreeData: store.transReducer.ContainerJsonTreeData,

    isTransCustomerRefLoading: store.transReducer.isTransCustomerRefLoading,
    TransCustomerRefData: store.transReducer.TransCustomerRefData,

    PoPostSuccessful: store.transReducer.PoPostSuccessful,

    isPoHistoryLoading: store.transReducer.isPoHistoryLoading,
    PoHistoryData: store.transReducer.PoHistoryData,

    isPoLocationLoading: store.transReducer.isPoLocationLoading,
    PoLocationData: store.transReducer.PoLocationData,

    isWarehouseLocationsLoading: store.userServicesReducer.isWarehouseLocationsLoading,
    WarehouseLocationsData: store.userServicesReducer.WarehouseLocationsData,

    PoLocationPostSuccessful: store.transReducer.PoLocationPostSuccessful,

    isPoLoadLocationHistoryLoading: store.transReducer.isPoLoadLocationHistoryLoading,
    PoLoadLocationHistoryData: store.transReducer.PoLoadLocationHistoryData,

    isTransProjectLoading: store.transReducer.isTransProjectLoading,
    TransProjectData: store.transReducer.TransProjectData,

});

const mapDispatchToProps = dispatch =>
({
    userWarehouseLocationsGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(userActions.userWarehouseLocationsGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    transPoGet: (_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID, _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd) => {
        dispatch(actions.transPoGet(_email, _authToken, _WarehouseID, _CustomerID, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _ContainerStatusID, _PoStatusID, _CustomerRefID, _CustomerRef, _RecoveryDateStart, _RecoveryDateEnd))
    },
    transLoadGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _MbolID, _HbolID, _PoID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal) => {
        dispatch(actions.transLoadGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _LoadStatusID, _ContainerID, _MbolID, _HbolID, _PoID, _CustomerRefID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _Carrier, _BOL, _Trailer, _Seal))
    },
    transLoadRemovePost: (_email, _authToken, _LoadID, _PoID, _PCS, _Pallets, _WarehouseLocations, _WarehouseID, _CustomerID) => {
        dispatch(actions.transLoadRemovePost(_email, _authToken, _LoadID, _PoID, _PCS, _Pallets, _WarehouseLocations, _WarehouseID, _CustomerID))
    },
    transLoadPoHistoryGet: (_email, _authToken, _PoID, _LoadID, _WarehouseID, _CustomerID) => {
        dispatch(actions.transLoadPoHistoryGet(_email, _authToken, _PoID, _LoadID, _WarehouseID, _CustomerID))
    },
    transContainerJsonTreeGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID) => {
        dispatch(actions.transContainerJsonTreeGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _WarehouseID, _CustomerID))
    },
    transCustomerRefGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transCustomerRefGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    transPoPost: (_email, _authToken, _PoID, _PO, _HbolID, _CustomerRef, _CaseCount, _PalletCount, _Notes, _Project, _Delete, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoPost(_email, _authToken, _PoID, _PO, _HbolID, _CustomerRef, _CaseCount, _PalletCount, _Notes, _Project, _Delete, _WarehouseID, _CustomerID))
    },
    transPoHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _PoID, _PO) => {
        dispatch(actions.transPoHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _PoID, _PO))
    },
    transPoLocationGet: (_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID) => {
        dispatch(actions.transPoLocationGet(_email, _authToken, _ContainerID, _Container, _MbolID, _MBOL, _HbolID, _HBOL, _PoID, _PO, _PoStatusID, _CustomerRefID, _RecoveryDateStart, _RecoveryDateEnd, _WarehouseID, _CustomerID))
    },
    transPoLocationPost: (_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize) => {
        dispatch(actions.transPoLocationPost(_email, _authToken, _PoID, _WarehouseLocationID, _Cases, _Pallets, _PreviousWarehouseLocationID, _WarehouseID, _CustomerID, _Oversize))
    },
    transPoLoadLocationHistoryGet: (_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PoID, _PO) => {
        dispatch(actions.transPoLoadLocationHistoryGet(_email, _authToken, _WarehouseID, _CustomerID, _LoadID, _PoID, _PO))
    },
    transProjectGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.transProjectGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
});

class PoDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.LoadRemovePostSuccessful !== this.props.LoadRemovePostSuccessful && this.props.LoadRemovePostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }

        if (prevProps.PoPostSuccessful !== this.props.PoPostSuccessful && this.props.PoPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }


        if (prevProps.PoLocationPostSuccessful !== this.props.PoLocationPostSuccessful && this.props.PoLocationPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else { }
        
    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramContainerID = queryStringParse(values.containerid)
        }
        catch (e) {
            var paramContainerID = null
        }
        try {
            var paramContainer = formatStringForAPI(values.container)
        }
        catch (e) {
            var paramContainer = null
        }
        try {
            var paramStatus = queryStringParse(values.containerstatus)
        }
        catch (e) {
            var paramStatus = null
        }
        try {
            var paramMbol = formatStringForAPI(values.mbol)
        }
        catch (e) {
            var paramMbol = null
        }
        try {
            var paramHbol = formatStringForAPI(values.hbol)
        }
        catch (e) {
            var paramHbol = null
        }
        try {
            var paramPo = formatStringForAPI(values.po)
        }
        catch (e) {
            var paramPo = null
        }
        try {
            var paramPoID = queryStringParse(values.poid)
        }
        catch (e) {
            var paramPoID = null
        }

        this.props.userWarehouseLocationsGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transProjectGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transPoGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, paramPoID, null, null, null, null, null, null, null);
        this.props.transLoadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, paramPoID, null, null, null, null, null, null, null, null, null)
        this.props.transLoadPoHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPoID, null, this.props.warehouseSelection, this.props.customerSelection)
        this.props.transContainerJsonTreeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, paramPoID, null, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transCustomerRefGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection);
        this.props.transPoHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection, paramPoID, null)
        this.props.transPoLocationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, paramPoID, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)

    }



    render() {

        return (

            <PoDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isWarehouseLocationsLoading={this.props.isWarehouseLocationsLoading}
                WarehouseLocationsData={this.props.WarehouseLocationsData}

                isPoLoading={this.props.isPoLoading}
                PoData={this.props.PoData}

                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}


                transLoadRemovePost={this.props.transLoadRemovePost}
                LoadRemovePostSuccessful={this.props.LoadRemovePostSuccessful}

                isLoadPoHistoryLoading={this.props.isLoadPoHistoryLoading}
                LoadPoHistoryData={this.props.LoadPoHistoryData}

                isContainerJsonTreeLoading={this.props.isContainerJsonTreeLoading}
                ContainerJsonTreeData={this.props.ContainerJsonTreeData}

                isTransCustomerRefLoading={this.props.isTransCustomerRefLoading}
                TransCustomerRefData={this.props.TransCustomerRefData}

                transPoPost={this.props.transPoPost}
                PoPostSuccessful={this.props.PoPostSuccessful}

                isPoHistoryLoading={this.props.isPoHistoryLoading}
                PoHistoryData={this.props.PoHistoryData}

                isPoLocationLoading={this.props.isPoLocationLoading}
                PoLocationData={this.props.PoLocationData}

                transPoLocationPost={this.props.transPoLocationPost}

                transPoLoadLocationHistoryGet={this.props.transPoLoadLocationHistoryGet}
                isPoLoadLocationHistoryLoading={this.props.isPoLoadLocationHistoryLoading}
                PoLoadLocationHistoryData={this.props.PoLoadLocationHistoryData}

                isTransProjectLoading={this.props.isTransProjectLoading}
                TransProjectData={this.props.TransProjectData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PoDetailsContainer));



