import React, {
    PureComponent
} from 'react';

import { withRouter } from 'react-router-dom';
import LoadDataElements from '../../dataComponentsXdock/LoadDataElements';
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import { Loader, Button, Row, Col, Panel } from 'rsuite';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import LoadExportModal from '../LoadExport/LoadExportModal';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import { loadStatus } from '../../../app/appConfig';
import queryString from 'query-string';
import { queryStringParse } from '../../../resources/logic/functions';

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "LoadID"

const mountRef = React.createRef();

class Load extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            // Table Check Box
            checkedKeys: [],

            //Search Values
            // searchMaster: '',
            searchStatus: null,
            // searchCarrier: null,
            // searchMinETADischargeStart: null,
            // searchMinETADischargeEnd: null,

            isFilterShown: true,

            //Export Modal
            isModalShown: false,
            loadDataExportModal: {}

        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        // this.onChangeSearchMaster = this.onChangeSearchMaster.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Export Modal
        this.onClickExportLoad = this.onClickExportLoad.bind(this);
        this.handleCloseExportModal = this.handleCloseExportModal.bind(this);

    }


    componentDidMount() {
        this.loadSortFromStorage()
        const values = queryString.parse(this.props.location.search)
        // var defaultWarehouse = null
        // try {
        //     var defaultWarehouse = queryStringParse(values.warehouseid)
        // }
        // catch (e) {
        //     var defaultWarehouse = null
        // }
        // this.onChangeWarehouseSelection(defaultWarehouse)


        try {
            var paramLoadStatus = queryStringParse(values.loadstatus)
        }
        catch (e) {
            var paramLoadStatus = null
        }
        this.onChangeSearchStatus(paramLoadStatus)


    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.UserWarehouseData !== this.props.UserWarehouseData && this.props.UserWarehouseData.length > 0) {
    //         var defaultWarehouse = null
    //         try {
    //             if (this.props.UserWarehouseData.length == 1) {
    //                 defaultWarehouse = this.props.UserWarehouseData[0]['value']
    //             }
    //             else {
    //                 defaultWarehouse = null
    //             }
    //         }
    //         catch (e) {
    //         }
    //         this.onChangeWarehouseSelection(defaultWarehouse)

    //         try {
    //             const values = queryString.parse(this.props.location.search)
    //             var defaultWarehouse = null
    //             try {
    //                 var defaultWarehouse = queryStringParse(values.warehouseid)
    //             }
    //             catch (e) {
    //                 var defaultWarehouse = null
    //             }
    //             this.onChangeWarehouseSelection(defaultWarehouse)
    //         }
    //         catch (e) { }

    //     }
    // }



    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            
            this.handleSortColumn(sortColumn, sortType)
        }
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }




    //Search Functions
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });

        // var defaultWarehouse = null
        // try {
        //     if (this.props.UserWarehouseData.length == 1) {
        //         defaultWarehouse = this.props.UserWarehouseData[0]['value']
        //     }
        //     else {
        //         defaultWarehouse = null
        //     }
        // }
        // catch (e) {
        // }


        this.setState({
            // searchMaster: '',
            searchStatus: 1,
            // searchCarrier: null,
            // searchMinETADischargeStart: null,
            // searchMinETADischargeEnd: null,
            // warehouseSelection: defaultWarehouse

        });
        this.props.history.push({
            search:
                'loadstatus=1'
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        // this.setState({
        //     searchMaster: ''
        // });


        try {
            var searchStatus = this.state.searchStatus
            var searchCarrier = this.state.searchCarrier
            this.props.history.push({
                search:
                    'loadstatus=' + searchStatus

                // 'warehouseid=' + this.state.warehouseSelection
                // 'carrier=' + searchCarrier + '&' +

            });
        }
        catch (e) { }
    }

    // onChangeSearchMaster(e) {
    //     this.setState({
    //         searchMaster: e
    //     });
    // }
    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }





    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'loaddetails',
                search: 'loadid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    onClickDepartLoad(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            if (this.state.checkedKeys.length > 0) {
                if (window.confirm('Depart Load?')) {
                    var buildLoadArray = {};
                    buildLoadArray = this.props.LoadData.filter(x => this.state.checkedKeys.includes(x.LoadID))
                    var missingDataBool = false;

                    for (let k in buildLoadArray) {

                        // buildLoadArray[k]['NewRecoveredPCS'] = buildLoadArray[k]['RecoveredPCS'] - buildLoadArray[k]['LoadedPCS']
                        // buildLoadArray[k]['NewPallets'] = buildLoadArray[k]['Pallets'] - buildLoadArray[k]['LoadedPallets']

                        // buildLoadArray[k]['NewRecoveredPCS'] = 0
                        // buildLoadArray[k]['NewPallets'] = 0



                        if (buildLoadArray[k].Trailer === null || buildLoadArray[k].Trailer == 'null') {
                            // window.alert('Please enter Trailer.')
                            missingDataBool = true
                        }
                        else if (buildLoadArray[k].Carrier === null || buildLoadArray[k].Carrier == 'null') {
                            // window.alert('Please enter Carrier.')
                            missingDataBool = true
                        }
                        else if (buildLoadArray[k].BOL === null || buildLoadArray[k].BOL == 'null') {
                            // window.alert('Please enter BOL.')
                            missingDataBool = true
                        }
                        else if (buildLoadArray[k].Seal === null || buildLoadArray[k].Seal == 'Seal') {
                            // window.alert('Please enter Seal.')
                            missingDataBool = true
                        }
                        else if (buildLoadArray[k].CurrentLoadStatusID == 1) {
                            this.props.loadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, buildLoadArray[k].LoadID, null, null, null, null, null, null, null, null, 2, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null)
                        }
                        else {
                        }

                    }

                    if (missingDataBool) {
                        window.alert('Remaining selected loads missing data.')
                    }


                }
            }
            else {
                window.alert('Please select rows.')
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }


    //Export Modal
    onClickExportLoad(rowData, keyColumn) {
        this.props.loadHawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData["LoadID"], null, null, this.props.warehouseSelection, this.props.customerSelection)
        this.setState({
            loadDataExportModal: rowData,
            isModalShown: true
        });

    }
    handleCloseExportModal() {
        this.setState({
            isModalShown: false
        });
    }



    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;




        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        // let warehouseSelectionDiv;
        // if (this.props.isUserWarehouseLoading) {
        //     warehouseSelectionDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else {

        //     warehouseSelectionDiv =
        //         <Row>
        //             {RSelectPicker(
        //                 this.props.isMobile,
        //                 this.props.UserWarehouseData,
        //                 this.state.warehouseSelection,
        //                 'Warehouse',
        //                 this.onChangeWarehouseSelection,
        //                 this.props.history
        //             )}
        //         </Row>


        // }

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>



                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                loadStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>


                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }




        let loadDiv;
        let rTable;
        if (this.props.isLoadLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadData) !== 'undefined' && this.props.LoadData != null) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramStatusID = queryStringParse(values.loadstatus)
            }
            catch (e) {
                var paramStatusID = null
            }
            rTable = RTable(
                this.props.LoadData,        // tableData,
                LoadDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isLoadLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                (paramStatusID == 1 || paramStatusID == null ? true : false), // checkColEntry,
                // true,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                checkedKeys,                       // checkedKeys,
                this.handleCheckAll,                       // handleCheckAll,
                this.handleCheck,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey
                true,                            // editLink,
                'Manifest',                            // editLinkText,
                this.onClickExportLoad                            // editLinkOnClick
            )

            loadDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>

                            {paramStatusID == 1 || paramStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> : ''}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>


                                {/* <ExcelFile element={<Row><Button style={{ float: 'left' }} onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Selected Load</Button> <Button style={{ float: 'right' }}>Export Excel</Button></Row>} filename={'Load_' + currentDateTime}> */}
                                <ExcelSheet data={this.props.LoadData} name="Load">

                                    {
                                        LoadDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }


        return (
            <div>
                <h3>
                    Airfreight - Load
                </h3>


                {hideShowSearch}
                {searchDiv}


                {loadDiv}
                {rTable}


                <LoadExportModal {...this.props} state={this.state}
                    isModalShown={this.state.isModalShown}
                    handleClose={this.handleCloseExportModal}
                />

            </div>
        );

    }
}

export default withRouter(Load);

