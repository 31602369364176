import React, {
    PureComponent
} from 'react';
import { Input, Modal, Button, Row, Col, FormGroup, InputGroup, InputNumber, Panel, ControlLabel, FormControl, DatePicker, Form, InputPicker } from 'rsuite';
import { lettersNumbersDashesInputMask, zipCodeInputMask, numbersInputMask, lettersNumbersInputMask, numbersDashInputMask, emailInputMask, hundredthsDecimalInputMask, textStringInputMask } from '../../../resources/logic/inputMasks';
import { printFormats } from '../../../app/appConfig';
import CustomInputPicker from '../../componentsGeneral/Rsuite/InputPicker/CustomInputPicker';

const styles = {
    marginBottom: 10,
    width: '100%'
};




function FuncLabelGenPrintOptions({
    handleChange,
    state, ...props

}) {
    return (


        <Panel header={'Options'} bordered style={{ height: '140px' }} >
            <FormGroup >
                <InputGroup style={styles}>
                    <InputGroup.Addon>Print Format (Required):</InputGroup.Addon>
                    <CustomInputPicker
                        selectionValue={state.LabelSpec_LabelImageFormat_Code}
                        onChange={handleChange}
                        menuData={printFormats}
                        stateName={'LabelSpec_LabelImageFormat_Code'}
                        color={'#FFE4E1'}
                        {...props}
                    />
                </InputGroup>
            </FormGroup>
        </Panel>





    );
}


export default FuncLabelGenPrintOptions;





