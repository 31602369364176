import React, {
    PureComponent
} from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, Loader, Button } from 'rsuite';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, queryStringParseWithZero, formatStringForAPI, formatNumberForAPI, formatDateTimeForUrlSearch, } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { ctrlTwrShipmentStatus } from '../../../app/appConfig';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import CtrlTwrLoadTenderDataElements from '../../dataComponentsControlTower/CtrlTwrLoadTenderDataElements';
import LoadTenderResponseModal from './LoadTenderResponseModal';
import { edi990QueueStatus } from '../../../app/appConfig';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const tableIdCol = "ID"

const mountRef = React.createRef();

class LoadTender extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Warehouse & Customer Arrays
            warehouseArray: {},
            customerArray: {},


            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchPro: '',
            searchPO: '',
            // searchCustomer: '',
            searchCountry: '',
            searchStatus: '',
            isFilterShown: true,

            //Load Tender Modal
            loadTenderResponseModalShown: false,
            loadTenderModalAcceptDecline: null,
            loadTenderID: null,
            loadTenderNumber: null,
            loadTenderHdrId204: null,
            loadTenderN902Pro: null,

        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);


        //Search Values
        this.onChangeSearchPro = this.onChangeSearchPro.bind(this);
        this.onChangeSearchCustomer = this.onChangeSearchCustomer.bind(this);
        this.onChangeSearchCountry = this.onChangeSearchCountry.bind(this);
        this.onChangeSearchPO = this.onChangeSearchPO.bind(this);
        this.onChangeSearchStatus = this.onChangeSearchStatus.bind(this);


        //Load Tender Actions
        this.onClickTableLoadTender = this.onClickTableLoadTender.bind(this);


        //Load Tender Modal
        this.handleSaveLoadTenderModal = this.handleSaveLoadTenderModal.bind(this);
        this.handleCloseLoadTenderModal = this.handleCloseLoadTenderModal.bind(this);
        this.onChangeLoadTenderResponseModal = this.onChangeLoadTenderResponseModal.bind(this);

    }



    componentDidMount() {
        this.getsessionStorageWarehouse() //this will preserve menu selection

        this.loadSortFromStorage()
        this.updateParamsFromURL()

    }

    getsessionStorageWarehouse() {

        try {
            let warehouseMenuList = JSON.parse(sessionStorage.getItem('AppConfig.WarehouseArray'));
            if (warehouseMenuList != null && warehouseMenuList != undefined) {
                this.setState({
                    warehouseArray: warehouseMenuList
                })
            }
        }
        catch (e) { }

        try {
            let customerMenuList = JSON.parse(sessionStorage.getItem('AppConfig.CustomerArray'));
            if (customerMenuList != null && customerMenuList != undefined) {
                this.setState({
                    customerArray: customerMenuList
                })
            }
        }
        catch (e) { }

    }

    updateParamsFromURL = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramPro = queryStringParse(values.pro)
        }
        catch (e) {
            var paramPro = null
        }
        if (paramPro != null) {
            this.onChangeSearchPro(paramPro)
        }

        try {
            var paramPO = queryStringParse(values.po)
        }
        catch (e) {
            var paramPO = null
        }
        if (paramPO != null) {
            this.onChangeSearchPO(paramPO)
        }

        try {
            var paramCustomer = queryStringParse(values.customer)
        }
        catch (e) {
            var paramCustomer = null
        }
        if (paramCustomer != null) {
            this.onChangeSearchCustomer(paramCustomer)
        }

        try {
            var paramCountry = queryStringParse(values.country)
        }
        catch (e) {
            var paramCountry = null
        }
        if (paramCountry != null) {
            this.onChangeSearchCountry(paramCountry)
        }


        try {
            var paramStatus = queryStringParseWithZero(values.status)
        }
        catch (e) {
            var paramStatus = null
        }
        if (paramStatus != null) {
            this.onChangeSearchStatus(paramStatus)
        }

    }




    componentDidUpdate(prevProps, prevState) {

        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }




    }




    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const { checkedKeys } = this.state;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {
            this.handleSortColumn(sortColumn, sortType)
        }
    }
    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });
        this.saveSortTosessionStorage(sortColumn, sortType)
        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }

    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }

    onClickResetFilters(e) {
        this.setState({
            isFilterShown: false
        });
        this.setState({
            searchPro: '',
            // searchCustomer: null,
            searchCountry: null,
            searchPO: '',
            searchStatus: null,

        });
        this.props.history.push({
            search: ''
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);
    }

    onClickApplySearch(e) {
        this.setState({
            searchPro: '',
            searchPO: '',
        });


        // try {
        //     var searchCustomer = this.state.searchCustomer
        // }
        // catch (e) {
        //     var searchCustomer = null
        // }

        try {
            var searchCountry = this.state.searchCountry
        }
        catch (e) {
            var searchCountry = null
        }

        try {
            var searchStatus = this.state.searchStatus
        }
        catch (e) {
            var searchStatus = null
        }


        try {
            var searchStatus = this.state.searchStatus
            this.props.history.push({
                search:
                    // 'customer=' + searchCustomer + '&' +
                    // 'country=' + searchCountry + '&' +
                    'status=' + searchStatus
            });
        }
        catch (e) { }

    }

    onSearchClearOtherFilters() {
        this.setState({
            searchStatus: null,
            // searchCustomer: null,
            searchCountry: null,

        });
    }

    onChangeSearchPro(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchPO: '',
            searchPro: e,
            // searchCustomer: null,
            searchCountry: null,
            searchStatus: null,
        });
    }

    onChangeSearchPO(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchPO: e,
            searchPro: '',
            // searchCustomer: null,
            searchCountry: null,
            searchStatus: null,

        });
    }

    onChangeSearchCustomer(e) {
        this.setState({
            // searchCustomer: e,
        });
    }
    onChangeSearchCountry(e) {
        this.setState({
            searchCountry: e,
        });
    }
    onChangeSearchStatus(e) {
        this.setState({
            searchStatus: e
        });
    }


    //Table on click details
    onClickLoadTenderDetails(e, rowData, history) {
        try {
            var paramID = rowData["HdrId204"]
            history.push({
                pathname: 'loadtenderdetails',
                search: 'id=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }



    //Table on click Load Tender 
    onClickTableLoadTender(e, rowData) {
        var loadTenderID = rowData['ID']
        var loadTenderNumber = rowData['B102']
        var loadTenderHdrId204 = rowData['HdrId204']
        var loadTenderN902Pro = rowData['N902']


        this.setState({
            loadTenderResponseModalShown: true,
            loadTenderID: loadTenderID,
            loadTenderNumber: loadTenderNumber,
            loadTenderHdrId204: loadTenderHdrId204,
            loadTenderN902Pro: loadTenderN902Pro
        });
    }



    //Load Tender Modal
    onChangeLoadTenderResponseModal(e, label) {
        if (label === 'loadTenderModalAcceptDecline') {
            this.setState({
                loadTenderModalAcceptDecline: e
            });
        }
    }


    handleCloseLoadTenderModal() {
        this.setState({
            loadTenderResponseModalShown: false,
            loadTenderModalAcceptDecline: null,
            loadTenderID: null,
            loadTenderNumber: null,
            loadTenderHdrId204: null,
            loadTenderN902Pro: null,

        });
    }

    handleSaveLoadTenderModal() {

        if (this.state.loadTenderModalAcceptDecline === null || this.state.loadTenderModalAcceptDecline === '') {
            window.alert('Please select a response.')
        }
        else {
            this.props.edi990QueuePost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                this.props.customerSelection,

                formatNumberForAPI(this.state.loadTenderModalAcceptDecline),
                formatNumberForAPI(this.state.loadTenderID),
                formatNumberForAPI(this.state.loadTenderHdrId204),
                null,
                formatNumberForAPI(this.state.loadTenderN902Pro),
                null,
                'Q'
            )

            this.handleCloseLoadTenderModal()

        }
    }




    render() {

        const { checkedKeys, sortColumn, sortType } = this.state;
        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    {/* <Panel bordered style={{ marginBottom: '10px' }}> */}
                        {/* <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PRO #',
                                this.onChangeSearchPro,
                                this.props.history,
                                'pro',
                                this.state.searchPro
                            )}
                        </Row> */}

                        {/* <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Shipment Identification Number',
                                this.onChangeSearchPO,
                                this.props.history,
                                'po',
                                this.state.searchPO
                            )}
                        </Row> */}
                    {/* </Panel> */}

                    <Panel bordered style={{ marginBottom: '10px' }}>

                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                edi990QueueStatus,
                                this.state.searchStatus,
                                'Status',
                                this.onChangeSearchStatus,
                                this.props.history,
                                mountRef
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isEdi990QueueLoading) {

            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.Edi990QueueData) !== 'undefined' && this.props.Edi990QueueData != null) {
            rTable = RTableGeneral(
                tableIdCol,     //   keyColumn,
                this.props.Edi990QueueData,     //   tableData,
                CtrlTwrLoadTenderDataElements,       //   DataElements,
                this.props.isEdi990QueueLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   onClickAddMilestone,
                this.onClickTableLoadTender,       //   onClickSelect,
                'Respond',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Details',     //   link1text,
                this.onClickLoadTenderDetails,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick


            )


            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'LoadTender_' + currentDateTime}>
                                <ExcelSheet data={this.props.Edi990QueueData} name="LoadTender">
                                    {
                                        CtrlTwrLoadTenderDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }

            </div>
        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }




        return (
            <div>
                <h3>
                    Control Tower - Load Tender
                </h3>
                {hideShowSearch}
                {searchDiv}

                {mawbDiv}
                {rTable}


                <LoadTenderResponseModal {...this.props} state={this.state} loadTenderResponseModalShown={this.state.loadTenderResponseModalShown} onChangeLoadTenderResponseModal={this.onChangeLoadTenderResponseModal} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseLoadTenderModal} handleSave={this.handleSaveLoadTenderModal} />



            </div>
        );

    }
}

export default withRouter(LoadTender);