export const CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements = [
    // {
    //     dBKey: 'ProRef',
    //     displayName: 'PRO',
    //     primaryCol: false,
    //     mobileDisplay: true,
    //     sortable: false,
    //     colWidth: 150
    // },

    // {
    //     dBKey: 'ModeName',
    //     displayName: 'Mode',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: false,
    //     colWidth: 150
    // },
    {
        dBKey: 'label',
        displayName: 'Milestone',
        primaryCol: false,
        mobileDisplay: false,
        sortable: false,
        colWidth: 400
    },

    

]


export default CtrlTwrShipmentLegsMissingRequiredMilestonesDataElements

