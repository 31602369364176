export const InventorySkuWarehouseLocationDataElements = [
    {
        dBKey: 'WarehouseLocationName',
        displayName: 'Warehouse Location',
        primaryCol: true,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },

    {
        dBKey: 'SKU',
        displayName: 'SKU',
        primaryCol: false,
        mobileDisplay: true,
        sortable: true,
        colWidth: 200
    },
    // {
    //     dBKey: 'SkuStatusName',
    //     displayName: 'Status',
    //     primaryCol: false,
    //     mobileDisplay: false,
    //     sortable: true,
    //     colWidth: 180
    // },





    
    {
        dBKey: 'Units',
        displayName: 'Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'Cases',
        displayName: 'Cases',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    {
        dBKey: 'Pallets',
        displayName: 'Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    
    {
        dBKey: 'Oversize',
        displayName: 'Oversize',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 140
    },
    


]


export default InventorySkuWarehouseLocationDataElements

