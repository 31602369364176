import { connect } from 'react-redux';
import React, { Component } from 'react';
import MasterDetails from './MasterDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI } from '../../../resources/logic/functions';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';

const mapStateToProps = store =>
({

    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isMawbLoading: store.vasReducer.isMawbLoading,
    MawbData: store.vasReducer.MawbData,

    isMawbStatusLoading: store.vasReducer.isMawbStatusLoading,
    MawbStatusData: store.vasReducer.MawbStatusData,


    MawbStatusPostSuccessful: store.vasReducer.MawbStatusPostSuccessful,

    MawbEvolveDataSaveSuccessful: store.vasReducer.MawbEvolveDataSaveSuccessful,

    isHawbLoading: store.vasReducer.isHawbLoading,
    HawbData: store.vasReducer.HawbData,

    HawbRecoverySaveSuccessful: store.vasReducer.HawbRecoverySaveSuccessful,

    isLoadLoading: store.vasReducer.isLoadLoading,
    LoadData: store.vasReducer.LoadData,

    isHawbConsigneeLoading: store.vasReducer.isHawbConsigneeLoading,
    HawbConsigneeData: store.vasReducer.HawbConsigneeData,

    PostHawbSuccessful: store.vasReducer.PostHawbSuccessful,

    isGetCustomsCityAPILoading: store.vasReducer.isGetCustomsCityAPILoading,
    GetCustomsCityAPISuccessful: store.vasReducer.GetCustomsCityAPISuccessful,
    isCustomsCityMessagesLoading: store.vasReducer.isCustomsCityMessagesLoading,
    CustomsCityMessagesData: store.vasReducer.CustomsCityMessagesData,


    isMawbRecoveryLoading: store.vasReducer.isMawbRecoveryLoading,
    MawbRecoveryData: store.vasReducer.MawbRecoveryData,
    // isMawbRecoverySummaryLoading: store.vasReducer.isMawbRecoverySummaryLoading,
    // MawbRecoverySummaryData: store.vasReducer.MawbRecoverySummaryData,

    MawbRecoverySaveSuccessful: store.vasReducer.MawbRecoverySaveSuccessful,

    HawbRecoveredShortPostSuccessful: store.vasReducer.HawbRecoveredShortPostSuccessful,

    isXdockMawbFileUploadLoading: store.vasReducer.isXdockMawbFileUploadLoading,
    XdockMawbFileUploadData: store.vasReducer.XdockMawbFileUploadData,

    XdockMawbFileUploadPostSuccessful: store.vasReducer.XdockMawbFileUploadPostSuccessful,


    isXdockCustomsCityManifestHistoryLoading: store.vasReducer.isXdockCustomsCityManifestHistoryLoading,
    XdockCustomsCityManifestHistoryData: store.vasReducer.XdockCustomsCityManifestHistoryData,
});

const mapDispatchToProps = dispatch =>
({

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },

    mawbGet: (_email, _authToken, _MasterID, _Master, _MinETADischargeStart, _MinETADischargeEnd, _CarrierName, _MawbStatusID, _WarehouseID, _OrderNo, _PlannedPickupStart, _PlannedPickupEnd, _AssignedDriver, _MawbColorCode, _CustomerID) => {
        dispatch(actions.mawbGet(_email, _authToken, _MasterID, _Master, _MinETADischargeStart, _MinETADischargeEnd, _CarrierName, _MawbStatusID, _WarehouseID, _OrderNo, _PlannedPickupStart, _PlannedPickupEnd, _AssignedDriver, _MawbColorCode, _CustomerID))
    },
    mawbStatusGet: (_email, _authToken, _MasterID, _Master, _WarehouseID, _CustomerID) => {
        dispatch(actions.mawbStatusGet(_email, _authToken, _MasterID, _Master, _WarehouseID, _CustomerID))
    },
    mawbStatusPost: (_email, _authToken, _MasterID, _Master, _MawbStatusID, _MawbStatusName, _WarehouseID, _CustomerID) => {
        dispatch(actions.mawbStatusPost(_email, _authToken, _MasterID, _Master, _MawbStatusID, _MawbStatusName, _WarehouseID, _CustomerID))
    },
    hawbGet: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID) => {
        dispatch(actions.hawbGet(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID))
    },
    hawbRecoveryPost: (_email, _authToken, _MasterID, _HouseID, _HouseRef, _RecoveredPCS, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbRecoveryPost(_email, _authToken, _MasterID, _HouseID, _HouseRef, _RecoveredPCS, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID))
    },
    loadGet: (_email, _authToken, _LoadID, _HouseID, _MasterID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _LoadStatusID, _Carrier, _BOL, _Trailer, _Seal, _ConsigneeID, _DestinationID, _WarehouseID, _CustomerID) => {
        dispatch(actions.loadGet(_email, _authToken, _LoadID, _HouseID, _MasterID, _PickupDateStart, _PickupDateEnd, _DeliveryDateStart, _DeliveryDateEnd, _LoadStatusID, _Carrier, _BOL, _Trailer, _Seal, _ConsigneeID, _DestinationID, _WarehouseID, _CustomerID))
    },
    mawbEvolveDataEntryPost: (_email, _authToken, _MasterID, _Master, _No, _PMC, _PickupBy, _Trips, _ArrivalTime, _LFD, _ArrivalAtWarehouse, _DeliveryTime, _Notes, _Recovered, _WarehouseID, _CustomerID) => {
        dispatch(actions.mawbEvolveDataEntryPost(_email, _authToken, _MasterID, _Master, _No, _PMC, _PickupBy, _Trips, _ArrivalTime, _LFD, _ArrivalAtWarehouse, _DeliveryTime, _Notes, _Recovered, _WarehouseID, _CustomerID))
    },
    hawbConsigneeGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbConsigneeGet(_email, _authToken, _WarehouseID, _CustomerID))
    },
    hawbPost: (_email, _authToken, _MasterID, _HouseRef, _ConsigneeName, _ConsigneeID, _Notes, _Origin, _HAWBDestination, _LastDischargePort, _DeliverToAddress, _Orders, _ShipmentETD, _EstCartageDelivery, _ETADischarge, _ActualCartageDelivery, _Cartons, _NewPallets, _Weight, _Chargeable, _Volume, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbPost(_email, _authToken, _MasterID, _HouseRef, _ConsigneeName, _ConsigneeID, _Notes, _Origin, _HAWBDestination, _LastDischargePort, _DeliverToAddress, _Orders, _ShipmentETD, _EstCartageDelivery, _ETADischarge, _ActualCartageDelivery, _Cartons, _NewPallets, _Weight, _Chargeable, _Volume, _WarehouseID, _CustomerID))
    },
    customsCityMessageGet: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master) => {
        dispatch(actions.customsCityMessageGet(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master))
    },

    customsCityAPIMessages: (_email, _authToken, _paramHouse, _paramMaster, _getOnly) => {
        dispatch(actions.customsCityAPIMessages(_email, _authToken, _paramHouse, _paramMaster, _getOnly))
    },

    mawbRecoveryGet: (_email, _authToken, _MasterID, _WarehouseID, _CustomerID) => {
        dispatch(actions.mawbRecoveryGet(_email, _authToken, _MasterID, _WarehouseID, _CustomerID))
    },
    // mawbRecoverySummaryGet:( _email, _authToken, _MasterID ) =>
    // {
    //     dispatch( actions.mawbRecoverySummaryGet( _email, _authToken, _MasterID ) )
    // },
    mawbRecoveryPost: (_email, _authToken, _MasterID, _PlannedPickupDT, _DriverAssigned, _Note, _TripMade, _FreightRecovered, _PickupDT, _CartonsRecovered, _PickedUpBy, _Carrier, _LFD, _PMC, _WarehouseID, _CustomerID) => {
        dispatch(actions.mawbRecoveryPost(_email, _authToken, _MasterID, _PlannedPickupDT, _DriverAssigned, _Note, _TripMade, _FreightRecovered, _PickupDT, _CartonsRecovered, _PickedUpBy, _Carrier, _LFD, _PMC, _WarehouseID, _CustomerID))
    },

    hawbRecoveredShortPost: (_email, _authToken, _MasterID, _TotalPCS, _RecoveredPCS, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbRecoveredShortPost(_email, _authToken, _MasterID, _TotalPCS, _RecoveredPCS, _WarehouseID, _CustomerID))
    },

    xDockMawbFileUploadGet: (_email, _authToken, _CustomerID, _FileUploadID, _MasterID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(actions.xDockMawbFileUploadGet(_email, _authToken, _CustomerID, _FileUploadID, _MasterID, _FileName, _S3FileName, _FileType, _FileDelete))
    },
    xDockMawbFileUploadPost: (_email, _authToken, _CustomerID, _FileUploadID, _MasterID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(actions.xDockMawbFileUploadPost(_email, _authToken, _CustomerID, _FileUploadID, _MasterID, _FileName, _S3FileName, _FileType, _FileDelete))
    },


    xDockCustomsCityManifestHistoryGet: (_email, _authToken, _CCManifestPostID, _MasterID, _BOLNumber, _HouseID, _houseBOLNumber) => {
        dispatch(actions.xDockCustomsCityManifestHistoryGet(_email, _authToken, _CCManifestPostID, _MasterID, _BOLNumber, _HouseID, _houseBOLNumber))
    },
});

class MasterDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.HawbRecoverySaveSuccessful !== this.props.HawbRecoverySaveSuccessful && this.props.HawbRecoverySaveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.MawbStatusPostSuccessful !== this.props.MawbStatusPostSuccessful && this.props.MawbStatusPostSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.MawbEvolveDataSaveSuccessful !== this.props.MawbEvolveDataSaveSuccessful && this.props.MawbEvolveDataSaveSuccessful) {
            this.handleUrlParam();
        }
        if (prevProps.PostHawbSuccessful !== this.props.PostHawbSuccessful && this.props.PostHawbSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }



        if (prevProps.GetCustomsCityAPISuccessful !== this.props.GetCustomsCityAPISuccessful && this.props.GetCustomsCityAPISuccessful) {
            setTimeout(() => {
                const values = queryString.parse(this.props.location.search)
                try {
                    var paramMasterID = queryStringParse(values.masterid)
                }
                catch (e) {
                    var paramMasterID = null
                }
                this.props.customsCityMessageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramMasterID, null)

                this.props.xDockCustomsCityManifestHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramMasterID, null, null, null)
                
                this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken,
                    null,
                    null,
                    paramMasterID,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    this.props.warehouseSelection,
                    null,
                    null,
                    this.props.customerSelection
                );

            }, 1000);
        }
        else {
        }

        if (prevProps.MawbRecoverySaveSuccessful !== this.props.MawbRecoverySaveSuccessful && this.props.MawbRecoverySaveSuccessful) {
            setTimeout(() => {
                const values = queryString.parse(this.props.location.search)
                try {
                    var paramMasterID = queryStringParse(values.masterid)
                }
                catch (e) {
                    var paramMasterID = null
                }
                this.props.mawbRecoveryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramMasterID, this.props.warehouseSelection, this.props.customerSelection)
                this.props.mawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramMasterID, null, null, null, null, null, this.props.warehouseSelection, null, null, null, null, null, this.props.customerSelection);
            }, 1000);
        }
        else {
        }

        if (prevProps.XdockMawbFileUploadPostSuccessful !== this.props.XdockMawbFileUploadPostSuccessful && this.props.XdockMawbFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramMasterID = queryStringParse(values.masterid)
            }
            catch (e) {
                var paramMasterID = null
            }
            this.props.xDockMawbFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramMasterID, null, null, null, 0)
        }

    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramMasterID = queryStringParse(values.masterid)
        }
        catch (e) {
            var paramMasterID = null
        }
        try {
            var paramMaster = formatStringForAPI(values.master)
        }
        catch (e) {
            var paramMaster = null
        }
        this.props.hawbConsigneeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)

        this.props.mawbRecoveryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramMasterID, this.props.warehouseSelection, this.props.customerSelection)
        // this.props.mawbRecoverySummaryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramMasterID)

        this.props.mawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramMasterID, paramMaster, null, null, null, null, this.props.warehouseSelection, null, null, null, null, null, this.props.customerSelection);

        this.props.mawbStatusGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramMasterID, paramMaster, this.props.warehouseSelection, this.props.customerSelection);

        this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken,
            null,
            null,
            paramMasterID,
            paramMaster,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.props.warehouseSelection,
            null,
            null,
            this.props.customerSelection
        );

        this.props.loadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramMasterID, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)


        this.props.customsCityMessageGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, paramMasterID, paramMaster)


        this.props.xDockMawbFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramMasterID, null, null, null, 0)


        this.props.xDockCustomsCityManifestHistoryGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramMasterID, null, null, null)
    }



    render() {

        return (

            <MasterDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}


                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}

                isMawbLoading={this.props.isMawbLoading}
                MawbData={this.props.MawbData}

                isMawbStatusLoading={this.props.isMawbStatusLoading}
                MawbStatusData={this.props.MawbStatusData}

                mawbStatusPost={this.props.mawbStatusPost}
                MawbStatusPostSuccessful={this.props.MawbStatusPostSuccessful}

                isHawbLoading={this.props.isHawbLoading}
                HawbData={this.props.HawbData}

                hawbRecoveryPost={this.props.hawbRecoveryPost}
                HawbRecoverySaveSuccessful={this.props.HawbRecoverySaveSuccessful}

                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}

                MawbEvolveDataSaveSuccessful={this.props.MawbEvolveDataSaveSuccessful}
                mawbEvolveDataEntryPost={this.props.mawbEvolveDataEntryPost}

                isHawbConsigneeLoading={this.props.isHawbConsigneeLoading}
                HawbConsigneeData={this.props.HawbConsigneeData}

                hawbPost={this.props.hawbPost}
                PostHawbSuccessful={this.props.PostHawbSuccessful}


                isCustomsCityMessagesLoading={this.props.isCustomsCityMessagesLoading}
                CustomsCityMessagesData={this.props.CustomsCityMessagesData}

                isGetCustomsCityAPILoading={this.props.isGetCustomsCityAPILoading}

                customsCityAPIMessages={this.props.customsCityAPIMessages}


                isMawbRecoveryLoading={this.props.isMawbRecoveryLoading}
                MawbRecoveryData={this.props.MawbRecoveryData}
                // isMawbRecoverySummaryLoading={this.props.isMawbRecoverySummaryLoading}
                // MawbRecoverySummaryData={this.props.MawbRecoverySummaryData}


                MawbRecoverySaveSuccessful={this.props.MawbRecoverySaveSuccessful}
                mawbRecoveryPost={this.props.mawbRecoveryPost}

                hawbRecoveredShortPost={this.props.hawbRecoveredShortPost}
                HawbRecoveredShortPostSuccessful={this.props.HawbRecoveredShortPostSuccessful}

                xDockMawbFileUploadGet={this.props.xDockMawbFileUploadGet}
                xDockMawbFileUploadPost={this.props.xDockMawbFileUploadPost}
                isXdockMawbFileUploadLoading={this.props.isXdockMawbFileUploadLoading}
                XdockMawbFileUploadData={this.props.XdockMawbFileUploadData}
                XdockMawbFileUploadPostSuccessful={this.props.XdockMawbFileUploadPostSuccessful}


                xDockCustomsCityManifestHistoryGet={this.props.xDockCustomsCityManifestHistoryGet}
                isXdockCustomsCityManifestHistoryLoading={this.props.isXdockCustomsCityManifestHistoryLoading}
                XdockCustomsCityManifestHistoryData={this.props.XdockCustomsCityManifestHistoryData}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MasterDetailsContainer));



