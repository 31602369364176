import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import MultiMilestoneEventEntryContainer from '../../../components/componentsControlTower/MultiMilestoneEventEntry/MultiMilestoneEventEntryContainer';

class ctrlTwrMultiMilestoneEventEntryView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {

    }

    render() {

        return (
            <AnimatedView>
                <MultiMilestoneEventEntryContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default ctrlTwrMultiMilestoneEventEntryView;
