import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Button,
    ButtonToolbar,
    Form,
    FormGroup,
    ControlLabel,
    InputPicker,
    DatePicker,
    Loader,
    Row,
    InputGroup,
    Panel,
    Input,
    Col,
    InputNumber,
    ButtonGroup,
    Whisper,
    Icon,
    Tooltip,
    Divider
} from 'rsuite';
import { lettersNumbersDashesSpacesInputMask, lettersNumbersDashesInputMask, textStringInputMask, textNumbersSymbolsInputmask, limitCharacterLength, limitUpperCaseLengthMask } from '../../../resources/logic/inputMasks';
import MinusIcon from '@rsuite/icons/Minus';
import CloseIcon from '@rsuite/icons/Close';

// const styles = {
//     width: '100%',
//     marginBottom: '10px',
//     marginTop: '10px'
// };

const tooltipSearch = (
    <Tooltip>
        Multi
    </Tooltip>
);

const tooltipDelete = (
    <Tooltip>
        Delete
    </Tooltip>
);

const styles = {
    marginTop: '5px',
    width: '100%'
};

const inputStyle = {
    width: '100%'
};

const dropDownLclFcl = [
    {
        "label": "PUP",
        "value": "Picked Up"
    },
    {
        "label": "DEP",
        "value": "Departed"
    },
    {
        "label": "DEL",
        "value": "Delivered"
    }
]

const dropDownMode = [
    {
        "label": "Air",
        "value": 1
    },
    {
        "label": "Ocean",
        "value": 2
    },
    {
        "label": "Truck",
        "value": 3
    }
]


// for milestones: 
// event code/description, 
// mode, 
// datetime, 
// select a location, 
// country/city/state/zip (not required though), 
// user, 
// stored at highest level (master, container, BOL)
// airpot iata codes

const mountRef = React.createRef();


const ShipmentManagementDrawer = ({ onChange, placement, confirm, close, masterLabel, onClickDeleteLeg, onClickShowAddLeg, onChangeNewLeg, onClickMulti, onClickDeleteMulti, state, ...props }) => (

    state.showShipManagmentDrawer === false ?
        <div align="center">
        </div>

        :

        props.isCtrlTwrShipmentLegsDataLoading ?

            <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>

            :


            <Drawer show={state.showShipManagmentDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
                <Drawer.Header>
                    <Drawer.Title style={{ marginBottom: '15px' }}>PRO: {masterLabel}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body> <div ref={mountRef}>

                    {
                        state.ShipManagementExistingDict.map(legItem => (

                            legItem.ModeID === 1 ? //Ocean
                                <Panel
                                    bordered
                                    style={{ marginBottom: '10px' }}
                                    header={
                                        <Row>
                                            <Col md={16} sm={24}>
                                                <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                            </Col>
                                            <Col md={8} sm={24}>
                                                <ButtonToolbar >
                                                    <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                </ButtonToolbar>
                                            </Col>
                                        </Row>
                                    }
                                >
                                    <FormGroup >
                                        <InputGroup style={styles}>
                                            <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                            <InputPicker preventOverflow
                                                container={() => mountRef.current}
                                                placeholder={'Managing Warehouse...'}
                                                value={legItem['WarehouseID']}
                                                onChange={(e) => { onChange(e, legItem["LegID"], 'WarehouseID') }}
                                                data={state.warehouseArray}
                                                style={inputStyle}
                                                cleanable={true}
                                                placement={'auto'}
                                            />
                                        </InputGroup>


                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Container:</InputGroup.Addon>
                                                <Input value={legItem['Container']} onChange={(e) => { onChange(e, legItem["LegID"], 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                            </InputGroup>
                                        </FormGroup>
                                    </FormGroup>

                                    <Row>
                                        <Col md={12} sm={24}>


                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>MBOL:</InputGroup.Addon>
                                                    <Input value={legItem['MBOL']} onChange={(e) => { onChange(e, legItem["LegID"], 'MBOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Seal:</InputGroup.Addon>
                                                    <Input value={legItem['Seal']} onChange={(e) => { onChange(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>


                                        </Col>
                                        <Col md={12} sm={24}>


                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                                    <Input value={legItem['Carrier']} onChange={(e) => { onChange(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Vessel:</InputGroup.Addon>
                                                    <Input value={legItem['Vessel']} onChange={(e) => { onChange(e, legItem["LegID"], 'Vessel') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={24}>
                                            <FormGroup>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Departure:</InputGroup.Addon>
                                                    <DatePicker
                                                        container={() => mountRef.current}
                                                        placeholder="Sailing Date"
                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                                        value={legItem["PlannedDepartureDate"]}
                                                        format="YYYY-MM-DD"
                                                        showMeridian
                                                        size="sm"
                                                        ranges={[
                                                            {
                                                                label: 'Now',
                                                                value: new Date()
                                                            }
                                                        ]}
                                                        style={styles}
                                                        oneTap
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col md={12} sm={24}>
                                            <FormGroup>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Arrival:</InputGroup.Addon>
                                                    <DatePicker
                                                        container={() => mountRef.current}
                                                        placeholder="Estimated Arrival Date"
                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                                        value={legItem["EstimatedDeliveryDate"]}
                                                        format="YYYY-MM-DD"
                                                        showMeridian
                                                        size="sm"
                                                        ranges={[
                                                            {
                                                                label: 'Now',
                                                                value: new Date()
                                                            }
                                                        ]}
                                                        style={styles}
                                                        oneTap
                                                        placement={'leftStart'}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={24}>
                                            <FormGroup style={{ marginTop: '5px' }}>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Origin:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        container={() => mountRef.current}
                                                        placeholder={'Origin Port...'}
                                                        value={legItem['SeaportOriginID']}
                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'SeaportOriginID') }}
                                                        data={props.CtrlTwrSeaportData}
                                                        block
                                                        cleanable={true}
                                                        style={inputStyle}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col md={12} sm={24}>
                                            <FormGroup style={{ marginTop: '5px' }}>
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Destination:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        container={() => mountRef.current}
                                                        placeholder={'Destination Port...'}
                                                        value={legItem['SeaportDestID']}
                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'SeaportDestID') }}
                                                        data={props.CtrlTwrSeaportData}
                                                        block
                                                        cleanable={true}
                                                        style={inputStyle}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col md={24} sm={24}>
                                            <Panel bordered style={{ marginBottom: 5, marginTop: 5 }} >
                                                <FormGroup>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Panel>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={24} sm={24}>
                                            <Panel bordered style={{ marginBottom: 10 }} >
                                                <FormGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                    </InputGroup>

                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                                        <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Panel>
                                        </Col>
                                    </Row>

                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                    <Panel
                                        bordered
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <Row >
                                            <Col md={8} sm={24}>
                                                <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'HBOL', null) }} appearance="default">Add HBOL</Button>
                                            </Col>
                                            <Col md={8} sm={24}>
                                                <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                            </Col>
                                            <Col md={8} sm={24}>
                                                <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                                            </Col>
                                        </Row>
                                        {
                                            state.ShipManagementCustRefData.map(freeCustItem => (
                                                freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                        <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                                        <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                            <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                                                <CloseIcon />
                                                            </InputGroup.Button>
                                                        </Whisper>

                                                    </InputGroup>
                                                    :
                                                    ''
                                            ))
                                        }
                                        {
                                            state.ShipManagementPoData.map(freePotem => (
                                                freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>PO</InputGroup.Addon>
                                                        <Input readOnly style={styles} value={freePotem["PO"]} />

                                                        <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                            <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                                                <CloseIcon />
                                                            </InputGroup.Button>
                                                        </Whisper>

                                                    </InputGroup>
                                                    :
                                                    ''
                                            ))
                                        }
                                        {
                                            state.ShipManagementHbolData.map(hbolItem => (
                                                hbolItem["LegID"] === legItem["LegID"] ?
                                                    <FormGroup style={{ marginTop: '5px' }}>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>HBOL</InputGroup.Addon>
                                                            <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                                            <InputGroup.Button onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add PO</InputGroup.Button>
                                                            <InputGroup.Button onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add Customer Ref</InputGroup.Button>

                                                            <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'HBOL', hbolItem["HbolID"], null, null) }}  >
                                                                    <CloseIcon />
                                                                </InputGroup.Button>
                                                            </Whisper>

                                                        </InputGroup>
                                                        {
                                                            state.ShipManagementCustRefData.map(custItem => (
                                                                custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>
                                                                            <MinusIcon />
                                                                        </InputGroup.Addon>
                                                                        <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                                        <Input readOnly style={styles} value={custItem["CustomerRef"]} />

                                                                        <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                            <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, custItem["CustomerRefID"]) }}  >
                                                                                <CloseIcon />
                                                                            </InputGroup.Button>
                                                                        </Whisper>


                                                                    </InputGroup>
                                                                    :
                                                                    ''
                                                            ))
                                                        }
                                                        {
                                                            state.ShipManagementPoData.map(poItem => (
                                                                poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>
                                                                            <MinusIcon />
                                                                        </InputGroup.Addon>
                                                                        <InputGroup.Addon>PO</InputGroup.Addon>
                                                                        <Input readOnly style={styles} value={poItem["PO"]} />

                                                                        <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                            <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, poItem["PoID"], null) }}  >
                                                                                <CloseIcon />
                                                                            </InputGroup.Button>
                                                                        </Whisper>


                                                                    </InputGroup>
                                                                    :
                                                                    ''
                                                            ))
                                                        }
                                                    </FormGroup>
                                                    :
                                                    <div></div>
                                            ))
                                        }
                                    </Panel>




                                </Panel>

                                :

                                legItem.ModeID === 2 ? //Air
                                    <Panel
                                        bordered
                                        style={{ marginBottom: '10px' }}
                                        header={
                                            <Row>
                                                <Col md={16} sm={24}>
                                                    <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                </Col>
                                                <Col md={8} sm={24}>
                                                    <ButtonToolbar >
                                                        <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                    </ButtonToolbar>
                                                </Col>
                                            </Row>
                                        }
                                    >
                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                                <InputPicker preventOverflow
                                                    container={() => mountRef.current}
                                                    placeholder={'Managing Warehouse...'}
                                                    value={legItem['WarehouseID']}
                                                    onChange={(e) => { onChange(e, legItem["LegID"], 'WarehouseID') }}
                                                    data={state.warehouseArray}
                                                    style={inputStyle}
                                                    cleanable={true}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup >
                                            <InputGroup style={styles}>
                                                <InputGroup.Addon>MBOL:</InputGroup.Addon>
                                                <Input value={legItem['MBOL']} onChange={(e) => { onChange(e, legItem["LegID"], 'MBOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                            </InputGroup>
                                        </FormGroup>

                                        <Row>

                                            <Col md={12} sm={24}>

                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                                        <Input value={legItem['Carrier']} onChange={(e) => { onChange(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>



                                            </Col>

                                            <Col md={12} sm={24}>
                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Flight:</InputGroup.Addon>
                                                        <Input value={legItem['Flight']} onChange={(e) => { onChange(e, legItem["LegID"], 'Flight') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>

                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={12} sm={24}>
                                                <FormGroup>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>ETD:</InputGroup.Addon>
                                                        <DatePicker
                                                            container={() => mountRef.current}
                                                            placeholder="ETD"
                                                            onChange={(e) => { onChange(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                                            value={legItem['PlannedDepartureDate']}
                                                            format="YYYY-MM-DD hh:mm aa"
                                                            defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                            showMeridian
                                                            size="sm"
                                                            ranges={[
                                                                {
                                                                    label: 'Now',
                                                                    value: new Date()
                                                                }
                                                            ]}
                                                            style={styles}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12} sm={24}>

                                                <FormGroup>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>ETA:</InputGroup.Addon>
                                                        <DatePicker
                                                            container={() => mountRef.current}
                                                            placeholder="ETA"
                                                            onChange={(e) => { onChange(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                                            value={legItem['EstimatedDeliveryDate']}
                                                            format="YYYY-MM-DD hh:mm aa"
                                                            defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                            showMeridian
                                                            size="sm"
                                                            ranges={[
                                                                {
                                                                    label: 'Now',
                                                                    value: new Date()
                                                                }
                                                            ]}
                                                            style={styles}
                                                            placement={'leftStart'}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12} sm={24}>
                                                <FormGroup style={{ marginTop: '5px' }}>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Origin:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            container={() => mountRef.current}
                                                            placeholder={'Origin Port...'}
                                                            value={legItem['AirportOriginID']}
                                                            onChange={(e) => { onChange(e, legItem["LegID"], 'AirportOriginID') }}
                                                            data={props.CtrlTwrAirportData}
                                                            block
                                                            cleanable={true}
                                                            style={inputStyle}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12} sm={24}>
                                                <FormGroup style={{ marginTop: '5px' }}>
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Destination:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            container={() => mountRef.current}
                                                            placeholder={'Destination Port...'}
                                                            value={legItem['AirportDestID']}
                                                            onChange={(e) => { onChange(e, legItem["LegID"], 'AirportDestID') }}
                                                            data={props.CtrlTwrAirportData}
                                                            block
                                                            cleanable={true}
                                                            style={inputStyle}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>



                                        <Row>
                                            <Col md={24} sm={24}>
                                                <Panel bordered style={{ marginBottom: 5, marginTop: 5 }} >
                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                        </InputGroup>

                                                    </FormGroup>
                                                </Panel>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={24} sm={24}>
                                                <Panel bordered style={{ marginBottom: 10 }} >
                                                    <FormGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                        </InputGroup>

                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                                            <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                        </InputGroup>

                                                    </FormGroup>
                                                </Panel>
                                            </Col>
                                        </Row>

                                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                        <Panel
                                            bordered
                                            style={{ marginBottom: '10px' }}
                                        >
                                            <Row >
                                                <Col md={8} sm={24}>
                                                    <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'HBOL', null) }} appearance="default">Add HBOL</Button>
                                                </Col>
                                                <Col md={8} sm={24}>
                                                    <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                                </Col>
                                                <Col md={8} sm={24}>
                                                    <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                                                </Col>
                                            </Row>
                                            {
                                                state.ShipManagementCustRefData.map(freeCustItem => (
                                                    freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                            <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                                            <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                                                    <CloseIcon />
                                                                </InputGroup.Button>
                                                            </Whisper>

                                                        </InputGroup>
                                                        :
                                                        ''
                                                ))
                                            }
                                            {
                                                state.ShipManagementPoData.map(freePotem => (
                                                    freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>PO</InputGroup.Addon>
                                                            <Input readOnly style={styles} value={freePotem["PO"]} />

                                                            <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                                                    <CloseIcon />
                                                                </InputGroup.Button>
                                                            </Whisper>

                                                        </InputGroup>
                                                        :
                                                        ''
                                                ))
                                            }
                                            {
                                                state.ShipManagementHbolData.map(hbolItem => (
                                                    hbolItem["LegID"] === legItem["LegID"] ?
                                                        <FormGroup style={{ marginTop: '5px' }}>
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>HBOL</InputGroup.Addon>
                                                                <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                                                <InputGroup.Button onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add PO</InputGroup.Button>
                                                                <InputGroup.Button onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add Customer Ref</InputGroup.Button>

                                                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                    <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'HBOL', hbolItem["HbolID"], null, null) }}  >
                                                                        <CloseIcon />
                                                                    </InputGroup.Button>
                                                                </Whisper>

                                                            </InputGroup>
                                                            {
                                                                state.ShipManagementCustRefData.map(custItem => (
                                                                    custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>
                                                                                <MinusIcon />
                                                                            </InputGroup.Addon>
                                                                            <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                                            <Input readOnly style={styles} value={custItem["CustomerRef"]} />

                                                                            <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                                <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, custItem["CustomerRefID"]) }}  >
                                                                                    <CloseIcon />
                                                                                </InputGroup.Button>
                                                                            </Whisper>


                                                                        </InputGroup>
                                                                        :
                                                                        ''
                                                                ))
                                                            }
                                                            {
                                                                state.ShipManagementPoData.map(poItem => (
                                                                    poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>
                                                                                <MinusIcon />
                                                                            </InputGroup.Addon>
                                                                            <InputGroup.Addon>PO</InputGroup.Addon>
                                                                            <Input readOnly style={styles} value={poItem["PO"]} />

                                                                            <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                                <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, poItem["PoID"], null) }}  >
                                                                                    <CloseIcon />
                                                                                </InputGroup.Button>
                                                                            </Whisper>


                                                                        </InputGroup>
                                                                        :
                                                                        ''
                                                                ))
                                                            }
                                                        </FormGroup>
                                                        :
                                                        <div></div>
                                                ))
                                            }
                                        </Panel>




                                    </Panel>

                                    :

                                    legItem.ModeID === 3 ? //Truck Load
                                        <Panel
                                            bordered
                                            style={{ marginBottom: '10px' }}
                                            header={
                                                <Row>
                                                    <Col md={16} sm={24}>
                                                        <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                    </Col>
                                                    <Col md={8} sm={24}>
                                                        <ButtonToolbar >
                                                            <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                        </ButtonToolbar>
                                                    </Col>
                                                </Row>
                                            }
                                        >
                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                                    <InputPicker preventOverflow
                                                        container={() => mountRef.current}
                                                        placeholder={'Managing Warehouse...'}
                                                        value={legItem['WarehouseID']}
                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'WarehouseID') }}
                                                        data={state.warehouseArray}
                                                        style={inputStyle}
                                                        cleanable={true}
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            <Row>
                                                <Col md={12} sm={24}>
                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>BOL:</InputGroup.Addon>
                                                            <Input value={legItem['BOL']} onChange={(e) => { onChange(e, legItem["LegID"], 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>


                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Seal:</InputGroup.Addon>
                                                            <Input value={legItem['Seal']} onChange={(e) => { onChange(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>

                                                </Col>

                                                <Col md={12} sm={24}>
                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                                            <Input value={legItem['Carrier']} onChange={(e) => { onChange(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Trailer:</InputGroup.Addon>
                                                            <Input value={legItem['Trailer']} onChange={(e) => { onChange(e, legItem["LegID"], 'Trailer') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>

                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} sm={24}>
                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Pickup:</InputGroup.Addon>
                                                            <DatePicker
                                                                container={() => mountRef.current}
                                                                placeholder="Estimated Pickup Date"
                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                                                value={legItem['PlannedDepartureDate']}
                                                                // format="YYYY-MM-DD hh:mm aa"
                                                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                format="YYYY-MM-DD"
                                                                showMeridian
                                                                size="sm"
                                                                ranges={[
                                                                    {
                                                                        label: 'Now',
                                                                        value: new Date()
                                                                    }
                                                                ]}
                                                                style={styles}
                                                                oneTap
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Appointment Time:</InputGroup.Addon>
                                                            <DatePicker
                                                                container={() => mountRef.current}
                                                                // placeholder="Pickup Appointment Time"
                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'PickupAppointmentTime') }}
                                                                value={legItem['PickupAppointmentTime']}
                                                                // format="YYYY-MM-DD hh:mm aa"
                                                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                format="hh:mm aa"
                                                                showMeridian
                                                                size="sm"
                                                                ranges={[
                                                                    {
                                                                        label: 'Now',
                                                                        value: new Date()
                                                                    }
                                                                ]}
                                                                style={styles}
                                                            // oneTap
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>


                                                </Col>
                                                <Col md={12} sm={24}>
                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Delivery:</InputGroup.Addon>
                                                            <DatePicker
                                                                container={() => mountRef.current}
                                                                placeholder="Estimated Delivery Date"
                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                                                value={legItem["EstimatedDeliveryDate"]}
                                                                format="YYYY-MM-DD"
                                                                // format="YYYY-MM-DD hh:mm aa"
                                                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                showMeridian
                                                                size="sm"
                                                                ranges={[
                                                                    {
                                                                        label: 'Now',
                                                                        value: new Date()
                                                                    }
                                                                ]}
                                                                style={styles}
                                                                oneTap
                                                                placement={'leftStart'}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Appointment Time:</InputGroup.Addon>
                                                            <DatePicker
                                                                container={() => mountRef.current}
                                                                // placeholder="Delivery Appointment Time"
                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'DeliveryAppointmentTime') }}
                                                                value={legItem["DeliveryAppointmentTime"]}
                                                                format="hh:mm aa"
                                                                // format="YYYY-MM-DD hh:mm aa"
                                                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                showMeridian
                                                                size="sm"
                                                                ranges={[
                                                                    {
                                                                        label: 'Now',
                                                                        value: new Date()
                                                                    }
                                                                ]}
                                                                style={styles}
                                                                // oneTap
                                                                placement={'leftStart'}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>



                                            {/* <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Pickup Address:</InputGroup.Addon>
                                                    <Input value={legItem['PickupAddress']} onChange={(e) => { onChange(e, legItem["LegID"], 'PickupAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup >
                                                <InputGroup style={styles}>
                                                    <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                                                    <Input value={legItem['DeliveryAddress']} onChange={(e) => { onChange(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                                </InputGroup>
                                            </FormGroup> */}



                                            <Row>
                                                <Col md={24} sm={24}>
                                                    <Panel bordered style={{ marginBottom: 5, marginTop: 5 }} >
                                                        <FormGroup>
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                            </InputGroup>

                                                        </FormGroup>
                                                    </Panel>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md={24} sm={24}>
                                                    <Panel bordered style={{ marginBottom: 10 }} >
                                                        <FormGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                            </InputGroup>

                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                                                <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                            </InputGroup>

                                                        </FormGroup>
                                                    </Panel>
                                                </Col>
                                            </Row>


                                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                            <Panel
                                                bordered
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <Row >
                                                    <Col md={12} sm={24}>
                                                        <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                                    </Col>
                                                    <Col md={12} sm={24}>
                                                        <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                                                    </Col>
                                                </Row>
                                                {
                                                    state.ShipManagementCustRefData.map(freeCustItem => (
                                                        freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                                <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                    <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                                                        <CloseIcon />
                                                                    </InputGroup.Button>
                                                                </Whisper>

                                                            </InputGroup>
                                                            :
                                                            ''
                                                    ))
                                                }
                                                {
                                                    state.ShipManagementPoData.map(freePotem => (
                                                        freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>PO</InputGroup.Addon>
                                                                <Input readOnly style={styles} value={freePotem["PO"]} />

                                                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                    <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                                                        <CloseIcon />
                                                                    </InputGroup.Button>
                                                                </Whisper>

                                                            </InputGroup>
                                                            :
                                                            ''
                                                    ))
                                                }

                                            </Panel>





                                        </Panel>

                                        :

                                        legItem.ModeID === 4 ? //Less Than Truck Load

                                            <Panel
                                                bordered
                                                style={{ marginBottom: '10px' }}
                                                header={
                                                    <Row>
                                                        <Col md={16} sm={24}>
                                                            <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                        </Col>
                                                        <Col md={8} sm={24}>
                                                            <ButtonToolbar >
                                                                <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Row>
                                                }
                                            >
                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                                        <InputPicker preventOverflow
                                                            container={() => mountRef.current}
                                                            placeholder={'Managing Warehouse...'}
                                                            value={legItem['WarehouseID']}
                                                            onChange={(e) => { onChange(e, legItem["LegID"], 'WarehouseID') }}
                                                            data={state.warehouseArray}
                                                            style={inputStyle}
                                                            cleanable={true}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>BOL:</InputGroup.Addon>
                                                        <Input value={legItem['BOL']} onChange={(e) => { onChange(e, legItem["LegID"], 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>


                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Seal:</InputGroup.Addon>
                                                        <Input value={legItem['Seal']} onChange={(e) => { onChange(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>

                                                <Row>
                                                    <Col md={12} sm={24}>
                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                                                <Input value={legItem['Carrier']} onChange={(e) => { onChange(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={12} sm={24}>

                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Trailer:</InputGroup.Addon>
                                                                <Input value={legItem['Trailer']} onChange={(e) => { onChange(e, legItem["LegID"], 'Trailer') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>




                                                <Row>
                                                    <Col md={12} sm={24}>
                                                        <FormGroup>
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Pickup:</InputGroup.Addon>
                                                                <DatePicker
                                                                    container={() => mountRef.current}
                                                                    placeholder="Estimated Pickup Date"
                                                                    onChange={(e) => { onChange(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                                                    value={legItem['PlannedDepartureDate']}
                                                                    format="YYYY-MM-DD"
                                                                    // format="YYYY-MM-DD hh:mm aa"
                                                                    // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                    showMeridian
                                                                    size="sm"
                                                                    ranges={[
                                                                        {
                                                                            label: 'Now',
                                                                            value: new Date()
                                                                        }
                                                                    ]}
                                                                    style={styles}
                                                                    oneTap
                                                                // placement={'leftStart'}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12} sm={24}>
                                                        <FormGroup>
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Delivery:</InputGroup.Addon>
                                                                <DatePicker
                                                                    container={() => mountRef.current}
                                                                    placeholder="Estimated Delivery Date"
                                                                    onChange={(e) => { onChange(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                                                    value={legItem["EstimatedDeliveryDate"]}
                                                                    format="YYYY-MM-DD"
                                                                    // format="YYYY-MM-DD hh:mm aa"
                                                                    // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                    showMeridian
                                                                    size="sm"
                                                                    ranges={[
                                                                        {
                                                                            label: 'Now',
                                                                            value: new Date()
                                                                        }
                                                                    ]}
                                                                    style={styles}
                                                                    oneTap
                                                                    placement={'leftStart'}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>


                                                {/* <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Pickup Address:</InputGroup.Addon>
                                                        <Input value={legItem['PickupAddress']} onChange={(e) => { onChange(e, legItem["LegID"], 'PickupAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup >
                                                    <InputGroup style={styles}>
                                                        <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                                                        <Input value={legItem['DeliveryAddress']} onChange={(e) => { onChange(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                                    </InputGroup>
                                                </FormGroup> */}


                                                <Row>
                                                    <Col md={24} sm={24}>
                                                        <Panel bordered style={{ marginBottom: 5, marginTop: 5 }} >
                                                            <FormGroup>
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                </InputGroup>

                                                            </FormGroup>
                                                        </Panel>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md={24} sm={24}>
                                                        <Panel bordered style={{ marginBottom: 10 }} >
                                                            <FormGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                </InputGroup>

                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                                                    <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                </InputGroup>

                                                            </FormGroup>
                                                        </Panel>
                                                    </Col>
                                                </Row>

                                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                                <Panel
                                                    bordered
                                                    style={{ marginBottom: '10px' }}
                                                >
                                                    <Row >
                                                        <Col md={12} sm={24}>
                                                            <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                                        </Col>
                                                        <Col md={12} sm={24}>
                                                            <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        state.ShipManagementCustRefData.map(freeCustItem => (
                                                            freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                                    <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                                                    <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                        <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                                                            <CloseIcon />
                                                                        </InputGroup.Button>
                                                                    </Whisper>

                                                                </InputGroup>
                                                                :
                                                                ''
                                                        ))
                                                    }
                                                    {
                                                        state.ShipManagementPoData.map(freePotem => (
                                                            freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>PO</InputGroup.Addon>
                                                                    <Input readOnly style={styles} value={freePotem["PO"]} />

                                                                    <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                        <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                                                            <CloseIcon />
                                                                        </InputGroup.Button>
                                                                    </Whisper>

                                                                </InputGroup>
                                                                :
                                                                ''
                                                        ))
                                                    }

                                                </Panel>



                                            </Panel>

                                            :

                                            legItem.ModeID === 5 ? //Rail

                                                <Panel
                                                    bordered
                                                    style={{ marginBottom: '10px' }}
                                                    header={
                                                        <Row>
                                                            <Col md={16} sm={24}>
                                                                <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                            </Col>
                                                            <Col md={8} sm={24}>
                                                                <ButtonToolbar >
                                                                    <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                                </ButtonToolbar>
                                                            </Col>
                                                        </Row>
                                                    }
                                                >
                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                                            <InputPicker preventOverflow
                                                                container={() => mountRef.current}
                                                                placeholder={'Managing Warehouse...'}
                                                                value={legItem['WarehouseID']}
                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'WarehouseID') }}
                                                                data={state.warehouseArray}
                                                                style={inputStyle}
                                                                cleanable={true}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>



                                                    <Row>
                                                        <Col md={12} sm={24}>
                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>BOL:</InputGroup.Addon>
                                                                    <Input value={legItem['BOL']} onChange={(e) => { onChange(e, legItem["LegID"], 'BOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Container:</InputGroup.Addon>
                                                                    <Input value={legItem['Container']} onChange={(e) => { onChange(e, legItem["LegID"], 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12} sm={24}>
                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Seal:</InputGroup.Addon>
                                                                    <Input value={legItem['Seal']} onChange={(e) => { onChange(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                                                    <Input value={legItem['Carrier']} onChange={(e) => { onChange(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12} sm={24}>
                                                            <FormGroup>
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Pickup:</InputGroup.Addon>
                                                                    <DatePicker
                                                                        container={() => mountRef.current}
                                                                        placeholder="Estimated Pickup Date"
                                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                                                        value={legItem['PlannedDepartureDate']}
                                                                        format="YYYY-MM-DD"
                                                                        // format="YYYY-MM-DD hh:mm aa"
                                                                        // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                        showMeridian
                                                                        size="sm"
                                                                        ranges={[
                                                                            {
                                                                                label: 'Now',
                                                                                value: new Date()
                                                                            }
                                                                        ]}
                                                                        style={styles}
                                                                        placement={'leftStart'}
                                                                        oneTap
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12} sm={24}>
                                                            <FormGroup>
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Delivery:</InputGroup.Addon>
                                                                    <DatePicker
                                                                        container={() => mountRef.current}
                                                                        placeholder="Estimated Delivery Date"
                                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                                                        value={legItem["EstimatedDeliveryDate"]}
                                                                        format="YYYY-MM-DD"
                                                                        // format="YYYY-MM-DD hh:mm aa"
                                                                        // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                        showMeridian
                                                                        size="sm"
                                                                        ranges={[
                                                                            {
                                                                                label: 'Now',
                                                                                value: new Date()
                                                                            }
                                                                        ]}
                                                                        style={styles}
                                                                        oneTap
                                                                        placement={'leftStart'}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    {/* <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Pickup Address:</InputGroup.Addon>
                                                            <Input value={legItem['PickupAddress']} onChange={(e) => { onChange(e, legItem["LegID"], 'PickupAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup >
                                                        <InputGroup style={styles}>
                                                            <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                                                            <Input value={legItem['DeliveryAddress']} onChange={(e) => { onChange(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                                        </InputGroup>
                                                    </FormGroup> */}



                                                    <Row>
                                                        <Col md={24} sm={24}>
                                                            <Panel bordered style={{ marginBottom: 5, marginTop: 5 }} >
                                                                <FormGroup>
                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                    </InputGroup>

                                                                </FormGroup>
                                                            </Panel>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col md={24} sm={24}>
                                                            <Panel bordered style={{ marginBottom: 10 }} >
                                                                <FormGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                    </InputGroup>

                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                                                        <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                    </InputGroup>

                                                                </FormGroup>
                                                            </Panel>
                                                        </Col>
                                                    </Row>


                                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                                    <Panel
                                                        bordered
                                                        style={{ marginBottom: '10px' }}
                                                    >
                                                        <Row >
                                                            <Col md={24} sm={24}>
                                                                <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            state.ShipManagementPoData.map(freePotem => (
                                                                freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                                                    <InputGroup style={styles}>
                                                                        <InputGroup.Addon>PO</InputGroup.Addon>
                                                                        <Input readOnly style={styles} value={freePotem["PO"]} />

                                                                        <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                            <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                                                                <CloseIcon />
                                                                            </InputGroup.Button>
                                                                        </Whisper>

                                                                    </InputGroup>
                                                                    :
                                                                    ''
                                                            ))
                                                        }

                                                    </Panel>

                                                </Panel>

                                                :

                                                legItem.ModeID === 6 ? //Brokerage
                                                    <Panel
                                                        bordered
                                                        style={{ marginBottom: '10px' }}
                                                        header={
                                                            <Row>
                                                                <Col md={16} sm={24}>
                                                                    <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                                </Col>
                                                                <Col md={8} sm={24}>
                                                                    <ButtonToolbar >
                                                                        <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                                    </ButtonToolbar>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    >
                                                        <FormGroup >
                                                            <InputGroup style={styles}>
                                                                <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                                                <InputPicker preventOverflow
                                                                    container={() => mountRef.current}
                                                                    placeholder={'Managing Warehouse...'}
                                                                    value={legItem['WarehouseID']}
                                                                    onChange={(e) => { onChange(e, legItem["LegID"], 'WarehouseID') }}
                                                                    data={state.warehouseArray}
                                                                    style={inputStyle}
                                                                    cleanable={true}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>

                                                    </Panel>

                                                    :

                                                    legItem.ModeID === 7 ? //Drayage
                                                        <Panel
                                                            bordered
                                                            style={{ marginBottom: '10px' }}
                                                            header={
                                                                <Row>
                                                                    <Col md={16} sm={24}>
                                                                        <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                                    </Col>
                                                                    <Col md={8} sm={24}>
                                                                        <ButtonToolbar >
                                                                            <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                                        </ButtonToolbar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        >
                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Managing Warehouse:</InputGroup.Addon>
                                                                    <InputPicker preventOverflow
                                                                        container={() => mountRef.current}
                                                                        placeholder={'Managing Warehouse...'}
                                                                        value={legItem['WarehouseID']}
                                                                        onChange={(e) => { onChange(e, legItem["LegID"], 'WarehouseID') }}
                                                                        data={state.warehouseArray}
                                                                        style={inputStyle}
                                                                        cleanable={true}
                                                                        placement={'auto'}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Container:</InputGroup.Addon>
                                                                    <Input value={legItem['Container']} onChange={(e) => { onChange(e, legItem["LegID"], 'Container') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup>

                                                            <Row>
                                                                <Col md={12} sm={24}>


                                                                    <FormGroup >
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>MBOL:</InputGroup.Addon>
                                                                            <Input value={legItem['MBOL']} onChange={(e) => { onChange(e, legItem["LegID"], 'MBOL') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                                                                        </InputGroup>
                                                                    </FormGroup>

                                                                    <FormGroup >
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Seal:</InputGroup.Addon>
                                                                            <Input value={legItem['Seal']} onChange={(e) => { onChange(e, legItem["LegID"], 'Seal') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                        </InputGroup>
                                                                    </FormGroup>

                                                                </Col>
                                                                <Col md={12} sm={24}>




                                                                    <FormGroup >
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Carrier:</InputGroup.Addon>
                                                                            <Input value={legItem['Carrier']} onChange={(e) => { onChange(e, legItem["LegID"], 'Carrier') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                        </InputGroup>
                                                                    </FormGroup>

                                                                    <FormGroup >
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Vessel:</InputGroup.Addon>
                                                                            <Input value={legItem['Vessel']} onChange={(e) => { onChange(e, legItem["LegID"], 'Vessel') }} onInput={(e) => e.target.value = lettersNumbersDashesSpacesInputMask(e)} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={12} sm={24}>
                                                                    <FormGroup>
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Pickup:</InputGroup.Addon>
                                                                            <DatePicker
                                                                                container={() => mountRef.current}
                                                                                placeholder="Planned Pickup Date"
                                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'PlannedDepartureDate') }}
                                                                                value={legItem["PlannedDepartureDate"]}
                                                                                format="YYYY-MM-DD"
                                                                                // format="YYYY-MM-DD hh:mm aa"
                                                                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                                showMeridian
                                                                                size="sm"
                                                                                ranges={[
                                                                                    {
                                                                                        label: 'Now',
                                                                                        value: new Date()
                                                                                    }
                                                                                ]}
                                                                                style={styles}
                                                                                oneTap
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md={12} sm={24}>
                                                                    <FormGroup>
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Arrival:</InputGroup.Addon>
                                                                            <DatePicker
                                                                                container={() => mountRef.current}
                                                                                placeholder="Estimated Arrival Date"
                                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'EstimatedDeliveryDate') }}
                                                                                value={legItem["EstimatedDeliveryDate"]}
                                                                                format="YYYY-MM-DD"
                                                                                // format="YYYY-MM-DD hh:mm aa"
                                                                                // defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                                                                showMeridian
                                                                                size="sm"
                                                                                ranges={[
                                                                                    {
                                                                                        label: 'Now',
                                                                                        value: new Date()
                                                                                    }
                                                                                ]}
                                                                                style={styles}
                                                                                oneTap
                                                                                placement={'leftStart'}
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={12} sm={24}>
                                                                    <FormGroup style={{ marginTop: '5px' }}>
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Origin:</InputGroup.Addon>
                                                                            <InputPicker preventOverflow
                                                                                container={() => mountRef.current}
                                                                                placeholder={'Origin Port...'}
                                                                                value={legItem['SeaportOriginID']}
                                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'SeaportOriginID') }}
                                                                                data={props.CtrlTwrSeaportData}
                                                                                block
                                                                                cleanable={true}
                                                                                style={inputStyle}
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md={12} sm={24}>
                                                                    <FormGroup style={{ marginTop: '5px' }}>
                                                                        <InputGroup style={styles}>
                                                                            <InputGroup.Addon>Destination:</InputGroup.Addon>
                                                                            <InputPicker preventOverflow
                                                                                container={() => mountRef.current}
                                                                                placeholder={'Destination Port...'}
                                                                                value={legItem['SeaportDestID']}
                                                                                onChange={(e) => { onChange(e, legItem["LegID"], 'SeaportDestID') }}
                                                                                data={props.CtrlTwrSeaportData}
                                                                                block
                                                                                cleanable={true}
                                                                                style={inputStyle}
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>


                                                            {/* <FormGroup >
                                                                <InputGroup style={styles}>
                                                                    <InputGroup.Addon>Delivery Address:</InputGroup.Addon>
                                                                    <Input value={legItem['DeliveryAddress']} onChange={(e) => { onChange(e, legItem["LegID"], 'DeliveryAddress') }} onInput={(e) => e.target.value = textStringInputMask(e)} />
                                                                </InputGroup>
                                                            </FormGroup> */}



                                                            <Row>
                                                                <Col md={24} sm={24}>
                                                                    <Panel bordered style={{ marginBottom: 5, marginTop: 5 }} >
                                                                        <FormGroup>
                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Shipper Name:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Shipper_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Shipper_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Origin Address:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Origin_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Origin City:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Origin_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Origin State:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Origin_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Origin Zip:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Origin_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Origin Country:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Origin_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Origin_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                            </InputGroup>

                                                                        </FormGroup>
                                                                    </Panel>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col md={24} sm={24}>
                                                                    <Panel bordered style={{ marginBottom: 10 }} >
                                                                        <FormGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Consignee Name:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Consignee_Name']} onChange={(e) => { onChange(e, legItem["LegID"], 'Consignee_Name') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Destination Address:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Destination_AddressLine']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_AddressLine') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Destination City:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Destination_City']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_City') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Destination State:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Destination_State']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_State') }} onInput={(e) => e.target.value = textNumbersSymbolsInputmask(e)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Destination Zip:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Destination_PostalCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_PostalCode') }} onInput={(e) => e.target.value = limitCharacterLength(textStringInputMask(e), 9)} />
                                                                            </InputGroup>

                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Destination Country:</InputGroup.Addon>
                                                                                <Input style={{ width: '100%' }} value={legItem['Destination_CountryCode']} onChange={(e) => { onChange(e, legItem["LegID"], 'Destination_CountryCode') }} onInput={(e) => e.target.value = limitUpperCaseLengthMask(e, 2)} />
                                                                            </InputGroup>

                                                                        </FormGroup>
                                                                    </Panel>
                                                                </Col>
                                                            </Row>


                                                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                                                            <Panel
                                                                bordered
                                                                style={{ marginBottom: '10px' }}
                                                            >
                                                                <Row >
                                                                    <Col md={8} sm={24}>
                                                                        <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'HBOL', null) }} appearance="default">Add HBOL</Button>
                                                                    </Col>
                                                                    <Col md={8} sm={24}>
                                                                        <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', null) }} appearance="default">Add PO</Button>
                                                                    </Col>
                                                                    <Col md={8} sm={24}>
                                                                        <Button block onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', null) }} appearance="default">Add Customer Ref</Button>
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    state.ShipManagementCustRefData.map(freeCustItem => (
                                                                        freeCustItem["LegID"] === legItem["LegID"] && freeCustItem["HbolID"] === null ?
                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                                                <Input readOnly style={styles} value={freeCustItem["CustomerRef"]} />

                                                                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                                    <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, freeCustItem["CustomerRefID"]) }}  >
                                                                                        <CloseIcon />
                                                                                    </InputGroup.Button>
                                                                                </Whisper>

                                                                            </InputGroup>
                                                                            :
                                                                            ''
                                                                    ))
                                                                }
                                                                {
                                                                    state.ShipManagementPoData.map(freePotem => (
                                                                        freePotem["LegID"] === legItem["LegID"] && freePotem["HbolID"] === null ?
                                                                            <InputGroup style={styles}>
                                                                                <InputGroup.Addon>PO</InputGroup.Addon>
                                                                                <Input readOnly style={styles} value={freePotem["PO"]} />

                                                                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                                    <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, freePotem["PoID"], null) }}  >
                                                                                        <CloseIcon />
                                                                                    </InputGroup.Button>
                                                                                </Whisper>

                                                                            </InputGroup>
                                                                            :
                                                                            ''
                                                                    ))
                                                                }
                                                                {
                                                                    state.ShipManagementHbolData.map(hbolItem => (
                                                                        hbolItem["LegID"] === legItem["LegID"] ?
                                                                            <FormGroup style={{ marginTop: '5px' }}>
                                                                                <InputGroup style={styles}>
                                                                                    <InputGroup.Addon>HBOL</InputGroup.Addon>
                                                                                    <Input readOnly style={styles} value={hbolItem["HBOL"]} />
                                                                                    <InputGroup.Button onClick={(e) => { onClickMulti(e, legItem["LegID"], 'PO', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add PO</InputGroup.Button>
                                                                                    <InputGroup.Button onClick={(e) => { onClickMulti(e, legItem["LegID"], 'CustomerRef', hbolItem["HbolID"]) }} style={{ borderRight: 'outset' }} >Add Customer Ref</InputGroup.Button>

                                                                                    <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                                        <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'HBOL', hbolItem["HbolID"], null, null) }}  >
                                                                                            <CloseIcon />
                                                                                        </InputGroup.Button>
                                                                                    </Whisper>

                                                                                </InputGroup>
                                                                                {
                                                                                    state.ShipManagementCustRefData.map(custItem => (
                                                                                        custItem["LegID"] === legItem["LegID"] && custItem["HbolID"] === hbolItem["HbolID"] ?
                                                                                            <InputGroup style={styles}>
                                                                                                <InputGroup.Addon>
                                                                                                    <MinusIcon />
                                                                                                </InputGroup.Addon>
                                                                                                <InputGroup.Addon>Customer Ref</InputGroup.Addon>
                                                                                                <Input readOnly style={styles} value={custItem["CustomerRef"]} />

                                                                                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                                                    <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'CustomerRef', null, null, custItem["CustomerRefID"]) }}  >
                                                                                                        <CloseIcon />
                                                                                                    </InputGroup.Button>
                                                                                                </Whisper>


                                                                                            </InputGroup>
                                                                                            :
                                                                                            ''
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    state.ShipManagementPoData.map(poItem => (
                                                                                        poItem["LegID"] === legItem["LegID"] && poItem["HbolID"] === hbolItem["HbolID"] ?
                                                                                            <InputGroup style={styles}>
                                                                                                <InputGroup.Addon>
                                                                                                    <MinusIcon />
                                                                                                </InputGroup.Addon>
                                                                                                <InputGroup.Addon>PO</InputGroup.Addon>
                                                                                                <Input readOnly style={styles} value={poItem["PO"]} />

                                                                                                <Whisper placement="top" trigger="hover" speaker={tooltipDelete}>
                                                                                                    <InputGroup.Button onClick={(e) => { onClickDeleteMulti(e, 'PO', null, poItem["PoID"], null) }}  >
                                                                                                        <CloseIcon />
                                                                                                    </InputGroup.Button>
                                                                                                </Whisper>


                                                                                            </InputGroup>
                                                                                            :
                                                                                            ''
                                                                                    ))
                                                                                }
                                                                            </FormGroup>
                                                                            :
                                                                            <div></div>
                                                                    ))
                                                                }
                                                            </Panel>


                                                        </Panel>

                                                        :

                                                        <Panel
                                                            bordered
                                                            style={{ marginBottom: '10px' }}
                                                            header={
                                                                <Row>
                                                                    <Col md={16} sm={24}>
                                                                        <span>Leg: {legItem.LegNumber} - {legItem.ModeName}</span>
                                                                    </Col>
                                                                    <Col md={8} sm={24}>
                                                                        <ButtonToolbar >
                                                                            <Button style={{ float: 'right' }} onClick={(e) => { onClickDeleteLeg(e, legItem["LegID"]) }} >Delete Leg</Button>
                                                                        </ButtonToolbar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        >
                                                        </Panel>



                        ))
                    }


                    <FormGroup>

                        <ButtonToolbar >
                            <Button onClick={confirm} appearance="primary">Save</Button>
                            <Button onClick={close} appearance="default">Cancel</Button>
                            <Button style={{ float: 'right' }} onClick={(e) => { onClickShowAddLeg(e) }} >Add New Leg</Button>

                        </ButtonToolbar>

                    </FormGroup>


                </div> </Drawer.Body>
                <Drawer.Footer>

                    <br />

                </Drawer.Footer>


            </Drawer >

);



export default ShipmentManagementDrawer;


