import React, {
    PureComponent
} from 'react';
import { Row, Col, InputGroup, Panel, FormGroup, RadioGroup, Radio, InputPicker } from 'rsuite';
import CustomInputPicker from '../../componentsGeneral/Rsuite/InputPicker/CustomInputPicker';

const styles = {
    // marginBottom: '10px',
    width: '100%'
};
const stylesRequiredInput = {
    backgroundColor: '#FFE4E1'
};
const stylesInputPicker = {
    width: '100%'
};
const stylesRequiredAddOn = {
    backgroundColor: "rgb(255,228,225)"
};

const mountRef = React.createRef();

function StepCarrier({
    handleChangeFunc,
    onClickCarrierMethod,
    handleChangeCarrier,
    state, ...props
}) {



    let portDiv;
    if (props.isLabelGenPortRefLoading) {
        portDiv = <div align="center">
        </div>
    }
    else {
        var filteredPortList = []
        filteredPortList = props.LabelGenPortRefData.filter(x => x.CustomerID === state.CustomerID)

        if (filteredPortList.length > 0) {
            portDiv = <Row style={{ marginBottom: 10 }}>
                <Col md={24} sm={24} >
                    <Panel  >
                        <FormGroup >
                            <InputGroup style={styles}>
                                <InputGroup.Addon>Port (Required):</InputGroup.Addon>
                                <CustomInputPicker
                                    selectionValue={state.LabelPortID}
                                    onChange={handleChangeFunc}
                                    menuData={props.LabelGenPortRefData.filter(x => x.CustomerID === state.CustomerID)}
                                    stateName={'LabelPortID'}
                                    color={'#FFE4E1'}
                                    {...props}
                                />

                            </InputGroup>
                        </FormGroup>
                    </Panel>
                </Col>
            </Row>
        }

        else {
            portDiv = <div></div>
        }
    }




    let stepCarrier;
    if (typeof (state.carrierServiceArray) !== 'undefined' && state.carrierServiceArray != null && state.carrierServiceArray.length > 0) {

        stepCarrier = <div ref={mountRef}>

            <Col md={24} sm={24}>

                <Panel bordered style={{ marginBottom: 10 }} >

                    <Row style={{ marginBottom: 10 }}>
                        <Col md={12} sm={24} >
                            <Panel  >
                                <FormGroup >
                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Customer (Required):</InputGroup.Addon>
                                        <CustomInputPicker
                                            selectionValue={state.CustomerID}
                                            onChange={handleChangeFunc}
                                            menuData={state.customerArray}
                                            stateName={'CustomerID'}
                                            color={'#FFE4E1'}
                                            {...props}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Panel>
                        </Col>
                        <Col md={12} sm={24} >
                            <Panel  >
                                <FormGroup >
                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Warehouse (Required):</InputGroup.Addon>
                                        <CustomInputPicker
                                            selectionValue={state.WarehouseID}
                                            onChange={handleChangeFunc}
                                            menuData={state.warehouseArray}
                                            stateName={'WarehouseID'}
                                            color={'#FFE4E1'}
                                            {...props}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Panel>
                        </Col>
                    </Row>



                    <Row style={{ marginBottom: 10 }}>
                        <Col md={12} sm={24} >
                            <Panel  >
                                <FormGroup >
                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Carrier (Required):</InputGroup.Addon>
                                        <CustomInputPicker
                                            selectionValue={state.CarrierID}
                                            onChange={handleChangeCarrier}
                                            menuData={state.carrierArray}
                                            stateName={'CarrierID'}
                                            color={'#FFE4E1'}
                                            {...props}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Panel>
                        </Col>
                        <Col md={12} sm={24} >
                            <Panel  >
                                <FormGroup >
                                    <InputGroup style={styles}>
                                        <InputGroup.Addon>Service (Required):</InputGroup.Addon>
                                        <CustomInputPicker
                                            selectionValue={state.CarrierServiceID}
                                            onChange={handleChangeFunc}
                                            menuData={state.carrierServiceArray.filter(x => x.CarrierID === state.CarrierID)}
                                            stateName={'CarrierServiceID'}
                                            color={'#FFE4E1'}
                                            {...props}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Panel>
                        </Col>
                    </Row>

                    {portDiv}

                </Panel>
            </Col>
        </div>

    }
    else {
        stepCarrier = <div></div>
    }


    return (
        <div>
            {stepCarrier}
        </div>

    );
}


export default StepCarrier;