import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import AccountDetailsContainer from '../../../components/componentsUser/AccountDetails/AccountDetailsContainer';

class accountDetailsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {


          };
      }



    render() {

        return(
            <AnimatedView>

                <AccountDetailsContainer isMobile={this.props.isMobile}/>

            </AnimatedView>

        );
    }
}

export default accountDetailsView;
